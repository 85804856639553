import React, { useState } from "react";
import "../CSS/Font.css";
import "../CSS/Menu.css";
import BaseButton from "./Utils/BaseButton";
import TaskBarView from "./TaskBarView";
import PlayerData from "../Data/GameData/PlayerData/PlayerData";
import Phase from "../Data/GameData/Game/Phase";
import Logo from "./Utils/Logo";
import UnitLineView from "./Units/UnitLineView";
import Unit from "../Data/GameData/MapData/Unit";
import UnitImageView from "./Object/UnitImageView";
import ActionProductionView from "./ManageSystem/ActionView/ActionProductionView";
import ProductionActionData from "../Data/GameData/ActionData/ProductionActionData";
import MoveActionData from "../Data/GameData/ActionData/MoveActionData";
import ActionMove from "./ManageSystem/ActionView/ActionMove";
import BuyActionData from "../Data/GameData/ActionData/BuyActionData";
import ActionBuy from "./ManageSystem/ActionView/ActionBuy";
import StartOfGame from "./ManageSystem/ActionView/StartOfGame";
import Invasion from "./ManageSystem/ActionView/Invasion";
import ConsultCombat from "./ManageSystem/ActionView/ConsultCombat";
import ModifyFleetLimitAction from "../Data/GameData/ActionData/ModifyFleetLimitAction";
import ActionModifyFleetLimitView from "./ManageSystem/ActionView/ActionModifyFleetLimitView";
import ActionBuild from "./ManageSystem/ActionView/ActionBuild";
import AbilityList from "./Ability/AbilityList";
import PlanetInfo from "./ManageSystem/ActionView/PlanetInfo";
import ScoringView from "./Scoring/ScoringView";
import AllAbilitiesView from "./Ability/AllAbilitiesView";
import DraftData from "../Data/GameData/Faction/DraftData";
import DraftView from "./Map/DraftView";
import GameInfoView from "./Game/GameInfoView";
import TechTreeView from "./Technology/TechTreeView";
import TutorialTaskBarView from "./TutorialTaskBarView";
import LawsMapView from "./Laws/LawsMapView";

const Popup = require("../Data/Other/Popup");
const StaticGameData = require("../Data/GameData/StaticGameData");
const ActivationData = require("../Data/GameData/Activation/ActivationData");
const Color = require("../Common/Config/Colors");
const System = require("../Data/GameData/MapData/System");

const NavigationView = ({
  screenData = null,
  navigationData = { buttonsData: [], taskBarData: null },
  //taskBarData = { text: null },
  visible = true,
}) => {
  const popup = StaticGameData.popup;
  if (visible === false) return null;
  //Task bar text coming from MODE
  const playerData = StaticGameData.getPlayerData();

  if (!navigationData.buttonsData) {
    navigationData.buttonsData = [];
  }

  /* if (PlayerData.isTutorial(playerData)) {
    navigationData.buttonsData.push({
      text: "Tutorial",
      callback: () => {
        Popup.addLayer({
          name: "HelpPopup",
          helpReference: "tutorialHelpView",
        });
      },
    });
  }*/

  //Navigation buttons comming from MODE
  /*if (Popup.mode) {
    taskBarData.text = Popup.modeData.taskBarText;
    if (Popup.modeData) {
      //console.log("NavigationView Popup.modeData: ", Popup.modeData);

      if (Popup.modeData.cancelButton && Popup.modeData.cancelButton.text) {
        navigationData.buttonsData.push({
          text: Popup.modeData.cancelButton.text,
          callback: Popup.modeData.cancelButton.callback,
        });
      }
      if (
        (Popup.modeData.callbackButton &&
          Popup.modeData.callbackButton.nameView ===
            StaticGameData.popup.name) ||
        (Popup.modeData.callbackButton &&
          Popup.modeData.callbackButton.nameView === Popup.ALL_VIEW)
      ) {
        navigationData.buttonsData.push({
          text: Popup.modeData.callbackButton.text,
          callback: Popup.modeData.callbackButton.callback,
        });
      }
      if (Popup.modeData.moreButtons) {
        Popup.modeData.moreButtons.forEach((buttonData) => {
          if (buttonData.nameView === StaticGameData.popup.name)
            navigationData.buttonsData.push(buttonData);
        });
      }
    }
  }*/

  const elementStyle = {
    //margin: "10px",
  };

  const navigationBarLineStyle = {
    display: "inline-block",
    width: "auto", //display: "flex",
    textAlign: "center",
    //flexDirection: "row",
    //justifyContent: "flex-end", // Align items to the bottom
    //border: "1px solid red",
  };

  return (
    <div
      style={{
        //position: "fixed",
        //bottom: 0,
        //left: 0,
        //backgroundColor: Color.COLOR_GREY_3,
        zIndex: 1000,
        //border: "1px solid pink",
        //width: "auto",
        fontSize: "1em",
        //justifyContent: "center",
        //alignItems: "center",
        textAlign: "center",
        width: "auto",
        //pointerEvents: "auto",
        pointerEvents: "auto",
        //display: "flex",
        //flexDirection: "column",
        //backgroundColor: "purple",
        //borderTop: "2px solid " + Color.COLOR_GREY_5,

        //padding: "100px",
        //margin: "10px",
      }}
    >
      {Popup.tutorialTaskBarData && (
        <TutorialTaskBarView
          taskBarData={Popup.tutorialTaskBarData}
        ></TutorialTaskBarView>
      )}
      {
        <TaskBarView
          taskBarData={navigationData.taskBarData}
          elementStyle={elementStyle}
        ></TaskBarView>
      }
      {navigationData && navigationData.child && (
        <div>{navigationData.child}</div>
      )}
      <div>
        {" "}
        {playerData &&
          DraftData.isDraftFinished(playerData.draftData) &&
          playerData.faction &&
          Popup.getContextualButtonsVisibility() &&
          navigationData.isMapStandardNavigationData &&
          navigationData.isComingFromMapView &&
          popup.name === "MapView" && (
            <div style={navigationBarLineStyle}>
              <BaseButton
                onClick={() => {
                  ProductionActionData.prepareStep(playerData);
                  Popup.activateBlackPopup(
                    <ActionProductionView></ActionProductionView>
                  );
                }}
              >
                <Logo logoName="mineral"></Logo>
                <Logo logoName="science"></Logo>
                <Logo logoName="population"></Logo>
                <Logo logoName="energy"></Logo>
              </BaseButton>
              <BaseButton
                onClick={() => {
                  ActionBuy.prepareUI(playerData);
                }}
              >
                Buy
              </BaseButton>

              <BaseButton
                onClick={() => {
                  Popup.addDynamicLayer(
                    <AllAbilitiesView
                      playerDataToDisplay={playerData}
                    ></AllAbilitiesView>
                  );
                  //Popup.setContextualButtonsVisibility(false);
                }}
              >
                Abilities
              </BaseButton>
              {false && (
                <BaseButton
                  //height="2.80em"
                  //overflow="hidden"
                  onClick={() => {
                    ActionBuild.prepareUI(playerData);
                  }}
                >
                  {false && (
                    <UnitImageView
                      sizeEm={1}
                      unit={Unit.createUnit(
                        playerData,
                        playerData.faction.name,
                        Unit.UNIT_TYPE_CRUSER,
                        "",
                        false
                      )}
                    ></UnitImageView>
                  )}
                  <Logo logoName="build"></Logo>
                </BaseButton>
              )}

              <BaseButton
                onClick={() => {
                  ModifyFleetLimitAction.prepare(playerData);
                  Popup.activateBlackPopup(
                    <ActionModifyFleetLimitView></ActionModifyFleetLimitView>
                  );
                }}
              >
                <Logo logoName="cargo"></Logo>
              </BaseButton>
              <BaseButton
                onClick={() => {
                  Popup.addLayer({
                    name: "TechTreeView",
                    playerInGameId: playerData.playerInGameId,
                  });
                  Popup.setContextualButtonsVisibility(false);
                }}
              >
                Tech
              </BaseButton>
              <BaseButton
                onClick={() => {
                  Popup.addLayer({ name: "TransactionListView" });
                }}
              >
                <Logo logoName="transaction icon"></Logo>
              </BaseButton>
            </div>
          )}
      </div>
      <div>
        {playerData &&
          DraftData.isDraftFinished(playerData.draftData) &&
          playerData.faction &&
          Popup.getInfoContextualButtonsVisibility() &&
          popup.name === "MapView" &&
          navigationData.isMapStandardNavigationData &&
          navigationData.isComingFromMapView && (
            <div style={navigationBarLineStyle}>
              <BaseButton
                onClick={() => {
                  Popup.addLayer({ name: "GameFactionsView" });
                  Popup.setContextualButtonsVisibility(false);
                }}
              >
                Factions
              </BaseButton>

              <BaseButton
                onClick={() => {
                  ConsultCombat.prepareUI(playerData);
                }}
              >
                <Logo logoName="space combat"></Logo>
              </BaseButton>
              <BaseButton
                onClick={() => {
                  Popup.setContextualButtonsVisibility(false);
                  PlanetInfo.prepareUI(playerData);
                }}
              >
                <Logo logoName="planet_info"></Logo>
              </BaseButton>

              <BaseButton
                onClick={() => {
                  Popup.activateBlackPopup(<ScoringView></ScoringView>);
                }}
              >
                Scoring
              </BaseButton>
              <BaseButton
                onClick={() => {
                  Popup.activateBlackPopup(
                    <div>
                      <LawsMapView></LawsMapView>
                      <BaseButton
                        onClick={() => {
                          Popup.deActivateBlackPopup();
                        }}
                      >
                        Ok
                      </BaseButton>
                    </div>
                  );
                }}
              >
                Laws
              </BaseButton>
              <BaseButton
                onClick={() => {
                  Popup.activateBlackPopup(<GameInfoView></GameInfoView>);
                }}
              >
                Game Info
              </BaseButton>
            </div>
          )}
      </div>

      <div
        //className="div-hor-wrap"
        style={navigationBarLineStyle}
      >
        <span>
          {navigationData &&
            navigationData.buttonsData.map((buttonData, index) => {
              const { text, content, callback, type } = buttonData;

              const typeResult = type ? type : "white";

              if (content) {
                return (
                  <span key={index} style={elementStyle}>
                    <BaseButton
                      type={typeResult}
                      onClick={() => callback(screenData)}
                    >
                      {content}
                    </BaseButton>
                  </span>
                );
              }

              return (
                <span key={index} style={elementStyle}>
                  <BaseButton
                    type={typeResult}
                    onClick={() => callback(screenData)}
                  >
                    {text}
                  </BaseButton>
                </span>
              );
            })}
        </span>

        <span>
          {popup.name === "MapView" &&
            navigationData.isComingFromMapView &&
            playerData.gameParam.draft &&
            !DraftData.isDraftFinished(playerData.draftData) && (
              <span>
                <BaseButton
                  onClick={() => {
                    Popup.addLayer({
                      name: "TechTreeView",
                      playerInGameId: playerData.playerInGameId,
                    });
                    Popup.setContextualButtonsVisibility(false);
                  }}
                >
                  Techs
                </BaseButton>
                <BaseButton
                  onClick={() => {
                    Popup.addDynamicLayer(
                      <DraftView
                        draftData={playerData.draftData}
                        navigation={true}
                      ></DraftView>
                    );
                  }}
                >
                  Draft
                </BaseButton>
              </span>
            )}
        </span>

        <span>
          {popup.name === "MapView" &&
            DraftData.isDraftFinished(playerData.draftData) &&
            navigationData.isMapStandardNavigationData &&
            navigationData.isComingFromMapView &&
            playerData.faction &&
            !Popup.getMapNavigationDataInstance() && (
              <span style={elementStyle}>
                {
                  <BaseButton
                    onClick={() => {
                      Popup.setInfoContextualButtonsVisibility(
                        !Popup.getInfoContextualButtonsVisibility()
                      );
                      Popup.touch();
                    }}
                  >
                    <Logo logoName="info icon"></Logo>
                  </BaseButton>
                }
                {Popup.getContextualButtonsVisibility() === false && (
                  <BaseButton
                    onClick={() => {
                      Popup.setContextualButtonsVisibility(
                        !Popup.getContextualButtonsVisibility()
                      );
                      Popup.touch();
                    }}
                  >
                    <Logo logoName="context_open"></Logo>
                  </BaseButton>
                )}

                {Popup.getContextualButtonsVisibility() === true && (
                  <BaseButton
                    onClick={() => {
                      Popup.setContextualButtonsVisibility(
                        !Popup.getContextualButtonsVisibility()
                      );
                      Popup.touch();
                    }}
                  >
                    <Logo logoName="context_close"></Logo>
                  </BaseButton>
                )}
              </span>
            )}
        </span>
        <span>
          {PlayerData.isThereMandatoryAction(playerData) &&
            StaticGameData.popup.name !== "MandatoryActionGlobalView" &&
            navigationData.isComingFromMapView && (
              <span style={elementStyle}>
                <BaseButton
                  type="action"
                  onClick={() => {
                    Popup.addLayer({ name: "MandatoryActionGlobalView" });
                  }}
                >
                  To do
                </BaseButton>
              </span>
            )}
        </span>
      </div>
    </div>
  );
};

export default NavigationView;
