const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const ActivationData = require("./ActivationData.js");
const PlanetData = require("../MapData/Planet.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const UnitCatalog = require("../Utils/UnitCatalog.js");
const SpaceObject = require("../MapData/SpaceObject.js");
const WarningAction = require("../ActionCommon/WarningAction.js");
const SolveFleetLimitData = require("../MandatoryAction/SolveFleetLimitData.js");
const SolveCapacityData = require("../MandatoryAction/SolveCapacityData.js");
const UnitShopList = require("../Utils/UnitShopList.js");
const Cost = require("../Utils/Cost.js");

class ConstructionData {
  //Transfer Functions

  static VIEW_GLOBAL_VIEW = "GlobalView";
  static VIEW_BUILD_VIEW = "BuildView";

  static switchToView(playerData, view) {
    playerData.activationData.constructionStep.view = view;
    if (view === ConstructionData.VIEW_GLOBAL_VIEW) {
    }
    Popup.touch();
  }

  static switchToBuildView(playerData, unitConstData) {
    playerData.activationData.constructionStep.view =
      ConstructionData.VIEW_BUILD_VIEW;
    playerData.activationData.constructionStep.selectedConstData =
      unitConstData;

    Popup.touch();
  }

  static prepareStep(playerData, planet) {
    const activeSystem = ActivationData.getActiveSystem(playerData);
    const data = {
      view: ConstructionData.VIEW_GLOBAL_VIEW,
      unitClassSelected: null,
      selectedConstData: null,
      planetName: planet.name,
      unitsConstData: [],
      cost: Cost.createCost({}),
    };

    const planetFleet = Planet.getFleet(planet, playerData.faction.name);
    const unitsWhichCanConstruct = Fleet.getUnits(
      planetFleet,
      Fleet.UNIT_CLASS_ALL
    ).filter((unit) => Unit.canUnitConstruct(playerData, unit));

    for (let j = 0; j < unitsWhichCanConstruct.length; j++) {
      const unitShopList = UnitShopList.create(
        Unit.getConstructableUnitsFromUnit(
          playerData,
          playerData.faction.name,
          unitsWhichCanConstruct[j]
        )
      );

      data.unitsConstData.push({
        unit: unitsWhichCanConstruct[j],
        unitShopList: unitShopList,
        ghostFleetConstructing: Fleet.createFleet(
          playerData,
          playerData.faction.name
        ),
        cost: Cost.createCost({}),
      });
    }

    playerData.activationData.constructionStep = data;
  }

  static getData(playerData) {
    return playerData.activationData.constructionStep;
  }

  static getUnitShopListFromConstructingUnitData(
    playerData,
    constructingUnitData
  ) {
    return constructingUnitData.unitShopList;
  }

  static addOrder(playerData, constructingUnitData, orderedUnit) {
    if (
      constructingUnitData.unit.constructionAmount <=
      UnitShopList.getAmountUnitsShopped(constructingUnitData.unitShopList)
    ) {
      UIMessage.displayInfoMessage(
        "Construction limit reached.",
        Unit.getPrintingName(constructingUnitData.unit) +
          " can't construct more than" +
          constructingUnitData.unit.constructionAmount +
          " units."
      );
      return;
    }
    UnitShopList.addOrderUnit(
      constructingUnitData.unitShopList,
      orderedUnit,
      1
    );
    this.updateGhostFleetProducing(playerData, constructingUnitData);
  }

  static updateGhostFleetProducing(playerData, constructingUnitData) {
    constructingUnitData.ghostFleetConstructing =
      UnitShopList.generateGhostFleet(
        playerData,
        constructingUnitData.unitShopList
      );
    constructingUnitData.cost = Fleet.getCost(
      playerData,
      constructingUnitData.ghostFleetConstructing
    );

    const planetCost = Cost.createCost({});
    const data = this.getData(playerData);

    data.cost = planetCost;
    for (let i = 0; i < data.unitsConstData.length; i++) {
      const unitConstData = data.unitsConstData[i];
      Cost.addCostToCost(unitConstData.cost, planetCost);
    }
  }

  static removeOrder(playerData, constructingUnitData, orderedUnit) {
    UnitShopList.addOrderUnit(
      constructingUnitData.unitShopList,
      orderedUnit,
      -1
    );
    this.updateGhostFleetProducing(playerData, constructingUnitData);
  }

  static getSelectedUnitConstData(playerData) {
    return playerData.activationData.constructionStep.selectedConstData;
  }

  static getView(playerData) {
    return playerData.activationData.constructionStep.view;
  }

  static warningConstruction(playerData, callback) {
    const data = this.getData(playerData);

    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);

    const constructingSystem = JSON.parse(
      JSON.stringify(ActivationData.getActiveSystem(playerData))
    );

    const spaceAreaFleet = System.getFleetOrCreate(
      constructingSystem,
      playerData.faction.name,
      playerData.color
    );

    for (let j = 0; j < data.unitsConstData.length; j++) {
      const unitConstData = data.unitsConstData[j];
      const ghostFleet = unitConstData.ghostFleetConstructing;

      const constructedShips = Fleet.getUnits(
        ghostFleet,
        Fleet.UNIT_CLASS_SHIP
      );
      const constructingGroundForces = Fleet.getUnits(
        ghostFleet,
        Fleet.UNIT_CLASS_GROUND_FORCE
      );
      const constructingStructures = Fleet.getUnits(
        ghostFleet,
        Fleet.UNIT_CLASS_STRUCTURE
      );

      Fleet.addUnits(spaceAreaFleet, constructedShips);
      const planetFleet = SpaceObject.getFleetOrCreate(
        planet,
        playerData.faction.name,
        playerData.color
      );
      /*console.log("constructingGroundForces: ", constructingGroundForces);
      console.log("planetFleet: ", planetFleet);*/
      Fleet.addUnits(planetFleet, constructingGroundForces);
      Fleet.addUnits(planetFleet, constructingStructures);
    }
    WarningAction.warningFleetIsValid(constructingSystem, playerData, callback);
  }

  static resolveConstruction(playerData) {
    const data = playerData.activationData.constructionStep;
    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);

    if (planet.hasBuiltUnits) {
      throw new Error(
        "You can't build units on a planet that has already build units this round."
      );
    }

    const activeSystem = ActivationData.getActiveSystem(playerData);

    const spaceAreaFleet = System.getFleetOrCreate(
      activeSystem,
      playerData.faction.name,
      playerData.color
    );

    for (let j = 0; j < data.unitsConstData.length; j++) {
      const unitConstData = data.unitsConstData[j];
      const fleetProducing = UnitShopList.generateFleet(
        playerData,
        unitConstData.unitShopList
      );

      const cost = Fleet.getCost(playerData, fleetProducing);

      PlayerData.spendCost(playerData, cost, planet);

      const constructedShips = Fleet.getUnits(
        fleetProducing,
        Fleet.UNIT_CLASS_SHIP
      );

      //Check if space area is not controlled by another faction
      if (
        constructedShips.length > 0 &&
        activeSystem.faction &&
        activeSystem.faction !== playerData.faction.name
      ) {
        throw new Error(
          "The space area of this system is controlled by : " +
            activeSystem.faction +
            ". You can't construct ships in the space area : your planets are blockaded."
        );
      }

      const constructingGroundForces = Fleet.getUnits(
        fleetProducing,
        Fleet.UNIT_CLASS_GROUND_FORCE
      );
      const constructingStructures = Fleet.getUnits(
        fleetProducing,
        Fleet.UNIT_CLASS_STRUCTURE
      );

      //Check units requirements
      this.canPlanetConstruct(playerData, planet, fleetProducing);

      Fleet.addUnits(spaceAreaFleet, constructedShips);
      const planetFleet = SpaceObject.getFleetOrCreate(
        planet,
        playerData.faction.name,
        playerData.color
      );
      /*console.log("constructingGroundForces: ", constructingGroundForces);
      console.log("planetFleet: ", planetFleet);*/
      Fleet.addUnits(planetFleet, constructingGroundForces);
      Fleet.addUnits(planetFleet, constructingStructures);

      planet.hasBuiltUnits = true;

      //Logging
      if (!Fleet.isEmpty(fleetProducing)) {
        System.logActivityToSpaceObject(activeSystem, planet, [
          { type: LogMessage.ITEM_TYPE_SPACE_OBJECT, content: planet },
          { content: " built " },
          {
            type: LogMessage.ITEM_TYPE_FLEET,
            content: Fleet.getLogItem(fleetProducing),
          },
        ]);
      }
    }
    try {
      System.checkLogisticMass(playerData, activeSystem);
    } catch (e) {
      SolveFleetLimitData.prepareStep(playerData, activeSystem);
    }

    try {
      System.checkCapacity(playerData, activeSystem);
    } catch (e) {
      SolveCapacityData.prepareStep(playerData, activeSystem);
    }
  }

  static canPlanetConstruct(playerData, planet, fleet) {
    //Checking that unit requirement are met on planet
    //const structure, the structure used as requirement to build other units
    const structuresOnPlanetsFleet = Planet.getFleet(
      planet,
      playerData.faction.name
    );

    const structures = Fleet.getUnits(
      structuresOnPlanetsFleet,
      Fleet.UNIT_CLASS_STRUCTURE
    );

    const beingConstructedStructures = Fleet.getUnits(
      fleet,
      Fleet.UNIT_CLASS_STRUCTURE
    );

    if (beingConstructedStructures.length > 0) {
      structures.concat(beingConstructedStructures);
    }

    //Constructing units
    const units = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_ALL);
    for (let i = 0; i < units.length; i++) {
      const unit = units[i];

      if (unit.requiredStructures) {
        for (let j = 0; j < unit.requiredStructures.length; j++) {
          const requiredStructure = unit.requiredStructures[j];

          if (
            !structures.some(
              (structure) => structure.type === requiredStructure
            )
          ) {
            throw new Error(
              "A " +
                requiredStructure +
                " is required on the planet " +
                planet.name +
                " to build a " +
                unit.type +
                ". You can build a " +
                requiredStructure +
                " as part of this construction to match the requirement."
            );
          }
        }
      }
    }
  }
}

module.exports = ConstructionData;
