const Fleet = require("../MapData/Fleet");
const Planet = require("../MapData/Planet");
const TechList = require("../Technology/TechList");
const TechTree = require("../Technology/TechTree");
const CustomMath = require("../../../Common/Math/CustomMath");
const System = require("../MapData/System");
const SolveCapacityData = require("../MandatoryAction/SolveCapacityData");
const SolveFleetLimitData = require("../MandatoryAction/SolveFleetLimitData");
const Unit = require("../MapData/Unit");
const Curiosity = require("../MapData/Curiosity");
const Rules = require("../Game/Rules");
const Map = require("../MapData/Map");

class MapUnitaryAction {
  //Check legality

  static checkAllSystems(playerData) {
    const systems = Map.getSystemList(playerData.map);
    for (let i = 0; i < systems.length; i++) {
      this.checkSystem(playerData, systems[i]);
    }
  }

  static checkSystem(playerData, system) {
    SolveCapacityData.check(playerData, system);
    SolveFleetLimitData.check(playerData, system);
  }

  //Planet
  static placeUnitsOnPlanet(playerData, planet, units) {
    const fleet = Fleet.createFleet(playerData, playerData.faction.name);
    Fleet.addUnits(fleet, units);
    this.placeFleetOnPlanet(playerData, planet, fleet);
  }

  static removeUnitsFromPlanet(playerData, planet, units) {
    const fleet = Fleet.createFleet(playerData, playerData.faction.name);
    Fleet.addUnits(fleet, units);
    this.removeFleetFromPlanet(playerData, planet, fleet);
  }

  static removeUnitFromSystem(playerData, system, unit) {
    System.removeUnit(playerData, system, unit);
    this.checkSystem(playerData, system);
  }

  static placeFleetOnPlanet(playerData, planet, fleet) {
    if (
      planet.type === "Volcanic" &&
      !TechTree.hasPlayerTech(playerData, TechList.TECH.thermalSuits.name)
    ) {
      throw new Error(
        "You cannot place units on or take control of volcanic planets without thermal suits technology."
      );
    }
    Planet.addFleetToPlanet(planet, fleet);

    this.checkStructureAmountLimit(playerData, planet);
  }

  static removeFleetFromPlanet(playerData, planet, fleet) {
    Planet.removeFleetFromPlanet(planet, fleet);
  }

  static placePopulationOnPlanet(playerData, planet, population) {
    /*const maxPop = Planet.getMaxAmountPopulation(playerData, planet);
    if (planet.population + population > maxPop) {
      throw new Error(
        "You can't place more population than the planet can hold"
      );
    }*/
    planet.population = CustomMath.roundDec(population + planet.population);
    Planet.capPopulation(playerData, planet); // Is sending message to client, not tested from client but should work
  }

  static placeUnitsInSystemSA(playerData, system, units) {
    const fleet = Fleet.createFleet(playerData, playerData.faction.name);
    Fleet.addUnits(fleet, units);
    this.placeFleetInSystemSA(playerData, system, fleet);
  }

  static placeFleetInSystemSA(playerData, system, fleet) {
    //Check asteroid fields
    const backgroundObject = system.backgroundObject;
    if (backgroundObject === Curiosity.object.asteroidField.name) {
      if (
        !TechTree.hasPlayerTech(playerData, TechList.TECH.massDeflector.name)
      ) {
        throw new Error(
          "You cannot place units in an asteroid field without mass deflector technology."
        );
      }
    }

    const spaceAreaFleet = System.getFleetOrCreate(
      playerData,
      system,
      playerData.faction.name
    );
    Fleet.addFleetToFleet(fleet, spaceAreaFleet);
    this.checkSystem(playerData, system);
  }

  static removeUnitsFromSystemSA(playerData, system, units) {
    const fleet = Fleet.createFleet(playerData, playerData.faction.name);
    Fleet.addUnits(fleet, units);

    this.removeFleetFromSystemSA(playerData, system, fleet);
    this.checkSystem(playerData, system);
  }

  static removeUnitsFromSystemSAOrGround(playerData, system, units) {
    const fleet = Fleet.createFleet(playerData, playerData.faction.name);
    Fleet.addUnits(fleet, units);

    this.removeFleetFromSystemSAOrGround(playerData, system, fleet);
  }

  static removeFleetFromSystemSAOrGround(playerData, system, fleet) {
    System.removeFleetFromSystem(system, fleet);
    this.checkSystem(playerData, system);
  }

  static forceRemoveUnitsFromSystemSAOrGroundAllPlayers(
    playerData,
    system,
    units
  ) {
    const systemFleets = System.getFleets(system);
    for (let i = 0; i < systemFleets.length; i++) {
      const fleet = systemFleets[i];
      Fleet.removeUnits(fleet, units);
    }

    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const planetFleets = Planet.getFleets(planet);
      for (let j = 0; j < planetFleets.length; j++) {
        const fleet = planetFleets[j];
        Fleet.removeUnits(fleet, units);
      }
    }

    this.checkSystem(playerData, system);
  }

  static forceRemoveUnitsFromSAAllPlayers(playerData, system, units) {
    const systemFleets = System.getFleets(system);
    for (let i = 0; i < systemFleets.length; i++) {
      const fleet = systemFleets[i];
      Fleet.removeUnits(fleet, units);
    }

    this.checkSystem(playerData, system);
  }

  static forceRemoveUnitsFromPlanetAllPlayers(playerData, planet, units) {
    const planetFleets = Planet.getFleets(planet);
    for (let i = 0; i < planetFleets.length; i++) {
      const fleet = planetFleets[i];
      Fleet.removeUnits(fleet, units);
    }

    this.checkStructureAmountLimit(playerData, planet);
  }

  static removeFleetFromSystemSA(playerData, system, fleet) {
    const spaceAreaFleet = System.getFleetOrCreate(
      playerData,
      system,
      playerData.faction.name
    );

    const units = Fleet.getUnits(fleet);
    for (let i = 0; i < units.length; i++) {
      if (!System.isUnitInSpaceArea(playerData, system, units[i])) {
        throw new Error("Unit not found in system space area");
      }
    }

    Fleet.removeFleetFromFleet(fleet, spaceAreaFleet);
    this.checkSystem(playerData, system);
  }

  static checkStructureAmountLimit(playerData, planet) {
    const fleet = Planet.getFleetOrCreate(
      playerData,
      planet,
      playerData.faction.name
    );
    const structures = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_STRUCTURE);

    const structureTypes = [];

    structures.forEach((structure) => {
      const existingType = structureTypes.find(
        (structureType) => structureType.unit.type === structure.type
      );

      if (existingType) {
        existingType.amount++;
      } else {
        structureTypes.push({ unit: structure, amount: 1 });
      }
    });

    for (let i = 0; i < structureTypes.length; i++) {
      const structureType = structureTypes[i];
      const maxAmount = Unit.getMaxAmountOnPlanet(
        playerData,
        structureType.unit
      );
      const amount = structureType.amount;

      if (maxAmount && amount > maxAmount) {
        throw new Error(
          "You can't have more than " +
            maxAmount +
            " " +
            structureType.unit.type +
            " on a planet."
        );
      }
    }

    //Max amount of structures per planet
    if (structures.length > Rules.AMOUNT_STRUCTURE_PER_PLANET_MAX) {
      throw new Error(
        "You can't have more than " +
          Rules.AMOUNT_STRUCTURE_PER_PLANET_MAX +
          " structures on a planet."
      );
    }
  }
}

module.exports = MapUnitaryAction;
