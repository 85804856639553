import React, { useState } from "react";

import UIMessage from "../../Data/GameData/Connection/UIMessage";
import BaseButton from "../Utils/BaseButton";
import GameState from "../../Data/GameData/Game/GameState";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";
import LogBookView from "../Utils/LogMessage/LogBookView";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const Popup = require("../../Data/Other/Popup");
const Color = require("../../Common/Config/Colors");

const DetailViewWhenNoFactionPicked = ({ playerData, targetItem }) => {
  const message = UIMessage.getFirstMessageToDisplay(playerData);
  const isItemSystem = targetItem.objects && !targetItem.isFactionDraftSystem;
  const isItemPlanet = targetItem.isPlanet;
  const isItemDraftSystem = targetItem.isFactionDraftSystem;

  let backgroundColor;
  backgroundColor = "rgb(0, 0, 0,1)";
  backgroundColor = Color.COLOR_GREY_2;

  const style = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 2000,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  };

  const handleMouseDownOnBackground = (e) => {
    //e.preventDefault();
    //e.stopPropagation();
  };

  const handleMouseUpOnBackground = (e) => {
    //e.preventDefault();
    //e.stopPropagation();

    StaticGameData.popup.mapClicked = null;
    Popup.deleteAttribute("mapClicked");
  };

  const Title = () => {
    return (
      <div
        style={{
          fontSize: "1.5em",
          color: "white",
          //border: "2px solid blue",
          padding: "10px",
          width: "100%",
        }}
      >
        no title
      </div>
    );
  };

  const SystemContent = () => {
    return (
      <div className="div-ver-center">
        <BaseButton
          onClick={(e) => {
            Popup.addLayer({ name: "ManageSystemView", system: targetItem });
          }}
        >
          Details
        </BaseButton>
      </div>
    );
  };

  const DraftSystemContent = () => {
    let isPlayerHomeSystem = false;
    {
      if (
        playerData.homeSystemCoords.x === targetItem.x &&
        playerData.homeSystemCoords.y === targetItem.y
      ) {
        isPlayerHomeSystem = true;
      }
    }
    return (
      <div className="div-ver-center">
        <BaseButton
          onClick={(e) => {
            Popup.addLayer({
              name: "DraftFactionView",
              draftSystem: targetItem,
              isPlayerHomeSystem: isPlayerHomeSystem,
            });
          }}
        >
          {isPlayerHomeSystem ? "Pick Faction" : "See Factions"}
        </BaseButton>
      </div>
    );
  };

  const PlanetContent = () => {
    return (
      <div>
        <BaseButton
          onClick={(e) => {
            Popup.addLayer({
              name: "ManageObjectView",
              spaceObject: targetItem,
            });
          }}
        >
          Details
        </BaseButton>
      </div>
    );
  };

  const Content = () => {
    return (
      <div
        style={{
          fontSize: "1rem",
          color: "white",
          //border: "2px solid yellow",
          padding: "10px",
          //width: "100%",
          //maxHeight: "100%",
          overflow: "auto",
          boxSizing: "border-box",
        }}
      >
        <div>
          {isItemPlanet && <span>Planet </span>}{" "}
          {isItemSystem && <span>Space Area </span>}
          {targetItem.name}
        </div>

        {isItemDraftSystem && <DraftSystemContent></DraftSystemContent>}
        {isItemSystem && <SystemContent></SystemContent>}
        {isItemPlanet && <PlanetContent></PlanetContent>}
        <BaseButton
          onClick={() => {
            Popup.deleteAttribute("mapClicked");
          }}
        >
          Back
        </BaseButton>
      </div>
    );
  };

  return <Content></Content>;
};

export default DetailViewWhenNoFactionPicked;
