const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const SystemAction = require("../ActionCommon/SystemAction.js");
const ValidAction = require("../ActionCommon/WarningAction.js");
const WarningAction = require("../ActionCommon/WarningAction.js");
const Cost = require("../Utils/Cost.js");
const LogBook = require("../Connection/LogBook.js");
const LogAttachment = require("../Connection/LogAttachment.js");
const CheckAction = require("../ActionCommon/CheckAction.js");
const Phase = require("../Game/Phase.js");
const StaticGameData = require("../StaticGameData.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Request = require("../../../Common/Requests/Request.js");
const ActionCommonData = require("./ActionCommonData.js");
const TechTree = require("../Technology/TechTree.js");
const TechList = require("../Technology/TechList.js");
const DraftData = require("../Faction/DraftData.js");
const Faction = require("../PlayerData/Faction.js");
const SecondaryObject = require("../MapData/SecondaryObject.js");

class DraftActionData {
  static resolvePickPositionClient(system) {
    const data = {
      action: "pick position",
      systemName: system.name,
    };

    this.resolveClientCommon(data);
  }

  static resolvePickHeroClient(hero) {
    const data = {
      action: "pick hero",
      heroName: hero.name,
    };

    this.resolveClientCommon(data);
  }

  static resolvePickFactionClient(factionName) {
    const data = {
      action: "pick faction",
      factionName: factionName,
    };

    this.resolveClientCommon(data);
  }

  static resolveClientCommon(data) {
    const playerData = StaticGameData.getPlayerData();
    playerData.phase = Phase.PHASE_ACTION;
    playerData.step = Phase.STEP_DRAFT_ACTION;

    Request.updateGameState(data);
  }

  static resolvePickPositionServer(playerData, data) {
    const system = Map.getSystemFromName(playerData.map, data.systemName);

    if (!system.isFactionDraftSystem) {
      throw new Error("This system is not a system for drafting.");
    }
    if (
      DraftData.isMapPositionPicked(playerData.draftData, {
        x: system.x,
        y: system.y,
      })
    ) {
      throw new Error("This position is already picked.");
    }
    if (
      DraftData.hasPlayerPickedPosition(
        playerData.draftData,
        playerData.playerInGameId
      )
    ) {
      throw new Error("You have already picked a position.");
    }

    const draftData = playerData.draftData;
    DraftData.pickMapPosition(
      draftData,
      { x: system.x, y: system.y },
      playerData.playerInGameId
    );
    playerData.homeSystemCoords = { x: system.x, y: system.y };

    if (
      DraftData.hasPlayerPickedFaction(
        playerData.draftData,
        playerData.playerInGameId
      )
    ) {
      DraftActionData.fillSystemWithPlayerFaction(playerData);
    }

    if (
      DraftData.hasPlayerPickedHero(
        playerData.draftData,
        playerData.playerInGameId
      )
    ) {
      DraftActionData.fillSystemWithPlayerHero(playerData);
    }
  }

  static resolveCommon(playerData, data) {
    const activePlayer = DraftData.getActivePlayer(playerData.draftData);
    if (playerData.playerInGameId !== activePlayer.playerInGameId) {
      throw new Error("It is not your turn to draft.");
    }

    if (DraftData.isDraftFinished(playerData.draftData)) {
      throw new Error("The draft is already finished.");
    }

    if (data.action === "pick faction") {
      DraftActionData.resolvePickFaction(playerData, data);
    }

    if (data.action === "pick hero") {
      DraftActionData.resolvePickHero(playerData, data);
    }

    if (data.action === "pick position") {
      DraftActionData.resolvePickPositionServer(playerData, data);
    }

    DraftData.nextTurn(playerData.draftData);
  }

  static resolvePickHero(playerData, data) {
    if (
      DraftData.hasPlayerPickedHero(
        playerData.draftData,
        playerData.playerInGameId
      )
    ) {
      throw new Error("You have already picked a hero.");
    }

    if (DraftData.isHeroPicked(playerData.draftData, data.heroName)) {
      throw new Error("This hero is already picked.");
    }

    DraftData.pickHero(
      playerData.draftData,
      data.heroName,
      playerData.playerInGameId
    );

    if (
      DraftData.hasPlayerPickedPosition(
        playerData.draftData,
        playerData.playerInGameId
      )
    ) {
      DraftActionData.fillSystemWithPlayerHero(playerData);
    }
  }

  static resolvePickFaction(playerData, data) {
    const factionName = data.factionName;
    if (
      DraftData.hasPlayerPickedFaction(
        playerData.draftData,
        playerData.playerInGameId
      )
    ) {
      throw new Error("You have already picked a faction.");
    }

    if (DraftData.isFactionPicked(playerData.draftData, factionName)) {
      throw new Error("This faction is already picked.");
    }

    DraftData.pickFaction(
      playerData.draftData,
      factionName,
      playerData.playerInGameId
    );

    const faction = DraftData.getFactionFromName(
      playerData.draftData,
      data.factionName
    );

    playerData.faction = faction;

    if (
      DraftData.hasPlayerPickedPosition(
        playerData.draftData,
        playerData.playerInGameId
      )
    ) {
      DraftActionData.fillSystemWithPlayerFaction(playerData);
      if (
        DraftData.hasPlayerPickedHero(
          playerData.draftData,
          playerData.playerInGameId
        )
      ) {
        DraftActionData.fillSystemWithPlayerHero(playerData);
      }
    }
  }

  static fillSystemWithPlayerFaction(playerData) {
    if (
      !playerData.homeSystemCoords ||
      !DraftData.hasPlayerPickedPosition(
        playerData.draftData,
        playerData.playerInGameId
      )
    ) {
      throw new Error("You have not picked a position.");
    }

    const homeSystem = Map.getSystemFromCoords(
      playerData.map,
      playerData.homeSystemCoords.x,
      playerData.homeSystemCoords.y
    );

    //Player data updates
    playerData.color = playerData.faction.dedicatedColor;

    //Fill system with player faction
    Faction.fillEmptyHSWithPlayerFaction(
      playerData,
      playerData.faction,
      homeSystem
    );
  }

  static fillSystemWithHero(playerData) {
    if (
      !playerData.homeSystemCoords ||
      !DraftData.hasPlayerPickedPosition(
        playerData.draftData,
        playerData.playerInGameId
      )
    ) {
      throw new Error("You have not picked a position.");
    }

    const homeSystem = Map.getSystemFromCoords(
      playerData.map,
      playerData.homeSystemCoords
    );

    const playerHeroName = DraftData.getHeroFromPlayerInGameId(
      playerData.draftData,
      playerData.playerInGameId
    );

    const playerHero = DraftData.getHeroFromName(
      playerData.draftData,
      playerHeroName
    );

    const secondaryObject = {
      type: SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION,
      minorFaction: playerHero,
    };

    const systemObjects = System.getSpaceObjects(homeSystem);
    for (let i = 0; i < systemObjects.length; i++) {
      if (systemObjects[i].type === "void") {
        systemObjects[i] = secondaryObject;
        break;
      }
    }

    CustomMath.shuffleArray(systemObjects);
  }

  static fillSystemWithPlayerHero(playerData) {
    if (
      !playerData.homeSystemCoords ||
      !DraftData.hasPlayerPickedPosition(
        playerData.draftData,
        playerData.playerInGameId
      )
    ) {
      throw new Error("You have not picked a position.");
    }

    const homeSystem = Map.getSystemFromCoords(
      playerData.map,
      playerData.homeSystemCoords.x,
      playerData.homeSystemCoords.y
    );

    const playerHeroName = DraftData.getHeroFromPlayerInGameId(
      playerData.draftData,
      playerData.playerInGameId
    );

    const playerHero = DraftData.getHeroFromName(
      playerData.draftData,
      playerHeroName
    );

    const objects = System.getSpaceObjects(homeSystem);
    for (let i = 0; i < objects.length; i++) {
      if (objects[i].type === "void") {
        objects[i] = {
          type: SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION,
          minorFactionData: playerHero,
        };
        objects[i].name = "hero_" + playerData.playerInGameId;
        break;
      }
    }

    CustomMath.shuffleArray(objects);
  }
}

module.exports = DraftActionData;
