const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const ActivationData = require("../Activation/ActivationData.js");
const PlanetData = require("../MapData/Planet.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Cost = require("./Cost.js");
const UnitShopList = require("./UnitShopList.js");

class UnitCatalog {
  static create(playerData) {
    //const shipCatalog = [];
    const shipsInCatalog = Unit.getUnitShipsCatalog(
      playerData,
      playerData.faction.name
    );
    const shipShopList = UnitShopList.create(shipsInCatalog);
    /*for (let i = 0; i < shipsInCatalog.length; i++) {
      const unit = shipsInCatalog[i];
      shipCatalog.push({
        unit: unit,
        orderAmount: 0,
      });
    }*/

    //const groundForceCatalog = [];

    const groundForcesInCatalog = Unit.getUnitGroundForcesCatalog(
      playerData,
      playerData.faction.name
    );
    const groundForceShopList = UnitShopList.create(groundForcesInCatalog);
    /*for (let i = 0; i < groundForcesInCatalog.length; i++) {
      const unit = groundForcesInCatalog[i];
      groundForceCatalog.push({
        unit: unit,
        orderAmount: 0,
      });
    }*/

    //const structureCatalog = [];
    const structuresInCatalog = Unit.getUnitStructuresCatalog(
      playerData,
      playerData.faction.name
    );
    const structureShopList = UnitShopList.create(structuresInCatalog);
    /*for (let i = 0; i < structuresInCatalog.length; i++) {
      const unit = structuresInCatalog[i];
      structureCatalog.push({
        unit: unit,
        orderAmount: 0,
      });
    }*/

    /* const data = {
      costMineral: 0,
      costPopulation: 0,
      costEnergy: 0,
      costInfluence: 0,
      shipCatalog: shipCatalog,
      groundForceCatalog: groundForceCatalog,
      structureCatalog: structureCatalog,
    };*/

    return {
      shipCatalog: shipShopList,
      groundForceCatalog: groundForceShopList,
      structureCatalog: structureShopList,
    };
  }

  static getCost(playerData, unitCatalog) {
    const cost = Cost.createCost({});

    Cost.addCostToCost(
      UnitShopList.getCost(playerData, unitCatalog.shipCatalog),
      cost
    );
    Cost.addCostToCost(
      UnitShopList.getCost(playerData, unitCatalog.groundForceCatalog),
      cost
    );
    Cost.addCostToCost(
      UnitShopList.getCost(playerData, unitCatalog.structureCatalog),
      cost
    );

    /*for (let i = 0; i < unitCatalog.shipCatalog.length; i++) {
      const unit = unitCatalog.shipCatalog[i].unit;
      const orderAmount = unitCatalog.shipCatalog[i].orderAmount;
      if (unit.costMineral) {
        cost.mineral += unit.costMineral * orderAmount;
      }
      if (unit.costPopulation) {
        cost.population += unit.costPopulation * orderAmount;
      }
      if (unit.costEnergy) {
        cost.energy += unit.costEnergy * orderAmount;
      }
      if (unit.costInfluence) {
        cost.influence += unit.costInfluence * orderAmount;
      }
    }

    for (let i = 0; i < unitCatalog.groundForceCatalog.length; i++) {
      const unit = unitCatalog.groundForceCatalog[i].unit;
      const orderAmount = unitCatalog.groundForceCatalog[i].orderAmount;
      if (unit.costMineral) {
        cost.mineral += unit.costMineral * orderAmount;
      }
      if (unit.costPopulation) {
        cost.population += unit.costPopulation * orderAmount;
      }
      if (unit.costEnergy) {
        cost.energy += unit.costEnergy * orderAmount;
      }
      if (unit.costInfluence) {
        cost.influence += unit.costInfluence * orderAmount;
      }
    }

    for (let i = 0; i < unitCatalog.structureCatalog.length; i++) {
      const unit = unitCatalog.structureCatalog[i].unit;
      const orderAmount = unitCatalog.structureCatalog[i].orderAmount;
      if (unit.costMineral) {
        cost.mineral += unit.costMineral * orderAmount;
      }
      if (unit.costPopulation) {
        cost.population += unit.costPopulation * orderAmount;
      }
      if (unit.costEnergy) {
        cost.energy += unit.costEnergy * orderAmount;
      }
      if (unit.costInfluence) {
        cost.influence += unit.costInfluence * orderAmount;
      }
    }*/
    return cost;
  }

  static addOrderUnit(unitCatalog, unit, amount) {
    if (unit.class === Fleet.UNIT_CLASS_SHIP) {
      /* for (let i = 0; i < unitCatalog.shipCatalog.length; i++) {
        if (Unit.areUnitsTheSame(unitCatalog.shipCatalog[i].unit, unit)) {
          if (unitCatalog.shipCatalog[i].orderAmount + amount < 0) {
            return;
          }
          unitCatalog.shipCatalog[i].orderAmount += amount;
          return;
        }
      }*/
      UnitShopList.addOrderUnit(unitCatalog.shipCatalog, unit, amount);
    } else if (unit.class === Fleet.UNIT_CLASS_GROUND_FORCE) {
      /*for (let i = 0; i < unitCatalog.groundForceCatalog.length; i++) {
        if (
          Unit.areUnitsTheSame(unitCatalog.groundForceCatalog[i].unit, unit)
        ) {
          if (unitCatalog.groundForceCatalog[i].orderAmount + amount < 0) {
            return;
          }
          unitCatalog.groundForceCatalog[i].orderAmount += amount;
          return;
        }
      }*/
      UnitShopList.addOrderUnit(unitCatalog.groundForceCatalog, unit, amount);
    } else if (unit.class === Fleet.UNIT_CLASS_STRUCTURE) {
      /*for (let i = 0; i < unitCatalog.structureCatalog.length; i++) {
        if (Unit.areUnitsTheSame(unitCatalog.structureCatalog[i].unit, unit)) {
          if (unitCatalog.structureCatalog[i].orderAmount + amount < 0) {
            return;
          }
          unitCatalog.structureCatalog[i].orderAmount += amount;
          return;
        }
      }*/
      UnitShopList.addOrderUnit(unitCatalog.structureCatalog, unit, amount);
    }
  }

  static getClassCatalog(unitCatalog, unitClass) {
    if (unitClass === Fleet.UNIT_CLASS_SHIP) {
      return unitCatalog.shipCatalog;
    } else if (unitClass === Fleet.UNIT_CLASS_GROUND_FORCE) {
      return unitCatalog.groundForceCatalog;
    } else if (unitClass === Fleet.UNIT_CLASS_STRUCTURE) {
      return unitCatalog.structureCatalog;
    }
  }

  static generateGhostFleet(playerData, unitCatalog) {
    const ghostFleet = Fleet.createFleet(playerData, playerData.faction.name);
    const shipFleet = UnitShopList.generateFleet(
      playerData,
      unitCatalog.shipCatalog
    );
    const groundForceFleet = UnitShopList.generateFleet(
      playerData,
      unitCatalog.groundForceCatalog
    );
    const structureFleet = UnitShopList.generateFleet(
      playerData,
      unitCatalog.structureCatalog
    );
    Fleet.addFleetToFleet(shipFleet, ghostFleet);
    Fleet.addFleetToFleet(groundForceFleet, ghostFleet);
    Fleet.addFleetToFleet(structureFleet, ghostFleet);
    return ghostFleet;
  }

  static generateFleet(playerData, unitCatalog) {
    const fleet = Fleet.createFleet(playerData, playerData.faction.name);
    const shipFleet = UnitShopList.generateFleet(
      playerData,
      unitCatalog.shipCatalog
    );
    const groundForceFleet = UnitShopList.generateFleet(
      playerData,
      unitCatalog.groundForceCatalog
    );
    const structureFleet = UnitShopList.generateFleet(
      playerData,
      unitCatalog.structureCatalog
    );
    Fleet.addFleetToFleet(shipFleet, fleet);
    Fleet.addFleetToFleet(groundForceFleet, fleet);
    Fleet.addFleetToFleet(structureFleet, fleet);
    return fleet;
  }
}

module.exports = UnitCatalog;
