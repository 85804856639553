//const Color = require("../../../Common/Config/Colors");
//const StaticGameData = require("../StaticGameData");
//const Popup = require("../../Other/Popup");
const LogMessage = require("./LogMessage");

class LogBook {
  static generateLogBook(text, itemArrayP = []) {
    const itemArray = [...itemArrayP];
    const logBook = LogBook.createLogBook();
    this.generateAddMessage(logBook, text, itemArray);
    //console.log("LogBook generateLogBook", logBook);

    return logBook;
  }

  static generateAddMessage(logBook, text, itemArrayP = []) {
    const textArray = text.split("$");
    const logBookItemList = [];
    const itemArray = [...itemArrayP];

    //console.log("LogBook generateLogBook", textArray, itemArray);

    for (let i = 0; i < textArray.length; i++) {
      if (i % 2 === 1) {
        const type = textArray[i];
        //console.log("LogMessage type : ", type);
        // console.log("LogMessage content : ", itemArray[0]);

        if (itemArray.length < 1) {
          throw new Error(
            "LogBook generateLogBook: itemArray does not include enough items."
          );
        }

        if (type === "resource") {
          logBookItemList.push({
            type: LogMessage.ITEM_TYPE_RESOURCE_LOGO,
            content: itemArray.shift(),
          });
        }

        if (type === "unit") {
          logBookItemList.push({
            type: LogMessage.ITEM_TYPE_UNIT,
            content: itemArray.shift(),
          });
        }

        if (type === "fleet") {
          logBookItemList.push({
            type: LogMessage.ITEM_TYPE_FLEET,
            content: itemArray.shift(),
          });
        }

        if (type === "faction") {
          logBookItemList.push({
            type: LogMessage.ITEM_TYPE_FACTION_LOGO,
            content: itemArray.shift(),
          });
        }

        if (type === "space_object") {
          logBookItemList.push({
            type: LogMessage.ITEM_TYPE_SPACE_OBJECT,
            content: itemArray.shift(),
          });
        }

        if (type === "planet") {
          logBookItemList.push({
            type: LogMessage.ITEM_TYPE_SPACE_OBJECT,
            content: itemArray.shift(),
          });
        }

        if (type === "system") {
          logBookItemList.push({
            type: LogMessage.ITEM_TYPE_SYSTEM,
            content: itemArray.shift(),
          });
        }

        if (type === "logo") {
          logBookItemList.push({
            type: LogMessage.ITEM_TYPE_LOGO,
            content: itemArray.shift(),
          });
        }

        if (type === "item") {
          logBookItemList.push({
            type: LogMessage.ITEM_TYPE_ITEM,
            content: itemArray.shift(),
          });
        }
      } else {
        logBookItemList.push({ content: textArray[i] });
      }
    }

    LogBook.createMessage(logBook, logBookItemList);
  }

  static createLogBook() {
    const logTree = [];
    const logBook = {
      logTree: logTree,
      activeLogTree: logTree,
      activeLogTreePile: [],
    };

    return logBook;
  }

  static createMessage(
    logBook,
    itemList,
    collapsed = false,
    type = LogMessage.TYPE_INFO
  ) {
    //console.log("LogBook createMessage", type);
    const message = LogMessage.createMessage(itemList, type);
    /*for (let i = 0; i < itemList.length; i++) {
      const item = itemList[i];
      if (item.type) {
        LogMessage.addItem(message, item.content, item.type);
      } else {
        LogMessage.addItem(message, item.content);
      }
    }
*/

    this.addLogMessage(logBook, message, collapsed);
  }

  static addLogMessage(logBook, logMessage, collapsed = false) {
    logBook.activeLogTree.push({
      logMessage: logMessage,
      collapsed: collapsed, // colapsed means that the sub messages are not displayed by default
      subLogTree: [],
    });
  }

  static addDown(logBook) {
    if (logBook.activeLogTree.length === 0) {
      throw new Error("logBook.activeLogTree is empty. Cannot go down.");
    }
    logBook.activeLogTree[logBook.activeLogTree.length - 1].collapsed = true;
    logBook.activeLogTreePile.push(logBook.activeLogTree);
    logBook.activeLogTree =
      logBook.activeLogTree[logBook.activeLogTree.length - 1].subLogTree;
  }

  static addUp(logBook) {
    logBook.activeLogTree = logBook.activeLogTreePile.pop();
  }

  static isEmpty(logBook) {
    return !logBook || logBook.logTree.length === 0;
  }

  static concat(logBookToAdd, logBook) {
    if (!logBookToAdd || !logBook) {
      return;
    }
    logBook.logTree = logBook.logTree.concat(logBookToAdd.logTree);
    LogBook.correctCursor(logBook);
  }

  static concatToActiveLogBook(logBookToAdd, logBook) {
    if (!logBookToAdd || !logBook) {
      return;
    }

    logBookToAdd.logTree.forEach((item) => {
      logBook.activeLogTree.push(item);
    });
    LogBook.correctCursor(logBook);
  }

  static correctCursor(logBook) {
    logBook.activeLogTree = logBook.logTree;
  }

  static removeLast(logBook) {
    logBook.activeLogTree.pop();
  }
}

module.exports = LogBook;
