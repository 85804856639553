import React from "react";
import Game from "../Data/GameData/Game/Game";
import StaticGameData from "../Data/GameData/StaticGameData";
import FactionLogo from "../GameView/Utils/FactionLogo";
import DynamicView from "../GameView/DynamicView";
import Popup from "../Data/Other/Popup";
import GameView from "../GameView/GameView";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import MenuNavigationView from "../MainMenu/MenuNavigationView";
import GameGlobalLayout from "../GameView/GameGlobalLayout";
import BaseButton from "../GameView/Utils/BaseButton";
import Request from "../Common/Requests/Request";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import BaseInput from "../GameView/Utils/BaseInput";

function GameLobby({ game }) {
  const DisplayPasswordEnter = ({ game }) => {
    const [password, setPassword] = React.useState("");
    return (
      <div>
        <div>Enter password : </div>
        <LineSpace></LineSpace>
        <div>
          <BaseInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></BaseInput>
        </div>{" "}
        <LineSpace></LineSpace>
        <div>
          <BaseButton
            onClick={() => {
              Popup.deActivateBlackPopup();
            }}
          >
            Cancel
          </BaseButton>
        </div>
        <div>
          <BaseButton
            onClick={() => {
              Request.callPostAPI(
                { gameId: game.gameId, password: password },
                "join-custom-game"
              );
              Popup.deActivateBlackPopup();
              Popup.goBack();
            }}
          >
            Ok
          </BaseButton>
        </div>
      </div>
    );
  };

  const DisplayPlayerList = ({ game }) => {
    return (
      <div>
        <h2>Players : </h2>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
          <div>User</div>
          <div>In Game Id</div>
          <div>Faction</div>
          <LineSpace></LineSpace>
          <LineSpace></LineSpace>
        </div>
        {game.players.map((player, index) => (
          <div key={index}>
            <DisplayPlayer player={player}></DisplayPlayer>
          </div>
        ))}
      </div>
    );
  };
  const DisplayPlayer = ({ player }) => {
    const playerFaction = Game.getPlayerFactionFromPlayerId(
      game,
      player.playerInGameId
    );
    return (
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
        <div>
          {player.username && !game.playerNameHidden
            ? player.username
            : "Hidden"}
        </div>
        <div>{player.playerInGameId}</div>
        <div>
          {playerFaction ? (
            <span>
              <FactionLogo factionName={playerFaction}></FactionLogo>{" "}
              playerFaction
            </span>
          ) : (
            "?"
          )}
        </div>
      </div>
    );
  };

  const DisplayGameParameter = () => {
    const containerParamStyle = {
      border: "1px solid white",
      borderRadius: "5px",
      padding: "10px",
    };

    console.log("DEBUG game : ", game);

    return (
      <div>
        <div>
          <h1>Game Id : {game.gameId}</h1>
        </div>
        <LineSpace></LineSpace>
        <div style={containerParamStyle}>
          <span>Draft : {game.draft ? "Yes" : "No"}</span>
          {game.draft ? (
            <div>
              Players will draft their faction, their map position and the hero
              in their home system.
            </div>
          ) : (
            <div>
              {" "}
              Players will be placed randomly on the map and will be able to
              choose their faction out of a set of 2 factions.
            </div>
          )}
        </div>
        <LineSpace />
        <div style={containerParamStyle}>
          <span>Password : {game.isPassword ? "Yes" : "No"}</span>
        </div>

        <LineSpace />
        <div style={containerParamStyle}>
          <span>Amount players : {game.maxNumberPlayers}</span>
        </div>
        <LineSpace></LineSpace>
        <div style={containerParamStyle}>
          Player names hidden : {game.playerNameHidden ? "Yes" : "No"}
          <div>
            {game.playerNameHidden
              ? "Players will not know who are controlling the other factions."
              : "Players will know the name of the players controlling the other factions."}
          </div>
        </div>
        <LineSpace></LineSpace>
        {false && (
          <div style={containerParamStyle}>
            Win condition : {game.vpLimit} victory points.
          </div>
        )}
        <div style={containerParamStyle}>
          Round limit : {game.roundAmount} rounds.
        </div>
        <LineSpace></LineSpace>
      </div>
    );
  };

  const DisplayDeleteGameButton = ({ game }) => {
    if (Game.isGameCreatorFromUsername(game, StaticGameData.username)) {
      return (
        <div>
          <BaseButton
            onClick={() => {
              UIMessage.displayConfirmMessage(
                "Delete Game ?",
                "Are you sure you want to delete this game ?",
                () => {
                  Request.callPostAPI({ game: game }, "delete-custom-game");
                  Popup.goBack();
                },
                true
              );
            }}
          >
            Delete Game
          </BaseButton>
          <BaseButton
            onClick={() => {
              UIMessage.displayConfirmMessage(
                "Start Game ?",
                "Are you sure you want to start this game ?",
                () => {
                  const startGame = async () => {
                    try {
                      await Request.callPostAPI(
                        { game: game },
                        "start-custom-game"
                      );
                    } catch {
                      UIMessage.displayErrorMessageToStaticGameData(
                        "Error starting game"
                      );
                      Popup.touch();
                      return;
                    }
                    UIMessage.displayInfoMessageToStaticGameData(
                      "Game started",
                      "The game " + game.gameId + " has started."
                    );
                    Popup.touch();
                  };
                  startGame();
                  Popup.goBack();
                },
                true
              );
            }}
          >
            Start Game
          </BaseButton>
        </div>
      );
    } else {
      if (Game.isPlayerInGameFromUsername(game, StaticGameData.username)) {
        return (
          <div>
            <BaseButton
              onClick={() => {
                UIMessage.displayConfirmMessage(
                  "Quit Game ?",
                  "Are you sure you want to quit this game ?",
                  () => {
                    Request.callPostAPI({ game: game }, "quit-custom-game");
                    Popup.goBack();
                  },
                  true
                );
              }}
            >
              Quit Game
            </BaseButton>
          </div>
        );
      }
    }
  };

  const DisplayJoinButton = ({ game }) => {
    if (Game.isPlayerInGameFromUsername(game, StaticGameData.username)) {
      return;
    }

    return (
      <div>
        <BaseButton
          onClick={() => {
            UIMessage.displayConfirmMessage(
              "Join Game ?",
              "Are you sure you want to join this game ?",
              () => {
                if (game.isPassword) {
                  Popup.activateBlackPopup(
                    <DisplayPasswordEnter game={game}></DisplayPasswordEnter>
                  );
                } else {
                  Request.callPostAPI(
                    { gameId: game.gameId, password: "" },
                    "join-custom-game"
                  );
                  Popup.goBack();
                }
              },
              true
            );
          }}
        >
          Join Game
        </BaseButton>
      </div>
    );
  };

  const navigationData = {
    buttonsData: [
      {
        text: "Back",
        callback: () => Popup.goBack(),
      },
    ],
  };

  return (
    <GameGlobalLayout showTitle={false} backgroundType="uniform">
      <div className="text-box-center" style={{ height: "100%" }}>
        <DisplayGameParameter></DisplayGameParameter>
        <LineSpace></LineSpace>
        <DisplayPlayerList game={game}></DisplayPlayerList>
        <LineSpace></LineSpace>
        <DisplayJoinButton game={game}></DisplayJoinButton>
        <LineSpace></LineSpace>
        <DisplayDeleteGameButton game={game}></DisplayDeleteGameButton>{" "}
        <LineSpace></LineSpace>
        <div>Waiting for other players to join the game...</div>
      </div>
      <MenuNavigationView navigationData={navigationData} />
    </GameGlobalLayout>
  );
}

export default GameLobby;
