import React, { useState, useEffect } from "react";
import Color from "../../Common/Config/Colors";
import Law from "../../Data/GameData/Law/Law";
import BaseButton from "../Utils/BaseButton";
import LineSpace from "../Utils/Layout/LineSpace";
import Popup from "../../Data/Other/Popup";
import LawDetailedView from "./LawDetailedView";
import FactionLogo from "../Utils/FactionLogo";
import ResolveLaw from "../../Data/GameData/Law/ResolveLaw";
import Logo from "../Utils/Logo";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");

const LawButtonView = ({ law, voted = false, voting = false }) => {
  const playerData = StaticGameData.getPlayerData();

  const lawDesc = Law.createDescription(law);
  const playerVote = Law.getPlayerVote(playerData);
  const hasVotedForThisLaw = playerVote === law.name;

  return (
    <BaseButton
      onClick={() => {
        Popup.addDynamicLayer(
          <LawDetailedView
            law={law}
            voting={voting}
            voted={voted}
          ></LawDetailedView>
        );
      }}
    >
      <div style={{ color: "cyan" }}> {lawDesc.name}</div>
      {hasVotedForThisLaw && playerData.faction && (
        <div>
          {ResolveLaw.getVoteAmount(playerData.map, playerData)}{" "}
          <Logo logoName="vote"></Logo>
        </div>
      )}
      <hr></hr> <div> {lawDesc.shortDescription}</div>
    </BaseButton>
  );
};

export default LawButtonView;
