class Objectif {
  static create(data, advanced = false) {
    const objectif = {
      name: data.name,
      vp: null,
      description: null,
      advanced: advanced,
    };

    if (advanced === false) {
      objectif.vp = data.VP;
      objectif.description = data.description;
    } else {
      objectif.vp = data.VP_A;
      objectif.description = data.description_A;
    }

    return objectif;
  }
}

module.exports = Objectif;
