import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";

import IconGridView from "./IconGridView.jsx";
import IconView from "../Icon/IconView.jsx";
import SpaceObjectImageView from "../Object/SpaceObjectImageView.jsx";
import GameState from "../../Data/GameData/Game/GameState.js";
import Logo from "../Utils/Logo.jsx";
import FactionLogo from "../Utils/FactionLogo.jsx";
import FleetsSummaryView from "../ManageSystem/FleetsSummaryView.jsx";
import UnitImageView from "../Object/UnitImageView.jsx";
import UnitsStackView from "../Units/UnitsStackView.jsx";
import BaseButton from "../Utils/BaseButton.jsx";
import ModifyFleetLimitAction from "../../Data/GameData/ActionData/ModifyFleetLimitAction.js";
import TechButtonView from "../Technology/TechButtonView.jsx";
import InteractionFunctions from "../UIInteraction/InteractionFunctions.jsx";
import InventoryView from "../Transaction/InventoryView.jsx";
import AllAbilitiesView from "../Ability/AllAbilitiesView.jsx";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject.js";
import ActionMove from "../ManageSystem/ActionView/ActionMove.jsx";
import UIMessage from "../../Data/GameData/Connection/UIMessage.js";
import StartOfGameCA from "../../ClientAction/StartOfGameCA.js";
import Map from "../../Data/GameData/MapData/Map.js";
import ProductionActionData from "../../Data/GameData/ActionData/ProductionActionData.js";
import ActionProductionView from "../ManageSystem/ActionView/ActionProductionView.jsx";
import ActionBuild from "../ManageSystem/ActionView/ActionBuild.jsx";
import ManageSystemView from "../ManageSystem/ManageSystemView.jsx";
import ManagePlanetView from "../ManageSystem/ManagePlanetView.jsx";
import ManageMinorFactionView from "../ManageSystem/ManageMinorFactionView.jsx";
import InvasionActionData from "../../Data/GameData/ActionData/InvasionActionData.js";
import Planet from "../../Data/GameData/MapData/Planet.js";
import SystemLogCombatView from "../LogViews/SystemLogCombatView.jsx";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Popup = require("../../Data/Other/Popup.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const ActivationData = require("../../Data/GameData/Activation/ActivationData.js");
const Phase = require("../../Data/GameData/Game/Phase.js");
const EndRoundReviewCA = require("../../ClientAction/EndRoundReviewCA.js");
const Unit = require("../../Data/GameData/MapData/Unit.js");

class MapClickCallBackView {
  static routeClick = (playerData, object) => {
    if (object.isPlanet) {
      MapClickCallBackView.clickPlanet(playerData, object);
    } else if (
      object.type &&
      object.type === SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION
    ) {
      MapClickCallBackView.clickMinorFaction(playerData, object);
    } else if (object.isSystem) {
      MapClickCallBackView.clickSpaceArea(playerData, object);
    }
  };

  static clickPlanet = (playerData, planet) => {
    if (!playerData.faction) {
      Popup.addDynamicLayer(
        <ManagePlanetView planet={planet}></ManagePlanetView>
      );
      return;
    }

    const hasProduced = planet.hasProduced;
    const hasBuild = planet.hasBuiltUnits;

    const planetCombatKey = planet.endRoundData.didGroundCombatOccur
      ? planet.combatKey
      : null;

    if (planet.faction && planet.faction === playerData.faction.name) {
      Popup.activateBlackPopup(
        <div className="div-ver-center">
          <BaseButton
            type="white"
            onClick={() => {
              const system = Map.getSystemFromSpaceObjectName(
                playerData.map,
                planet.name
              );
              ActionMove.prepare(playerData, system);
            }}
          >
            Move Units
          </BaseButton>
          <BaseButton
            type={hasProduced ? "navigation" : "white"}
            onClick={() => {
              ProductionActionData.prepareStep(playerData);
              Popup.activateBlackPopup(
                <ActionProductionView></ActionProductionView>
              );
            }}
          >
            <Logo logoName="mineral"></Logo> Resources
          </BaseButton>
          <BaseButton
            type={hasBuild ? "navigation" : "white"}
            onClick={() => {
              ActionBuild.prepareOnAPlanet(playerData, planet);
            }}
          >
            <Logo logoName="build"></Logo> Build Units
          </BaseButton>
          {planetCombatKey && (
            <BaseButton
              onClick={() => {
                Popup.deActivateBlackPopup();
                Popup.addLayer({
                  name: "CombatView",
                  combatKey: planetCombatKey,
                });
              }}
            >
              <Logo logoName="space combat"></Logo> Combat
            </BaseButton>
          )}
          <BaseButton
            type="white"
            onClick={() => {
              Popup.deActivateBlackPopup();
              Popup.addDynamicLayer(
                <ManagePlanetView planet={planet}></ManagePlanetView>
              );
            }}
          >
            <Logo logoName="info icon"></Logo> Info Planet
          </BaseButton>
          <BaseButton
            type="white"
            onClick={() => {
              Popup.deActivateBlackPopup();
            }}
          >
            Back
          </BaseButton>
        </div>
      );
    } else {
      Popup.activateBlackPopup(
        <div className="div-ver-center">
          {typeof playerData.colonists !== "undefined" &&
            playerData.colonists > 0 === true && (
              <BaseButton
                type="action"
                onClick={() => {
                  StartOfGameCA.resolve_STEP_START_OF_GAME_COLONIZE_fromMap({
                    spaceObjectName: planet.name,
                  });
                }}
              >
                Colonize
              </BaseButton>
            )}

          {Planet.isFactionInvading(planet, playerData.faction.name) ? (
            <BaseButton
              onClick={() => {
                Popup.deActivateBlackPopup();
                InvasionActionData.prepare(playerData, planet, true);
                InvasionActionData.resolveClient(playerData);
              }}
            >
              Cancel Invasion
            </BaseButton>
          ) : (
            <BaseButton
              onClick={() => {
                Popup.deActivateBlackPopup();
                InvasionActionData.prepare(playerData, planet);
                InvasionActionData.resolveClient(playerData);
              }}
            >
              Invade
            </BaseButton>
          )}
          {planetCombatKey && (
            <BaseButton
              onClick={() => {
                Popup.deActivateBlackPopup();
                Popup.addLayer({
                  name: "CombatView",
                  combatKey: planetCombatKey,
                });
              }}
            >
              <Logo logoName="space combat"></Logo> Combat
            </BaseButton>
          )}
          <BaseButton
            type="white"
            onClick={() => {
              Popup.deActivateBlackPopup();
              Popup.addDynamicLayer(
                <ManagePlanetView planet={planet}></ManagePlanetView>
              );
            }}
          >
            <Logo logoName="info icon"></Logo> Info Planet
          </BaseButton>
          <BaseButton
            type="white"
            onClick={() => {
              Popup.deActivateBlackPopup();
            }}
          >
            Back
          </BaseButton>
        </div>
      );
    }
  };

  static clickMinorFaction = (playerData, spaceObject) => {
    Popup.addDynamicLayer(
      <ManageMinorFactionView
        minorFaction={SecondaryObject.getMinorFaction(spaceObject)}
        spaceObject={spaceObject}
      ></ManageMinorFactionView>
    );
  };

  static clickSpaceArea = (playerData, system) => {
    if (!playerData.faction) {
      Popup.addDynamicLayer(
        <ManageSystemView system={system}></ManageSystemView>
      );
      return;
    }

    const hasBeenActivated = system.hasBeenActivated;
    const isDraftSystem = system.isFactionDraftSystem;

    if (isDraftSystem) {
      Popup.addLayer({ name: "ManageSystemView", system: system });
      return;
    }

    const units = System.getAllUnitsFromFactionIncludingPlanets(
      playerData,
      system,
      playerData.faction.name
    );
    const playerHasUnits = units.length > 0 ? true : false;

    const systemCombatKeyList = System.getAllCombatKeys(system).filter(
      (key) => !key.object.isPlanet
    );
    const systemCombatKey =
      systemCombatKeyList.length > 0 ? systemCombatKeyList[0] : null;

    Popup.activateBlackPopup(
      <div className="div-ver-center">
        {playerHasUnits && (
          <BaseButton
            type={hasBeenActivated ? "navigation" : "white"}
            onClick={() => {
              ActionMove.prepare(playerData, system);
            }}
          >
            Move Units
          </BaseButton>
        )}
        <BaseButton
          onClick={() => {
            Popup.deActivateBlackPopup();
            Popup.addLayer({ name: "ManageSystemView", system: system });
          }}
        >
          <Logo logoName="info icon"></Logo> Info System
        </BaseButton>
        {systemCombatKey && (
          <BaseButton
            onClick={() => {
              Popup.deActivateBlackPopup();
              Popup.addLayer({
                name: "CombatView",
                combatKey: systemCombatKey.key,
              });
              /*Popup.addDynamicLayer(
                <SystemLogCombatView
                  system={system}
                  backCallback={() => {
                    Popup.goBack();
                  }}
                ></SystemLogCombatView>
              );*/
            }}
          >
            <Logo logoName="space combat"></Logo> Combat
          </BaseButton>
        )}
        <BaseButton
          type="white"
          onClick={() => {
            Popup.deActivateBlackPopup();
          }}
        >
          Back
        </BaseButton>
      </div>
    );
  };
}

export default MapClickCallBackView;
