import React, { useEffect, useState } from "react";
import StaticGameData from "../../Data/GameData/StaticGameData.js";
import "../../CSS/Menu.css";

const Color = require("../../Common/Config/Colors.js");

const BaseInput = ({
  type = "text",
  id = "",
  value = null,
  onChange,
  width = "auto",
}) => {
  const inputStyle = {
    fontSize: "1.2em",
    borderRadius: "10px",
    padding: "10px",
    backgroundColor: Color.COLOR_MEDIUM_SHADOW,
    color: "white",
    fontFamily: "CustomFont",
    //borderColor: "rgb(13,21,34)",
    borderWidth: "4px",
    borderStyle: "solid",
    borderColor: Color.BASE_BUTTON_BORDER,
    width: width,
  };

  return (
    <input
      className="custom-input"
      style={inputStyle}
      type={type}
      id={id}
      value={value}
      onChange={onChange}
    />
  );
};

export default BaseInput;
