import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import { getObjectSummaryJSXArray } from "./SpaceObjectInfoArray"; // Adjust the path accordingly
import FactionLogo from "../Utils/FactionLogo";
import Logo from "../Utils/Logo";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const SpaceObject = require("../../Data/GameData/MapData/SpaceObject.js");
const Rules = require("../../Data/GameData/Game/Rules.js");

const ResourceView = ({ resourceType, children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div>
        <Logo logoName={resourceType} heightRem={"1em"} />
      </div>

      <span> {children}</span>
    </div>
  );
};

export default ResourceView;
