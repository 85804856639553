import React from "react";
import Popup from "../Data/Other/Popup";
import GameGlobalLayout from "../GameView/GameGlobalLayout";
import MenuNavigationView from "../MainMenu/MenuNavigationView";

let processPage = null;

export const setProcessPage = (processor) => {
  processPage = processor;
};

const RulePage = ({ data, isFirstPage = false }) => {
  console.log("RulePage data: ", data);

  const processedPage = processPage(data);
  const { title, content, subsections = [] } = processedPage;

  // Define the mapping of keywords to page names
  const keywordToPageMap = {
    planet: "planetExplanation",
    planets: "planetExplanation",
    wormhole: "wormholeExplanation",
    wormholes: "wormholeExplanation",
    system: "systemExplanation",
    systems: "systemExplanation",
  };

  const navigationData = {
    buttonsData: [
      {
        text: isFirstPage ? "Back to game" : "Back",
        callback: () => {
          Popup.goBack();
        },
      },
    ],
  };

  return (
    <GameGlobalLayout showTitle={false} backgroundType="uniform">
      <div className="text-box" style={{ textAlign: "left" }}>
        <h1 style={{ color: "gold" }}>{title}</h1>
        <div>{content}</div>
        {subsections && subsections.length > 0 && (
          <div>
            {subsections.map((subsection, index) => (
              <div key={index}>
                <h2 style={{ color: "gold" }}>{subsection.title}</h2>
                <div>{subsection.content}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <MenuNavigationView navigationData={navigationData}></MenuNavigationView>
    </GameGlobalLayout>
  );
};

export default RulePage;
