import { useState, useEffect } from "react";
import Popup from "../../../Data/Other/Popup";

// Custom hook to manage scroll position
const useScrollPosition = (
  ref,
  initialPosition = 0,
  rememberScroll = true,
  scrollAtEnd = false,
  popup,
  rememberScrollKey //If rememberScrollKey, instead of storing in popup, we store in global state. This is required if some different component is using the same popup
) => {
  const [scrollPosition, setScrollPosition] = useState(initialPosition);

  useEffect(() => {
    const updateScrollPosition = () => {
      if (ref.current) {
        setScrollPosition(ref.current.scrollTop);

        if (rememberScroll && rememberScrollKey) {
          Popup.addGlobalState(rememberScrollKey, ref.current.scrollTop);
          console.log(
            "useScrollPosition save rememberScrollKey",
            rememberScrollKey,
            ", value:",
            ref.current.scrollTop
          );
        } else {
          if (rememberScroll && popup) {
            popup.scrollPosition = ref.current.scrollTop;
          }
        }
      }
    };

    if (ref.current && initialPosition !== 0) {
      ref.current.scrollTop = initialPosition;
    }

    if (ref.current) {
      //Check if we rememberd a scroll position
      let rememberedScrollPosition = null;
      if (rememberScroll && rememberScrollKey) {
        rememberedScrollPosition = Popup.getGlobalState(rememberScrollKey);
        console.log(
          "useScrollPosition load rememberScrollKey",
          rememberScrollKey,
          ", value:",
          rememberedScrollPosition
        );
      } else {
        if (rememberScroll && popup) {
          rememberedScrollPosition = popup.scrollPosition;
        }
      }

      if (rememberedScrollPosition === null) {
        if (scrollAtEnd) {
          ref.current.scrollTop = ref.current.scrollHeight;
        }
      } else {
        ref.current.scrollTop = rememberedScrollPosition;
      }

      /*if (
        (!popup || !popup.scrollPosition) &&
        (!rememberScrollKey || !Popup.getGlobalState(rememberScrollKey))
      ) {
        if (scrollAtEnd) {
          ref.current.scrollTop = ref.current.scrollHeight;
        } else {
          ref.current.scrollTop = 0;
        }
      } else {
        if (
          rememberScroll &&
          rememberScrollKey &&
          Popup.getGlobalState(rememberScrollKey)
        ) {
          ref.current.scrollTop = Popup.getGlobalState(rememberScrollKey);
        } else {
          if (rememberScroll && popup && popup.scrollPosition) {
            ref.current.scrollTop = popup.scrollPosition;
          }
        }
      }*/
    }

    if (ref.current) {
      ref.current.addEventListener("scroll", updateScrollPosition);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", updateScrollPosition);
        if (rememberScroll) {
          setScrollPosition(ref.current.scrollTop);
        }
      }
    };
  }, [ref, initialPosition, rememberScroll]);

  return scrollPosition;
};

export default useScrollPosition;
