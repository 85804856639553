import AbilityCommon from "../../Data/GameData/Ability/AbilityCommon";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import PlanetView from "../Object/PlanetView";
import InteractionFunctions from "../UIInteraction/InteractionFunctions";
const TechList = require("../../Data/GameData/Technology/TechList");
const ResolveTechAbility = require("../../Data/GameData/Ability/ResolveTechAbility");

const {
  default: SelectionListView,
} = require("../Utils/Layout/SelectionListView");
const System = require("../../Data/GameData/MapData/System");

class AbilityInput {
  static getInputFromTech(tech) {
    switch (tech.name) {
      case TechList.TECH.orbitalMechDeployment.name:
        return AbilityInput.orbitalMechDeployment;
      case TechList.TECH.nekroReanimation.name:
        return AbilityInput.nekroReanimation;
      case TechList.TECH.warpImpulsion.name:
        return AbilityInput.warpImpulsion;
      case TechList.TECH.transportCarrierDeployment.name:
        return AbilityInput.transportCarrierDeployment;
      case TechList.TECH.warpGate.name:
        return AbilityInput.warpGate;
      case TechList.TECH.repairingStation.name:
        return AbilityInput.repairingStation;
      default:
        throw new Error("Tech ability callback not found");
    }
  }

  static orbitalMechDeployment = () => {
    const confirmCallback = (planet) => {
      AbilityCommon.requestToServer(TechList.TECH.orbitalMechDeployment.name, {
        planetName: planet.name,
      });
    };

    //InteractionFunctions.selectPlanetsOnMap(1, 1, confirmCallback);
    InteractionFunctions.selectAPlanet(confirmCallback);
  };

  static nekroReanimation = () => {
    const confirmCallback = (planet) => {
      AbilityCommon.requestToServer(TechList.TECH.nekroReanimation.name, {
        planetName: planet.name,
      });
    };
    InteractionFunctions.selectAPlanet(confirmCallback);
  };

  static warpImpulsion = () => {
    let units = [];
    let systemName = null;
    const confirmCallback = () => {
      AbilityCommon.requestToServer(TechList.TECH.warpImpulsion.name, {
        systemName: systemName,
        units: units,
      });
    };

    const selectUnitsImpacted = () => {
      InteractionFunctions.selectUnitsOnMap(
        (selectedUnits) => {
          units = selectedUnits;
          selectTargetSystem();
        },
        null,
        { fromOnlyOneSystem: true }
      );
    };

    const selectTargetSystem = () => {
      InteractionFunctions.selectASystem((selectedSystem) => {
        systemName = selectedSystem.name;
        confirmCallback();
      });
    };

    selectUnitsImpacted();
  };

  static transportCarrierDeployment = () => {
    let systemName = null;

    const confirmCallback = () => {
      AbilityCommon.requestToServer(
        TechList.TECH.transportCarrierDeployment.name,
        {
          systemName: systemName,
        }
      );
    };

    InteractionFunctions.selectASystem((selectedSystem) => {
      systemName = selectedSystem.name;
      confirmCallback();
    });
  };

  static warpGate = () => {
    let units = [];
    let systemName = null;

    const confirmCallback = () => {
      AbilityCommon.requestToServer(TechList.TECH.warpGate.name, {
        systemName: systemName,
        units: units,
      });
    };

    const selectUnitsImpacted = () => {
      InteractionFunctions.selectUnitsOnMap((selectedUnits) => {
        units = selectedUnits;
        selectTargetSystem();
      });
    };

    const selectTargetSystem = () => {
      InteractionFunctions.selectASystem((selectedSystem) => {
        systemName = selectedSystem.name;
        confirmCallback();
      });
    };

    selectUnitsImpacted();
  };

  static repairingStation = () => {
    let units = [];
    let systemName = null;

    const commit = (unit) => {
      AbilityCommon.requestToServer(TechList.TECH.repairingStation.name, {
        unitId: unit.id,
      });
    };

    const afterUnitSelected = (unit) => {
      UIMessage.displayConfirmMessage(
        "Replair",
        "Are you sure to use repairing station on " + unit.type + " ?",
        () => {
          commit(unit);
        }
      );
    };

    InteractionFunctions.selectAUnit(
      afterUnitSelected,
      "Select a unit to repair."
    );
  };
}

export default AbilityInput;
