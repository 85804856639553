import React, { useState, useEffect } from "react";

const StaticGameData = require("../../../Data/GameData/StaticGameData");
const Popup = require("../../../Data/Other/Popup");
const Color = require("../../../Common/Config/Colors");

const BlackPopup = ({ popup }) => {
  console.log("RENDER BlackPopup: ");

  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    setRenderKey((prevKey) => prevKey + 1);
  }, [popup]);

  let backgroundColor;
  backgroundColor = "rgba(0, 0, 0, 0.8)";

  const style = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 2000,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    //overflowY: "hidden",
  };

  const handleMouseDownOnBackground = (e) => {
    //e.preventDefault();
    //e.stopPropagation();
  };

  const handleMouseUpOnBackground = (e) => {
    //e.preventDefault();
    //e.stopPropagation();

    Popup.deActivateBlackPopup();
  };

  const Content = () => {
    return <div key={renderKey}>{Popup.getBlackPopupComponent()}</div>;
  };

  return (
    <div
      style={style}
      onMouseDown={handleMouseDownOnBackground}
      onMouseUp={handleMouseUpOnBackground}
      className="div-fill"
    >
      <div
        style={{
          marginTop: "5%", //This is to not hide the title bar with the resources
        }}
      ></div>
      <div
        style={{
          backgroundColor: backgroundColor,
          //height: "auto",
          maxWidth: "98%",
          border: "2px solid white",
          borderRadius: "10px",
          padding: "10px",
          maxHeight: "90%",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onMouseUp={(e) => {
          e.stopPropagation();
        }}
      >
        <Content></Content>
      </div>
    </div>
  );
};

export default BlackPopup;
