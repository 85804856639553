import React, { useState, useEffect } from "react";

import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import ResourceBarView from "../../Utils/ResourceBarView";
import CostView from "../../Utils/CostView";
import ActivationCA from "../../../ClientAction/ActivationCA";
import Logo from "../../Utils/Logo";
import PlanetView from "../../Object/PlanetView";
import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Rules = require("../../../Data/GameData/Game/Rules");
const Cost = require("../../../Data/GameData/Utils/Cost");
const Unit = require("../../../Data/GameData/MapData/Unit.js");
const UnitCatalog = require("../../../Data/GameData/Utils/UnitCatalog.js");
const TransferData = require("../../../Data/GameData/Activation/TransferData");
const Map = require("../../../Data/GameData/MapData/Map.js");
const BuildStructureActionData = require("../../../Data/GameData/ActionData/BuildStructureActionData");

const ActionBuildStructureView = ({ system, navigationData }) => {
  const playerData = StaticGameData.getPlayerData();
  const structureData = ActionCommonData.getData(playerData);
  const view = structureData.view;

  //console.log("ActivationBuildView : ", structureData);

  const activeSystem = ActivationData.getActiveSystem(playerData);
  const planet = Map.getSpaceObjectFromName(
    playerData.map,
    structureData.planetName
  );

  const DisplayCatalogLine = ({ catalogLine, fullCatalog, fleetProducing }) => {
    const unit = catalogLine.unit;
    const amount = catalogLine.orderAmount;

    return (
      <div>
        <div className="div-hor-center">
          <span style={{ width: "60%" }}>
            <UnitLineView
              unit={unit}
              factionName={playerData.faction.name}
              showHP={false}
              showRequirements={true}
              showName={true}
              showCost={true}
            ></UnitLineView>
          </span>
          <span style={{ width: "10%" }}>
            <span> {amount}</span>
          </span>
          <span style={{ width: "30%" }}>
            <BaseButton
              onClick={() => {
                UnitCatalog.addOrderUnit(fullCatalog, unit, 1);
                Fleet.addUnit(fleetProducing, unit);
                Popup.touch();
              }}
            >
              +
            </BaseButton>
            <BaseButton
              onClick={() => {
                UnitCatalog.addOrderUnit(fullCatalog, unit, -1);
                Fleet.removeUnit(fleetProducing, unit);
                Popup.touch();
              }}
            >
              -
            </BaseButton>
          </span>
        </div>
      </div>
    );
  };

  const DisplayBuildView = () => {
    const confirm = () => {
      BuildStructureActionData.resolveClient(playerData);
      Popup.goBack();
      /*UIMessage.displayConfirmMessage(
        "Construct ?",
        "Are you sure you want to build these structures ?",
        () => {
          ActionCommonData.resolveClient(playerData);
          Popup.goBack();
        }
      );*/
    };

    navigationData.buttonsData = [
      {
        text: "Cancel",
        callback: () => {
          ActionCommonData.cleanActionData(playerData);
          Popup.goBack();
        },
      },
      {
        text: "Build Structures",
        callback: confirm,
      },
    ];

    const fleetProducing = structureData.fleetProducing;
    const fullCatalog = BuildStructureActionData.getFullCatalog(
      playerData,
      planet
    );
    const classCatalog = BuildStructureActionData.getClassCatalog(
      playerData,
      planet,
      Fleet.UNIT_CLASS_STRUCTURE
    );
    let fullCatalogCost = UnitCatalog.getCost(playerData, fullCatalog);

    /*console.log("fullCatalog : ", fullCatalog);
    console.log("classCatalog : ", classCatalog);*/
    return (
      <div>
        <PlanetView
          planet={planet}
          showStock={true}
          showSize={true}
        ></PlanetView>
        <div className="text-box" style={{}}>
          <span>Construction Cost : </span>
          <CostView cost={fullCatalogCost}></CostView>
        </div>
        {classCatalog.map((item, index) => {
          return (
            <DisplayCatalogLine
              key={index}
              catalogLine={item}
              fullCatalog={fullCatalog}
              fleetProducing={fleetProducing}
            ></DisplayCatalogLine>
          );
        })}
      </div>
    );
  };

  /*const DisplayGlobalView = () => {
    const confirm = () => {
      UIMessage.displayConfirmMessage(
        "Construct ?",
        "Are you sure you want to build these structures ?",
        () => {
          ActivationCA.resolve_STEP_ACTIVATION_STRUCTURE();
        }
      );
    };

    navigationData.buttonsData.push(
      {
        text: "Help",
        callback: () => {
          Popup.addLayer({
            name: "HelpPopup",
            helpReference: "buildStructureStep",
          });
        },
      },
      //{ text: "Don't Construct", callback: null },
      {
        text: "Confirm",
        callback: confirm,
        type: "action",
      }
    );

    return (
      <div>
        {ASPlanets.map((planet, index) => {
          const planetCatalog = StructureData.getFullCatalog(
            playerData,
            planet
          );
          //console.log("planetCatalog : ", planetCatalog);
          const fleetProducing = StructureData.getPlanetData(
            playerData,
            planet
          ).fleetProducing;
          const fleetCost = Fleet.getCost(playerData, fleetProducing);

          //console.log("fleetProducing: ", fleetProducing);
          return (
            <div key={index}>
              <PlanetViewActivation
                spaceObject={planet}
                showPopRepartition={true}
              >
                <BaseButton
                  onClick={() => {
                    StructureData.setTransferInPlanet(playerData, planet);
                    TransferData.prepareTransferData(playerData);
                    StructureData.switchToView(
                      playerData,
                      StructureData.VIEW_TRANSFER
                    );
                  }}
                >
                  Transfer In
                </BaseButton>
              </PlanetViewActivation>
              <div className="text-box">
                <div>
                  Build cost : <CostView cost={fleetCost}></CostView>
                </div>
              </div>
              {!Fleet.isEmpty(fleetProducing) && (
                <div>
                  Building :{" "}
                  <FleetSummaryView
                    fleet={fleetProducing}
                    onClick={() => {
                      StructureData.switchToBuildView(
                        playerData,
                        Fleet.UNIT_CLASS_STRUCTURE,
                        planet
                      );
                    }}
                  ></FleetSummaryView>
                </div>
              )}
              {Fleet.isEmpty(fleetProducing) && (
                <div className="text-box" style={{ textAlign: "center" }}>
                  <BaseButton
                    onClick={() => {
                      StructureData.switchToBuildView(
                        playerData,
                        Fleet.UNIT_CLASS_STRUCTURE,
                        planet
                      );
                    }}
                  >
                    Build Structure
                  </BaseButton>
                </div>
              )}

              <hr></hr>
            </div>
          );
        })}
      </div>
    );
  };*/
  return (
    <div>
      <DisplayBuildView></DisplayBuildView>
    </div>
  );
};

export default ActionBuildStructureView;

//
