import React, { useState, useEffect } from "react";
import "../../../../CSS/Menu.css";
import BaseButton from "../../../Utils/BaseButton";
import Popup from "../../../../Data/Other/Popup";
import { getObjectSummaryJSXArray } from "../../SpaceObjectInfoArray"; // Adjust the path accordingly
import ResourceView from "../../ResourceView";
import StaticGameData from "../../../../Data/GameData/StaticGameData.js";
import FleetSummaryView from "../../FleetSummaryView";
import ResourceBarView from "../../../Utils/ResourceBarView";
import FleetsSummaryView from "../../FleetsSummaryView";
import Logo from "../../../Utils/Logo";
import FactionLogo from "../../../Utils/FactionLogo";
import LogAttachment from "../../../../Data/GameData/Connection/LogAttachment";
import FleetLineView from "../../../Object/FleetLineView";

const System = require("../../../../Data/GameData/MapData/System.js");
const Color = require("../../../../Common/Config/Colors.js");
const Fleet = require("../../../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../../../Common/Math/CustomMath.js");
const SpaceObject = require("../../../../Data/GameData/MapData/SpaceObject.js");
const Rules = require("../../../../Data/GameData/Game/Rules.js");
const Map = require("../../../../Data/GameData/MapData/Map.js");
const Planet = require("../../../../Data/GameData/MapData/Planet.js");
const LogBook = require("../../../../Data/GameData/Connection/LogBook.js");

const SystemViewActivation = ({
  system,
  children,
  onclick = () => {},
  calledFromActivation = false,
  showFleet = true,
  showLogs = false,
  showButtonSystemName = true,
  showGeneralInfo = false,
}) => {
  const playerData = StaticGameData.getPlayerData();

  console.log("SystemViewActivation system", system);

  const DisplayLogs = () => {
    const combatLog = LogAttachment.getFullCombatLog(system);
    const activityLog = LogAttachment.getFullActivityLog(system);

    //console.log("DisplayLogs activityLog", activityLog);

    return (
      <div>
        {(!LogBook.isEmpty(combatLog) || !LogBook.isEmpty(activityLog)) && (
          <BaseButton
            onClick={() => {
              Popup.addLayer({
                name: "ManageLogView",
                combatLog: combatLog,
                activityLog: activityLog,
              });
            }}
          >
            Logs
          </BaseButton>
        )}
      </div>
    );
  };

  const DisplayFleets = ({ system }) => {
    const fleets = System.getFleets(system);

    return (
      <div>
        {fleets.map((fleet, index) => {
          return (
            <div key={index}>
              <FleetLineView fleet={fleet}></FleetLineView>
            </div>
          );
        })}
      </div>
    );
  };

  const DisplaySystemGeneralInfo = ({ system }) => {
    return <span></span>;
  };

  const DisplayContent = ({ planet }) => {
    return (
      <div
        //className={"div-hor-center"}
        style={{
          fontSize: "1em",

          //justifyContent: "left",
          //alignItems: "left",

          //display: "flex",
          //justifyContent: "center",
          //alignItems: "left",
          //backgroundColor: "grey",
        }}
      >
        <div
          style={
            {
              //display: "flex",
              //flexDirection: "column",
              //textAlign: "left",
              //fontSize: "1em",
              //lineHeight: "1",
              //backgroundColor: "yellow",
            }
          }
        >
          <div
            style={
              {
                //display: "flex",
                //alignItems: "center", // Align items vertically within the div
              }
            }
          >
            {showButtonSystemName && (
              <div>
                <BaseButton
                  onClick={() => {
                    Popup.addLayer({
                      name: "ManageSystemView",
                      system: system,
                    });
                  }}
                >
                  {system.name}
                </BaseButton>
              </div>
            )}
            {showGeneralInfo && (
              <DisplaySystemGeneralInfo
                system={system}
              ></DisplaySystemGeneralInfo>
            )}
          </div>
          <div>
            {showFleet && <DisplayFleets system={system}></DisplayFleets>}
          </div>
          {showLogs && <DisplayLogs system={system}></DisplayLogs>}

          <div> {children}</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <DisplayContent></DisplayContent>
    </div>
  );
};

export default SystemViewActivation;
