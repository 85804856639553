const Logic = require("../Logic");
const StaticGameData = require("../../GameData/StaticGameData");
const GameState = require("../../GameData/Game/GameState");
const Map = require("../../GameData/Map/Map");

class PickFactionLogic extends Logic {
  constructor(data, gameState) {
    super("PickFactionLogic", data, gameState);
  }

  async execute() {
    super.execute();
    await this.pickFaction(this.data.playerPos, this.data.factionPicked);
  }

  async pickFaction(playerPos, factionPicked) {
    const playerState = this.gameState.playerStates[playerPos - 1];

    if (!playerState.factionDraft) {
      throw new Error("No faction draft found.");
    }

    const selectedFaction = playerState.factionDraft.find(
      (faction) => faction.factionName === factionPicked
    );

    if (!selectedFaction) {
      throw new Error(`Faction "${factionPicked}" not found in the draft.`);
    }

    playerState.faction = selectedFaction;
    playerState.unitPlans = selectedFaction.unitPlans;
    playerState.factionDraft = [];

    const homeSystemTile = playerState.faction.homeSystemTile;
    homeSystemTile.controllingPlayerMapPosition = playerPos;

    //update map
    Map.replaceTileAtPosition(homeSystemTile, playerPos, this.gameState);

    await this.applyLogic({ name: "MapView" });
  }
}

module.exports = PickFactionLogic;
