import React from "react";
import Game from "../Data/GameData/Game/Game";
import StaticGameData from "../Data/GameData/StaticGameData";
import FactionLogo from "../GameView/Utils/FactionLogo";
import DynamicView from "../GameView/DynamicView";
import Popup from "../Data/Other/Popup";
import GameView from "../GameView/GameView";
import GameLobby from "./GameLobby";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import GameGlobalFunctions from "../Data/Other/GameGlobalFunctions";
import GameGlobalLayout from "../GameView/GameGlobalLayout";
import MenuNavigationView from "../MainMenu/MenuNavigationView";
import GameInfoView from "../GameView/Game/GameInfoView";
import GameParamView from "./GameParamView";
import GameRankingView from "./GameRankingView";

function GameFinishedScreen({ game }) {
  console.log(game);

  const navigationData = {
    buttonsData: [
      {
        text: "Back",
        callback: () => Popup.goBack(),
      },
      {
        text: "Open Game",
        callback: () => Popup.enterGame(game),
      },
    ],
  };

  return (
    <GameGlobalLayout showTitle={false} backgroundType="uniform">
      <div className="text-box-center" style={{ height: "100%" }}>
        <div>
          <GameParamView game={game}></GameParamView>
        </div>
        <div>
          <GameRankingView game={game}></GameRankingView>
        </div>
      </div>
      <MenuNavigationView navigationData={navigationData} />
    </GameGlobalLayout>
  );
}

export default GameFinishedScreen;
