import React, { useState, useEffect, useRef } from "react";
import FactionLogo from "../Utils/FactionLogo";
import ScoreData from "../../Data/GameData/Objectifs/ScoreData";
import FactionSpan from "../Utils/FactionSpan";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import TransactionView from "./TransactionView";
import ChatFactionLogo from "../ChatView/ChatFactionLogo";
import Logo from "../Utils/Logo";
import TransactionItemsView from "./TransactionItemsView";
import ActionCommonData from "../../Data/GameData/ActionData/ActionCommonData";
import Item from "../../Data/GameData/Transactions/Item";
import SelectionListView from "../Utils/Layout/SelectionListView";
import ItemView from "./ItemView";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import ItemDetailedView from "./ItemDetailedView";
import TransactionInputView from "./TransactionInputView";
import ChatTagMethods from "../ChatView/ChatTagMethods";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");
const TransactionData = require("../../Data/GameData/Transactions/TransactionData");
const Transaction = require("../../Data/GameData/Transactions/Transaction");
const ProposeTransactionActionData = require("../../Data/GameData/ActionData/ProposeTransactionActionData");

const TransactionTextView = ({ transaction, direction }) => {
  const popup = StaticGameData.popup;

  const playerData = StaticGameData.getPlayerData();

  const [newMessage, setNewMessage] = useState(""); // Plain text representation

  const navigationData = {
    buttonsData: [
      {
        text: "Cancel",
        callback: () => {
          Popup.deActivateBlackPopup();
        },
      },
      {
        text: "Ok",
        callback: () => {
          handleSubmit();
          Popup.deActivateBlackPopup();
        },
      },
    ],
  };

  const inputRef = useRef(null);

  const handleSubmit = () => {
    if (newMessage.trim()) {
      console.log("Message submitted:", newMessage);
      setNewMessage(""); // Clear input
      if (inputRef.current) {
        inputRef.current.innerHTML = ""; // Clear the contenteditable div
      }

      const rawMessage = ChatTagMethods.reverseReplaceTags(newMessage);

      popup.shouldFocusInput = true;

      if (direction === "in") {
        Transaction.setTextIn(transaction, rawMessage);
      }
      if (direction === "out") {
        Transaction.setTextOut(transaction, rawMessage);
      }

      Popup.touch();
    }
  };

  return (
    <GameGlobalLayout showTitle={false}>
      <div
        className="text-box"
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "1em",
        }}
      >
        <TransactionInputView
          transaction={transaction}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          handleSubmit={handleSubmit}
          inputRef={inputRef}
        ></TransactionInputView>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default TransactionTextView;
