const Popup = require("../../Other/Popup");
const StaticGameData = require("../StaticGameData");
const Map = require("../MapData/Map");
const Rules = require("../Game/Rules");
const PlayerData = require("../PlayerData/PlayerData");
const UIMessage = require("../Connection/UIMessage");
const SpaceObject = require("../MapData/SpaceObject");
const Request = require("../../../Common/Requests/Request.js");
const SystemAction = require("../ActionCommon/SystemAction");
const OngoingActionActivation = require("./OngoingActionActivation");

//**********Order of phases
//COLINIZE

class OngoingAction {
  static ONGOING_ACTION_NONE = "none";
  static ONGOING_ACTION_ACTIVATION_ACTIVATED = "activation activated";
  static ONGOING_ACTION_COLONIZE = "colonize";
  static ONGOING_ACTION_WAITING_NEW_ROUND = "waiting new round";

  static updatePopupForOngoingAction(ongoingActionData) {
    console.log("Ongoing action data: ", ongoingActionData);
    if (!ongoingActionData) {
      return;
    }

    if (ongoingActionData.name === this.ONGOING_ACTION_NONE) {
      this.noneAction(ongoingActionData);
    }
    if (ongoingActionData.name === this.ONGOING_ACTION_ACTIVATION_ACTIVATED) {
      this.activationActivated(ongoingActionData);
    }

    if (ongoingActionData.name === this.ONGOING_ACTION_COLONIZE) {
      this.colonizeAction(ongoingActionData);
    }
    if (ongoingActionData.name === Rules.PHASE_ACTIVATION) {
      this.activationAction(ongoingActionData);
    }
  }

  static noneAction(ongoingActionData) {
    const playerData = StaticGameData.getPlayerData();
    const callback = (screenData) => {
      //return;
      /*const activate = (screenData) => {
        try {
          const result = PlayerData.colonize(playerData, screenData.spaceObject);
          Request.updateGameState({
            action: "colonize",
            spaceObjectName: screenData.spaceObject.name,
          });

          //OngoingAction.colonizeAction(ongoingActionData);
          //console.log("Colonize playerData : ", playerData);
        } catch (e) {
          UIMessage.displayErrorMessage("Can't colonize !", e);
          //throw e;
        }
      };*/

      const activate = () => {
        try {
          SystemAction.activateSystem(playerData, screenData.system);
          Popup.addLayer({ name: "ManageActivationView" });
          Request.updateGameState({
            action: "activate system",
            x: screenData.system.x,
            y: screenData.system.y,
          });
          Popup.resetMode();
        } catch (e) {
          //console.log("Error activating system : ", e);

          UIMessage.displayErrorMessage(
            "Can't activate system " + screenData.system.name + "!",
            e
          );
          //throw e;
          if (playerData) {
            console.log(
              "displayErrorMessage: playerData.messageToDisplay 2",
              playerData.messageToDisplay
            );
          }
        }
      };

      UIMessage.displayConfirmMessage(
        "Activate " + screenData.system.name + " ?",
        "You will have to finish the activation phase of this system before activating another one. After the activation phase of this system, units, minerals and population will not be able to leave this system.",
        activate
      );
    };

    Popup.setMode(
      { name: Rules.PHASE_ACTIVATION },
      {
        taskBarText: "Choose a system to activate.",
        callbackButton: {
          nameView: "ManageSystemView",
          text: "Activate",
          callback: callback,
          cancelButton: null,
        },
      }
    );
    Popup.touch();
  }

  static activationActivated(ongoingActionData) {
    OngoingActionActivation.OngoingActionActivation(ongoingActionData);
  }

  static colonizeAction(ongoingActionData) {
    //Colonize action
    const playerData = StaticGameData.getPlayerData();

    const doneCallback = () => {
      console.log("Done colonizing");
      const confirmCallback = () => {
        Popup.resetMode();
        Request.updateGameState({
          action: "stop colonize",
        });
      };
      UIMessage.displayConfirmMessage(
        "Not all colonists used !",
        "You did not used all of your colonists yet. You have " +
          playerData.colonists +
          " colonists left. Are you sure you want to stop colonization ? If you confirm, all your colonists will be removed. Colonist can only be used at the beginning of the game.",
        confirmCallback
      );
    };

    const mode = {
      name: Popup.MODE_CHOOSE_PLANET_COLONIZE,
    };

    const confirmationCallback = (screenData) => {
      const colonizePlanet = () => {
        try {
          const result = PlayerData.colonize(
            playerData,
            screenData.spaceObject
          );
          Request.updateGameState({
            action: "colonize",
            spaceObjectName: screenData.spaceObject.name,
          });
          /*UIMessage.displayInfoMessage(
            screenData.spaceObject.name + " has been colonized !",
            result.message
          );*/
          //OngoingAction.colonizeAction(ongoingActionData);
          //console.log("Colonize playerData : ", playerData);
        } catch (e) {
          UIMessage.displayErrorMessage("Can't colonize !", e);
          //throw e;
        }
      };

      UIMessage.displayConfirmMessage(
        "Colonize ?",
        "Are you sure you want to colonize " +
          screenData.spaceObject.name +
          " ?",
        colonizePlanet
      );
    };

    const taskBarText =
      "Choose a planet to colonize, at maximum distance of " +
      Rules.COLONIZE_DISTANCE +
      " of your homesystem. You have " +
      playerData.colonists +
      " colonists left.";
    const cancelButton = { text: "Done Colonizing", callback: doneCallback };
    const callbackButton = {
      nameView: "ManageObjectView",
      text: "Colonize !",
      callback: confirmationCallback,
    };

    Popup.setMode(mode, {
      taskBarText,
      callbackButton,
      cancelButton,
    });

    Popup.touch();
    //Popup.addAttributes({ test: "test" });
    //console.log("OngoingAction : Popup ", StaticGameData.popup);
  }
}

module.exports = OngoingAction;
