const PlayerData = require("../GameData/PlayerData/PlayerData.js");
const Rules = require("../GameData/Game/Rules.js");
const System = require("../GameData/MapData/System.js");
const Unit = require("../GameData/MapData/Unit.js");
const Map = require("../GameData/MapData/Map.js");
const Fleet = require("../GameData/MapData/Fleet.js");
const LogBook = require("../GameData/Connection/LogBook.js");
const LogMessage = require("../GameData/Connection/LogMessage.js");
const LogAttachment = require("../GameData/Connection/LogAttachment.js");
const Faction = require("../GameData/PlayerData/Faction.js");
const StaticGameData = require("../GameData/StaticGameData.js");
const ChatTag = require("./ChatTag.js");

class ChatItem {
  static TYPE_TEXT = "text";

  static createMessageText(
    text,
    playerInGameId,
    factionsInConversation = [Faction.DOMINUS_LEGION]
  ) {
    return {
      type: this.TYPE_TEXT,
      content: text,
      playerInGameId: playerInGameId,
      factionsInConversation: factionsInConversation,
    };
  }

  static getFactionToList(chatItem) {
    if (
      !chatItem.factionsInConversation ||
      chatItem.factionsInConversation.length === 0
    ) {
      return [];
    }

    /*console.log("debug chatItem : ", chatItem);
    console.log("debug playerInGameId", chatItem.playerInGameId);*/

    const playerData = StaticGameData.getPlayerData();
    const senderPlayerData = PlayerData.getPlayerDataFromInGameId(
      playerData,
      4
    );
    const senderFaction = senderPlayerData.faction.name;
    return chatItem.factionsInConversation.filter(
      (faction) => faction !== senderFaction
    );
  }

  static getAllTags(chatItem) {
    const tagList = ChatTag.getAllTagsFromString(chatItem.content);
    return tagList;
  }

  static getTagIncludingSenderFaction(chatItem) {
    const tagList = ChatItem.getAllTags(chatItem);

    //Add sender faction
    const playerData = StaticGameData.getPlayerData();
    const senderPlayerData = PlayerData.getPlayerDataFromInGameId(
      playerData,
      chatItem.playerInGameId
    );
    if (senderPlayerData.faction) {
      tagList.push(":" + senderPlayerData.faction.name + ":");
    }
    return tagList;
  }
}

module.exports = ChatItem;
