import React, { useState } from "react";
import "../CSS/Font.css";
import "../CSS/Menu.css";
import BaseButton from "./Utils/BaseButton";
import LogBookView from "./Utils/LogMessage/LogBookView";
import Color from "../Common/Config/Colors";
import DraftData from "../Data/GameData/Faction/DraftData";
import PlayerData from "../Data/GameData/PlayerData/PlayerData";
import LogBook from "../Data/GameData/Connection/LogBook";

const Popup = require("../Data/Other/Popup");
const StaticGameData = require("../Data/GameData/StaticGameData");

const TutorialTaskBarView = ({ taskBarData, elementStyle = {} }) => {
  //console.log("TaskBarView setInGame : ", StaticGameData.setInGame);

  //normal behaviour

  if (!taskBarData) {
    return;
  }

  if (
    !taskBarData.text &&
    !taskBarData.displayText &&
    !taskBarData.generateLogBook &&
    !taskBarData.content
  ) {
    return null;
  }

  const text = taskBarData.text;
  const logBook = taskBarData.logBook;
  const content = taskBarData.content;
  const generateLogBook = taskBarData.generateLogBook; // [text;itemArray]
  const nextButton = taskBarData.nextButton;

  return (
    <div
      className="div-fill"
      style={{ width: "99vw" }}

      /*style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        backgroundColor: "rgb(54, 54, 54)",
        zIndex: 1000,
        width: "100vw",
        height: "auto",
        paddingTop: paddingTopBottom,
        paddingBottom: paddingTopBottom,
        fontSize: "2vh",
      }}*/
    >
      <div
        style={{
          margin: "5px",
          padding: "5px",
          backgroundColor: "RGB(255,255,255,0.85)",
          color: "black",
          //border: "1px solid black",
          borderRadius: "10px",
        }}
      >
        {/* Your task content here */}
        {text && (
          <div style={elementStyle}>
            <span>{text}</span>
          </div>
        )}
        {content && <div style={elementStyle}>{content}</div>}
        {generateLogBook && (
          <div style={elementStyle}>
            <LogBookView
              logBook={LogBook.generateLogBook(
                generateLogBook[0],
                generateLogBook[1]
              )}
              textAlign="center"
            ></LogBookView>
          </div>
        )}
        <div className="div-fill">
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {nextButton && (
              <div style={elementStyle}>
                <BaseButton onClick={() => {}}> Next </BaseButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialTaskBarView;
