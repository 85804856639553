import React, { useState, useEffect } from "react";
import { GameListView } from "../GameList/GameList";
import TestSimpleComponent from "../Test/TestSimpleComponent";
import StaticGameData from "../Data/GameData/StaticGameData";
import GameGlobalLayout from "../GameView/GameGlobalLayout";
import NavigationView from "../GameView/NavigationView";
import MenuNavigationView from "./MenuNavigationView";
import BaseButton from "../GameView/Utils/BaseButton";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import Popup from "../Data/Other/Popup";
import ResetPasswordFromGame from "../login/ResetPasswordFromGame";
import Request, { callPostAPI } from "../Common/Requests/Request";
import EloRanking from "../Data/GameData/Objectifs/EloRanking";
import User from "../Data/UserData/User";
import EndGameRanking from "../Data/GameData/Objectifs/EndGameRanking";
import FactionLogo from "../GameView/Utils/FactionLogo";
import Color from "../Common/Config/Colors";
const Game = require("../Data/GameData/Game/Game");

export const StatisticView = ({ user }) => {
  const [gameList, setGameList] = useState(null);
  const [factionStatArray, setFactionStatArray] = useState(null);

  // Function to fetch user data
  const fetchGames = async () => {
    try {
      const gameListNotFiltered = await Request.getListGamesFromPlayer(
        "AllYourGames",
        setGameList
      );
    } catch (error) {
      console.error("Error fetching list of games:", error);
    }
  };

  // Use effect to call fetchUser when the component mounts
  useEffect(() => {
    fetchGames();
  }, []);

  useEffect(() => {
    if (!gameList) {
      return;
    }
    console.log("gameList: ", gameList);
    const filteredGameList = gameList.filter((game) => Game.isFinished(game));

    //Stats calculation
    const factionStatArray = EloRanking.createArrayStatsPerFaction();
    for (let i = 0; i < filteredGameList.length; i++) {
      const game = filteredGameList[i];
      EloRanking.addStatOfGameToArray(
        factionStatArray,
        game,
        User.getUserName(user)
      );
    }
    EloRanking.finalizeStatsArray(factionStatArray);
    setFactionStatArray(factionStatArray);
  }, [gameList]);

  const DisplayFactionStat = ({ factionStat }) => {
    return (
      <div>
        <div style={{ backgroundColor: Color.COLOR_GREY_3, padding: "0.2em" }}>
          {" "}
          <div>
            <FactionLogo factionName={factionStat.faction}></FactionLogo>{" "}
            {factionStat.faction}
          </div>
          <LineSpace></LineSpace>
          <div style={{ color: "gold" }}>
            <div>Ranked Games : {factionStat.amountGamesRanked}</div>
            <div>
              Performance :{" "}
              {factionStat.averageRankedPerformance
                ? factionStat.averageRankedPerformance + "%"
                : "?"}
            </div>
          </div>
          <LineSpace></LineSpace>
          <div style={{ color: Color.COLOR_CYAN }}>
            {" "}
            <div>Custom Games : {factionStat.amountGamesCustom}</div>
            <div>
              Performance :{" "}
              {factionStat.averageCustomPerformance
                ? factionStat.averageCustomPerformance + "%"
                : "?"}
            </div>
          </div>
        </div>
        <div>
          <LineSpace></LineSpace>
        </div>
      </div>
    );
  };

  console.log("factionStatArray: ", factionStatArray);

  const navigationData = {
    buttonsData: [
      {
        text: "Back",
        callback: () => Popup.goBack(),
      },
    ],
  };

  return (
    <GameGlobalLayout showTitle={false} backgroundType="uniform">
      <div style={{}}>
        <h1>{User.getUserName(user)}</h1>
        <div>
          {factionStatArray &&
            Object.keys(factionStatArray) // Get all keys from the object
              .sort() // Sort keys alphabetically
              .map((key) => (
                <DisplayFactionStat
                  key={key} // React key for the list item
                  factionStat={factionStatArray[key]} // Access the factionStat by key
                />
              ))}
        </div>
      </div>
      <MenuNavigationView navigationData={navigationData} />
    </GameGlobalLayout>
  );
};

export default StatisticView;
