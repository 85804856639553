const SpaceObject = require("../MapData/SpaceObject");
const System = require("../MapData/System");
const Color = require("../../../Common/Config/Colors");
const Fleet = require("../MapData/Fleet");
const Unit = require("../MapData/Unit");
const Planet = require("../MapData/Planet");
const ImagesUrl = require("../../Other/ImagesUrl");
const Rules = require("../Game/Rules");
const Item = require("../Transactions/Item");
const AbilityCard = require("../Ability/AbilityCard");
const FactionAbility = require("../Faction/FactionAbility");
const PlanetBonus = require("../Bonus/PlanetBonus");
const HeroNames = require("../MinorFaction/HeroNames");
const CustomMath = require("../../../Common/Math/CustomMath");
const TechList = require("../Technology/TechList");
const TechTree = require("../Technology/TechTree");

class Faction {
  static FACTION_NAME_ARBOREC = "The Arborec";
  static FACTION_NAME_LETNEV = "The Barony of Letnev";
  static FACTION_NAME_SAAR = "The Clan of Saar";
  static FACTION_NAME_MUAAT = "The Embers of Muaat";
  static FACTION_NAME_HACAN = "The Emirates of Hacan";
  static FACTION_NAME_SOL = "The Federation of Sol";
  static FACTION_NAME_CREUSS = "The Ghosts of Creuss";
  static FACTION_NAME_L1Z1X = "The L1Z1X Mindnet";
  static FACTION_NAME_MENTAK = "The Mentak Coalition";
  static FACTION_NAME_NAALU = "The Naalu Collective";
  static FACTION_NAME_NEKRO = "The Nekro Virus";
  static FACTION_NAME_SARDAKK = "Sardakk N’orr";
  static FACTION_NAME_JOL = "The Universities of Jol-Nar";
  static FACTION_NAME_WINNU = "The Winnu";
  static FACTION_NAME_XXCHA = "The Xxcha Kingdom";
  static FACTION_NAME_YIN = "The Yin Brotherhood";
  static FACTION_NAME_YSSARIL = "The Yssaril Tribes";
  static FACTION_NAME_ARGENT = "The Argent Flight";
  static FACTION_NAME_EMPYREAN = "The Empyrean";
  static FACTION_NAME_MAHACT = "The Mahact Gene-Sorcerers";
  static FACTION_NAME_NAAZ = "The Naaz-Rokha Alliance";
  static FACTION_NAME_NOMAD = "The Nomad";
  static FACTION_NAME_TITANS = "The Titans of Ul";
  static FACTION_NAME_CABAL = "The Vuil'Raith Cabal";

  //MinorFactions
  static MINOR_FACTION_NAME_KOHOR = "(m) Kohor, the merchants";
  static MINOR_FACTION_NAME_BOROUl = "(m) Boroul, the pirates";
  static NAME_E_AETHERION = "Exiled Aetherion";
  static NAME_E_ANDAR = "Exiled Andar";
  static NAME_E_BOREALIS = "Exiled Borealis";
  static NAME_E_CALVARIA = "Exiled Calvaria";
  static NAME_E_DELPHAS = "Exiled Elphas";
  static NAME_E_DRAKOR = "Exiled Drakor";
  static NAME_E_ERINYS = "Exiled Erinys";
  static NAME_E_EROSIUM = "Exiled Erosium";
  static NAME_E_FELTOR = "Exiled Feltor";
  static NAME_E_GHELOR = "Exiled Ghelor";
  static NAME_E_HELKARAN = "Exiled Helkaran";
  static NAME_E_ICANTA = "Exiled Icanta";
  static NAME_E_JELORIA = "Exiled Jeloria";
  static NAME_E_KORATH = "Exiled Korath";
  static NAME_E_KOBAMDA = "Exiled Quobamba"; // TO correct
  static NAME_E_LARAN = "Exiled Laran";
  static NAME_E_MALTRION = "Exiled Maltrion";
  static NAME_E_MINARI = "Exiled Minari";
  static NAME_E_OPHARIAN = "Exiled Opharian";
  static NAME_E_PROTHIAN = "Exiled Prothian";
  static NAME_E_QUORIDIOM = "Exiled Quoridiom";
  static NAME_E_RYNZORATH = "Exiled Rynzorath";
  static NAME_E_SELTAAR = "Exiled Seltaar";
  static NAME_E_TERRAN = "Exiled Terran";
  static NAME_E_TAARKIR = "Exiled Taarkir";
  static NAME_E_UTARION = "Exiled Utarion";
  static NAME_E_VOIDBORN = "Exiled Voidborn";
  static NAME_E_WOLTHAAR = "Exiled Wolthaar";
  static NAME_E_XELPHAR = "Exiled Xelphar";
  static NAME_E_XENOS = "Exiled Xenos";
  static NAME_E_ZYLORIAN = "Exiled Zylorian";

  //OLD Game Real Factions
  static NAME_ETHERAL = "The Etherals";
  static NAME_ROCKS = "The Rocks";

  //Game Real Factions
  static CONCORDANT_ORDER = "The Concordant Order";
  static VAL_AR_SKYBORNE = "The Vahl'ar Skyborne";
  static LITHORIAN_COLLECTIVE = "The Lithorian Collective";
  static ASHEN_COHORT = "The Ashen Cohort";
  static DOMINUS_LEGION = "The Dominus Legion";
  static ETHERAL_SPREAD = "The Etheral Spread";
  static ASTHARAN_TRADE_GUILD = "The Astharan Trade Guild";

  static createRelic(factionName, id, relicName, data = {}) {
    return Item.createRelicWithForcedId(
      factionName + "_" + id,
      relicName,
      factionName,
      data
    );
  }

  static factions = [
    //vahl'ar skyborne
    {
      name: Faction.VAL_AR_SKYBORNE,
      dedicatedColor: Color.COLOR_VAL_AR_SKYBORNE,
      startingResources: this.createStartingResources({
        colonists: 1,
        credit: 2,
        mineral: 0,
        science: 2,
        energy: 4,
      }),
      startingItems: [
        this.createRelic(Faction.VAL_AR_SKYBORNE, 0, Item.NAME_PEACE_SPACE),
        this.createRelic(Faction.VAL_AR_SKYBORNE, 0, Item.NAME_PEACE_GROUND),
        this.createRelic(Faction.VAL_AR_SKYBORNE, 0, Item.NAME_TRADE, {
          value: 2,
        }),
        this.createRelic(Faction.VAL_AR_SKYBORNE, 0, Item.NAME_POLITIC, {
          value: 8,
        }),
        this.createRelic(Faction.VAL_AR_SKYBORNE, 0, Item.NAME_PROPULSION_AID),
      ],
      startingUnits: [
        Unit.UNIT_TYPE_CARRIER,
        Unit.UNIT_TYPE_DESTROYER,
        Unit.UNIT_TYPE_FIGHTER,
        Unit.UNIT_TYPE_FIGHTER,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_FACTORY,
      ],
      abilities: [
        AbilityCard.create(FactionAbility.VAHL_AR_SKYBORNE_GRAVITY_SAILS),
        AbilityCard.create(FactionAbility.VAHL_AR_SKYBORNE_HYPER_TIDE_DRIVES),
        AbilityCard.create(
          FactionAbility.VAHL_AR_SKYBORNE_VOIDSTREAM_PROPULSION
        ),
        AbilityCard.create(FactionAbility.GLOBAL_HYPER_POWERED),
      ],
      homeSystemName: "Aureion",
      homePlanetData: {
        type: "Ice",
        name: "Cryosyne",
        bonusList: [PlanetBonus.NAME_ENERGY],
      },
      startingTechnologies: [TechList.TECH.massDeflector.name],
      hero: "to be defined",
    },
    //astharan trade guild
    {
      name: Faction.ASTHARAN_TRADE_GUILD,
      dedicatedColor: Color.COLOR_ASTHARAN_TRADE_GUILD,
      startingResources: this.createStartingResources({
        colonists: 1,
        credit: 4,
      }),
      startingUnits: [
        Unit.UNIT_TYPE_CARRIER,
        Unit.UNIT_TYPE_CARRIER,
        Unit.UNIT_TYPE_FIGHTER,
        Unit.UNIT_TYPE_DESTROYER,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_FACTORY,
      ],
      startingItems: [
        this.createRelic(
          Faction.ASTHARAN_TRADE_GUILD,
          0,
          Item.NAME_PEACE_SPACE
        ),
        this.createRelic(
          Faction.ASTHARAN_TRADE_GUILD,
          0,
          Item.NAME_PEACE_GROUND
        ),
        this.createRelic(Faction.ASTHARAN_TRADE_GUILD, 0, Item.NAME_TRADE, {
          value: 2,
        }),
        this.createRelic(Faction.ASTHARAN_TRADE_GUILD, 0, Item.NAME_TRADE, {
          value: 1,
        }),
        this.createRelic(Faction.ASTHARAN_TRADE_GUILD, 0, Item.NAME_TRADE, {
          value: 1,
        }),
        this.createRelic(Faction.ASTHARAN_TRADE_GUILD, 0, Item.NAME_POLITIC, {
          value: 6,
        }),
        /* this.createRelic(
          Faction.ASTHARAN_TRADE_GUILD,
          6,
          Item.NAME_MARKET_ISOLATION
        ),*/
      ],
      abilities: [
        //AbilityCard.create(FactionAbility.ASTHARAN_TRADE_GUILD_MASTER_TRADER),
        AbilityCard.create(FactionAbility.GLOBAL_VERY_RICH),

        //AbilityCard.create(FactionAbility.ETHERAL_ETHERAL_HERO_FORM),
      ],
      homeSystemName: "Merchantris ",
      homePlanetData: { type: "Terran", name: "Tradeora" },
      startingTechnologies: [TechList.TECH.ecoHarvestFarming.name],
      hero: "to be defined",
    },
    //ashen cohort
    {
      name: Faction.ASHEN_COHORT,
      dedicatedColor: Color.COLOR_ASHEN_COHORT,
      startingResources: this.createStartingResources({
        colonists: 1,
        credit: 2,
      }),
      startingUnits: [
        Unit.UNIT_TYPE_CARRIER,
        Unit.UNIT_TYPE_FIGHTER,
        Unit.UNIT_TYPE_FIGHTER,
        Unit.UNIT_TYPE_CRUSER,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_FACTORY,
      ],
      startingItems: [
        this.createRelic(Faction.ASHEN_COHORT, 0, Item.NAME_PEACE_SPACE),
        this.createRelic(Faction.ASHEN_COHORT, 0, Item.NAME_PEACE_GROUND),
        this.createRelic(Faction.ASHEN_COHORT, 0, Item.NAME_TRADE, {
          value: 1,
        }),
        this.createRelic(Faction.ASHEN_COHORT, 0, Item.NAME_POLITIC, {
          value: 6,
        }),
        this.createRelic(Faction.ASHEN_COHORT, 0, Item.NAME_MOTIVATION),
      ],
      abilities: [
        AbilityCard.create(FactionAbility.GLOBAL_BIG),
        AbilityCard.create(FactionAbility.GLOBAL_CARRIER_EXTRA_CAPACITY),
        AbilityCard.create(FactionAbility.GLOBAL_RESISTANT),
        AbilityCard.create(FactionAbility.GLOBAL_FRAGILE_WAR_SHIPS),
        AbilityCard.create(FactionAbility.GLOBAL_FORTIFICATIONS),
        AbilityCard.create(
          FactionAbility.ASHEN_COHORT_PLANETARY_ASSAULT_PLATFORM
        ),

        //AbilityCard.create(FactionAbility.ETHERAL_ETHERAL_HERO_FORM),
      ],

      homeSystemName: "Ashen",
      homePlanetData: {
        type: "Ice",
        name: "Scoria Prime",
        bonusList: [PlanetBonus.NAME_ENERGY, PlanetBonus.NAME_POPULATION],
      },
      startingTechnologies: [TechList.TECH.cryoHarvestArrays.name],
      hero: "to be defined",
    },
    //dominus legion
    {
      name: Faction.DOMINUS_LEGION,
      dedicatedColor: Color.COLOR_DOMINUS_LEGION,
      startingResources: this.createStartingResources({
        colonists: 1,
        credit: 1,
      }),
      startingUnits: [
        Unit.UNIT_TYPE_CARRIER,
        Unit.UNIT_TYPE_DREADNOUGH,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_FACTORY,
      ],
      startingItems: [
        this.createRelic(Faction.DOMINUS_LEGION, 0, Item.NAME_PEACE_SPACE),
        this.createRelic(Faction.DOMINUS_LEGION, 0, Item.NAME_PEACE_GROUND),
        this.createRelic(Faction.DOMINUS_LEGION, 0, Item.NAME_TRADE, {
          value: 1,
        }),
        this.createRelic(Faction.DOMINUS_LEGION, 0, Item.NAME_POLITIC, {
          value: 8,
        }),
        this.createRelic(
          Faction.DOMINUS_LEGION,
          0,
          Item.NAME_DREADNOUGHT_DEPLOYMENT
        ),
      ],
      abilities: [
        AbilityCard.create(FactionAbility.DOMINUS_LEGION_PREEMPTIVE_BARRAGE),
        AbilityCard.create(FactionAbility.DOMINUS_SIEGE_WARFARE_EXPERTISE),
        AbilityCard.create(FactionAbility.GLOBAL_RESISTANT_WAR_SHIPS),
        AbilityCard.create(FactionAbility.GLOBAL_TITAN_FORGE_AFFINITY),
      ],
      homeSystemName: "Dominion",
      homePlanetData: {
        type: "Oceanic",
        name: "Blazemount",
        bonusList: [PlanetBonus.NAME_POPULATION, PlanetBonus.NAME_MINERAL],
      },
      startingTechnologies: [TechList.TECH.aquafustionReactors.name],
      hero: "to be defined",
    },
    //Lithorian collective
    {
      name: Faction.LITHORIAN_COLLECTIVE,
      dedicatedColor: Color.COLOR_LITHORIAN_COLLECTIVE,
      startingResources: this.createStartingResources({
        colonists: 1,
        credit: 2,
      }),
      startingUnits: [
        Unit.UNIT_TYPE_CARRIER,
        Unit.UNIT_TYPE_DESTROYER,
        Unit.UNIT_TYPE_DESTROYER,
        Unit.UNIT_TYPE_FIGHTER,
        Unit.UNIT_TYPE_FIGHTER,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_FACTORY,
      ],
      startingItems: [
        this.createRelic(
          Faction.LITHORIAN_COLLECTIVE,
          0,
          Item.NAME_PEACE_SPACE
        ),
        this.createRelic(
          Faction.LITHORIAN_COLLECTIVE,
          0,
          Item.NAME_PEACE_GROUND
        ),
        this.createRelic(Faction.LITHORIAN_COLLECTIVE, 0, Item.NAME_TRADE, {
          value: 1,
        }),
        this.createRelic(Faction.LITHORIAN_COLLECTIVE, 0, Item.NAME_POLITIC, {
          value: 6,
        }),
        this.createRelic(
          Faction.LITHORIAN_COLLECTIVE,
          0,
          Item.NAME_GEO_FACTORY_ASSEMBLER
        ),
      ],
      abilities: [
        AbilityCard.create(FactionAbility.LITHORIAN_COLLECTIVE_MINERAL_SENSOR),
        AbilityCard.create(
          FactionAbility.LITHORIAN_COLLECTIVE_NATURAL_ROCK_FACTORIES
        ),
        AbilityCard.create(FactionAbility.LITHORIAN_COLLECTIVE_STONE_FORM),
        AbilityCard.create(FactionAbility.GLOBAL_BIG),
        AbilityCard.create(FactionAbility.GLOBAL_INDUSTRY_FOCUSED),
        AbilityCard.create(FactionAbility.GLOBAL_STRONG_SHIPS),
        AbilityCard.create(FactionAbility.GLOBAL_RESISTANT_SHIPS),
      ],
      homeSystemName: "Kragos",
      homePlanetData: { type: "Desert", name: "Obsidrax" },
      startingTechnologies: [TechList.TECH.cryoHarvestArrays.name],
      hero: "to be defined",
    },
    //Etheral Spread
    {
      name: Faction.ETHERAL_SPREAD,
      dedicatedColor: Color.COLOR_ETHERAL_SPREAD,
      startingResources: this.createStartingResources({
        colonists: 1,
        credit: 2,
      }),
      startingUnits: [
        Unit.UNIT_TYPE_CARRIER,
        Unit.UNIT_TYPE_FIGHTER,
        Unit.UNIT_TYPE_DESTROYER,
        Unit.UNIT_TYPE_CRUSER,
        Unit.UNIT_TYPE_FIGHTER,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_FACTORY,
      ],
      startingItems: [
        this.createRelic(Faction.ETHERAL_SPREAD, 0, Item.NAME_PEACE_SPACE),
        this.createRelic(Faction.ETHERAL_SPREAD, 0, Item.NAME_PEACE_GROUND),
        this.createRelic(Faction.ETHERAL_SPREAD, 0, Item.NAME_TRADE, {
          value: 2,
        }),
        this.createRelic(Faction.ETHERAL_SPREAD, 0, Item.NAME_POLITIC, {
          value: 5,
        }),
        this.createRelic(
          Faction.ETHERAL_SPREAD,
          0,
          Item.NAME_ETHERAL_CONDENSATOR
        ),
      ],
      abilities: [
        AbilityCard.create(FactionAbility.ETHERAL_SPREAD_ETHERAL_FORM),
        AbilityCard.create(FactionAbility.ETHERAL_SPREAD_ETHERAL_EMBUSK),
        AbilityCard.create(FactionAbility.ETHERAL_SPREAD_ETHERAL_CONNECTION),
        //AbilityCard.create(FactionAbility.ETHERAL_ETHERAL_HERO_FORM),
      ],
      homeSystemName: "Astral Expanse",
      homePlanetData: { type: "Gaz", name: "Lumivan" },
      startingTechnologies: [TechList.TECH.voltCores.name],
      hero: "to be defined",
    },
    //CONCORDANT_ORDER
    {
      name: Faction.CONCORDANT_ORDER,
      dedicatedColor: Color.COLOR_CONCORDANT_ORDER,
      startingResources: this.createStartingResources({
        colonists: 2,
        credit: 3,
      }),
      startingUnits: [
        Unit.UNIT_TYPE_CARRIER,
        Unit.UNIT_TYPE_CARRIER,
        Unit.UNIT_TYPE_FIGHTER,
        Unit.UNIT_TYPE_FIGHTER,
        Unit.UNIT_TYPE_FIGHTER,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_INFANTRY,
        Unit.UNIT_TYPE_FACTORY,
      ],
      startingItems: [
        this.createRelic(Faction.CONCORDANT_ORDER, 0, Item.NAME_PEACE_SPACE),
        this.createRelic(Faction.CONCORDANT_ORDER, 0, Item.NAME_PEACE_GROUND),
        this.createRelic(Faction.CONCORDANT_ORDER, 0, Item.NAME_TRADE, {
          value: 2,
        }),
        this.createRelic(Faction.CONCORDANT_ORDER, 0, Item.NAME_POLITIC, {
          value: 8,
        }),
        this.createRelic(
          Faction.CONCORDANT_ORDER,
          0,
          Item.NAME_CULTURAL_RENAISSANCE
        ),
      ],
      abilities: [
        AbilityCard.create(FactionAbility.CONCORDANT_ORDER_RESPECTED),
        //AbilityCard.create(FactionAbility.CONCORDANT_ORDER_SUBVERSION_PROTOCOL),
        AbilityCard.create(FactionAbility.CONCORDANT_ORDER_VEIL_OF_IMMUNITY),
      ],
      homeSystemName: "Celestara",
      homePlanetData: { type: "Terran", name: "Calladria" },
      startingTechnologies: [TechList.TECH.ecoHarvestFarming.name],
      hero: "to be defined",
    },
  ];

  static createHomePlanet(faction) {
    const planet = SpaceObject.createPlanet(faction.homePlanetData.type);
    planet.name = faction.homePlanetData.name;
    planet.faction = faction.name;
    PlanetBonus.resetBonus(planet);
    if (faction.homePlanetData.bonusList) {
      for (let i = 0; i < faction.homePlanetData.bonusList.length; i++) {
        PlanetBonus.addSpecificBonus(
          planet,
          faction.homePlanetData.bonusList[i]
        );
      }
    }

    return planet;
  }

  static createFaction(factionName) {
    const faction = Faction.factions.find((faction) => {
      return faction.name === factionName;
    });

    const factionCopy = JSON.parse(JSON.stringify(faction));
    this.enrichFaction(factionCopy);

    if (factionCopy) {
      return factionCopy;
    } else {
      throw new Error("Faction not found");
    }
  }

  static createStartingResources({
    mineral = 0,
    energy = 4,
    science = 0,
    population = 4,
    credit = 2,
    colonists = 2,
  }) {
    return {
      mineral: mineral,
      energy: energy,
      science: science,
      population: population,
      credit: credit,
      colonists: colonists,
    };
  }

  static enrichPlayerDataByFactionCreate(playerData) {
    playerData.colonists = playerData.faction.startingResources.colonists;
    playerData.credit = playerData.faction.startingResources.credit;
    playerData.energy = playerData.faction.startingResources.energy;
    playerData.mineral = playerData.faction.startingResources.mineral;
    playerData.science = playerData.faction.startingResources.science;
    playerData.population = playerData.faction.startingResources.population;

    //Starting techs
    if (playerData.faction.startingTechnologies) {
      for (let i = 0; i < playerData.faction.startingTechnologies.length; i++) {
        TechTree.gainTech(
          playerData,
          playerData.faction.startingTechnologies[i]
        );
      }
    }

    //StartingItems
    if (playerData.faction.startingItems) {
      for (let i = 0; i < playerData.faction.startingItems.length; i++) {
        Item.setId(
          playerData.faction.startingItems[i],
          playerData.faction.name + "_" + i
        );
        Item.addItemToArray(
          playerData.faction.startingItems[i],
          playerData.items
        );
      }
    }
  }

  static fillEmptyHSWithPlayerFaction(playerData, faction, system) {
    //Make it a real home system
    system.isFactionDraftSystem = false;
    system.factionsToPickFrom = [];
    system.faction = faction.name;
    system.isHomeSystem = true;

    const factionPlanet = Faction.createHomePlanet(faction);
    const factionFleet = Fleet.createFleet(playerData, faction.name);
    const planetFleet = Fleet.createFleet(playerData, faction.name);

    const unitsTypeList = faction.startingUnits;
    for (let i = 0; i < unitsTypeList.length; i++) {
      const unitType = unitsTypeList[i];
      const unit = Unit.createUnit(playerData, faction.name, unitType);
      if (unit.class === Unit.CLASS_SHIP) {
        Fleet.addUnit(factionFleet, unit);
      } else {
        Fleet.addUnit(planetFleet, unit);
      }
    }

    System.addFleetToSystem(system, factionFleet);
    Planet.addFleetToPlanet(factionPlanet, planetFleet);

    //Planet
    const objects = System.getSpaceObjects(system);
    for (let i = 0; i < objects.length; i++) {
      if (objects[i].type === "void") {
        objects[i] = factionPlanet;
        break;
      }
    }
    CustomMath.shuffleArray(objects);

    system.name = faction.homeSystemName;
    system.faction = faction.name;
    System.renameObjectsInSystem(system);

    //Background Object
    system.backgroundObject = System.BACK_NONE;

    this.enrichPlayerDataByFactionCreate(playerData);
  }

  static createHomeSystem(playerData, faction, x, y, previousSystem) {
    let homeSystem = System.createEmptySystem(x, y);
    this.fillEmptyHSWithPlayerFaction(playerData, faction, homeSystem);
    return homeSystem;
    /*switch (factionName) {
      default:
        homeSystem = System.createHomeSystem(
          x,
          y,
          [
            { isPlanet: true, type: "Terran" },
            { isPlanet: false, type: "void" },
          ],
          factionName
        );
        //planet Naalu 1
        let object = System.getSpaceObjects(homeSystem)[0];
        Planet.addPopulation(object, 5);
        const fleetPlanet_1 = Fleet.createFleet(playerData, factionName);
        Fleet.createUnit(
          playerData,
          fleetPlanet_1,
          Unit.UNIT_TYPE_INFANTRY,
          ""
        );
        Fleet.createUnit(
          playerData,
          fleetPlanet_1,
          Unit.UNIT_TYPE_INFANTRY,
          ""
        );
        Fleet.createUnit(
          playerData,
          fleetPlanet_1,
          Unit.UNIT_TYPE_INFANTRY,
          ""
        );
        Fleet.createUnit(
          playerData,
          fleetPlanet_1,
          Unit.UNIT_TYPE_INFANTRY,
          ""
        );

        Planet.addFleetToPlanet(object, fleetPlanet_1);

        //planet Naalu 2
        /*object = System.getSpaceObjects(homeSystem)[1];
        Planet.addPopulation(object, 3);
        const fleetPlanet_2 = Fleet.createFleet(playerData, factionName);
        Fleet.createUnit(
          playerData,
          fleetPlanet_2,
          Unit.UNIT_TYPE_INFANTRY,
          ""
        );
        Fleet.createUnit(
          playerData,
          fleetPlanet_2,
          Unit.UNIT_TYPE_INFANTRY,
          ""
        );

        Planet.addFleetToPlanet(object, fleetPlanet_2);*/

    //Fleet
    /*const fleet = Fleet.createFleet(playerData, factionName);
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_CRUSER, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_CARRIER, "");
        System.addFleetToSystem(homeSystem, fleet);*/

    //TEST
    /*const fleetTest = Fleet.createFleet(playerData, factionName);
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_CARRIER, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_DREADNOUGH, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_INFANTRY, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_INFANTRY, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_INFANTRY, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_FIGHTER, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_FIGHTER, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_FIGHTER, "");
        System.addFleetToSystem(homeSystem, fleetTest);
        playerData.cargo = 20;
        playerData.science = 100;
        playerData.population = 50;
        playerData.energy = 50;
        playerData.mineral = 50;*/

    //Test items of exiled
    /*const items = playerData.items;

        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_CALVARIA_SCROLL),
          playerData.items
        );
        Item.exhaustItem(items[items.length - 1]);
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_DELPHAS_SCROLL),
          items
        );
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_ICANTA_SCROLL),
          items
        );
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_JELORIA_SCROLL),
          items
        );
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_KOBAMDA_SCROLL),
          items
        );
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_MALTRION_SCROLL),
          items
        );
        Item.exhaustItem(items[items.length - 1]);
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_QUORIDIOM_SCROLL),
          items
        );
        Item.exhaustItem(items[items.length - 1]);
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_RYNZORATH_SCROLL),
          items
        );
        Item.exhaustItem(items[items.length - 1]);
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_SELTAAR_SCROLL),
          items
        );
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_TAARKIR_SCROLL),
          items
        );
        Item.exhaustItem(items[items.length - 1]);
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_VOIDBORN_SCROLL),
          items
        );}*/

    /*homeSystem.name = factionName;
    System.renameObjectsInSystem(homeSystem);

    //Background Object
    homeSystem.backgroundObject = System.BACK_NONE;

    //Get the connection of the system it is replacing.
    homeSystem.mapConnection = previousSystem.mapConnection;

    //Initialize Relics and Artifacts
    const items = playerData.items;
    Item.addItemToArray(
      Item.createRelic(playerData, Item.NAME_TRADE, playerData.faction.name, {
        value: 1,
      }),
      items
    );
    Item.addItemToArray(
      Item.createRelic(
        playerData,
        Item.NAME_PEACE_SPACE,
        playerData.faction.name
      ),
      items
    );
    Item.addItemToArray(
      Item.createRelic(
        playerData,
        Item.NAME_PEACE_GROUND,
        playerData.faction.name
      ),
      items
    );

    return homeSystem;*/
  }

  static getAbilityCards(faction) {
    return faction.abilities;
  }

  static enrichFaction(faction) {
    if (!faction.startingCargo) faction.startingCargo = 3;
  }

  static createRandomFactions(amountFaction) {
    const factions = Faction.factions.slice();
    const randomFactions = [];
    for (let i = 0; i < amountFaction; i++) {
      const randomIndex = Math.floor(Math.random() * factions.length);
      const factionCopy = JSON.parse(JSON.stringify(factions[randomIndex]));
      this.enrichFaction(factionCopy);
      randomFactions.push(factionCopy);
      factions.splice(randomIndex, 1);
    }
    return randomFactions;
  }

  static getEnergyProduction(faction) {
    if (faction.energyProduction) {
      return faction.energyProduction;
    } else {
      return Rules.ENERGY_PRODUCTION;
    }
  }

  static getImagePopulationFromFaction(factionName) {
    return ImagesUrl.URL_POPULATION;
  }

  static getLogoImageFromFaction(factionName) {
    if (factionName === Faction.CONCORDANT_ORDER) {
      return ImagesUrl.URL_FACTION_CONCORDANT_ORDER;
    }
    if (factionName === Faction.VAL_AR_SKYBORNE) {
      return ImagesUrl.URL_FACTION_VAL_AR_SKYBORNE;
    }
    if (factionName === Faction.LITHORIAN_COLLECTIVE) {
      return ImagesUrl.URL_FACTION_LITHORIAN_COLLECTIVE;
    }
    if (factionName === Faction.ASHEN_COHORT) {
      return ImagesUrl.URL_FACTION_ASHEN_COHORT;
    }

    if (factionName === Faction.DOMINUS_LEGION) {
      return ImagesUrl.URL_FACTION_DOMINUS_LEGION;
    }
    if (factionName === Faction.ETHERAL_SPREAD) {
      return ImagesUrl.URL_FACTION_ETHERAL_SPREAD;
    }
    if (factionName === Faction.ASTHARAN_TRADE_GUILD) {
      return ImagesUrl.URL_FACTION_ASTHARAN_TRADE_GUILD;
    }

    return ImagesUrl.URL_NO_IMAGE;
  }

  static getImageUrlFromFaction(factionName) {
    switch (factionName) {
      case Faction.FACTION_NAME_ARBOREC:
        return ImagesUrl.URL_FACTION_ARBOREC;
      case Faction.FACTION_NAME_LETNEV:
        return ImagesUrl.URL_FACTION_LETNEV;
      case Faction.FACTION_NAME_SAAR:
        return ImagesUrl.URL_FACTION_SAAR;
      case Faction.FACTION_NAME_MUAAT:
        return ImagesUrl.URL_FACTION_MUAAT;
      case Faction.FACTION_NAME_HACAN:
        return ImagesUrl.URL_FACTION_HACAN;
      case Faction.FACTION_NAME_SOL:
        return ImagesUrl.URL_FACTION_SOL;
      case Faction.FACTION_NAME_CREUSS:
        return ImagesUrl.URL_FACTION_CREUSS;
      case Faction.FACTION_NAME_L1Z1X:
        return ImagesUrl.URL_FACTION_L1Z1X;
      case Faction.FACTION_NAME_MENTAK:
        return ImagesUrl.URL_FACTION_MENTAK;
      case Faction.FACTION_NAME_NAALU:
        return ImagesUrl.URL_FACTION_NAALU;
      case Faction.FACTION_NAME_NEKRO:
        return ImagesUrl.URL_FACTION_NEKRO;
      case Faction.FACTION_NAME_SARDAKK:
        return ImagesUrl.URL_FACTION_SARDAKK;
      case Faction.FACTION_NAME_JOL:
        return ImagesUrl.URL_FACTION_JOL;
      case Faction.FACTION_NAME_WINNU:
        return ImagesUrl.URL_FACTION_WINNU;
      case Faction.FACTION_NAME_XXCHA:
        return ImagesUrl.URL_FACTION_XXCHA;
      case Faction.FACTION_NAME_YIN:
        return ImagesUrl.URL_FACTION_YIN;
      case Faction.FACTION_NAME_YSSARIL:
        return ImagesUrl.URL_FACTION_YSSARIL;
      case Faction.FACTION_NAME_ARGENT:
        return ImagesUrl.URL_FACTION_ARGENT;
      case Faction.FACTION_NAME_EMPYREAN:
        return ImagesUrl.URL_FACTION_EMPYREAN;
      case Faction.FACTION_NAME_MAHACT:
        return ImagesUrl.URL_FACTION_MAHACT;
      case Faction.FACTION_NAME_NAAZ:
        return ImagesUrl.URL_FACTION_NAAZ;
      case Faction.FACTION_NAME_NOMAD:
        return ImagesUrl.URL_FACTION_NOMAD;
      case Faction.FACTION_NAME_TITANS:
        return ImagesUrl.URL_FACTION_TITAN;
      case Faction.FACTION_NAME_CABAL:
        return ImagesUrl.URL_FACTION_CABAL;

      default:
        throw new Error("Faction image not found");
    }
  }

  static isMinorFaction(factionName) {
    return factionName.startsWith("(m)") || factionName.startsWith("Hero");
  }
}

module.exports = Faction;
