class Amount {
  constructor(amount) {
    this.amount = amount;
    this.modifiers = [];
  }

  static addModifier(amount, modifier, text) {
    amount.modifiers.push({ modifier, text });
  }

  static getTotalValue(amount) {
    let total = amount.amount;
    for (const modifier of amount.modifiers) {
      total = total + modifier.modifier;
    }
    return total;
  }

  static getBaseValue(amount) {
    return amount.amount;
  }

  static getTotalModifiers(amount) {
    let total = 0;
    for (const modifier of amount.modifiers) {
      total = total + modifier.modifier;
    }
    return total;
  }

  static addAmount(amount1, amount2) {
    const result = new Amount(amount1.amount + amount2.amount);
    result.modifiers = amount1.modifiers.concat(amount2.modifiers);
    return result;
  }
}

module.exports = Amount;
