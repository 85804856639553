//const { isExhausted } = require("./AbilityCommon");
const ActivePassiveAbility = require("./ActivePassiveAbility");

class AbilityCard {
  static TYPE_ABILITY = "Ability";
  static TYPE_EFFECT = "Effect";

  static create(name, type = this.TYPE_ABILITY) {
    const data = {
      name: name,
      isExhausted: false,
      type: type,
      effectActivatedList: [], //For abilities with conditional effects
    };
    return data;
  }

  static createDesc(abilityCard, activePassiveAbilityList) {
    const data = {
      name: abilityCard.name,
      type: abilityCard.type,
      abilities: activePassiveAbilityList,
      isExhausted: abilityCard.isExhausted,
    };
    return data;
  }

  static getName(abilityCard) {
    return abilityCard.name;
  }

  static getAbilitiesFromDesc(abilityCardDesc) {
    return abilityCardDesc.abilities;
  }

  static isExhausted(abilityCard) {
    return abilityCard.isExhausted;
  }

  static exhaust(abilityCard) {
    abilityCard.isExhausted = true;
  }

  static ready(abilityCard) {
    abilityCard.isExhausted = false;
  }

  static isActiveAbility(abilityCard) {
    return abilityCard.abilities.some((ability) =>
      ActivePassiveAbility.isActive(ability)
    );
  }

  static activateEffect(abilityCard, effectName) {
    abilityCard.effectActivatedList.push(effectName);
  }

  static deactivateEffect(abilityCard, effectName) {
    const index = abilityCard.effectActivatedList.indexOf(effectName);
    if (index !== -1) {
      abilityCard.effectActivatedList.splice(index, 1);
    }
  }

  static resetEffects(abilityCard) {
    abilityCard.effectActivatedList = [];
  }

  static isEffectActivated(abilityCard, effectName) {
    return abilityCard.effectActivatedList.includes(effectName);
  }
}

module.exports = AbilityCard;
