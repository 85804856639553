import React, { useEffect, useState } from "react";

const BaseImage = ({
  style,
  src,
  alt,
  onlyOneRes = true,
  width,
  height,
  maxWidth,
  forceLowRes = false,
}) => {
  // Remove ".png" and add "_sr.png"
  const lowResSrc = src.replace(".png", "_sr.png");
  const highResSrc = src;
  const [currentSrc, setCurrentSrc] = useState(
    onlyOneRes ? highResSrc : lowResSrc
  );

  useEffect(() => {
    // If forceLowRes is true, use the low-resolution image
    if (forceLowRes) {
      setCurrentSrc(lowResSrc);
      return;
    }

    // Otherwise, load the high-resolution image if onlyOneRes is false
    if (!onlyOneRes) {
      const img = new Image();
      img.src = highResSrc;
      img.onload = () => {
        // Update to the high-resolution image once it has loaded
        setCurrentSrc(highResSrc);
      };
    } else {
      setCurrentSrc(highResSrc);
    }
  }, [highResSrc, lowResSrc, forceLowRes, onlyOneRes]);

  const imageStyle = {
    ...style,
  };

  if (maxWidth) {
    imageStyle.maxWidth = maxWidth;
  }

  //console.log("currentSrc", currentSrc);

  return (
    <img
      style={imageStyle}
      src={currentSrc}
      alt={alt}
      width={width}
      height={height}
    />
  );
};

export default BaseImage;
