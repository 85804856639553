import React, { useState, useEffect } from "react";
import UIMessage from "../../../Data/GameData/Connection/UIMessage.js";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData.js";
import StaticGameData from "../../../Data/GameData/StaticGameData.js";
import ActivationData from "../../../Data/GameData/Activation/ActivationData.js";
import BaseButton from "../../Utils/BaseButton.jsx";
import FleetSummaryView from "../FleetSummaryView.jsx";
import UnitLineView from "../../Units/UnitLineView.jsx";
import UnitSelectionView from "../../Units/UnitSelectionView.jsx";
import SpaceObjectImageView from "../../Object/SpaceObjectImageView.jsx";
import Logo from "../../Utils/Logo.jsx";
import ActivationCA from "../../../ClientAction/ActivationCA.js";
import TransferData from "../../../Data/GameData/Activation/TransferData.js";
import ProductionActionData from "../../../Data/GameData/ActionData/ProductionActionData.js";
import PlanetView from "../../Object/PlanetView.jsx";
import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData.js";
import BuyActionData from "../../../Data/GameData/ActionData/BuyActionData.js";
import CostView from "../../Utils/CostView.jsx";
import Phase from "../../../Data/GameData/Game/Phase.js";
import ActionBuyView from "./ActionBuyView.jsx";
import BuildUnitActionData from "../../../Data/GameData/ActionData/BuildUnitActionData.js";
import ActionBuildUnitView from "./ActionBuildUnitView.jsx";
import InteractionFunctions from "../../UIInteraction/InteractionFunctions.jsx";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup.js");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const Planet = require("../../../Data/GameData/MapData/Planet.js");

class ActionBuild {
  static prepareUI(playerData) {
    Popup.setMapDisplayMode(Popup.MAP_DISPLAY_BUILD);
    const afterSelectingPlanet = (planet) => {
      if (planet.faction !== playerData.faction.name) {
        UIMessage.displayInfoMessage(
          "No Control",
          "You can't build units on a planet that is not controlled by your faction."
        );

        return;
      }

      BuildUnitActionData.prepare(
        StaticGameData.getPlayerData(), //This is important to get the new playerData from staticGameData, because after the first constrution the playerData object ref is updated
        planet
      );

      Popup.activateBlackPopup(<ActionBuildUnitView></ActionBuildUnitView>);
    };

    InteractionFunctions.selectAPlanet(
      afterSelectingPlanet,
      "Select a planet on which to build units.",
      null,
      () => {
        Popup.resetMapDisplayMode();
      },
      false
    );
  }

  static prepareOnAPlanet(playerData, planet) {
    BuildUnitActionData.prepare(playerData, planet);
    Popup.activateBlackPopup(<ActionBuildUnitView></ActionBuildUnitView>);
  }
}

export default ActionBuild;
