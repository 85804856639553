import AbilityCommon from "../../Data/GameData/Ability/AbilityCommon";
import ResolveFactionAbility from "../../Data/GameData/Ability/ResolveFactionAbility";
import ResolveMinorFactionAbility from "../../Data/GameData/Ability/ResolveMinorFactionAbility";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import FactionAbility from "../../Data/GameData/Faction/FactionAbility";
import Map from "../../Data/GameData/MapData/Map";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject";
import MinorFaction from "../../Data/GameData/MinorFaction/MinorFaction";
import Faction from "../../Data/GameData/PlayerData/Faction";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import PlanetView from "../Object/PlanetView";
import InteractionFunctions from "../UIInteraction/InteractionFunctions";
const TechList = require("../../Data/GameData/Technology/TechList");
const ResolveTechAbility = require("../../Data/GameData/Ability/ResolveTechAbility");

const {
  default: SelectionListView,
} = require("../Utils/Layout/SelectionListView");
const System = require("../../Data/GameData/MapData/System");

class FactionAbilityInput {
  static routeFactionAbilityInput(abilityCardName) {
    const playerData = StaticGameData.getPlayerData();

    const route = () => {
      switch (abilityCardName) {
        case FactionAbility.LITHORIAN_COLLECTIVE_NATURAL_ROCK_FACTORIES:
          this.rocksNaturalRockFactories(playerData, abilityCardName);
          break;

        case FactionAbility.ASHEN_COHORT_PLANETARY_ASSAULT_PLATFORM:
          this.ashenPlanetaryAssaultPlatform(playerData, abilityCardName);
          break;

        default:
          throw new Error("Faction ability name not found");
      }
    };

    UIMessage.displayConfirmMessage(
      abilityCardName,
      "Do you want to use your " + abilityCardName + " faction ability ?",
      route
    );
  }

  static rocksNaturalRockFactories = (playerData, abilityCardName) => {
    InteractionFunctions.selectAPlanet((planet) => {
      ResolveFactionAbility.sendToServerCommon(abilityCardName, {
        planetName: planet.name,
      });
      Popup.resetMapNavigationData();
    }, "Select a planet to place a factory on");
  };

  static ashenPlanetaryAssaultPlatform = (playerData, abilityCardName) => {
    InteractionFunctions.selectAPlanet((planet) => {
      ResolveFactionAbility.sendToServerCommon(abilityCardName, {
        planetName: planet.name,
      });
      Popup.resetMapNavigationData();
    });
  };
}

export default FactionAbilityInput;
