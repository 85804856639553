import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo";
import ScoreData from "../../Data/GameData/Objectifs/ScoreData";
import FactionSpan from "../Utils/FactionSpan";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import TransactionView from "./TransactionView";
import ProposeTransactionView from "./ProposeTransactionView";
import ActionPropose from "../ManageSystem/ActionView/ActionPropose";
import Transaction from "../../Data/GameData/Transactions/Transaction";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import Phase from "../../Data/GameData/Game/Phase";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");
const Request = require("../../Common/Requests/Request");

const TransactionListView = ({ transactionData, updateDataOnGameView }) => {
  const playerData = StaticGameData.getPlayerData();
  const color = PlayerData.getPlayerColor(playerData);
  const allPlayersData = PlayerData.getAllPlayersData(playerData);

  const navigationData = {
    buttonsData: [],
  };

  navigationData.buttonsData.push({
    text: "Back",
    callback: () => {
      Popup.goBack();
    },
  });
  navigationData.buttonsData.push({
    text: "Propose",
    callback: () => {
      ActionPropose.prepareUI(playerData, updateDataOnGameView);
      /*Popup.addDynamicLayer(
        <ProposeTransactionView></ProposeTransactionView>,
        {}
      );*/
    },
  });

  const DisplayAcceptPopup = ({ transactionId, areYouProposing }) => {
    if (areYouProposing) {
      return (
        <div>
          {" "}
          <BaseButton
            onClick={() => {
              UIMessage.displayConfirmMessage(
                "Cancel this transaction ?",
                "You did propose this transaction. Do you want to cancel it ?",
                () => {
                  playerData.phase = Phase.PHASE_ACTION;
                  playerData.step = Phase.STEP_REJECT_TRANSACTION;
                  Request.updateGameState(
                    { transactionId: transactionId },
                    true
                  );
                  Popup.deActivateBlackPopup();
                }
              );
            }}
          >
            Reject
          </BaseButton>
        </div>
      );
    }
    return (
      <div>
        <div>Accept ?</div>
        <div>
          <BaseButton
            onClick={() => {
              playerData.phase = Phase.PHASE_ACTION;
              playerData.step = Phase.STEP_REJECT_TRANSACTION;
              Request.updateGameState({ transactionId: transactionId }, true);
              Popup.deActivateBlackPopup();
            }}
          >
            Reject
          </BaseButton>
          <BaseButton
            onClick={() => {
              playerData.phase = Phase.PHASE_ACTION;
              playerData.step = Phase.STEP_ACCEPT_TRANSACTION;
              Request.updateGameState({ transactionId: transactionId }, true);
              Popup.deActivateBlackPopup();
            }}
          >
            Accept
          </BaseButton>
        </div>
      </div>
    );
  };

  const DisplayTransaction = ({
    transaction,
    buttonType,
    onClick = () => {},
  }) => {
    if (transaction.textOut === "vwa1") {
    }

    return (
      <TransactionView
        transaction={transaction}
        buttonType={buttonType}
        onClick={onClick}
      ></TransactionView>
    );
  };

  const DisplayAcceptedTransactionArray = ({ transactions }) => {
    let roundToDisplay = null;

    return transactions.map((item, key) => {
      const nextTransaction =
        key > 0 ? transactions[key - 1].transaction : null;
      roundToDisplay = null;

      if (nextTransaction) {
        if (
          Transaction.getRoundProposed(item.transaction) &&
          Transaction.getRoundProposed(nextTransaction)
        ) {
          if (
            Transaction.getRoundProposed(item.transaction) !==
            Transaction.getRoundProposed(nextTransaction)
          ) {
            roundToDisplay = Transaction.getRoundProposed(item.transaction);
          }
        }
      } else {
        if (
          Transaction.getRoundProposed(item.transaction) &&
          Transaction.getRoundProposed(item.transaction) <
            playerData.roundNumber
        ) {
          roundToDisplay = Transaction.getRoundProposed(item.transaction);
        }
      }

      return (
        <div key={key}>
          {roundToDisplay && <div>Past Round {roundToDisplay}</div>}

          <DisplayTransaction
            transaction={item.transaction}
            buttonType="navigation"
          ></DisplayTransaction>
        </div>
      );
    });
  };

  const DisplayRejectedTransactionArray = ({ transactions }) => {
    let roundToDisplay = null;

    return transactions.map((item, key) => {
      const nextTransaction =
        key > 0 ? transactions[key - 1].transaction : null;
      roundToDisplay = null;

      if (nextTransaction) {
        if (
          Transaction.getRoundProposed(item.transaction) &&
          Transaction.getRoundProposed(nextTransaction)
        ) {
          if (
            Transaction.getRoundProposed(item.transaction) !==
            Transaction.getRoundProposed(nextTransaction)
          ) {
            roundToDisplay = Transaction.getRoundProposed(item.transaction);
          }
        }
      } else {
        if (
          Transaction.getRoundProposed(item.transaction) &&
          Transaction.getRoundProposed(item.transaction) <
            playerData.roundNumber
        ) {
          roundToDisplay = Transaction.getRoundProposed(item.transaction);
        }
      }

      return (
        <div key={key}>
          {roundToDisplay && <div>Past Round {roundToDisplay}</div>}

          <DisplayTransaction
            transaction={item.transaction}
            buttonType="navigation"
          ></DisplayTransaction>
        </div>
      );
    });
  };

  const DisplayProposedTransactionArray = ({ transactions }) => {
    return transactions.map((item, key) => {
      const receivingFaction = Transaction.getReceivingFaction(
        item.transaction
      );
      const proposingFaction = Transaction.getProposingFaction(
        item.transaction
      );

      const isProposedToYou = playerData.faction.name === receivingFaction;
      const areYouProposing = playerData.faction.name === proposingFaction;
      return (
        <div key={key}>
          <DisplayTransaction
            transaction={item.transaction}
            buttonType={
              isProposedToYou || areYouProposing ? "white" : "greyOut"
            }
            onClick={() => {
              if (isProposedToYou || areYouProposing) {
                Popup.activateBlackPopup(
                  <DisplayAcceptPopup
                    transactionId={item.id}
                    areYouProposing={areYouProposing}
                  ></DisplayAcceptPopup>
                );
              } else {
                UIMessage.displayInfoMessage(
                  "",
                  "This transaction is not proposed to you. It is proposed by " +
                    proposingFaction +
                    " to " +
                    receivingFaction
                );
              }
            }}
          ></DisplayTransaction>
        </div>
      );
    });
  };

  const DisplayProposedTransactions = ({ transactions }) => {
    return (
      <div>
        <div style={{ textDecoration: "underline", marginBottom: "1em" }}>
          Proposed Transactions :{" "}
        </div>
        <div>
          {transactions.length === 0 ? (
            <div>No proposed transactions.</div>
          ) : (
            <DisplayProposedTransactionArray
              transactions={transactions}
            ></DisplayProposedTransactionArray>
          )}
        </div>
      </div>
    );
  };

  const DisplayAcceptedTransactions = ({ transactions }) => {
    return (
      <div>
        <div style={{ textDecoration: "underline", marginBottom: "1em" }}>
          Accepted Transactions :{" "}
        </div>
        <div>
          {transactions.length === 0 ? (
            <div>No accepted transactions.</div>
          ) : (
            <DisplayAcceptedTransactionArray
              transactions={transactions}
            ></DisplayAcceptedTransactionArray>
          )}
        </div>
      </div>
    );
  };

  const DisplayRejectedTransactions = ({ transactions }) => {
    return (
      <div>
        <div style={{ textDecoration: "underline", marginBottom: "1em" }}>
          Rejected Transactions :{" "}
        </div>
        <div>
          {transactions.length === 0 ? (
            <div>No rejected transactions.</div>
          ) : (
            <DisplayRejectedTransactionArray
              transactions={transactions}
            ></DisplayRejectedTransactionArray>
          )}
        </div>
      </div>
    );
  };
  return (
    <GameGlobalLayout scrollAtEnd={false} rememberScroll={false}>
      <div className="text-box" style={{ textAlign: "center" }}>
        <div style={{ marginBottom: "1em" }}>
          <DisplayProposedTransactions
            transactions={transactionData.proposedTransactions
              .slice()
              .reverse()}
          ></DisplayProposedTransactions>
        </div>
        <div style={{ marginBottom: "1em" }}>
          <DisplayAcceptedTransactions
            transactions={transactionData.acceptedTransactions
              .slice()
              .reverse()}
          ></DisplayAcceptedTransactions>
        </div>
        <div style={{ marginBottom: "1em" }}>
          <DisplayRejectedTransactions
            transactions={transactionData.rejectedTransactions
              .slice()
              .reverse()}
          ></DisplayRejectedTransactions>
        </div>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default TransactionListView;
