const PlayerData = require("../GameData/PlayerData/PlayerData");
const GameGlobalFunctions = require("./GameGlobalFunctions");

class InitializeGlobal {
  static ini() {
    GameGlobalFunctions.getPlayerDataFromFaction =
      PlayerData.getPlayerDataFromFaction;
  }
}

module.exports = InitializeGlobal;
