import React, { useState } from "react";

import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import BaseButton from "../../Utils/BaseButton";
import GameState from "../../../Data/GameData/Game/GameState";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import LogBookView from "../../Utils/LogMessage/LogBookView";

const StaticGameData = require("../../../Data/GameData/StaticGameData");
const Popup = require("../../../Data/Other/Popup");
const Color = require("../../../Common/Config/Colors");

const ActionSelectionView = ({ popup }) => {
  const buttons = popup.actionSelectionButtons;
  if (!buttons) {
    buttons = [];
  }

  buttons.push(
    <BaseButton
      onClick={() => {
        Popup.deleteAttribute("actionSelectionPopup");
      }}
    >
      Back
    </BaseButton>
  );

  let backgroundColor;
  backgroundColor = "rgba(0, 0, 0, 0.8)";

  const style = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 2000,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  };

  const handleMouseDownOnBackground = (e) => {
    //e.preventDefault();
    //e.stopPropagation();
  };

  const handleMouseUpOnBackground = (e) => {
    //e.preventDefault();
    //e.stopPropagation();

    Popup.deleteAttribute("actionSelectionPopup");
  };

  const Content = () => {
    return (
      <div>
        {buttons.map((button, index) => {
          return <div key={index}>{button}</div>;
        })}
      </div>
    );
  };

  return (
    <div
      style={style}
      onMouseDown={handleMouseDownOnBackground}
      onMouseUp={handleMouseUpOnBackground}
      className="div-fill"
    >
      <div>
        <div
          style={{
            backgroundColor: backgroundColor,
            height: "auto",
            border: "4px solid white",
            borderRadius: "10px",
            padding: "10px",
            //padding: "10px",
            maxHeight: "95vh",
            maxWidth: "95vw",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
          className="div-ver-center"
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onMouseUp={(e) => {
            e.stopPropagation();
          }}
        >
          <Content></Content>
        </div>
      </div>
    </div>
  );
};

export default ActionSelectionView;
