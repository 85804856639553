import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo";
import ScoreData from "../../Data/GameData/Objectifs/ScoreData";
import FactionSpan from "../Utils/FactionSpan";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import Objectives from "../../Data/GameData/Objectifs/Objectives";
import PublicObjectiveDetailedView from "./PublicObjectiveDetailedView";
import Version from "../../Data/Other/Version";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");

const PublicObjectiveView = ({ objectiveName }) => {
  const playerData = StaticGameData.getPlayerData();
  const allPlayersData = PlayerData.getAllPlayersData(playerData);

  const objectiveDesc = Objectives.createDesc(objectiveName);

  const DisplayFactionLogo = ({}) => {
    const factions = allPlayersData
      .filter(
        (player) =>
          player.faction && Objectives.hasScoredObjective(player, objectiveName)
      )
      .map((player) => player.faction.name);

    return (
      <span>
        {factions.map((faction, index) => (
          <span key={index}>
            {" "}
            <FactionLogo factionName={faction} />{" "}
          </span>
        ))}
      </span>
    );
  };

  return (
    <div>
      <BaseButton
        onClick={() => {
          Popup.addDynamicLayer(
            <PublicObjectiveDetailedView
              objectiveName={objectiveName}
            ></PublicObjectiveDetailedView>
          );
        }}
      >
        <DisplayFactionLogo></DisplayFactionLogo>
        {objectiveName}
        {!Version.isAtleatVersion(playerData, 0.12) && (
          <span style={{ color: "gold" }}> : {objectiveDesc.vp} VP</span>
        )}
        {Version.isAtleatVersion(playerData, 0.12) &&
          ScoreData.isObjectiveSelected(playerData, objectiveName) && (
            <div style={{ color: "gold" }}>Selected</div>
          )}
        {Version.isAtleatVersion(playerData, 0.12) &&
          ScoreData.isObjectiveScoredThisRound(playerData, objectiveName) && (
            <div style={{ color: "gold" }}>Scored this round</div>
          )}
      </BaseButton>
    </div>
  );
};

export default PublicObjectiveView;
