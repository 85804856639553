import React, { useState, useEffect, useMemo } from "react";

import ActivationViewLayout from "./ActivationViewLayout";
import ActivationNavigationView from "./ActivationNavigationView";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import PlanetViewActivation from "./Objects/PlanetViewActivation";
import Logo from "../../Utils/Logo";
import ActivationTransferInView from "./ActivationTransferInView";
import ResourceBarView from "../../Utils/ResourceBarView";
import CostView from "../../Utils/CostView";
import ActivationCA from "../../../ClientAction/ActivationCA";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const TransferData = require("../../../Data/GameData/Activation/TransferData.js");
const Planet = require("../../../Data/GameData/MapData/Planet.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");

const ActivationTransferView = ({
  system,
  planet,
  navigationData,
  resolve,
}) => {
  console.log("RENDER ActivationTransferView ");
  const playerData = StaticGameData.getPlayerData();

  console.log("TransferData : ", playerData.activationData.transferData);
  //Storing active system planets

  const activeSystem = ActivationData.getActiveSystem(playerData);
  const receivingPlanet = TransferData.getReceivingPlanet(playerData);

  const ASPlanets = System.getPlanets(activeSystem);

  const otherSystems = Map.getSystemsWhereFactionHasPlanet(
    playerData.map,
    playerData.faction.name
  ).filter((system) => !System.areSystemsTheSame(system, activeSystem));

  const sendingSystems = [];
  const otherSystemsActivated = [];
  sendingSystems.push(activeSystem);

  for (let i = 0; i < otherSystems.length; i++) {
    if (otherSystems.hasBeenActivated) {
      otherSystemsActivated.push(otherSystems[i]);
    } else {
      sendingSystems.push(otherSystems[i]);
    }
  }

  /*const planetsInActiveSystem = System.getPlanetsFromFaction(
    system,
    playerData.faction.name
  );*/

  //console.log("ActivationMoveView RENDER : ", playerData.activationData);

  const DisplayReceivingPlanet = ({ planet }) => {
    return (
      <div>
        <PlanetViewActivation spaceObject={planet}></PlanetViewActivation>
      </div>
    );
  };

  const DisplaySendingPlanets = () => {
    const DisplayTransferingPlanets = ({ planet }) => {
      return (
        <span>
          <PlanetViewActivation spaceObject={planet} showFleet={false}>
            {" "}
            <BaseButton
              onClick={() => {
                TransferData.selectPlanet(playerData, planet);
                TransferData.switchToView(
                  playerData,
                  TransferData.VIEW_TRANSFER
                );
              }}
            >
              Transfer Out
            </BaseButton>
          </PlanetViewActivation>
          <hr></hr>
        </span>
      );
    };

    return (
      // Map on system.spaceObjects where spaceObject.isPlanet
      <div>
        {sendingSystems.map((system, index) => (
          <div key={index}>
            {System.getPlanetsFromFaction(system, playerData.faction.name)
              .filter((planetF) => planetF.name !== receivingPlanet.name)
              .filter((planet) => {
                const system = Map.getSystemFromSpaceObject(
                  planet,
                  playerData.map
                );
                try {
                  System.canTransferFromToPlanet(playerData, system, planet);
                  return true;
                } catch {
                  return false;
                }
              })
              .map((planet, index) => (
                <DisplayTransferingPlanets
                  key={index}
                  planet={planet}
                ></DisplayTransferingPlanets>
              ))}
          </div>
        ))}
      </div>
    );
  };

  const GlobalView = () => {
    navigationData.buttonsData.push({
      text: "Don't Transfer",
      callback: () => {
        ActivationCA.initialize_STEP_ACTIVATION_ACTIVATED();
      },
      type: "action",
    });
    return (
      <div className="text-box" style={{ height: "100%" }}>
        <div>
          Transferring mineral and population to : {receivingPlanet.name}
        </div>
        <div>
          You can transfer only from planets in systems not controlled by
          another faction
        </div>
        <hr></hr>

        <div style={{}}>
          <DisplaySendingPlanets></DisplaySendingPlanets>
        </div>
      </div>
    );
  };

  const TransferView = ({ planetReceiving }) => {
    //const [amountMineral, setAmountMineral] = useState(0);
    //const [amountPopulation, setAmountPopulation] = useState(0);

    const transferData = TransferData.getTransferData(playerData);
    const amountPopulation = transferData.amountPopulation;
    const amountMineral = transferData.amountMineral;

    navigationData.buttonsData.splice(0, navigationData.buttonsData.length);
    navigationData.buttonsData.push({
      text: "Back",
      callback: () =>
        TransferData.switchToView(playerData, TransferData.VIEW_GLOBAL_VIEW),
    });
    navigationData.buttonsData.push({
      text: "Don't Transfer",
      callback: () => {
        ActivationCA.initialize_STEP_ACTIVATION_ACTIVATED();
      },
      type: "action",
    });
    navigationData.buttonsData.push({
      text: "Confirm",
      callback: () => {
        ActivationCA.resolve_STEP_ACTIVATION_TRANSFER();
      },
      type: "action",
    });
    const planet = TransferData.getSelectedPlanet(playerData);

    const populationReceivingPlanet = CustomMath.roundDec(
      amountPopulation + planetReceiving.population
    );
    const mineralReceivingPlanet = CustomMath.roundDec(
      amountMineral + planetReceiving.mineral
    );

    const populationSendingPlanet = CustomMath.roundDec(
      planet.unassignedPop - amountPopulation
    );
    const mineralSendingPlanet = CustomMath.roundDec(
      planet.mineral - amountMineral
    );

    //Display cost
    const cost = TransferData.getCostTransfer(playerData);
    navigationData.child = (
      <div>
        <span>Transfer cost : </span>
        <CostView cost={cost}></CostView>
      </div>
    );

    /*console.log("amountPopulation : ", amountPopulation);
    console.log("amountMineral : ", amountMineral);
    console.log("planet : ", planet);
    console.log("populationSendingPlanet : ", populationSendingPlanet);
    console.log("mineralSendingPlanet : ", mineralSendingPlanet);
    console.log("populationReceivingPlanet : ", populationReceivingPlanet);
    console.log("mineralReceivingPlanet : ", mineralReceivingPlanet);*/

    const addPopulation = (amount) => {
      TransferData.addPopulation(playerData, amount);
    };

    const addMineral = (amount) => {
      TransferData.addMineral(playerData, amount);
    };

    return (
      <div>
        <PlanetViewActivation
          spaceObject={planetReceiving}
          showPopRepartition={false}
        >
          <div>Receiving...</div>
          <div>
            <ResourceBarView
              fontSize="1.5em"
              resourceType={"population"}
              amount={populationReceivingPlanet}
            ></ResourceBarView>
            <ResourceBarView
              fontSize="1.5em"
              resourceType={"mineral"}
              amount={mineralReceivingPlanet}
            ></ResourceBarView>
          </div>
        </PlanetViewActivation>
        <hr></hr>
        <PlanetViewActivation spaceObject={planet} showPopRepartition={false}>
          <div>
            Sending...
            <div>
              <ResourceBarView
                fontSize="1.5em"
                resourceType={"population"}
                amount={populationSendingPlanet}
              ></ResourceBarView>
              <ResourceBarView
                fontSize="1.5em"
                resourceType={"mineral"}
                amount={mineralSendingPlanet}
              ></ResourceBarView>
            </div>
          </div>
          <div style={{ fontSize: "1.5em" }}>
            <BaseButton
              onClick={() => {
                addMineral(+1);
              }}
            >
              <span>
                {" "}
                + <Logo logoName="mineral" heightRem="1em"></Logo>
              </span>
            </BaseButton>
            <BaseButton
              onClick={() => {
                addMineral(-1);
              }}
            >
              <span>
                {" "}
                - <Logo logoName="mineral" heightRem="1em"></Logo>
              </span>
            </BaseButton>
            <BaseButton
              onClick={() => {
                addPopulation(+1);
              }}
            >
              <span>
                {" "}
                + <Logo logoName="population" heightRem="1em"></Logo>
              </span>
            </BaseButton>
            <BaseButton
              onClick={() => {
                addPopulation(-1);
              }}
            >
              <span>
                {" "}
                - <Logo logoName="population" heightRem="1em"></Logo>
              </span>
            </BaseButton>
          </div>
        </PlanetViewActivation>
      </div>
    );
  };

  const view = TransferData.getView(playerData);

  //Check if the player can transfer passing through system
  try {
    System.canTransferThrough(playerData, system);
  } catch (e) {
    return <div>{e.message}</div>;
  }

  return (
    <div>
      {view === TransferData.VIEW_GLOBAL_VIEW && <GlobalView></GlobalView>}
      {view === TransferData.VIEW_TRANSFER && (
        <TransferView planetReceiving={receivingPlanet}></TransferView>
      )}
    </div>
  );
};

export default ActivationTransferView;
