const StaticGameData = require("../GameData/StaticGameData");

class Popup {
  static MODE_CHOOSE_SYSTEM = "choose system";
  static MODE_CHOOSE_PLANET = "choose planet";
  static MODE_CHOOSE_PLANET_COLONIZE = "choose planet colonize";

  static ALL_VIEW = "ALL";

  static IS_CLIENT_WAITING_SERVER = false;

  //Recording of the zoom on the map
  static mapZoom = 1;

  //Display mode of the map
  static MAP_DISPLAY_MAP = "map";
  static MAP_DISPLAY_FLEET = "fleet";
  static MAP_DISPLAY_BUILD = "build";
  static MAP_DISPLAY_PLANET_INFO = "planet info";
  static mapDisplayMode = Popup.MAP_DISPLAY_MAP;

  static MAP_INTERACTION_MODE_NORMAL = "normal";
  static MAP_INTERACTION_MODE_MOVE = "move";
  static MAP_INTERACTION_MODE_SELECT_SYSTEM = "select system";
  static MAP_INTERACTION_MODE_SELECT_OBJECT = "select object";
  static mapInteractionMode = Popup.MAP_INTERACTION_MODE_NORMAL;

  //Navigation and task bar data
  static mapNavigationData = null;
  static mapClickCallback = null;
  static mapClickCallbackReturnOnlyType = "all";
  static mapScaleMemory = 1;
  static mapTranslateMemory = { x: 0, y: 0 };
  static firstMapRender = true;
  static areContextualButtonsVisible = false;
  static areInfoContextualButtonsVisible = false;

  static mapLightData = { showCombatIcon: false };

  static mapLightUpdate = () => {
    this.mapLightData = { ...this.mapLightData };
  };

  static resetPopupForNewGame() {
    this.firstMapRender = true;
  }

  static setFirstMapRender(firstMapRender) {
    this.firstMapRender = firstMapRender;
  }

  static setContextualButtonsVisibility(visibility) {
    this.areContextualButtonsVisible = visibility;
    if (visibility === true) {
      this.setInfoContextualButtonsVisibility(false);
    }
  }

  static getContextualButtonsVisibility() {
    return this.areContextualButtonsVisible;
  }

  static setInfoContextualButtonsVisibility(visibility) {
    this.areInfoContextualButtonsVisible = visibility;
    if (visibility === true) {
      this.setContextualButtonsVisibility(false);
    }
  }

  static getInfoContextualButtonsVisibility() {
    return this.areInfoContextualButtonsVisible;
  }

  static resetMapNavigationData() {
    this.mapNavigationData = null;
    this.mapClickCallback = null;
    this.mapClickCallbackReturnOnlyType = "all";
  }

  static setMapNavigationData(navigationData) {
    this.mapNavigationData = navigationData;
  }

  static setMapClickCallback(callback, returnOnlyType = null) {
    this.mapClickCallback = callback;
    this.mapClickCallbackReturnOnlyType = returnOnlyType;
  }

  static getMapNavigationDataInstance() {
    if (!this.mapNavigationData) return null;
    let buttonsData = [];
    //console.log("buttonsData", buttonsData);

    if (this.mapNavigationData.buttonsData) {
      buttonsData = buttonsData.concat(this.mapNavigationData.buttonsData);
    }

    return {
      buttonsData: buttonsData ? buttonsData : null,
      taskBarData: this.mapNavigationData.taskBarData,
    };
  }

  static setShowCombatIcon(show) {
    this.mapLightData.showCombatIcon = show;
  }

  static getShowCombatIcon() {
    return this.mapLightData.showCombatIcon;
  }

  //Mode data
  static mode = null; // like choose planet, choose system,...
  static modeData = {
    taskBarText: null,
    callbackButton: { nameView: null, callback: null, text: null },
    cancelButton: { text: null, callback: null },
  };

  //Information to manipulate the task bar
  static taskBarDataManipulator = [];

  static createPopup(data) {
    if (StaticGameData.serverMode) return;
    const popup = { previousPopups: [], ...data };
    this.enrichTaskBarData(popup);
    return popup;
  }

  static overWrite(data) {
    if (StaticGameData.serverMode) return;
    const newPopup = { ...data, previousPopups: [] };
    this.enrichTaskBarData(newPopup);
    StaticGameData.setPopup(newPopup);
  }

  static addAttributes(data) {
    const newPopup = { ...StaticGameData.popup, ...data };
    //console.log("addAttributes : ", newPopup);
    this.enrichTaskBarData(newPopup);
    //console.log("addAttributes : ", newPopup);
    StaticGameData.setPopup(newPopup);
  }

  static addActionSelectionAttributes(buttons) {
    this.addAttributes({
      actionSelectionPopup: true,
      actionSelectionButtons: buttons,
    });
  }

  static removeActionSelectionAttributes() {
    this.deleteAttribute("actionSelectionPopup");
    this.deleteAttribute("actionSelectionButtons");
  }

  static addLayer(data) {
    //console.log("Popup : addLayer : data : ", data);

    if (StaticGameData.serverMode) return;

    //Clear actual popups.
    this.deleteAttribute("actionSelectionPopup");

    //If an activation is ongoing and if we try to access the system view of the activated system, we swtich to the activation view
    /* if (data.name === "ManageSystemView") {
      const playerData = StaticGameData.getPlayerData();
      if (
        playerData.activationData &&
        playerData.activationData.isASystemActive &&
        playerData.activationData.systemName
      ) {
        if (playerData.activationData.systemName === data.system.name) {
          data.name = "ManageActivationView";
        }
      }
    }*/

    const previousPopups = StaticGameData.popup.previousPopups;
    delete StaticGameData.popup.previousPopups;
    previousPopups.push(StaticGameData.popup);
    const newPopup = { previousPopups: previousPopups, ...data };
    //console.log("Popup : addLayer : newPopup : ", newPopup);
    this.enrichTaskBarData(newPopup);
    //console.log("Popup : addLayer : newPopup : ", newPopup);
    StaticGameData.setPopup(newPopup);

    //console.log("POPUP addLayer : ", StaticGameData.popup);
  }

  static addDynamicLayer(componentToDisplay, data = {}) {
    this.addLayer({
      ...data,
      name: "DynamicView",
      displayComponent: () => {
        return componentToDisplay;
      },
    });
  }

  static removeLayer() {
    if (StaticGameData.serverMode) return;
    const previousPopups = StaticGameData.popup.previousPopups;
    if (!previousPopups || previousPopups.length === 0) {
      throw new Error("No previous popup");
    }
    const newPopup = previousPopups.pop();
    newPopup.previousPopups = previousPopups;
    this.enrichTaskBarData(newPopup);
    StaticGameData.setPopup(newPopup);
  }

  static replaceLayer(data) {
    this.removeLayer();
    this.addLayer(data);
  }

  static addActionLayer() {
    this.addLayer({ name: "ActionGlobalView" });
  }

  static deleteAttribute(attributeName) {
    const newPopup = { ...StaticGameData.popup };
    delete newPopup[attributeName];
    this.enrichTaskBarData(newPopup);
    StaticGameData.setPopup(newPopup);
  }

  static touch() {
    const newPopup = { ...StaticGameData.popup };
    this.enrichTaskBarData(newPopup);
    StaticGameData.setPopup(newPopup);
  }

  static goBack() {
    if (StaticGameData.serverMode) return;
    const previousPopups = StaticGameData.popup.previousPopups;
    if (!previousPopups || previousPopups.length === 0) {
      throw new Error("No previous popup");
    }
    const newPopup = previousPopups.pop();
    newPopup.previousPopups = previousPopups;
    this.enrichTaskBarData(newPopup);
    StaticGameData.setPopup(newPopup);
  }

  static goQuit() {
    if (StaticGameData.serverMode) return;
    const previousPopups = StaticGameData.popup.previousPopups;
    const newPopup = previousPopups[0];
    newPopup.previousPopups = [];
    this.enrichTaskBarData(newPopup);
    StaticGameData.setPopup(newPopup);
  }

  static reset() {
    this.mode = null;
    if (StaticGameData.serverMode) return;
    StaticGameData.setPopup(this.createPopup({ name: "MapView" }));
    this.taskBarDataManipulator = [];
  }

  static unStackToMap() {
    StaticGameData.setPopup(this.createPopup({ name: "MapView" }));
  }

  static setMode(modeName, modeData) {
    if (!modeName) {
      throw new Error("Mode must have a name");
    }
    this.mode = { name: modeName };
    this.modeData = modeData;
  }

  static resetMode() {
    this.mode = null;
    this.modeData = null;
  }

  /*static setTaskBarDataManipulator(manipulatorArray) {
    this.taskBarDataManipulator = manipulatorArray;
  }*/

  static enrichTaskBarData(popup) {
    popup.mode = this.mode;
    return;
  }

  //Global state
  static globalState = {};
  static addGlobalState(key, object) {
    this.globalState[key] = object;
  }

  static deleteGlobalState(key) {
    delete this.globalState[key];
  }

  static getGlobalState(key) {
    if (
      typeof this.globalState[key] === "undefined" ||
      this.globalState[key] === null
    ) {
      return null;
    }
    return this.globalState[key];
  }

  static getGlobalStateKey(string) {
    return StaticGameData.gameState.gameId + string;
  }

  static resetGlobalState() {
    this.globalState = {};
  }

  static setClientWaitingServer(clientWaiting) {
    this.IS_CLIENT_WAITING_SERVER = clientWaiting;
    Popup.touch();
  }
  static setMapDisplayMode(mode) {
    this.mapDisplayMode = mode;
  }

  static setMapDisplayModeFleet() {
    this.mapDisplayMode = this.MAP_DISPLAY_FLEET;
  }

  static setMapDisplayModeMap() {
    this.mapDisplayMode = this.MAP_DISPLAY_MAP;
  }

  static switchMapDisplayMode() {
    if (this.mapDisplayMode === this.MAP_DISPLAY_MAP) {
      this.mapDisplayMode = this.MAP_DISPLAY_FLEET;
    } else {
      this.mapDisplayMode = this.MAP_DISPLAY_MAP;
    }
    Popup.touch();
  }

  static resetMapDisplayMode() {
    this.mapDisplayMode = this.MAP_DISPLAY_MAP;
  }

  static getMapDisplayMode() {
    return this.mapDisplayMode;
  }

  //BlackPopup
  static blackPopupActive = false;
  static blackPopupComponent = null;

  static activateBlackPopup(component) {
    const popup = StaticGameData.popup;
    popup.blackPopupActive = true;
    popup.blackPopupComponent = component;
    Popup.touch();
  }

  static deActivateBlackPopup() {
    const popup = StaticGameData.popup;
    popup.blackPopupActive = false;
    popup.blackPopupComponent = null;
    Popup.touch();
  }

  static isBlackPopupActive() {
    const popup = StaticGameData.popup;
    return popup.blackPopupActive;
  }

  static getBlackPopupComponent() {
    const popup = StaticGameData.popup;

    return popup.blackPopupComponent;
  }

  //Mange the enter and quit of game

  static game = null;
  static enterGame(game) {
    StaticGameData.menuPopup = StaticGameData.popup;
    StaticGameData.setMenuPopupReference = StaticGameData.setPopupReference;
    StaticGameData.setInGame(true);
    this.game = game;
    this.touch();
  }

  static quitGame() {
    StaticGameData.popup = StaticGameData.menuPopup;
    StaticGameData.setPopupReference = StaticGameData.setMenuPopupReference;
    StaticGameData.setInGame(false);
    this.game = null;
    console.log(
      "StaticGameData.setPopupReference : ",
      StaticGameData.setPopupReference
    );
    this.touch();
  }

  //Ingame Popup
  /*static ingamePopupActive = false;

  static activateIngamePopup() {
    popup.ingamePopupActive = true;
  }

  static deActivateIngamePopup() {
    popup.ingamePopupActive = false;
  }

  static previousPopupStack = [];

  static addNewPopupStack() {
    previousPopupStack.push(StaticGameData.popup);
    StaticGameData.setPopup(Popup.createPopup({}));
  }

  static removePopupStack() {
    if (previousPopupStack.length === 0) {
      throw new Error("No previous popup");
    }
    StaticGameData.setPopup(previousPopupStack.pop());
  }*/
}

module.exports = Popup;
