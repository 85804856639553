import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import SpaceObjectSummaryView from "../ManageSystem/SpaceObjectSummaryView";
import NavigationView from "../NavigationView";
import GameGlobalLayout from "../GameGlobalLayout";
import TitleView from "../TitleView";
import StaticGameData from "../../Data/GameData/StaticGameData";
import PlanetViewActivation from "./ActivationView/Objects/PlanetViewActivation";
import PlanetView from "../Object/PlanetView";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Request = require("../../Common/Requests/Request.js");
const Map = require("../../Data/GameData/MapData/Map.js");

const getNavigationData = (spaceObject) => {
  const playerData = StaticGameData.getPlayerData();

  const system = Map.getSystemFromSpaceObject(spaceObject, playerData.map);

  if (StaticGameData.popup.navigationData) {
    return StaticGameData.popup.navigationData;
  } else {
    return {
      buttonsData: [
        { text: "Map", callback: () => Popup.unStackToMap() },
        {
          text: "System",
          callback: () => {
            Popup.reset();
            Popup.addLayer({ name: "ManageSystemView", system: system });
          },
        },
        { text: "Back", callback: () => Popup.goBack() },
      ],
    };
  }
};

const ManageObjectView = ({
  spaceObject,
  navigationData = getNavigationData(spaceObject),
}) => {
  //Go back
  const back = () => {
    Popup.goBack();
  };

  /*const getTaskBarData = () => {
    if (!Popup.mode) {
      return null;
    }

    if (Popup.mode.name === Popup.MODE_CHOOSE_PLANET_COLONIZE) {
      console.log("MapView Popup.mode: ", Popup.mode);
      return {
        text: "Colonize : " + spaceObject.name + " ?",
      };
    }
  };
  const taskBarData = getTaskBarData();*/

  /*if (Popup.mode && Popup.mode.name === Popup.MODE_CHOOSE_PLANET_COLONIZE) {
      // Add the "Done colonizing" button
      baseData.unshift({
        text: "Colonize " + spaceObject.name,
        callback: () => {
          const updateData = { name: "Colonize", spaceObject: spaceObject };
        },
      });
    }*/

  console.log("ManageObjectView spaceObject : ", spaceObject);

  const ObjectDisplay = () => {
    return (
      <div
        className="text-box"
        style={{
          textAlign: "center",
          height: "100%",
          //backgroundColor: Color.COLOR_MANAGE_VIEW_BACKGROUND,
        }}
      >
        {spaceObject.name && (
          <div style={{ marginBottom: "1em" }}>{spaceObject.name}</div>
        )}
        {spaceObject.isPlanet && (
          <PlanetView
            planet={spaceObject}
            showPlanetParameter={true}
            showGeneralInfo={true}
            showButtonPlanetName={false}
          ></PlanetView>
        )}
      </div>
    );
  };

  return (
    <GameGlobalLayout backgroundType="shadow">
      <ObjectDisplay></ObjectDisplay>
      <NavigationView
        screenData={{ spaceObject: spaceObject }}
        navigationData={navigationData}
      ></NavigationView>
    </GameGlobalLayout>
  );
};

export default ManageObjectView;
