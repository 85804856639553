import React, { useState } from "react";

import ResourceBarView from "./ResourceBarView";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Logo from "./Logo";

const CustomMath = require("../../Common/Math/CustomMath");

const CostView = ({
  cost,
  textIfEmpty = "no cost",
  compactView = false,
  showEvenIfZero = false,
  //showCompleteWithCredit = true
}) => {
  console.log("CostView cost: ", cost);
  //const playerData = StaticGameData.getPlayerData();

  /*let creditToBuy = 0;
  if (showCompleteWithCredit) {
    const costAdaptedToBuy = JSON.parse(JSON.stringify(cost));
    costAdaptedToBuy.credit = 0;
    PlayerData.adaptCostForBuy(playerData, costAdaptedToBuy);
    creditToBuy = costAdaptedToBuy.credit;
  }*/

  if (
    !cost.mineral &&
    !cost.population &&
    !cost.science &&
    !cost.influence &&
    !cost.energy &&
    !cost.cargo &&
    !cost.credit
  ) {
    return <span>{textIfEmpty}</span>;
  }

  console.log("cost.population: ", cost.population);
  console.log("cost.population !== 0", cost.population !== 0);

  const DisplayResourceCost = ({ resourceType, amount }) => {
    if (!resourceType) return null;
    if (!amount) return null;

    return (
      <ResourceBarView
        resourceType={resourceType}
        amount={amount}
      ></ResourceBarView>
    );
  };

  if (compactView) {
    const shouldPrint = (amount) => {
      if (!amount) return false;
      if (amount === 0) return false;
      return true;
    };
    return (
      <span>
        {shouldPrint(cost.mineral) && (
          <span style={{ margin: "0.5em", whiteSpace: "nowrap" }}>
            {CustomMath.roundDec(cost.mineral)} <Logo logoName="mineral"></Logo>
          </span>
        )}
        {shouldPrint(cost.science) && (
          <span style={{ margin: "0.5em", whiteSpace: "nowrap" }}>
            {" "}
            {CustomMath.roundDec(cost.science)} <Logo logoName="science"></Logo>
          </span>
        )}
        {shouldPrint(cost.energy) && (
          <span style={{ margin: "0.5em", whiteSpace: "nowrap" }}>
            {CustomMath.roundDec(cost.energy)} <Logo logoName="energy"></Logo>
          </span>
        )}
        {shouldPrint(cost.population) && (
          <span style={{ margin: "0.5em", whiteSpace: "nowrap" }}>
            {CustomMath.roundDec(cost.population)}{" "}
            <Logo logoName="population"></Logo>
          </span>
        )}
        {shouldPrint(cost.credit) && (
          <span style={{ margin: "0.5em", whiteSpace: "nowrap" }}>
            {CustomMath.roundDec(cost.credit)} <Logo logoName="credit"></Logo>
          </span>
        )}
      </span>
    );
  }

  return (
    <span>
      <DisplayResourceCost
        resourceType="mineral"
        amount={CustomMath.roundDec(cost.mineral)}
      ></DisplayResourceCost>
      <DisplayResourceCost
        resourceType="population"
        amount={CustomMath.roundDec(cost.population)}
      ></DisplayResourceCost>
      <DisplayResourceCost
        resourceType="science"
        amount={CustomMath.roundDec(cost.science)}
      ></DisplayResourceCost>
      <DisplayResourceCost
        resourceType="influence"
        amount={CustomMath.roundDec(cost.influence)}
      ></DisplayResourceCost>
      <DisplayResourceCost
        resourceType="energy"
        amount={CustomMath.roundDec(cost.energy)}
      ></DisplayResourceCost>
      <DisplayResourceCost
        resourceType="cargo"
        amount={CustomMath.roundDec(cost.cargo)}
      ></DisplayResourceCost>
      <DisplayResourceCost
        resourceType="credit"
        amount={CustomMath.roundDec(cost.credit)}
      ></DisplayResourceCost>
    </span>
  );
};

export default CostView;
