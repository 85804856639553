const Unit = require("../MapData/Unit");

const TechTree = require("../Technology/TechTree");
const TechList = require("../Technology/TechList");
const Map = require("../MapData/Map");
const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction");
const Tech = require("../Technology/Tech");
const Fleet = require("../MapData/Fleet");
const System = require("../MapData/System");
const ResolveData = require("../EndOfRound/ResolveData");
const CombatData = require("../Combat/CombatData");
const LogBook = require("../Connection/LogBook");
const SolveFleetLimitData = require("../MandatoryAction/SolveFleetLimitData");
const SolveCapacityData = require("../MandatoryAction/SolveCapacityData");
const CombatRecording = require("../Combat/CombatRecording");
const PlayerData = require("../PlayerData/PlayerData");
const Cost = require("../Utils/Cost");
const CustomMath = require("../../../Common/Math/CustomMath");
const UnitAbility = require("./UnitAbility");
const AbilityCommon = require("./AbilityCommon");

class ResolveTechAbility {
  //static PHASE_TECH_ABILITY = "resolveTechAbility";

  static orbitalMechDeployment = (playerData, data) => {
    TechTree.verifyHasPlayerTech(
      playerData,
      TechList.TECH.orbitalMechDeployment.name
    );
    TechTree.exhaustTech(
      TechTree.getTechTree(playerData),
      TechList.TECH.orbitalMechDeployment.name
    );

    //Pay the cost
    PlayerData.spendCost(playerData, Cost.createCost({ energy: 1 }));

    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);
    if (planet.faction !== playerData.faction.name) {
      throw new Error("You do not have control of the planet.");
    }
    const unitsToAdd = [
      Unit.createUnit(
        playerData,
        playerData.faction.name,
        Unit.UNIT_TYPE_MECH,
        ""
      ),
    ];
    MapUnitaryAction.placeUnitsOnPlanet(playerData, planet, unitsToAdd);
  };

  static nekroReanimation = (playerData, data) => {
    TechTree.verifyHasPlayerTech(
      playerData,
      TechList.TECH.nekroReanimation.name
    );
    TechTree.exhaustTech(
      TechTree.getTechTree(playerData),
      TechList.TECH.nekroReanimation.name
    );

    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);
    if (planet.faction !== playerData.faction.name) {
      throw new Error("You do not have control of the planet.");
    }

    const units = [];
    for (let i = 0; i < 2; i++) {
      units.push(
        Unit.createUnit(
          playerData,
          playerData.faction.name,
          Unit.UNIT_TYPE_INFANTRY,
          ""
        )
      );
    }

    MapUnitaryAction.placeUnitsOnPlanet(playerData, planet, units);
  };

  static warpImpulsion = (playerData, data) => {
    TechTree.verifyHasPlayerTech(playerData, TechList.TECH.warpImpulsion.name);
    TechTree.exhaustTech(
      TechTree.getTechTree(playerData),
      TechList.TECH.warpImpulsion.name
    );

    //Pay the cost
    PlayerData.spendCost(playerData, Cost.createCost({ energy: 1 }));

    const system = Map.getSystemFromName(playerData.map, data.systemName);
    const units = data.units;
    let systemSource = null;

    const setSystemSource = (source) => {
      if (systemSource && systemSource !== source) {
        throw new Error("You cannot move units from different systems.");
      }
      systemSource = source;
    };

    //Check that system is not controlled by another faction
    if (system.faction !== null && system.faction !== playerData.faction.name) {
      throw new Error(
        "The target space area should not be controlled by another faction."
      );
    }

    const fleet = Fleet.createFleet(playerData, playerData.faction.name);
    let shipNotTransportedCount = 0;
    for (let i = 0; i < units.length; i++) {
      const unit = units[i];

      if (!unit.transportRequired) {
        shipNotTransportedCount++;
      }

      if (unit.class === Unit.CLASS_STRUCTURE) {
        throw new Error("You cannot move structures with warp impulsion.");
      }

      console.log("unit.id", unit.id);
      const system = Map.getSystemFromUnitId(playerData.map, unit.id);
      if (!system) {
        throw new Error("Unit not found on the map.");
      }

      if (system.hasBeenActivated) {
        throw new Error(
          "You cannot select units from a locked system with warp impulsion."
        );
      }

      if (!System.isUnitInSpaceArea(playerData, system, units[i])) {
        throw new Error(
          "All units selected should be in the space area of the source system. " +
            unit.type +
            " is not in the space area of the source system."
        );
      }

      if (shipNotTransportedCount > 1) {
        throw new Error(
          "You can only move 1 ship and the unit it can transport."
        );
      }

      setSystemSource(system);
      Fleet.addUnit(fleet, unit);
    }

    Fleet.checkCapacity(playerData, fleet);

    const distance = Map.getDistanceBetweenSystems(
      playerData,
      systemSource,
      system,
      playerData.map
    );
    if (distance > 1) {
      throw new Error("You cannot move units to a non adjacent system.");
    }

    MapUnitaryAction.removeFleetFromSystemSA(playerData, systemSource, fleet);
    MapUnitaryAction.placeFleetInSystemSA(playerData, system, fleet);

    SolveFleetLimitData.check(playerData, systemSource);
    SolveCapacityData.check(playerData, systemSource);
    SolveFleetLimitData.check(playerData, system);
    SolveCapacityData.check(playerData, system);
  };

  static transportCarrierDeployment = (playerData, data) => {
    TechTree.verifyHasPlayerTech(
      playerData,
      TechList.TECH.transportCarrierDeployment.name
    );
    TechTree.exhaustTech(
      TechTree.getTechTree(playerData),
      TechList.TECH.transportCarrierDeployment.name
    );

    //Pay the cost
    PlayerData.spendCost(playerData, Cost.createCost({ energy: 1 }));

    const system = Map.getSystemFromName(playerData.map, data.systemName);

    //Check that the system includes at least on of the player units
    const playerUnits = System.getAllUnitsFromFactionIncludingPlanets(
      playerData,
      system,
      playerData.faction.name
    );
    if (playerUnits.length === 0) {
      throw new Error(
        "You must have at least one unit in the system to use the transport carrier deployment."
      );
    }

    MapUnitaryAction.placeUnitsInSystemSA(playerData, system, [
      Unit.createUnit(
        playerData,
        playerData.faction.name,
        Unit.UNIT_TYPE_CARRIER,
        ""
      ),
    ]);

    SolveFleetLimitData.check(playerData, system);
    SolveCapacityData.check(playerData, system);
  };

  static warpGate = (playerData, data) => {
    TechTree.verifyHasPlayerTech(playerData, TechList.TECH.warpGate.name);
    TechTree.exhaustTech(
      TechTree.getTechTree(playerData),
      TechList.TECH.warpGate.name
    );

    //Pay the cost
    PlayerData.spendCost(playerData, Cost.createCost({ energy: 2 }));

    const system = Map.getSystemFromName(playerData.map, data.systemName);
    const units = data.units;
    let systemSource = null;
    const setSystemSource = (source) => {
      if (systemSource && systemSource !== source) {
        throw new Error("You cannot select units from different systems.");
      }
      systemSource = source;
    };
    const fleet = Fleet.createFleet(playerData, playerData.faction.name);
    for (let i = 0; i < units.length; i++) {
      const unit = units[i];

      if (unit.class === Unit.CLASS_STRUCTURE) {
        throw new Error("You cannot move structures with warp gate.");
      }

      const system = Map.getSystemFromUnitId(playerData.map, unit.id);
      if (!system) {
        throw new Error("Unit not found on the map.");
      }

      if (system.hasBeenActivated) {
        throw new Error(
          "You cannot select units from a locked system with warp gate."
        );
      }

      if (!System.isUnitInSpaceArea(playerData, system, units[i])) {
        throw new Error(
          "All units selected should be in the space area of the source system. " +
            unit.type +
            " is not in the space area of the source system."
        );
      }

      setSystemSource(system);
      Fleet.addUnit(fleet, unit);
    }

    const allUnitsInSourceSystem =
      System.getAllUnitsFromFactionIncludingPlanets(
        playerData,
        systemSource,
        playerData.faction.name
      );
    const structureInSourceSystem = allUnitsInSourceSystem.filter(
      (unit) => unit.class === Unit.CLASS_STRUCTURE
    );
    if (structureInSourceSystem.length === 0) {
      throw new Error(
        "You need to have a structure in the source system to use the warp gate."
      );
    }

    const allUnitsInTargetSystem =
      System.getAllUnitsFromFactionIncludingPlanets(
        playerData,
        system,
        playerData.faction.name
      );
    const structureInTargetSystem = allUnitsInSourceSystem.filter(
      (unit) => unit.class === Unit.CLASS_STRUCTURE
    );
    if (structureInSourceSystem.length === 0) {
      throw new Error(
        "You need to have a structure in the target system to use the warp gate."
      );
    }

    MapUnitaryAction.removeFleetFromSystemSA(playerData, systemSource, fleet);
    MapUnitaryAction.placeFleetInSystemSA(playerData, system, fleet);

    SolveFleetLimitData.check(playerData, systemSource);
    SolveCapacityData.check(playerData, systemSource);
    SolveFleetLimitData.check(playerData, system);
    SolveCapacityData.check(playerData, system);
  };

  static combatRepairModule(
    recData,
    resolveData,
    combatData,
    system,
    planet,
    combatFunctions
  ) {
    const fleets = CombatData.getFleets(combatData);
    for (let i = 0; i < fleets.length; i++) {
      const fleet = fleets[i];
      const playerData = ResolveData.getPlayerDataFromFaction(
        resolveData,
        fleet.faction
      );

      if (
        TechTree.hasPlayerTech(
          playerData,
          TechList.TECH.combatRepairModule.name
        )
      ) {
        const HLog = LogBook.createLogBook();
        LogBook.generateAddMessage(
          HLog,
          "$faction$ uses COMBAT REPAIR MODULE TECH",
          [fleet.faction]
        );
        combatFunctions.repairRandomDamagesOnUnits(
          recData,
          resolveData,
          combatData,
          1,
          Fleet.getUnits(fleet),
          fleet.faction,
          system,
          HLog
        );
      }
    }
  }

  static plasmaCanonCharge = (
    recData,
    resolveData,
    combatData,
    system,
    combatFunctions
  ) => {
    const combatFleetList = CombatData.getFleetCombatExecutionOrder(
      combatData,
      system
    );
    for (let i = 0; i < combatFleetList.length; i++) {
      CombatData.initializeFleetCombatList(combatData);

      const combatFleet = combatFleetList[i];
      const fleet = combatFleet.fleet;
      const playerData = ResolveData.getPlayerDataFromFaction(
        resolveData,
        combatFleet.fleet.faction
      );
      if (
        TechTree.hasPlayerTech(playerData, TechList.TECH.plasmaCanonCharge.name)
      ) {
        const units = Fleet.getUnits(combatFleet.fleet, Fleet.UNIT_CLASS_SHIP);
        const nonFigthersUnits = units.filter(
          (unit) => unit.type !== Unit.UNIT_TYPE_FIGHTER
        );
        if (nonFigthersUnits.length >= 3) {
          const HLLog = LogBook.createLogBook();
          const DLog = LogBook.createLogBook();
          LogBook.generateAddMessage(
            HLLog,
            "$faction$ uses PLASMA CANON CHARGE ability.",
            [fleet.faction]
          );
          const hit = combatFunctions.rollOnce(5.5, 7.5);
          LogBook.generateAddMessage(
            DLog,
            "$faction$ produced " + hit + " (" + 4.5 + " - " + 6.5 + ").",
            [fleet.faction]
          );
          LogBook.generateAddMessage(
            DLog,
            "PLASMA CANON CHARGE occurs before the beginnin of the combat round.",
            [fleet.faction]
          );

          combatFunctions.assignProducedHitsToFleetCombat(
            HLLog,
            combatFleet,
            hit
          );
          CombatRecording.createStep(recData, HLLog, DLog, system);
          combatFunctions.assignHits(
            recData,
            resolveData,
            combatData,
            system,
            null
          );
        }
      }
    }
  };

  static repairingStation = (playerData, data) => {
    TechTree.verifyHasPlayerTech(
      playerData,
      TechList.TECH.repairingStation.name
    );
    TechTree.exhaustTech(
      TechTree.getTechTree(playerData),
      TechList.TECH.repairingStation.name
    );
    const system = Map.getSystemFromUnitId(playerData.map, data.unitId);
    if (!system) {
      throw new Error("Unit not found on the map.");
    }
    const fleet = System.getFleetFromUnitId(system, data.unitId);
    const unit = System.getUnitFromId(system, data.unitId);

    if (!fleet || !system || !unit) {
      throw new Error("Unit not found on the map or in a fleet.");
    }

    if (fleet.faction !== playerData.faction.name) {
      throw new Error("You do not have control of this unit.");
    }

    if (!system.faction || system.faction !== playerData.faction.name) {
      throw new Error("You do not have control of this space area.");
    }

    const planets = System.getPlanetsFromFaction(
      system,
      playerData.faction.name
    );
    if (planets.length === 0) {
      throw new Error(
        "You need to have control of a planet in the system to use the repairing station."
      );
    }

    if (Unit.getHP(playerData, unit) === Unit.getMaxHP(playerData, unit)) {
      throw new Error("Unit is already at full health.");
    }

    Unit.setHP(playerData, unit, Unit.getMaxHP(playerData, unit));
  };

  static applyUpgradeOnAllUnits(playerData, ugradeName) {
    const units = Map.getAllUnitsFromFaction(
      playerData,
      playerData.map,
      playerData.faction.name
    );

    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      Unit.upgradeUnitAfterGain(playerData, unit, ugradeName);
    }
  }
}

module.exports = ResolveTechAbility;
