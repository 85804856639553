import React from "react";
import Game from "../Data/GameData/Game/Game";
import StaticGameData from "../Data/GameData/StaticGameData";
import FactionLogo from "../GameView/Utils/FactionLogo";
import DynamicView from "../GameView/DynamicView";
import Popup from "../Data/Other/Popup";
import GameView from "../GameView/GameView";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import MenuNavigationView from "../MainMenu/MenuNavigationView";
import GameGlobalLayout from "../GameView/GameGlobalLayout";
import BaseButton from "../GameView/Utils/BaseButton";
import Request from "../Common/Requests/Request";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import BaseInput from "../GameView/Utils/BaseInput";
import Time from "../Data/GameData/Time/Time";

function GameRankingView({ game }) {
  const ranking = game.ranking;

  console.log("debug Game : ", game);

  const DisplayRow = ({ row }) => {
    const score = row.score;
    const faction = row.faction;
    const player = Game.getPlayerFromFaction(game, faction);
    const playerDisplayName = Game.getPlayerDisplayName(player);
    const rank = row.rank;
    console.log("row: ", row);

    return (
      <div style={{ display: "grid", gridTemplateColumns: "2fr 2fr 2fr" }}>
        <div>
          <FactionLogo factionName={faction}></FactionLogo> {playerDisplayName}
        </div>
        <div>{score} VP</div>
        <div>
          {row.initialElo}{" "}
          <span>
            (
            {row.eloChange < 0 ? (
              <span> -{-row.eloChange}</span>
            ) : (
              <span> +{row.eloChange}</span>
            )}{" "}
            )
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className="text-box-center"
      style={
        {
          //boxShadow: "inset 0 0 0 4px pink", // Simulate an inner border
        }
      }
    >
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 2fr 2fr",
            textDecoration: "underline",
          }}
        >
          <div>Player</div>
          <div>VP</div>{" "}
          <div>
            Elo{" "}
            {Game.isRanked(game) ? (
              <span>(Ranked)</span>
            ) : (
              <span>(Custom)</span>
            )}
          </div>
        </div>
        {ranking.map((row, index) => (
          <div key={index}>
            <div>
              <DisplayRow row={row}></DisplayRow>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GameRankingView;
