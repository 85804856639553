import React, { useState } from "react";
import "../CSS/Font.css";
import "../CSS/Menu.css";
import BaseButton from "./Utils/BaseButton";
import TaskBarView from "./TaskBarView";
import PlayerData from "../Data/GameData/PlayerData/PlayerData";
import Logo from "./Utils/Logo";
import LogoAndTextView from "./Utils/LogoAndTextView";

const Popup = require("../Data/Other/Popup");
const StaticGameData = require("../Data/GameData/StaticGameData");
const Color = require("../Common/Config/Colors");

const TitleView = ({
  children,
  showResourceBar = true,
  titleComponent = null,
}) => {
  const playerData = StaticGameData.getPlayerData();
  const color = PlayerData.getPlayerColor(StaticGameData.getPlayerData());

  const style = {
    //color: color ? `${color}` : "white",
    //backgroundColor: Color.COLOR_GREY_3,
    backgroundColor: "black",
    //borderBottom: "2px solid " + Color.COLOR_GREY_5,
    borderBottom: "1px solid white",
  };

  return (
    <div style={style}>
      {showResourceBar && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
            justifyContent: "center",

            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {" "}
          <div className="div-fill" style={{}}>
            <LogoAndTextView style={{}}>
              <Logo logoName="credit" heightRem="1em"></Logo>{" "}
              <span style={{ marginLeft: "0.2em" }}> {playerData.credit}</span>
            </LogoAndTextView>
          </div>
          <div className="div-fill" style={{}}>
            <LogoAndTextView style={{}}>
              <Logo logoName="mineral" heightRem="1em"></Logo>{" "}
              <span style={{ marginLeft: "0.2em" }}> {playerData.mineral}</span>
            </LogoAndTextView>
          </div>
          <div className="div-fill" style={{}}>
            <LogoAndTextView>
              <Logo logoName="science" heightRem="1em"></Logo>{" "}
              <span style={{ marginLeft: "0.2em" }}> {playerData.science}</span>
            </LogoAndTextView>
          </div>
          <div className="div-fill" style={{}}>
            <LogoAndTextView>
              <Logo logoName="population" heightRem="1em"></Logo>{" "}
              <span style={{ marginLeft: "0.2em" }}>
                {" "}
                {playerData.population}
              </span>
            </LogoAndTextView>
          </div>
          <div className="div-fill" style={{}}>
            <LogoAndTextView>
              <Logo logoName="energy" heightRem="1em"></Logo>{" "}
              <span style={{ marginLeft: "0.2em" }}> {playerData.energy}</span>
            </LogoAndTextView>
          </div>{" "}
        </div>
      )}
      {titleComponent && <span>{titleComponent}</span>}
      {!titleComponent && <div>{children}</div>}
    </div>
  );
};

export default TitleView;
