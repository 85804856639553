const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const System = require("../MapData/System.js");
const Unit = require("../MapData/Unit.js");
const Map = require("../MapData/Map.js");
const Fleet = require("../MapData/Fleet.js");
const LogBook = require("../Connection/LogBook.js");
const LogMessage = require("../Connection/LogMessage.js");
const UIMessage = require("../Connection/UIMessage.js");
const SolveCapacityData = require("../MandatoryAction/SolveCapacityData.js");
const SolveFleetLimitData = require("../MandatoryAction/SolveFleetLimitData.js");

class CheckAction {
  static checkMandatoryActions(playerData) {
    this.checkFleetMandatoryAction(playerData);
  }

  static checkFleetMandatoryAction(playerData) {
    const systemList = Map.getSystemList(playerData.map);
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];

      const fleet = System.getFleet(system, playerData.faction.name);
      if (fleet) {
        try {
          System.checkLogisticMass(playerData, system);
        } catch {
          SolveFleetLimitData.prepareStep(playerData, system);
        }
        try {
          System.checkCapacity(playerData, system);
        } catch {
          SolveCapacityData.prepareStep(playerData, system);
        }
      }
    }
  }
}

module.exports = CheckAction;
