import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import BaseButton from "../GameView/Utils/BaseButton";
import ImagesUrl from "../Data/Other/ImagesUrl";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import Color from "../Common/Config/Colors";
import StaticGameData from "../Data/GameData/StaticGameData";
import WelcomeScreen from "../Main/WelcomeScreen";
import BaseImage from "../GameView/Utils/BaseImage";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import BaseInput from "../GameView/Utils/BaseInput";
import Request from "../Common/Requests/Request";

const Popup = require("../Data/Other/Popup");

export const ForgotPasswordScreen = ({}) => {
  const [email, setEmail] = useState("");

  const handleSendMail = async () => {
    if (!email) {
      UIMessage.displayInfoMessageToStaticGameData(
        null,
        "Please enter your email."
      );
      return;
    }

    Request.callPostAPI({ email: email }, "forgot-password", (response) => {});
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div>Enter you email address : </div>
      <LineSpace></LineSpace>
      <div>
        <BaseInput
          value={email}
          onChange={(e) => {
            const input = e.target.value;
            setEmail(input);
          }}
        ></BaseInput>
      </div>
      <LineSpace></LineSpace>
      <div>
        <BaseButton onClick={handleSendMail}>
          Send reset password email
        </BaseButton>
      </div>
      <LineSpace></LineSpace>
      <div>
        <BaseButton
          onClick={() => {
            Popup.goBack();
          }}
        >
          Back
        </BaseButton>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;
