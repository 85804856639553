import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton.jsx";
import Popup from "../../Data/Other/Popup.js";
import SpaceObjectSummaryView from "./SpaceObjectSummaryView.jsx";
import NavigationView from "../NavigationView.jsx";
import GameGlobalLayout from "../GameGlobalLayout.jsx";
import TitleView from "../TitleView.jsx";
import StaticGameData from "../../Data/GameData/StaticGameData.js";
import PlanetViewActivation from "./ActivationView/Objects/PlanetViewActivation.jsx";
import PlanetView from "../Object/PlanetView.jsx";
import MinorFactionObjectView from "../Object/MinorFactionObjectView.jsx";
import ObjectiveView from "../Object/ObjectiveView.jsx";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Request = require("../../Common/Requests/Request.js");
const Map = require("../../Data/GameData/MapData/Map.js");

const ManageMinorFactionView = ({ minorFaction, spaceObject }) => {
  console.log("minorFaction", minorFaction);
  const navigationData = {
    buttonsData: [{ text: "Back", callback: () => Popup.goBack() }],
  };

  const DisplayMinorFaction = () => {
    return (
      <div>
        {" "}
        <MinorFactionObjectView
          mapView={false}
          size={256}
          spaceObject={spaceObject}
        ></MinorFactionObjectView>
      </div>
    );
  };

  return (
    <GameGlobalLayout backgroundType="shadow">
      <div className="text-box">
        {" "}
        <DisplayMinorFaction></DisplayMinorFaction>
      </div>

      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default ManageMinorFactionView;
