import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import BaseButton from "../GameView/Utils/BaseButton";
import ImagesUrl from "../Data/Other/ImagesUrl";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import Color from "../Common/Config/Colors";
import StaticGameData from "../Data/GameData/StaticGameData";
import WelcomeScreen from "../Main/WelcomeScreen";
import BaseImage from "../GameView/Utils/BaseImage";
import { ForgotPasswordScreen } from "./ForgotPasswordScreen";

const Popup = require("../Data/Other/Popup");

export const LoginMainScreen = ({ setIsUserLogged }) => {
  const [activeScreen, setActiveScreen] = useState("Login");

  return (
    <div>
      {activeScreen == "Login" && (
        <LoginScreen
          setActiveScreen={setActiveScreen}
          setIsUserLogged={setIsUserLogged}
        ></LoginScreen>
      )}
      {activeScreen == "Register" && <RegisterScreen></RegisterScreen>}
    </div>
  );
};

export const LoginScreen = ({ setActiveScreen, setIsUserLogged }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Perform login validation and logic here
    // You can use the username and password states to perform authentication
    // Create an object with the login credentials
    const loginData = {
      username,
      password,
    };

    // Send a POST request to the server for login validation
    fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the server
        if (data.success) {
          // Login successful, do something (e.g., update UI, set user as logged in)
          console.log("Login successful");
          setIsUserLogged(true);
        } else {
          // Login failed, handle error (e.g., show error message)
          console.log("Login failed:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error occurred during login:", error);
      });

    // Reset the form
    setUsername("");
    setPassword("");
  };

  const handleForgotPassword = () => {
    console.log("Forgot password clicked");
    Popup.addDynamicLayer(<ForgotPasswordScreen></ForgotPasswordScreen>);
  };

  const handleRegister = () => {
    // Handle register logic here
    setActiveScreen("Register");
  };

  const handleLogin = () => {
    // Perform login validation and logic here
    // You can use the username and password states to perform authentication
    // Create an object with the login credentials
    const loginData = {
      username,
      password,
    };

    // Send a POST request to the server for login validation
    fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the server
        if (data.success) {
          // Login successful, do something (e.g., update UI, set user as logged in)
          console.log("Login successful");
          setIsUserLogged(true);
          StaticGameData.username = data.username;
          Popup.addDynamicLayer(<WelcomeScreen></WelcomeScreen>);
        } else {
          // Login failed, handle error (e.g., show error message)
          console.log("Login failed:", data.message);
          setErrorMessage("Incorrect login information. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error occurred during login:", error);
      });

    // Reset the form
    setUsername("");
    setPassword("");
  };

  const inputStyle = {
    fontSize: "1.2em",
    borderRadius: "10px",
    padding: "10px",
    backgroundColor: Color.COLOR_MEDIUM_SHADOW,
    color: "white",
    fontFamily: "CustomFont",
    //borderColor: "rgb(13,21,34)",
    borderWidth: "4px",
    borderStyle: "solid",
    borderColor: Color.BASE_BUTTON_BORDER,
  };
  return (
    <div
      style={
        {
          //outline: "2px solid rgba(0,255, 0, 0.7)",
          //outlineOffset: "-4px",
        }
      }
    >
      <BaseImage
        src={ImagesUrl.TITLE_IMAGE}
        alt="Title Image"
        width="400px"
        style={{
          maxWidth: "95vw",
        }}
      />
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Username : </label>{" "}
        </div>
        <LineSpace></LineSpace>
        <div>
          <input
            className="custom-input"
            style={inputStyle}
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
          />
        </div>
        <LineSpace></LineSpace>
        <div>
          <label htmlFor="password">Password : </label>{" "}
        </div>
        <LineSpace></LineSpace>
        <div>
          <input
            style={inputStyle}
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <LineSpace></LineSpace>
        {errorMessage && (
          <div>
            {errorMessage}
            <LineSpace></LineSpace>
          </div>
        )}
        <div>
          <BaseButton onClick={handleLogin}>Login</BaseButton>
        </div>
        <LineSpace></LineSpace>
        <div>
          <BaseButton onClick={handleForgotPassword}>
            Forgot Password
          </BaseButton>
        </div>
        <LineSpace></LineSpace>
        <div>
          <BaseButton onClick={handleRegister}>Create Account</BaseButton>
        </div>
      </form>
      <LineSpace></LineSpace>
      <LineSpace></LineSpace>
    </div>
  );
};

export const RegisterScreen = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registeredUser, setRegisteredUser] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the email fields match
    if (formData.email !== formData.confirmEmail) {
      setErrorMessage("Emails do not match.");
      return;
    }

    // Check if the password fields match
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    // Clear any previous error message if the form is valid
    setErrorMessage("");

    try {
      // Send the form data to the server for account creation
      const response = await fetch("/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: formData.username,
          email: formData.email,
          password: formData.password,
        }),
      });

      const data = await response.json();
      console.log("Registration response:", data);

      if (data.success) {
        // Registration successful, do something (e.g., show success message, redirect, etc.)
        setRegisteredUser(data.user); // Save the registered user information
        setRegistrationSuccess(true); // Set registration success flag to true
      } else {
        // Registration failed, handle the error
        setErrorMessage(data.message);
      }

      // Handle the server response as needed (e.g., show success message, redirect, etc.)
    } catch (error) {
      console.error("Error occurred during registration:", error);
      // Handle the error as needed (e.g., show error message)
    }
  };

  const handleRegister = async () => {
    // Check if the email fields match
    if (formData.email !== formData.confirmEmail) {
      setErrorMessage("Emails do not match.");
      return;
    }

    // Check if the password fields match
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    // Clear any previous error message if the form is valid
    setErrorMessage("");

    try {
      // Send the form data to the server for account creation
      const response = await fetch("/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: formData.username,
          email: formData.email,
          password: formData.password,
        }),
      });

      const data = await response.json();
      console.log("Registration response:", data);

      if (data.success) {
        // Registration successful, do something (e.g., show success message, redirect, etc.)
        setRegisteredUser(data.user); // Save the registered user information
        setRegistrationSuccess(true); // Set registration success flag to true
      } else {
        // Registration failed, handle the error
        setErrorMessage(data.message);
      }

      // Handle the server response as needed (e.g., show success message, redirect, etc.)
    } catch (error) {
      console.error("Error occurred during registration:", error);
      // Handle the error as needed (e.g., show error message)
    }
  };

  if (registrationSuccess && registeredUser) {
    return <RegistrationSuccess user={registeredUser} />;
  }

  const inputStyle = {
    fontSize: "1.2em",
    borderRadius: "10px",
    padding: "10px",
    backgroundColor: Color.COLOR_MEDIUM_SHADOW,
    color: "white",
    fontFamily: "CustomFont",
    //borderColor: "rgb(13,21,34)",
    borderWidth: "4px",
    borderStyle: "solid",
    borderColor: Color.BASE_BUTTON_BORDER,
  };

  return (
    <div className="text-box-center">
      <BaseImage
        src={ImagesUrl.TITLE_IMAGE}
        alt="Title Image"
        width="400px"
        style={{ maxWidth: "95vw" }}
      />
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <div>
            <label htmlFor="username">Username: </label>
          </div>{" "}
          <div>
            {" "}
            <input
              className="custom-input"
              style={inputStyle}
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>{" "}
          <LineSpace></LineSpace>
        </div>
        <div>
          <div>
            <label htmlFor="email">Email: </label>
          </div>{" "}
          <div>
            {" "}
            <input
              className="custom-input"
              style={inputStyle}
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>{" "}
          <LineSpace></LineSpace>
        </div>
        <div>
          <div>
            {" "}
            <label htmlFor="confirmEmail">Confirm Email: </label>
          </div>{" "}
          <div>
            <input
              className="custom-input"
              style={inputStyle}
              type="email"
              id="confirmEmail"
              name="confirmEmail"
              value={formData.confirmEmail}
              onChange={handleChange}
              required
            />
          </div>
          <LineSpace></LineSpace>
        </div>
        <div>
          {" "}
          <div>
            <label htmlFor="password">Password: </label>
          </div>
          <div>
            <input
              className="custom-input"
              style={inputStyle}
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <LineSpace></LineSpace>
        </div>
        <div>
          <div>
            <label htmlFor="confirmPassword">Confirm Password: </label>
          </div>{" "}
          <div>
            <input
              className="custom-input"
              style={inputStyle}
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <LineSpace></LineSpace>
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <div>
          <BaseButton onClick={handleRegister}>Register</BaseButton>
        </div>
      </form>
      <LineSpace></LineSpace>
      <LineSpace></LineSpace>
    </div>
  );
};

const RegistrationSuccess = ({ user }) => {
  return (
    <div>
      <BaseImage
        src={ImagesUrl.TITLE_IMAGE}
        alt="Title Image"
        width="400px"
        style={{ maxWidth: "95vw" }}
      />
      <h2>Registration Successful!</h2>

      <p>User Information:</p>
      <p>Username: {user.username}</p>
      <p>Email: {user.email}</p>
      <BaseButton onClick={() => window.location.reload()}>
        Go Back to login screen
      </BaseButton>
    </div>
  );
};
