import React, { useState } from "react";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";
import FactionLogo from "../Utils/FactionLogo";
import CostView from "../Utils/CostView";
import ValueView from "../Utils/ValueView";
import Logo from "../Utils/Logo";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const Popup = require("../../Data/Other/Popup");
const Unit = require("../../Data/GameData/MapData/Unit");
const Color = require("../../Common/Config/Colors");
const Rules = require("../../Data/GameData/Game/Rules");

const UnitDescription = ({ unit }) => {
  const DsiplayDescription = ({ unit }) => {
    if (unit.type === Unit.UNIT_TYPE_FIGHTER) {
      return (
        <div>
          {Unit.getPrintingName(unit)} is a small ship escorting bigger ships to
          protect them. It has to be carried inside ships whith capacity.
        </div>
      );
    }
    if (unit.type === Unit.UNIT_TYPE_DESTROYER) {
      return (
        <div>
          {Unit.getPrintingName(unit)} can travel rather fast and far. It has
          low combat values, but It does not cost much. It is very good for
          exploration.
        </div>
      );
    }
    if (unit.type === Unit.UNIT_TYPE_CRUSER) {
      return (
        <div>
          {Unit.getPrintingName(unit)} can travel rather fast and far. It has
          medium combat values. It does not shine in any area, but is good all
          around.
        </div>
      );
    }
    if (unit.type === Unit.UNIT_TYPE_DREADNOUGH) {
      return (
        <div>
          {Unit.getPrintingName(unit)} is slow and heavy. They have high combat
          values and high HP. It shines as big battle ship but is not good for
          exploration.
        </div>
      );
    }
    if (unit.type === Unit.UNIT_TYPE_CARRIER) {
      return (
        <div>
          {Unit.getPrintingName(unit)} is slow and has low combat value. Its
          interest is in its capacity value. It can carry lots of other units.
        </div>
      );
    }
    if (unit.type === Unit.UNIT_TYPE_INFANTRY) {
      return (
        <div>
          {Unit.getPrintingName(unit)} is a cheap ground forces. In most
          faction, It is composed of beings with guns and armors.
        </div>
      );
    }
    if (unit.type === Unit.UNIT_TYPE_MECH) {
      return (
        <div>
          {Unit.getPrintingName(unit)} is a technological ground forces. It is
          strong and resistant, but has its cost.
        </div>
      );
    }
    if (unit.type === Unit.UNIT_TYPE_GENERATOR) {
      return (
        <div>
          {Unit.getPrintingName(unit)} generates an mount of energy during the
          production step. The amount of energy depends on the planet it is on.
        </div>
      );
    }
    if (unit.type === Unit.UNIT_TYPE_FACTORY) {
      return (
        <div>
          {Unit.getPrintingName(unit)} can build units during the build units
          step. It can build most of the units of the game, but some units
          requires another structure to be built.
        </div>
      );
    }
    if (unit.type === Unit.UNIT_TYPE_ASSEMBLY_ORBITAL) {
      return (
        <div>
          {Unit.getPrintingName(unit)} can build big units which has to be
          assembled in space.
        </div>
      );
    }
    if (unit.type === Unit.UNIT_TYPE_PLANETARY_CANON) {
      return (
        <div>
          {Unit.getPrintingName(unit)} is a defensive canon which shoots on the
          ennemy ships before the start of the space combat and on the ennemy
          ground forces before the start of the ground combat.
        </div>
      );
    }
    if (unit.type === Unit.UNIT_TYPE_PLANETARY_SHIELD) {
      return (
        <div>
          {Unit.getPrintingName(unit)} protects the planet from bombardment.
        </div>
      );
    }
    if (unit.type === Unit.UNIT_TYPE_SUPPLY_BASE) {
      return (
        <div>
          <div>
            {" "}
            {Unit.getPrintingName(unit)} increases your fleet limit by{" "}
            {Rules.SUPPLY_BASE_FLEET_LIMIT_GAIN}.
          </div>
          <div>
            {" "}
            IMPORTANT : each {Unit.getPrintingName(unit)} consumes{" "}
            {Rules.SUPPLY_BASE_ENERGY_CONSUMPTION} energy{" "}
            <Logo logoName="energy"></Logo> at the end of the round. You can see
            how many energy will be consumed by the (-x) next to your energy
            indicator at the top of the screen.
          </div>
        </div>
      );
    }
  };

  return <DsiplayDescription unit={unit}></DsiplayDescription>;
};

export default UnitDescription;
