import React, { useState, useEffect, useRef, Children } from "react";

import ChatTag from "../../Data/ChatData/ChatTag.js";

const tagMappings = ChatTag.tagMappings;

class ChatTagMethods {
  static replaceTagsInString = (text) => {
    //console.log("debug text in:", text);

    // Dynamically build a regex pattern from tagMappings keys
    const validTags = Object.keys(tagMappings)
      .map((key) => key.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")) // Escape special regex characters
      .join("|"); // Join keys with `|` to create an OR pattern

    const regex = new RegExp(`(${validTags})`, "g"); // Create the regex for exact matches
    //console.log("debug regex:", regex);

    // Replace valid tags with corresponding mapped values
    const textOut = text.replace(regex, (match) => tagMappings[match] || match);

    //console.log("debug text out:", textOut);
    return textOut;
  };

  static reverseReplaceTags = (html) => {
    //console.log("debug html in:", html);

    // Generate relaxed regex patterns based on the `alt` attribute
    const reverseMappings = Object.entries(tagMappings).reduce(
      (acc, [key, value]) => {
        const altMatch = value.match(/alt="([^"]+)"/); // Extract the alt attribute
        if (altMatch) {
          const alt = altMatch[1];
          //console.log("debug alt:", alt);

          // Create a regex to match the <img> tag based on the alt attribute
          const regex = `<img[^>]*alt=["']${alt}["'][^>]*>`; // Use string representation
          acc[regex] = key; // Map the regex string to the corresponding tag key
        }
        return acc;
      },
      {}
    );

    let processedText = html;
    for (const [regexString, tag] of Object.entries(reverseMappings)) {
      const regex = new RegExp(regexString, "gi"); // Recreate the RegExp object from the string
      //console.log("debug regex:", regex);
      processedText = processedText.replace(regex, tag);
    }

    //console.log("debug text out:", processedText);

    return processedText;
  };
}

export default ChatTagMethods;
