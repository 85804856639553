import React, { useState } from "react";
import "../CSS/Font.css";
import "../CSS/Menu.css";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import BaseButton from "./Utils/BaseButton";
import GameState from "../Data/GameData/Game/GameState";
import PlayerData from "../Data/GameData/PlayerData/PlayerData";
import LogBookView from "./Utils/LogMessage/LogBookView";
import TransactionData from "../Data/GameData/Transactions/TransactionData";
import Transaction from "../Data/GameData/Transactions/Transaction";
import TransactionView from "./Transaction/TransactionView";
import Request from "../Common/Requests/Request";
import TransactionItemsView from "./Transaction/TransactionItemsView";
import LogBook from "../Data/GameData/Connection/LogBook";
import FactionLogo from "./Utils/FactionLogo";
import Logo from "./Utils/Logo";
import Phase from "../Data/GameData/Game/Phase";
import BaseImage from "./Utils/BaseImage";
import ImagesUrl from "../Data/Other/ImagesUrl";

const StaticGameData = require("../Data/GameData/StaticGameData");
const Popup = require("../Data/Other/Popup");
const Color = require("../Common/Config/Colors");

const DisplayTransactionMessage = ({
  transactionId,
  messageTreatedCallback,
  message,
}) => {
  /*return (
    <div>
      <div>TEST</div>
      <div>
        <BaseButton
          //style={{}}
          onClick={() => {
            const playerData = StaticGameData.getPlayerData();
            UIMessage.removeMessageToDisplay(StaticGameData.getPlayerData());
            messageTreatedCallback();
            Popup.touch();
          }}
        >
          <div style={{ fontSize: "1.5em" }}>Ok</div>
        </BaseButton>
      </div>
    </div>
  );*/
  const playerData = StaticGameData.getPlayerData();

  const transactionData = StaticGameData.getTransactionData();
  const transaction = TransactionData.getTransactionFromId(
    transactionData,
    transactionId
  );

  const isAccepted = TransactionData.isTransactionAccepted(
    transactionData,
    transactionId
  );

  const isRejected = TransactionData.isTransactionRejected(
    transactionData,
    transactionId
  );

  const isProposed = TransactionData.isTransactionProposed(
    transactionData,
    transactionId
  );

  const proposingFaction = Transaction.getProposingFaction(
    transaction.transaction
  );

  const receivingFaction = Transaction.getReceivingFaction(
    transaction.transaction
  );

  const logBook = LogBook.generateLogBook(
    "This is a transaction. Transaction are used to exchange items between factions.",
    []
  );
  console.log("MessageView: messages", playerData.messageToDisplay);

  return (
    <div>
      <div>
        {message.action === "proposed" && isProposed && (
          <span>
            <FactionLogo factionName={proposingFaction}></FactionLogo> is
            proposing to you the following transaction. You can accept it,
            reject it, or wait. If you wait, you can still consult it in the
            transaction menu : <Logo logoName="transaction icon"></Logo> list,
            and accept it later.
          </span>
        )}
        {message.action === "proposed" && isAccepted && (
          <span>
            <FactionLogo factionName={proposingFaction}></FactionLogo>
            proposed to you the following transaction. It has been automatically
            accepted, as nothing was expected in return.
          </span>
        )}
        {message.action === "accepted" && isAccepted && (
          <span>
            <FactionLogo factionName={receivingFaction}></FactionLogo>
            accepted the following transaction.
          </span>
        )}
        {message.action === "rejected" && isRejected && (
          <span>
            <FactionLogo factionName={message.rejectingFaction}></FactionLogo>
            rejected the following transaction.
          </span>
        )}
      </div>
      <div>
        {" "}
        <TransactionView
          transaction={transaction.transaction}
          buttonType="greyOut"
          detailedView={true}
          onClick={() => {
            UIMessage.displayInfoLogBook(logBook, { displayOnTop: true });
          }}
        ></TransactionView>
      </div>
      <div>
        {isProposed ? (
          <div>
            <BaseButton
              //style={{}}
              onClick={() => {
                const afterConfirm = () => {
                  playerData.phase = Phase.PHASE_ACTION;
                  playerData.step = Phase.STEP_ACCEPT_TRANSACTION;
                  Request.updateGameState(
                    { transactionId: transactionId },
                    true
                  );
                };
                messageTreatedCallback(message);
                UIMessage.removeMessageToDisplay(playerData);

                UIMessage.displayConfirmMessage(
                  "Accept ?",
                  "Are you sure to accept this transaction ?",
                  afterConfirm,
                  false,
                  { displayOnTop: true }
                );
              }}
            >
              <div style={{ fontSize: "1em" }}>Accept</div>
            </BaseButton>
            <BaseButton
              //style={{}}
              onClick={() => {
                const afterConfirm = () => {
                  playerData.phase = Phase.PHASE_ACTION;
                  playerData.step = Phase.STEP_REJECT_TRANSACTION;
                  Request.updateGameState(
                    { transactionId: transactionId },
                    true
                  );
                };
                messageTreatedCallback(message);
                UIMessage.removeMessageToDisplay(playerData);
                UIMessage.displayConfirmMessage(
                  "Reject ?",
                  "Are you sure to reject this transaction ?",
                  afterConfirm,
                  false,
                  { displayOnTop: true }
                );
              }}
            >
              <div style={{ fontSize: "1em" }}>Reject</div>
            </BaseButton>
            <BaseButton
              //style={{}}
              onClick={() => {
                UIMessage.removeMessageToDisplay(
                  StaticGameData.getPlayerData()
                );
                messageTreatedCallback(message);
                Popup.touch();
              }}
            >
              <div style={{ fontSize: "1em" }}>Wait</div>
            </BaseButton>
          </div>
        ) : (
          <div>
            <BaseButton
              //style={{}}
              onClick={() => {
                UIMessage.removeMessageToDisplay(
                  StaticGameData.getPlayerData()
                );
                messageTreatedCallback(message);
                Popup.touch();
              }}
            >
              <div style={{ fontSize: "1em" }}>Ok</div>
            </BaseButton>
          </div>
        )}
      </div>
    </div>
  );
};

const DisplayTutorialMessage = ({ message }) => {
  const playerData = StaticGameData.getPlayerData();
  return (
    <div>
      <div className="div-fill">
        <div
          style={{
            borderWidth: "3px",
            borderStyle: "solid",
            borderColor: Color.BASE_BUTTON_BORDER,
            borderRadius: "10px",
            overflow: "hidden",
            width: "128px",
            height: "128px",
          }}
        >
          <BaseImage
            src={ImagesUrl.TUTORIAL_AURION}
            width="128px"
            height="128px"
          ></BaseImage>
        </div>
      </div>

      <div>
        <LogBookView logBook={message.logBook} textAlign="center"></LogBookView>
      </div>
      <div>
        <BaseButton
          onClick={() => {
            UIMessage.removeMessageToDisplay(playerData);
            Popup.touch();
          }}
        >
          Ok
        </BaseButton>
      </div>
    </div>
  );
};

const MessageView = ({ playerData }) => {
  let message = null;
  if (playerData) {
    message = UIMessage.getFirstMessageToDisplay(playerData);
  } else {
    message = UIMessage.getFirstMessageToDisplayinStaticGameData();
  }

  if (!message) {
    throw new Error("No message to display");
  }

  if (!message.type) {
    throw new Error("No message type");
  }

  const messageTreatedCallback = (message) => {
    if (message.id) {
      console.log("messageTreatedCallback: message", message);
      Request.callPostAPI(
        {
          messagesToRemove: [message.id],
          gameId: StaticGameData.gameState.gameId,
        },
        "gameState-update-light",
        () => {},
        false
      );
    }
  };

  /*if (message.format === UIMessage.MESSAGE_FORMAT_TEXT && !message.lines) {
    throw new Error("No message lines");
  }*/
  //console.log("MessageView: message", message);
  // Define background color based on message type
  let backgroundColor;
  if (message.type === UIMessage.TYPE_ERROR) {
    //backgroundColor = "rgb(255, 200, 200)"; // Red background for error
  } else if (message.type === UIMessage.TYPE_INFO) {
    //backgroundColor = "rgb(200, 200, 255)"; // Blue background for info
    //backgroundColor = "rgb(200, 200, 255)"; // Blue background for info
  } else {
    //backgroundColor = "rgb(31, 31, 31)"; // Default background color
  }
  //backgroundColor = "rgb(0, 0, 0,1)";
  backgroundColor = Color.COLOR_HEAVY_SHADOW;

  const style = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 2000,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  };

  const callback = StaticGameData.popup.callback;

  const handleMouseDownOnBackground = (e) => {
    e.preventDefault();
    //e.stopPropagation();
  };

  const handleMouseUpOnBackground = (e) => {
    //e.preventDefault();
    //e.stopPropagation();
    if (UIMessage.TYPE_INFO === message.type) {
      if (playerData) {
        UIMessage.removeMessageToDisplay(playerData);
      } else {
        UIMessage.removeMessageToDisplayFromStaticGameData();
      }
      Popup.touch();
    }
  };

  const Title = () => {
    return (
      <div
        style={{
          fontSize: "1.5em",
          color: "white",
          //border: "2px solid blue",
          padding: "10px",
          width: "100%",
        }}
      >
        {message.name}
      </div>
    );
  };

  const Content = () => {
    return (
      <div
        style={{
          fontSize: "1rem",
          color: "white",
          //border: "2px solid yellow",
          padding: "10px",
          width: "100%",
          maxHeight: "100%",
          overflow: "auto",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            textAlign: "left",
            maxHeight: "100%",
            overflowY: "auto",
            width: "100%",
          }}
        >
          {message.format === UIMessage.MESSAGE_FORMAT_TEXT &&
            message.lines.map((line, index) => {
              return <div key={index}>{line}</div>;
            })}
        </div>
        {message.format === UIMessage.MESSAGE_FORMAT_LOGBOOK && (
          <LogBookView logBook={message.logBook}></LogBookView>
        )}
        {message.format === UIMessage.MESSAGE_FORMAT_COMPONENT && (
          <div>{message.component}</div>
        )}
        {(message.type === UIMessage.TYPE_INFO ||
          message.type === UIMessage.TYPE_ERROR) && (
          <div style={{ padding: "10px" }}>
            <BaseButton
              //style={{}}
              onClick={() => {
                if (playerData) {
                  UIMessage.removeMessageToDisplay(
                    StaticGameData.getPlayerData()
                  );
                } else {
                  UIMessage.removeMessageToDisplayFromStaticGameData();
                }
                messageTreatedCallback(message);
                Popup.touch();
              }}
            >
              <div style={{ fontSize: "1.5em" }}>Ok</div>
            </BaseButton>
          </div>
        )}

        {message.type === UIMessage.TYPE_CONFIRM && (
          <div style={{ padding: "10px" }}>
            <BaseButton
              //style={{}}
              onClick={() => {
                if (playerData) {
                  UIMessage.removeMessageToDisplay(
                    StaticGameData.getPlayerData()
                  );
                } else {
                  UIMessage.removeMessageToDisplayFromStaticGameData();
                }
                messageTreatedCallback(message);
                Popup.touch();
              }}
            >
              <div style={{ fontSize: "1.5em" }}>Cancel</div>
            </BaseButton>
            <BaseButton
              //style={{}}
              onClick={() => {
                if (playerData) {
                  UIMessage.removeMessageToDisplay(
                    StaticGameData.getPlayerData()
                  );
                } else {
                  UIMessage.removeMessageToDisplayFromStaticGameData();
                }
                messageTreatedCallback(message);
                Popup.touch();

                message.confirmCallback();
              }}
            >
              <div style={{ fontSize: "1.5em" }}>Confirm</div>
            </BaseButton>
          </div>
        )}
        {message.type === UIMessage.TYPE_TRANSACTION && (
          <DisplayTransactionMessage
            message={message}
            transactionId={message.transactionId}
            messageTreatedCallback={messageTreatedCallback}
          />
        )}
        {message.type === UIMessage.TYPE_TUTORIAL && (
          <DisplayTutorialMessage message={message} />
        )}
      </div>
    );
  };

  return (
    <div
      style={style}
      onMouseDown={handleMouseDownOnBackground}
      onMouseUp={handleMouseUpOnBackground}
      className="div-fill"
    >
      <div className="div-fill" style={{ width: "98%", height: "95%" }}>
        <div
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onMouseUp={(e) => {
            e.stopPropagation();
          }}
          style={{
            backgroundColor: backgroundColor,
            height: "auto",
            borderWidth: "4px",
            borderStyle: "solid",
            borderColor: Color.BASE_BUTTON_BORDER,
            borderRadius: "10px",
            //padding: "10px",
            //padding: "10px",
            maxHeight: "100%",
            //maxWidth: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
          className="div-ver-center"
        >
          {message.name && (
            <div>
              <Title></Title>
            </div>
          )}
          <Content></Content>
          {false && (
            <div
              style={{
                //height: "100%",
                width: "100%",
                overflowY: "auto", // Enable scrolling if content overflows
                //overflowX: "hidden",
                //border: "2px solid blue",
              }}
            >
              <Content></Content>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageView;
