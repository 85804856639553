import React, { useState } from "react";

import BaseButton from "../BaseButton";

const ExpandableView = ({
  children,
  expanded = false,
  onToggle = () => {},
  collapsedChildren = () => {},
}) => {
  const [expandedState, setExpandedState] = useState(expanded);

  const handleClick = () => {
    setExpandedState(!expandedState);
    onToggle();
  };

  // Ensure children exist
  if (!children) return null;

  // Convert children to an array if it's not already an array
  const childrenArray = React.Children.toArray(children);

  // Check if childrenArray is empty
  if (childrenArray.length === 0) return null;

  const DisplayCollapsedItems = () => {
    console.log("childrenArray.length ", childrenArray.length);

    // Return JSX elements directly, not an object
    return childrenArray
      .slice(1)
      .map((child, index) => <div key={index}>{child}</div>);
  };

  return (
    <div>
      <div
        style={{
          display: "frid",
          gridTemplateColumns: "5fr, 1fr",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {childrenArray[0]}
        <span> </span>
        {!expandedState && <BaseButton onClick={handleClick}>{">"}</BaseButton>}
        {expandedState && <BaseButton onClick={handleClick}>{"<"}</BaseButton>}
      </div>
      {!expandedState && <div>{collapsedChildren()}</div>}
      {expandedState && (
        <div style={{ marginLeft: "0.5em" }}>
          <DisplayCollapsedItems />
        </div>
      )}
    </div>
  );
};

export default ExpandableView;
