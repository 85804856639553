import React, { useState, useEffect } from "react";
import ActivationViewLayout from "./ActivationViewLayout";
import ActivationNavigationView from "./ActivationNavigationView";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import PlanetViewActivation from "./Objects/PlanetViewActivation";
import Logo from "../../Utils/Logo";
import ActivationTransferInView from "./ActivationTransferInView";
import ActivationTransferView from "./ActivationTransferView";
import ActivationCA from "../../../ClientAction/ActivationCA";
import TransferData from "../../../Data/GameData/Activation/TransferData";
import LiftData from "../../../Data/GameData/Activation/LiftData";
import ExpandableView from "../../Utils/Layout/ExpandableView";
import SelectionListView from "../../Utils/Layout/SelectionListView";
import CostView from "../../Utils/CostView";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const ProductionData = require("../../../Data/GameData/Activation/ProductionData.js");
const Planet = require("../../../Data/GameData/MapData/Planet.js");

const ActivationLiftView = ({ system, navigationData }) => {
  console.log("RENDER ActivationLiftView ");
  const playerData = StaticGameData.getPlayerData();

  const data = LiftData.getData(playerData);

  const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);

  navigationData.buttonsData.push(
    {
      text: "Help",
      callback: () => {
        Popup.addLayer({ name: "HelpPopup", helpReference: "liftStep" });
      },
    },
    {
      text: "No Lift",
      callback: () => {
        ActivationCA.initialize_STEP_ACTIVATION_ACTIVATED();
      },
      type: "action",
    },
    {
      text: "Confirm",
      callback: () => {
        const lift = () => {
          ActivationCA.resolve_STEP_ACTIVATION_LIFT("lift");
        };

        UIMessage.displayConfirmMessage(
          "Lift ?",
          "Are you sure you want to lift these units into space ?",
          lift
        );
      },
      type: "action",
    }
  );

  const DisplaySpaceArea = ({ system }) => {
    const spaceAreaFleet = Fleet.createFleet(
      playerData,
      playerData.faction.name
    );
    const systemFleet = System.getFleet(system, playerData.faction.name);
    Fleet.addFleetToFleet(systemFleet, spaceAreaFleet);

    Fleet.addFleetToFleet(data.fleetLifted, spaceAreaFleet);

    const capacity = Fleet.getTotalCapacity(playerData, spaceAreaFleet);
    const requiredCapacity = Fleet.getTotalRequiredCapacity(
      playerData,
      spaceAreaFleet
    );

    //Display cost
    const cost = LiftData.getCostLift(playerData);
    navigationData.child = (
      <div>
        <span>Lift cost : </span>
        <CostView cost={cost}></CostView>
      </div>
    );

    return (
      <div className="text-box">
        <div>Fleet in space : </div>
        <FleetSummaryView fleet={spaceAreaFleet}></FleetSummaryView>
        <div>
          Capacity : {requiredCapacity} / {capacity}
        </div>

        <hr></hr>
      </div>
    );
  };

  const DisplayPlanets = () => {
    const planetFleet = Planet.getFleet(planet, playerData.faction.name);

    const groundForces = Fleet.getUnits(
      planetFleet,
      Fleet.UNIT_CLASS_GROUND_FORCE
    );
    const liftedGroundForces = Fleet.getUnits(
      data.fleetLifted,
      Fleet.UNIT_CLASS_GROUND_FORCE
    );
    const displayItem = (item) => {
      return (
        <UnitLineView
          unit={item}
          factionName={playerData.faction.name}
        ></UnitLineView>
      );
    };
    const selectedItems = liftedGroundForces;
    const selectItem = (item) => {
      Fleet.addUnit(data.fleetLifted, item);

      Popup.touch();
    };

    const unSelectItem = (item) => {
      Fleet.removeUnit(data.fleetLifted, item);
      Popup.touch();
    };

    return (
      <div>
        <PlanetViewActivation
          spaceObject={planet}
          showStock={true}
          showFleet={false}
        ></PlanetViewActivation>

        {!Fleet.isEmpty(planetFleet) ? (
          <SelectionListView
            items={groundForces}
            displayItem={displayItem}
            selectedItems={selectedItems}
            selectItem={selectItem}
            unSelectItem={unSelectItem}
            textSelected="Stay"
            textUnselected="Lift"
          ></SelectionListView>
        ) : (
          <span>You have no units on this planet</span>
        )}
        <hr></hr>
      </div>
    );
  };

  return (
    <div style={{}}>
      <DisplaySpaceArea system={system}></DisplaySpaceArea>
      <DisplayPlanets></DisplayPlanets>
    </div>
  );
};

export default ActivationLiftView;
