class HelpData {
  static TEST = "TEST";

  static data = {
    helpMainScreen: {
      title: "Main Help Screen",
      texts: [
        "This is the main help screen. Here you can find help on the different aspects of the game.",
      ],
      references: ["helpMainScreen"],
    },
    /*activationGlobal: {
      title: "Activation Action",
      jsx: true,
      content: [
        <div>
          <div>
            Master Imperium is a game of strategy and tactics. The activation
            phase is the most important phase in the game.
          </div>
          <div>When you activate a system, it becomes the active system.</div>
          <div>
            "After the activation of a system, the system cannot be activated
            again until the next round.",
          </div>
          <div>
            During the activation of the system, you will go through the
            activation steps sequentially
          </div>
        </div>,
      ],
      references: ["systemActivation", "round"],
    },*/
  };
}

module.exports = HelpData;
