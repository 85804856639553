import React, { useState, useRef } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import ChatStaticData from "../../Data/ChatData/ChatStaticData";
import StaticGameData from "../../Data/GameData/StaticGameData";
import GameUpdate from "../../Data/GameData/Game/GameUpdate";
import Popup from "../../Data/Other/Popup";
import ChatItem from "../../Data/ChatData/ChatItem";
import Request from "../../Common/Requests/Request";
import Faction from "../../Data/GameData/PlayerData/Faction";
import FactionLogo from "../Utils/FactionLogo";
import ChatTag from "../../Data/ChatData/ChatTag";
import ChatTagMethods from "../ChatView/ChatTagMethods";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";
import Logo from "../Utils/Logo";

const tagMappings = ChatTag.tagMappings;

const TransactionInputView = ({
  transaction,
  newMessage,
  setNewMessage,
  handleSubmit,
  inputRef,
}) => {
  //const inputRef = useRef(null);

  const playerData = StaticGameData.getPlayerData();
  const popup = StaticGameData.popup;

  const replaceTags = (text) => {
    return ChatTagMethods.replaceTagsInString(text);
  };

  const moveCaretToEnd = (el) => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false); // Place cursor at the end
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const moveCaretToPosition = (el, cursorPosition) => {
    const selection = window.getSelection();
    const range = document.createRange();
    //console.log("debug cursor----------------");

    let currentOffset = 0;
    let targetNode = null;
    let targetOffset = 0;

    // Traverse child nodes to locate the target node and offset
    el.childNodes.forEach((node) => {
      const nodeLength =
        node.nodeType === Node.TEXT_NODE ? node.textContent.length : 1; // Treat <img> as 1 character
      /*console.log("debug cursor----");
      console.log("debug cursor currentOffset:", currentOffset);
      console.log("debug cursor nodeLength:", nodeLength);
      console.log("debug cursor cursorPosition:", cursorPosition);*/
      if (currentOffset + nodeLength > cursorPosition && !targetNode) {
        targetNode = node;
        targetOffset = cursorPosition - currentOffset; // Offset within the node

        if (node.nodeType === Node.TEXT_NODE) {
          range.setStart(targetNode, targetOffset);
        } else {
          currentOffset += nodeLength;

          range.setStart(targetNode, 0);
        }
      }

      currentOffset += nodeLength;
    });

    // Fallback: If no target node found, use the last node
    if (!targetNode) {
      targetNode = el.lastChild || el;

      if (targetNode.nodeType === Node.TEXT_NODE) {
        // If the last node is a text node, place the cursor at its end
        targetOffset = targetNode.textContent.length;
      } else {
        // If the last node is a non-text node (e.g., <img>), place the cursor in the parent node
        targetOffset = Array.from(el.childNodes).indexOf(targetNode) + 1;
        targetNode = el;
      }

      // Set the range to the calculated node and offset
      range.setStart(targetNode, targetOffset);
    }

    range.collapse(true);

    // Apply the selection
    selection.removeAllRanges();
    selection.addRange(range);

    /* console.log(
      "Cursor moved to node:",
      targetNode,
      "at offset:",
      targetOffset
    );*/
  };

  const getCursorPosition = (el) => {
    const selection = window.getSelection();
    // Check if there is an active selection
    if (!selection.rangeCount) {
      return el.textContent.length; // Return end of content if no selection
    }

    const range = selection.getRangeAt(0);

    let cursorPosition = 0;
    let found = false;
    //console.log("debug el:", el);

    el.childNodes.forEach((node) => {
      if (found) return; // Stop once the cursor position is located

      if (node === range.startContainer) {
        // If the cursor is within this node
        if (node.nodeType === Node.TEXT_NODE) {
          const text = node.textContent.slice(0, range.startOffset);
          const tags = Object.keys(tagMappings);

          // Calculate the cursor position accounting for tag replacements
          let i = 0; // Manual index for skipping
          let offset = 0;

          while (i < text.length) {
            const potentialTag = text.slice(i);
            const matchingTag = tags.find((tag) =>
              potentialTag.startsWith(tag)
            );

            if (matchingTag) {
              // Match found, treat the tag as a single character
              offset += 1;
              i += matchingTag.length; // Skip to the end of the tag
            } else {
              // Regular character
              offset += 1;
              i += 1; // Move to the next character
            }
          }

          cursorPosition += offset;
        } else {
          cursorPosition += range.startOffset;
        }
        found = true;
      } else if (node.nodeType === Node.TEXT_NODE) {
        // Add length of the text node
        cursorPosition += node.textContent.length;
      } else {
        // Add 1 for non-text nodes like <img>
        cursorPosition += 1;
      }
    });

    return cursorPosition;
  };

  const handleInput = (e, asynchrone = true) => {
    const content = e.target.innerHTML; // Use `innerHTML` to retain tags and images
    const cursorPosition = getCursorPosition(e.target); // Get current cursor position

    // Update the state with the user's raw input immediately
    setNewMessage(content);

    // Asynchronously update the innerHTML with formatted content
    if (asynchrone) {
      setTimeout(() => {
        const parsedContent = replaceTags(content); // Replace tags or format text

        // Update the DOM only if necessary
        if (inputRef.current && inputRef.current.innerHTML !== parsedContent) {
          inputRef.current.innerHTML = parsedContent;
          moveCaretToPosition(inputRef.current, cursorPosition); // Restore cursor position
        }
      }, 0);
    } else {
      const parsedContent = replaceTags(content); // Replace tags or format text
      inputRef.current.innerHTML = parsedContent;
      moveCaretToPosition(inputRef.current, cursorPosition); // Restore cursor position
    }
  };

  /*const handleInput = (e) => {
    const content = e.target.innerHTML; // Get the raw HTML content
    setNewMessage(content.replace(/<[^>]+>/g, "")); // Extract plain text for backend use
  };*/

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents new line
      Popup.deActivateBlackPopup();
      handleSubmit();
    }
  };

  const reverseReplaceTags = (html) => {
    return ChatTagMethods.reverseReplaceTags(html);
  };

  const isDescendant = (parent, child) => {
    let node = child;
    while (node) {
      if (node === parent) return true;
      node = node.parentNode;
    }
    return false;
  };

  const handleTagButtonClick = (factionName) => {
    const tag = `:${factionName}:`;

    if (inputRef.current) {
      const selection = window.getSelection();
      //console.log("debug Cursor****************");
      //console.log("debug Cursor selection:", selection);
      const isFocused =
        selection.rangeCount > 0 &&
        isDescendant(
          inputRef.current,
          selection.getRangeAt(0).commonAncestorContainer
        );

      if (isFocused) {
        // Input is focused, insert tag at the current cursor position
        //console.log("debug Cursor handleTag IS FOCUSED");
        const range = selection.getRangeAt(0);
        const cursorPosition = getCursorPosition(inputRef.current); // Capture the current cursor position

        range.deleteContents(); // Remove any selected content

        // Create a text node for the tag
        const tagNode = document.createTextNode(tag);

        // Insert the tag node at the current cursor position
        range.insertNode(tagNode);

        // Calculate the new cursor position after inserting the tag
        const newCursorPosition = cursorPosition + 1;
        /*console.log("debug Cursor handleTag cursorPosition: ", cursorPosition);
        console.log("debug Cursor handleTag tag.length: ", tag.length);
        console.log(
          "debug Cursor handleTag newCursorPosition: ",
          newCursorPosition
        );*/

        // Trigger handleInput to process the updated content
        const fakeEvent = { target: inputRef.current };
        handleInput(fakeEvent, false);

        // Restore the cursor to the new position
        moveCaretToPosition(inputRef.current, newCursorPosition);
      } else {
        //console.log("debug Cursor handleTag NOT FOCUSED");
        // Input is not focused, append tag at the end
        const currentContent = inputRef.current.innerHTML;
        const updatedContent = `${currentContent}${tag}`;
        inputRef.current.innerHTML = updatedContent;

        // Trigger handleInput manually to process the new content
        const fakeEvent = { target: inputRef.current };
        handleInput(fakeEvent, false);

        // Focus the input and move cursor to the end
        inputRef.current.focus();
        moveCaretToEnd(inputRef.current);
      }
    }
  };

  const allPlayerDataList = PlayerData.getAllPlayersData(playerData);

  return (
    <div style={{}}>
      <div style={{ fontSize: "1.3em" }}>
        {allPlayerDataList.map((playerDataItem, index) => {
          if (!playerDataItem.faction) return;
          const factionName = playerDataItem.faction.name;
          return (
            <span style={{}} key={index}>
              <BaseButton
                noPadding={true}
                width="1.9em"
                height="1.9em"
                onClick={() => handleTagButtonClick(factionName)}
              >
                <FactionLogo factionName={factionName}></FactionLogo>
              </BaseButton>
            </span>
          );
        })}
        {false && (
          <span style={{}}>
            <BaseButton noPadding={true} width="1.9em" height="1.9em">
              +
            </BaseButton>
          </span>
        )}
      </div>
      <form
        style={{
          display: "flex",
          padding: "10px",
          //backgroundColor: Color.COLOR_GREY_3,
          //maxHeight: "3em",
        }}
        onSubmit={(e) => e.preventDefault()}
      >
        <div
          contentEditable
          ref={inputRef}
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          placeholder="Type a message..."
          spellCheck="false" // Disable spell check
          //inputMode="none" // Prevent auto-complete or auto-correct
          style={{
            backgroundColor: "#333",
            width: "100%",
            fontFamily: "CustomFont",
            color: "white",
            //maxHeight: "4.5em",
            overflowY: "auto",
            padding: "8px",
            fontSize: "1rem",
            border: "1px solid #ccc",
            borderRadius: "5px",
            whiteSpace: "pre-wrap", // Keep text formatting
            outline: "none",
            textAlign: "left",
          }}
        />
      </form>
    </div>
  );
};

export default TransactionInputView;
