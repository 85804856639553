import React, { useState } from "react";
import StaticGameData from "../../Data/GameData/StaticGameData.js";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData.js";
import FactionLogo from "./FactionLogo.jsx";

const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const ImagesUrl = require("../../Data/Other/ImagesUrl.js");

const IMAGE_FACTION_LOGO_BASE = "/Images/Faction/";

const FactionSpan = ({ factionName }) => {
  const playerData = StaticGameData.getPlayerData();
  const color = PlayerData.getPlayerColor(playerData, factionName);

  return (
    <span>
      <FactionLogo factionName={factionName}></FactionLogo>
      <span style={{ color: color }}> {factionName}</span>
    </span>
  );
};

export default FactionSpan;
