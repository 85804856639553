import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import { getObjectSummaryJSXArray } from "./SpaceObjectInfoArray"; // Adjust the path accordingly
import FactionLogo from "../Utils/FactionLogo";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import UnitLineView from "../Units/UnitLineView";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const SpaceObject = require("../../Data/GameData/MapData/SpaceObject.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const Unit = require("../../Data/GameData/MapData/Unit.js");

const FleetDetailedView = ({ fleet, unitClass = Fleet.UNIT_CLASS_ALL }) => {
  if (!fleet) {
    return null;
  }

  const navigationData = {
    buttonsData: [
      {
        text: "Back",
        callback: () => {
          Popup.goBack();
        },
      },
    ],
  };

  console.log("FleetDetailedView fleet: ", fleet);

  const playerData = StaticGameData.getPlayerData();
  const selectedFactionData = PlayerData.getPlayerDataFromFaction(
    playerData,
    fleet.faction
  );
  if (!selectedFactionData) {
    throw new Error("FleetSummaryView: selectedFactionData is null");
  }

  const DisplayCapacity = ({}) => {
    const requiredCapacity = Fleet.getTotalRequiredCapacity(playerData, fleet);
    const capacity = Fleet.getTotalCapacity(playerData, fleet);
    const logisticMass = Fleet.getLogisticMass(fleet);
    const fleetLimit = PlayerData.getFleetLimit(playerData);

    return (
      <span style={{ color: "white" }}>
        Capacity : {requiredCapacity} / {capacity} , Fleet limit :{" "}
        {logisticMass} / {fleetLimit}
      </span>
    );
  };

  const DisplayShips = ({ fleet, unitClass }) => {
    const fleetShortText = Fleet.getFleetClassShortText(fleet, unitClass);
    if (fleetShortText === "") {
      return null;
    }
    /*const shipsArray = Fleet.getShipsArray(fleet);
    const shipDisplayArray = [];
    for (let i = 0; i < shipsArray.length; i++) {
      const ship = shipsArray[i];
      shipDisplayArray.push(
        <div key={i}>
          <span>
            {ship.type} {ship.subType} {ship.amount}/{ship.damagedAmount}
          </span>
        </div>
      );
    }*/

    return (
      <span>
        <span>
          <FactionLogo factionName={fleet.faction} heightRem={"1em"} />
        </span>
        <span style={{ width: "1em" }}></span>
        <span> {fleetShortText}</span>
      </span>
    );
  };

  const DisplayClass = ({ fleet, unitClass }) => {
    const units = Fleet.getUnits(fleet, unitClass);
    if (units.length === 0) {
      return null;
    }
    //map on units
    return (
      <div style={{}}>
        {unitClass === Fleet.UNIT_CLASS_SHIP && (
          <div style={{ fontSize: "1.2em", marginBottom: "0.5em" }}>
            Ships :{" "}
          </div>
        )}
        {unitClass === Fleet.UNIT_CLASS_GROUND_FORCE && (
          <div style={{ fontSize: "1.2em", marginBottom: "0.5em" }}>
            Ground Forces :{" "}
          </div>
        )}
        {unitClass === Fleet.UNIT_CLASS_STRUCTURE && (
          <div style={{ fontSize: "1.2em", marginBottom: "0.5em" }}>
            Structures :{" "}
          </div>
        )}
        {units.map((unit, index) => {
          return (
            <span key={index} style={{ fontSize: "2em" }}>
              <UnitLineView
                key={index}
                sizeEm={4}
                unit={unit}
                factionName={fleet.faction}
              ></UnitLineView>
            </span>
          );
        })}
        <hr></hr>
      </div>
    );
  };

  const DisplayFleet = ({ fleet, unitClass }) => {
    return (
      <div
        className="text-box"
        style={{
          textAlign: "center",
          fontSize: "1em",
        }}
      >
        <div style={{ marginBottom: "2em" }}>
          <DisplayCapacity></DisplayCapacity>
        </div>

        {(unitClass === Fleet.UNIT_CLASS_SHIP ||
          unitClass === Fleet.UNIT_CLASS_ALL) && (
          <div style={{ marginBottom: "2em" }}>
            <DisplayClass
              fleet={fleet}
              unitClass={Fleet.UNIT_CLASS_SHIP}
            ></DisplayClass>
          </div>
        )}
        {(unitClass === Fleet.UNIT_CLASS_GROUND_FORCE ||
          unitClass === Fleet.UNIT_CLASS_ALL) && (
          <div style={{ marginBottom: "2em" }}>
            <DisplayClass
              fleet={fleet}
              unitClass={Fleet.UNIT_CLASS_GROUND_FORCE}
            ></DisplayClass>
          </div>
        )}
        {(unitClass === Fleet.UNIT_CLASS_STRUCTURE ||
          unitClass === Fleet.UNIT_CLASS_ALL) && (
          <div style={{ marginBottom: "2em" }}>
            <DisplayClass
              fleet={fleet}
              unitClass={Fleet.UNIT_CLASS_STRUCTURE}
            ></DisplayClass>
          </div>
        )}
      </div>
    );
  };

  return (
    <GameGlobalLayout>
      <DisplayFleet fleet={fleet} unitClass={unitClass}></DisplayFleet>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default FleetDetailedView;
