import { useEffect } from "react";

import InteractionFunctions from "../../UIInteraction/InteractionFunctions";
import Phase from "../../../Data/GameData/Game/Phase";
import Map from "../../../Data/GameData/MapData/Map";
import System from "../../../Data/GameData/MapData/System";
import BaseButton from "../../Utils/BaseButton";
import SpaceObjectImageView from "../../Object/SpaceObjectImageView";
import Popup from "../../../Data/Other/Popup";

class ActionMove {
  static prepare(playerData) {
    playerData.phase = Phase.PHASE_ACTION;
    playerData.step = Phase.STEP_ACTION_MOVE;

    const data = {
      unitsToMove: [],
      targetLocation: [],
    };

    const afterUnitsSelected = (units) => {
      InteractionFunctions.selectASystem(
        afterSystemSelected,
        "Select target system."
      );
      data.unitsToMove = units;
    };

    const afterSystemSelected = (system) => {
      Popup.activateBlackPopup(this.selectAreaInSystem(system, () => {}));
    };

    InteractionFunctions.selectUnitsOnMap(
      afterUnitsSelected,
      "Select units to move.",
      { fromOnlyOneSystem: true }
    );
  }

  static selectAreaInSystem = (system, callback) => {
    const planets = System.getPlanets(system);

    return (
      <div>
        <div>
          <BaseButton>Space area</BaseButton>
        </div>
        {planets.map((planet, index) => {
          return (
            <div key={index}>
              <BaseButton onClick={() => callback(planet)}>
                <SpaceObjectImageView
                  spaceObject={planet}
                  height="1em"
                ></SpaceObjectImageView>
                {planet.name}
              </BaseButton>{" "}
            </div>
          );
        })}
      </div>
    );
  };
}

export default ActionMove;
