const PlayerData = require("../PlayerData/PlayerData");

const ScoreData = require("../Objectifs/ScoreData");
const MinorFaction = require("../MinorFaction/MinorFaction");
const Request = require("../../../Common/Requests/Request");
const AbilityCommon = require("./AbilityCommon");
const Phase = require("../Game/Phase");
const StaticGameData = require("../StaticGameData");

const UIMessage = require("../Connection/UIMessage");
const Item = require("../Transactions/Item");
const Map = require("../MapData/Map");
const SpaceObject = require("../MapData/SpaceObject");
const Planet = require("../MapData/Planet");
const ResolveData = require("../EndOfRound/ResolveData");
const Fleet = require("../MapData/Fleet");
const CustomMath = require("../../../Common/Math/CustomMath");
const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction");
const System = require("../MapData/System");
const CombatFunctions = require("../Combat/CombatFunctions");
const LogBook = require("../Connection/LogBook");
const CombatRecording = require("../Combat/CombatRecording");
const ResolveRelicAbility = require("./ResolveRelicAbility");
const Unit = require("../MapData/Unit");

class ResolveItemAbility {
  //static PHASE_TECH_ABILITY = "resolveTechAbility";

  static sendToServerCommon(item, actionData) {
    const playerData = StaticGameData.getPlayerData();
    const data = {
      itemId: item.id,
      data: actionData,
    };

    playerData.phase = AbilityCommon.PHASE_RESOLVE_ABILITY;
    playerData.step = Phase.STEP_ITEM_ABILITY;

    Request.updateGameState(data);
  }

  static resolveCommon = (playerData, updateData) => {
    const item = Item.getItemFromId(playerData.items, updateData.itemId);
    if (!item) {
      throw new Error(
        "Item with id " + updateData.item.id + " not found in player items."
      );
    }

    if (item.isExhausted) {
      throw new Error("Item is exhausted. It cannot be used.");
    }

    const data = updateData.data;

    Item.exhaustItem(item);

    if (Item.isRelic(item)) {
      ResolveRelicAbility.resolveCommon(playerData, item, data);
    } else {
      this.routeAbility(playerData, item, data);
    }
  };

  static routeAbility = (playerData, item, data) => {
    switch (item.name) {
      case Item.NAME_JELORIA_SCROLL:
        this.jeloriaScroll(playerData, item, data);
        break;
      case Item.NAME_KOBAMDA_SCROLL:
        this.kobamdaScroll(playerData, item, data);
        break;
      case Item.NAME_CALVARIA_SCROLL:
      case Item.NAME_DELPHAS_SCROLL:
      case Item.NAME_ICANTA_SCROLL:
      case Item.NAME_MALTRION_SCROLL:
      case Item.NAME_QUORIDIOM_SCROLL:
      case Item.NAME_RYNZORATH_SCROLL:
      case Item.NAME_SELTAAR_SCROLL:
      case Item.NAME_TAARKIR_SCROLL:
        //this.exhaustItem(playerData, item, data);
        break;
      case Item.NAME_HELL_MOON_DOCTRINE:
        this.hellMoonDoctrine(playerData, item, data);
        break;

      default:
        throw new Error("Unknown item ability for " + item.name + ".");
    }
  };

  static jeloriaScroll = (playerData, item, data) => {
    const selectedItemId = data.selectedItemId;
    const selectedItem = Item.getItemFromId(playerData.items, selectedItemId);

    if (!selectedItem) {
      throw new Error("Selected item not found.");
    } else {
      Item.readyItem(selectedItem);
    }

    this.logAndMessage(
      playerData,
      item,
      "The item " + selectedItem.name + " has been readied."
    );
  };

  static kobamdaScroll = (playerData, item, data) => {
    const planetList = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );
    const amountIceAndOceanicPlanets = planetList.filter(
      (planet) => planet.type === "Oceanic" || planet.type === "Ice"
    ).length;

    const amountVPGained = amountIceAndOceanicPlanets * 1;
    ScoreData.addScore(
      PlayerData.getScoreData(playerData),
      amountVPGained,
      "Kobamda Scroll : controlling " +
        amountIceAndOceanicPlanets +
        " ice or oceanic planets."
    );
    this.logAndMessage(
      playerData,
      item,
      "Gained " +
        amountVPGained +
        " victory points for controlling " +
        amountIceAndOceanicPlanets +
        " ice or oceanic planets."
    );
  };

  static hellMoonDoctrine = (playerData, item, data) => {
    const system = Map.getSystemFromName(playerData.map, data.systemName);
    const faction = playerData.faction.name;

    if (system.faction !== faction) {
      throw new Error("You must control the system to use this item.");
    }

    const planetList = System.getPlanets(system);
    let playerControlAPlanet = false;
    for (let i = 0; i < planetList.length; i++) {
      const planet = planetList[i];
      if (planet.faction === faction) {
        playerControlAPlanet = true;
        break;
      }
    }

    if (!playerControlAPlanet) {
      throw new Error(
        "You must control a planet in the system to use this item."
      );
    }

    const unit = Unit.createUnit(playerData, faction, Unit.UNIT_TYPE_HELL_MOON);
    MapUnitaryAction.placeUnitsInSystemSA(playerData, system, [unit]);

    this.logAndMessage(
      playerData,
      item,
      "A Hell Moon has been placed in the system " + system.name + "."
    );
  };

  static logAndMessage = (playerData, item, message) => {
    UIMessage.sendInfoMessageToClient(playerData, item.name, message);
    PlayerData.generateLogActivity(playerData, item.name + " : " + message);
  };

  //PASSIVE
  static taarkirScroll = (recData, resolveData, system, planet) => {
    const HLog = LogBook.createLogBook();
    LogBook.generateAddMessage(HLog, "Taarkir Scroll activated");
    const DLog = LogBook.createLogBook();
    const fleetPresent = Planet.getFleets(planet);
    for (let i = 0; i < fleetPresent.length; i++) {
      const playerData = ResolveData.getPlayerDataFromFaction(
        resolveData,
        fleetPresent[i].faction
      );

      if (Item.playerHasExhaustedItem(playerData, Item.NAME_TAARKIR_SCROLL)) {
        const units = [];
        const fleetList = Planet.getFleets(planet);
        for (let i = 0; i < fleetList.length; i++) {
          const fleet = fleetList[i];
          if (fleet.faction !== playerData.faction.name) {
            units.push(...Fleet.getUnits(fleet, Fleet.UNIT_CLASS_GROUND_FORCE));
          }
        }
        if (units.length > 0) {
          CustomMath.shuffleArray(units);
          const unitToDestroy = units[0];
          const targetFaction = System.getFactionFromUnit(
            system,
            unitToDestroy
          );
          CombatFunctions.destroyAUnit(
            recData,
            resolveData,
            system,
            unitToDestroy,
            targetFaction
          );
          LogBook.generateAddMessage(HLog, "Unit destroyed : $unit$", [
            unitToDestroy,
          ]);
          LogBook.generateAddMessage(
            DLog,
            "$faction$ has a Taarkir scroll activated. Unit destroyed : $unit$",
            [playerData.faction.name, unitToDestroy]
          );
        }
      }
    }
    if (!LogBook.isEmpty(DLog)) {
      CombatRecording.createStep(recData, HLog, DLog, system);
    }
  };

  static exhaustItem(playerData, item, data) {
    Item.exhaustItem(item);
  }
}

module.exports = ResolveItemAbility;
