const Tech = require("./Tech");

class TechList {
  static TECH = {
    //Level 1 - 8
    quantumExcavation: Tech.create(
      "Quantum Excavation",
      1,
      "Each of your planet produces 30% more minerals.",
      "Quantum Excavation employs state-of-the-art quantum tunneling technology and AI-driven precision to extract valuable minerals from deep within planetary crusts with unparalleled efficiency and minimal environmental disruption.",
      false,
      { science: 6 },
      "+30% minerals per planet"
    ),
    ecoHarvestFarming: Tech.create(
      "EcoHarvest Farming",
      1,
      "Each of your planet produces 30% more population.",
      "EcoHarvest Farming implements environmentally conscious practices and precision agricultural techniques to optimize crop growth and yield, fostering sustainable food production while minimizing ecological impact.",
      false,
      { science: 6 },
      "+30% population per planet"
    ),
    neuralNexusGrid: Tech.create(
      "Neural Nexus Grid",
      1,
      "Each of your planet produces 30% more science.",
      "Neural Nexus Grid is an advanced scientific breakthrough that integrates a sophisticated network of neural interfaces and quantum processors, enabling seamless brain-to-brain communication and collective cognitive processing across vast distances.",
      false,
      { science: 6 },
      "+30% science per planet"
    ),
    voltCores: Tech.create(
      "Volt Cores",
      1,
      "Each of your planet produces 30% more energy.",
      "The volt cores system integrates advanced energy storage mechanisms and optimization algorithms to enhance the efficiency of renewable energy utilization, providing a stable and adaptable power source for evolving energy demands.",
      false,
      { science: 5 },
      "+30% energy per planet"
    ),

    aquafustionReactors: Tech.create(
      "AquaFusion Reactors",
      1,
      "At the beginning of a round, you gain 1 energy per oceanic planet you control.",
      "AquaFusion Reactors harness the immense energy of ocean currents and thermal gradients to generate sustainable power through advanced fusion technology.",
      false,
      { science: 5 },
      "+1 energy on ocean planets"
    ),
    cryoHarvestArrays: Tech.create(
      "CryoHarvest Arrays",
      1,
      "At the beginning of a round, you gain 1 energy per ice planet you control.",
      "CryoHarvest Arrays utilize advanced cryogenic technology to convert the thermal energy and trapped methane hydrates of ice planets into a sustainable and robust energy source.",
      false,
      { science: 5 },
      "+1 energy on ice planets"
    ),
    ligthArmor: Tech.create(
      "Light Armor",
      1,
      "Your infantries have +1 HP.",
      "Light Armor is a lightweight and durable protective system that enhances the survivability of infantry units in combat situations, providing increased protection and resilience against enemy attacks.",
      false,
      { science: 6 },
      "+1 hp on infantries"
    ),
    lightShielding: Tech.create(
      "Light Shielding",
      1,
      "Your destroyers and crusers have +1 HP.",
      "Light Shielding is an advanced energy shield system that enhances the defensive capabilities of destroyer and cruiser ships, providing increased protection and resilience against enemy attacks.",
      false,
      { science: 6 },
      "+1 hp on destroyers and crusers"
    ),
    //Level 2 - 10
    energyAmplifierModule: Tech.create(
      "Energy Amplifier Module ",
      2,
      "At the start of the round, you gain additional 1 energy.",
      "The Energy Amplifier Module (EAM) is a compact and efficient device that enhances existing energy production systems, providing an additional unit of energy to support increased power demands.",
      false,
      { science: 6 },
      "+1 free energy"
    ),
    nekroReanimation: Tech.create(
      "Necro Reanimation ",
      2,
      "Once per round, you can place 2 infantries on a planet you control.",
      "The Necro-Reanimation Protocol (NRP) is an advanced biotechnology that reanimates cadavers, transforming them into functional infantry units through a combination of cybernetic enhancements and biochemical treatments.",
      true,
      { science: 6 },
      "Place 2 free infantries"
    ),

    subterraneanHabitat: Tech.create(
      "Subterranean Habitat",
      2,
      "At the beginning of a round, you gain 0.4 population per planets you control.",
      "The Subterranean Habitat system is an advanced technology that enables sustainable living for large populations in expansive, self-contained underground environments.",
      false,
      { science: 6 },
      "+0.4 population per planet"
    ),
    warpImpulsion: Tech.create(
      "Warp Impulsion",
      2,
      "Once per round, you can pay 1 energy to move one ship and units it can transport from the space area of a system without portal into a space area not controlled by another faction in an adjacent system. This does not create any portal.",
      "Warp Impulsion is an advanced propulsion technology that enables a ship to instantaneously jump to an adjacent star system by creating a localized warp bubble, significantly reducing travel time and strategic redeployment.",
      true,
      { science: 6 },
      "One ship to adjacent system"
    ),
    aegisBombardment: Tech.create(
      "Aegis Bombardment Augmentation",
      2,
      "When bombarding, your units produces 2 times more hits.",
      "The Aegis Bombardment Augmentation (ABA) is a cutting-edge technology that enhances the effectiveness and precision of orbital bombardment by integrating advanced targeting algorithms and energy amplification systems, ensuring devastating impact on designated targets with unparalleled efficiency.",
      false,
      { science: 5 },
      "Bombardment x2"
    ),
    portalRetention: Tech.create(
      "Portal Retention",
      2,
      "Creating a portal cost you 0.5 less energy.",
      "Warp Retention is an advanced technology that optimizes the energy consumption of portal creation, reducing the energy cost and enhancing the efficiency of interstellar travel and strategic deployment.",
      false,
      { science: 6 },
      "Portals cost -0.5 energy"
    ),
    canonAccuracy: Tech.create(
      "Canon Accuracy",
      2,
      "Your planetary canons gain an extra SPACE CANON DEFENSE 1.2 - 2.2 ability.",
      "Canon Accuracy is an advanced technology that enhances the targeting and precision of planetary defense systems, enabling increased damage output and improved interception capabilities against incoming threats.",
      false,
      { science: 5 },
      "Planetary canons deals more damages"
    ),
    improvedWeaponry: Tech.create(
      "Improved Weaponry",
      2,
      "Your ground forces have +0.1 damages.",
      "Improved Weaponry is an upgrade to ground force weapon systems, enhancing firepower and targeting.",
      false,
      { science: 4 },
      "Ground forces +0.1 damages"
    ),
    improvedShipTargetting: Tech.create(
      "Improved Ship Targetting",
      2,
      "Your ships deals +0.1 damages",
      "Improved Ship Targetting is an enhancement of the ship targeting system, allowing them to reach their target more often.",
      false,
      { science: 4 },
      "Ships +0.1 damages"
    ),

    //Level 3 - 7
    combatRepairModule: Tech.create(
      "Combat Repair Module",
      3,
      "At the end of each combat round, 1 random damaged HP of your units is repaired.",
      "The Combat Repair Module (CRM) is an advanced technology designed for rapid in-combat repairs, featuring automated systems and versatile tools to swiftly restore functionality to damaged components and ensure operational readiness during intense engagements.",
      false,
      { science: 5 },
      "Repair during combat"
    ),
    quantumAssemblyLine: Tech.create(
      "Quantum Assembly Line ",
      3,
      "Your planets can produce 2 more units.",
      "The Quantum Assembly Line (QAL) is an advanced manufacturing technology revolutionizing production efficiency, utilizing quantum principles to streamline assembly processes and enable factories to produce an additional unit of output with unparalleled speed and precision, ensuring rapid scalability and resource optimization.",
      false,
      { science: 6 },
      "+2 built unit per structure"
    ),
    orbitalMechDeployment: Tech.create(
      "Orbital Mech Deployment",
      3,
      "Once per round, you can pay 1 energy to place one free mech on a planet you control.",
      "Orbital Mech Deployment enables rapid deployment of mechs to strategic locations, providing tactical advantages and operational flexibility.",
      true,
      { science: 6 },
      "Drop 1 free mech"
    ),
    transportCarrierDeployment: Tech.create(
      "Transporter Carrier Deployment",
      3,
      "Once per round, you can spend 1 energy to place a carrier in a system which contains at least one of your unit.",
      "The Transporter Carrier Deployment (TCD) technology enables the instantaneous deployment of a carrier vessel into a designated star system, utilizing advanced transporter systems to swiftly relocate the carrier and reinforce strategic positions or provide logistical support as needed.",
      true,
      { science: 5 },
      "Place 1 carrier"
    ),

    scrapRecuperationDrones: Tech.create(
      "Scrap Recuperation Drones",
      3,
      "When one of your non-figther ship is destroyed, you gain minerals for a total amount of 30% of the ship cost in mineral.",
      "Scrap Recuperation Drones are advanced drones that salvage valuable materials from destroyed ships, enabling efficient resource recovery.",
      false,
      { science: 4 },
      "Get back 30% mineral from destroyed ships"
    ),
    businessAcademy: Tech.create(
      "Business Academy",
      3,
      "When buying resource, the cost in credit is reduced by 20%.",
      "The Business Academy is a prestigious institution that offers advanced training in commerce, finance, and resource management.",
      false,
      { science: 5 },
      "Buying reduction 20%"
    ),
    reinforcedArmor: Tech.create(
      "Reinforced Armors",
      3,
      "Your ground forces have +1 max HP.",
      "Reinforced Armors is an upgrade to groundforce armor systems, enhancing durability and survivability.",
      false,
      { science: 3 },
      "Ground forces +1 HP"
    ),

    //Level 4 - 7
    warpGate: Tech.create(
      "Warp Gate",
      4,
      "Once per round, you can pay 2 energy to choose any number of units in the space area of a system without portal containing at least one of your structures and place them into another space area of a system containing at least one of your structures.",
      "The Warp Gate is a monumental structure that serves as a gateway between distant star systems, leveraging advanced spatial manipulation technology to fold space-time, allowing for near-instantaneous travel of spacecraft from one gate to another, facilitating swift and efficient transportation across vast cosmic distances.",
      true,
      { science: 6 },
      "Transfer units between 2 structures"
    ),
    reinforcedHull: Tech.create(
      "Reinforced Hull",
      4,
      "Your ships have +1 max HP.",
      "Reinforced Hull is a comprehensive upgrade to ship weapon systems, enhancing durability and survivability.",
      false,
      { science: 4 },
      "Ships +1 HP"
    ),

    repairingStation: Tech.create(
      "Repairing Station",
      4,
      "One time per round, choose one of your ship in a space area you control in a system including at least one planet you control. This ship is fully repaired.",
      "The Repairing Station is a versatile repair facility that provides maintenance and restoration services for spacecraft.",
      true,
      { science: 3 },
      "Repair one ship"
    ),
    modularBay: Tech.create(
      "Modular Bay",
      4,
      "If you have at least one non-figther ship in a fleet, up to 3 of your figther does not count toward the required capacity of the fleet.",
      "The Modular Bay is a versatile docking system which can be installed on any non-fighter ship, enabling it to carry additional fighter units.",
      false,
      { science: 6 },
      "3 fighters do not count toward capacity"
    ),
    carrierPropulsion: Tech.create(
      "Carrier Propulsion",
      4,
      "The range of your carrier is increased by 1.",
      "Carrier Propulsion is an advanced propulsion system that enhances the operational range and speed of carrier vessels, enabling rapid deployment and strategic mobility.",
      false,
      { science: 6 },
      "+1 range to carriers"
    ),
    hellMoon: Tech.create(
      "Hell Moon",
      4,
      "Your assembly orbitals can build Hell Moons.",
      "Hell Moon is a powerful weapon of mass destruction that can be produced in orbitals, causing widespread destruction.",
      false,
      { science: 6 },
      "Hell Moon production"
    ),
    dreadnoughPropulsion: Tech.create(
      "Improved Dreadnough",
      4,
      "Your dreadnough have +1 range.",
      "Improved Dreadnough is an upgrade to dreadnough weapon systems, enhancing firepower and targeting.",
      false,
      { science: 8 },
      "Dreadnough +1 range"
    ),

    //Level 5 - 5
    plasmaCanonCharge: Tech.create(
      "Plasma Canon Charge",
      5,
      "Before the first combat round, if you have at least 3 non figter ships in the space area, you produce 5.5 - 7.5 hits.",
      "The Plasma Cannon Charge is a high-energy weapon system that harnesses and condenses plasma into a concentrated beam, capable of obliterating targets with immense precision and power.",
      false,
      { science: 7 },
      "6.5 - 8.5 hits before space combat"
    ),
    integratedEconomy: Tech.create(
      "Integrated Economy",
      5,
      "At the start of a round, if you control at least 4 planets, you gain 4 credits.",
      "The Integrated Economy is a sophisticated economic system that seamlessly interconnects various planetary markets and industries, optimizing resource distribution, trade efficiency, and financial stability across a multi-planet civilization.",
      false,
      { science: 3 },
      "+4 credits if 4 planets"
    ),
    bombardmentWeaponsMiniaturization: Tech.create(
      "Bombardment Weapons Miniaturization",
      5,
      "All your non-figther ships gain Bombardment 0.5 - 1.5.",
      "Bombardment Weapons Miniaturization is a groundbreaking advancement that condenses devastating firepower into compact, portable platforms, revolutionizing warfare by enabling precise and versatile deployment of destructive force in various operational environments.",
      false,
      { science: 6 },
      "All ships have bombardment"
    ),
    improvedDestroyers: Tech.create(
      "Improved Destroyers",
      5,
      "Your destroyers gain an extra ANTI FIGHTER CAPSULE 1.5 - 2.5 ability and they deal 0.2 more damages.",
      "Improved Destroyers is an upgrade to destroyer weapon systems, enhancing firepower and targeting.",
      false,
      { science: 6 },
      "+2 damage on ANTI FIGHTER CAPSULE, +0.2 damage"
    ),
    improvedCruisers: Tech.create(
      "Improved Cruisers",
      5,
      "Your cruisers have +1 range and deals 0.1 more damage.",
      "Improved Cruisers is an upgrade to cruiser weapon systems, enhancing firepower and targeting.",
      false,
      { science: 5 },
      "Cruisers +1 range, +0.1 damage"
    ),

    //White techs
    massDeflector: Tech.create(
      "Mass Deflector",
      2,
      "Ships can enter and pass through asteroid fields.",
      "The Mass Deflector generates a localized gravitational field to deflect and neutralize hazardous asteroids, enabling safe passage of spacecraft through asteroid fields.",
      false,
      { science: 4 },
      "Pass through asteroid fields",
      true,
      true
    ),
    thermalSuits: Tech.create(
      "Thermal Suits",
      3,
      "You can place units on volcanic planet, and exploit them for resources.",
      "Thermal Suits are advanced exoskeletons that provide protection against extreme temperatures and environmental hazards, enabling safe and efficient resource extraction on volcanic planets.",
      false,
      { science: 4 },
      "Allows to drop on volcanic planets",
      true,
      true
    ),

    /*techLevel4C: Tech.create("techLevel4C", 4),
    techLevel4D: Tech.create("techLevel4D", 4),
    techLevel4E: Tech.create("techLevel4E", 4),*/
    /*techLevel4F: Tech.create("techLevel4F", 4),
    techLevel4G: Tech.create("techLevel4G", 4),
    techLevel4H: Tech.create("techLevel4H", 4),
    techLevel5A: Tech.create("techLevel5A", 5),

    techLevel5C: Tech.create("techLevel5C", 5),
    techLevel5D: Tech.create("techLevel5D", 5),
    techLevel5E: Tech.create("techLevel5E", 5),
    techLevel5F: Tech.create("techLevel5F", 5),
    techLevel5G: Tech.create("techLevel5G", 5),
    techLevel5H: Tech.create("techLevel5H", 5),*/
  };
}

module.exports = TechList;
