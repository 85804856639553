const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const ActivationData = require("../Activation/ActivationData.js");
const System = require("../MapData/System.js");
const Unit = require("../MapData/Unit.js");
const Map = require("../MapData/Map.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const StaticGameData = require("../StaticGameData.js");
const GameState = require("../Game/GameState.js");

class ActionGlobal {
  /*static treatError(error) {
    if (StaticGameData.serverMode) {
      throw error;
    } else {
      UIMessage.displayErrorMessage(error);
    }
  }

  static executeAction(gameState, callback, data) {
    //if (StaticGameData.serverMode) {
    callback(data);
    if (data.messageToDisplay) {
      PlayerData.setMessageToDisplay(
        gameState.playerData,
        data.messageToDisplay
      );
    } else {
      PlayerData.removeMessageToDisplay();
    }
  }*/
  //}
}

module.exports = ActionGlobal;
