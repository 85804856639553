import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo";
import ScoreData from "../../Data/GameData/Objectifs/ScoreData";
import FactionSpan from "../Utils/FactionSpan";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import Objectives from "../../Data/GameData/Objectifs/Objectives";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import Color from "../../Common/Config/Colors";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import MinorFactionMissionInput from "../Ability/MinorFactionMissionInput";
import ObjectiveInput from "../Ability/ObjectiveInput";
import LineSpace from "../Utils/Layout/LineSpace";
import Version from "../../Data/Other/Version";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");

const PublicObjectiveDetailedView = ({ objectiveName }) => {
  const playerData = StaticGameData.getPlayerData();
  const allPlayersData = PlayerData.getAllPlayersData(playerData);

  const objectiveDesc = Objectives.createDesc(objectiveName);

  const isObjectiveAchieved = Objectives.hasScoredObjective(
    playerData,
    objectiveName
  );

  const DisplayObjectiveLevelChoice = ({}) => {
    return (
      <div>
        <div>
          {objectiveDesc.vpList.map((vpItem, index) => {
            return (
              <div>
                <BaseButton
                  onClick={() => {
                    ObjectiveInput.routeObjectiveInput(objectiveName, index);
                  }}
                >
                  {vpItem.description} :{" "}
                  <span style={{ color: "gold" }}>{vpItem.vp} VP</span>
                </BaseButton>
              </div>
            );
          })}
        </div>

        <div>
          <BaseButton
            onClick={() => {
              Popup.deActivateBlackPopup();
            }}
          >
            Back
          </BaseButton>
        </div>
      </div>
    );
  };

  const DisplayFactionLogo = ({}) => {
    const factions = allPlayersData
      .filter(
        (player) =>
          player.faction && Objectives.hasScoredObjective(player, objectiveName)
      )
      .map((player) => player.faction.name);

    return (
      <span>
        {factions.map((faction, index) => (
          <span key={index}>
            {" "}
            <FactionLogo factionName={faction} />{" "}
          </span>
        ))}
      </span>
    );
  };

  const DisplayContentOld = ({}) => {
    return (
      <div
        style={{
          //set Interline gap
          lineHeight: "2em",
        }}
      >
        <div>Objective : {objectiveName}</div>
        <div>
          Following factions achived this objective :{" "}
          <DisplayFactionLogo></DisplayFactionLogo>
        </div>
        <div style={{ color: "gold" }}>{objectiveDesc.vp} VP</div>
        <div
          style={{
            marginTop: "0.5em",
            backgroundColor: Color.COLOR_GREY_3,
            marginBottom: "0.5em",
          }}
        >
          {objectiveDesc.description}
        </div>

        {isObjectiveAchieved ? (
          <BaseButton
            type="greyOut"
            onClick={() => {
              UIMessage.displayInfoMessage(
                "Already Scored",
                "You already scored this objective"
              );
            }}
          >
            Already Scored
          </BaseButton>
        ) : (
          <div>
            {objectiveDesc.onlyStartOfRound ? (
              <div>
                {!Version.isAtleatVersion(playerData, 0.12) && (
                  <div>
                    {" "}
                    This objective will be scored automatically at the end of
                    the round, after the combat phase.
                  </div>
                )}
                {Version.isAtleatVersion(playerData, 0.12) && (
                  <div>
                    <div>
                      {" "}
                      This objective has to be selected and will be scored at
                      the end of this round, after the combat phase.{" "}
                    </div>{" "}
                    <div>
                      {" "}
                      <BaseButton
                        onClick={() => {
                          ObjectiveInput.routeObjectiveInput(objectiveName);
                        }}
                      >
                        Select objective
                      </BaseButton>{" "}
                    </div>
                  </div>
                )}{" "}
              </div>
            ) : (
              <BaseButton
                onClick={() => {
                  ObjectiveInput.routeObjectiveInput(objectiveName);
                }}
              >
                Score
              </BaseButton>
            )}{" "}
          </div>
        )}
        <LineSpace></LineSpace>
        <div>
          Objectives are also automatically scored at the beginning of a round,
          before checking if there is a winner for the game.
        </div>
      </div>
    );
  };

  const DisplayContent012 = ({}) => {
    const DisplayVPList = ({}) => {
      const vpList = objectiveDesc.vpList;

      return (
        <div>
          {vpList.map((vpItem, index) => (
            <div key={index}>
              <div>
                <span>{vpItem.description} : </span>
                <span style={{ color: "gold" }}>{vpItem.vp} VP</span>
              </div>
            </div>
          ))}
        </div>
      );
    };

    return (
      <div
        style={{
          //set Interline gap
          lineHeight: "2em",
        }}
      >
        <div>Objective : {objectiveName}</div>
        <div>
          Following factions achived this objective :{" "}
          <DisplayFactionLogo></DisplayFactionLogo>
        </div>
        <div
          style={{
            marginTop: "0.5em",
            backgroundColor: Color.COLOR_GREY_3,
            marginBottom: "0.5em",
          }}
        >
          {objectiveDesc.description}
          <DisplayVPList></DisplayVPList>
        </div>

        {isObjectiveAchieved ? (
          <BaseButton
            type="greyOut"
            onClick={() => {
              UIMessage.displayInfoMessage(
                "Already Scored",
                "You already scored this objective"
              );
            }}
          >
            Already Scored
          </BaseButton>
        ) : (
          <div>
            {objectiveDesc.onlyStartOfRound ? (
              <div>
                <div>
                  <div>
                    {" "}
                    This objective has to be selected and will be scored at the
                    beginning of next round.{" "}
                  </div>{" "}
                  <div>
                    {" "}
                    {ScoreData.isObjectiveSelected(
                      playerData,
                      objectiveName
                    ) ? (
                      <BaseButton
                        onClick={() => {
                          ObjectiveInput.routeObjectiveInput(objectiveName);
                        }}
                      >
                        Unselect objective
                      </BaseButton>
                    ) : (
                      <BaseButton
                        onClick={() => {
                          ObjectiveInput.routeObjectiveInput(objectiveName);
                        }}
                      >
                        Select objective
                      </BaseButton>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <BaseButton
                onClick={() => {
                  Popup.activateBlackPopup(
                    <DisplayObjectiveLevelChoice></DisplayObjectiveLevelChoice>
                  );
                }}
              >
                Score
              </BaseButton>
            )}{" "}
          </div>
        )}
        <LineSpace></LineSpace>
      </div>
    );
  };

  const navigationData = {
    buttonsData: [
      {
        text: "Back",
        callback: () => {
          Popup.goBack();
        },
      },
    ],
  };

  return (
    <GameGlobalLayout backgroundType="uniform">
      <div
        className="text-box"
        style={{
          textAlign: "center",
        }}
      >
        {Version.isAtleatVersion(playerData, 0.12) && (
          <DisplayContent012></DisplayContent012>
        )}
        {!Version.isAtleatVersion(playerData, 0.12) && (
          <DisplayContentOld></DisplayContentOld>
        )}
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default PublicObjectiveDetailedView;
