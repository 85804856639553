import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import SpaceObjectSummaryView from "../ManageSystem/SpaceObjectSummaryView";
import NavigationView from "../NavigationView";
import StaticGameData from "../../Data/GameData/StaticGameData";
import GameGlobalLayout from "../GameGlobalLayout";
import FleetSummaryView from "./FleetSummaryView";
import FactionLogo from "../Utils/FactionLogo";
import Logo from "../Utils/Logo";
import TitleView from "../TitleView";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import FactionView from "../Object/FactionView";
import FleetLineView from "../Object/FleetLineView";
import PlanetView from "../Object/PlanetView";
import MoveActionData from "../../Data/GameData/ActionData/MoveActionData";
import Planet from "../../Data/GameData/MapData/Planet";
import CuriosityView from "../Map/CuriosityView.jsx";
import SystemLogView from "../LogViews/SystemLogCombatView.jsx";
import SystemLogActivityView from "../LogViews/SystemLogActivityView.jsx";
import SystemLogCombatView from "../LogViews/SystemLogCombatView.jsx";
import SecondaryObject, {
  SECONDARY_OBJECT_MINOR_FACTION,
} from "../../Data/GameData/MapData/SecondaryObject.js";
import MinorFactionObjectView from "../Object/MinorFactionObjectView.jsx";
import ObjectiveView from "../Object/ObjectiveView.jsx";
import BodyView from "../Faction/BodyView.jsx";
import FactionPickSheetView from "../Faction/FactionPickSheetView.jsx";
import DraftData from "../../Data/GameData/Faction/DraftData.js";
import LineSpace from "../Utils/Layout/LineSpace.jsx";
import Map from "../../Data/GameData/MapData/Map.js";
import SpaceObjectImageView from "../Object/SpaceObjectImageView.jsx";
import ManageObjectView from "./ManageObjectView.jsx";
import DraftActionData from "../../Data/GameData/ActionData/DraftActionData.js";
import Rules from "../../Data/GameData/Game/Rules.js";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Request = require("../../Common/Requests/Request.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const ActionGlobal = require("../../Data/GameData/ActionCommon/ActionGlobal.js");
const StartOfGameAction = require("../../Data/GameData/ActionCommon/StartOfGameAction.js");
const StartOfGameCA = require("../../ClientAction/StartOfGameCA");
const LogBook = require("../../Data/GameData/Connection/LogBook.js");
const LogAttachment = require("../../Data/GameData/Connection/LogAttachment.js");
const ActivationData = require("../../Data/GameData/Activation/ActivationData.js");
const ActivationCA = require("../../ClientAction/ActivationCA");
const Curiosity = require("../../Data/GameData/MapData/Curiosity.js");

const SIZE_OBJECT = 256;

const ManageSystemView = ({ system }) => {
  console.log("ManageSystemView system: ", system);

  const playerData = StaticGameData.getPlayerData();
  const combatLog = LogAttachment.getFullCombatLog(system);
  const activityLog = LogAttachment.getFullActivityLog(system);
  const isThisSystemActivated =
    ActivationData.isASystemActivated(playerData) &&
    System.areSystemsTheSame(
      ActivationData.getActiveSystem(playerData),
      system
    );

  /*const getTaskBarData = () => {
    if (!Popup.mode) {
      return null;
    }

    if (Popup.mode.name === Popup.MODE_CHOOSE_PLANET_COLONIZE) {
      console.log("MapView Popup.mode: ", Popup.mode);
      return {
        text:
          "Choose a planet to colonize. You have " +
          playerData.colonists +
          " colonists left.",
      };
    }
  };*/
  //const taskBarData = getTaskBarData();

  const getNavigationData = () => {
    const navigationData = {
      buttonsData: [
        { text: "Map", callback: () => Popup.unStackToMap() },
        { text: "Back", callback: () => Popup.goBack() },
      ],
    };

    /*if (Popup.mode && Popup.mode.name === Popup.MODE_CHOOSE_PLANET_COLONIZE) {
      // Add the "Done colonizing" button
      navigationData.unshift({
        text: "Done colonizing",
        callback: () => console.log("Done colonizing"),
      });
    }*/

    return navigationData;
  };
  const navigationData = getNavigationData();

  //Go back
  const back = () => {
    Popup.goBack();
  };

  //Pick faction
  const pickFaction = (factionName) => async () => {
    console.log("Picked faction: " + factionName);
    StartOfGameCA.resolve_STEP_START_OF_GAME_PICK_FACTION(factionName);
    Popup.resetMapNavigationData();
  };

  //Display of space objects
  const ObjectsDisplay = () => {
    return (
      <div>
        {system.objects.map((object, index) => (
          <div key={index}>
            <SpaceObjectSummaryView
              //width="100%"
              //height="100px"
              spaceObject={object}
              activation={isThisSystemActivated}
            />
            <hr></hr>
          </div>
        ))}
      </div>
    );
  };

  const DisplayLogs = () => {
    console.log("ManageSystem activityLog: ", activityLog);
    return (
      <span style={{ textAlign: "center" }}>
        <BaseButton
          onClick={() => {
            Popup.addDynamicLayer(
              <SystemLogActivityView
                system={system}
                backCallback={() => {
                  Popup.goBack();
                }}
              ></SystemLogActivityView>
            );
            /*Popup.addLayer({
              name: "SystemLogView",
              system: system,
              type: "activity",
            });*/
          }}
        >
          Logs
        </BaseButton>
      </span>
    );
  };

  const DisplayCombatLogs = () => {
    const combatList = System.getAllCombatKeys(system);
    console.log("combatList: ", combatList);

    if (combatList.length === 0) {
      return;
    }
    return (
      <span style={{ textAlign: "center" }}>
        <BaseButton
          onClick={() => {
            Popup.addDynamicLayer(
              <SystemLogCombatView
                system={system}
                backCallback={() => {
                  Popup.goBack();
                }}
              ></SystemLogCombatView>
            );
          }}
        >
          Combats
        </BaseButton>
      </span>
    );
  };

  const FactionDraftSystemDisplay = () => {
    let isPlayerHomeSystem = false;
    {
      if (
        playerData.homeSystemCoords &&
        playerData.homeSystemCoords.x === system.x &&
        playerData.homeSystemCoords.y === system.y
      ) {
        isPlayerHomeSystem = true;
      }
    }

    if (
      playerData.gameParam.draft &&
      !DraftData.isDraftFinished(playerData.draftData)
    ) {
      return (
        <div className="text-box-center" style={{}}>
          <div>Home System of slice : {system.sliceName}</div>{" "}
          <LineSpace></LineSpace>
          <div>
            Position : {System.getCoords(system).x} -{" "}
            {System.getCoords(system).y}
          </div>{" "}
          <LineSpace></LineSpace>
          <div>
            {" "}
            {DraftData.isMapPositionPicked(playerData.draftData, {
              x: system.x,
              y: system.y,
            }) ? (
              <BaseButton
                type="greyOut"
                onClick={() => {
                  UIMessage.displayInfoMessage(
                    "Picked position",
                    "This position is already picked."
                  );
                }}
              >
                Picked by{" "}
                {PlayerData.getPlayerDisplayName(
                  playerData,
                  DraftData.getPlayerFromPickedPosition(playerData.draftData, {
                    x: system.x,
                    y: system.y,
                  }).playerInGameId
                )}
              </BaseButton>
            ) : (
              <div style={{ fontSize: "1.2em" }}>
                {" "}
                <BaseButton
                  type="action"
                  onClick={() => {
                    UIMessage.displayConfirmMessage(
                      "Are you sure ?",
                      "Are you sure you want to pick this position ?",
                      () => {
                        Popup.unStackToMap();
                        DraftActionData.resolvePickPositionClient(system);
                      }
                    );
                  }}
                >
                  Pick this position
                </BaseButton>{" "}
              </div>
            )}
          </div>
          <LineSpace></LineSpace>
          <div>Planets colonizable in this slice : </div>
          <div>
            {Map.getColonizablePlanetsFromSystem(
              playerData,
              system,
              playerData.map
            ).map((planet, index) => {
              return (
                <div key={index} style={{}}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr 2fr 1fr",
                    }}
                  >
                    <div> </div>
                    <SpaceObjectImageView
                      spaceObject={planet}
                      maxHeight="64px"
                    ></SpaceObjectImageView>
                    <BaseButton
                      width="auto"
                      onClick={() => {
                        Popup.addDynamicLayer(
                          <ManageObjectView
                            spaceObject={planet}
                          ></ManageObjectView>
                        );
                      }}
                    >
                      {planet.name}
                    </BaseButton>
                    <div> </div>
                  </div>

                  <LineSpace></LineSpace>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return (
      <div className="text-box" style={{ textAlign: "center" }}>
        <div>
          <div>Tech Tree</div>
          <div>
            <BaseButton
              onClick={() => {
                Popup.addLayer({
                  name: "TechTreeView",
                  playerInGameId: playerData.playerInGameId,
                });
              }}
            >
              Consult Tech Tree
            </BaseButton>
          </div>
          <div>The tech tree has been randomly generated for this game.</div>
        </div>
        <hr></hr>
        <div>Choose one of the following factions :</div>

        {system.factionsToPickFrom.map((faction, index) => (
          <div key={index}>
            <div style={{ fontSize: "1.5em", marginTop: "1em" }}>
              {" "}
              <FactionLogo factionName={faction.name}></FactionLogo>{" "}
              <span> {faction.name}</span>
            </div>
            <div style={{ marginTop: "1em" }}>
              {isPlayerHomeSystem && (
                <BaseButton onClick={pickFaction(faction.name)}>
                  <span>Pick</span>
                  <span> {faction.name}</span>
                </BaseButton>
              )}
              <BaseButton
                onClick={() => {
                  Popup.addDynamicLayer(
                    <FactionPickSheetView
                      factionName={faction.name}
                    ></FactionPickSheetView>
                  );
                }}
              >
                <span>Abilities and info</span>
              </BaseButton>
            </div>

            <div style={{ marginTop: "1em" }}>
              <BodyView color={faction.dedicatedColor} size="256px"></BodyView>
            </div>
          </div>
        ))}
      </div>
    );

    /*return (
      <div className="text-box" style={{ textAlign: "center" }}>
        <div>
          <div>Tech Tree</div>
          <div>
            <BaseButton
              onClick={() => {
                Popup.addLayer({
                  name: "TechTreeView",
                  playerInGameId: playerData.playerInGameId,
                });
              }}
            >
              Consult Tech Tree
            </BaseButton>
          </div>
          <div>The tech tree has been randomly generated for this game.</div>
        </div>
        <hr></hr>
        <div>
          The player starting on that position can choose one of the following
          factions :
        </div>

        {system.factionsToPickFrom.map((faction, index) => (
          <div key={index}>
            <div style={{ marginTop: "2em" }}> </div>
            <div>
              <FactionView faction={faction}></FactionView>
            </div>
          </div>
        ))}
      </div>
    );*/
  };

  const DisplaySpaceFleets = () => {
    return (
      <div style={{ textAlign: "center" }}>
        {system.fleets.map((fleet, index) => (
          <div key={index} style={{ fontSize: "1em" }}>
            <FleetLineView fleet={fleet}></FleetLineView>{" "}
          </div>
        ))}
      </div>
    );
  };

  const DisplaySpaceActionBar = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <DisplayLogs></DisplayLogs>
        {false && (
          <BaseButton
            onClick={() => {
              ActivationCA.initialize_STEP_ACTIVATION_MOVE(system);
            }}
            type="action"
          >
            Move to this system
          </BaseButton>
        )}
        <DisplayCombatLogs></DisplayCombatLogs>
      </div>
    );
  };

  const DisplayObjects = () => {
    const spaceObjects = system.objects;
    console.log("spaceObjects: ", spaceObjects);
    return (
      <div>
        <div>
          {spaceObjects.map((object, index) => {
            if (object.isPlanet) {
              return (
                <div key={index}>
                  <hr></hr>
                  <PlanetView
                    planet={object}
                    showAction={false}
                    showButtonPlanetName={false}
                    showStock={false}
                    showPopRepartition={false}
                    showPlanetParameter={true}
                    showGeneralInfo={true}
                    showBonus={true}
                  ></PlanetView>
                </div>
              );
            }
          })}
        </div>
        <div>
          {spaceObjects.map((object, index) => {
            if (
              object.type === SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION
            ) {
              return (
                <div key={index}>
                  <hr></hr>
                  <MinorFactionObjectView
                    minorFaction={SecondaryObject.getMinorFaction(object)}
                    size={SIZE_OBJECT}
                    spaceObject={object}
                  ></MinorFactionObjectView>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };

  const DisplayActivationSpaceButton = () => {
    return (
      <span>
        <BaseButton
          onClick={() => {
            ActivationCA.initialize_STEP_ACTIVATION_MOVE(system);
          }}
        >
          Move to this system
        </BaseButton>
        <BaseButton
          onClick={() => {
            ActivationCA.initialize_STEP_ACTIVATION_INVASION();
          }}
        >
          Invade a planet
        </BaseButton>

        <BaseButton
          type="action"
          onClick={() => {
            UIMessage.displayConfirmMessage(
              "End Activation",
              "Are you sure you want to end activation phase ?",
              () => {
                ActivationCA.resolve_STEP_ACTIVATION_END_ACTIVATION();
              }
            );
          }}
        >
          End Activation
        </BaseButton>
      </span>
    );
  };

  const DisplayFleets = ({ system }) => {
    //console.log("system: ", system);

    if (system.fleets.length === 0) {
      return;
    }

    const getColor = (fleet) => {
      const playerData = StaticGameData.getPlayerData();
      const fleetPlayerData = PlayerData.getPlayerDataFromFaction(
        playerData,
        fleet.faction
      );

      return Color.getColorFromPlayerColor(fleetPlayerData.color);
    };

    return (
      <div>
        <div style={{ textAlign: "left" }}>
          {system.fleets.map((fleet, index) => (
            <div key={index} style={{ color: getColor(fleet) }}>
              <FleetSummaryView fleet={fleet}></FleetSummaryView>
            </div>
          ))}
        </div>{" "}
        <hr></hr>
      </div>
    );
  };

  const DisplayCuriosity = () => {
    if (system.backgroundObject === System.BACK_NONE) {
      return;
    } else {
      const curiosityObject = Curiosity.getCuriosityObject(
        system.backgroundObject
      );

      return (
        <div
          style={{
            textAlign: "center",

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ textDecoration: "underline" }}></div>

          <span>
            <BaseButton
              onClick={() => {
                UIMessage.displayInfoMessage(
                  curiosityObject.name,
                  curiosityObject.description
                );
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <CuriosityView
                  curiosity={system.backgroundObject}
                  sizeY="6em"
                ></CuriosityView>
                <div> {curiosityObject.name}</div>
                <div>{curiosityObject.shortDescription}</div>
              </div>
            </BaseButton>
          </span>
        </div>
      );
    }
  };

  const HomeSystemWarning = () => {
    if (playerData.homeSystemCoords) {
      if (
        playerData.homeSystemCoords.x === system.x &&
        playerData.homeSystemCoords.y === system.y
      ) {
        return (
          <div style={{ color: "red", textAlign: "center" }}>
            <span>
              This is your home system. If you do not control this sytem at the
              end of a round, you loose {Rules.PENALITY_FOR_HS_CONTROL} VP. If
              you do not control the planet in this system at the end of a
              round, you loose {Rules.PENALITY_FOR_HS_PLANET_CONTROL} VP
            </span>{" "}
          </div>
        );
      }
    }
  };

  const SystemDisplay = () => {
    const blockStyle = {
      marginBottom: "1em",
    };

    return (
      <div className="text-box" style={{ textAlign: "left", fontSize: "1em" }}>
        <div
          style={{
            textAlign: "center",
            //display: "grid",
            //gridTemplateColumns: "1fr 1fr",
          }}
        >
          <div style={blockStyle}>
            <span> System : {system.name}</span>
            {system.faction && (
              <span>
                {" "}
                (control :{" "}
                <FactionLogo factionName={system.faction}></FactionLogo>)
              </span>
            )}
          </div>
        </div>
        <HomeSystemWarning></HomeSystemWarning>
        <div style={blockStyle}>
          {" "}
          <DisplaySpaceFleets></DisplaySpaceFleets>
        </div>

        <div style={blockStyle}>
          {" "}
          <DisplaySpaceActionBar></DisplaySpaceActionBar>
        </div>
        <div>
          <DisplayCuriosity></DisplayCuriosity>
        </div>
        <div>
          <DisplayObjects></DisplayObjects>
        </div>
      </div>
    );
  };

  return (
    <GameGlobalLayout title={null} backgroundType="shadow">
      <div
        style={{
          height: "100%",
        }}
      >
        {system.isFactionDraftSystem && (
          <FactionDraftSystemDisplay></FactionDraftSystemDisplay>
        )}
        {!system.isFactionDraftSystem && <SystemDisplay></SystemDisplay>}
      </div>
      <NavigationView
        navigationData={navigationData}
        screenData={{ system: system }}
      ></NavigationView>
    </GameGlobalLayout>
  );
};

export default ManageSystemView;
