//const Color = require("../../../Common/Config/Colors");
//const StaticGameData = require("../StaticGameData");
//const Popup = require("../../Other/Popup");
const LogBook = require("./LogBook");
const LogMessage = require("./LogMessage");

class LogAttachment {
  static LOG_ACTIVITY = "logActivity";
  static LOG_COMBAT = "logCombat";

  static create(object, activity = true, combat = true) {
    if (activity) {
      object.logActivity = LogBook.createLogBook();
      object.previousLogActivity = LogBook.createLogBook();
    }
    if (combat) {
      object.logCombat = LogBook.createLogBook();
      object.previousLogCombat = LogBook.createLogBook();
    }
  }

  static getFullActivityLog(object) {
    if (object.logActivity) {
      const logBook = LogBook.createLogBook();
      LogBook.createMessage(
        logBook,
        [
          {
            content: "PREVIOUS ROUND",
          },
        ],
        false,
        LogMessage.TYPE_TITLE_SECTION
      );
      LogBook.concat(object.previousLogActivity, logBook);
      LogBook.correctCursor(logBook);
      LogBook.createMessage(
        logBook,
        [
          {
            content: "CURRENT ROUND",
          },
        ],
        false,
        LogMessage.TYPE_TITLE_SECTION
      );
      LogBook.concat(object.logActivity, logBook);
      LogBook.correctCursor(logBook);
      return logBook;
    } else {
      return null;
    }
  }

  static getFullCombatLog(object) {
    if (object.logCombat) {
      const logBook = LogBook.createLogBook();
      LogBook.createMessage(
        logBook,
        [
          {
            content: "PREVIOUS ROUND",
          },
        ],
        false,
        LogMessage.TYPE_TITLE_SECTION
      );
      LogBook.concat(object.previousLogCombat, logBook);
      LogBook.correctCursor(logBook);
      LogBook.createMessage(
        logBook,
        [
          {
            content: "CURRENT ROUND",
          },
        ],
        false,
        LogMessage.TYPE_TITLE_SECTION
      );
      LogBook.concat(object.logCombat, logBook);
      LogBook.correctCursor(logBook);
      return logBook;
    } else {
      return null;
    }
  }

  static getLogActivity(object) {
    if (!object.logActivity) {
      object.logActivity = LogBook.createLogBook();
    }
    LogBook.correctCursor(object.logActivity);
    return object.logActivity;
  }

  static getLogCombat(object) {
    if (!object.logCombat) {
      object.logCombat = LogBook.createLogBook();
    }
    LogBook.correctCursor(object.logCombat);
    return object.logCombat;
  }

  static getPreviousLogActivity(object) {
    if (!object.previousLogActivity) {
      object.previousLogActivity = LogBook.createLogBook();
    }
    LogBook.correctCursor(object.previousLogActivity);
    return object.previousLogActivity;
  }

  static getPreviousLogCombat(object) {
    if (!object.previousLogCombat) {
      object.previousLogCombat = LogBook.createLogBook();
    }
    LogBook.correctCursor(object.previousLogCombat);
    return object.previousLogCombat;
  }

  static clearLogs(object) {
    if (object.logActivity) {
      object.previousLogActivity = LogBook.createLogBook();
      object.logActivity = LogBook.createLogBook();
    }
    if (object.logCombat) {
      object.previousLogCombat = LogBook.createLogBook();
      object.logCombat = LogBook.createLogBook();
    }
  }

  static log(object, logMessage, logType) {
    if (logType === LogAttachment.LOG_ACTIVITY && object.logActivity) {
      LogBook.correctCursor(object.logActivity);
      LogBook.createMessage(object.logActivity, logMessage);
    }
    if (logType === LogAttachment.LOG_COMBAT && object.logCombat) {
      LogBook.correctCursor(object.logCombat);
      LogBook.createMessage(object.logCombat, logMessage);
    }
  }

  static logActivity(object, logMessage) {
    LogAttachment.log(object, logMessage, LogAttachment.LOG_ACTIVITY);
  }

  static logCombat(object, logMessage) {
    LogAttachment.log(object, logMessage, LogAttachment.LOG_COMBAT);
  }

  static setLog(object, logBook, logType) {
    if (logType === LogAttachment.LOG_ACTIVITY) {
      object.logActivity = logBook;
    } else if (logType === LogAttachment.LOG_COMBAT) {
      object.logCombat = logBook;
    }
  }

  static setpreviousLog(object, logBook, logType) {
    if (logType === LogAttachment.LOG_ACTIVITY) {
      object.previousLogActivity = logBook;
    } else if (logType === LogAttachment.LOG_COMBAT) {
      object.previousLogCombat = logBook;
    }
  }
}

module.exports = LogAttachment;
