const Dreadnought = require("./UnitType/Dreadnought.js");
const Cost = require("./Cost.js");
const CostResource = require("../Resource/CostResource.js");

class UnitIndex {
  /*static getUnitData(type, subType) {
    return UnitData[type] && UnitData[type][subType];
  }*/

  static getUnit(type, subType, id = null, controllingPlayerPos = null) {
    if (type === "Dreadnought") {
      return new Dreadnought(id, controllingPlayerPos, subType);
    }

    throw new Error("Unit of that type not found.");
  }

  static test() {
    return 3;
  }

  static getUnitsToBuilCost(unitsToBuild) {
    const cost = new CostResource();

    for (const type in unitsToBuild) {
      for (const subType in unitsToBuild[type]) {
        for (let i = 0; i < unitsToBuild[type][subType]; i++) {
          const unitData = UnitIndex.getUnit(type, subType, null, null);
          if (unitData) {
            CostResource.costAdd(cost, unitData.cost);
          }
        }
      }
    }
    return cost;
  }
}

module.exports = UnitIndex;
