const UnitIndex = require("../../GameData/Units/UnitIndex.js");
const StaticGameData = require("../StaticGameData");

class Cost {
  constructor(
    productionResource = 0,
    infrastructureResource = 0,
    expertResource = 0,
    commandResource = 0,
    credit = 0,
    techResource = 0
  ) {
    this.productionResource = productionResource;
    this.infrastructureResource = infrastructureResource;
    this.expertResource = expertResource;
    this.commandResource = commandResource;
    this.techResource = techResource;
    this.credit = credit;
  }

  static getText(cost) {
    const parts = [];

    if (cost.productionResource !== 0) {
      parts.push(`Production: ${cost.productionResource}`);
    }

    if (cost.infrastructureResource !== 0) {
      parts.push(`Infrastructure: ${cost.infrastructureResource}`);
    }

    if (cost.expertResource !== 0) {
      parts.push(`Expert: ${cost.expertResource}`);
    }

    if (cost.commandResource !== 0) {
      parts.push(`Command: ${cost.commandResource}`);
    }

    if (cost.credit !== 0) {
      parts.push(`Credit: ${cost.credit}`);
    }

    return parts.join(", ");
  }

  static getAbbreviationText(cost) {
    const parts = [];

    if (cost.productionResource !== 0) {
      parts.push(`${cost.productionResource} prod`);
    }

    if (cost.infrastructureResource !== 0) {
      parts.push(`${cost.infrastructureResource} infra`);
    }

    if (cost.expertResource !== 0) {
      parts.push(`${cost.expertResource} expert`);
    }

    if (cost.commandResource !== 0) {
      parts.push(`${cost.commandResource} command`);
    }

    if (cost.credit !== 0) {
      parts.push(`${cost.credit} credits`);
    }

    return parts.join(", ");
  }

  static getVerticalAbreviationArray(cost) {
    const parts = [];

    if (cost.productionResource !== 0) {
      parts.push(`${cost.productionResource} prod`);
    }

    if (cost.infrastructureResource !== 0) {
      parts.push(`${cost.infrastructureResource} infra`);
    }

    if (cost.expertResource !== 0) {
      parts.push(`${cost.expertResource} expert`);
    }

    if (cost.commandResource !== 0) {
      parts.push(`${cost.commandResource} command`);
    }

    if (cost.credit !== 0) {
      parts.push(`${cost.credit} credit`);
    }

    return parts;
  }

  static costAdd(cost1, cost2) {
    const result = new Cost();
    result.productionResource =
      cost1.productionResource + cost2.productionResource;
    result.infrastructureResource =
      cost1.infrastructureResource + cost2.infrastructureResource;
    result.expertResource = cost1.expertResource + cost2.expertResource;
    result.commandResource = cost1.commandResource + cost2.commandResource;
    result.techResource = cost1.techResource + cost2.techResource;
    result.credit = cost1.credit + cost2.credit;
    return result;
  }

  static costSubtract(cost1, cost2) {
    const result = new Cost();
    result.productionResource =
      cost1.productionResource - cost2.productionResource;
    result.infrastructureResource =
      cost1.infrastructureResource - cost2.infrastructureResource;
    result.expertResource = cost1.expertResource - cost2.expertResource;
    result.commandResource = cost1.commandResource - cost2.commandResource;
    result.techResource = cost1.techResource - cost2.techResource;
    result.credit = cost1.credit - cost2.credit;
    return result;
  }

  /*static getEffectsPlayerSpend(cost) {
    const effects = [];
    effects.push({
      effect: new PlayerSpendCommandEffect(),
      effectData: { amountToSpend: cost.commandResource },
    });
    effects.push({
      effect: new PlayerSpendCreditEffect(),
      effectData: { amountToSpend: cost.credit },
    });
    effects.push({
      effect: new PlayerSpendInfraEffect(),
      effectData: { amountToSpend: cost.infrastructureResource },
    });
    effects.push({
      effect: new PlayerSpendExpertEffect(),
      effectData: { amountToSpend: cost.expertResource },
    });
    effects.push({
      effect: new PlayerSpendTechEffect(),
      effectData: { amountToSpend: cost.techResource },
    });
    return effects;
  }*/
}

module.exports = Cost;
