class User {
  static createUser(username, email) {
    return {
      username: username,
      email: email,
      ongoingGames: [], //List of gameId
      finishedGames: [], //List of gameId
    };
  }

  static removeGameFromUser(user, gameId) {
    user.ongoingGames = user.ongoingGames.filter((game) => game !== gameId);
  }

  static getUserName(user) {
    return user.username;
  }

  static getOngoingGames(user) {
    return user.ongoingGames;
  }

  static getFinishedGames(user) {
    return user.finishedGames;
  }

  static markGameAsFinished(user, gameId) {
    user.ongoingGames = user.ongoingGames.filter((game) => game !== gameId);
    user.finishedGames.push(gameId);
  }

  static adminEndOfGame(user, game) {
    this.markGameAsFinished(user, game.gameId);
  }
}

module.exports = User;
