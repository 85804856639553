import React, { useState } from "react";

const ActivationViewLayout = ({ children }) => {
  //console.log("GameGlobalLayout children: ", children);
  //console.log("GameGlobalLayout children.length: ", children.length);
  if (!children || children.length !== 2) {
    throw new Error("ActivationViewLayout requires exactly two children");
  }
  return (
    <div
      className="div-fill"
      style={{
        display: "flex",
        flexDirection: "column",
        //backgroundColor: "purple",
        //height: "100%",
        //overflowY: "hidden",
        //height: "100%",
        //overflowY: "hidden",
      }}
    >
      {/* Content component taking the rest of the screen */}
      <div
        style={{
          flex: 1,
          //backgroundColor: "lightblue",
          width: "100%",
          //height: "100%",
          //overflowY: "scroll",
          overflowY: "auto",
        }}
      >
        {/* Render the first child component */}
        {children && children[0]}
      </div>

      {/* Fixed component at the bottom */}
      <div style={{ width: "100%" }}>
        {/* Render the second child component */}
        {children && children[1]}
      </div>
    </div>
  );
};

export default ActivationViewLayout;
