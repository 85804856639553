import React, { useRef, useState } from "react";
import ReactDOM from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import MapView from "../GameView/MapView";
import TestHook from "../GameView/CustomHook/TestHook";
import ImagesUrl from "../Data/Other/ImagesUrl";
import StaticGameData from "../Data/GameData/StaticGameData";
import Popup from "../Data/Other/Popup";
import { MainMenu } from "../MainMenu/MainMenu";
import BaseImage from "../GameView/Utils/BaseImage";

const WelcomeScreen = ({}) => {
  return (
    <div
      className="div-fill"
      style={{
        //backgroundImage: `url(${ImagesUrl.URL_MAP_BACKGROUND})`,
        backgroundColor: "black",
        fontSize: "2em",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={() => {
        Popup.addDynamicLayer(<MainMenu></MainMenu>);
      }}
    >
      <div>
        <BaseImage
          src={ImagesUrl.TITLE_IMAGE}
          alt="Title Image"
          width="400px"
          style={{ maxWidth: "95vw" }}
        />
      </div>
      <div>Welcome {StaticGameData.username}</div>
      <div>Click to continue...</div>
    </div>
  );
};

export default WelcomeScreen;
