const Resource = require("./ResourceInstance/Resource");
const Amount = require("./Amount");
class CostResource {
  constructor(resources = []) {
    this.resources = resources;
  }

  static getResource(cost, type) {
    return cost.resources.find((resource) => resource.type === type);
  }

  static removeResource(cost, type) {
    const resource = CostResource.getResource(cost, type);
    if (resource) {
      cost.resources.splice(cost.resources.indexOf(resource), 1);
    }
  }

  static costAddResource(costResource1, resource2) {
    if (!resource2) {
      throw new Error("Resource2 is null");
    }

    const resource1 = costResource1.resources.find(
      (res) => res.type === resource2.type
    );
    if (resource1) {
      Resource.addToResource(resource1, resource2);
    } else {
      costResource1.resources.push(resource2);
    }
  }

  static costAdd(costResource1, costResource2) {
    if (!costResource1) {
      throw new Error("costResource1 is null");
    }
    for (const resource2 of costResource2.resources) {
      CostResource.costAddResource(costResource1, resource2);
    }
  }

  static costPayResource(costResource1, resource2) {
    const resource1 = costResource1.resources.find(
      (res) => res.type === resource2.type
    );
    if (resource1) {
      const total1 = Amount.getTotalValue(resource1.amount);
      const total2 = Amount.getTotalValue(resource2.amount);
      if (total1 < total2) {
        return { state: false, message: "Not enough " + resource2.type };
      }
      Resource.subtractToResource(resource1, resource2);
    } else {
      if (Amount.getTotalValue(resource2.amount) > 0) {
        return { state: false, message: "Not enough " + resource2.type };
      }
      costResource1.resources.push(resource2);
    }
    return { state: true };
  }

  static costPay(costResource1, costResource2) {
    for (const resource2 of costResource2.resources) {
      const result = CostResource.costSubtractResource(
        costResource1,
        resource2
      );
      if (result.state === false) {
        return result;
      }
    }
    return { state: true };
  }

  static compare(costResource1, costResource2) {
    for (const resource2 of costResource2.resources) {
      const resource1 = costResource1.resources.find(
        (res) => res.type === resource2.type
      );
      if (resource1) {
        const resource1Amount = Amount.getTotalValue(resource1.amount);
        const resource2Amount = Amount.getTotalValue(resource2.amount);
        if (resource1Amount < resource2Amount) {
          return { state: false, resource: resource2 };
        }
      } else {
        return { state: false, resource: resource2 };
      }
    }
    return { state: true };
  }
}

module.exports = CostResource;
