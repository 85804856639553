import React, { useState, useEffect } from "react";
import UIMessage from "../../../Data/GameData/Connection/UIMessage.js";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData.js";
import StaticGameData from "../../../Data/GameData/StaticGameData.js";
import ActivationData from "../../../Data/GameData/Activation/ActivationData.js";
import BaseButton from "../../Utils/BaseButton.jsx";
import FleetSummaryView from "../FleetSummaryView.jsx";
import UnitLineView from "../../Units/UnitLineView.jsx";
import UnitSelectionView from "../../Units/UnitSelectionView.jsx";
import SpaceObjectImageView from "../../Object/SpaceObjectImageView.jsx";
import Logo from "../../Utils/Logo.jsx";
import ActivationCA from "../../../ClientAction/ActivationCA.js";
import TransferData from "../../../Data/GameData/Activation/TransferData.js";
import ProductionActionData from "../../../Data/GameData/ActionData/ProductionActionData.js";
import PlanetView from "../../Object/PlanetView.jsx";
import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData.js";
import BuyActionData from "../../../Data/GameData/ActionData/BuyActionData.js";
import CostView from "../../Utils/CostView.jsx";
import Phase from "../../../Data/GameData/Game/Phase.js";
import ActionBuyView from "./ActionBuyView.jsx";
import InteractionFunctions from "../../UIInteraction/InteractionFunctions.jsx";
import StartOfGameCA from "../../../ClientAction/StartOfGameCA.js";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup.js");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const Planet = require("../../../Data/GameData/MapData/Planet.js");

class StartOfGame {
  static prepareColonize(playerData) {
    const callback = (planet) => {
      Popup.resetMapNavigationData();
      StartOfGameCA.resolve_STEP_START_OF_GAME_COLONIZE({
        spaceObjectName: planet.name,
      });
    };

    InteractionFunctions.selectAPlanet(callback, "Select a planet to colonize");
    Popup.setContextualButtonsVisibility(false);
    //Popup.activateBlackPopup(<ActionBuyView></ActionBuyView>);
  }
}

export default StartOfGame;
