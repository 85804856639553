import React, { useState } from "react";
import "../CSS/Font.css";
import "../CSS/Menu.css";
import BaseButton from "../GameView/Utils/BaseButton";
import TaskBarView from "../GameView/TaskBarView";

const Popup = require("../Data/Other/Popup");
const StaticGameData = require("../Data/GameData/StaticGameData");
const Color = require("../Common/Config/Colors");

const MenuNavigationView = ({
  screenData = null,
  navigationData = { buttonsData: [], taskBarData: null },
  //taskBarData = { text: null },
  visible = true,
}) => {
  const Popup = StaticGameData.Popup;
  if (visible === false) return null;
  //Task bar text coming from MODE

  if (!navigationData.buttonsData) {
    navigationData.buttonsData = [];
  }

  const elementStyle = {
    //margin: "10px",
  };

  return (
    <div
      style={{
        //position: "fixed",
        //bottom: 0,
        //left: 0,
        //backgroundColor: Color.COLOR_GREY_3,
        zIndex: 1000,
        width: "100%",
        fontSize: "1em",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        //backgroundColor: "purple",
        //borderTop: "2px solid " + Color.COLOR_GREY_5,

        //padding: "100px",
        //margin: "10px",
      }}
    >
      {navigationData.taskBarData && (
        <TaskBarView
          taskBarData={navigationData.taskBarData}
          elementStyle={elementStyle}
        ></TaskBarView>
      )}
      {navigationData && navigationData.child && (
        <div>{navigationData.child}</div>
      )}

      <div className="div-hor-wrap">
        {navigationData &&
          navigationData.buttonsData.map((buttonData, index) => {
            const { text, content, callback, type } = buttonData;

            const typeResult = type ? type : "white";

            if (content) {
              return (
                <div key={index} style={elementStyle}>
                  <BaseButton type={typeResult} onClick={() => callback()}>
                    {content}
                  </BaseButton>
                </div>
              );
            }

            return (
              <div key={index} style={elementStyle}>
                <BaseButton type={typeResult} onClick={() => callback()}>
                  {text}
                </BaseButton>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MenuNavigationView;
