import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo.jsx";
import Logo from "../Utils/Logo.jsx";
import UnitLineView from "../Units/UnitLineView.jsx";
import SelectionListView from "../Utils/Layout/SelectionListView.jsx";
import UIMessage from "../../Data/GameData/Connection/UIMessage.js";
import FleetSummaryView from "../ManageSystem/FleetSummaryView.jsx";
import BaseButton from "../Utils/BaseButton.jsx";
import Popup from "../../Data/Other/Popup.js";
import ModifyFleetLimitAction from "../../Data/GameData/ActionData/ModifyFleetLimitAction.js";
import ActionCommonData from "../../Data/GameData/ActionData/ActionCommonData.js";
import FleetLineView from "../Object/FleetLineView.jsx";
import Phase from "../../Data/GameData/Game/Phase.js";

const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const System = require("../../Data/GameData/MapData/System.js");
const Map = require("../../Data/GameData/MapData/Map.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const SolveFleetLimitData = require("../../Data/GameData/MandatoryAction/SolveFleetLimitData.js");
const MandatoryActionCA = require("../../ClientAction/MandatoryActionCA.js");

const SolveFleetLimitView = ({ navigationData }) => {
  console.log("RENDER SolveFleetLimitView ");

  const playerData = StaticGameData.getPlayerData();
  playerData.phase = Phase.PHASE_MANDATORY_ACTION;
  playerData.step = Phase.STEP_MANDATORY_FLEET_LIMIT;
  console.log("debug mandatory action", playerData.mandatoryAction);
  const data = PlayerData.getFirstMandatoryAction(playerData).data;

  const system = Map.getSystemFromName(playerData.map, data.systemName);

  const fleet = System.getFleet(system, playerData.faction.name);

  const fleetLogisticMass = Fleet.getLogisticMass(fleet);
  const fleetLimit = PlayerData.getFleetLimit(playerData);

  const fleetLeft = Fleet.createFleet(playerData, playerData.faction.name);
  Fleet.addFleetToFleet(fleet, fleetLeft);
  Fleet.removeFleetFromFleet(data.fleetToRemove, fleetLeft);

  const fleetLogisticMassLeft = Fleet.getLogisticMass(fleetLeft);
  const fleetLimitLeft = PlayerData.getFleetLimit(playerData);

  const fleetCapacityLeft = Fleet.getTotalCapacity(playerData, fleetLeft);
  const fleetRequiredCapacityLeft = Fleet.getTotalRequiredCapacity(
    playerData,
    fleetLeft
  );

  SolveFleetLimitData.setBuyFleetLimit(playerData, false);
  const fleetExcedent = Math.ceil(Math.max(0, fleetLogisticMass - fleetLimit));
  const costBuyFleetLimit = ModifyFleetLimitAction.getCostFromAmount(
    playerData,
    fleetExcedent
  );
  const buyFleetLimit = () => {
    UIMessage.displayConfirmMessage(
      "Buy fleet limit",
      "Are you sure you want to buy the required fleet limit for " +
        costBuyFleetLimit.energy +
        " energy ?",
      () => {
        SolveFleetLimitData.setBuyFleetLimit(playerData, true);
        MandatoryActionCA.resolve_STEP_MANDATORY_FLEET_LIMIT();
      }
    );
  };

  //const fleet = System.getFleet(system, playerData.faction.name);

  const confirmScrap = () => {
    UIMessage.displayConfirmMessage(
      "Scrap units",
      "Are you sure you want to scrap the selected units ?",
      /*Request.sendRequest(
          Request.GAME_ACTION_MANDATORY,
          "SolveFleetLimit",
          resolveData
        );*/ () => {
        MandatoryActionCA.resolve_STEP_MANDATORY_FLEET_LIMIT();
      }
    );
  };

  navigationData.buttonsData.push({
    type: "action",
    text: "Scrap",
    callback: confirmScrap,
  });

  const DisplayText = () => {
    return (
      <div>
        <div>
          Fleet limit issue in system :
          <BaseButton
            onClick={() => {
              Popup.addLayer({ name: "ManageSystemView", system: system });
            }}
          >
            {system.name}
          </BaseButton>
        </div>
        <div>
          <span>Your fleet limit (</span>
          <Logo logoName="cargo"></Logo>
          <span>) is {fleetLimit}.</span>
          <span>
            {" "}
            The mass of your fleet in the system {system.name} is{" "}
            {fleetLogisticMass}.{" "}
          </span>
          <span>
            You have to scrap units until the mass of your fleet is equal lower
            than your fleet limit.
          </span>
        </div>
        <div>
          <Logo logoName="cargo"></Logo>
          <span>
            {" "}
            : {fleetLogisticMassLeft} / {fleetLimitLeft}
          </span>
        </div>
        <div>
          <span>
            Capacity : {fleetRequiredCapacityLeft} / {fleetCapacityLeft}
          </span>
        </div>
      </div>
    );
  };

  const DisplayScrapSelection = () => {
    const ships = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_SHIP);
    const selectedShips = Fleet.getUnits(
      data.fleetToRemove,
      Fleet.UNIT_CLASS_SHIP
    );
    //const groundForces = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_GROUND_FORCE);

    const items = ships;
    const displayItem = (item) => {
      return (
        <UnitLineView
          unit={item}
          factionName={playerData.faction.name}
        ></UnitLineView>
      );
    };
    const selectedItems = selectedShips;
    const selectItem = (item) => {
      Fleet.addUnit(data.fleetToRemove, item);
    };

    const unSelectItem = (item) => {
      Fleet.removeUnit(data.fleetToRemove, item);
    };

    const textIfEmpty = "No units to scrap";

    return (
      <div>
        <div>
          <BaseButton onClick={buyFleetLimit}>
            Buy required fleet limit for {costBuyFleetLimit.energy} energy
          </BaseButton>
        </div>
        <SelectionListView
          items={items}
          displayItem={displayItem}
          selectedItems={selectedItems}
          selectItem={selectItem}
          unSelectItem={unSelectItem}
          textIfEmpty={textIfEmpty}
        ></SelectionListView>
      </div>
    );
  };

  return (
    <div className="text-box" style={{ textAlign: "center" }}>
      <DisplayText></DisplayText>
      <div>
        <FleetLineView fleet={fleet}></FleetLineView>
      </div>
      <hr></hr>
      <DisplayScrapSelection></DisplayScrapSelection>
    </div>
  );
};

export default SolveFleetLimitView;
