import React, { useState, useEffect, useMemo } from "react";

import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import Logo from "../../Utils/Logo";
import ResourceBarView from "../../Utils/ResourceBarView";
import CostView from "../../Utils/CostView";
import ActivationCA from "../../../ClientAction/ActivationCA";
import PlanetView from "../../Object/PlanetView";
import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const TransferActionData = require("../../../Data/GameData/ActionData/TransferActionData.js");
const Planet = require("../../../Data/GameData/MapData/Planet.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");

const ActionTransferView = ({ navigationData }) => {
  console.log("RENDER ActionTransferView ");
  const playerData = StaticGameData.getPlayerData();

  //Storing active system planets

  const receivingPlanet = TransferActionData.getReceivingPlanet(playerData);
  const system = Map.getSystemFromSpaceObject(receivingPlanet, playerData.map);

  const ASPlanets = System.getPlanets(system);

  const otherSystems = Map.getSystemsWhereFactionHasPlanet(
    playerData.map,
    playerData.faction.name
  ).filter((systemTemp) => !System.areSystemsTheSame(systemTemp, system));

  const sendingSystems = [];
  const otherSystemsActivated = [];
  sendingSystems.push(system);

  for (let i = 0; i < otherSystems.length; i++) {
    if (otherSystems.hasBeenActivated) {
      otherSystemsActivated.push(otherSystems[i]);
    } else {
      sendingSystems.push(otherSystems[i]);
    }
  }

  /*const planetsInActiveSystem = System.getPlanetsFromFaction(
    system,
    playerData.faction.name
  );*/

  //console.log("ActivationMoveView RENDER : ", playerData.activationData);

  const DisplayReceivingPlanet = ({ planet }) => {
    return (
      <div>
        <PlanetView planet={planet}></PlanetView>
      </div>
    );
  };

  const DisplaySendingPlanets = () => {
    const DisplayTransferingPlanets = ({ planet }) => {
      return (
        <span>
          <PlanetView planet={planet} showFleet={false}>
            <BaseButton
              onClick={() => {
                TransferActionData.selectPlanet(playerData, planet);
                TransferActionData.switchToView(
                  playerData,
                  TransferActionData.VIEW_TRANSFER
                );
              }}
            >
              Transfer Out
            </BaseButton>
          </PlanetView>
          <hr></hr>
        </span>
      );
    };

    return (
      // Map on system.spaceObjects where spaceObject.isPlanet
      <div>
        {sendingSystems.map((system, index) => (
          <div key={index}>
            {System.getPlanetsFromFaction(system, playerData.faction.name)
              .filter((planetF) => planetF.name !== receivingPlanet.name)
              .filter((planet) => {
                const system = Map.getSystemFromSpaceObject(
                  planet,
                  playerData.map
                );
                try {
                  System.canTransferFromToPlanet(playerData, system, planet);
                  return true;
                } catch {
                  return false;
                }
              })
              .map((planet, index) => (
                <DisplayTransferingPlanets
                  key={index}
                  planet={planet}
                ></DisplayTransferingPlanets>
              ))}
          </div>
        ))}
      </div>
    );
  };

  const GlobalView = () => {
    navigationData.buttonsData.push({
      text: "Cancel",
      callback: () => {
        ActionCommonData.cleanActionData(playerData);
        Popup.goBack();
      },
    });
    return (
      <div className="text-box" style={{ height: "100%" }}>
        <div>
          Transferring mineral and population to : {receivingPlanet.name}
        </div>
        <div>
          You can transfer only from planets in systems not controlled by
          another faction
        </div>
        <hr></hr>

        <div style={{}}>
          <DisplaySendingPlanets></DisplaySendingPlanets>
        </div>
      </div>
    );
  };

  const TransferView = ({ planetReceiving }) => {
    //const [amountMineral, setAmountMineral] = useState(0);
    //const [amountPopulation, setAmountPopulation] = useState(0);

    const transferData = ActionCommonData.getData(playerData);
    const amountPopulation = transferData.amountPopulation;
    const amountMineral = transferData.amountMineral;

    navigationData.buttonsData.splice(0, navigationData.buttonsData.length);
    navigationData.buttonsData.push({
      text: "Back",
      callback: () => {
        if (transferData.directTransfer) {
          Popup.goBack();
        } else {
          TransferActionData.switchToView(
            playerData,
            TransferActionData.VIEW_GLOBAL_VIEW
          );
        }
      },
    });
    navigationData.buttonsData.push({
      text: "Cancel",
      callback: () => {
        TransferActionData.switchToView(
          playerData,
          TransferActionData.VIEW_GLOBAL_VIEW
        );
      },
    });
    navigationData.buttonsData.push({
      text: "Confirm",
      callback: () => {
        ActionCommonData.resolveClient(playerData);
        Popup.goBack();
      },
    });
    const planet = TransferActionData.getSelectedPlanet(playerData);

    const populationReceivingPlanet = CustomMath.roundDec(
      amountPopulation + planetReceiving.population
    );
    const mineralReceivingPlanet = CustomMath.roundDec(
      amountMineral + planetReceiving.mineral
    );

    const populationSendingPlanet = CustomMath.roundDec(
      planet.unassignedPop - amountPopulation
    );
    const mineralSendingPlanet = CustomMath.roundDec(
      planet.mineral - amountMineral
    );

    //Display cost
    const cost = TransferActionData.getCostTransfer(playerData);
    navigationData.child = (
      <div>
        <span>Transfer cost : </span>
        <CostView cost={cost}></CostView>
      </div>
    );

    /*console.log("amountPopulation : ", amountPopulation);
    console.log("amountMineral : ", amountMineral);
    console.log("planet : ", planet);
    console.log("populationSendingPlanet : ", populationSendingPlanet);
    console.log("mineralSendingPlanet : ", mineralSendingPlanet);
    console.log("populationReceivingPlanet : ", populationReceivingPlanet);
    console.log("mineralReceivingPlanet : ", mineralReceivingPlanet);*/

    const addPopulation = (amount) => {
      TransferActionData.addPopulation(playerData, amount);
    };

    const addMineral = (amount) => {
      TransferActionData.addMineral(playerData, amount);
    };

    return (
      <div>
        <PlanetView planet={planetReceiving} showPopRepartition={false}>
          <div>Receiving...</div>
          <div>
            <ResourceBarView
              fontSize="1.5em"
              resourceType={"population"}
              amount={populationReceivingPlanet}
            ></ResourceBarView>
            <ResourceBarView
              fontSize="1.5em"
              resourceType={"mineral"}
              amount={mineralReceivingPlanet}
            ></ResourceBarView>
          </div>
        </PlanetView>
        <hr></hr>
        <PlanetView planet={planet} showPopRepartition={false}>
          <div>
            Sending...
            <div>
              <ResourceBarView
                fontSize="1.5em"
                resourceType={"population"}
                amount={populationSendingPlanet}
              ></ResourceBarView>
              <ResourceBarView
                fontSize="1.5em"
                resourceType={"mineral"}
                amount={mineralSendingPlanet}
              ></ResourceBarView>
            </div>
          </div>
          <div style={{ fontSize: "1.5em" }}>
            <BaseButton
              onClick={() => {
                addMineral(+1);
              }}
            >
              <span>
                {" "}
                + <Logo logoName="mineral" heightRem="1em"></Logo>
              </span>
            </BaseButton>
            <BaseButton
              onClick={() => {
                addMineral(-1);
              }}
            >
              <span>
                {" "}
                - <Logo logoName="mineral" heightRem="1em"></Logo>
              </span>
            </BaseButton>
            <BaseButton
              onClick={() => {
                addPopulation(+1);
              }}
            >
              <span>
                {" "}
                + <Logo logoName="population" heightRem="1em"></Logo>
              </span>
            </BaseButton>
            <BaseButton
              onClick={() => {
                addPopulation(-1);
              }}
            >
              <span>
                {" "}
                - <Logo logoName="population" heightRem="1em"></Logo>
              </span>
            </BaseButton>
          </div>
        </PlanetView>
      </div>
    );
  };

  const view = TransferActionData.getView(playerData);

  //Check if the player can transfer passing through system
  try {
    System.canTransferThrough(playerData, system);
  } catch (e) {
    ActionCommonData.cleanActionData(playerData);
    Popup.goBack();
    UIMessage.displayInfoMessage("Can't transfer to this system", e.message);
    return;
  }

  return (
    <div>
      {view === TransferActionData.VIEW_GLOBAL_VIEW && (
        <GlobalView></GlobalView>
      )}
      {view === TransferActionData.VIEW_TRANSFER && (
        <TransferView planetReceiving={receivingPlanet}></TransferView>
      )}
    </div>
  );
};

export default ActionTransferView;
