import React, { useState, useEffect } from "react";

import UIMessage from "../../../Data/GameData/Connection/UIMessage.js";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData.js";
import StaticGameData from "../../../Data/GameData/StaticGameData.js";
import ActivationData from "../../../Data/GameData/Activation/ActivationData.js";
import BaseButton from "../../Utils/BaseButton.jsx";
import FleetSummaryView from "../FleetSummaryView.jsx";
import UnitLineView from "../../Units/UnitLineView.jsx";
import UnitSelectionView from "../../Units/UnitSelectionView.jsx";
import ResourceBarView from "../../Utils/ResourceBarView.jsx";
import CostView from "../../Utils/CostView.jsx";
import ActivationCA from "../../../ClientAction/ActivationCA.js";
import Logo from "../../Utils/Logo.jsx";
import Unit from "../../../Data/GameData/MapData/Unit.js";
import PlanetView from "../../Object/PlanetView.jsx";
import ActionCommonData, {
  prepare,
} from "../../../Data/GameData/ActionData/ActionCommonData.js";
import FleetLineView from "../../Object/FleetLineView.jsx";
import Planet from "../../../Data/GameData/MapData/Planet.js";
import SpaceObjectImageView from "../../Object/SpaceObjectImageView.jsx";
import BuyActionData from "../../../Data/GameData/ActionData/BuyActionData.js";
import TitleView from "../../TitleView.jsx";
import ActionBuild from "./ActionBuild.jsx";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup.js");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Rules = require("../../../Data/GameData/Game/Rules.js");
const Cost = require("../../../Data/GameData/Utils/Cost.js");
const UnitCatalog = require("../../../Data/GameData/Utils/UnitCatalog.js");
const UnitShopList = require("../../../Data/GameData/Utils/UnitShopList.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const BuildUnitActionData = require("../../../Data/GameData/ActionData/BuildUnitActionData.js");

const ActionBuildUnitView = () => {
  const playerData = StaticGameData.getPlayerData();
  const data = ActionCommonData.getData(playerData); //VWA ERROR here, data is nuoll some time, due to sync

  console.log("ActivationBuildView BuildUnitActionData : ", data);

  const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);
  const system = Map.getSystemFromSpaceObject(planet, playerData.map);

  const DisplayCatalogLine = ({ itemLine, unitShop }) => {
    const unit = itemLine.unit;
    const amount = itemLine.amount;

    return (
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ fontSize: "2em" }}>
            <UnitLineView
              unit={unit}
              factionName={playerData.faction.name}
              showHP={false}
              showRequirements={false}
              showCost={false}
              size="1.5em"
            ></UnitLineView>
          </span>
          <CostView
            cost={Unit.getCost(playerData, unit)}
            compactView={true}
          ></CostView>
          <span>
            <span> {amount}</span>
          </span>
          <span>
            <div className="div-hor-center">
              <BaseButton
                width="3em"
                onClick={() => {
                  BuildUnitActionData.modifyOrderAmount(
                    playerData,
                    unitShop,
                    unit,
                    +1,
                    planet
                  );

                  Popup.touch();
                }}
              >
                +
              </BaseButton>
              <BaseButton
                width="3em"
                onClick={() => {
                  BuildUnitActionData.modifyOrderAmount(
                    playerData,
                    unitShop,
                    unit,
                    -1,
                    planet
                  );
                  Popup.touch();
                }}
              >
                -
              </BaseButton>
            </div>
          </span>
        </div>
      </div>
    );
  };

  const DisplayBuildView = () => {
    //const planet = BuildUnitActionData.getSelectedPlanet(playerData);
    //const planetData = BuildUnitActionData.getPlanetData(playerData, planet);
    //const selectedClass = BuildUnitActionData.unitClassSelected;
    //const fullCatalog = BuildUnitActionData.getFullCatalog(playerData, planet);
    /*const classCatalog = BuildUnitActionData.getClassCatalog(
      playerData,
      planet,
      selectedClass
    );*/
    //let fullCatalogCost = UnitCatalog.getCost(playerData, fullCatalog);

    const unitShop = data.unitShop;
    const unitProduced = BuildUnitActionData.getUnitsProduced(
      playerData,
      unitShop
    );
    const planetConstruction = Planet.getConstructionValue(playerData, planet);
    const amountConstructedUnits =
      BuildUnitActionData.getOrderUnitAmount(unitShop);
    const fleetProduced = Fleet.createFleet(
      playerData,
      playerData.faction.name
    );
    Fleet.addUnits(fleetProduced, unitProduced);
    const cost = BuildUnitActionData.getOrderCost(playerData, unitShop);
    console.log("cost debug : ", cost);
    //const fleetProducing = selectedConstData.ghostFleetConstructing;

    //Target Fleet Display
    const spaceFleet = Fleet.createFleet(playerData, playerData.faction.name);
    const planetFleet = Fleet.createFleet(playerData, playerData.faction.name);
    /*Fleet.addFleetToFleet(
      System.getFleetOrCreate(playerData, system, playerData.faction.name),
      spaceFleet
    );
    Fleet.addFleetToFleet(
      Planet.getFleetOrCreate(playerData, planet, playerData.faction.name),
      planetFleet
    );
    Fleet.addUnits(
      spaceFleet,
      unitProduced.filter((unit) => unit.class === Fleet.UNIT_CLASS_SHIP)
    );
    Fleet.addUnits(
      planetFleet,
      unitProduced.filter((unit) => unit.class !== Fleet.UNIT_CLASS_SHIP)
    );*/

    return (
      <div>
        <SpaceObjectImageView
          spaceObject={planet}
          maxHeight={"3em"}
          showAsLogo={true}
        ></SpaceObjectImageView>
        <div>{planet.name}</div>
        <div style={{ minHeight: "12em" }}>
          <FleetLineView fleet={fleetProduced}></FleetLineView>
        </div>
        <div>
          Amount of unit constructed : {amountConstructedUnits} /{" "}
          {planetConstruction}
        </div>
        <div>
          Cost : <CostView cost={cost} compactView={true}></CostView>
        </div>

        <div>
          {unitShop.list.map((item, index) => {
            return (
              <DisplayCatalogLine
                key={index}
                itemLine={item}
                unitShop={unitShop}
              ></DisplayCatalogLine>
            );
          })}
        </div>
      </div>
    );
  };

  const DisplayAmountOfConstructedUnits = ({ unitConstData }) => {
    const unit = unitConstData.unit;
    const amount = UnitShopList.getAmountUnitsShopped(
      unitConstData.unitShopList
    );
    console.log("unitConstData : ", unitConstData);

    return (
      <span>
        {Unit.getPrintingName(unit)} is constructing : {amount} /{" "}
        {Unit.getConstructionAmount(playerData, unit)} units
      </span>
    );
  };

  const DisplaySpaceArea = ({}) => {
    const fleet = System.getFleet(system, playerData.faction.name);
    return (
      <div style={{ fontSize: "2em" }}>
        <FleetLineView fleet={fleet}></FleetLineView>
      </div>
    );
  };

  const DisplayGlobalView = () => {
    const confirm = () => {
      BuildUnitActionData.resolveClient(playerData);
      Popup.goBack();
      /*UIMessage.displayConfirmMessage(
        "Construct ?",
        "Are you sure you want to construct these units ?",
        () => {
          BuildUnitActionData.warningConstruction(playerData, () => {
            ActionCommonData.resolveClient(playerData);
            Popup.goBack();
          });
        }
      );*/
    };

    return (
      <div style={{ textAlign: "center" }}>
        <div>
          Space area : <DisplaySpaceArea></DisplaySpaceArea>
        </div>
        <hr></hr>

        <PlanetView planet={planet} showPopRepartition={true}></PlanetView>
        <div>
          Construction total cost : <CostView cost={data.cost}></CostView>
        </div>
        <div style={{ marginTop: "2em" }}></div>
        {data.unitsConstData.length === 0 && (
          <div>This planet has no units which can contruct units.</div>
        )}
        {data.unitsConstData.map((unitConstData, indexConst) => {
          const unit = unitConstData.unit;
          const unitShopList = unitConstData.unitShopList;
          const ghostFleetConstructing = unitConstData.ghostFleetConstructing;
          //console.log("unitConstData : ", unitConstData);
          return (
            <div
              key={indexConst}
              style={
                {
                  //backgroundColor: Color.COLOR_GREY_3,
                }
              }
            >
              <div>
                <div
                  style={
                    {
                      //display: "grid",
                      //gridTemplateColumns: "1fr 1fr",
                    }
                  }
                >
                  <span>
                    <span>
                      {" "}
                      <span>
                        <UnitLineView
                          unit={unit}
                          factionName={playerData.faction.name}
                          showHP={false}
                        ></UnitLineView>
                      </span>
                      <span>
                        <BaseButton
                          onClick={() => {
                            BuildUnitActionData.switchToBuildView(
                              playerData,
                              unitConstData,
                              planet
                            );
                          }}
                        >
                          Construct units
                        </BaseButton>
                      </span>
                    </span>
                    <span style={{ fontSize: "2em" }}>
                      {" "}
                      <FleetLineView
                        fleet={ghostFleetConstructing}
                        showFactionLogo={false}
                      ></FleetLineView>
                    </span>
                  </span>
                </div>
                <div>
                  <DisplayAmountOfConstructedUnits
                    unitConstData={unitConstData}
                  ></DisplayAmountOfConstructedUnits>
                </div>
                <div>
                  Cost : <CostView cost={unitConstData.cost}></CostView>
                </div>{" "}
              </div>
              <div style={{ marginTop: "2em" }}></div>
            </div>
          );
        })}
        <hr></hr>
      </div>
    );
  };

  return (
    <div
      style={
        {
          //width: "98vw"
        }
      }
    >
      <DisplayBuildView></DisplayBuildView>

      <div>
        <BaseButton
          onClick={() => {
            //Popup.resetMapDisplayMode();
            Popup.deActivateBlackPopup();
          }}
        >
          Cancel
        </BaseButton>
        <BaseButton
          onClick={() => {
            //Popup.resetMapDisplayMode();
            Popup.deActivateBlackPopup();
            //Popup.resetMapNavigationData();
            BuildUnitActionData.resolveClient(playerData);
            //ActionBuild.prepareUI(playerData);
          }}
        >
          Confirm
        </BaseButton>
      </div>
    </div>
  );
};

export default ActionBuildUnitView;

//
