const PlayerData = require("../Data/GameData/PlayerData/PlayerData.js");
const Map = require("../Data/GameData/MapData/Map.js");
const System = require("../Data/GameData/MapData/System.js");
const Faction = require("../Data/GameData/PlayerData/Faction.js");
const Game = require("../Data/GameData/Game/Game.js");
const Color = require("../Common/Config/Colors.js");
const UIMessage = require("../Data/GameData/Connection/UIMessage.js");
const Request = require("../Common/Requests/Request.js");
const Phase = require("../Data/GameData/Game/Phase.js");
const StaticGameData = require("../Data/GameData/StaticGameData.js");
const Popup = require("../Data/Other/Popup.js");
const Rules = require("../Data/GameData/Game/Rules.js");
const SystemAction = require("../Data/GameData/ActionCommon/SystemAction.js");
const SelectActionCA = require("./SelectActionCA.js");

class EndRoundReviewCA {
  static initialize_STEP_EORR(gameState) {
    this.createEORRData(gameState.playerData);

    Popup.reset();
    const callback = () => {
      this.resolve_STEP_STEP_EORR(gameState);
    };
    Popup.setMode(Phase.STEP_EORR, {
      taskBarText:
        "Space combats occured. Click on systems with combat icon to review them.",

      moreButtons: [
        {
          text: "Previous",
          callback: this.previousState,
          nameView: "MapView",
        },
        {
          text: "Next",
          callback: this.nextState,
          nameView: "MapView",
        },
      ],
    });

    Popup.touch();
  }

  static resolve_STEP_STEP_EORR(resolveData) {
    try {
      UIMessage.displayConfirmMessage(
        "End review ?",
        "Are you sure you want to stop reviewing the end of previous round and start playing the new round ?",
        () => {
          Request.updateGameState({});
        }
      );
    } catch (error) {
      throw error;
      //UIMessage.displayErrorMessage("Error picking faction", error);
    }
  }

  static statesEORR = ["space combat"];

  static createEORRData(playerData) {
    playerData.EORRData = { stateCursor: 0 };
  }

  static getStepEORR = (playerData) => {
    if (!playerData.EORRData) {
      return null;
    }
    return this.statesEORR[playerData.EORRData.stateCursor];
  };

  static nextState = () => {
    const playerData = StaticGameData.getPlayerData();
    playerData.EORRData.stateCursor++;
    if (playerData.EORRData.stateCursor >= this.statesEORR.length) {
      this.resolve_STEP_STEP_EORR();
    }
    Popup.touch();
  };

  static previousState = () => {
    const playerData = StaticGameData.getPlayerData();

    playerData.EORRData.stateCursor--;
    if (playerData.EORRData.stateCursor < 0) {
      playerData.EORRData.stateCursor = 0;
    }
    Popup.touch();
  };
}

module.exports = EndRoundReviewCA;
