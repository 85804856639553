import React, { useState, useEffect } from "react";
import ActivationViewLayout from "./ActivationViewLayout";
import ActivationNavigationView from "./ActivationNavigationView";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import PlanetViewActivation from "./Objects/PlanetViewActivation";
import Logo from "../../Utils/Logo";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");

const ActivationPlanetManagementView = ({ system }) => {
  const playerData = StaticGameData.getPlayerData();
  if (!playerData.ActivationPlanetManagementView) {
    playerData.ActivationPlanetManagementView = {
      status: "planet selection",
      selectedPlanet: null,
    };
  }

  const nextButtonData = {
    text: "Next",
    callback: null,
  };
  /*
  let navigationData = {};
  if (status === "planet selection") {
    navigationData = {
      buttonsData: [nextButtonData],
    };
  }
  if (status === "manage planet") {
    navigationData = {
      buttonsData: [
        {
          text: "Planet Selection",
          callback: () => {
            setStatus("planet selection");
            setSelectedPlanet(null);
          },
        },
        {
          text: "Confirm",
          callback: () => {
            setStatus("planet selection");
            setSelectedPlanet(null);
          },
        },
      ],
    };
  }
/*
  const DisplayPlanetsOld = () => {
    return (
      <div>
        {planetsInActiveSystem.map((planet, index) => {
          const productionData = ActivationData.getPlanetProductionData(
            playerData,
            planet.name
          );

          return (
            <div key={index}>
              <div className="div-hor-center">
                <div style={{}}>
                  <PlanetViewActivation
                    spaceObject={planet}
                    showStock={false}
                  ></PlanetViewActivation>
                </div>
              </div>
              <div>
                <span style={{ fontSize: "1.5rem" }}>
                  Unassigned population : {productionData.popUnassigned}{" "}
                </span>
              </div>
              <div>
                Unassigned population can be spent later in the build step.
              </div>
              <div style={{ fontSize: "1.5rem" }}>
                <Logo logoName="mineral" heightRem="1em"></Logo>
                <span>
                  {" "}
                  : {planet.mineral} + {planet.mineralEfficiency} x{" "}
                </span>
                <span>
                  {productionData.popOnMineral} ={" "}
                  {planet.mineral +
                    planet.mineralEfficiency * productionData.popOnMineral}
                </span>{" "}
                <BaseButton
                  onClick={() => {
                    ActivationData.assignPop(
                      playerData,
                      planet.name,
                      "mineral",
                      +1
                    );
                    Popup.touch();
                  }}
                >
                  {" "}
                  +{" "}
                </BaseButton>
                <BaseButton
                  onClick={() => {
                    ActivationData.assignPop(
                      playerData,
                      planet.name,
                      "mineral",
                      -1
                    );
                    Popup.touch();
                  }}
                >
                  {" "}
                  -{" "}
                </BaseButton>
              </div>

              <div style={{ fontSize: "1.5rem" }}>
                <Logo logoName="science" heightRem="1em"></Logo>
                <span>
                  {" "}
                  : {planet.science} + {planet.scienceEfficiency} x{" "}
                </span>
                <span>
                  {productionData.popOnScience} ={" "}
                  {planet.scienceEfficiency * productionData.popOnScience}
                </span>{" "}
                <BaseButton
                  onClick={() => {
                    ActivationData.assignPop(
                      playerData,
                      planet.name,
                      "science",
                      +1
                    );
                    Popup.touch();
                  }}
                >
                  {" "}
                  +{" "}
                </BaseButton>
                <BaseButton
                  onClick={() => {
                    ActivationData.assignPop(
                      playerData,
                      planet.name,
                      "science",
                      -1
                    );
                    Popup.touch();
                  }}
                >
                  {" "}
                  -{" "}
                </BaseButton>
              </div>

              <div style={{ fontSize: "1.5rem" }}>
                <span>Construction : {planet.construction} + 1 x </span>
                <span>
                  {productionData.popOnConstruction} ={" "}
                  {1 * productionData.popOnConstruction}
                </span>{" "}
                <BaseButton
                  onClick={() => {
                    ActivationData.assignPop(
                      playerData,
                      planet.name,
                      "construction",
                      +1
                    );
                    Popup.touch();
                  }}
                >
                  {" "}
                  +{" "}
                </BaseButton>
                <BaseButton
                  onClick={() => {
                    ActivationData.assignPop(
                      playerData,
                      planet.name,
                      "construction",
                      -1
                    );
                    Popup.touch();
                  }}
                >
                  {" "}
                  -{" "}
                </BaseButton>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const DisplayPlanetsSelection = () => {
    return (
      <div>
        {planetsInActiveSystem.map((planet, index) => {
          return (
            <div key={index}>
              <div>Manage your planets.</div>
              <PlanetViewActivation spaceObject={planet}>
                <BaseButton
                  onClick={() => {
                    setStatus("manage planet");
                    setSelectedPlanet(planet);
                  }}
                >
                  Manage {planet.name}
                </BaseButton>
              </PlanetViewActivation>
            </div>
          );
        })}
      </div>
    );
  };*/

  /*const DisplayManagePlanet = () => {
    return (
      <div>
        <PlanetViewActivation spaceObject={selectedPlanet}>
          <BaseButton>Manage {selectedPlanet.name}</BaseButton>
        </PlanetViewActivation>
      </div>
    );
  };*/

  /*return (
    <ActivationViewLayout style={{ height: "100%" }}>
      <div className="text-box" style={{ height: "100%", fontSize: "1.2rem" }}>
        {status === "planet selection" && (
          <DisplayPlanetsSelection></DisplayPlanetsSelection>
        )}{" "}
        {status === "manage planet" && (
          <DisplayManagePlanet></DisplayManagePlanet>
        )}
      </div>
      <ActivationNavigationView
        navigationData={navigationData}
      ></ActivationNavigationView>
    </ActivationViewLayout>
  );*/
};

export default ActivationPlanetManagementView;
