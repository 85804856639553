const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const ActivationData = require("../Activation/ActivationData.js");
const PlanetData = require("../MapData/Planet.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const SpaceObject = require("../MapData/SpaceObject.js");
const LogBook = require("../Connection/LogBook.js");

class ProductionData {
  //Transfer Functions

  static prepareStep(playerData, planet) {
    const activeSystem = ActivationData.getActiveSystem(playerData);
    const data = {
      planetName: planet.name,
      popOnMineral: 0,
      popOnScience: 0,
    };

    playerData.activationData.productionStep = data;
  }

  static getData(playerData) {
    return playerData.activationData.productionStep;
  }

  static assignPopToPlanet(playerData, planet, resourceType, pop) {
    console.log("assignPopToPlanet", playerData);
    const data = ProductionData.getData(playerData);

    let resType = "";
    if (resourceType === "mineral") {
      resType = "popOnMineral";
    } else if (resourceType === "science") {
      resType = "popOnScience";
    } else {
      return;
    }

    console.log("BEFORE");
    console.log("popOnMineral", planet.popOnMineral);
    console.log("popOnScience", planet.popOnScience);
    console.log("pop", pop);
    console.log("planet.unassignedPop", planet.unassignedPop);

    if (pop < 0 && data[resType] + pop < 0) return;
    if (pop > 0 && pop > planet.unassignedPop) return;

    if (resType === "popOnMineral") {
      Planet.assignPopOnMineral(planet, pop);
    }
    if (resType === "popOnScience") {
      Planet.assignPopOnScience(planet, pop);
    }

    data.popOnMineral = planet.popOnMineral;
    data.popOnScience = planet.popOnScience;

    Popup.touch();
  }

  static getPopOnMineral(playerData) {
    const data = this.getData(playerData);
    return data.popOnMineral;
  }

  static getPopOnScience(playerData) {
    const data = this.getData(playerData);
    return data.popOnScience;
  }

  static resolveProduction(playerData) {
    const data = this.getData(playerData);
    const activeSystem = ActivationData.getActiveSystem(playerData);
    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);

    if (planet.hasProduced) {
      throw new Error("You have already produced on this planet.");
    }

    const popOnMineral = data.popOnMineral;
    const popOnScience = data.popOnScience;

    if (popOnMineral + popOnScience > planet.population) {
      throw new Error("You cannot assign more population than the planet has.");
    }

    Planet.assignPopOnMineral(planet, popOnMineral);
    Planet.assignPopOnScience(planet, popOnScience);

    System.logActivityToSpaceObject(
      activeSystem,
      planet,
      Planet.getProductionLog(playerData, planet)
    );

    Planet.produceMineral(playerData, planet);
    Planet.produceScience(playerData, planet);
    Planet.producePopulation(playerData, planet);
    Planet.produceEnergy(playerData, planet);
    Planet.produceCredit(playerData, planet);
    planet.hasProduced = true;

    try {
      Planet.checkAfterPlacingPop(playerData, planet);
    } catch (error) {
      UIMessage.sendInfoMessageToClient(
        playerData,
        planet.name + " overcrowded",
        planet.name +
          " is overcrowded. It is holding " +
          planet.population +
          " population, and its size is only " +
          planet.size +
          ". At the end of the activation phase, the population will be reduced to " +
          planet.size +
          ". You can spend population when building structures and units to avoid this."
      );
    }
  }
}

module.exports = ProductionData;
