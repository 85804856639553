import React, { useState } from "react";
import Logo from "./Logo.jsx";

const IMAGE_LOGO_BASE = "/Images/";
const ImagesUrl = require("../../Data/Other/ImagesUrl.js");

const LogoPopAssigned = ({ assignType, heightRem, centerYToText = true }) => {
  if (!assignType) {
    throw new Logo("No assignType to display");
  }

  const DisplayAssignedPop = () => {
    let resourceHandled = null;
    const resourceHandledSize = "0.75em";

    if (assignType === "popOnMineral") {
      resourceHandled = (
        <Logo logoName="mineral" heightRem={resourceHandledSize}></Logo>
      );
    }
    if (assignType === "popOnScience") {
      resourceHandled = (
        <Logo logoName="science" heightRem={resourceHandledSize}></Logo>
      );
    }

    if (assignType === "popOnEnergy") {
      resourceHandled = (
        <Logo logoName="energy" heightRem={resourceHandledSize}></Logo>
      );
    }

    return (
      <span style={{ position: "relative" }}>
        <Logo logoName="population"></Logo>
        {true && (
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "40%",
              transform: "translateY(-50%)",
            }}
          >
            {resourceHandled}
          </span>
        )}
      </span>
    );
  };

  return (
    <span
      style={{
        position: centerYToText ? "relative" : "unset",
        top: centerYToText ? "0.1em" : "unset",
        left: centerYToText ? 0 : "unset",
      }}
    >
      <DisplayAssignedPop></DisplayAssignedPop>
    </span>
  );
};

export default LogoPopAssigned;
