class UnitAbility {
  static TYPE = "unit";
  static ID_AFC = "antiFigtherCapsule";
  static ID_BOMBARDMENT = "bombardment";
  static ID_SPACE_CANON_DEFENSE = "spaceCanonDefense";
  static ID_PLANETARY_CANON_DEFENSE = "planetaryCanonDefense";
  static ID_PLANETARY_SHIELD = "planetaryShield";

  static object = {
    antiFigtherCapsule: (data) => {
      return {
        id: this.NAME_AFC,
        label: "Anti Figther Capsule " + data.min + " - " + data.max,
        active: false,
        exhaustable: false,
        type: this.TYPE,
        effect:
          "After the long shot range step of a space combat, this unit produces " +
          data.min +
          " - " +
          data.max +
          " hits, only assigneable to fighters. This triggers only once per combat, before the combat rounds.",
        description:
          "Moving capsules dropped in space designed to seek and destroy very small ships.",
      };
    },
    bombardment: (data) => {
      return {
        id: this.ID_BOMBARDMENT,
        label: "Bombardment " + data.min + " - " + data.max,
        active: false,
        exhaustable: false,
        type: this.TYPE,
        effect:
          "Before you invade a planet, if you control the space area, this unit will produce " +
          data.min +
          " - " +
          data.max +
          " hits which will be assigned on ennemy ground forces on the planet. You have to actively decide to invade the planet, otherwise the bombardment will not happen.",
        description:
          "The unit can bombard the planet from space, destroying ground forces.",
      };
    },
    spaceCanonDefense: (data) => {
      return {
        id: this.ID_SPACE_CANON_DEFENSE,
        label: "Space Canon Defense " + data.min + " - " + data.max,
        active: false,
        exhaustable: false,
        type: this.TYPE,
        effect:
          "Before the start of a space combat in this system, deals " +
          data.min +
          " - " +
          data.max +
          " damages which will be assigned on ennemy ships.",
        description:
          "Very long range shot from the planet to the ennemy ships in space.",
      };
    },
    planetaryCanonDefense: (data) => {
      return {
        id: this.ID_PLANETARY_CANON_DEFENSE,
        label: "Planetary Canon Defense " + data.min + " - " + data.max,
        active: false,
        exhaustable: false,
        type: this.TYPE,
        effect:
          "Before the start of a ground combat in this system, deals " +
          data.min +
          " - " +
          data.max +
          " damages which will be assigned on ennemy ground forces.",
        description:
          "Shot on ennemy ground forces when they are being deployed.",
      };
    },
    planetaryShield: (data) => {
      return {
        id: this.ID_PLANETARY_SHIELD,
        label: "Canon DefensePlanetary Shield",
        active: false,
        exhaustable: false,
        type: this.TYPE,
        effect:
          "Reduce by " +
          50 +
          "% the bombardment damage generated towards this planet.",
        description:
          "The planet is covered by magnetic shield, reducing the damages taken by bombarding ships.",
      };
    },
  };

  //Get ability from id and data as parameters
  static getUnitAbility(id, data, type = this.TYPE_ALL) {
    if (type === UnitAbility.TYPE || type === this.TYPE_ALL) {
      return UnitAbility.object[id](data);
    }
  }

  //Data as parameters of an ability
  static createUnitAbilityData(id, data) {
    const ability = this.getUnitAbility(id, data);
    const abilityData = {
      id: id,
      data: data,
    };
    if (ability.exhaustable) abilityData.exhausted = false;

    return abilityData;
  }
}

module.exports = UnitAbility;
