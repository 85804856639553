import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";

import IconGridView from "./IconGridView.jsx";
import IconView from "../Icon/IconView.jsx";
import SpaceObjectImageView from "../Object/SpaceObjectImageView.jsx";
import GameState from "../../Data/GameData/Game/GameState.js";
import Logo from "../Utils/Logo.jsx";
import FactionLogo from "../Utils/FactionLogo.jsx";
import FleetsSummaryView from "../ManageSystem/FleetsSummaryView.jsx";
import UnitImageView from "../Object/UnitImageView.jsx";
import UnitsStackView from "../Units/UnitsStackView.jsx";
import ImagesUrl from "../../Data/Other/ImagesUrl.js";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject.js";
import MinorFactionObjectView from "../Object/MinorFactionObjectView.jsx";
import MinorFactionAndObjectiveMapView from "../Object/MinorFactionAndObjectiveMapView.jsx";
import BaseImage from "../Utils/BaseImage.jsx";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Popup = require("../../Data/Other/Popup.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const ActivationData = require("../../Data/GameData/Activation/ActivationData.js");
const Phase = require("../../Data/GameData/Game/Phase.js");
const EndRoundReviewCA = require("../../ClientAction/EndRoundReviewCA.js");
const Unit = require("../../Data/GameData/MapData/Unit.js");

const SecondaryObjectView = ({ sizeX, sizeY, spaceObject }) => {
  const playerData = StaticGameData.getPlayerData();

  const DisplayWormhole = () => {
    let imageUrl = "";
    if (spaceObject.wormhole === "b") {
      imageUrl = ImagesUrl.SECONDARY_OBJECT_WORMHOLE_B;
    }
    if (spaceObject.wormhole === "o") {
      imageUrl = ImagesUrl.SECONDARY_OBJECT_WORMHOLE_O;
    }
    if (spaceObject.wormhole === "p") {
      imageUrl = ImagesUrl.SECONDARY_OBJECT_WORMHOLE_P;
    }
    if (spaceObject.wormhole === "r") {
      imageUrl = ImagesUrl.SECONDARY_OBJECT_WORMHOLE_R;
    }
    if (spaceObject.wormhole === "y") {
      imageUrl = ImagesUrl.SECONDARY_OBJECT_WORMHOLE_Y;
    }
    if (spaceObject.wormhole === "u") {
      imageUrl = ImagesUrl.SECONDARY_OBJECT_WORMHOLE_U;
    }
    if (spaceObject.wormhole === "t") {
      imageUrl = ImagesUrl.SECONDARY_OBJECT_WORMHOLE_T;
    }
    if (spaceObject.wormhole === "g") {
      imageUrl = ImagesUrl.SECONDARY_OBJECT_WORMHOLE_G;
    }

    const imageStyle = {
      //If maxWidth defined, use it here, otherwise use 100%
      maxWidth: sizeX ? sizeX : "100%",
      maxHeight: sizeY ? sizeY : "100%",
      height: "auto",
    };
    console.log("wormhole imageUrl=" + imageUrl);
    console.log("wormhole ", spaceObject);

    return (
      <BaseImage style={imageStyle} src={imageUrl} alt="wormhole"></BaseImage>
    );
  };

  const DisplaySpaceObject = () => {
    if (spaceObject.type === SecondaryObject.SECONDARY_OBJECT_WORMHOLE) {
      return <DisplayWormhole></DisplayWormhole>;
    }
    if (spaceObject.type === SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION) {
      return (
        <div style={{ position: "relative" }}>
          <MinorFactionAndObjectiveMapView
            spaceObject={spaceObject}
          ></MinorFactionAndObjectiveMapView>
        </div>
      );
    }
  };

  return <DisplaySpaceObject></DisplaySpaceObject>;
};

/*<div
            className="div-fill"
            style={{ position: "relative", height: "20%" }}
          >
            <ObjectText></ObjectText>
          </div>*/

export default SecondaryObjectView;
