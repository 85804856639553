import React, { useState, useEffect } from "react";
import ActivationViewLayout from "./ActivationViewLayout";
import ActivationNavigationView from "./ActivationNavigationView";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import ExpandableView from "../../Utils/Layout/ExpandableView";
import SelectionListView from "../../Utils/Layout/SelectionListView";
import PlanetLogo from "../../Utils/PlanetLogo";
import CostView from "../../Utils/CostView";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const ActivationCA = require("../../../ClientAction/ActivationCA");
const Unit = require("../../../Data/GameData/MapData/Unit.js");
const Planet = require("../../../Data/GameData/MapData/Planet.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const WarningAction = require("../../../Data/GameData/ActionCommon/WarningAction");

const ActivationMoveView = ({ system, activationData, navigationData }) => {
  const playerData = StaticGameData.getPlayerData();

  //console.log("ActivationMoveView RENDER : ", playerData.activationData);

  const selectSystemCallback = (screenData) => {
    try {
      ActivationData.addSystemFrom(playerData, screenData.system);
      ActivationData.selectSystemFrom(playerData, screenData.system);
      Popup.resetMode();
      Popup.addLayer({ name: "ManageActivationView" });
    } catch (e) {
      UIMessage.displayErrorMessage("Can't select this system", e);
    }

    //Popup.touch();
  };

  const chooseSystemToMoveFromCallback = () => {
    Popup.setMode(
      { name: "choose system" },
      {
        taskBarText: "Select a system to move units from.",
        callbackButton: {
          nameView: "ManageSystemView",
          callback: selectSystemCallback,
          text: "Move units from this system",
        },
        cancelButton: { text: null, callback: null },
      }
    );
    Popup.unStackToMap();
    Popup.touch();
  };

  const confirmGoToNextStep = () => {
    const goNextStepCallback = () => {
      //console.log("goNextStepCallback : ", playerData.activationData);
      try {
        SystemAction.moveUnitsSystemsFrom(
          playerData,
          ActivationData.getActiveSystem(playerData),
          ActivationData.getSystemsFromList(playerData)
        );
        Request.updateGameState({
          action: "activation move",
          activationData: playerData.activationData,
        });
      } catch (e) {
        UIMessage.displayErrorMessage("Can't go to next step", e);
        throw e;
      }
    };

    UIMessage.displayConfirmMessage(
      "Go to next step?",
      "Are you sure to go to the next step? You will not be able to move units anymore to " +
        system.name +
        ".",
      goNextStepCallback
    );
  };

  const DisplayNoSystemFrom = () => {
    navigationData.buttonsData = [
      { text: "Go to transfer step", callback: confirmGoToNextStep },
      {
        text: "+ move",
        callback: chooseSystemToMoveFromCallback,
      },
    ];
    //Popup.touch();
    return (
      <div>
        <div>
          This is the beginning of the move step. You can move ships from other
          systems to this system. After this activation, ships will not be able
          to move out of this system.
        </div>
        <div>
          If you want to move units, go to the map view and select a system you
          want units to move from.
        </div>
      </div>
    );
  };

  const SystemFromSelected = ({
    unitSelectionArray,
    fleetResult,
    systemFrom,
  }) => {
    const systemFleet = System.getFleet(
      systemFrom.system,
      playerData.faction.name
    );
    const units = Fleet.getUnits(systemFleet, Fleet.UNIT_CLASS_ALL);
    const selectedUnits = Fleet.getUnits(systemFrom.fleet);

    const selectItem = (unitP) => {
      Fleet.addUnit(systemFrom.fleet, unitP);
    };
    const unselectItem = (unitP) => {
      Fleet.removeUnit(systemFrom.fleet, unitP);
    };
    const displayItem = (unitP) => {
      return (
        <UnitLineView
          unit={unitP}
          factionName={playerData.faction.name}
          hpOnBottom={false}
        ></UnitLineView>
      );
      return <span>{Unit.getPrintingName(unitP)}</span>;
    };

    console.log("systemFrom", systemFrom);

    return (
      <div>
        <SelectionListView
          items={units}
          displayItem={displayItem}
          selectedItems={selectedUnits}
          selectItem={selectItem}
          unSelectItem={unselectItem}
          textSelected="Don't Move"
          textUnselected="Move"
          textIfEmpty="No ships in this system."
        ></SelectionListView>
        <div>
          {systemFrom.planetsFrom.map((planetFrom, index) => {
            const planet = Map.getSpaceObjectFromName(
              playerData.map,
              planetFrom.planetName
            );
            const planetFleet = Planet.getFleetOrCreate(
              planet,
              playerData.faction.name,
              playerData.color
            );

            const planetUnits = Fleet.getUnits(
              planetFleet,
              Fleet.UNIT_CLASS_GROUND_FORCE
            );
            const selectedPlanetUnits = Fleet.getUnits(
              planetFrom.fleet,
              Fleet.UNIT_CLASS_GROUND_FORCE
            );
            const selectGF = (unitP) => {
              Fleet.addUnit(planetFrom.fleet, unitP);
              Fleet.addUnit(systemFrom.fleet, unitP);
            };
            const unselectGF = (unitP) => {
              Fleet.removeUnit(planetFrom.fleet, unitP);
              Fleet.removeUnit(systemFrom.fleet, unitP);
            };
            const displayGF = (unitP) => {
              return (
                <UnitLineView
                  unit={unitP}
                  factionName={playerData.faction.name}
                  hpOnBottom={false}
                ></UnitLineView>
              );
            };

            const onTogggleExpand = () => {
              planetFrom.collapsed = !planetFrom.collapsed;
            };

            const displayWhenCollapsed = () => {
              return <FleetSummaryView fleet={planetFleet}></FleetSummaryView>;
            };

            return (
              <div key={index}>
                <ExpandableView
                  expanded={planetFrom.collapsed}
                  onToggle={onTogggleExpand}
                  collapsedChildren={displayWhenCollapsed}
                >
                  <span>
                    <BaseButton
                      onClick={() => {
                        Popup.addLayer({
                          name: "ManageObjectView",
                          spaceObject: planet,
                        });
                      }}
                    >
                      {" "}
                      <PlanetLogo planet={planet}></PlanetLogo>
                      <span> {planet.name}</span>
                    </BaseButton>
                  </span>

                  <SelectionListView
                    items={planetUnits}
                    displayItem={displayGF}
                    selectedItems={selectedPlanetUnits}
                    selectItem={selectGF}
                    unSelectItem={unselectGF}
                    textSelected="Don't Move"
                    textUnselected="Move"
                    textIfEmpty="No ground forces on this planet."
                  ></SelectionListView>
                </ExpandableView>
              </div>
            );
          })}
        </div>
      </div>
    );

    if (unitSelectionArray) {
      return (
        <UnitSelectionView
          unitSelectionArray={unitSelectionArray}
          fleetResult={fleetResult}
        ></UnitSelectionView>
      );
    } else {
      return <div>You have no ships or ground forces in this system.</div>;
    }
  };

  const SystemsFrom = () => {
    const DisplayOneSystemFrom = ({ systemFrom }) => {
      const expandedToggle = () => {
        ActivationData.expandSystemFrom(playerData, systemFrom.system);
      };

      const fleetCapacity = Fleet.getTotalCapacity(
        playerData,
        systemFrom.fleet
      );
      const requiredCapacity = Fleet.getTotalRequiredCapacity(
        playerData,
        systemFrom.fleet
      );
      const fleetMass = Fleet.getLogisticMass(systemFrom.fleet);

      return (
        <div>
          <ExpandableView
            expanded={!systemFrom.collapsed}
            onToggle={expandedToggle}
            collapsedChildren={() => {
              return (
                <div>
                  <div>Moving fleet : </div>
                  <div style={{ marginLeft: "2em" }}>
                    <div>
                      Capacity : {requiredCapacity} / {fleetCapacity}, mass :{" "}
                      {fleetMass}
                    </div>
                    <div>
                      <FleetSummaryView
                        fleet={systemFrom.fleet}
                      ></FleetSummaryView>
                    </div>
                  </div>
                </div>
              );
            }}
          >
            <span>
              <BaseButton
                onClick={() => {
                  Popup.addLayer({
                    name: "ManageSystemView",
                    system: systemFrom.system,
                  });
                }}
              >
                {systemFrom.system.name} system
              </BaseButton>
            </span>
            <span>
              <SystemFromSelected
                unitSelectionArray={systemFrom.unitSelectionArray}
                fleetResult={systemFrom.fleet}
                systemFrom={systemFrom}
              ></SystemFromSelected>
            </span>
          </ExpandableView>
          <hr></hr>
        </div>
      );

      return (
        <div>
          <span
            style={{ fontSize: "1.5rem" }}
            onClick={() => {
              ActivationData.expandSystemFrom(playerData, systemFrom.system);
              Popup.touch();
            }}
          >
            + {systemFrom.system.name}
            <span> </span>
            <span>(Distance : {systemFrom.distance})</span>
          </span>

          {systemFrom.collapsed === false ? (
            <span>
              <SystemFromSelected
                unitSelectionArray={systemFrom.unitSelectionArray}
                fleetResult={systemFrom.fleet}
              ></SystemFromSelected>
            </span>
          ) : (
            <span>
              <FleetSummaryView fleet={systemFrom.fleet}></FleetSummaryView>
            </span>
          )}
        </div>
      );
    };

    const DisplaySystems = () => {
      return (
        <div>
          {ActivationData.getSystemsFromList(playerData)
            .filter((systemFrom) => {
              console.log("systemFrom", systemFrom);
              return !systemFrom.system.hasBeenActivated;
            })
            .map((systemFrom, index) => (
              <DisplayOneSystemFrom
                key={index}
                systemFrom={systemFrom}
              ></DisplayOneSystemFrom>
            ))}
        </div>
      );
    };

    const validationMove = () => {
      ActivationCA.resolve_STEP_ACTIVATION_MOVE("move");
    };
    const noMove = () => {
      ActivationCA.resolve_STEP_ACTIVATION_MOVE("no move");
    };
    const warningMove = () => {
      ActivationData.warningMove(playerData, validationMove);
    };

    const confirmMove = () => {
      UIMessage.displayConfirmMessage(
        "Confirm move ?",
        "Are you sure you want to move these units to " +
          ActivationData.getActiveSystem(playerData).name +
          " ? After confirmation, you will not be able to move units anymore to " +
          ActivationData.getActiveSystem(playerData).name +
          ".",
        warningMove
      );
    };
    const confirmNoMove = () => {
      UIMessage.displayConfirmMessage(
        "No Move ?",
        "Are you sure you don't want to move units to " + system.name + " ?",
        validationMove
      );
    };

    navigationData.buttonsData.push(
      {
        text: "Help",
        callback: () => {
          Popup.addLayer({ name: "HelpPopup", helpReference: "moveStep" });
        },
      },
      {
        text: "Don't move",
        callback: () => {
          ActivationCA.initialize_STEP_ACTIVATION_ACTIVATED();
        },
        type: "action",
      },
      { text: "Move", callback: confirmMove, type: "action" }
    );

    //Popup.touch();
    return (
      <div>
        <DisplaySystems></DisplaySystems>
      </div>
    );
  };

  //Display cost
  const cost = ActivationData.getCostMove(playerData);
  navigationData.child = (
    <div>
      <span>Move cost : </span>
      <CostView cost={cost}></CostView>
    </div>
  );

  return (
    <div className="text-box" style={{ height: "100%" }}>
      <div>
        You can move units from system that were not already activated this
        round to the active system {system.name}.
      </div>

      <hr></hr>
      <SystemsFrom></SystemsFrom>
    </div>
  );
};

export default ActivationMoveView;

//
