const PlayerData = require("../Data/GameData/PlayerData/PlayerData.js");
const Map = require("../Data/GameData/MapData/Map.js");
const System = require("../Data/GameData/MapData/System.js");
const Faction = require("../Data/GameData/PlayerData/Faction.js");
const Game = require("../Data/GameData/Game/Game.js");
const Color = require("../Common/Config/Colors.js");
const UIMessage = require("../Data/GameData/Connection/UIMessage.js");
const Request = require("../Common/Requests/Request.js");
const Phase = require("../Data/GameData/Game/Phase.js");
const StaticGameData = require("../Data/GameData/StaticGameData.js");
const Popup = require("../Data/Other/Popup.js");
const Rules = require("../Data/GameData/Game/Rules.js");

class MandatoryActionCA {
  static initialize(gameState) {
    const mandatoryActionData = PlayerData.getFirstMandatoryAction(
      gameState.playerData
    );

    if (!mandatoryActionData) {
      return;
    }

    if (mandatoryActionData.name === Phase.STEP_MANDATORY_FLEET_LIMIT) {
      this.initialize_STEP_MANDATORY_FLEET_LIMIT(gameState);
    }
    if (mandatoryActionData.name === Phase.STEP_MANDATORY_FLEET_CAPACITY) {
      this.initialize_STEP_MANDATORY_FLEET_CAPACITY(gameState);
    }
  }

  static initialize_STEP_MANDATORY_FLEET_LIMIT(gameState) {
    Popup.reset();
    Popup.addLayer({ name: "MandatoryActionGlobalView" });
  }

  static resolve_STEP_MANDATORY_FLEET_LIMIT() {
    Popup.unStackToMap();
    const scrapData = PlayerData.getFirstMandatoryAction(
      StaticGameData.getPlayerData()
    );
    Request.updateGameState(scrapData);
  }

  static initialize_STEP_MANDATORY_FLEET_CAPACITY(gameState) {
    Popup.reset();
    Popup.addLayer({ name: "MandatoryActionGlobalView" });
  }

  static resolve_STEP_MANDATORY_FLEET_CAPACITY() {
    Popup.unStackToMap();
    const scrapData = PlayerData.getFirstMandatoryAction(
      StaticGameData.getPlayerData()
    );
    Request.updateGameState(scrapData);
  }
}

module.exports = MandatoryActionCA;
