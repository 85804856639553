const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../../GameData/Connection/LogMessage.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Cost = require("../Utils/Cost.js");
const SpaceObject = require("../MapData/SpaceObject.js");
const LogBook = require("../Connection/LogBook.js");

class TransferData {
  //Transfer Functions

  static VIEW_GLOBAL_VIEW = "GlobalView";
  static VIEW_TRANSFER = "Transfer";

  static switchToView(playerData, view) {
    playerData.activationData.transferData.view = view;

    if (view === TransferData.VIEW_TRANSFER) {
      const planet = this.getSelectedPlanet(playerData);
      playerData.activationData.transferData.amountMineral = 0;
      playerData.activationData.transferData.amountPopulation = 0;
      playerData.activationData.transferData.availablePopulation =
        planet.unassignedPop;
      playerData.activationData.transferData.availableMineral = planet.mineral;
    }
    Popup.touch();
  }

  static getView(playerData) {
    return playerData.activationData.transferData.view;
  }

  static prepareTransferData(playerData, planet) {
    const data = {
      receivingPlanet: planet,
      selectedPlanet: null,
      view: TransferData.VIEW_GLOBAL_VIEW,
      amountMineral: 0,
      amountPopulation: 0,
      availablePopulation: 0,
      availableMineral: 0,
    };

    playerData.activationData.transferData = data;
  }

  static setAmountMineral(playerData, amount) {
    playerData.activationData.transferData.amountMineral = amount;
  }

  static setAmountPopulation(playerData, amount) {
    playerData.activationData.transferData.amountPopulation = amount;
  }

  /*static setReceivingPlanet(playerData, planet) {
    playerData.activationData.transferData.receivingPlanet = planet;
  }*/

  static getTransferData(playerData) {
    return playerData.activationData.transferData;
  }

  static getReceivingPlanet(playerData) {
    return Map.getSpaceObjectFromName(
      playerData.map,
      playerData.activationData.transferData.receivingPlanet.name
    );
  }

  static selectPlanet(playerData, planet) {
    playerData.activationData.transferData.selectedPlanet = planet;
  }

  static unSelectPlanet(playerData) {
    playerData.activationData.transferData.selectedPlanet = null;
  }

  static getSelectedPlanet(playerData) {
    return Map.getSpaceObjectFromName(
      playerData.map,
      playerData.activationData.transferData.selectedPlanet.name
    );
  }

  static getSelectedPlanetData(playerData) {
    const data = {
      amountMineral: playerData.activationData.transferData.amountMineral,
      amountPopulation: playerData.activationData.transferData.amountPopulation,
      availableMineral: playerData.activationData.transferData.availableMineral,
      availablePopulation:
        playerData.activationData.transferData.availablePopulation,
    };
    return data;
  }

  static addMineral(playerData, amount) {
    if (
      amount < 0 &&
      playerData.activationData.transferData.amountMineral + amount < 0
    ) {
      return;
    }
    if (
      amount > 0 &&
      playerData.activationData.transferData.amountMineral + amount >
        playerData.activationData.transferData.availableMineral
    ) {
      return;
    }
    playerData.activationData.transferData.amountMineral = CustomMath.roundDec(
      playerData.activationData.transferData.amountMineral + amount
    );
    Popup.touch();
  }

  static addPopulation(playerData, amount) {
    const planet = this.getSelectedPlanet(playerData);
    const receivingPlanet = this.getReceivingPlanet(playerData);
    if (
      amount < 0 &&
      playerData.activationData.transferData.amountPopulation + amount < 0
    ) {
      return;
    }

    if (
      amount > 0 &&
      playerData.activationData.transferData.amountPopulation + amount >
        planet.unassignedPop
    ) {
      return;
    }

    if (
      amount > 0 &&
      playerData.activationData.transferData.amountPopulation +
        amount +
        receivingPlanet.population >
        receivingPlanet.size
    ) {
      UIMessage.displayInfoMessage(
        "Planet too small",
        "You can't transfer more population than the planet can hold. " +
          receivingPlanet.name +
          " has a size of " +
          receivingPlanet.size +
          ", so it can hold a maximum of " +
          receivingPlanet.size +
          " population."
      );
      return;
    }

    //Planet.assignPopulationOnTransfer(planet, amount);
    playerData.activationData.transferData.amountPopulation =
      CustomMath.roundDec(
        playerData.activationData.transferData.amountPopulation + amount
      );

    Popup.touch();
  }

  static getCostTransfer(playerData) {
    const cost = Cost.createCost({});

    const transferData = this.getTransferData(playerData);

    const costTransfer = PlayerData.getCostTransfer(
      playerData,
      transferData.amountPopulation,
      transferData.amountMineral
    );

    return costTransfer;
  }

  static resolveTransfer(playerData) {
    console.log("resolveTransfer", playerData.activationData.transferData);
    const receivingPlanet = Map.getSpaceObjectFromName(
      playerData.map,
      playerData.activationData.transferData.receivingPlanet.name
    );

    const sendingPlanet = Map.getSpaceObjectFromName(
      playerData.map,
      playerData.activationData.transferData.selectedPlanet.name
    );

    System.canTransferFromToPlanet(
      playerData,
      Map.getSystemFromSpaceObject(sendingPlanet, playerData.map),
      sendingPlanet
    );
    System.canTransferFromToPlanet(
      playerData,
      Map.getSystemFromSpaceObject(receivingPlanet, playerData.map),
      receivingPlanet
    );

    const amountMineral = playerData.activationData.transferData.amountMineral;
    const amountPopulation =
      playerData.activationData.transferData.amountPopulation;

    //Chekc that mineral and population are intergers with modulo
    CustomMath.checkInteger(amountMineral);
    CustomMath.checkInteger(amountPopulation);

    if (sendingPlanet.faction !== playerData.faction.name) {
      throw new Error("You can't transfer from a planet that is not yours");
    }
    if (amountMineral > sendingPlanet.mineral) {
      throw new Error("You can't transfer more mineral than the planet has");
    }
    if (amountPopulation > sendingPlanet.population) {
      throw new Error("You can't transfer more population than the planet has");
    }
    receivingPlanet.mineral = CustomMath.roundDec(
      receivingPlanet.mineral + amountMineral
    );
    Planet.addPopulation(receivingPlanet, amountPopulation);

    sendingPlanet.mineral = CustomMath.roundDec(
      sendingPlanet.mineral - amountMineral
    );
    Planet.removePopulation(sendingPlanet, amountPopulation);

    if (amountPopulation > 0) {
      Planet.checkAfterPlacingPop(playerData, receivingPlanet);
    }

    const cost = this.getCostTransfer(playerData);
    PlayerData.spendCost(playerData, cost);
  }
}

module.exports = TransferData;
