class Version {
  static currentVersion = 0.12;

  static isAtleatCurrentVersion(playerData) {
    return playerData.gameVersion >= Version.currentVersion;
  }

  static isAtleatVersion(playerData, version) {
    return playerData.gameVersion >= version;
  }

  static getVersion(playerData) {
    return playerData.gameVersion;
  }
}

module.exports = Version;
