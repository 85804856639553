import React, { useState, useEffect } from "react";
import ActivationViewLayout from "./ActivationViewLayout";
import ActivationNavigationView from "./ActivationNavigationView";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import SpaceObjectImageView from "../../Object/SpaceObjectImageView";
import PlanetViewActivation from "./Objects/PlanetViewActivation";
import Logo from "../../Utils/Logo";
import LogMessageView from "../../Utils/LogMessage/LogMessageView";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const Planet = require("../../../Data/GameData/MapData/Planet.js");
const ActivationCA = require("../../../ClientAction/ActivationCA");

const ActivationTransferInView = ({ system, setStep }) => {
  const playerData = StaticGameData.getPlayerData();

  const planetsInActiveSystem = System.getPlanetsFromFaction(
    system,
    playerData.faction.name
  );

  const taskBarData = {
    /*text:
      "Transfering " +
      ActivationData.getTotalTransferedMineral(playerData) +
      " minerals and " +
      ActivationData.getTotalTransferedPopulation(playerData) +
      " population...",*/
  };

  //console.log("ActivationMoveView RENDER : ", playerData.activationData);

  const endActivationConfirm = () => {
    const endActivation = () => {
      try {
        SystemAction.endActivation(playerData);
        Request.updateGameState({ action: "end activation phase" });
      } catch (error) {
        UIMessage.displayErrorMessage(error);
      }
    };
    UIMessage.displayConfirmMessage(
      "End activation",
      "Are you sure you want to end the activation phase of system " +
        system.name +
        " ?",
      endActivation
    );
  };

  const navigationData = {
    buttonsData: [
      {
        text: "Previous",
        callback: () => {
          setStep("transfer out");
        },
      },
      {
        text: "Confirm",
        callback: ActivationCA.resolve_STEP_ACTIVATION_TRANSFER,
      },
    ],
  };

  const DisplayPlanets = () => {
    return (
      //Map on system.spaceObjects where spaceObject.isPlanet

      <div>
        {planetsInActiveSystem.map((spaceObject, index) => {
          const transferData = ActivationData.getPlanetTransferData(
            playerData,
            spaceObject.name
          );
          const targetMineral =
            transferData.transferedInMineral + spaceObject.mineral;
          const targetPopulation =
            transferData.transferedInPopulation + spaceObject.population;
          const maxPop = Planet.getMaximumPopulation(playerData, spaceObject);
          console.log("maxPop : ", maxPop);
          return (
            <div key={index}>
              <div className="div-hor-center">
                <div style={{}}>
                  <PlanetViewActivation
                    spaceObject={spaceObject}
                    showStock={false}
                  >
                    <BaseButton
                      onClick={() => {
                        ActivationData.transferToPlanet(
                          playerData,
                          spaceObject.name,
                          0,
                          +1
                        );
                      }}
                    >
                      <span>
                        <span>+ </span>
                        <span>
                          <Logo logoName="mineral" heightRem="1em"></Logo>
                        </span>
                      </span>
                    </BaseButton>
                    <BaseButton
                      onClick={() => {
                        ActivationData.transferToPlanet(
                          playerData,
                          spaceObject.name,
                          0,
                          -1
                        );
                      }}
                    >
                      <span>
                        <span>- </span>
                        <span>
                          <Logo logoName="mineral" heightRem="1em"></Logo>
                        </span>
                      </span>
                    </BaseButton>
                    <BaseButton
                      onClick={() => {
                        ActivationData.transferToPlanet(
                          playerData,
                          spaceObject.name,
                          +1,
                          0
                        );
                      }}
                    >
                      <span>
                        <span>+ </span>
                        <span>
                          <Logo logoName="population" heightRem="1em"></Logo>
                        </span>
                      </span>
                    </BaseButton>
                    <BaseButton
                      onClick={() => {
                        ActivationData.transferToPlanet(
                          playerData,
                          spaceObject.name,
                          -1,
                          0
                        );
                      }}
                    >
                      <span>
                        <span>- </span>
                        <span>
                          <Logo logoName="population" heightRem="1em"></Logo>
                        </span>
                      </span>
                    </BaseButton>
                    <div>
                      <span>Planet size : {spaceObject.size} </span>
                    </div>
                    <div>
                      <span>
                        Current stock : {spaceObject.mineral}{" "}
                        <Logo logoName="mineral" heightRem="1em"></Logo>
                      </span>
                      <span>
                        , {spaceObject.population}{" "}
                        <Logo logoName="population" heightRem="1em"></Logo>
                      </span>
                    </div>
                    <div>
                      <span>
                        Target stock : {targetMineral}
                        <Logo logoName="mineral" heightRem="1em"></Logo>
                      </span>
                      <span>
                        , {targetPopulation}{" "}
                        <Logo logoName="population" heightRem="1em"></Logo>
                      </span>
                    </div>
                    <div>
                      Maximum population at the end of the activation phase :{" "}
                      {maxPop}
                    </div>
                  </PlanetViewActivation>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const DisplayTotalTransfered = () => {
    const cargoCost = ActivationData.getTransferCargoCost(
      playerData,
      ActivationData.getTotalTransferedPopulation(playerData),
      ActivationData.getTotalTransferedMineral(playerData)
    );
    return (
      <div>
        <div>
          <span>
            Total to distribute :{" "}
            {ActivationData.getTotalTransferedMineralLeft(playerData)} /
            {ActivationData.getTotalTransferedMineral(playerData)}{" "}
            <Logo logoName="mineral" heightRem="1em"></Logo>
            <span> , </span>
            {ActivationData.getTotalTransferedPopulationLeft(playerData)} /{" "}
            {ActivationData.getTotalTransferedPopulation(playerData)}{" "}
            <Logo logoName="population" heightRem="1em"></Logo>
          </span>
        </div>
        <div>
          <LogMessageView logMessage={cargoCost.log}></LogMessageView>
        </div>
      </div>
    );
  };

  return (
    <ActivationViewLayout style={{ height: "100%" }}>
      <div className="text-box" style={{ height: "100%" }}>
        <div>
          Choose which resource is transfered to which planet in the active
          system.
        </div>
        <div>
          Pay attention that any population exceeding the population limit of
          the planet will be lost at the end of the activation phase. Be
          carefull to spend the population in excess before the end of the
          activation phase.
        </div>
        <hr></hr>
        <DisplayTotalTransfered></DisplayTotalTransfered>
        <hr></hr>
        <div style={{}}>
          <DisplayPlanets></DisplayPlanets>
        </div>
      </div>
      <ActivationNavigationView
        navigationData={navigationData}
        taskBarData={taskBarData}
      ></ActivationNavigationView>
    </ActivationViewLayout>
  );
};

export default ActivationTransferInView;
