import React, { useState, useEffect } from "react";
import ActivationViewLayout from "./ActivationViewLayout";
import ActivationNavigationView from "./ActivationNavigationView";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import BaseButton from "../../Utils/BaseButton";
import LinkExt from "../../../Help/LinkExt";
import ManageSystemView from "../ManageSystemView";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Phase = require("../../../Data/GameData/Game/Phase.js");
const ActivationData = require("../../../Data/GameData/Activation/ActivationData.js");

const ActivationActivatedView = ({
  system,
  activationData,
  navigationData,
}) => {
  const playerData = StaticGameData.getPlayerData();

  const goToMoveStep = () => {
    try {
      Request.updateGameState({ action: "end activation" });
    } catch (e) {
      UIMessage.displayErrorMessage("Can't go to move step !", e);
      //throw e;
    }
  };

  const confirmToMoveStep = () => {
    goToMoveStep();
    /*UIMessage.displayConfirmMessage(
      "Go to move step?",
      "Are you sure to go to the move step? The activation step will end.",
      goToMoveStep
    );*/
  };

  navigationData.buttonsData.push({
    text: "Next",
    callback: confirmToMoveStep,
    type: "action",
  });

  return (
    <div className="text-box" style={{ height: "100%" }}>
      <div>
        {" "}
        You will resolve an activation on {system.name}. {system.name} is now
        the active system during this activation. Here are the phases of the
        activation :{" "}
      </div>
      <div>
        {" "}
        <ul>
          <li>Move (move units to {system.name})</li>
          <li>Lift units from planets you control to your ships</li>
          <li>Drop units from your ships to the planets you control</li>
          <li>Invasion of one planet you don't yet control</li>
          <li>Production of resource</li>
          <li>Construction of structures</li>
          <li>Construction of units</li>
        </ul>
      </div>
      <div>
        Help about activation :{" "}
        <LinkExt linkc="activationGlobal" text="Activation"></LinkExt>
      </div>
    </div>
  );
};

export default ActivationActivatedView;

//
