const { desktopNames } = require("browserslist");
const CustomMath = require("../../../Common/Math/CustomMath");
const Law = require("./Law");
const Unit = require("../MapData/Unit");
const SpaceObject = require("../MapData/SpaceObject");
const Map = require("../MapData/Map");
const Item = require("../Transactions/Item");
const LogBook = require("../Connection/LogBook");
const Planet = require("../MapData/Planet");
const Fleet = require("../MapData/Fleet");
const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction");
const System = require("../MapData/System");
const SecondaryObject = require("../MapData/SecondaryObject");
const PlayerData = require("../PlayerData/PlayerData");
const TechTree = require("../Technology/TechTree");
const Tech = require("../Technology/Tech");
const Cost = require("../Utils/Cost");
const { gameState } = require("../StaticGameData");
const GameGlobalFunctions = require("../../Other/GameGlobalFunctions");

class ResolveLaw {
  static createLawValues(playerData, law) {
    if (law.name === Law.NAME_OBSEOLESCENCE_MANDATE) {
      const types = [...Unit.getListShipTypes()];
      CustomMath.shuffleArray(types);
      law.values = { type: types.pop() };
      return;
    }

    if (law.name === Law.NAME_EXPERIMENTAL_UPGRADE_PROGRAM) {
      const types = [...Unit.getListShipTypes()];
      CustomMath.shuffleArray(types);
      law.values = { type: types.pop() };
      return;
    }
    if (law.name === Law.NAME_HEALTH_REFORM) {
      const types = [...SpaceObject.getPlanetTypes()];
      CustomMath.shuffleArray(types);
      law.values = { type1: types.pop(), type2: types.pop() };
      return;
    }
    if (
      law.name === Law.NAME_UNIFIED_RESEARCH_ACCORD ||
      law.name === Law.NAME_MINERAL_ALLOCATION_DIRECTIVE ||
      law.name === Law.NAME_ENERGY_ALLOCATION_DIRECTIVE ||
      law.name === Law.NAME_POPULATION_CONSERVATION_ACT
    ) {
      const amount = CustomMath.roundDec(CustomMath.generateRandomNumber(0, 8));
      law.values = { amount: amount };
      return;
    }
    if (
      law.name === Law.NAME_MINING_REGULATION ||
      law.name === Law.NAME_RESEARCH_REGULATION ||
      law.name === Law.NAME_ENERGY_REGULATION
    ) {
      const amount = CustomMath.roundDec(CustomMath.generateRandomReal(2, 2.5));
      law.values = { amount: amount };
      return;
    }
    if (law.name === Law.NAME_POPULATION_REGULATION) {
      const amount = CustomMath.roundDec(CustomMath.generateRandomReal(1, 1.5));
      law.values = { amount: amount };
      return;
    }
    if (law.name === Law.NAME_WEALTH_EQUALIZATION) {
      const amount = CustomMath.roundDec(CustomMath.generateRandomNumber(0, 8));
      law.values = { amount: amount };
      return;
    }
    if (law.name === Law.NAME_RESEARCH_TAXATION) {
      const amount = CustomMath.roundDec(CustomMath.generateRandomNumber(1, 2));
      law.values = { amount: amount };
      return;
    }
    if (law.name === Law.NAME_FLEET_DECENTRALIZATION_POLICY) {
      const amount = CustomMath.roundDec(CustomMath.generateRandomNumber(1, 3));
      law.values = { amount: amount };
      return;
    }
  }

  static createLaw(playerData, name) {
    const law = {};
    law.name = name;
    this.createLawValues(playerData, law);
    return law;
  }

  static createRandomLaw = () => {
    const nameDeck = Law.getLawNameDeck();
    CustomMath.shuffleArray(nameDeck);
    const name = nameDeck.pop();
    return this.createLaw(null, name);
  };

  static createTwoDifferentLaws = () => {
    const nameDeck = Law.getLawNameDeck();
    CustomMath.shuffleArray(nameDeck);
    const name1 = nameDeck.pop();
    const name2 = nameDeck.pop();
    return [this.createLaw(null, name1), this.createLaw(null, name2)];
  };

  static adminBeginningOfRoundLaw(playerData) {}

  static getVoteFromPlanets(map, playerDataTarget) {
    return 0;
    if (!playerDataTarget.faction) return 0;
    const planets = Map.getPlanetsFromFaction(
      map,
      playerDataTarget.faction.name
    );
    const amountPlanets = planets.length;
    const voteAmount = amountPlanets;
    return CustomMath.roundDec(voteAmount);
  }

  static getVoteFromItems(playerDataTarget) {
    if (!playerDataTarget.faction) return 0;
    const items = playerDataTarget.items;
    let amountVote = 0;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.name === Item.NAME_POLITIC) {
        amountVote = amountVote + item.data.value;
      }
    }

    return CustomMath.roundDec(amountVote);
  }

  static getVoteAmount(map, playerDataTarget) {
    if (!playerDataTarget.faction) return 0;
    const voteFromPlanets = this.getVoteFromPlanets(map, playerDataTarget);
    const voteFromItems = this.getVoteFromItems(playerDataTarget);
    return CustomMath.roundDec(voteFromPlanets + voteFromItems);
  }

  static resolveLawResult(gameStateList) {
    const logBook = LogBook.createLogBook();

    const referentPlayerData = gameStateList[0].playerData;

    const votingLawsTab = [...Law.getVotingLaws(referentPlayerData)].map(
      (law) => {
        return { law: law, votes: 0 };
      }
    );

    const playerDataList = gameStateList.map(
      (gameState) => gameState.playerData
    );

    if (votingLawsTab.length > 0) {
      for (let i = 0; i < playerDataList.length; i++) {
        const playerData = playerDataList[i];

        const lawVotedName = Law.getPlayerVote(playerData);
        const amountVotes = this.getVoteAmount(playerData.map, playerData);
        for (let k = 0; k < votingLawsTab.length; k++) {
          if (votingLawsTab[k].law.name === lawVotedName) {
            votingLawsTab[k].votes = CustomMath.roundDec(
              votingLawsTab[k].votes + amountVotes
            );
            LogBook.generateAddMessage(
              logBook,
              "$faction$ voted for law " +
                lawVotedName +
                " with " +
                amountVotes +
                " votes.",
              [playerData.faction.name]
            );
          }
        }
      }

      votingLawsTab.sort((a, b) => {
        return b.votes - a.votes;
      });

      LogBook.generateAddMessage(logBook, "The following laws were voted:");
      for (let i = 0; i < votingLawsTab.length; i++) {
        LogBook.generateAddMessage(
          logBook,
          votingLawsTab[i].law.name +
            " with " +
            votingLawsTab[i].votes +
            " votes."
        );
      }
      LogBook.generateAddMessage(
        logBook,
        votingLawsTab[0].law.name + "passed. The others are discarded. "
      );

      for (let i = 0; i < playerDataList.length; i++) {
        const playerData = playerDataList[i];
        Law.resetActiveLaws(playerData);
        Law.addActiveLaw(playerData, votingLawsTab[0].law);
      }
    }

    let nextVotingLaws = null;
    if (
      referentPlayerData.roundNumber < referentPlayerData.gameParam.roundAmount
    ) {
      nextVotingLaws = this.createTwoDifferentLaws();
      for (let i = 0; i < nextVotingLaws.length; i++) {
        LogBook.generateAddMessage(
          logBook,
          "Added a new law to vote for :  " + nextVotingLaws[i].name + "."
        );
      }
    }

    for (let i = 0; i < playerDataList.length; i++) {
      const playerData = playerDataList[i];
      Law.resetVotingLaws(playerData);
      Law.resetPlayerVote(playerData);
      if (nextVotingLaws) {
        for (let j = 0; j < nextVotingLaws.length; j++) {
          Law.addVotingLaw(playerData, nextVotingLaws[j]);
        }
      }

      Law.appendLog(playerData, logBook);
    }
  }

  static serverResolve(playerData, resolveData) {
    const lawName = resolveData.lawName;
    Law.setPlayerVote(playerData, lawName);
  }

  static resolveActiveLawAtEndOfRound(playerData) {
    const activelaw = Law.getActiveLaws(playerData);
    for (let i = 0; i < activelaw.length; i++) {
      const law = activelaw[i];
      const lawName = law.name;

      if (lawName === Law.NAME_ISOLATIONISM_DIRECTIVE) {
        this.isolationismDirectiveEndOfRound(playerData, law);
      }

      return;
    }
  }

  static isolationismDirectiveEndOfRound(playerData, law) {
    playerData.credit = 0;

    this.log(playerData, law, "Credit set to 0.");
  }

  static resolveActiveLawAtBeginningOfRound(playerData) {
    const activelaw = Law.getActiveLaws(playerData);
    for (let i = 0; i < activelaw.length; i++) {
      const law = activelaw[i];
      const lawName = law.name;

      if (lawName === Law.NAME_HEALTH_REFORM) {
        this.healthReform(playerData, law);
      }
      if (lawName === Law.NAME_RIFT_PROTECTION_ACT) {
        this.riftProtectionAct(playerData, law);
      }
      if (
        lawName === Law.NAME_UNIFIED_RESEARCH_ACCORD ||
        lawName === Law.NAME_MINERAL_ALLOCATION_DIRECTIVE ||
        lawName === Law.NAME_ENERGY_ALLOCATION_DIRECTIVE ||
        lawName === Law.NAME_POPULATION_CONSERVATION_ACT
      ) {
        this.resourceAllocationDirective(playerData, law);
      }
      if (
        lawName === Law.NAME_MINING_REGULATION ||
        lawName === Law.NAME_RESEARCH_REGULATION ||
        lawName === Law.NAME_ENERGY_REGULATION ||
        lawName === Law.NAME_POPULATION_REGULATION
      ) {
        this.resourceRegulation(playerData, law);
      }

      if (lawName === Law.NAME_WEALTH_EQUALIZATION) {
        this.wealthEqualization(playerData, law);
      }
      if (lawName === Law.NAME_RESEARCH_TAXATION) {
        this.researchTaxation(playerData, law);
      }

      if (lawName === Law.NAME_FREE_TRADE_ACCORD) {
        this.freeTradeAccord(playerData, law);
      }
      if (lawName === Law.NAME_GALACTIC_CULLING_PROTOCOL) {
        this.galacticCullingProtocol(playerData, law);
      }
      if (lawName === Law.NAME_HELL_MOON_DOCTRINE) {
        this.hellMoonDoctrine(playerData, law);
      }
      if (lawName === Law.NAME_FLEET_DECENTRALIZATION_POLICY) {
        this.fleetDecentralizationPolicy(playerData, law);
      }

      return;
    }
  }

  static healthReform(playerData, law) {
    const planets = Map.getPlanetList(playerData.map);
    const planetLogList = [];
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      if (
        planet.type === law.values.type1 ||
        planet.type === law.values.type2
      ) {
        const units = Planet.getAllUnits(planet);
        const infantries = units.filter(
          (unit) => unit.type === Unit.UNIT_TYPE_INFANTRY
        );
        MapUnitaryAction.forceRemoveUnitsFromPlanetAllPlayers(
          playerData,
          planet,
          infantries
        );
        if (infantries.length > 0) {
          planetLogList.push(planet);
        }
      }
    }
    if (planetLogList.length > 0) {
      this.log(
        playerData,
        law,
        "Infantries on " +
          planetLogList.map((planet) => planet.name).join(", ") +
          " have been removed."
      );
    }
  }

  static riftProtectionAct(playerData, law) {
    const systemList = Map.getSystemList(playerData.map);
    const shipLogList = [];

    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];

      //check if whormhole
      const spaceObject = System.getSpaceObjectsFromType(
        system,
        SecondaryObject.SECONDARY_OBJECT_WORMHOLE
      );
      if (spaceObject.length > 0) {
        //Destroy all ships in the system
        const units = System.getAllUnitsInSpace(system);
        MapUnitaryAction.forceRemoveUnitsFromSAAllPlayers(
          playerData,
          system,
          units
        );
        if (units.length > 0) {
          shipLogList.push(system);
        }
      }
    }
    if (shipLogList.length > 0) {
      this.log(
        playerData,
        law,
        "Ships in " +
          shipLogList.map((system) => system.name).join(", ") +
          " have been removed."
      );
    }
  }

  static resourceAllocationDirective(playerData, law) {
    const amount = law.values.amount;
    if (law.name === Law.NAME_UNIFIED_RESEARCH_ACCORD) {
      playerData.science = amount;
      this.log(playerData, law, "Science set to  " + amount + ".");
    }
    if (law.name === Law.NAME_MINERAL_ALLOCATION_DIRECTIVE) {
      playerData.mineral = amount;
      this.log(playerData, law, "Minerals set to  " + amount + ".");
    }
    if (law.name === Law.NAME_ENERGY_ALLOCATION_DIRECTIVE) {
      playerData.energy = amount;
      this.log(playerData, law, "Energy set to  " + amount + ".");
    }
    if (law.name === Law.NAME_POPULATION_CONSERVATION_ACT) {
      playerData.population = amount;
      this.log(playerData, law, "Population set to  " + amount + ".");
    }
  }

  static resourceRegulation(playerData, law) {
    const amount = law.values.amount;

    const planets = Map.getPlanetList(playerData.map);
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];

      if (law.name === Law.NAME_MINING_REGULATION) {
        const planetProductionBase = Planet.getMineralProduction(
          playerData,
          planet,
          true
        );
        if (planetProductionBase > amount) {
          planet.mineralEfficiency = amount;
          this.log(
            playerData,
            law,
            "Base mineral production of " +
              planet.name +
              " set to  " +
              amount +
              "."
          );
        }
      }
      if (law.name === Law.NAME_RESEARCH_REGULATION) {
        const planetProductionBase = Planet.getScienceProduction(
          playerData,
          planet,
          true
        );
        if (planetProductionBase > amount) {
          planet.scienceEfficiency = amount;
          this.log(
            playerData,
            law,
            "Base science production of " +
              planet.name +
              " set to  " +
              amount +
              "."
          );
        }
      }
      if (law.name === Law.NAME_ENERGY_REGULATION) {
        const planetProductionBase = Planet.getEnergyProduction(
          playerData,
          planet,
          true
        );
        if (planetProductionBase > amount) {
          planet.energyEfficiency = amount;
          this.log(
            playerData,
            law,
            "Base energy production of " +
              planet.name +
              " set to  " +
              amount +
              "."
          );
        }
      }
      if (law.name === Law.NAME_POPULATION_REGULATION) {
        const planetProductionBase = Planet.getPopulationProduction(
          playerData,
          planet,
          true
        );
        if (planetProductionBase > amount) {
          planet.populationEfficiency = amount;
          this.log(
            playerData,
            law,
            "Base population production of " +
              planet.name +
              " set to  " +
              amount +
              "."
          );
        }
      }
    }
  }

  static wealthEqualization(playerData, law) {
    const amount = law.values.amount;
    playerData.credit = amount;
    this.log(playerData, law, "Wealth set to  " + amount + ".");
  }

  static researchTaxation(playerData, law) {
    const amount = law.values.amount;

    const playerDataList = PlayerData.getAllPlayersData(playerData);
    for (let i = 0; i < playerDataList.length; i++) {
      const playerDataTarget = playerDataList[i];
      const techTree = playerDataTarget.techTree;
      const techList = TechTree.getAllTechs(techTree);
      for (let j = 0; j < techList.length; j++) {
        const tech = techList[j];
        Cost.addCostToCost(Cost.createCost({ science: amount }), tech.cost);
      }
    }

    this.log(playerData, law, "Research cost increased by  " + amount + ".");
  }

  static freeTradeAccord(playerData, law) {
    const item = Item.createRelic(
      playerData,
      Item.NAME_TRADE,
      playerData.faction.name,
      { value: 1 }
    );
    Item.addItemToArray(item, playerData.items);

    this.log(playerData, law, "Gained $item$", [item]);
  }

  static galacticCullingProtocol(playerData, law) {
    const planetList = Map.getPlanetList(playerData.map);
    const planetLogList = [];
    for (let i = 0; i < planetList.length; i++) {
      const planet = planetList[i];
      const units = Planet.getAllUnits(planet);
      const infantries = units.filter(
        (unit) => unit.type === Unit.UNIT_TYPE_INFANTRY
      );
      CustomMath.shuffleArray(infantries);
      //remove all but 2 infantries
      const infantriesToRemove = infantries.slice(2);
      MapUnitaryAction.forceRemoveUnitsFromPlanetAllPlayers(
        playerData,
        planet,
        infantriesToRemove
      );
      if (infantriesToRemove.length > 0) {
        planetLogList.push(planet);
      }
    }
    if (planetLogList.length > 0) {
      this.log(
        playerData,
        law,
        "Infantries on " +
          planetLogList.map((planet) => planet.name).join(", ") +
          " have been removed, except for 2 per planet."
      );
    }
  }

  static hellMoonDoctrine(playerData, law) {
    const item = Item.createArtifact(playerData, Item.NAME_HELL_MOON_DOCTRINE);
    Item.addItemToArray(item, playerData.items);

    this.log(playerData, law, "Gained $item$", [item]);
  }

  static fleetDecentralizationPolicy(playerData, law) {
    const amount = law.values.amount;
    playerData.cargo = CustomMath.roundInt(
      Math.max(1, playerData.cargo - amount)
    );

    MapUnitaryAction.checkAllSystems(playerData);

    this.log(
      playerData,
      law,
      "Fleet limit decreased by  " + amount + ", minimum 1."
    );
  }

  static voidDisturbanceAct(gameStateList, map) {
    /* const activeLaw = Law.getActiveLaws(gameStateList[0].playerData);
    for (let i = 0; i < activeLaw.length; i++) {
      const law = activeLaw[i];
      if (law.name === Law.NAME_VOID_DISTURBANCE_ACT) {
        const allPlayerData = gameStateList.map(
          (gameState) => gameState.playerData
        );

        const systemList = Map.getSystemList(map);
        for (let i = 0; i < systemList.length; i++) {
          const system = systemList[i];
          const fleetList = System.getFleets(system);
          for (let j = 0; j < fleetList.length; j++) {
            const fleet = fleetList[j];
            const units = Fleet.getUnits(fleetList[j]);
            const hpLeft = Unit.getHP(units);
            const playerData = allPlayerData.find(
              (playerData) => fleet.faction === playerData.faction.name
            );

            if (hpLeft && playerData) {
              Unit.setHP(
                playerData,
                unit,
                CustomMath.generateRandomNumber(1, hpLeft)
              );
            }
          }
        }

        for (let i = 0; i < allPlayerData.length; i++) {
          this.log(
            allPlayerData[i],
            law,
            "Fleet HPs have been randomly set between 1 and their previous HP value."
          );
        }
      }
    }*/
  }

  static log(playerData, law, text, logItems) {
    PlayerData.generateLogActivity(
      playerData,
      law.name + " : " + text,
      logItems
    );

    Law.generateLog(playerData, law.name + " : " + text, logItems);
  }
}

module.exports = ResolveLaw;
