class ActivePassiveAbility {
  static createDesc(cost, text, active = false, itemsInfo = []) {
    const data = {
      cost: cost,
      text: text,
      active: active,
      itemsInfo: itemsInfo,
    };
    return data;
  }

  static getCost(desc) {
    return desc.cost;
  }

  static getText(desc) {
    return desc.text;
  }

  static isActive(desc) {
    return desc.active;
  }

  static getItemsInfo(desc) {
    return desc.itemsInfo;
  }
}

module.exports = ActivePassiveAbility;
