import React, { useState } from "react";
import { GameListView } from "../GameList/GameList";
import TestSimpleComponent from "../Test/TestSimpleComponent";
import StaticGameData from "../Data/GameData/StaticGameData";
import GameGlobalLayout from "../GameView/GameGlobalLayout";
import NavigationView from "../GameView/NavigationView";
import MenuNavigationView from "./MenuNavigationView";
import BaseButton from "../GameView/Utils/BaseButton";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import Popup from "../Data/Other/Popup";

export const DiscordLinkView = () => {
  return (
    <div>
      <h1>Join our Discord</h1>
      <LineSpace></LineSpace>
      <div>
        The discord is used to share news about the game, and organize events
        like tournaments.
      </div>
      <LineSpace></LineSpace>
      <div>We need your feedback to develop this game !</div>
      <LineSpace></LineSpace>

      <BaseButton
        onClick={() => {
          window.open("https://discord.gg/8fq5SDaGEx", "_blank");
        }}
      >
        Follow Discord Invite
      </BaseButton>
      <BaseButton
        onClick={() => {
          navigator.clipboard.writeText("https://discord.gg/8fq5SDaGEx");
        }}
      >
        Copy Discord Invite
      </BaseButton>
      <LineSpace></LineSpace>
      <div>
        If it does not work, it could be because of your web browser. Try
        another one or just paste the invite link in Discord app itself, after
        clicking on the "+" to join a new Discord server.
      </div>
      <LineSpace></LineSpace>
      <BaseButton
        onClick={() => {
          Popup.deActivateBlackPopup();
        }}
      >
        Back
      </BaseButton>
    </div>
  );
};

export default DiscordLinkView;
