import React, { useState, useEffect, useRef, Children } from "react";
import "../../CSS/Menu.css";

import ChatTagMethods from "./ChatTagMethods.jsx";

const ChatStringView = ({ string }) => {
  const processTextWithTags = (text) => {
    return ChatTagMethods.replaceTagsInString(text); // Returns HTML
  };

  return (
    <span dangerouslySetInnerHTML={{ __html: processTextWithTags(string) }} />
  );
};

export default ChatStringView;
