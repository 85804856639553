const PlayerData = require("../PlayerData/PlayerData");

class GameState {
  static createGameState(gameId, playerData, map, username) {
    return {
      gameId: gameId,
      playerData: playerData,
      //hidden: { map: map },
      username: username,
      transactionData: null, // Transaction data will be appened by the server to gameState each time the gameState is sent to the client
    };
  }

  static filterGameStateHiddenData(gameState) {
    gameState.hidden = {};
    PlayerData.filterPlayerHiddenData(gameState.playerData);
    return gameState;
  }

  static filterForLightUpdate(gameState) {
    const playerData = gameState.playerData;

    PlayerData.filterForLightUpdate(playerData);
  }
}

module.exports = GameState;
