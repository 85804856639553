import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import CostView from "../Utils/CostView";
import Cost from "../../Data/GameData/Utils/Cost";
import ActivePassiveAbility from "../../Data/GameData/Ability/ActivePassiveAbility";
import Item from "../../Data/GameData/Transactions/Item";
import AbilityView from "./AbilityView";

const AbilityCardDescView = ({ abilityCardDesc }) => {
  console.log("DEBUG abilityCardDesc : ", abilityCardDesc);

  const DisplayAbilities = () => {
    const abilities = abilityCardDesc.abilities;
    return abilities.map((abilityDesc, index) => {
      return (
        <div key={index}>
          <AbilityView abilityDesc={abilityDesc}></AbilityView>
        </div>
      );
    });
  };

  return (
    <span>
      <span>{abilityCardDesc.name}</span>
      <DisplayAbilities></DisplayAbilities>
    </span>
  );
};

export default AbilityCardDescView;
