const PlayerData = require("../Data/GameData/PlayerData/PlayerData.js");
const Map = require("../Data/GameData/MapData/Map.js");
const System = require("../Data/GameData/MapData/System.js");
const Faction = require("../Data/GameData/PlayerData/Faction.js");
const Game = require("../Data/GameData/Game/Game.js");
const Color = require("../Common/Config/Colors.js");
const UIMessage = require("../Data/GameData/Connection/UIMessage.js");
const Request = require("../Common/Requests/Request.js");
const Phase = require("../Data/GameData/Game/Phase.js");
const StaticGameData = require("../Data/GameData/StaticGameData.js");
const Popup = require("../Data/Other/Popup.js");
const Rules = require("../Data/GameData/Game/Rules.js");

class StartOfGameCA {
  static initialize_STEP_START_OF_GAME_PICK_FACTION(gameState) {
    Popup.setMapNavigationData({
      taskBarData: {
        text: "Click on your homesystem surrounded by gold square and choose your faction.",
      },
    });

    Popup.touch();
  }

  static resolve_STEP_START_OF_GAME_PICK_FACTION(factionName) {
    try {
      UIMessage.displayConfirmMessage(
        "Pick faction ?",
        "Are you sure you want to pick the faction " + factionName + " ?",
        () => {
          Request.updateGameState({
            factionName: factionName,
          });
        }
      );
      Popup.goBack();
    } catch (error) {
      throw error;
      //UIMessage.displayErrorMessage("Error picking faction", error);
    }
  }

  static initialize_STEP_START_OF_GAME_COLONIZE(gameState) {
    //Colonize action
    const playerData = StaticGameData.getPlayerData();

    const doneCallback = () => {
      console.log("Stop colonizing");
      const confirmCallback = () => {
        Popup.resetMode();
        this.resolve_STEP_START_OF_GAME_COLONIZE({
          action: "stop colonize",
        });
      };
      UIMessage.displayConfirmMessage(
        "Not all colonists used !",
        "You did not used all of your colonists yet. You have " +
          playerData.colonists +
          " colonists left. Are you sure you want to stop colonization ? If you confirm, all your colonists will be removed. Colonist can only be used at the beginning of the game.",
        confirmCallback
      );
    };

    const mode = {
      name: Popup.MODE_CHOOSE_PLANET_COLONIZE,
    };

    const confirmationCallback = (screenData) => {
      const colonizePlanet = () => {
        try {
          console.log("colonizePlanet");
          this.resolve_STEP_START_OF_GAME_COLONIZE({
            action: "colonize",
            spaceObjectName: screenData.spaceObject.name,
          });
          /*UIMessage.displayInfoMessage(
            screenData.spaceObject.name + " has been colonized !",
            result.message
          );*/
          //OngoingAction.colonizeAction(ongoingActionData);
          //console.log("Colonize playerData : ", playerData);
        } catch (e) {
          UIMessage.displayErrorMessage("Can't colonize !", e);
          throw e;
        }
      };
      console.log("confirmationCallback");
      UIMessage.displayConfirmMessage(
        "Colonize ?",
        "Are you sure you want to colonize " +
          screenData.spaceObject.name +
          " ?",
        colonizePlanet
      );
    };

    const taskBarText =
      "Choose a planet to colonize, at maximum distance of " +
      Rules.COLONIZE_DISTANCE +
      " of your homesystem. You have " +
      playerData.colonists +
      " colonists left.";
    const cancelButton = { text: "Done Colonizing", callback: doneCallback };
    const callbackButton = {
      nameView: "ManageObjectView",
      text: "Colonize !",
      callback: confirmationCallback,
    };

    Popup.setMode(mode, {
      taskBarText,
      callbackButton,
      cancelButton,
    });

    Popup.touch();
    //Popup.addAttributes({ test: "test" });
    //console.log("OngoingAction : Popup ", StaticGameData.popup);
  }

  static resolve_STEP_START_OF_GAME_COLONIZE(resolveData) {
    const playerData = StaticGameData.getPlayerData();
    playerData.phase = Phase.PHASE_START_OF_GAME;
    playerData.step = Phase.STEP_START_OF_GAME_COLONIZE;
    Popup.removeActionSelectionAttributes();
    if (resolveData.action === "stop colonize") {
      Request.updateGameState(resolveData);
    } else {
      Request.updateGameState(resolveData);
    }
  }

  static resolve_STEP_START_OF_GAME_COLONIZE_fromMap(resolveData) {
    const planetName = resolveData.spaceObjectName;
    UIMessage.displayConfirmMessage(
      "Colonize ?",
      "Are you sure you want to colonize " + planetName + " ?",
      () => {
        const playerData = StaticGameData.getPlayerData();
        playerData.phase = Phase.PHASE_START_OF_GAME;
        playerData.step = Phase.STEP_START_OF_GAME_COLONIZE;
        Request.updateGameState({
          action: "colonize",
          spaceObjectName: planetName,
        });
        Popup.deActivateBlackPopup();
        Popup.resetMapNavigationData();
      }
    );

    /* const playerData = StaticGameData.getPlayerData();
    playerData.phase = Phase.PHASE_START_OF_GAME;
    playerData.step = Phase.STEP_START_OF_GAME_COLONIZE;
    Popup.removeActionSelectionAttributes();
    if (resolveData.action === "stop colonize") {
      Request.updateGameState(resolveData);
    } else {
      Request.updateGameState(resolveData);
    }*/
  }
}

module.exports = StartOfGameCA;
