const LogBook = require("../Connection/LogBook");
const LogMessage = require("../Connection/LogMessage");
const Map = require("../MapData/Map");
const TechTree = require("../Technology/TechTree");
const CustomMath = require("../../../Common/Math/CustomMath");

class ScoreData {
  static VP_PER_PLANET = 1;
  static VP_PER_TECH = 0.2;

  static create() {
    const data = {
      logBoook: LogBook.createLogBook(),
      score: 0,
      selectedObjective: null,
      objectiveScoredThisRound: null,
    };

    LogBook.generateAddMessage(
      data.logBoook,
      "Initial victory points (VP) is " + data.score + "."
    );

    LogBook.createMessage(
      data.logBoook,
      [{ content: "ROUND " + 1 }],
      false,
      LogMessage.TYPE_TITLE_SECTION
    );

    return data;
  }

  static getLastLog(data) {
    LogBook.correctCursor(data.logBoook);
    return data.logBoook;
  }

  static getScore(data) {
    return data.score;
  }

  static generateAddMessage(scoreData, text, itemArray) {
    const log = ScoreData.getLastLog(scoreData);
    LogBook.generateAddMessage(log, text, itemArray);
  }

  /*static createNewLogBook(scoreData) {
    scoreData.logArray.push(LogBook.createLogBook());
  }*/

  static adminAfterRound(playerData, scoreData, newRoundNumber) {
    const log = ScoreData.getLastLog(scoreData);
    //this.createNewLogBook(scoreData);

    //Map
    const map = playerData.map;

    LogBook.generateAddMessage(
      log,
      "Total victory points (VP) is " + scoreData.score + "."
    );

    LogBook.createMessage(
      log,
      [{ content: "ROUND " + newRoundNumber }],
      false,
      LogMessage.TYPE_TITLE_SECTION
    );
  }

  static addScore(scoreData, amount, explanationText, explanationItems = []) {
    LogBook.correctCursor(scoreData.logBoook);
    scoreData.score = CustomMath.roundDec(scoreData.score + amount);
    const log = ScoreData.getLastLog(scoreData);
    const explanationTextWithVP =
      explanationText + " : " + CustomMath.roundDec(amount) + " VP.";
    LogBook.generateAddMessage(log, explanationTextWithVP, explanationItems);
  }

  static selectObjective(playerData, objectiveName) {
    playerData.scoreData.selectedObjective = objectiveName;
  }

  static resetSelectedObjective(playerData) {
    playerData.scoreData.selectedObjective = null;
  }

  static getSelectedObjective(playerData) {
    return playerData.scoreData.selectedObjective;
  }

  static isObjectiveSelected(playerData, objectiveName) {
    return playerData.scoreData.selectedObjective === objectiveName;
  }

  static setObjectiveScoredThisRound(playerData, objectiveName) {
    playerData.scoreData.objectiveScoredThisRound = objectiveName;
  }

  static resetObjectiveScoredThisRound(playerData) {
    playerData.scoreData.objectiveScoredThisRound = null;
  }

  static isObjectiveScoredThisRound(playerData, objectiveName) {
    return playerData.scoreData.objectiveScoredThisRound === objectiveName;
  }

  static hasScoredObjectiveThisRound(playerData) {
    return playerData.scoreData.objectiveScoredThisRound !== null;
  }
}

module.exports = ScoreData;
