import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import NavigationView from "../NavigationView";
import StaticGameData from "../../Data/GameData/StaticGameData";
import GameGlobalLayout from "../GameGlobalLayout";
import SolveFleetLimitView from "./SolveFleetLimitView";
import SolveCapacityView from "./SolveCapacityView";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Request = require("../../Common/Requests/Request.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const Rules = require("../../Data/GameData/Game/Rules.js");
const Phase = require("../../Data/GameData/Game/Phase.js");

const MandatoryActionGlobalView = ({}) => {
  console.log("RENDER MandatoryActionGlobalView ");
  const playerData = StaticGameData.getPlayerData();

  const navigationData = {
    buttonsData: [
      { text: "Map", callback: () => Popup.unStackToMap() },
      { text: "Back", callback: () => Popup.goBack() },
    ],
  };

  const mandatoryAction = PlayerData.getFirstMandatoryAction(playerData);
  console.log("playerData.mandatoryAction: ", playerData.mandatoryAction);

  return (
    <GameGlobalLayout forbidOverflow={false} title={mandatoryAction.name}>
      <div
        className="text-box"
        style={{
          //backgroundColor: "blue",
          textAlign: "center",
          height: "100%",
          fontSize: "1em",
        }}
      >
        <div>
          You have to resolve this mandatory action before doing anything else !
        </div>
        {mandatoryAction.name === Phase.STEP_MANDATORY_FLEET_LIMIT && (
          <SolveFleetLimitView
            navigationData={navigationData}
          ></SolveFleetLimitView>
        )}
        {mandatoryAction.name === Phase.STEP_MANDATORY_FLEET_CAPACITY && (
          <SolveCapacityView
            navigationData={navigationData}
          ></SolveCapacityView>
        )}
      </div>

      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default MandatoryActionGlobalView;
