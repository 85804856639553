import React, { useState } from "react";
import StaticGameData from "../../../Data/GameData/StaticGameData.js";
import InteractionFunctions from "../../UIInteraction/InteractionFunctions.jsx";
import AdminActionData from "../../../Data/GameData/ActionAdminData/AdminActionData.js";
import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData.js";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData.js";
import BaseButton from "../../Utils/BaseButton.jsx";
import FactionLogo from "../../Utils/FactionLogo.jsx";

const Popup = require("../../../Data/Other/Popup.js");

const ChangeOwnerObject = () => {
  const prepareChangeOwnerSystem = (playerData) => {
    AdminActionData.prepare(playerData);

    InteractionFunctions.selectASystem((system) => {
      Popup.activateBlackPopup(
        <DisplayChangeOwnerView playerData={playerData} object={system} />
      );
    }, "Select system to change owner");
  };

  const prepareChangeOwnerPlanet = (playerData) => {
    AdminActionData.prepare(playerData);

    InteractionFunctions.selectAPlanet((planet) => {
      Popup.activateBlackPopup(
        <DisplayChangeOwnerView playerData={playerData} object={planet} />
      );
    }, "Select planet to change owner");
  };

  const DisplayChangeOwnerView = ({ playerData, object }) => {
    const allPlayerDataList = PlayerData.getAllPlayersData(playerData);
    const [newOwnerFaction, setNewOwnerFaction] = useState(null);

    return (
      <div>
        <div>Object name: {object.name}</div>
        <div>New Owner: {newOwnerFaction ? newOwnerFaction : "no owner"}</div>

        {allPlayerDataList.map((player, index) => {
          if (!player.faction) return null;
          return (
            <span key={index}>
              <BaseButton
                onClick={() => setNewOwnerFaction(player.faction.name)}
              >
                <FactionLogo factionName={player.faction.name} />
              </BaseButton>
            </span>
          );
        })}

        <BaseButton onClick={() => setNewOwnerFaction(null)}>
          No Owner
        </BaseButton>

        <div>
          <BaseButton
            onClick={() => {
              Popup.resetMapNavigationData();
              Popup.unStackToMap();
              AdminActionData.prepare(playerData);
              const data = ActionCommonData.getData(playerData);
              data.changeOwnerObject = {
                objectName: object.name,
                isPlanet: object.isPlanet,
                newOwnerFaction: newOwnerFaction,
              };
              AdminActionData.resolveClient(
                playerData,
                AdminActionData.ACTION_CHANGE_OWNER_OBJECT
              );
            }}
          >
            Confirm
          </BaseButton>
          <BaseButton onClick={() => Popup.deActivateBlackPopup()}>
            Back
          </BaseButton>
        </div>
      </div>
    );
  };

  return { prepareChangeOwnerSystem, prepareChangeOwnerPlanet }; // Export both functions
};

export default ChangeOwnerObject;
