const Fleet = require("../MapData/Fleet");
const CustomMath = require("../../../Common/Math/CustomMath");
const ResolveData = require("../EndOfRound/ResolveData");
const Planet = require("../MapData/Planet");
const System = require("../MapData/System");
const Unit = require("../MapData/Unit");

class CombatData {
  static TYPE_GROUND = "ground";
  static TYPE_SPACE = "space";

  static create(resolveData, system, planet = null) {
    const data = {
      fleetCombatList: [],
      type: null,
    };

    let fleets = system.fleets;
    data.type = this.TYPE_SPACE;
    if (planet) {
      fleets = planet.fleets;
      data.type = this.TYPE_GROUND;
    }

    const allPlayerData = ResolveData.getAllPlayerData(resolveData);
    for (let i = 0; i < allPlayerData.length; i++) {
      const playerData = allPlayerData[i];
      const unitsParticipating = [];
      if (planet) {
        const fleet = Planet.getFleet(planet, playerData.faction.name);
        if (fleet) {
          unitsParticipating.push(
            ...Fleet.getUnits(fleet, Fleet.UNIT_CLASS_GROUND_FORCE)
          );
          unitsParticipating.push(
            ...Fleet.getUnits(fleet, Fleet.UNIT_CLASS_STRUCTURE)
          );
        }
      } else {
        const fleet = System.getFleet(system, playerData.faction.name);
        if (fleet) {
          unitsParticipating.push(
            ...Fleet.getUnits(fleet, Fleet.UNIT_CLASS_SHIP)
          );
        }
      }
      const fleetParticipating = Fleet.createFleet(
        playerData,
        playerData.faction.name
      );
      Fleet.addUnits(fleetParticipating, unitsParticipating);
      const fleetData = {
        fleet: fleetParticipating,
        hits: 0,
        hitToAssign: 0,
      };
      data.fleetCombatList.push(fleetData);
    }

    /*for (let i = 0; i < fleets.length; i++) {
      const playerData = ResolveData.getPlayerDataFromFaction(
        resolveData,
        fleets[i].faction
      );
      let unitsParticipating = [];
      if (planet) {
        unitsParticipating.push(
          ...Fleet.getUnits(fleets[i], Fleet.UNIT_CLASS_GROUND_FORCE)
        );
        unitsParticipating.push(
          ...Fleet.getUnits(fleets[i], Fleet.UNIT_CLASS_STRUCTURE)
        );
      } else {
        unitsParticipating.push(
          ...Fleet.getUnits(fleets[i], Fleet.UNIT_CLASS_SHIP)
        );
      }
      const fleetParticipating = Fleet.createFleet(
        playerData,
        fleets[i].faction
      );
      Fleet.addUnits(fleetParticipating, unitsParticipating);
      const fleetData = {
        fleet: fleetParticipating,
        hits: 0,
        hitToAssign: 0,
      };
      data.fleetCombatList.push(fleetData);
    }*/

    return data;
  }

  //Initialize
  static initializeFleetCombatList(combatData) {
    for (let i = 0; i < combatData.fleetCombatList.length; i++) {
      combatData.fleetCombatList[i].hits = 0;
      combatData.fleetCombatList[i].hitToAssign = 0;
    }
  }

  //Get
  static getFleetCombatList(combatData) {
    return combatData.fleetCombatList;
  }

  static getFleetCombat(combatData, faction) {
    for (let i = 0; i < combatData.fleetCombatList.length; i++) {
      if (combatData.fleetCombatList[i].fleet.faction === faction) {
        return combatData.fleetCombatList[i];
      }
    }
    return null;
  }

  static getFleetFromFactionOrCreate(playerData, combatData, faction) {
    for (let i = 0; i < combatData.fleetCombatList.length; i++) {
      if (combatData.fleetCombatList[i].fleet.faction === faction) {
        return combatData.fleetCombatList[i].fleet;
      }
    }
    return null;
  }

  static getFleets(combatData) {
    const fleets = [];
    for (let i = 0; i < combatData.fleetCombatList.length; i++) {
      fleets.push(combatData.fleetCombatList[i].fleet);
    }
    return fleets;
  }

  static addFleetOfFactionIfNotPresent(playerData, combatData, faction) {
    if (!this.getFleetCombat(combatData, faction)) {
      const fleet = Fleet.createFleet(playerData, faction);
      const fleetData = {
        fleet: fleet,
        hits: 0,
        hitToAssign: 0,
      };
      combatData.fleetCombatList.push(fleetData);
    }
  }

  static addFleetsOfFactionsIfNotPresent(resolveData, combatData, faction) {
    for (let i = 0; i < faction.length; i++) {
      const playerData = ResolveData.getPlayerDataFromFaction(
        resolveData,
        faction[i]
      );
      this.addFleetOfFactionIfNotPresent(playerData, combatData, faction[i]);
    }
  }

  static getFleetCombatExecutionOrder(combatData, objectControlled = null) {
    const order = [];
    for (let i = 0; i < combatData.fleetCombatList.length; i++) {
      order.push(combatData.fleetCombatList[i]);
    }

    CustomMath.shuffleArray(order);

    if (objectControlled && objectControlled.faction) {
      const index = order.find(
        (fleet) => fleet.faction === objectControlled.faction
      );
      if (index >= 0) {
        const firstToExecute = order[index];
        order.splice(index, 1);
        order.unshift(firstToExecute);
      }
    }

    return order;
  }

  static removeUnitWithNoHp(resolveData, fleetCombatList) {
    for (let i = 0; i < fleetCombatList.length; i++) {
      const playerData = ResolveData.getPlayerDataFromFaction(
        resolveData,
        fleetCombatList[i].fleet.faction
      );
      const fleet = fleetCombatList[i].fleet;
      const units = Fleet.getUnits(fleet);
      for (let j = 0; j < units.length; j++) {
        if (Unit.getHP(playerData, units[j]) <= 0) {
          Fleet.removeUnit(fleet, units[j]);
        }
      }
    }
  }
}

module.exports = CombatData;
