import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import NavigationView from "../NavigationView";
import StaticGameData from "../../Data/GameData/StaticGameData";
import GameGlobalLayout from "../GameGlobalLayout";
import SolveFleetLimitView from "./SolveFleetLimitView";
import SolveCapacityView from "./SolveCapacityView";
import ActionCommonData from "../../Data/GameData/ActionData/ActionCommonData";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Request = require("../../Common/Requests/Request.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const Rules = require("../../Data/GameData/Game/Rules.js");
const Phase = require("../../Data/GameData/Game/Phase.js");

const MandatoryActionNotAllowedOtherAction = ({}) => {
  const playerData = StaticGameData.getPlayerData();

  return (
    <div className="text-box" style={{ textAlign: "center" }}>
      <div>
        {" "}
        You have a mandatory action to execute before you are able to do
        something else !
      </div>
      <div>
        <BaseButton
          onClick={() => {
            ActionCommonData.cleanActionData(playerData);
            Popup.addLayer({ name: "MandatoryActionGlobalView" });
          }}
        >
          Do mandatory action
        </BaseButton>
      </div>
      <div>
        <BaseButton
          onClick={() => {
            Popup.goBack();
            ActionCommonData.cleanActionData(playerData);
          }}
        >
          Back
        </BaseButton>
      </div>
    </div>
  );
};

export default MandatoryActionNotAllowedOtherAction;
