const PlayerData = require("../PlayerData/PlayerData.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Phase = require("../Game/Phase.js");
const SolveCapacityData = require("./SolveCapacityData.js");
const ModifyFleetLimitAction = require("../ActionData/ModifyFleetLimitAction.js");

class SolveFleetLimitData {
  static check(playerData, system) {
    /*PlayerData.removeMandatoryActionByNameAndSystem(
      playerData,
      Phase.STEP_MANDATORY_FLEET_LIMIT
    );*/
    try {
      System.checkLogisticMass(playerData, system);
    } catch (e) {
      SolveFleetLimitData.prepareStep(playerData, system);
    }
  }

  static prepareStep(playerData, system) {
    const data = {
      systemName: system.name,
      fleetToRemove: Fleet.createFleet(playerData, playerData.faction.name),
      buyFleetLimit: false,
    };
    PlayerData.addMandatoryAction(
      playerData,
      Phase.STEP_MANDATORY_FLEET_LIMIT,
      data
    );
  }

  static setBuyFleetLimit(playerData, value) {
    const data = PlayerData.getFirstMandatoryAction(playerData).data;
    data.buyFleetLimit = value;
  }

  static checkIfStillActive(playerData) {
    const data = PlayerData.getFirstMandatoryAction(playerData).data;
    const system = Map.getSystemFromName(playerData.map, data.systemName);
    try {
      System.checkLogisticMass(playerData, system);
    } catch (error) {
      return true;
    }
    return false;
  }

  static resolve(playerData) {
    const data = PlayerData.getFirstMandatoryAction(playerData).data;

    const system = Map.getSystemFromName(playerData.map, data.systemName);

    const fleet = System.getFleet(system, playerData.faction.name);

    if (data.buyFleetLimit) {
      const fleetLogisticMass = Fleet.getLogisticMass(fleet);
      const fleetLimit = PlayerData.getFleetLimit(playerData);
      const excedent = Math.ceil(Math.max(0, fleetLogisticMass - fleetLimit));

      const cost = ModifyFleetLimitAction.getCostFromAmount(
        playerData,
        excedent
      );

      PlayerData.spendCost(playerData, cost);
      CustomMath.roundDec((playerData.cargo += excedent));
    } else {
      Fleet.removeFleetFromFleet(data.fleetToRemove, fleet);
    }

    console.log("Checking logistic mass again");
    try {
      System.checkLogisticMass(playerData, system);
    } catch (error) {
      console.log("Preparing new step for fleet limit");
      this.prepareStep(playerData, system);
    }

    try {
      System.checkCapacity(playerData, system);
    } catch (error) {
      SolveCapacityData.prepareStep(playerData, system);
    }
  }
}

module.exports = SolveFleetLimitData;
