const Color = require("../../../Common/Config/Colors");

class Transaction {
  static STATE_PROPOSED = "Proposed";
  static STATE_ACCEPTED = "Accepted";
  static STATE_REJECTED = "Rejected";

  static create(proposingFaction) {
    return {
      proposingFaction: proposingFaction,
      receivingFaction: null,
      itemsOut: [],
      itemsIn: [],
      textOut: "",
      textIn: "",
      creditBalance: 0, //positive if receiving faction is receiving
      state: Transaction.STATE_PROPOSED,
      roundProposed: null,
    };
  }

  static getRoundProposed(transaction) {
    return transaction.roundProposed;
  }

  static setRoundProposed(transaction, round) {
    transaction.roundProposed = round;
  }

  static addItemIn(transaction, item) {
    const index = transaction.itemsIn.indexOf(item);
    if (index > -1) {
      return;
    }
    transaction.itemsIn.push(item);
  }

  static removeItemIn(transaction, item) {
    for (let i = 0; i < transaction.itemsIn.length; i++) {
      if (transaction.itemsIn[i].id === item.id) {
        transaction.itemsIn.splice(i, 1);
        return;
      }
    }
  }

  static addItemOut(transaction, item) {
    const index = transaction.itemsOut.indexOf(item);
    if (index > -1) {
      return;
    }
    transaction.itemsOut.push(item);
  }

  static removeItemOut(transaction, item) {
    for (let i = 0; i < transaction.itemsOut.length; i++) {
      if (transaction.itemsOut[i].id === item.id) {
        transaction.itemsOut.splice(i, 1);
        return;
      }
    }
  }

  static setTextIn(transaction, text) {
    transaction.textIn = text;
  }

  static getTextIn(transaction) {
    if (!transaction.textIn) {
      transaction.textIn = "";
    }
    return transaction.textIn;
  }

  static setTextOut(transaction, text) {
    transaction.textOut = text;
  }

  static getTextOut(transaction) {
    if (!transaction.textOut) {
      transaction.textOut = "";
    }
    return transaction.textOut;
  }

  static modifyCreditBalance(transaction, amount) {
    transaction.creditBalance += amount;
  }

  static setReceivingFaction(transaction, receivingFaction) {
    if (transaction.receivingFaction !== receivingFaction) {
      transaction.itemsIn = [];
    }
    transaction.receivingFaction = receivingFaction;
  }

  static getReceivingFaction(transaction) {
    return transaction.receivingFaction;
  }

  static getProposingFaction(transaction) {
    return transaction.proposingFaction;
  }

  static getItemsIn(transaction) {
    return transaction.itemsIn;
  }

  static getItemsOut(transaction) {
    return transaction.itemsOut;
  }

  static getCreditBalance(transaction) {
    return transaction.creditBalance;
  }

  static isAutomaticallyAccepted(transaction) {
    return transaction.itemsIn.length === 0 && transaction.creditBalance >= 0;
  }

  static isEmpty(transaction) {
    return (
      Transaction.getItemsIn(transaction).length === 0 &&
      Transaction.getItemsOut(transaction).length === 0 &&
      Transaction.getTextIn(transaction) === "" &&
      Transaction.getTextOut(transaction) === "" &&
      Transaction.getCreditBalance(transaction) === 0
    );
  }
}

module.exports = Transaction;
