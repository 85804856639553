//DEPRECIATED

const StaticGameData = require("../StaticGameData.js");
const MapPosition = require("./MapPosition.js");

global.HEX_DIAG = 700;

class HexaCoordinates {
  constructor(q, r) {
    this.q = q;
    this.r = r;
  }
}

module.exports = HexaCoordinates;

class Map {
  constructor(placedTiles, mapPositions, gameState) {
    this.placedTiles = placedTiles;
    this.mapPositions = mapPositions;
  }
  static getPlacedTileAtCoordinates(q, r, gameState) {
    const map = gameState.map;
    for (let i = 0; i < map.placedTiles.length; i++) {
      const placedTile = map.placedTiles[i];
      if (placedTile.coordinates.q === q && placedTile.coordinates.r === r) {
        return placedTile;
      }
    }
    return null;
  }

  static getTileAtCoordinates(q, r, gameState) {
    return Map.getPlacedTileAtCoordinates(q, r, gameState).tile;
  }

  static getPlacedTileAtPosition(mapPosition, gameState) {
    const map = gameState.map;
    return Map.getPlacedTileAtCoordinates(
      map.mapPositions[mapPosition - 1].q,
      map.mapPositions[mapPosition - 1].r,
      gameState
    );
  }

  static replaceTileAtPosition(newTile, mapPosition, gameState) {
    const map = gameState.map;
    for (let i = 0; i < map.placedTiles.length; i++) {
      if (
        map.placedTiles[i].coordinates.q ===
          map.mapPositions[mapPosition - 1].q &&
        map.placedTiles[i].coordinates.r === map.mapPositions[mapPosition - 1].r
      ) {
        map.placedTiles[i].tile = newTile;
        return;
      }
    }
  }

  static getDistanceBetweenCoordinates(q1, r1, q2, r2, gameState) {
    const map = gameState.map;
    const tile1 = Map.getPlacedTileAtCoordinates(q1, r1, gameState);
    const tile2 = Map.getPlacedTileAtCoordinates(q2, r2, gameState);

    const visited = new Set();

    function getDistanceRecursively(
      currentDistance,
      currentTiles,
      tileTofind,
      map
    ) {
      for (let i = 0; i < currentTiles.length; i++) {
        const currentTile = currentTiles[i];
        if (currentTile === tileTofind) {
          return currentDistance;
        }
      }

      const nextCurrentTiles = [];

      for (let i = 0; i < currentTiles.length; i++) {
        const currentTile = currentTiles[i];
        if (!visited.has(currentTile)) {
          visited.add(currentTile);
          const neighbors = Map.getNeighbors(
            currentTile.coordinates.q,
            currentTile.coordinates.r,
            gameState
          );
          for (const neighbor of neighbors) {
            if (neighbor) {
              nextCurrentTiles.push(neighbor);
            }
          }
        }
      }
      return getDistanceRecursively(
        currentDistance + 1,
        nextCurrentTiles,
        tileTofind,
        map
      );
    }
    return getDistanceRecursively(0, [tile1], tile2, map);
  }

  static getNeighbors(q, r, gameState) {
    const neighbors = [];
    const directions = [
      { q: 1, r: 0 },
      { q: 1, r: -1 },
      { q: 0, r: -1 },
      { q: -1, r: 0 },
      { q: -1, r: 1 },
      { q: 0, r: 1 },
    ];

    for (const dir of directions) {
      const neighbor = { q: q + dir.q, r: r + dir.r };
      neighbors.push(
        Map.getPlacedTileAtCoordinates(neighbor.q, neighbor.r, gameState)
      );
    }

    return neighbors;
  }
}

module.exports = Map;
