const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");

const Unit = require("../MapData/Unit.js");

const Planet = require("../MapData/Planet.js");

const ActionCommonData = require("../ActionData/ActionCommonData.js");
const Map = require("../MapData/Map.js");

class AdminCreateUnitData {
  static unitsShop = (playerData) => {
    let catalogList = [];
    catalogList = catalogList.concat(
      Unit.getUnitShipsCatalog(playerData, playerData.faction.name)
    );
    catalogList = catalogList.concat(
      Unit.getUnitGroundForcesCatalog(playerData, playerData.faction.name)
    );
    catalogList = catalogList.concat(
      Unit.getUnitStructuresCatalog(playerData, playerData.faction.name)
    );

    const unitShop = { list: [] };
    for (let i = 0; i < catalogList.length; i++) {
      const unit = catalogList[i];
      unitShop.list.push({ unit: unit, amount: 0 });
    }

    return unitShop;
  };

  static modifyOrderAmount(playerData, unitShop, unit, amountP) {
    let amount = amountP;

    for (let i = 0; i < unitShop.list.length; i++) {
      if (unitShop.list[i].unit === unit) {
        unitShop.list[i].amount += amount;
        if (unitShop.list[i].amount < 0) {
          unitShop.list[i].amount = 0;
        }
        return;
      }
    }
  }

  static ACTION_NONE = "ACTION_NONE";
  static ACTION_CREATE_UNIT = "ACTION_CREATE_UNIT";
  static ACTION_REMOVE_UNIT = "ACTION_REMOVE_UNIT";

  static create(playerData) {
    const data = {
      location: null,
      unitShop: this.unitsShop(playerData),
    };

    return data;
  }

  static getData = (playerData) => {
    return ActionCommonData.getData(playerData).createUnitData;
  };

  static getUnitsProduced(playerData, unitShop) {
    const unitsProduced = [];
    for (let i = 0; i < unitShop.list.length; i++) {
      const unit = unitShop.list[i].unit;
      const amount = unitShop.list[i].amount;
      for (let j = 0; j < amount; j++) {
        unitsProduced.push(
          Unit.createUnit(playerData, playerData.faction.name, unit.type)
        );
      }
    }
    return unitsProduced;
  }

  static resolveCreateUnits(playerData) {
    const data = AdminCreateUnitData.getData(playerData);
    const unitsProduced = AdminCreateUnitData.getUnitsProduced(
      playerData,
      data.unitShop
    );
    const locationP = data.location;
    let location = null;
    if (locationP.isPlanet) {
      location = Map.getSpaceObjectFromName(playerData.map, locationP.name);
    } else {
      location = Map.getSystemFromName(playerData.map, locationP.name);
    }

    const shipsProduced = unitsProduced.filter(
      (unit) => unit.class === Fleet.UNIT_CLASS_SHIP
    );
    const groundForcesProduced = unitsProduced.filter(
      (unit) => unit.class === Fleet.UNIT_CLASS_GROUND_FORCE
    );
    const structuresProduced = unitsProduced.filter(
      (unit) => unit.class === Fleet.UNIT_CLASS_STRUCTURE
    );

    if (!location.isPlanet && structuresProduced.length > 0) {
      throw new Error("Structures can only be placed on planets.");
    }
    if (location.isPlanet && shipsProduced.length > 0) {
      throw new Error("Ships can only be placed in space areas.");
    }

    let fleet = null;
    if (location.isPlanet) {
      fleet = Planet.getFleetOrCreate(
        playerData,
        location,
        playerData.faction.name
      );
    } else {
      fleet = System.getFleetOrCreate(
        playerData,
        location,
        playerData.faction.name
      );
    }

    Fleet.addUnits(fleet, unitsProduced);
  }
}

module.exports = AdminCreateUnitData;
