import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import { getObjectSummaryJSXArray } from "./SpaceObjectInfoArray"; // Adjust the path accordingly
import ResourceView from "./ResourceView";
import PlanetViewActivation from "./ActivationView/Objects/PlanetViewActivation";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const SpaceObject = require("../../Data/GameData/MapData/SpaceObject.js");
const Rules = require("../../Data/GameData//Game/Rules.js");

const SpaceObjectSummaryView = ({
  spaceObject,
  activation,
  onclick = () => {},
}) => {
  const ObjectInformation = () => {
    const jsxArray = getObjectSummaryJSXArray(spaceObject);

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          //gap: "0",
          width: "100%",
        }}
      >
        {jsxArray.map((info, index) => (
          <span
            key={index}
            style={
              {
                //textAlign: "center",
                //border: "1px solid white",
                //padding: "0",
                //marginRight: "10px",
                //boxSizing: "border-box",
              }
            }
          >
            {info}
            {index < jsxArray.length - 1 && <span> / </span>}
          </span>
        ))}
      </div>
    );
  };

  const DisplayPlanet = ({ planet }) => {
    return (
      <PlanetViewActivation
        spaceObject={planet}
        showLogs={true}
        activation={activation}
      ></PlanetViewActivation>
    );

    const arrayResources = [];
    let key = 1;
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_MINERAL}
      >
        {planet.mineral} + {planet.mineralEfficiency} / P
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_SCIENCE}
      >
        + {planet.scienceEfficiency} / P
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_INFLUENCE}
      >
        + {planet.influence}
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_POPULATION}
      >
        {planet.population} + {planet.populationGrow}%
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_ENERGY}
      >
        + 0
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_SIZE}
      >
        {planet.size}
      </ResourceView>
    );

    //console.log("DisplayPlanet ", planet);
    //console.log("DisplayPlanet planet.mineral", planet.mineral);

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          textAlign: "left",
          //gap: "0",
          width: "100%",
          fontSize: "1.5rem",
        }}
      >
        {arrayResources}
      </div>
    );
  };

  const handleClick = () => {
    Popup.addLayer({
      name: "ManageObjectView",
      spaceObject: spaceObject,
    });
  };

  //const DisplayObjectInformation

  return (
    <div>
      {spaceObject.isPlanet && (
        <DisplayPlanet planet={spaceObject}></DisplayPlanet>
      )}
    </div>
  );
};

export default SpaceObjectSummaryView;
