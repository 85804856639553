import React from "react";
import RuleImage from "../Utils/RuleImage";
import RuleP from "../Utils/RuleP";
import RuleUl from "../Utils/RuleUl";
import RuleLi from "../Utils/RuleLi";
import RuleLink from "../Utils/RuleLink";
import SpaceObject from "../../Data/GameData/MapData/SpaceObject";
import PlanetBonus from "../../Data/GameData/Bonus/PlanetBonus";
import PlanetView from "../../GameView/Object/PlanetView";
import SpaceObjectImageView from "../../GameView/Object/SpaceObjectImageView";
import BaseButton from "../../GameView/Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import ManagePlanetView from "../../GameView/ManageSystem/ManagePlanetView";
import Logo from "../../GameView/Utils/Logo";
import Planet from "../../Data/GameData/MapData/Planet";
import LineSpace from "../../GameView/Utils/Layout/LineSpace";
import TechTypeView from "../../GameView/Technology/TechTypeView";
import Color from "../../Common/Config/Colors";

const ActionPagesData = {
  researchExplanation: {
    title: "Research",
    aliases: ["research", "technologies", "tech tree"],
    content: (
      <div>
        <RuleImage name="object_tech_tree"></RuleImage>
        <RuleP>
          In <strong>Portal Empires</strong>, the tech tree is procedurally
          generated for each game, creating a unique experience every time. This
          ensures that no two games are the same, encouraging adaptability and
          exploration of new strategies.
        </RuleP>
        <RuleP>
          Technologies are divided into four colors: red{" "}
          <TechTypeView color={Color.COLOR_NAME_TECH_RED}></TechTypeView>, blue{" "}
          <TechTypeView color={Color.COLOR_NAME_TECH_BLUE}></TechTypeView>,
          green{" "}
          <TechTypeView color={Color.COLOR_NAME_TECH_GREEN}></TechTypeView>, and
          yellow{" "}
          <TechTypeView color={Color.COLOR_NAME_TECH_YELLOW}></TechTypeView>.
          Each color represents a specific domain of advancement, while some
          technologies are uncolored. These uncolored technologies are unique
          and do not belong to any color group.
        </RuleP>
        <RuleImage name="object_tech"></RuleImage>
        <RuleP>
          Researching a technology requires a certain amount of{" "}
          <RuleLink name="scienceExplanation">science</RuleLink>. Additionally,
          some technologies require a specific number of prerequisites from the
          same color to unlock.
        </RuleP>
        <RuleP>
          When a faction researches a technology of a specific color, they gain
          one prerequisite for that color. However, uncolored technologies are
          special—they do not provide prerequisites when researched.
        </RuleP>
      </div>
    ),
  },
  buildUnitsExplanation: {
    title: "Build Units",
    aliases: ["build units", "unit production"],
    content: (
      <div>
        <div>
          <RuleImage name="action_build_unit"></RuleImage>
        </div>
        <RuleP>
          Each planet can build units once per round, and the number of units
          that can be built is limited. This limit varies by planet.
        </RuleP>
        <RuleP>
          Some units require specific infrastructure on the planet to be
          produced. For example:
        </RuleP>
        <RuleUl>
          <RuleLi>
            Small ships can only be produced if there is a{" "}
            <strong>factory</strong> on the planet.
          </RuleLi>
          <RuleLi>
            Larger ships require an <strong>assembly orbital</strong> to be
            built.
          </RuleLi>
        </RuleUl>
        <RuleP>
          Once a planet has built units during a given round, it cannot produce
          additional units until the next round.
        </RuleP>
      </div>
    ),
  },
  invasionExplanation: {
    title: "Invasion",
    aliases: [
      "invasion",
      "planet invasion",
      "invade",
      "invades",
      "invaded",
      "invasions",
    ],
    content: (
      <div>
        <div></div>
        <RuleP>
          A faction that controls a system after space combat can invade a
          planet within that system. However, the invasion order must have been
          issued by the faction's Commander before the combat phase.
        </RuleP>
        <RuleP>
          If a faction plans to invade a planet but does not control the system
          after space combat, the invasion order has no effect. Control of the
          system is a prerequisite for executing an invasion.
        </RuleP>
        <RuleP>
          If the faction gains control of the system during space combat, the
          invasion proceeds. All ground forces of the faction present in the
          system are deployed onto the planet as part of the invasion.
        </RuleP>
        <RuleImage name="action_invasion"></RuleImage>
      </div>
    ),
  },
  actionMoveExplanation: {
    title: "Move",
    aliases: ["move", "movement", "moving"],
    content: (
      <div>
        <RuleP>
          Ships can move from system to system, as long as the destination is
          within their movement range. However, to move to a system, a{" "}
          <strong>portal</strong> must first be created in that system, which
          costs energy.
        </RuleP>
        <RuleP>
          Moving units within the same system does not cost anything and does
          not require a portal. This allows for efficient repositioning of
          fleets within a system without using additional resources.
        </RuleP>
        <RuleP>
          Units cannot move out of a system containing a portal, preventing them
          from moving twice in the same round. At the beginning of each round,
          all portals are removed, allowing units to move again and resetting
          the strategic movement phase.
        </RuleP>
        <RuleP>
          Some units, such as ground forces and fighter ships, cannot move on
          their own and must be transported. These units have a{" "}
          <strong>required capacity</strong> value, representing the space
          needed to transport them.
        </RuleP>
        <RuleP>
          Other units, like carriers, dreadnoughts, and hellmoons, have a{" "}
          <strong>capacity</strong> value, allowing them to transport other
          units. A fleet is valid for movement if the total required capacity of
          all units needing transport is less than or equal to the total
          available capacity of the transporting units.
        </RuleP>
        <RuleP>
          Planning movement effectively is critical for positioning your fleets,
          reinforcing systems, and launching invasions. Managing energy and
          capacity constraints ensures that your fleets operate efficiently.
        </RuleP>
        <RuleImage name="action_move_no_portal"></RuleImage>
        <RuleImage name="action_move_portal"></RuleImage>
        <RuleImage name="action_move_same_system"></RuleImage>
      </div>
    ),
  },
};

export default ActionPagesData;
