class MinorFactionList {
  static createFactionData({ name }) {
    return {
      name: name,
      level: 0,
      active: false,
    };
  }

  static factions = [
    {
      description: "Always ready to trade. With any species.",
      abilities: [
        {
          name: "We can loan to you",
          description: "Gain 5 credits.",
        },
        {
          name: "Some more ?",
          description: "Gain 5 credits. Gain 4 victory points.",
        },
        {
          name: "Provide Transport Ships",
          description:
            "Choose 4 carriers in this system. These carrier are removed from the game. Gain 5 credits. Gain 8 victory points.",
        },
      ],
    },
  ];

  static canAbilityBeUsed(playerData, factionData, abilityName) {
    const abilityLevel = MinorFactionList.getAbilityLevel(
      factionData.name,
      abilityName
    );
    const factionLevel = factionData.level;
    if (abilityLevel === factionLevel) {
      return true;
    } else {
      return false;
    }
  }

  //Get

  static getAbilityLevel(factionName, abilityName) {
    const faction = MinorFactionList.getFactionFromName(factionName);
    for (let i = 0; i < faction.abilities.length; i++) {
      const ability = faction.abilities[i];
      if (ability.name === abilityName) {
        return i;
      }
    }
    throw new Error("Ability not found");
  }

  static getFactionFromName(name) {
    for (let i = 0; i < MinorFactionList.factions.length; i++) {
      const faction = MinorFactionList.factions[i];
      if (faction.name === name) {
        return faction;
      }
    }
    throw new Error("Faction not found");
  }

  static isActive(factionData) {
    return factionData.active;
  }

  static setActive(factionData, active) {
    factionData.active = active;
  }
}

module.exports = MinorFactionList;
