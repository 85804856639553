import React, { useState, useEffect } from "react";

import StaticGameData from "../../../Data/GameData/StaticGameData.js";
import BaseButton from "../../Utils/BaseButton.jsx";

import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData.js";

import NavigationView from "../../NavigationView.jsx";
import GameGlobalLayout from "../../GameGlobalLayout.jsx";
import AdminCreateUnitView from "./AdminCreateUnitView.jsx";
import InteractionFunctions from "../../UIInteraction/InteractionFunctions.jsx";
import AdminCreateUnitData from "../../../Data/GameData/ActionAdminData/AdminCreateUnitData.js";
import AdminRemoveUnitInput from "./AdminRemoveUnitInput.jsx";
import ChangeOwnerObject from "./ChangeOwnerObject.jsx";
import ModifyResources from "./ModifyResources.jsx";
import UIMessage from "../../../Data/GameData/Connection/UIMessage.js";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup.js");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const Planet = require("../../../Data/GameData/MapData/Planet.js");

const ActionAdminView = ({ activePlayerData }) => {
  const playerData = StaticGameData.getPlayerData();
  const data = ActionCommonData.getData(playerData);

  const DisplayMainAdminMenu = () => {
    return (
      <div>
        <BaseButton
          onClick={() => {
            InteractionFunctions.selectAPlanet((planet) => {
              const createUnitsData = AdminCreateUnitData.getData(playerData);
              createUnitsData.location = planet;
              Popup.addDynamicLayer(
                <AdminCreateUnitView
                  activePlayerData={activePlayerData}
                ></AdminCreateUnitView>
              );
            });
          }}
        >
          Create Units On Planet
        </BaseButton>
        <BaseButton
          onClick={() => {
            InteractionFunctions.selectASystem((system) => {
              const createUnitsData = AdminCreateUnitData.getData(playerData);
              createUnitsData.location = system;
              Popup.addDynamicLayer(
                <AdminCreateUnitView
                  activePlayerData={activePlayerData}
                ></AdminCreateUnitView>
              );
            });
          }}
        >
          Create Units In Space Area
        </BaseButton>
        <BaseButton
          onClick={() => {
            AdminRemoveUnitInput.prepare(playerData);
          }}
        >
          Remove Units
        </BaseButton>
        <BaseButton
          onClick={() => {
            const changeOwnerObject = ChangeOwnerObject();
            changeOwnerObject.prepareChangeOwnerSystem(playerData);
          }}
        >
          Change Owner System
        </BaseButton>
        <BaseButton
          onClick={() => {
            const changeOwnerObject = ChangeOwnerObject();
            changeOwnerObject.prepareChangeOwnerPlanet(playerData);
          }}
        >
          Change Owner Planet
        </BaseButton>
        <BaseButton
          onClick={() => {
            const modifyResourceObject = ModifyResources();
            modifyResourceObject.prepare(playerData);
          }}
        >
          Modify Resources
        </BaseButton>
        <BaseButton
          onClick={() => {
            UIMessage.displayConfirmMessage(
              "Force end of round",
              "Are you sure you want to force the end of the round?",
              () => {
                Popup.unStackToMap();
                Request.forceEndOfRound();
              }
            );
          }}
        >
          Force end of round
        </BaseButton>
      </div>
    );
  };

  const navigationData = {
    buttonsData: [{ text: "Back", callback: () => Popup.goBack() }],
  };

  return (
    <GameGlobalLayout title={null} backgroundType="shadow">
      <div
        className="text-box"
        style={{
          textAlign: "center",
          height: "100%",
        }}
      >
        <DisplayMainAdminMenu></DisplayMainAdminMenu>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default ActionAdminView;
