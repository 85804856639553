const PlayerData = require("../Data/GameData/PlayerData/PlayerData.js");
const Map = require("../Data/GameData/MapData/Map.js");
const System = require("../Data/GameData/MapData/System.js");
const Faction = require("../Data/GameData/PlayerData/Faction.js");
const Game = require("../Data/GameData/Game/Game.js");
const Color = require("../Common/Config/Colors.js");
const UIMessage = require("../Data/GameData/Connection/UIMessage.js");
const Request = require("../Common/Requests/Request.js");
const Phase = require("../Data/GameData/Game/Phase.js");
const StaticGameData = require("../Data/GameData/StaticGameData.js");
const Popup = require("../Data/Other/Popup.js");
const Rules = require("../Data/GameData/Game/Rules.js");
const SystemAction = require("../Data/GameData/ActionCommon/SystemAction.js");

class SelectActionCA {
  static initialize_STEP_SELECT_ACTION(gameState) {
    const playerData = StaticGameData.getPlayerData();
    const callback = (screenData) => {
      this.resolve_STEP_SELECT_ACTION({
        system: screenData.system,
        action: "activate system",
      });
    };

    //Popup.reset();

    /*Popup.setMode(Phase.PHASE_ACTIVATION, {
      taskBarText: "Choose a system to activate.",
      callbackButton: {
        nameView: "ManageSystemView",
        text: "Activate",
        callback: callback,
        cancelButton: null,
      },
    });*/
    Popup.touch();
  }

  static resolve_STEP_SELECT_ACTION(resolveData) {
    const playerData = StaticGameData.getPlayerData();
    const system = resolveData.system;
    const activate = () => {
      Request.updateGameState({
        action: resolveData.action,
        x: system.x,
        y: system.y,
      });
      Popup.resetMode();
    };

    UIMessage.displayConfirmMessage(
      "Activate " + system.name + " ?",
      "You will have to finish the activation phase of this system before activating another one. After the activation phase of this system, units, minerals and population will not be able to leave this system.",
      activate
    );
  }
}

module.exports = SelectActionCA;
