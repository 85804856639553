const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const ActivationData = require("./ActivationData.js");
const PlanetData = require("../MapData/Planet.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");

class LogData {
  static LOG_COMBAT = "Combat Log";
  static LOG_ACTIVITY = "Activity Log";

  static setActiveLog(playerData, log) {
    if (!playerData.logData) playerData.logData = {};
    playerData.logData.activeLog = log;
  }

  static getActiveLog(playerData) {
    if (!playerData.logData) return this.LOG_COMBAT;
    return playerData.logData.activeLog;
  }
}

module.exports = LogData;
