import React from "react";
import Game from "../Data/GameData/Game/Game";
import StaticGameData from "../Data/GameData/StaticGameData";
import FactionLogo from "../GameView/Utils/FactionLogo";
import DynamicView from "../GameView/DynamicView";
import Popup from "../Data/Other/Popup";
import GameView from "../GameView/GameView";
import GameLobby from "./GameLobby";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import GameGlobalFunctions from "../Data/Other/GameGlobalFunctions";
import GameFinishedScreen from "./GameFinishedScreen";

function GameListItem({ game, setRouteMainHandler, children }) {
  //console.log(game);

  if (!StaticGameData.username) {
    return null;
  }

  console.log(game);
  const joinGameHandler = (selectedGame) => {
    /*console.log("Join game: ", selectedGame);
    setRouteMainHandler({
      activeScreen: "GameView",
      game: selectedGame,
    });*/
    /*Popup.addDynamicLayer(<GameView game={game}></GameView>, {
      gameView: true,
    });*/
    if (Game.isFinished(game)) {
      Popup.addDynamicLayer(
        <GameFinishedScreen game={game}></GameFinishedScreen>
      );
    } else if (Game.isStarted(game)) {
      Popup.enterGame(game);
    } else {
      Popup.addDynamicLayer(<GameLobby game={game}></GameLobby>);
    }
  };

  let colorText = "white";

  const selfPlayer = Game.getPlayerFromUsername(game, StaticGameData.username);

  if (selfPlayer && selfPlayer.isWaitingForNewRound) {
    colorText = "grey";
  }

  if (game.status === "Finished") {
    colorText = "grey";
  }

  const DisplayPlayerFaction = ({ game }) => {
    const playerFaction = Game.getPlayerFaction(game, StaticGameData.username);

    if (!Game.isPlayerInGameFromUsername(game, StaticGameData.username)) {
      return null;
    }

    if (!playerFaction) {
      return;
    }

    return (
      <span>
        <span>
          {playerFaction ? (
            <FactionLogo factionName={playerFaction}></FactionLogo>
          ) : (
            "?"
          )}
        </span>
      </span>
    );
  };

  const DisplayOtherPlayersFactions = ({ game }) => {
    const otherPlayerFactions = Game.getOtherPlayerFactions(
      game,
      StaticGameData.username
    );

    return (
      <span>
        <span>
          {otherPlayerFactions.map((faction, index) => (
            <span key={index}>
              {faction && <FactionLogo factionName={faction}></FactionLogo>}
            </span>
          ))}
        </span>
      </span>
    );
  };

  const HorSPace = () => {
    return <span style={{ marginRight: "3em" }}></span>;
  };

  return (
    <span onClick={() => joinGameHandler(game)} style={{ color: colorText }}>
      <div>
        <div style={{ display: "block" }}>
          <DisplayPlayerFaction game={game}></DisplayPlayerFaction>{" "}
          {game.gameListType === "Custom" && <span>Custom</span>}
          {game.gameListType === "Ranked" && <span>Ranked</span>}
          <span> </span>
          {game.isTutorial && <span> Tutorial</span>} {game.gameId}
          <span>
            {" "}
            <DisplayOtherPlayersFactions
              game={game}
            ></DisplayOtherPlayersFactions>
          </span>
        </div>

        {false && (
          <span>Status : {Game.isGameFull(game) ? "Started" : "Lobby"}</span>
        )}
      </div>

      {false && (
        <div className="div-hor-center">
          {!Game.isGameFull(game) && (
            <div>
              {" "}
              <span>
                Number of Players: {game.players.length}/{game.maxNumberPlayers}
              </span>
              <HorSPace></HorSPace>
            </div>
          )}
        </div>
      )}
      {GameGlobalFunctions.exists(Game.getCurrentRound(game)) ? (
        <div>{Game.getPrintRoundStatus(game)}</div>
      ) : (
        <div>
          {game.status === "Not Started" && (
            <div>
              <div>
                Players : {game.players.length}/{game.maxNumberPlayers}
              </div>{" "}
              <div>Victory :{game.vpLimit} VP</div>{" "}
              <div> {game.statusToDisplay}</div>
            </div>
          )}
          {game.status === "Finished" && (
            <div>
              <div>Finished</div>
            </div>
          )}
        </div>
      )}

      <div>{children}</div>
    </span>
  );
}

export default GameListItem;
