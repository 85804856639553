const CustomMath = require("../../../Common/Math/CustomMath");
const Time = require("../Time/Time");

class GameUpdate {
  static create(gameId, roomIdList) {
    return {
      gameId: gameId,
      currentRound: 1,
      gameStateIncrement: 0,
      roomUpdateList: roomIdList.map((roomId) => {
        return {
          roomId: roomId, // Use roomId instead of roomName
          lastChatItemLoaded: 0, //State of the room. Used to now if the client did load the new state
          lastChatItemNotified: 0, //Used for message displaying on the screen in transparency
          lastChatItemRed: 0, //Used to show an exclamation mark on the chat button
        };
      }),
    };
  }

  static getRoomUpdate(gameUpdate, roomId) {
    return gameUpdate.roomUpdateList.find(
      (roomUpdate) => roomUpdate.roomId === roomId
    );
  }

  static setRoomLastItemLoaded(gameUpdate, roomId, lastChatItemLoaded) {
    const roomUpdate = this.getRoomUpdate(gameUpdate, roomId);
    roomUpdate.lastChatItemLoaded = lastChatItemLoaded;
  }

  static doesNeedGameUpdate(gameUpdateLocal, gameUpdateServer) {
    return (
      gameUpdateLocal.gameStateIncrement < gameUpdateServer.gameStateIncrement
    );
  }

  static doesNeedRoomUpdate(gameUpdateLocal, roomId, gameUpdateServer) {
    const roomUpdateLocal = this.getRoomUpdate(gameUpdateLocal, roomId);
    const roomUpdateServer = this.getRoomUpdate(gameUpdateServer, roomId);
    if (!roomUpdateLocal || !roomUpdateServer) return false;
    return (
      roomUpdateLocal.lastChatItemLoaded < roomUpdateServer.lastChatItemLoaded
    );
  }

  static isRoomRed(gameUpdateLocal, roomId) {
    const roomUpdate = this.getRoomUpdate(gameUpdateLocal, roomId);
    /*console.log("roomUpdate roomId", roomId);
    console.log("roomUpdate gameUpdateLocal", gameUpdateLocal);
    console.log("roomUpdate.lastChatItemLoaded", roomUpdate.lastChatItemLoaded);
    console.log("roomUpdate.lastChatItemLoaded", roomUpdate.lastChatItemLoaded);*/
    return roomUpdate.lastChatItemRed >= roomUpdate.lastChatItemLoaded;
  }

  static markRoomAsRed(gameUpdate, roomId) {
    const roomUpdate = this.getRoomUpdate(gameUpdate, roomId);
    roomUpdate.lastChatItemRed = roomUpdate.lastChatItemLoaded;
  }

  static incrementGameUpdate(gameUpdate) {
    gameUpdate.gameStateIncrement++;
  }

  static incrementCurrentRound(gameUpdate) {
    gameUpdate.currentRound++;
  }
  static setRoomIncrement(gameUpdate, roomId, roomStateIncrement) {
    const roomUpdate = this.getRoomUpdate(gameUpdate, roomId);
    roomUpdate.lastChatItemLoaded = roomStateIncrement;
    roomUpdate.lastChatItemNotified = roomStateIncrement;
    roomUpdate.lastChatItemRed = roomStateIncrement;
  }

  static alignAllRoomsLastChatItemLoaded(gameUpdateSource, gameUpdateTarget) {
    gameUpdateSource.roomUpdateList.forEach((roomUpdateSource) => {
      const roomUpdateTarget = gameUpdateTarget.roomUpdateList.find(
        (roomUpdate) => roomUpdate.roomId === roomUpdateSource.roomId
      );
      roomUpdateTarget.lastChatItemLoaded = roomUpdateSource.lastChatItemLoaded;
    });
  }

  static alignAllRoomsLastChatItemNotified(gameUpdateSource, gameUpdateTarget) {
    gameUpdateSource.roomUpdateList.forEach((roomUpdateSource) => {
      const roomUpdateTarget = gameUpdateTarget.roomUpdateList.find(
        (roomUpdate) => roomUpdate.roomId === roomUpdateSource.roomId
      );
      roomUpdateTarget.lastChatItemNotified =
        roomUpdateSource.lastChatItemNotified;
    });
  }

  static alignRoomLastChatItemRed(gameUpdateSource, gameUpdateTarget, roomId) {
    const roomUpdateSource = gameUpdateSource.roomUpdateList.find(
      (roomUpdate) => roomUpdate.roomId === roomId
    );
    const roomUpdateTarget = gameUpdateTarget.roomUpdateList.find(
      (roomUpdate) => roomUpdate.roomId === roomId
    );
    roomUpdateTarget.lastChatItemRed = roomUpdateSource.lastChatItemRed;
  }

  static incrementAllRoomUpdate(gameUpdate) {
    gameUpdate.roomUpdateList.forEach((roomUpdate) => {
      roomUpdate.roomStateIncrement++;
    });
  }

  static setGameIncrement(gameUpdate, gameUpdateSource) {
    gameUpdate.gameStateIncrement = gameUpdateSource.gameStateIncrement;
    gameUpdate.currentRound = gameUpdateSource.currentRound;
  }

  static setAllRoomIncrement(gameUpdateToUpdate, gameUpdateSource) {
    gameUpdateToUpdate.roomUpdateList = gameUpdateSource.roomUpdateList;
  }
}

module.exports = GameUpdate;
