import Logo from "../Utils/Logo.jsx";

const PlanetBonus = require("../../Data/GameData/Bonus/PlanetBonus.js");

const PlanetBonusView = ({ bonusName, size = null }) => {
  //console.log("PlanetBonusView bonusName : ", bonusName);
  //return;
  const getLogoName = (bonusName) => {
    if (bonusName === PlanetBonus.NAME_BUILD) {
      return "build";
    }
    if (bonusName === PlanetBonus.NAME_CREDIT) {
      return "credit";
    }
    if (bonusName === PlanetBonus.NAME_ENERGY) {
      return "energy";
    }
    if (bonusName === PlanetBonus.NAME_MINERAL) {
      return "mineral";
    }
    if (bonusName === PlanetBonus.NAME_POPULATION) {
      return "population";
    }
    if (bonusName === PlanetBonus.NAME_RESEARCH) {
      return "science";
    }
    if (bonusName === PlanetBonus.NAME_VP) {
      return "vp";
    }
    throw new Error("No logo name for bonus " + bonusName);
  };

  const logoName = getLogoName(bonusName);

  return (
    <span style={{}}>
      <Logo logoName={logoName} heightRem={size}></Logo>
    </span>
  );
};

export default PlanetBonusView;
