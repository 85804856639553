const Fleet = require("./Fleet.js");
const Unit = require("./Unit.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const LogBook = require("../Connection/LogBook.js");
const LogAttachment = require("../Connection/LogAttachment.js");
const Planet = require("./Planet.js");

class SpaceObject {
  //Creation of objects

  static TYPE_VOID = "Void";

  static getPlanetTypes() {
    return ["Terran", "Ice", "Oceanic", "Desert", "Gaz", "Volcanic"];
  }

  static getPlanetTerran() {
    return {
      type: "Terran",
      size: 5,
      //energyEfficiency: CustomMath.generateRandomNumber(50, 70) / 10,
      //current Resources produced in the game
      populationEfficiency: 2,
      mineralEfficiency: 2,
      scienceEfficiency: 2,
      energyEfficiency: 1.5,
      construction: 4,
    };
  }
  static getPlanetIce() {
    return {
      type: "Ice",
      size: 8,
      //current Resources produced in the game
      populationEfficiency: 1,
      mineralEfficiency: 1.5,
      scienceEfficiency: 1.5,
      energyEfficiency: 2.5,
      construction: 5,
    };
  }
  static getPlanetOceanic() {
    return {
      type: "Oceanic",
      size: 5,

      //current Resources produced in the game
      populationEfficiency: 1,
      mineralEfficiency: 2,
      scienceEfficiency: 2,
      energyEfficiency: 2,
      construction: 3,
    };
  }
  static getPlanetVolcanic() {
    return {
      type: "Volcanic",
      size: 4,

      //current Resources produced in the game
      populationEfficiency: 0,
      mineralEfficiency: 3.5,
      scienceEfficiency: 3,
      energyEfficiency: 3,
      construction: 3,
    };
  }
  static getPlanetDesert() {
    return {
      type: "Desert",
      size: 6,

      //current Resources produced in the game
      populationEfficiency: 1,
      mineralEfficiency: 3,
      scienceEfficiency: 1.5,
      energyEfficiency: 2,
      construction: 4,
    };
  }
  static getPlanetGaz() {
    return {
      type: "Gaz",
      size: 3,

      //current Resources produced in the game
      populationEfficiency: 0,
      mineralEfficiency: 0,
      scienceEfficiency: 3,
      energyEfficiency: 3,
      construction: 3,
    };
  }

  static getPlanetTypeDeck() {
    return [
      "Terran",
      "Ice",
      "Oceanic",
      "Desert",
      "Gaz",

      "Terran",
      "Ice",
      "Oceanic",
      "Desert",
      "Gaz",

      "Volcanic",
    ];
  }

  static createSpaceObject() {
    let objectTypeRandom = CustomMath.generateRandomReal(0, 100);
    if ((objectTypeRandom -= 25) < 0) {
      return this.createPlanet();
    }
    if ((objectTypeRandom -= 50) < 0) {
      return this.createVoid();
    }
    return this.createVoid();
  }

  static createSpecificPlanet(type) {
    switch (type) {
      case "Terran":
        return this.getPlanetTerran();
      case "Ice":
        return this.getPlanetIce();
      case "Oceanic":
        return this.getPlanetOceanic();
      case "Volcanic":
        return this.getPlanetVolcanic();
      case "Desert":
        return this.getPlanetDesert();
      case "Gaz":
        return this.getPlanetGaz();
      default:
        return this.createPlanet();
    }
  }

  static createVoid() {
    // Implementation for creating a void space object
    return {
      type: "void",
      name: null,
      // Add other properties as needed
    };
  }

  static createPlanet(typeP = null, planetTypeDeck = null) {
    let type;
    if (!planetTypeDeck || planetTypeDeck.length === 0) {
      //Method without planetTypeDeck
      if (!typeP) {
        let planetTypeRandom = CustomMath.generateRandomReal(0, 100);

        if ((planetTypeRandom -= 18) < 0) {
          type = "Terran";
        } else {
          if ((planetTypeRandom -= 18) < 0) {
            type = "Ice";
          } else {
            if ((planetTypeRandom -= 18) < 0) {
              type = "Oceanic";
            } else {
              if ((planetTypeRandom -= 10) < 0) {
                type = "Volcanic";
              } else {
                if ((planetTypeRandom -= 18) < 0) {
                  type = "Desert";
                } else {
                  if ((planetTypeRandom -= 100) < 0) {
                    //console.log("planetTypeRandom", planetTypeRandom);
                    type = "Gaz";
                  }
                }
              }
            }
          }
        }
      } else {
        type = typeP;
      }
    } else {
      //Method with planetTypeDeck
      type = planetTypeDeck.pop();
    }

    // Implementation for creating a planet space object
    const planetTypeAttributes = this.createSpecificPlanet(type);
    const planet = {
      ...planetTypeAttributes,
      ...Planet.createPlanetData(),
    };

    LogAttachment.create(planet, true, true);

    return planet;
  }

  static getFleetOrCreate(playerData, spaceObject, factionName, color) {
    let fleet = this.getFleet(spaceObject, factionName);
    if (!fleet) {
      fleet = Fleet.createFleet(playerData, factionName);
      SpaceObject.addFleet(spaceObject, fleet);
    }
    return fleet;
  }

  static getFleet(spaceObject, factionName) {
    if (!spaceObject.fleets) return null;
    return spaceObject.fleets.find((fleet) => fleet.faction === factionName);
  }

  static getFleets(spaceObject) {
    if (!spaceObject.fleets) return null;
    return spaceObject.fleets;
  }

  static addFleet(spaceObject, fleet) {
    //Find if exists fleet with factionName
    let fleetIndex = spaceObject.fleets.findIndex(
      (fleet) => fleet.faction === fleet.faction
    );
    if (fleetIndex === -1) {
      spaceObject.fleets.push(fleet);
    } else {
      Fleet.addFleetToFleet(fleet, spaceObject.fleets[fleetIndex]);
    }
  }

  static removeFleet(spaceObject, factionName) {
    let fleetIndex = spaceObject.fleets.findIndex(
      (fleet) => fleet.faction === factionName
    );
    if (fleetIndex !== -1) {
      spaceObject.fleets.splice(fleetIndex, 1);
    }
  }

  static colonizeObject(spaceObject, playerData) {
    if (!spaceObject.isPlanet) {
      throw new Error(
        "SpaceObject.colonizeObject : spaceObject is not a planet"
      );
    }
    if (playerData.colonists <= 0) {
      throw new Error("SpaceObject.colonizeObject : playerData.colonists <= 0");
    }
  }

  /*static createPlanetTypeAttributes() {
    let planetTypeRandom = CustomMath.generateRandomReal(0, 100);

    if ((planetTypeRandom -= 17) < 0) {
      return this.getPlanetTerran();
    }
    if ((planetTypeRandom -= 16) < 0) {
      return this.getPlanetIce();
    }
    if ((planetTypeRandom -= 16) < 0) {
      return this.getPlanetOceanic();
    }
    if ((planetTypeRandom -= 17) < 0) {
      return this.getPlanetVolcanic();
    }
    if ((planetTypeRandom -= 17) < 0) {
      return this.getPlanetDesert();
    }
    if ((planetTypeRandom -= 17) < 0) {
      return this.getPlanetGaz();
    }
  }*/

  static createNotDiscoveredObject() {
    return {
      type: "notDiscovered",
    };
  }

  //Logs

  static getLogItem(spaceObject) {
    return JSON.parse(JSON.stringify(spaceObject));
  }

  //Clear
  static clearGlobalMapSpaceObject(spaceObject) {
    spaceObject.fleets = [];
  }
}

module.exports = SpaceObject;
