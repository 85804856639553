import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import SpaceObjectSummaryView from "../ManageSystem/SpaceObjectSummaryView";
import NavigationView from "../NavigationView";
import GameGlobalLayout from "../GameGlobalLayout";
import TitleView from "../TitleView";
import StaticGameData from "../../Data/GameData/StaticGameData";
import PlanetViewActivation from "./ActivationView/Objects/PlanetViewActivation";
import LogBookView from "../Utils/LogMessage/LogBookView";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Request = require("../../Common/Requests/Request.js");
const Map = require("../../Data/GameData/MapData/Map.js");
const LogData = require("../../Data/GameData/Activation/LogData.js");
const LogBook = require("../../Data/GameData/Connection/LogBook.js");

const ManageLogView = ({ combatLog = null, activityLog = null, type }) => {
  const playerData = StaticGameData.getPlayerData();

  /*console.log("combatLog", combatLog);
  console.log("activityLog", activityLog);*/

  const navigationData = {
    buttonsData: [
      { text: "Map", callback: () => Popup.unStackToMap() },
      { text: "Back", callback: () => Popup.goBack() },
    ],
  };

  /*if (combatLog) {
    navigationData.buttonsData.push({
      text: "Combat Log",
      callback: () => {
        LogData.setActiveLog(playerData, LogData.LOG_COMBAT);
        Popup.touch();
      },
    });
  }*/

  const DisplayLog = ({ logBook }) => {
    return (
      <div className="text-box">
        <LogBookView logBook={logBook}></LogBookView>
      </div>
    );
  };

  const DisplayLogRouting = () => {
    if (type === "activity") {
      return <DisplayActivity></DisplayActivity>;
    } else {
      return null;
    }
  };

  const DisplayActivity = () => {
    return (
      <div>
        <DisplayLog logBook={activityLog}></DisplayLog>
      </div>
    );
  };

  return (
    <GameGlobalLayout>
      <DisplayLogRouting></DisplayLogRouting>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default ManageLogView;
