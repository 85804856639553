const StaticGameData = require("../StaticGameData.js");
const Request = require("../../../Common/Requests/Request.js");
const UIMessage = require("../Connection/UIMessage.js");
const UnitAbility = require("./UnitAbility.js");

class AbilityCommon {
  //Should be replaced by ./Ability
  static TYPE_ALL = "all";
  static TYPE_MINOR_FACTION = "minorFaction";

  static PHASE_RESOLVE_ABILITY = "resolveAbility"; //For server phase

  static requestToServer = (step, abilityData) => {
    const playerData = StaticGameData.getPlayerData();
    if (playerData.mandatoryAction.length > 0) {
      UIMessage.displayInfoMessage(
        "Mandatory action first !",
        'You must resolve the mandatory action first. Click on "To do" at the bottom of the screen.'
      );
      return;
    }

    playerData.phase = this.PHASE_RESOLVE_ABILITY;
    playerData.step = step;
    Request.updateGameState(abilityData);
  };

  //Get ability from id and data as parameters
  static getAbility(id, data, type = this.TYPE_ALL) {
    if (type === UnitAbility.TYPE || type === this.TYPE_ALL) {
      return UnitAbility.object[id](data);
    }
  }

  //Data as parameters of an ability
  static createAbilityData(id, data) {
    const ability = this.getAbility(id, data);
    const abilityData = {
      id: id,
      data: data,
    };
    if (ability.exhaustable) abilityData.exhausted = false;

    return abilityData;
  }

  //Set
  static exhaust(abilityData) {
    abilityData.exhausted = true;
  }
  static ready(abilityData) {
    abilityData.exhausted = false;
  }

  //Get
  static isExhausted(abilityData) {
    return abilityData.exhausted;
  }
}

module.exports = AbilityCommon;
