import React, { useState, useEffect } from "react";
import Color from "../../Common/Config/Colors";
import Law from "../../Data/GameData/Law/Law";
import BaseButton from "../Utils/BaseButton";
import LineSpace from "../Utils/Layout/LineSpace";
import LawButtonView from "./LawButtonView";
import Popup from "../../Data/Other/Popup";
import LogBookView from "../Utils/LogMessage/LogBookView";
import FullScreenLogView from "../LogViews/FullScreenLogView";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");

const LawsMapView = ({}) => {
  const playerData = StaticGameData.getPlayerData();

  const activeLaw = Law.getActiveLaws(playerData);
  const votingLaws = Law.getVotingLaws(playerData);

  //console.log("debug votingLaws", votingLaws);

  return (
    <div>
      <div style={{ color: "cyan", fontSize: "1.2em" }}>Laws</div>
      <BaseButton
        onClick={() => {
          Popup.addDynamicLayer(
            <FullScreenLogView
              logBook={Law.getLawLog(playerData)}
              scrollAtEnd={true}
              rememberScrollKey={"LawLog"}
            />
          );
        }}
      >
        Law Logs
      </BaseButton>
      <LineSpace></LineSpace>

      {activeLaw.length > 0 ? (
        <div>
          <div> Active Law :</div>{" "}
          <div>
            {" "}
            <LawButtonView law={activeLaw[0]} voted={true}></LawButtonView>
          </div>
          <LineSpace></LineSpace>
        </div>
      ) : (
        <div>
          No active law<LineSpace></LineSpace>
        </div>
      )}
      {votingLaws.length > 0 ? (
        <div>
          <div> Next round potential laws : </div>
          <div>
            {votingLaws.map((law, index) => (
              <span key={index}>
                <LawButtonView law={law} voting={true}></LawButtonView>
              </span>
            ))}
          </div>
          <LineSpace></LineSpace>
        </div>
      ) : (
        <div>
          No law to vote for<LineSpace></LineSpace>
        </div>
      )}
    </div>
  );
};

export default LawsMapView;
