const MinorFactionList = require("./MinorFactionList");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const { Description } = require("@headlessui/react");
const SecondaryObject = require("../MapData/SecondaryObject.js");
const Rules = require("../Game/Rules.js");
const Faction = require("../PlayerData/Faction.js");
const Fleet = require("../MapData/Fleet.js");
const Unit = require("../MapData/Unit.js");
const MinorFactionAbility = require("./MinorFactionAbility.js");
const HeroNames = require("./HeroNames.js");

class MinorFaction {
  //HERO FROM FACTION NAME
  static HERO_ANDAAR = "Hero Andaar";
  static HERO_AETHERION = "Hero Aetherion";
  static HERO_BOREALIS = "Hero Borealis";
  static HERO_CALVARIA = "Hero Calvaria";
  static HERO_DELPHAS = "Hero Delphas";
  static HERO_DRAKOR = "Hero Drakor";
  static HERO_ERINYS = "Hero Erinys";
  static HERO_EROSIUM = "Hero Erosium";
  static HERO_FELTOR = "Hero Feltor";
  static HERO_GHELOR = "Hero Ghelor";
  static HERO_HELKARAN = "Hero Helkaran";
  static HERO_ICANTA = "Hero Icanta";
  static HERO_JELORIA = "Hero Jeloria";
  static HERO_KORATH = "Hero Korath";
  static HERO_KOBAMDA = "Hero Kobamda";
  static HERO_LARAN = "Hero Laran";
  static HERO_MALTRION = "Hero Maltrion";
  static HERO_MINARI = "Hero Minari";
  static HERO_OPHARIAN = "Hero Opharian";
  static HERO_PROTHIAN = "Hero Prothian";
  static HERO_QUORIDIOM = "Hero Quoridiom";
  static HERO_RYNZORATH = "Hero Rynzorath";
  static HERO_SELTAAR = "Hero Seltaar";
  static HERO_TERRAN = "Hero Terran";
  static HERO_TAARKIR = "Hero Taarkir";
  static HERO_UTARION = "Hero Utarion";
  static HERO_VOIDBORN = "Hero Voidborn";
  static HERO_WOLTHAAR = "Hero Wolthaar";
  static HERO_XELPHAR = "Hero Xelphar";
  static HERO_XENOS = "Hero Xenos";
  static HERO_ZYLORIAN = "Hero Zylorian";

  //HERO NEW NAMES
  static HERO_Y_XXOR = "Y'xxor";
  static HERO_SLATHEN = "Slathen";
  static HERO_AV_ARI = "Av'ari";
  static HERO_KZATHOR = "Kzathor";
  static HERO_ZEELA = "Zeela";
  static HERO_BRALLAX = "Brallax";
  static HERO_AZH_RA = "Azh'ra";
  static HERO_TZREL = "Tzrel";
  static HERO_VOLXATH = "Volxath";
  static HERO_QUINAR = "Quinar";
  static HERO_GHYLDRA = "Ghyldra";
  static HERO_RONNAK = "Ronnak";
  static HERO_ESDRAN = "Esdran";
  static HERO_PHAARA = "Phaara";
  static HERO_TROXATH = "Troxath";
  static HERO_XORA = "Xora";
  static HERO_DRINOLL = "Drinoll";
  static HERO_VLYSSA = "Vlyssa";
  static HERO_ZREN = "Zren";
  static HERO_KOLTA = "Kolta";

  static create(name, id, populationCostMin, populationCostMax) {
    const data = {
      name: name,
      populationCostMin: populationCostMin,
      populationCostMax: populationCostMax,
      populationCost: CustomMath.generateRandomNumber(
        populationCostMin,
        Math.ceil((populationCostMax + populationCostMin) / 4)
      ),
      missionDone: false,
      id: id,
    };

    return data;
  }

  static adminAfterRound(minorFaction) {
    minorFaction.populationCost = Math.min(
      minorFaction.populationCostMax,
      Math.max(minorFaction.populationCostMin, minorFaction.populationCost - 2)
    );
  }

  static resetPopulationCost(minorFaction) {
    minorFaction.populationCost = minorFaction.populationCostMax;
  }

  static generateRandomMinorFactionArray(size) {
    //Prepare faction desc array
    const factionDescListSource = this.getFactionDescriptionList();
    const amountArrayNeeded = Math.ceil(size / factionDescListSource.length);

    let factionDescList = [];
    for (let i = 0; i < amountArrayNeeded; i++) {
      factionDescList = factionDescList.concat(
        JSON.parse(JSON.stringify(factionDescListSource))
      );
    }

    CustomMath.shuffleArray(factionDescList);

    //prepare return array
    const factionArray = [];
    for (let i = 0; i < size; i++) {
      const factionDesc = factionDescList.pop();
      const faction = MinorFaction.create(
        factionDesc.name,
        i, // for ID
        factionDesc.populationCostMin,
        factionDesc.populationCostMax
      );
      factionArray.push(faction);
    }

    return factionArray;
  }

  static getFactionDescriptionList = () => {
    const minorFactionAbilities = MinorFactionAbility.getAbilities();

    return [
      /* {
        name: Faction.MINOR_FACTION_NAME_KOHOR,
        description:
          "The Kohor travels the galaxy, trading with all factions. They don't always sell what you need, but they always have what you want. The council is investigating on them, they might sell forbidden goods.",
        populationCostMin: 4,
        populationCostMax: 10,
        abilityName: "Get Corrupted",
        abilityDescription: "Gain 6 credits.",
        missionName: "Provide Escort Ships",
        missionDescription:
          "Select 2 destroyers and 2 carriers in this system to give to the Kohor.",
        missionVP: 5,
        areUnitsInSystem: false,
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.MINOR_FACTION_NAME_BOROUl,
        description:
          "The Borouls are a faction of pirates, they are feared by all factions. They are known to attack commercial ships and steal their cargo.",
        populationCostMin: 6,
        populationCostMax: 12,
        abilityName: "Sponsor an attack",
        abilityDescription:
          "Choose a system at maximum 2 distances from this system. Place 3 Boroul crusers in this system.",
        missionName: "Secure the system",
        missionDescription:
          "Have control of the space area in this system, with no Boroul units in it.",
        missionVP: 7,
        areUnitsInSystem: true, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [
          Unit.UNIT_TYPE_CRUSER,
          Unit.UNIT_TYPE_CRUSER,
          Unit.UNIT_TYPE_CRUSER,
          Unit.UNIT_TYPE_CRUSER,
        ],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },*/
      {
        name: HeroNames.HERO_AETHERION,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_AETHERION],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_ANDAR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_ANDAR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_BOREALIS,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_BOREALIS],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_CALVARIA,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_CALVARIA],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_DELPHAS,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_DELPHAS],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      /*{
        name: HeroNames.HERO_DRAKOR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_DRAKOR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },*/
      {
        name: HeroNames.HERO_ERINYS,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_ERINYS],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      /*{
        name: HeroNames.HERO_EROSIUM,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_EROSIUM],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },*/
      {
        name: HeroNames.HERO_FELTOR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_FELTOR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_GHELOR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_GHELOR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_HELKARAN,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_HELKARAN],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_ICANTA,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_ICANTA],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_JELORIA,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_JELORIA],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_KORATH,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_KORATH],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_KOBAMDA,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_KOBAMDA],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_LARAN,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_LARAN],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_MALTRION,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_MALTRION],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_MINARI,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_MINARI],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_OPHARIAN,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_OPHARIAN],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_PROTHIAN,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_PROTHIAN],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_QUORIDIOM,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_QUORIDIOM],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_RYNZORATH,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_RYNZORATH],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_SELTAAR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_SELTAAR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_TERRAN,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_TERRAN],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_TAARKIR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_TAARKIR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_UTARION,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_UTARION],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_VOIDBORN,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_VOIDBORN],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_WOLTHAAR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_WOLTHAAR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_XELPHAR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_XELPHAR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_XENOS,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_XENOS],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: HeroNames.HERO_ZYLORIAN,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[HeroNames.HERO_ZYLORIAN],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
    ];
  };

  static getFactionDescDataFromName(name) {
    const factionDescList = this.getFactionDescriptionList();
    for (let i = 0; i < factionDescList.length; i++) {
      if (factionDescList[i].name === name) {
        return factionDescList[i];
      }
    }
  }

  static getName(minorFaction) {
    return minorFaction.name;
  }

  static getNameWithoutPrefixM(minorFaction) {
    return;
    return minorFaction.name.substring(4);
  }
}

module.exports = MinorFaction;
