import React, { useState, useEffect, useRef, Children } from "react";
import "../../CSS/Menu.css";

import Color from "../../Common/Config/Colors.js";

import ChatFactionLogo from "./ChatFactionLogo.jsx";
import ChatItem from "../../Data/ChatData/ChatItem.js";
import FactionLogo from "../Utils/FactionLogo.jsx";
import Logo from "../Utils/Logo.jsx";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData.js";
import StaticGameData from "../../Data/GameData/StaticGameData.js";
import ChatStringView from "./ChatStringView.jsx";

const ChatItemView = ({ chatItem }) => {
  const style = {
    padding: "5px 10px",
    margin: "5px 0",
    borderRadius: "5px",
    borderWidth: "2px",
    borderStyle: "solid",
    //backgroundColor: Color.COLOR_GREY_5,
    backgroundColor: "black",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    textAlign: "left",
    color: "white",
    width: "fit-content",
  };

  const tagList = ChatItem.getAllTags(chatItem);

  //const factionToList = ChatItem.getFactionToList(chatItem);
  const playerData = StaticGameData.getPlayerData();
  const playerDataSender = PlayerData.getPlayerDataFromInGameId(
    playerData,
    chatItem.playerInGameId
  );
  const colorSender = playerDataSender.color
    ? Color.getColorFromPlayerColor(playerDataSender.color)
    : "rgb(102,153,255)";

  return (
    <div
      style={{
        ...style,
        //borderColor: Color.BASE_BUTTON_BORDER,
        border: "1px solid #aaa",
        borderRadius: "5px",
      }}
    >
      <div style={{ fontSize: "0.8em" }}>
        {" "}
        <ChatFactionLogo
          playerInGameId={chatItem.playerInGameId}
          showFactionName={true}
        ></ChatFactionLogo>{" "}
        {!playerData.gameParam.playerNameHidden && (
          <span>
            <span>
              {" "}
              <span style={{ color: colorSender }}>
                {PlayerData.getPlayerDisplayName(
                  playerData,
                  chatItem.playerInGameId
                )}
              </span>
            </span>
          </span>
        )}
        {/* factionToList.length > 0 && (
          <span>
            <span>
              {" "}
              <Logo logoName="arrow_right"> </Logo>{" "}
            </span>
            {factionToList.map((faction, index) => {
              console.log("debug faction : " + faction);
              return (
                <span key={index}>
                  <FactionLogo factionName={faction}></FactionLogo>
                </span>
              );
            })}
          </span>
        )*/}
      </div>

      <div>
        <ChatStringView string={chatItem.content}></ChatStringView>
      </div>
    </div>
  );
};

export default ChatItemView;
