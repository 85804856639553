const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const ActivationData = require("./ActivationData.js");
const PlanetData = require("../MapData/Planet.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const SpaceObject = require("../MapData/SpaceObject.js");
const LogBook = require("../Connection/LogBook.js");

class DropData {
  //Lift Functions

  static prepareStep(playerData, planet) {
    const activeSystem = ActivationData.getActiveSystem(playerData);
    const data = {
      planetName: planet.name,
      fleetDropped: Fleet.createFleet(playerData, playerData.faction.name),
    };

    playerData.activationData.dropStep = data;
  }

  static getData(playerData) {
    return playerData.activationData.dropStep;
  }

  static dropUnit(playerData, planet, unit) {
    const data = this.getData(playerData);
    if (unit === null) {
      throw new Error("Unit not found");
    }
    Fleet.addUnit(data.fleetDropped, unit);
  }

  static removeDropUnit(playerData, planet, unit) {
    const data = this.getData(playerData);
    if (unit === null) {
      throw new Error("Unit is null");
    }
    Fleet.removeUnit(data.fleetDropped, unit);
  }

  static resolveDrop(playerData) {
    const activeSystem = ActivationData.getActiveSystem(playerData);
    const spaceFleet = System.getFleetOrCreate(
      activeSystem,
      playerData.faction.name,
      playerData.color
    );
    const data = this.getData(playerData);

    const planet = System.getSpaceObjectFromName(activeSystem, data.planetName);

    if (!planet) {
      throw new Error("Planet not found in active system");
    }

    const planetFleet = Planet.getFleetOrCreate(
      planet,
      playerData.faction.name,
      playerData.color
    );

    const unitsToDrop = Fleet.getUnits(data.fleetDropped);
    for (let j = 0; j < unitsToDrop.length; j++) {
      const unit = unitsToDrop[j];
      if (unit.class !== Fleet.UNIT_CLASS_GROUND_FORCE) {
        throw new Error("Only ground forces can be dropped");
      }

      Fleet.removeUnit(spaceFleet, unit);
      Fleet.addUnit(planetFleet, unit);
    }

    if (!Fleet.isEmpty(data.fleetDropped)) {
      System.logActivityToSpaceObject(activeSystem, planet, [
        {
          type: LogMessage.ITEM_TYPE_FACTION_LOGO,
          content: playerData.faction.name,
        },
        { content: " Dropped ground forces on " },
        {
          type: LogMessage.ITEM_TYPE_SPACE_OBJECT,
          content: planet,
        },
        {
          content: " ",
        },
        {
          type: LogMessage.ITEM_TYPE_FLEET,
          content: Fleet.getLogItem(data.fleetDropped),
        },
      ]);
    }

    Fleet.checkValidity(playerData, spaceFleet);
  }

  static collapseAllBut(playerData, planetName) {
    const planetsData = this.getPlanetsData(playerData);
    for (let i = 0; i < planetsData.length; i++) {
      if (planetsData[i].name !== planetName) {
        planetsData[i].collapsed = true;
      }
    }
  }
}

module.exports = DropData;
