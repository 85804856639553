const ImagesUrl = require("../../Other/ImagesUrl.js");

class HeroNames {
  //HERO FROM FACTION NAME
  static HERO_ANDAR = "Hero Andaar";
  static HERO_AETHERION = "Hero Aetherion";
  static HERO_BOREALIS = "Hero Borealis";
  static HERO_CALVARIA = "Hero Calvaria";
  static HERO_DELPHAS = "Hero Delphas";
  static HERO_DRAKOR = "Hero Drakor";
  static HERO_ERINYS = "Hero Erinys";
  static HERO_EROSIUM = "Hero Erosium";
  static HERO_FELTOR = "Hero Feltor";
  static HERO_GHELOR = "Hero Ghelor";
  static HERO_HELKARAN = "Hero Helkaran";
  static HERO_ICANTA = "Hero Icanta";
  static HERO_JELORIA = "Hero Jeloria";
  static HERO_KORATH = "Hero Korath";
  static HERO_KOBAMDA = "Hero Kobamda";
  static HERO_LARAN = "Hero Laran";
  static HERO_MALTRION = "Hero Maltrion";
  static HERO_MINARI = "Hero Minari";
  static HERO_OPHARIAN = "Hero Opharian";
  static HERO_PROTHIAN = "Hero Prothian";
  static HERO_QUORIDIOM = "Hero Quoridiom";
  static HERO_RYNZORATH = "Hero Rynzorath";
  static HERO_SELTAAR = "Hero Seltaar";
  static HERO_TERRAN = "Hero Terran";
  static HERO_TAARKIR = "Hero Taarkir";
  static HERO_UTARION = "Hero Utarion";
  static HERO_VOIDBORN = "Hero Voidborn";
  static HERO_WOLTHAAR = "Hero Wolthaar";
  static HERO_XELPHAR = "Hero Xelphar";
  static HERO_XENOS = "Hero Xenos";
  static HERO_ZYLORIAN = "Hero Zylorian";

  //HERO NEW NAMES
  static HERO_Y_XXOR = "Y'xxor";
  static HERO_SLATHEN = "Slathen";
  static HERO_AV_ARI = "Av'ari";
  static HERO_KZATHOR = "Kzathor";
  static HERO_ZEELA = "Zeela";
  static HERO_BRALLAX = "Brallax";
  static HERO_AZH_RA = "Azh'ra";
  static HERO_TZREL = "Tzrel";
  static HERO_VOLXATH = "Volxath";
  static HERO_QUINAR = "Quinar";
  static HERO_GHYLDRA = "Ghyldra";
  static HERO_RONNAK = "Ronnak";
  static HERO_ESDRAN = "Esdran";
  static HERO_PHAARA = "Phaara";
  static HERO_TROXATH = "Troxath";
  static HERO_XORA = "Xora";
  static HERO_DRINOLL = "Drinoll";
  static HERO_VLYSSA = "Vlyssa";
  static HERO_ZREN = "Zren";
  static HERO_KOLTA = "Kolta";

  static getHeroImageFileName(name) {
    switch (name) {
      case HeroNames.HERO_AETHERION:
        return ImagesUrl.URL_AETHERION;
      case HeroNames.HERO_ANDAR:
        return ImagesUrl.URL_ANDAR;
      case HeroNames.HERO_BOREALIS:
        return ImagesUrl.URL_BOREALIS;
      case HeroNames.HERO_CALVARIA:
        return ImagesUrl.URL_CALVARIA;
      case HeroNames.HERO_DELPHAS:
        return ImagesUrl.URL_DELPHAS;
      case HeroNames.HERO_DRAKOR:
        return ImagesUrl.URL_DRAKOR;
      case HeroNames.HERO_ERINYS:
        return ImagesUrl.URL_ERINYS;
      case HeroNames.HERO_EROSIUM:
        return ImagesUrl.URL_EROSIUM;
      case HeroNames.HERO_FELTOR:
        return ImagesUrl.URL_FELTOR;
      case HeroNames.HERO_GHELOR:
        return ImagesUrl.URL_GHELOR;
      case HeroNames.HERO_HELKARAN:
        return ImagesUrl.URL_HELKARAN;
      case HeroNames.HERO_ICANTA:
        return ImagesUrl.URL_ICANTA;
      case HeroNames.HERO_JELORIA:
        return ImagesUrl.URL_JELORIA;
      case HeroNames.HERO_KORATH:
        return ImagesUrl.URL_KORATH;
      case HeroNames.HERO_KOBAMDA:
        return ImagesUrl.URL_KOBAMDA;
      case HeroNames.HERO_LARAN:
        return ImagesUrl.URL_LARAN;
      case HeroNames.HERO_MALTRION:
        return ImagesUrl.URL_MALTRION;
      case HeroNames.HERO_MINARI:
        return ImagesUrl.URL_MINARI;
      case HeroNames.HERO_OPHARIAN:
        return ImagesUrl.URL_OPHARIAN;
      case HeroNames.HERO_PROTHIAN:
        return ImagesUrl.URL_PROTHIAN;
      case HeroNames.HERO_QUORIDIOM:
        return ImagesUrl.URL_QUORIDIOM;
      case HeroNames.HERO_RYNZORATH:
        return ImagesUrl.URL_RYNZORATH;
      case HeroNames.HERO_SELTAAR:
        return ImagesUrl.URL_SELTAAR;
      case HeroNames.HERO_TERRAN:
        return ImagesUrl.URL_TERRAN;
      case HeroNames.HERO_TAARKIR:
        return ImagesUrl.URL_TAARKIR;
      case HeroNames.HERO_UTARION:
        return ImagesUrl.URL_UTARION;
      case HeroNames.HERO_VOIDBORN:
        return ImagesUrl.URL_VOIDBORN;
      case HeroNames.HERO_WOLTHAAR:
        return ImagesUrl.URL_WOLTHAAR;
      case HeroNames.HERO_XELPHAR:
        return ImagesUrl.URL_XELPHAR;
      case HeroNames.HERO_XENOS:
        return ImagesUrl.URL_XENOS;
      case HeroNames.HERO_ZYLORIAN:
        return ImagesUrl.URL_ZYLORIAN;

      default:
        throw new Error("Hero image not found");
    }
  }
}

module.exports = HeroNames;
