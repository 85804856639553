import React from "react";
import BaseImage from "../../GameView/Utils/BaseImage";
import ImagesUrl from "../../Data/Other/ImagesUrl";

const RuleImage = ({ name, url = null, width = null }) => {
  const baseUrl = ImagesUrl.IMAGE_PATH_BASE + "Rulebook/";

  const srcUrl = url ? url : baseUrl + name + ".png";

  //return <img src={srcUrl} width="500px"></img>;

  return (
    <BaseImage
      src={baseUrl + name + ".png"}
      alt={name}
      maxWidth="97%"
      width={width ? width : "undefined"}
      onlyOneRes={true}
      style={{ margin: "10px" }}
    ></BaseImage>
  );
};

export default RuleImage;
