import React, { useState, useEffect } from "react";
import ActivationViewLayout from "./ActivationViewLayout";
import ActivationNavigationView from "./ActivationNavigationView";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import SpaceObjectImageView from "../../Object/SpaceObjectImageView";
import PlanetViewActivation from "./Objects/PlanetViewActivation";
import Logo from "../../Utils/Logo";
import ActivationTransferInView from "./ActivationTransferInView";
import ActivationTransferView from "./ActivationTransferView";
import ActivationCA from "../../../ClientAction/ActivationCA";
import TransferData from "../../../Data/GameData/Activation/TransferData";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const ProductionData = require("../../../Data/GameData/Activation/ProductionData.js");
const Planet = require("../../../Data/GameData/MapData/Planet.js");

const ActivationProductionView = ({ system, navigationData }) => {
  console.log("RENDER ActivationProductionView ");
  const playerData = StaticGameData.getPlayerData();
  const productionData = ProductionData.getData(playerData);

  const planet = Map.getSpaceObjectFromName(
    playerData.map,
    productionData.planetName
  );

  const taskBarData = {
    text: null,
  };

  /*const navigationData = {
    buttonsData: [
      {
        text: "Produce",
        callback: null,
      },
    ],
  };*/

  const DisplayPlanet = () => {
    const popOnMineral = ProductionData.getPopOnMineral(
      playerData,
      planet.name
    );
    const popOnScience = ProductionData.getPopOnScience(
      playerData,
      planet.name
    );
    const unassignedPop = planet.population - popOnMineral - popOnScience;
    const mineralEfficiency = Planet.getMineralEfficiency(playerData, planet);
    const scienceEfficiency = Planet.getScienceEfficiency(playerData, planet);
    const productionMineral = Planet.getMineralProduction(
      playerData,
      planet,
      popOnMineral
    );
    const productionScience = Planet.getScienceProduction(
      playerData,
      planet,
      popOnScience
    );

    const populationGrow = Planet.getPopulationGrow(playerData, planet);

    const productionPopulation = Planet.getPopulationProduction(
      playerData,
      planet
    );

    const assign = ProductionData.assignPopToPlanet;

    const buttonStyle = { width: "50px" };

    return (
      <div>
        <div className="div-hor-center">
          <div style={{}}>
            <PlanetViewActivation
              spaceObject={planet}
              showStock={true}
            ></PlanetViewActivation>
          </div>
        </div>

        <div
          style={{
            fontSize: "1.2em",
            display: "grid",
            gridTemplateColumns: "1fr 2fr 1fr 1fr",
            gap: "1px",
            alignItems:
              "center" /* Center items vertically within the container */,
            justifyItems:
              "center" /* Center items horizontally within the container */,
          }}
        >
          <div>
            <Logo logoName="mineral" heightRem="2em"></Logo>
          </div>
          <div>
            <span>+ {mineralEfficiency} x </span>
            <span>{popOnMineral}</span>
          </div>
          <div> {productionMineral}</div>
          <div className="div-ver-center">
            <div style={{ width: "100%" }}>
              <BaseButton
                onClick={() => {
                  assign(playerData, planet, "mineral", +1);
                }}
                fillSpace={true}
              >
                {" "}
                +{" "}
              </BaseButton>
            </div>
            <div style={{ width: "100%" }}>
              <BaseButton
                onClick={() => {
                  assign(playerData, planet, "mineral", -1);
                }}
                fillSpace={true}
              >
                {" "}
                -{" "}
              </BaseButton>
            </div>
          </div>

          <div>
            <Logo logoName="science" heightRem="2em"></Logo>
          </div>
          <div>
            <span>+ {scienceEfficiency} x </span>
            <span>{popOnScience}</span>
          </div>
          <div>+ {productionScience}</div>
          <div className="div-ver-center">
            <div style={{ width: "100%" }}>
              <BaseButton
                onClick={() => {
                  assign(playerData, planet, "science", +1);
                }}
                fillSpace={true}
              >
                {" "}
                +{" "}
              </BaseButton>
            </div>
            <div style={{ width: "100%" }}>
              <BaseButton
                onClick={() => {
                  assign(playerData, planet, "science", -1);
                }}
                fillSpace={true}
              >
                {" "}
                -{" "}
              </BaseButton>
            </div>
          </div>
        </div>
        <div>
          <span>{planet.population} </span>
          <Logo logoName="population"></Logo>
          <span>
            {" "}
            with a grow rate of : {Planet.getPopulationGrow(
              playerData,
              planet
            )}{" "}
            will produce :{" "}
          </span>
          <span>{Planet.getPopulationProduction(playerData, planet)} </span>
          <Logo logoName="population"></Logo>
        </div>
        <div>
          <span>
            {Planet.getAmountGenerator(playerData, planet)} generators will
            generate {Planet.getEnergyProduction(playerData, planet)}{" "}
          </span>
          <Logo logoName="energy"></Logo>
        </div>
        <hr></hr>
      </div>
    );
  };

  const ProductionView = ({}) => {
    const confirmProduce = () => {
      UIMessage.displayConfirmMessage(
        "Produce ?",
        "Are you sure you want to produce?",
        () => {
          ActivationCA.resolve_STEP_ACTIVATION_PRODUCTION("production");
        }
      );
    };

    const confirmDontProduce = () => {
      UIMessage.displayConfirmMessage(
        "Don't Produce ?",
        "Are you sure you don't want to produce?",
        () => {
          ActivationCA.resolve_STEP_ACTIVATION_PRODUCTION("no production");
        }
      );
    };

    /*navigationData.buttonsData.push({
      text: "Don't Produce",
      callback: confirmDontProduce,
      type: "action",
    });*/
    navigationData.buttonsData.push(
      {
        text: "Help",
        callback: () => {
          Popup.addLayer({
            name: "HelpPopup",
            helpReference: "productionStep",
          });
        },
      },
      {
        text: "Don't Produce",
        callback: () => {
          ActivationCA.initialize_STEP_ACTIVATION_ACTIVATED();
        },
        type: "action",
      },
      {
        text: "Produce",
        callback: confirmProduce,
        type: "action",
      }
    );

    return (
      <div className="text-box" style={{ height: "100%" }}>
        <div>
          Unassigned population can be spent during the build actions, or be
          transfered to other planets.
        </div>
        <hr></hr>
        <div style={{}}>
          <DisplayPlanet></DisplayPlanet>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ProductionView navigationData={navigationData}></ProductionView>
    </div>
  );
};

export default ActivationProductionView;
