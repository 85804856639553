import React from "react";
import RuleImage from "../Utils/RuleImage";
import RuleP from "../Utils/RuleP";
import RuleUl from "../Utils/RuleUl";
import RuleLi from "../Utils/RuleLi";
import RuleLink from "../Utils/RuleLink";
import UnitPagesData from "./UnitPagesData";

const MovePagesData = {
  movementExplanation: {
    title: "Movement",
    content: (
      <div>
        <RuleP>
          Ships can move to any system within their{" "}
          <RuleLink name="range">range</RuleLink>, following a path of connected
          adjacent systems. Diagonal movement is not allowed, so the path must
          consist of systems that share direct borders.
        </RuleP>
        <RuleP>
          For movement, the movement distance is taken into account, which is
          different from the map distance.
        </RuleP>

        <RuleImage name="movement_adjacency"></RuleImage>
      </div>
    ),
    subsections: [
      {
        title: "Special Movement Rules",
        content: (
          <p>
            Certain technologies or abilities may allow fleets to bypass normal
            movement restrictions.
          </p>
        ),
      },
    ],
  },
};

export default MovePagesData;
