const StaticGameData = require("../GameData/StaticGameData");

class DeviceData {
  static IS_USER_USING_MOUSE = true;
  static IS_USER_USING_TOUCH = true;

  static userTouchedScreen() {
    this.IS_USER_USING_MOUSE = false;
    this.IS_USER_USING_TOUCH = true;
  }

  static userClickedMouse() {
    this.IS_USER_USING_MOUSE = true;
    this.IS_USER_USING_TOUCH = false;
  }
}

module.exports = DeviceData;
