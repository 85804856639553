import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import SpaceObjectSummaryView from "./SpaceObjectSummaryView";
import NavigationView from "../NavigationView";
import StaticGameData from "../../Data/GameData/StaticGameData";
import GameGlobalLayout from "../GameGlobalLayout";
import FleetSummaryView from "./FleetSummaryView";
import FactionLogo from "../Utils/FactionLogo";
import Logo from "../Utils/Logo";
import TitleView from "../TitleView";
import ActivationActivatedView from "./ActivationView/ActivationActivatedView";
import ActivationMoveView from "./ActivationView/ActivationMoveView";
import ActivationBuildView from "./ActivationView/ActivationBuildView";
import ActivationAssignementView from "./ActivationView/ActivationAssignementView";
import ActivationTransferView from "./ActivationView/ActivationTransferView";
import ActivationProductionView from "./ActivationView/ActivationProductionView";
import ActivationPlanetManagementView from "./ActivationView/ActivationPlanetManagementView";
import ActivationLiftView from "./ActivationView/ActivationLiftView";
import ActivationDropView from "./ActivationView/ActivationDropView";
import ActivationInvasionView from "./ActivationView/ActivationInvasionView";
import ActivationStructureView from "./ActivationView/ActivationStructureView";
import ManageSystemView from "./ManageSystemView";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Request = require("../../Common/Requests/Request.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const Rules = require("../../Data/GameData/Game/Rules.js");
const Phase = require("../../Data/GameData/Game/Phase.js");
const ActivationData = require("../../Data/GameData/Activation/ActivationData.js");

const ManageActivationView = ({ system, activationData }) => {
  console.log("RENDER ManageActivationView ");
  const playerData = StaticGameData.getPlayerData();
  const activeSystem = ActivationData.getActiveSystem(playerData);

  const navigationData = {
    buttonsData: [
      { text: "Map", callback: () => Popup.unStackToMap() },
      { text: "Back", callback: () => Popup.goBack() },
    ],
  };
  const getNavigationData = () => {
    return navigationData;
  };

  if (playerData.step === Phase.STEP_ACTIVATION_ACTIVATED) {
    return <ManageSystemView system={activeSystem}></ManageSystemView>;
  }

  return (
    <GameGlobalLayout
      forbidOverflow={false}
      title={system.name + " : " + playerData.step + " step"}
    >
      <div
        style={{
          //backgroundColor: "blue",
          height: "100%",
          fontSize: "1rem",
        }}
      >
        {playerData.step === Phase.STEP_ACTIVATION_ACTIVATED && (
          <ActivationActivatedView
            system={system}
            navigationData={navigationData}
          ></ActivationActivatedView>
        )}
        {playerData.step === Phase.STEP_ACTIVATION_MOVE && (
          <ActivationMoveView
            system={system}
            navigationData={navigationData}
          ></ActivationMoveView>
        )}{" "}
        {playerData.step === Phase.STEP_ACTIVATION_STRUCTURE && (
          <ActivationStructureView
            system={system}
            navigationData={navigationData}
          ></ActivationStructureView>
        )}
        {playerData.step === Phase.STEP_ACTIVATION_BUILD && (
          <ActivationBuildView
            system={system}
            navigationData={navigationData}
          ></ActivationBuildView>
        )}
        {playerData.step === Phase.STEP_ACTIVATION_TRANSFER && (
          <ActivationTransferView
            system={system}
            navigationData={navigationData}
          ></ActivationTransferView>
        )}
        {playerData.step === Phase.STEP_ACTIVATION_ASSIGNEMENT && (
          <ActivationAssignementView
            system={system}
            navigationData={navigationData}
          ></ActivationAssignementView>
        )}
        {playerData.step === Phase.STEP_ACTIVATION_PRODUCTION && (
          <ActivationProductionView
            system={system}
            navigationData={navigationData}
          ></ActivationProductionView>
        )}
        {playerData.step === Phase.STEP_ACTIVATION_PLANET_MANAGEMENT && (
          <ActivationPlanetManagementView
            system={system}
            navigationData={navigationData}
          ></ActivationPlanetManagementView>
        )}
        {playerData.step === Phase.STEP_ACTIVATION_LIFT && (
          <ActivationLiftView
            system={system}
            navigationData={navigationData}
          ></ActivationLiftView>
        )}
        {playerData.step === Phase.STEP_ACTIVATION_DROP && (
          <ActivationDropView
            system={system}
            navigationData={navigationData}
          ></ActivationDropView>
        )}
        {playerData.step === Phase.STEP_ACTIVATION_INVASION && (
          <ActivationInvasionView
            system={system}
            navigationData={navigationData}
          ></ActivationInvasionView>
        )}
      </div>

      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default ManageActivationView;

/*{false && (
        <div
          style={{
            backgroundColor: "blue",
          }}
        >
          {playerData.activationData.step ===
            Rules.PHASE_ACTIVATION_ACTIVATED && (
            <ActivationActivatedView system={system}></ActivationActivatedView>
          )}
        </div>
      )}*/
