import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import { getObjectSummaryJSXArray } from "./SpaceObjectInfoArray"; // Adjust the path accordingly
import FactionLogo from "../Utils/FactionLogo";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const SpaceObject = require("../../Data/GameData/MapData/SpaceObject.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");

const FleetSummaryView = ({
  fleet,
  unitClass = Fleet.UNIT_CLASS_ALL,
  onClick = null,
  showCapacity = false,
  superShort = false,
}) => {
  if (!fleet) {
    return null;
  }

  if (Fleet.isEmpty(fleet)) {
    return null;
  }

  const playerData = StaticGameData.getPlayerData();
  const selectedFactionData = PlayerData.getPlayerDataFromFaction(
    playerData,
    fleet.faction
  );
  if (!selectedFactionData) {
    throw new Error("FleetSummaryView: selectedFactionData is null");
  }

  const DisplayShips = ({ fleet, unitClass }) => {
    const getFleetText = () => {
      if (superShort) {
        return Fleet.getFleetClassSuperShortText(fleet, unitClass);
      } else {
        return Fleet.getFleetClassShortText(fleet, unitClass);
      }
    };

    const fleetShortText = getFleetText(fleet, unitClass);
    if (fleetShortText === "") {
      return null;
    }

    /*const shipsArray = Fleet.getShipsArray(fleet);
    const shipDisplayArray = [];
    for (let i = 0; i < shipsArray.length; i++) {
      const ship = shipsArray[i];
      shipDisplayArray.push(
        <div key={i}>
          <span>
            {ship.type} {ship.subType} {ship.amount}/{ship.damagedAmount}
          </span>
        </div>
      );
    }*/

    return (
      <span>
        {!superShort && (
          <span>
            <FactionLogo factionName={fleet.faction} heightRem={"1em"} />
          </span>
        )}
        <span style={{ width: "1em" }}></span>
        <span> {fleetShortText}</span>
      </span>
    );
  };

  const DisplayCapacity = ({}) => {
    const requiredCapacity = Fleet.getTotalRequiredCapacity(playerData, fleet);
    const capacity = Fleet.getTotalCapacity(playerData, fleet);
    const logisticMass = Fleet.getLogisticMass(fleet);
    const fleetLimit = PlayerData.getFleetLimit(playerData);

    return (
      <span style={{ color: "white" }}>
        Cap : {requiredCapacity} / {capacity} , Fleet : {logisticMass} /{" "}
        {fleetLimit}
      </span>
    );
  };

  if (superShort) {
    return (
      <span
        style={{
          color: Color.getColorFromPlayerColor(selectedFactionData.color),
        }}
      >
        <DisplayShips
          fleet={fleet}
          unitClass={Fleet.UNIT_CLASS_ALL}
        ></DisplayShips>
      </span>
    );
  }

  return (
    <span>
      <BaseButton
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            Popup.addLayer({ name: "FleetDetailedView", fleet: fleet });
          }
        }}
        type="fleet"
        textColor={Color.getColorFromPlayerColor(selectedFactionData.color)}
      >
        <span style={{ display: "inline-block", verticalAlign: "top" }}>
          {(unitClass === "ship" || unitClass === "all") && (
            <div>
              <DisplayShips fleet={fleet} unitClass={"ship"}></DisplayShips>
            </div>
          )}
          {(unitClass === "ground force" || unitClass === "all") && (
            <div>
              <DisplayShips
                fleet={fleet}
                unitClass={"ground force"}
              ></DisplayShips>
            </div>
          )}
          {(unitClass === "structure" || unitClass === "all") && (
            <div>
              <DisplayShips
                fleet={fleet}
                unitClass={"structure"}
              ></DisplayShips>
            </div>
          )}
        </span>
        {showCapacity && (
          <div>
            <DisplayCapacity></DisplayCapacity>
          </div>
        )}
      </BaseButton>
    </span>
  );
};

export default FleetSummaryView;
