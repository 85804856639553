import React from "react";
import MovePagesData from "./MovePagesData";
import ObjectPagesData from "./ObjectPagesData";
import UnitPagesData from "./UnitPagesData";
import { setPageResolver } from "../Utils/RuleLink";
import { setProcessPage } from "../RulePage";
import RuleLink from "../Utils/RuleLink";
import ActionPagesData from "./ActionPagesData";
import MechanicPagesData from "./MechanicPagesData";

// Combine all page data
const allPages = {
  ...MovePagesData,
  ...ObjectPagesData,
  ...UnitPagesData,
  ...ActionPagesData,
  ...MechanicPagesData,
};

// Generate the keyword-to-page mapping from aliases
const generateKeywordToPageMap = (pages) => {
  const keywordToPageMap = {};

  Object.entries(pages).forEach(([pageName, pageData]) => {
    if (pageData.aliases) {
      pageData.aliases.forEach((alias) => {
        keywordToPageMap[alias.toLowerCase()] = pageName;
      });
    }
  });

  return keywordToPageMap;
};

const keywordToPageMap = generateKeywordToPageMap(allPages);

// Helper function to recursively process JSX content
const processJsxWithLinks = (node, keywordToPageMap, excludedAliases = []) => {
  if (typeof node === "string") {
    const elements = [];
    let lastIndex = 0;

    // Create regex for keyword replacement, excluding current page's aliases
    const keywords = Object.keys(keywordToPageMap).filter(
      (key) => !excludedAliases.includes(key)
    );
    const regex = new RegExp(`\\b(${keywords.join("|")})\\b`, "gi");

    let match;
    while ((match = regex.exec(node)) !== null) {
      if (match.index > lastIndex) {
        elements.push(node.substring(lastIndex, match.index));
      }

      const keyword = match[0];
      const pageName = keywordToPageMap[keyword.toLowerCase()];
      elements.push(
        <RuleLink key={match.index} name={pageName}>
          {keyword}
        </RuleLink>
      );

      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < node.length) {
      elements.push(node.substring(lastIndex));
    }

    return elements.length > 1 ? elements : elements[0];
  }

  if (React.isValidElement(node)) {
    return React.cloneElement(node, {
      ...node.props,
      children: React.Children.map(node.props.children, (child) =>
        processJsxWithLinks(child, keywordToPageMap, excludedAliases)
      ),
    });
  }

  return node;
};

// Process content and subsections of a page
const processPage = (page) => {
  if (!page) return null;

  const processedPage = { ...page };

  // Pass aliases as excludedAliases
  const excludedAliases = page.aliases || [];

  if (page.content) {
    processedPage.content = processJsxWithLinks(
      page.content,
      keywordToPageMap,
      excludedAliases
    );
  }

  if (page.subsections && Array.isArray(page.subsections)) {
    processedPage.subsections = page.subsections.map((subsection) => {
      const processedSubsection = { ...subsection };

      if (subsection.content) {
        processedSubsection.content = processJsxWithLinks(
          subsection.content,
          keywordToPageMap,
          excludedAliases
        );
      }

      return processedSubsection;
    });
  }

  return processedPage;
};

const indexPages = {
  getPage: (name) => {
    const rawPage = allPages[name];

    if (!rawPage) {
      throw new Error(`Page not found: ${name}`);
    }

    return rawPage;
  },
};

setPageResolver(indexPages.getPage);

setProcessPage(processPage);

export default indexPages;
