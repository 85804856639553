//DEPRECIATED

const Popup = require("../../Other/Popup");
const LogBook = require("../Connection/LogBook");
const UIMessage = require("../Connection/UIMessage");

class Tutorial {
  static enrichPlayerData(playerData) {
    playerData.tutorial = {
      waitingOnStep: null,
      waitingOnScreenName: null,
      stepName: null,
    };
  }

  static triggerScreenName(playerData, screenName) {
    if (!playerData.tutorial) return;

    if (!playerData.tutorial.waitingOnScreenName) return;

    if (playerData.tutorial.waitingOnScreenName === screenName) {
      return true;
    }

    return false;
  }

  static routeCurrentStep(playerData) {
    if (!playerData.tutorial) return;

    switch (playerData.tutorial.stepName) {
      case "introduction":
        this.introduction(playerData);
        break;
    }

    this.stepFinished(playerData);
  }

  static introduction(playerData) {
    const logBook = LogBook.generateLogBook("Welcome to this training !");
    LogBook.generateAddMessage(
      logBook,
      "I am Aureon, your instructor. I have been assigned by the Galactic Council to train you in the art of space navigation and commanding your faction."
    );

    UIMessage.displayTutorialMessage(logBook, playerData);
  }

  static stepFinished(playerData) {}

  static checkTutorialRouteSA(playerData) {
    return playerData.tutorial.routeSA;
  }

  static getTutorialStepsArray() {}
}

module.exports = { Tutorial };
