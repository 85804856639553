const Color = require("../../../Common/Config/Colors");
const ActivePassiveAbility = require("../Ability/ActivePassiveAbility");

class Tech {
  static create(
    name,
    level,
    effect = "No effect",
    description = "No description",
    active = false,
    cost = { science: 4 },
    shortDescription = "No short description",
    whiteTech = false,
    mandatoryTech = false
  ) {
    return {
      name: name,
      level: level,
      requirements: [],
      color: null,
      owned: false, //If player owns tech
      cost: cost,
      effect: effect,
      //abilities : ActivePassiveAbility.createDesc
      description: description,
      active: active,
      isExhausted: false,
      shortDescription: shortDescription,
      whiteTech: whiteTech,
      mandatoryTech: mandatoryTech,
    };
  }

  //Get
  static getAbbreviationColor(color) {
    if (color === Color.COLOR_NAME_TECH_BLUE) {
      return { text: "B", color: Color.COLOR_TECH_BLUE };
    }
    if (color === Color.COLOR_NAME_TECH_RED) {
      return { text: "R", color: Color.COLOR_TECH_RED };
    }
    if (color === Color.COLOR_NAME_TECH_GREEN) {
      return { text: "G", color: Color.COLOR_TECH_GREEN };
    }
    if (color === Color.COLOR_NAME_TECH_YELLOW) {
      return { text: "Y", color: Color.COLOR_TECH_YELLOW };
    }
    return { text: "-", color: "white" };
  }

  static getLogoName(color) {
    if (color === Color.COLOR_NAME_TECH_BLUE) {
      return "tech blue";
    }
    if (color === Color.COLOR_NAME_TECH_RED) {
      return "tech red";
    }
    if (color === Color.COLOR_NAME_TECH_YELLOW) {
      return "tech yellow";
    }
    if (color === Color.COLOR_NAME_TECH_GREEN) {
      return "tech green";
    }
  }

  //Generate
  static addPrimaryRequirement(tech, color) {
    //First req is forced to be at least filled
    if (this.getEmptyRequirementSlotSize(tech) > 0) {
      tech.requirements.push(color);
    }

    this.duplicateRequirement(tech, color);
  }

  static duplicateRequirement = (tech, color) => {
    const baseDupplicateOdd = 0.5;
    const oddDegradationRatio = 0.7;
    let dupplicateOdd = baseDupplicateOdd;
    const slots = this.getEmptyRequirementSlotSize(tech);
    for (let i = 0; i < slots; i++) {
      const random = Math.random();
      if (random < dupplicateOdd) {
        tech.requirements.push(color);
        dupplicateOdd *= dupplicateOdd * oddDegradationRatio;
      } else {
        return;
      }
    }
  };

  static getEmptyRequirementSlotSize(tech) {
    return tech.level - tech.requirements.length - 1;
  }

  static addSecondaryRequirement(tech, color) {
    this.duplicateRequirement(tech, color);
    /*const slots = this.getEmptyRequirementSlotSize(tech);
    const amountReqToAdd = CustomMath.generateRandomNumber(
      Math.floor(slots / 2),
      slots
    );
    for (let i = 0; i < amountReqToAdd; i++) {
      tech.requirements.push(color);
    }*/
  }

  static addThirdRequirement(tech, color) {
    const slots = this.getEmptyRequirementSlotSize(tech);
    const amountReqToAdd = slots;
    for (let i = 0; i < amountReqToAdd; i++) {
      tech.requirements.push(color);
    }
  }

  static exhaust(tech) {
    tech.isExhausted = true;
  }

  static ready(tech) {
    tech.isExhausted = false;
  }

  static isExhausted(tech) {
    return tech.isExhausted;
  }
}

module.exports = Tech;
