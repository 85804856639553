import React, { useState, useEffect } from "react";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import SpaceObjectImageView from "../../Object/SpaceObjectImageView";
import Logo from "../../Utils/Logo";
import ActivationCA from "../../../ClientAction/ActivationCA";
import TransferData from "../../../Data/GameData/Activation/TransferData";
import ProductionActionData from "../../../Data/GameData/ActionData/ProductionActionData";
import PlanetView from "../../Object/PlanetView";
import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData";
import BuyActionData from "../../../Data/GameData/ActionData/BuyActionData";
import CostView from "../../Utils/CostView";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const Planet = require("../../../Data/GameData/MapData/Planet.js");

const ActionBuyView = ({}) => {
  console.log("RENDER ActivationProductionView ");
  const playerData = StaticGameData.getPlayerData();
  const data = ActionCommonData.getData(playerData);

  const BuyView = ({}) => {
    const buttonWidth = "2.5em";
    const largeButtonWidth = "3em";

    return (
      <div className="text-box" style={{ height: "100%", textAlign: "center" }}>
        <div style={{ fontSize: "1.5em" }}>
          <div className="div-fill">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 2fr",
                justifyContent: "center",
                alignItems: "center",

                maxWidth: "600px",
              }}
            >
              <Logo logoName="mineral"></Logo>
              <div>{data.mineralToBuy}</div>
              <div>
                <div>
                  <BaseButton
                    width={buttonWidth}
                    onClick={() => {
                      BuyActionData.updateMineralToBuy(playerData, 0.1);
                    }}
                  >
                    +
                  </BaseButton>
                  <BaseButton
                    width={largeButtonWidth}
                    onClick={() => {
                      BuyActionData.updateMineralToBuy(playerData, 1);
                    }}
                  >
                    + +
                  </BaseButton>
                </div>
                <div>
                  <BaseButton
                    width={buttonWidth}
                    onClick={() => {
                      BuyActionData.updateMineralToBuy(playerData, -0.1);
                    }}
                  >
                    -
                  </BaseButton>
                  <BaseButton
                    width={largeButtonWidth}
                    onClick={() => {
                      BuyActionData.updateMineralToBuy(playerData, -1);
                    }}
                  >
                    - -
                  </BaseButton>
                </div>
              </div>
              <Logo logoName="science"></Logo>
              <div>{data.scienceToBuy}</div>
              <div>
                <div>
                  <BaseButton
                    width={buttonWidth}
                    onClick={() => {
                      BuyActionData.updateScienceToBuy(playerData, 0.1);
                    }}
                  >
                    +
                  </BaseButton>
                  <BaseButton
                    width={largeButtonWidth}
                    onClick={() => {
                      BuyActionData.updateScienceToBuy(playerData, 1);
                    }}
                  >
                    + +
                  </BaseButton>
                </div>
                <div>
                  <BaseButton
                    width={buttonWidth}
                    onClick={() => {
                      BuyActionData.updateScienceToBuy(playerData, -0.1);
                    }}
                  >
                    -
                  </BaseButton>
                  <BaseButton
                    width={largeButtonWidth}
                    onClick={() => {
                      BuyActionData.updateScienceToBuy(playerData, -1);
                    }}
                  >
                    - -
                  </BaseButton>
                </div>
              </div>
              <Logo logoName="energy"></Logo>
              <div>{data.energyToBuy}</div>
              <div>
                <div>
                  <BaseButton
                    width={buttonWidth}
                    onClick={() => {
                      BuyActionData.updateEnergyToBuy(playerData, 0.1);
                    }}
                  >
                    +
                  </BaseButton>
                  <BaseButton
                    width={largeButtonWidth}
                    onClick={() => {
                      BuyActionData.updateEnergyToBuy(playerData, 1);
                    }}
                  >
                    + +
                  </BaseButton>
                </div>
                <div>
                  <BaseButton
                    width={buttonWidth}
                    onClick={() => {
                      BuyActionData.updateEnergyToBuy(playerData, -0.1);
                    }}
                  >
                    -
                  </BaseButton>
                  <BaseButton
                    width={largeButtonWidth}
                    onClick={() => {
                      BuyActionData.updateEnergyToBuy(playerData, -1);
                    }}
                  >
                    - -
                  </BaseButton>
                </div>
              </div>
            </div>
          </div>{" "}
          <div style={{ marginTop: "1em" }}>
            Cost :{" "}
            <CostView cost={BuyActionData.getCost(playerData)}></CostView>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div>
        {" "}
        <BuyView></BuyView>
      </div>{" "}
      <div>
        <BaseButton
          onClick={() => {
            Popup.deActivateBlackPopup();
          }}
        >
          Cancel
        </BaseButton>
        <BaseButton
          onClick={() => {
            Popup.deActivateBlackPopup();
            BuyActionData.clientResolve(playerData);
          }}
        >
          Confirm
        </BaseButton>
      </div>
    </div>
  );
};

export default ActionBuyView;
