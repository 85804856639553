import { useEffect } from "react";

import InteractionFunctions from "../../UIInteraction/InteractionFunctions";
import Phase from "../../../Data/GameData/Game/Phase";
import Map from "../../../Data/GameData/MapData/Map";
import System from "../../../Data/GameData/MapData/System";
import BaseButton from "../../Utils/BaseButton";
import SpaceObjectImageView from "../../Object/SpaceObjectImageView";
import Popup from "../../../Data/Other/Popup";
import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData";
import MoveActionData from "../../../Data/GameData/ActionData/MoveActionData";
import FleetLineView from "../../Object/FleetLineView";
import Fleet from "../../../Data/GameData/MapData/Fleet";
import UnitsLineView from "../../Units/UnitsLineView";
import Unit from "../../../Data/GameData/MapData/Unit";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";

class ActionMove {
  static prepare(playerData, system) {
    const selectedUnits = [];

    playerData.phase = Phase.PHASE_ACTION;
    playerData.step = Phase.STEP_ACTION_MOVE;

    const data = {
      unitsToMove: [],
      systemName: null,
      planetName: null,
      invade: false,
    };

    const afterUnitsSelected = (units) => {
      data.unitsToMove = units;
      InteractionFunctions.selectASpaceObjectOrSystem(
        afterTargetObjectSelected,
        "",
        <span>
          Select a system or planet to move these units to :{" "}
          <UnitsLineView
            units={units}
            factionName={playerData.faction.name}
          ></UnitsLineView>
        </span>,
        () => {
          Popup.resetMapNavigationData();
        },
        false
      );

      /*InteractionFunctions.selectASystem(
        () => {
          Popup.setMapClickCallback(afterObjectSelected);
        },
        "Select target system or planet.",
        <span>
          Select a system to move these units :{" "}
          <UnitsLineView
            onClick={() => {
              ActionMove.prepare(playerData);
            }}
            units={units}
            factionName={playerData.faction.name}
          ></UnitsLineView>
        </span>
      );
      data.unitsToMove = units;*/
    };

    const afterTargetObjectSelected = (object) => {
      const system = object.isSystem
        ? object
        : Map.getSystemFromSpaceObjectName(playerData.map, object.name);
      if (!system) {
        throw new Error("System not found (Action Move)");
      }

      const data = {};
      if (object.isPlanet) {
        data.planetName = object.name;
      }
      data.systemName = system.name;
      data.unitsToMove = selectedUnits;

      const newPlayerData = StaticGameData.getPlayerData();
      MoveActionData.resolveClient(newPlayerData, data);
      Popup.resetMapNavigationData();

      //ActionMove.prepare(newPlayerData);
    };

    const afterSystemSelected = (system) => {
      data.systemName = system.name;
      Popup.activateBlackPopup(
        this.selectAreaInSystem(system, afterConfirmingAreaInSystem)
      );
    };

    const afterConfirmingAreaInSystem = (targetObject) => {
      if (targetObject.isPlanet) {
        data.planetName = targetObject.name;
      } else {
        data.planetName = null;
      }
      Popup.deActivateBlackPopup();
      const newPlayerData = StaticGameData.getPlayerData();
      MoveActionData.resolveClient(newPlayerData, data);
      ActionMove.prepare(newPlayerData);
    };

    InteractionFunctions.selectUnitsInSystem({
      selectedUnits: selectedUnits,
      system: system,
      callback: afterUnitsSelected,
      filter: { classesIncluded: [Unit.CLASS_GROUND_FORCE, Unit.CLASS_SHIP] },
    });
  }

  static prepareOld(playerData) {
    playerData.phase = Phase.PHASE_ACTION;
    playerData.step = Phase.STEP_ACTION_MOVE;

    const data = {
      unitsToMove: [],
      systemName: null,
      planetName: null,
    };

    const afterSystemSelected = (system) => {
      data.systemName = system.name;
      Popup.activateBlackPopup(
        this.selectAreaInSystem(system, afterConfirmingAreaInSystem)
      );
    };

    const afterUnitsSelected = (units) => {
      InteractionFunctions.selectASystem(
        afterSystemSelected,
        "Select target system or planet.",
        <span>
          Select a system or a planet to move these units to :{" "}
          <UnitsLineView
            /*onClick={() => {
              ActionMove.prepare(playerData);
            }}*/
            units={units}
            factionName={playerData.faction.name}
          ></UnitsLineView>
        </span>
      );
      data.unitsToMove = units;
    };

    const afterConfirmingAreaInSystem = (targetObject) => {
      if (targetObject.isPlanet) {
        data.planetName = targetObject.name;
      } else {
        data.planetName = null;
      }
      Popup.deActivateBlackPopup();
      const newPlayerData = StaticGameData.getPlayerData();
      MoveActionData.resolveClient(newPlayerData, data);
      ActionMove.prepare(newPlayerData);
    };

    InteractionFunctions.selectUnitsOnMap(
      afterUnitsSelected,
      "Select units to move.",
      {
        fromOnlyOneSystem: true,
        classesIncluded: [Unit.CLASS_GROUND_FORCE, Unit.CLASS_SHIP],
      }
    );
  }

  static selectAreaInSystem = (system, callback) => {
    const planets = System.getPlanets(system);

    return (
      <div>
        <div style={{ margin: "0.5em" }}>
          Select a area in the target system to move units to :{" "}
        </div>
        <div style={{ margin: "0.5em" }}>
          <BaseButton
            onClick={() => {
              callback(system);
            }}
          >
            Space area
          </BaseButton>
        </div>
        {planets.map((planet, index) => {
          return (
            <div key={index} style={{ margin: "0.5em" }}>
              <BaseButton
                onClick={() => {
                  callback(planet);
                }}
              >
                <SpaceObjectImageView
                  spaceObject={planet}
                  maxHeight="1em"
                  showAsLogo={true}
                ></SpaceObjectImageView>
                {planet.name}
              </BaseButton>{" "}
            </div>
          );
        })}
        <div>
          <BaseButton
            onClick={() => {
              Popup.deActivateBlackPopup();
            }}
          >
            Cancel
          </BaseButton>
        </div>
      </div>
    );
  };
}

export default ActionMove;
