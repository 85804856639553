import React from "react";
import Game from "../Data/GameData/Game/Game";
import StaticGameData from "../Data/GameData/StaticGameData";
import FactionLogo from "../GameView/Utils/FactionLogo";
import DynamicView from "../GameView/DynamicView";
import Popup from "../Data/Other/Popup";
import GameView from "../GameView/GameView";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import MenuNavigationView from "../MainMenu/MenuNavigationView";
import GameGlobalLayout from "../GameView/GameGlobalLayout";
import BaseButton from "../GameView/Utils/BaseButton";
import Request from "../Common/Requests/Request";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import BaseInput from "../GameView/Utils/BaseInput";
import Time from "../Data/GameData/Time/Time";

function GameParamView({ game }) {
  console.log("game", game);

  return (
    <div
      className="text-box-center"
      style={
        {
          //boxShadow: "inset 0 0 0 4px pink", // Simulate an inner border
        }
      }
    >
      <LineSpace></LineSpace>
      <div> Game ID : {game.gameId}</div>{" "}
      <div> Draft : {game.draft ? "Yes" : "No"}</div>
      <div> Amount of players : {game.maxNumberPlayers}</div>
      <div> Password : {game.password ? "Yes" : "No"}</div>
      <div> Ranked : {game.ranked ? "Yes" : "No"}</div>
      <div> VP Limit : {game.vpLimit}</div>
      <div> Round Limit : {game.roundAmount} rounds</div>
      <div> Player Name Hidden : {game.playerNameHidden ? "Yes" : "No"}</div>
      <div> Game List Type : {game.gameListType}</div>
      <div> Time Type : {game.timeType}</div>
      {game.timeType === Game.TIME_TYPE_FREE && (
        <div>
          Preferable round duration :{" "}
          {Time.getDisplayTimeInString(game.roundTime)}
        </div>
      )}
      <LineSpace></LineSpace>
    </div>
  );
}

export default GameParamView;
