const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const ActivationData = require("./ActivationData.js");
const PlanetData = require("../MapData/Planet.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const UnitCatalog = require("../Utils/UnitCatalog.js");
const SpaceObject = require("../MapData/SpaceObject.js");
const WarningAction = require("../ActionCommon/WarningAction.js");
const SolveFleetLimitData = require("../MandatoryAction/SolveFleetLimitData.js");
const SolveCapacityData = require("../MandatoryAction/SolveCapacityData.js");
const ConstructionData = require("./ConstructionData.js");

class StructureData {
  //Transfer Functions

  static prepareStep(playerData, planet) {
    const activeSystem = ActivationData.getActiveSystem(playerData);
    const data = {
      planetName: planet.name,
      fleetProducing: Fleet.createFleet(playerData, playerData.faction.name),
      unitCatalog: UnitCatalog.create(playerData),
    };

    playerData.activationData.structureStep = data;
  }

  static getData(playerData) {
    return playerData.activationData.structureStep;
  }

  static getFullCatalog(playerData, planet) {
    const data = StructureData.getData(playerData);
    return data.unitCatalog;
  }

  static getClassCatalog(playerData, planet, unitClass) {
    const data = StructureData.getData(playerData);
    return UnitCatalog.getClassCatalog(data.unitCatalog, unitClass);
  }

  static resolveStructure(playerData) {
    const data = this.getData(playerData);

    const activeSystem = ActivationData.getActiveSystem(playerData);
    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);

    const constructingSystem = Map.getSystemFromSpaceObject(
      planet,
      playerData.map
    );

    const fleetProducing = data.fleetProducing;
    const cost = Fleet.getCost(playerData, fleetProducing);
    PlayerData.spendCost(playerData, cost, planet);

    const constructingStructures = Fleet.getUnits(
      fleetProducing,
      Fleet.UNIT_CLASS_STRUCTURE
    );

    //Check units requirements
    ConstructionData.canPlanetConstruct(playerData, planet, fleetProducing);

    const planetFleet = SpaceObject.getFleetOrCreate(
      planet,
      playerData.faction.name,
      playerData.color
    );
    /*console.log("constructingGroundForces: ", constructingGroundForces);
      console.log("planetFleet: ", planetFleet);*/
    Fleet.addUnits(planetFleet, constructingStructures);

    System.logActivityToSpaceObject(constructingSystem, planet, [
      { type: LogMessage.ITEM_TYPE_SPACE_OBJECT, content: planet },
      { content: " built " },
      { type: LogMessage.ITEM_TYPE_FLEET, content: fleetProducing },
    ]);

    Planet.checkSizeUsed(playerData, planet);

    PlayerData.updateFleetLimit(playerData);
    PlayerData.updateEnergyConsumption(playerData);

    //Logging
    if (!Fleet.isEmpty(data.fleetProducing)) {
      System.logActivityToSpaceObject(activeSystem, planet, [
        { type: LogMessage.ITEM_TYPE_SPACE_OBJECT, content: planet },
        { content: data.name + " built " },
        {
          type: LogMessage.ITEM_TYPE_FLEET,
          content: Fleet.getLogItem(data.fleetProducing),
        },
      ]);
    }
  }
}

module.exports = StructureData;
