import React, { useState, useEffect, useRef, Children } from "react";
import "../../CSS/Menu.css";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import Request from "../../Common/Requests/Request";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Color from "../../Common/Config/Colors";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";

import ChatRoom from "../../Data/ChatData/ChatRoom.js";
import FactionLogo from "../Utils/FactionLogo";
import ChatFactionLogo from "./ChatFactionLogo";
import BaseButton from "../Utils/BaseButton";
import useChatData from "./UseChatData";
import GameUpdate from "../../Data/GameData/Game/GameUpdate.js";

const ChatRequest = require("../../Data/ChatData/ChatRequest");
const ChatStaticData = require("../../Data/ChatData/ChatStaticData");

const IMAGE_PLANET_TEST = "/Images/Map/Objects/planet_test.png";

const BlinkingExclamation = ({ shouldBlink = true }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (!shouldBlink) {
      setVisible(true); // Ensure it's always visible when blinking is disabled
      return;
    }

    const interval = setInterval(() => {
      setVisible((prev) => !prev); // Toggle visibility
    }, 500); // Adjust the interval (in milliseconds) for blinking speed

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [shouldBlink]);

  return <span style={{ visibility: visible ? "visible" : "hidden" }}>!</span>;
};

const ChatMenuView = ({ setActiveRoom, navigationData, chatData }) => {
  //const [chatData, setChatData] = useState(ChatStaticData.chatData);

  //const { loading } = useChatData(setChatData); // Pass the callback to the custom hook

  //Loading screen
  /* useEffect(() => {
    if (!chatData) {
      Popup.setClientWaitingServer(true);
    } else {
      Popup.setClientWaitingServer(false);
    }
  }, [ChatStaticData.chatData]);*/

  //Navigation data
  navigationData.buttonsData.push(
    {
      text: "Map",
      callback: () => {
        Popup.unStackToMap();
      },
    },
    {
      text: "Back",
      callback: () => {
        Popup.goBack();
      },
    }
  );

  const DisplayRoomButton = ({ room }) => {
    console.log("room", room);
    let displayItem = null;
    if (room.playerInGameId) {
      displayItem = (
        <ChatFactionLogo
          playerInGameId={room.playerInGameId}
          showFactionName={true}
        ></ChatFactionLogo>
      );
    } else {
      displayItem = <span>Chat Room Common</span>;
    }

    let roomRed = true;
    if (ChatStaticData.chatData) {
      const gameUpdate = StaticGameData.getPlayerData().gameUpdate;
      const chatRoomId = room.id;
      if (!GameUpdate.isRoomRed(gameUpdate, chatRoomId)) {
        roomRed = false;
      }
    }

    return (
      <div>
        <BaseButton
          onClick={() => {
            setActiveRoom(room);
          }}
        >
          {roomRed === false && (
            <span>
              {" "}
              <BlinkingExclamation shouldBlink={false}></BlinkingExclamation>
              <span> </span>
            </span>
          )}
          {displayItem}
        </BaseButton>
      </div>
    );
  };

  const DisplayMainChatMenu = () => {
    return (
      <div className="text-box" style={{ textAlign: "center" }}>
        <DisplayRoomButton room={chatData.commonRoom}></DisplayRoomButton>
        {chatData.factionRooms.map((room, index) => {
          return (
            <DisplayRoomButton key={index} room={room}></DisplayRoomButton>
          );
        })}
      </div>
    );
  };

  if (!chatData) return <div></div>;

  return <DisplayMainChatMenu></DisplayMainChatMenu>;
};

/*<GameGlobalLayout style={{ height: "100%" }} backgroundType="shadow">
      <div
        style={{
          height: "100%",
          overflow: "hidden",
          //backgroundColor: Color.COLOR_MANAGE_VIEW_BACKGROUND,
        }}
      >
        <DisplayChatView></DisplayChatView>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>*/

export default ChatMenuView;
