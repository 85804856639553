class StaticGameData {
  static gameState = { gameId: -999 }; // 333 to test
  static popup = null;
  static setPopupReference = null;
  static setGameStateReference = null;
  static serverMode = null;
  static transactionData = null;
  static setInGame = null;

  //Message outside games
  static messageToDisplay = [];

  //Save of the menu popup (outside of the game view)
  static menuPopup = null;
  static setMenuPopupReference = null;

  //Transactions update function
  static updateTransactionsFunction = null;

  //User info
  static username = null;

  static setGameState(gameState) {
    StaticGameData.gameState = gameState;
    //Important to do setGameStateReference after, as it can afterwards in GameStateHubView replace the StaticGameData.gameState
    StaticGameData.setGameStateReference(gameState);
  }

  static getPlayerData() {
    return StaticGameData.gameState.playerData;
  }

  static setPopup(popup) {
    StaticGameData.popup = popup;

    StaticGameData.setPopupReference(popup);
  }

  static setDummyValue(serverMode) {
    StaticGameData.gameState = null;
    StaticGameData.popup = {};
    StaticGameData.setPopupReference = () => {};
    StaticGameData.setGameStateReference = () => {};
    StaticGameData.serverMode = serverMode;
  }

  static setTransactionData(transactionData) {
    StaticGameData.transactionData = transactionData;
  }

  static getTransactionData() {
    return StaticGameData.transactionData;
  }

  static setUpdatesTransactions(updateTransactions) {
    StaticGameData.updateTransactionsFunction = updateTransactions;
  }

  static async updateTransactions() {
    await StaticGameData.updateTransactionsFunction();
  }

  //GameState data for replay purposes
  static isPlayingAnimation = false;
  static setPlayingAnimation(isPlaying) {
    StaticGameData.isPlayingAnimation = isPlaying;
  }

  static gameStateBeforeAnimation = null;
  static getGameStateBeforeAnimation = () => {
    return StaticGameData.gameStateBeforeAnimation;
  };
  static setGameStateBeforeAnimation = (gameState) => {
    StaticGameData.gameStateBeforeAnimation = gameState;
  };
  static gameStateCurrentAnimation = null;
  static getGameStateCurrentAnimation = () => {
    return StaticGameData.gameStateCurrentAnimation;
  };
  static setGameStateCurrentAnimation = (gameState) => {
    StaticGameData.gameStateCurrentAnimation = gameState;
  };
}

module.exports = StaticGameData;
