import React, { useState, useEffect } from "react";
import "../../../../CSS/Menu.css";
import BaseButton from "../../../Utils/BaseButton";
import Popup from "../../../../Data/Other/Popup";
import SpaceObjectImageView from "../../../Object/SpaceObjectImageView";
import { getObjectSummaryJSXArray } from "../../SpaceObjectInfoArray"; // Adjust the path accordingly
import ResourceView from "../../ResourceView";
import StaticGameData from "../../../../Data/GameData/StaticGameData.js";
import FleetSummaryView from "../../FleetSummaryView";
import ResourceBarView from "../../../Utils/ResourceBarView";
import FleetsSummaryView from "../../FleetsSummaryView";
import Logo from "../../../Utils/Logo";
import FactionLogo from "../../../Utils/FactionLogo";
import LogAttachment from "../../../../Data/GameData/Connection/LogAttachment";
import ActivationCA from "../../../../ClientAction/ActivationCA";
import UIMessage from "../../../../Data/GameData/Connection/UIMessage";

const System = require("../../../../Data/GameData/MapData/System.js");
const Color = require("../../../../Common/Config/Colors.js");
const Fleet = require("../../../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../../../Common/Math/CustomMath.js");
const SpaceObject = require("../../../../Data/GameData/MapData/SpaceObject.js");
const Rules = require("../../../../Data/GameData/Game/Rules.js");
const Map = require("../../../../Data/GameData/MapData/Map.js");
const Planet = require("../../../../Data/GameData/MapData/Planet.js");
const LogBook = require("../../../../Data/GameData/Connection/LogBook.js");

const PlanetViewActivation = ({
  spaceObject,
  children,
  onclick = () => {},
  calledFromActivation = false,
  showFleet = true,
  showStock = true,
  showPopRepartition = true,
  showLogs = false,
  showButtonPlanetName = true,
  showPlanetParameter = false,
  showGeneralInfo = false,
  activation = false, //Put on true if you want to display the activation button
  showSize = false,
}) => {
  const playerData = StaticGameData.getPlayerData();

  const showInvasion = StaticGameData.popup.name === "ManageActivationView";

  console.log("PlanetViewActivation spaceObject", spaceObject);

  const ObjectInformation = () => {
    const jsxArray = getObjectSummaryJSXArray(spaceObject);

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          //gap: "0",
          width: "100%",
        }}
      >
        {jsxArray.map((info, index) => (
          <span
            key={index}
            style={
              {
                //textAlign: "center",
                //border: "1px solid white",
                //padding: "0",
                //marginRight: "10px",
                //boxSizing: "border-box",
              }
            }
          >
            {info}
            {index < jsxArray.length - 1 && <span> / </span>}
          </span>
        ))}
      </div>
    );
  };

  const DisplayLogs = () => {
    const combatLog = LogAttachment.getFullCombatLog(spaceObject);
    const activityLog = LogAttachment.getFullActivityLog(spaceObject);

    console.log("DisplayLogs activityLog", activityLog);

    return (
      <span>
        {(!LogBook.isEmpty(combatLog) || !LogBook.isEmpty(activityLog)) && (
          <BaseButton
            onClick={() => {
              Popup.addLayer({
                name: "ManageLogView",
                combatLog: combatLog,
                activityLog: activityLog,
              });
            }}
          >
            Logs
          </BaseButton>
        )}
      </span>
    );
  };
  const DisplaySize = ({}) => {
    const usedSize = Planet.getAmountUsedSize(spaceObject);
    const size = spaceObject.size;
    return (
      <span>
        {usedSize} / {size}
      </span>
    );
  };

  const DisplayFleets = ({ planet }) => {
    const fleets = Planet.getFleets(planet);
    return <FleetsSummaryView fleets={fleets}></FleetsSummaryView>;
  };

  const DisplayPopRepartition = ({ planet }) => {
    return (
      <span>
        {planet.unassignedPop > 0 && (
          <span style={{ marginRight: "1em" }}>
            <ResourceBarView
              fontSize="1.5em"
              amount={planet.unassignedPop}
              resourceType="population"
            ></ResourceBarView>
          </span>
        )}
        {planet.popOnScience > 0 && (
          <span style={{ marginRight: "1em" }}>
            <ResourceBarView
              fontSize="1.5em"
              amount={planet.popOnScience}
              resourceType="popOnScience"
            ></ResourceBarView>
          </span>
        )}
        {planet.popOnMineral > 0 && (
          <span style={{ marginRight: "1em" }}>
            <ResourceBarView
              fontSize="1.5em"
              amount={planet.popOnMineral}
              resourceType="popOnMineral"
            ></ResourceBarView>
          </span>
        )}
      </span>
    );
  };

  const DisplayStock = ({ planet }) => {
    return (
      <span>
        <ResourceBarView
          resourceType="mineral"
          amount={planet.mineral}
          fontSize="1.5em"
        ></ResourceBarView>
      </span>
    );
  };

  const DisplayPlanet = ({ planet }) => {
    const arrayResources = [];
    let key = 1;
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_MINERAL}
      >
        {planet.mineral} + {planet.mineralEfficiency} / P
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_SCIENCE}
      >
        + {planet.scienceEfficiency} / P
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_INFLUENCE}
      >
        + {planet.influence}
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_POPULATION}
      >
        {planet.population} + {planet.populationGrow}%
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_ENERGY}
      >
        + 0
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_SIZE}
      >
        <span> </span>
        {planet.size}
      </ResourceView>
    );

    //console.log("DisplayPlanet ", planet);
    //console.log("DisplayPlanet planet.mineral", planet.mineral);

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          textAlign: "left",
          //gap: "0",
          width: "100%",
          fontSize: "1.5rem",
        }}
      >
        {arrayResources}
      </div>
    );
  };

  const consultPlanet = () => {
    /*Popup.reset();
    Popup.addLayer({
      name: "ManageSystemView",
      system: Map.getSystemFromSpaceObject(spaceObject, playerData.map),
    });*/
    Popup.addLayer({
      name: "ManageObjectView",
      spaceObject: spaceObject,
    });
  };

  const DisplayPlanetGeneralInfo = ({ planet }) => {
    return (
      <div>
        <div>Planet type : {planet.type}</div>
        <div>Size : {planet.size}</div>
      </div>
    );
  };

  const DisplayPlanetParameters = ({ planet }) => {
    return (
      <div>
        <div style={{ textDecoration: "underline" }}>
          Resources production :{" "}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <div>
            {" "}
            <Logo logoName="mineral"></Logo>
            <span> : {planet.mineralEfficiency} / pop</span>
          </div>
          <div>
            <Logo logoName="science"></Logo>
            <span> : {planet.scienceEfficiency} / pop</span>
          </div>
          <div>
            <Logo logoName="energy"></Logo>
            <span> : {planet.generatorEfficiency} / generator</span>
          </div>
          <div>
            <Logo logoName="influence"></Logo>
            <span> : {planet.influence} </span>
          </div>
          <div>
            <div>Population grow : {planet.populationGrow * 100} %</div>
          </div>
        </div>
      </div>
    );
  };

  const DisplayActivationButton = () => {
    if (spaceObject.faction !== playerData.faction.name) {
      return null;
    }
    return (
      <span>
        {" "}
        {spaceObject.hasProduced && (
          <BaseButton
            onClick={() => {
              UIMessage.displayInfoMessage(
                "Already produced",
                "This planet already produced during this round. It will be able to produce again next round."
              );
            }}
            type="greyOut"
          >
            Resource Production
          </BaseButton>
        )}
        {!spaceObject.hasProduced && (
          <BaseButton
            onClick={() => {
              ActivationCA.initialize_STEP_ACTIVATION_PRODUCTION(spaceObject);
            }}
          >
            Resource Production
          </BaseButton>
        )}
        <BaseButton
          onClick={() => {
            ActivationCA.initialize_STEP_ACTIVATION_STRUCTURE(spaceObject);
          }}
        >
          Build Structures
        </BaseButton>
        {spaceObject.hasBuiltUnits && (
          <BaseButton
            onClick={() => {
              UIMessage.displayInfoMessage(
                "Already Built Units",
                "This planet already built units during this round. It will be able to build units again next round."
              );
            }}
            type="greyOut"
          >
            Build Units
          </BaseButton>
        )}
        {!spaceObject.hasBuiltUnits && (
          <BaseButton
            onClick={() => {
              ActivationCA.initialize_STEP_ACTIVATION_BUILD(spaceObject);
            }}
          >
            Build Units
          </BaseButton>
        )}
        <BaseButton
          onClick={() => {
            ActivationCA.initialize_STEP_ACTIVATION_TRANSFER(spaceObject);
          }}
        >
          Transfer
        </BaseButton>
        <BaseButton
          onClick={() => {
            ActivationCA.initialize_STEP_ACTIVATION_LIFT(spaceObject);
          }}
        >
          Lift
        </BaseButton>
        <BaseButton
          onClick={() => {
            ActivationCA.initialize_STEP_ACTIVATION_DROP(spaceObject);
          }}
        >
          Drop
        </BaseButton>
      </span>
    );
  };

  const DisplayWillBeInvade = () => {
    if (
      showInvasion &&
      Planet.isFactionInvading(spaceObject, playerData.faction.name)
    ) {
      return (
        <div
          style={{
            color: "red",
            backgroundColor: Color.COLOR_LOWLIGHT,
            textAlign: "center",
          }}
        >
          Will be invaded
        </div>
      );
    }
  };

  const Display2ColumnView = ({ planet }) => {
    return (
      <div
        //className={"div-hor-center"}
        style={{
          fontSize: "1em",
          display: "flex",
          //justifyContent: "center",
          alignItems: "center",
          //backgroundColor: "grey",
        }}
      >
        <div
          //className="div-fill"
          style={{ width: "20%", margin: "1em" }}
          /*onClick={() => {
            consultPlanet();
          }}*/
        >
          <SpaceObjectImageView
            spaceObject={spaceObject}
          ></SpaceObjectImageView>
        </div>
        <div
          style={{
            //display: "flex",
            //flexDirection: "column",
            textAlign: "left",
            fontSize: "1em",
            lineHeight: "1",
            //backgroundColor: "yellow",
          }}
        >
          <div
            style={
              {
                //display: "flex",
                //alignItems: "center", // Align items vertically within the div
              }
            }
          >
            {showButtonPlanetName && (
              <div>
                <BaseButton
                  onClick={() => {
                    Popup.addLayer({
                      name: "ManageObjectView",
                      spaceObject: spaceObject,
                    });
                  }}
                >
                  {spaceObject.name}
                </BaseButton>
              </div>
            )}
            {showGeneralInfo && (
              <DisplayPlanetGeneralInfo
                planet={spaceObject}
              ></DisplayPlanetGeneralInfo>
            )}
            {showPopRepartition && (
              <DisplayPopRepartition
                planet={spaceObject}
              ></DisplayPopRepartition>
            )}

            <div>
              <span>
                {showStock && (
                  <DisplayStock planet={spaceObject}></DisplayStock>
                )}
              </span>
            </div>
          </div>
          <div>
            {showFleet && <DisplayFleets planet={spaceObject}></DisplayFleets>}
          </div>
          <div>
            {showLogs && <DisplayLogs planet={spaceObject}></DisplayLogs>}

            <DisplayWillBeInvade></DisplayWillBeInvade>
          </div>

          <div> {children}</div>
        </div>
      </div>
    );
  };

  //const DisplayObjectInformation

  const planetFleet = SpaceObject.getFleet(spaceObject);

  return (
    <div>
      <div>
        <Display2ColumnView></Display2ColumnView>
      </div>
      <div style={{ textAlign: "center" }}>
        {activation && <DisplayActivationButton></DisplayActivationButton>}
      </div>

      {showPlanetParameter && (
        <DisplayPlanetParameters planet={spaceObject}></DisplayPlanetParameters>
      )}
      {showSize && <DisplaySize></DisplaySize>}
    </div>
  );
};

export default PlanetViewActivation;
