const AbilityCard = require("../Ability/AbilityCard");
const ActivePassiveAbility = require("../Ability/ActivePassiveAbility");

class PlayerEffect {
  static ETHERAL_ETHERAL_HERO_FORM = "Ethereal Hero Form";
  static ETHERAL_ETHERAL_CONNECTION = "Ethereal Connection";

  static getAbilityCardDesc(ablityCard) {
    switch (ablityCard.name) {
      case this.ETHERAL_ETHERAL_HERO_FORM:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Your infantries have +2 max HP for this round.",
            false
          ),
        ]);
      case this.ETHERAL_ETHERAL_CONNECTION:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Until the end of the round, your infantries have +0.2 damage.",
            false
          ),
        ]);

      default:
        throw new Error("Ability card name not found for faction ability");
    }
  }

  //Effects
  static hasEffect(object, effectName) {
    if (!object.effects) return false;
    return object.effects.some((effect) => effect.name === effectName);
  }

  static applyEffect(object, effectName) {
    if (!this.hasEffect(object, effectName)) {
      if (!object.effect) object.effects = [];
      object.effects.push(
        AbilityCard.create(effectName, AbilityCard.TYPE_EFFECT)
      );
    }
  }

  static removeEffect(object, effectName) {
    if (!object.effects) return;
    object.effects = object.effect.filter(
      (effect) => effect.name !== effectName
    );
  }

  static resetAllEffects(object) {
    object.effects = [];
  }

  static getEffectList(object) {
    return object.effects;
  }

  static getName(effect) {
    return effect.name;
  }
}

module.exports = PlayerEffect;
