const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../../GameData/Connection/LogMessage.js");
const SystemAction = require("../ActionCommon/SystemAction.js");
const ValidAction = require("../ActionCommon/WarningAction.js");
const WarningAction = require("../ActionCommon/WarningAction.js");
const Cost = require("../Utils/Cost.js");
const LogBook = require("../Connection/LogBook.js");
const LogAttachment = require("../Connection/LogAttachment.js");
const CheckAction = require("../ActionCommon/CheckAction.js");

class ActivationData {
  //Activation Functions
  static activateSystem(playerData, system) {
    playerData.activationData = {
      isASystemActive: true,
      systemCoords: { x: system.x, y: system.y },
      systemName: system.name,
      moveStep: {
        systemsFrom: [],
      },
    };
    System.activateSystem(system);
  }

  static getActiveSystem(playerData) {
    if (!playerData.activationData || !playerData.activationData.systemCoords) {
      return null;
    }
    return Map.getSystemFromCoords(
      playerData.map,
      playerData.activationData.systemCoords.x,
      playerData.activationData.systemCoords.y
    );
  }

  static deactivateSystem(playerData) {
    const activeSystem = this.getActiveSystem(playerData);
    if (activeSystem) {
      System.deactivateSystem(activeSystem);
    }
    playerData.activationData = null;
  }

  static isASystemActivated(playerData) {
    if (playerData.activationData && playerData.activationData.isASystemActive)
      return true;
    return false;
  }

  static isActiveSystem(playerData, system) {
    if (System.areSystemsTheSame(this.getActiveSystem(playerData), system))
      return true;
    return false;
  }

  //Move functions

  static errorMoveDataMissing(playerData) {
    if (!playerData.activationData) {
      throw new Error("ActivationData : No activationData");
    }
    if (!playerData.activationData.moveStep) {
      throw new Error("ActivationData : No activationData.moveStep");
    }
    if (!playerData.activationData.moveStep.systemsFrom) {
      throw new Error(
        "ActivationData : No activationData.moveStep.systemsFrom"
      );
    }
    return true;
  }

  static prepareMoveStep(playerData) {
    Map.getSystemsWhereFactionHasShips(
      playerData.map,
      playerData.faction.name
    ).forEach((system) => {
      try {
        ActivationData.addSystemFrom(playerData, system);
      } catch (e) {}
    });
  }

  static addSystemFrom(playerData, system) {
    if (System.areSystemsTheSame(this.getActiveSystem(playerData), system)) {
      throw new Error(
        "You did select the active system to move units from. There is no need, as the move step has for goal to move units to the active system. Units in the active systems are already there."
      );
    }

    //Chekc if system already there
    const systemsFrom = playerData.activationData.moveStep.systemsFrom;
    for (let i = 0; i < systemsFrom.length; i++) {
      if (
        system.x === systemsFrom[i].coords.x &&
        system.y === systemsFrom[i].coords.y
      ) {
        throw new Error(
          "You already selected this system to move units from. You can't select it twice."
        );
      }
    }

    systemsFrom.push({
      coords: { x: system.x, y: system.y },
      fleet: Fleet.createFleet(playerData, playerData.faction.name),
      unitSelectionArray: Fleet.getUnitSelectionArray(
        System.getFleet(system, playerData.faction.name),
        [Fleet.UNIT_CLASS_SHIP, Fleet.UNIT_CLASS_GROUND_FORCE]
      ),
      distance: Map.getMoveDistanceBetweenSystems(
        system,
        ActivationData.getActiveSystem(playerData),
        playerData.map
      ),
      planetsFrom: [],
      collapsed: true,
    });

    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const planetFrom = {
        planetName: planet.name,
        fleet: Fleet.createFleet(playerData, playerData.faction.name),
        collapsed: true,
      };
      systemsFrom[systemsFrom.length - 1].planetsFrom.push(planetFrom);
    }

    console.log("systemsFrom : ", systemsFrom);
  }

  static getSystemsFromLength(playerData) {
    this.errorMoveDataMissing(playerData);
    return playerData.activationData.moveStep.systemsFrom.length;
  }

  static getSystemsFromList(playerData) {
    this.errorMoveDataMissing(playerData);
    const systemsFromArray = [];
    for (
      let i = 0;
      i < playerData.activationData.moveStep.systemsFrom.length;
      i++
    ) {
      const data = playerData.activationData.moveStep.systemsFrom[i];
      systemsFromArray.push({
        system: Map.getSystemFromCoordsObject(playerData.map, data.coords),
        fleet: data.fleet,
        unitSelectionArray: data.unitSelectionArray,
        distance: data.distance,
        collapsed: data.collapsed,
        planetsFrom: data.planetsFrom,
      });
    }
    return systemsFromArray;
  }

  static getSystemFromFromSystem(playerData, system) {
    const systemsFrom = playerData.activationData.moveStep.systemsFrom;
    for (let i = 0; i < systemsFrom.length; i++) {
      if (
        system.x === systemsFrom[i].coords.x &&
        system.y === systemsFrom[i].coords.y
      ) {
        return systemsFrom[i];
      }
    }
    return null;
  }

  static addFleetToMove(playerData, system, fleet) {
    const systemsFrom = playerData.activationData.moveStep.systemsFrom;
    for (let i; i < systemsFrom.length; i++) {
      if (
        system.x === systemsFrom[i].coords.x &&
        system.y === systemsFrom[i].coords.y
      ) {
        Fleet.addFleetToFleet(fleet, systemsFrom[i].fleet);
      }
    }
  }

  static removeFleetToMove(playerData, system, fleet) {
    const systemsFrom = playerData.activationData.moveStep.systemsFrom;
    for (let i; i < systemsFrom.length; i++) {
      if (
        system.x === systemsFrom[i].coords.x &&
        system.y === systemsFrom[i].coords.y
      ) {
        Fleet.removeFleetFromFleet(fleet, systemsFrom[i].fleet);
      }
    }
  }

  static deleteSystemFrom(playerData, system) {
    //console.log("deleteSystemFrom : ", playerData);
    //console.log("deleteSystemFrom : ", system);
    const systemsFrom = playerData.activationData.moveStep.systemsFrom;

    const indexToRemove = systemsFrom.findIndex(
      (systemI) =>
        system.x === systemI.coords.x && system.y === systemI.coords.y
    );

    if (indexToRemove === -1) {
      throw new Error(
        "Fleet: Attempting to remove a systemFrom which does not exists"
      );
    } else {
      systemsFrom.splice(indexToRemove, 1);
    }
  }

  static expandSystemFrom(playerData, system) {
    const systemFrom = this.getSystemFromFromSystem(playerData, system);
    if (systemFrom) {
      systemFrom.collapsed = !systemFrom.collapsed;
    }
  }

  static warningMove(playerData, callBack) {
    const fleetMoving = Fleet.createFleet(playerData.faction.name);
    const systemsFrom = this.getSystemsFromList(playerData);
    for (let i = 0; i < systemsFrom.length; i++) {
      const systemFrom = systemsFrom[i];
      const fleet = systemFrom.fleet;
      Fleet.addFleetToFleet(fleet, fleetMoving);
    }
    const checkSystem = JSON.parse(
      JSON.stringify(this.getActiveSystem(playerData))
    );
    System.addFleetToSystem(checkSystem, fleetMoving);

    WarningAction.warningFleetIsValid(checkSystem, playerData, callBack);
  }

  static resolveMove(playerData) {
    const moveStep = playerData.activationData.moveStep;
    const systemsFrom = this.getSystemsFromList(playerData);
    const activeSystem = this.getActiveSystem(playerData);
    const logBook = LogAttachment.getLogActivity(activeSystem);

    this.moveUnitsSystemsFrom(playerData, activeSystem, systemsFrom, logBook);

    const cost = this.getCostMove(playerData);
    PlayerData.spendCost(playerData, cost);

    //post move events
    System.repair(playerData, activeSystem);

    /*try {
      System.checkLogisticMass(playerData, activeSystem);
    } catch (e) {
      SolveFleetLimitData.prepareStep(playerData, activeSystem);
    }*/

    CheckAction.checkFleetMandatoryAction(playerData);

    /*try {
      System.checkCapacity(playerData, activeSystem);
    } catch (e) {}*/
  }

  static getCostMove(playerData) {
    const systemsFrom = this.getSystemsFromList(playerData);
    const cost = Cost.createCost({});
    const costMove = PlayerData.getCostMove(playerData);
    const costLift = PlayerData.getCostLift(playerData);
    let doesAtLeastOneUnitMove = false;

    for (let i = 0; i < systemsFrom.length; i++) {
      const systemFrom = systemsFrom[i];

      if (!Fleet.isEmpty(systemFrom.fleet)) {
        doesAtLeastOneUnitMove = true;
      }

      for (let j = 0; j < systemFrom.planetsFrom.length; j++) {
        const planetFrom = systemFrom.planetsFrom[j];
        if (!Fleet.isEmpty(planetFrom.fleet)) {
          //console.log("add cost planet ");
          Cost.addCostToCost(costLift, cost);
        }
      }
      //console.log("move cost : ", cost);
    }
    if (doesAtLeastOneUnitMove) {
      Cost.addCostToCost(costMove, cost);
    }
    return cost;
  }

  static moveUnitsSystemsFrom(playerData, targetSystem, systemsFrom, logBook) {
    if (
      !System.areSystemsTheSame(
        ActivationData.getActiveSystem(playerData),
        targetSystem
      )
    ) {
      throw new Error(
        "Cannot move units to a system which is not the active system"
      );
    }

    for (let i = 0; i < systemsFrom.length; i++) {
      const systemFrom = systemsFrom[i];

      Fleet.checkCapacity(playerData, systemFrom.fleet);

      SystemAction.moveUnitsFromToSystem(
        playerData,
        targetSystem,
        systemFrom.system,
        systemFrom.fleet,
        logBook
      );
    }
  }

  /*static unselectSystemFrom(playerData) {
    playerData.activationData.moveStep.systemFromSelected = null;
  }*/

  //Transfer Functions

  static prepareTransferStep(playerData) {
    const activeSystem = this.getActiveSystem(playerData);
    playerData.activationData.transferStep = {
      activeSystemPlanets: [],
      otherSystems: [],
      transferedTotalMineral: 0,
      transferedTotalMineralLeft: 0,
      transferedTotalPopulation: 0,
      transferedTotalPopulationLeft: 0,
    };

    const ASPlanets = System.getPlanets(activeSystem);

    const otherSystems = Map.getSystemsWhereFactionHasPlanet(
      playerData.map,
      playerData.faction.name
    ).filter((system) => !System.areSystemsTheSame(system, activeSystem));

    for (let i = 0; i < ASPlanets.length; i++) {
      playerData.activationData.transferStep.activeSystemPlanets.push({
        name: ASPlanets[i].name,
        transferedInPopulation: 0,
        transferedInMineral: 0,
      });
    }

    for (let i = 0; i < otherSystems.length; i++) {
      playerData.activationData.transferStep.otherSystems.push({
        systemName: otherSystems[i].name,
        planets: [],
      });
      const OSPlanets = System.getPlanets(otherSystems[i]);
      for (let j = 0; j < OSPlanets.length; j++) {
        playerData.activationData.transferStep.otherSystems[i].planets.push({
          name: OSPlanets[j].name,
          availablePopulation: OSPlanets[j].population,
          transferedOutPopulation: 0,
          availableMineral: OSPlanets[j].mineral,
          transferedOutMineral: 0,
        });
      }
    }
  }

  static getPlanetTransferData(playerData, planetName) {
    const activeSystemPlanets =
      playerData.activationData.transferStep.activeSystemPlanets;
    for (let i = 0; i < activeSystemPlanets.length; i++) {
      if (activeSystemPlanets[i].name === planetName) {
        return activeSystemPlanets[i];
      }
    }

    const otherSystems = playerData.activationData.transferStep.otherSystems;
    for (let i = 0; i < otherSystems.length; i++) {
      const planets = otherSystems[i].planets;
      for (let j = 0; j < planets.length; j++) {
        if (planets[j].name === planetName) {
          return planets[j];
        }
      }
    }
    return null;
  }

  static getSystemTransferData(playerData, systemName) {
    const otherSystems = playerData.activationData.transferStep.otherSystems;
    for (let i = 0; i < otherSystems.length; i++) {
      if (otherSystems[i].systemName === systemName) {
        return otherSystems[i];
      }
    }
    return null;
  }

  static getTotalTransferedPopulation(playerData) {
    return playerData.activationData.transferStep.transferedTotalPopulation;
  }

  static getTotalTransferedPopulationLeft(playerData) {
    return playerData.activationData.transferStep.transferedTotalPopulationLeft;
  }

  static getTotalTransferedMineral(playerData) {
    return playerData.activationData.transferStep.transferedTotalMineral;
  }

  static getTotalTransferedMineralLeft(playerData) {
    return playerData.activationData.transferStep.transferedTotalMineralLeft;
  }

  static transferFromPlanet(playerData, planetName, pop, mineral) {
    const planet = this.getPlanetTransferData(playerData, planetName);
    if (planet.availablePopulation < pop + planet.transferedOutPopulation) {
      return;
    }
    if (planet.availableMineral < mineral + planet.transferedOutMineral) {
      return;
    }
    if (
      pop < 0 &&
      (-pop >
        playerData.activationData.transferStep.transferedTotalPopulation ||
        -pop > planet.transferedOutPopulation)
    ) {
      return;
    }
    if (
      mineral < 0 &&
      (-mineral >
        playerData.activationData.transferStep.transferedTotalMineral ||
        -mineral > planet.transferedOutMineral)
    ) {
      return;
    }
    playerData.activationData.transferStep.transferedTotalPopulation += pop;
    playerData.activationData.transferStep.transferedTotalPopulationLeft += pop;
    playerData.activationData.transferStep.transferedTotalMineral += mineral;
    playerData.activationData.transferStep.transferedTotalMineralLeft +=
      mineral;
    planet.transferedOutMineral += mineral;
    planet.transferedOutPopulation += pop;
    Popup.touch();
    return { pop: pop, mineral: mineral };
  }

  static getTransferCargoCost(playerData, pop, mineral) {
    const cost = pop + mineral;
    const log = LogMessage.createMessage([
      {
        content:
          "Cargo cost : " + cost + " (1 cargo ship per 1 pop or 1 mineral)",
      },
    ]);
    return { value: pop + mineral, log: log };
  }

  static transferToPlanet(playerData, planetName, pop, mineral) {
    const planet = this.getPlanetTransferData(playerData, planetName);
    console.log("planet : ", planet);

    if (pop < 0 && -pop > planet.transferedInPopulation) {
      return;
    }
    if (mineral < 0 && -mineral > planet.transferedInMineral) {
      return;
    }

    planet.transferedInPopulation += pop;
    playerData.activationData.transferStep.transferedTotalPopulationLeft -= pop;
    planet.transferedInMineral += mineral;
    playerData.activationData.transferStep.transferedTotalMineralLeft -=
      mineral;
    Popup.touch();
  }

  static resolveTransfer(playerData) {
    console.log(
      "playerData.activationData.transferStep : ",
      playerData.activationData.transferStep.otherSystems.planets
    );

    console.log(
      "playerData.activationData.transferStep : ",
      playerData.activationData.transferStep
    );

    //Check that all mineral transfered from planets are valid
    const otherSystems = playerData.activationData.transferStep.otherSystems;
    let totalMineralTransfered = 0;
    let totalPopulationTransfered = 0;
    otherSystems.forEach((system) => {
      const planetsData = system.planets;
      planetsData.forEach((planetData) => {
        const planetObject = Map.getSpaceObjectFromName(
          playerData.map,
          planetData.name
        );
        if (planetData.transferedOutPopulation > planetObject.population) {
          throw new Error(
            planetObject.name +
              " has only " +
              planetObject.population +
              " population left, but you are trying to transfer " +
              planetData.transferedOutPopulation +
              " population."
          );
        }
        totalMineralTransfered =
          totalMineralTransfered + planetData.transferedOutMineral;
        if (planetData.transferedOutMineral > planetObject.mineral) {
          throw new Error(
            planetObject.name +
              " has only " +
              planetObject.mineral +
              " mineral left, but you are trying to transfer " +
              planetData.transferedOutMineral +
              " mineral."
          );
        }
        totalPopulationTransfered =
          totalPopulationTransfered + planetData.transferedOutPopulation;

        //Actual transfer
        planetObject.population =
          planetObject.population - planetData.transferedOutPopulation;
        planetObject.mineral =
          planetObject.mineral - planetData.transferedOutMineral;
      });
    });

    let totalMineralTransferedIn = 0;
    let totalPopulationTransferedIn = 0;
    //check that all resources transfered to planet are valid
    const ASPlanets =
      playerData.activationData.transferStep.activeSystemPlanets;

    for (let i = 0; i < ASPlanets.length; i++) {
      //Actual transfer
      const planetObject = Map.getSpaceObjectFromName(
        playerData.map,
        ASPlanets[i].name
      );
      planetObject.population =
        planetObject.population + ASPlanets[i].transferedInPopulation;
      planetObject.mineral =
        planetObject.mineral + ASPlanets[i].transferedInMineral;

      //Storing the sum to compare at the end if this is ok
      const planetData = ASPlanets[i];
      totalMineralTransferedIn =
        totalMineralTransferedIn + planetData.transferedInMineral;
      totalPopulationTransferedIn =
        totalPopulationTransferedIn + planetData.transferedInPopulation;

      const cargoCost = this.getTransferCargoCost(
        playerData,
        planetData.transferedInPopulation,
        planetData.transferedInMineral
      );
      PlayerData.spendCargo(playerData, cargoCost.value);
    }

    if (totalMineralTransferedIn > totalMineralTransfered) {
      throw new Error(
        "You are trying to import " +
          (totalMineralTransferedIn - totalMineralTransfered) +
          " more mineral to planets in the active system than mineral you exported from the planets outside the active system."
      );
    }
    if (totalPopulationTransferedIn > totalPopulationTransfered) {
      throw new Error(
        "You are trying to import " +
          (totalPopulationTransferedIn - totalPopulationTransfered) +
          " more population to planets in the active system than population you exported from the planets outside the active system."
      );
    }
    if (totalMineralTransferedIn < totalMineralTransfered) {
      throw new Error(
        "You exported " +
          (totalMineralTransfered - totalMineralTransferedIn) +
          " mineral from planets outside the active system which you did not imported into the planets in the active system."
      );
    }
    if (totalPopulationTransferedIn < totalPopulationTransfered) {
      throw new Error(
        "You exported " +
          (totalPopulationTransfered - totalPopulationTransferedIn) +
          " population from planets outside the active system which you did not imported into the planets in the active system."
      );
    }
    //Check that total transfer in is equal to transfered out
    if (
      totalMineralTransferedIn !== totalMineralTransfered ||
      totalPopulationTransferedIn !== totalPopulationTransfered
    ) {
      throw new Error("Total transfer in is not equal to total transfer out");
    }
  }

  //Production functions

  static createProductionData(planet) {
    return {
      planetName: planet.name,
      popOnMineral: 0,
      popOnScience: 0,
      popOnConstruction: 0,
      popUnassigned: planet.population,
      mineralProduced: planet.mineral,
      scienceProduced: 0,
      constructionProduced: 0,
    };
  }

  static prepareProductionStep(playerData) {
    const activeSystem = this.getActiveSystem(playerData);

    if (!activeSystem) {
      throw new Error("No active system found.");
    }

    const planets = System.getPlanets(activeSystem);

    const planetDataArray = planets.map((planet) => {
      return this.createProductionData(planet);
    });
    console.log("planetDataArray : ", planetDataArray);

    playerData.activationData.productionStep = {
      planetsData: planetDataArray,
    };
  }

  static getPlanetsProductionData(playerData) {
    return playerData.activationData.productionStep.planetsData;
  }

  static getPlanetProductionData(playerData, planetName) {
    const planetsData = playerData.activationData.productionStep.planetsData;
    for (let i = 0; i < planetsData.length; i++) {
      if (planetsData[i].planetName === planetName) {
        return planetsData[i];
      }
    }

    return null;
  }

  static assignPop(playerData, planetName, assignType, amount) {
    let popOnType = "";
    if (assignType === "mineral") {
      popOnType = "popOnMineral";
    }
    if (assignType === "science") {
      popOnType = "popOnScience";
    }
    if (assignType === "construction") {
      popOnType = "popOnConstruction";
    }

    const planetData = this.getPlanetProductionData(playerData, planetName);
    console.log("planetData : ", planetData);
    console.log("popOnType : ", popOnType);
    console.log("assignType : ", assignType);
    console.log("amount : ", amount);
    console.log("planetData.popUnassigned : ", planetData.popUnassigned);
    console.log("planetData[popOnType] : ", planetData[popOnType]);

    if (planetData.popUnassigned < amount) {
      return;
    }
    if (planetData[popOnType] < -amount) {
      return;
    }
    planetData.popUnassigned -= amount;
    planetData[popOnType] += amount;
  }

  //Assignement Function
  static createAssignementData(playerData, system) {
    const planets = System.getPlanets(system);

    playerData.activationData.assignementStep = {
      planets: [],
    };

    for (let i; i < planets.length; i++) {
      playerData.activationData.assignementStep.planets.push({
        name: planets[i].name,
        availablePopulation: planets[i].population,
        popOnMineral: 0,
        popOnScience: 0,
        popOnConstruction: 0,
      });
    }
  }

  static ON_POP_TYPE_MINERAL = "popOnMineral";
  static ON_POP_TYPE_SCIENCE = "popOnScience";
  static ON_POP_TYPE_CONSTRUCTION = "popOnConstruction";

  /*static assignPop(playerData, planetName, popOnType) {
    const assignementStep = playerData.activationData.assignementStep;
    const planet = assignementStep.planets.find(
      (planet) => planet.name === planetName
    );
    if (!planet) {
      throw new Error("ActivationData : assignPop : planet not found");
    }
    if (planet.availablePopulation > 0) {
      planet.availablePopulation -= 1;
      planet[popOnType] += 1;
    }
  }*/

  /*static unAssignPop(playerData, planetName, popOnType) {
    const assignementStep = playerData.activationData.assignementStep;
    const planet = assignementStep.planets.find(
      (planet) => planet.name === planetName
    );
    if (!planet) {
      throw new Error("ActivationData : unAssignPop : planet not found");
    }
    if (planet[popOnType] > 0) {
      planet.availablePopulation += 1;
      planet[popOnType] -= 1;
    }
  }*/

  //End Activation

  //Manage Planet Functions
  static preparePlanetManagementStep(playerData) {
    const activeSystem = this.getActiveSystem(playerData);

    const planetsInActiveSystem = System.getPlanetsFromFaction(
      this.getActiveSystem(playerData),
      playerData.faction.name
    );

    const ACPlanetsData = [];
    for (let i = 0; i < planetsInActiveSystem.length; i++) {
      const planet = planetsInActiveSystem[i];
      ACPlanetsData.push({
        name: planet.name,
        producedPopulation: Planet.getProducedPopulation(planet),
        producedMineral: 0,
        producedScience: 0,
        spendableMineral: planet.mineral,
        spendablePopulation: planet.population,
        spendableScience: playerData.science,
        popOnMineral: 0,
        popOnScience: 0,
        fleetToBuild: [],
      });
    }

    const otherSystems = Map.getSystemsWhereFactionHasPlanet(
      playerData.map,
      playerData.faction.name
    ).filter((system) => !System.areSystemsTheSame(system, activeSystem));

    const otherSystemNotActivated = [];
    const otherSystemActivated = [];
    for (let system of otherSystems) {
      if (!System.areSystemsTheSame(activeSystem, system)) {
        const systemData = {
          name: system.name,
          planetsData: [],
        };
        const planets = System.getPlanets(system);
        for (let planet of planets) {
          systemData.planetsData.push({
            name: planet.name,
          });
        }
        if (system.hasBeenActivated) {
          otherSystemActivated.push(systemData);
        } else {
          otherSystemNotActivated.push(systemData);
        }
      }
    }
  }

  static endActivation(playerData) {
    const system = this.getActiveSystem(playerData);
    system.isActiveSystem = false;
    playerData.activationData.isASystemActive = false;
    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      Planet.capPopulation(playerData, planet);
    }
  }
}

module.exports = ActivationData;
