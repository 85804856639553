const Fleet = require("../MapData/Fleet");
const Planet = require("../MapData/Planet");
const TechList = require("../Technology/TechList");
const TechTree = require("../Technology/TechTree");
const CustomMath = require("../../../Common/Math/CustomMath");
const System = require("../MapData/System");
const SolveCapacityData = require("../MandatoryAction/SolveCapacityData");
const SolveFleetLimitData = require("../MandatoryAction/SolveFleetLimitData");
const Unit = require("../MapData/Unit");
const Curiosity = require("../MapData/Curiosity");
const MapUnitaryAction = require("./MapUnitaryAction");

class ServiceUnitaryAction {
  //This class does not include unitary action but act as a service layer, facilitating the use of unitary actions

  static createUnitOnPlanet(playerData, planet, unitType, factionName = null) {
    if (!factionName) {
      factionName = playerData.faction.name;
    }
    const unit = Unit.createUnit(playerData, factionName, unitType);
    MapUnitaryAction.placeUnitsOnPlanet(playerData, planet, [unit]);
  }

  static createUnitOnPlanetControlled(playerData, planet, unitType) {
    if (planet.faction !== playerData.faction.name) {
      throw new Error("You do not control the planet " + planet.name + ".");
    }
    this.createUnitOnPlanet(playerData, planet, unitType);
  }
}

module.exports = ServiceUnitaryAction;
