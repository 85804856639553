import React from "react";
import UnitLineView from "./UnitLineView";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import SelectionListView from "../Utils/Layout/SelectionListView";

const Fleet = require("../../Data/GameData/MapData/Fleet");

const UnitinFLeetSelectionView = ({ fleetItems, fleetSelected, faction }) => {
  const unitItems = Fleet.getUnits(fleetItems);
  const selectedUnitItems = Fleet.getUnits(fleetSelected);

  return (
    <div style={{ fontSize: "1em" }}>
      <SelectionListView
        items={unitItems}
        displayItem={(item) => {
          return (
            <UnitLineView unit={item} factionName={faction}></UnitLineView>
          );
        }}
        selectedItems={selectedUnitItems}
        selectItem={(item) => {
          Fleet.addUnit(fleetSelected, item);
          Popup.touch();
        }}
        unSelectItem={(item) => {
          Fleet.removeUnit(fleetSelected, item);
          Popup.touch();
        }}
      ></SelectionListView>
    </div>
  );
};

export default UnitinFLeetSelectionView;
