import React, { useState } from "react";
import { GameListView } from "../GameList/GameList";
import TestSimpleComponent from "../Test/TestSimpleComponent";
import StaticGameData from "../Data/GameData/StaticGameData";
import GameGlobalLayout from "../GameView/GameGlobalLayout";
import NavigationView from "../GameView/NavigationView";
import MenuNavigationView from "./MenuNavigationView";
import BaseButton from "../GameView/Utils/BaseButton";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import Popup from "../Data/Other/Popup";

export const OptionView = () => {
  return (
    <div>
      <h1>Option</h1>
      <div>
        This screen will allow you to configure your game settings. Will be
        available soon.
      </div>
    </div>
  );
};

export default OptionView;
