class Phase {
  //Chose an action. Mostly an activation of a system
  static PHASE_SELECT_ACTION = "Select Action";
  static STEP_SELECT_ACTION = "Select Action";

  //Start of game phases
  static PHASE_START_OF_GAME = "Start of game";
  static STEP_START_OF_GAME_PICK_FACTION = "Pick Faction";
  static STEP_START_OF_GAME_COLONIZE = "colonize";

  //Activation phase
  static PHASE_ACTIVATION = "Activation";
  static STEP_ACTIVATION_ACTIVATED = "Activation";
  static STEP_MODIFY_FLEET_LIMIT = "Modify Fleet Limit";
  static STEP_ACTIVATION_MOVE = "Move";
  static STEP_ACTIVATION_LIFT = "Lift";
  static STEP_ACTIVATION_DROP = "Drop";
  static STEP_ACTIVATION_INVASION = "Invasion";
  static STEP_ACTIVATION_TRANSFER = "transfer";
  static STEP_ACTIVATION_ASSIGNEMENT = "Assignement";
  static STEP_ACTIVATION_STRUCTURE = "Build Structure";
  static STEP_ACTIVATION_BUILD = "Build Units";
  static STEP_ACTIVATION_PRODUCTION = "Production";
  static STEP_ACTIVATION_PLANET_MANAGEMENT = "Planet Management";
  static STEP_ACTIVATION_END_ACTIVATION = "End Activation";
  static STEP_PROPOSE_TRANSACTION = "Propose Transaction";
  static STEP_ACCEPT_TRANSACTION = "Accept Transaction";
  static STEP_REJECT_TRANSACTION = "Reject Transaction";
  static STEP_DRAFT_ACTION = "Draft Action";
  static STEP_SELECT_OBJECTIVE = "Select Objective";
  static STEP_VOTE_ACTION = "Vote Action";

  //Action phase
  static PHASE_ACTION = "Action";
  static STEP_ACTION_MOVE = "Move";
  static STEP_ACTION_PRODUCE = "Produce";
  static STEP_ACTION_LIFT = "Lift";
  static STEP_ACTION_DROP = "Drop";
  static STEP_ACTION_INVASION = "Invasion";
  static STEP_ACTION_TRANSFER = "Transfer";
  static STEP_ACTION_BUILD_STRUCTURE = "Build Structure";
  static STEP_ACTION_BUILD_UNIT = "Build Units";
  static STEP_ACTION_BUY_RESOURCES = "Buy Resources";
  static STEP_ACTION_RESEARCH_TECH = "Research Tech";

  //Admin phase
  static STEP_ADMIN = "Admin";

  //Combat phase
  static PHASE_EORR = "Review end of round";
  static STEP_EORR = "Review end of round";

  //Mandatory Actions
  static PHASE_MANDATORY_ACTION = "Mandatory Action";
  static STEP_MANDATORY_FLEET_LIMIT = "Solve Fleet Limit";
  static STEP_MANDATORY_FLEET_CAPACITY = "Solve Fleet Capacity";

  //Minor factions
  static STEP_MINOR_FACTION_ABILITY = "Minor Faction Ability";
  static STEP_ITEM_ABILITY = "Item Ability";
  static STEP_MINOR_FACTION_MISSION = "Minor Faction Mission";
  static STEP_OBJECTIVE_SCORE = "Score Objective";

  //FactionAbilities
  static STEP_FACTION_ABILITY = "Faction Ability";

  //End of game
  static PHASE_END_OF_GAME = "End of game";
  static STEP_END_OF_GAME = "End of game";
}

module.exports = Phase;
