import React, { useState, useEffect } from "react";
import GameListItem from "./GameListItem";
import Game from "../Data/GameData/Game/Game";
import StaticGameData from "../Data/GameData/StaticGameData";
import BaseButton from "../GameView/Utils/BaseButton";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import Popup from "../Data/Other/Popup";
import { CreateCustomGame } from "./CreateCustomGame";
import Logo from "../GameView/Utils/Logo";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import DiscordLinkView from "../MainMenu/DiscordLinkView";
import GameGlobalLayout from "../GameView/GameGlobalLayout";
import MenuNavigationView from "../MainMenu/MenuNavigationView";
import BaseInput from "../GameView/Utils/BaseInput";

export const CustomGameList = ({ setRouteMainHandler }) => {
  const [games, setGames] = useState([]);

  const [refreshCounter, setRefreshCounter] = useState(0);
  const [searchId, setSearchId] = useState("");

  const fetchGames = async () => {
    console.log("Fetching games...");
    try {
      Popup.setClientWaitingServer(true);
      let gameListTypeR = "CustomGames";

      const response = await fetch("/api/list-games", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameListType: gameListTypeR,
        }),
      });
      Popup.setClientWaitingServer(false);
      if (response.ok) {
        const result = await response.json();
        setGames(result.listOfGames); // Update the state with the received game data
      } else {
        console.error("Failed to fetch games:", response.statusText);
      }
    } catch (error) {
      Popup.setClientWaitingServer(false);
      console.error("Error fetching games:", error);
    }
  };

  useEffect(() => {
    fetchGames();
  }, [refreshCounter]);

  const handleJoinGame = async () => {
    console.debug("Joining game...");
    try {
      // Send a request to "/api/join-game"
      const response = await fetch("/api/join-game", {
        method: "GET",
      });

      if (response.ok) {
        console.log("Waiting for server response...");
        // The request was successful
        const data = await response.json();
        //console.log("VWA 2");
        console.log("Joined game successfully:", data);
        // Handle the response data as needed, such as updating the game list
        fetchGames();
      } else {
        // The request failed with an error
        console.error("Failed to join game:", response.statusText);
      }
    } catch (error) {
      console.error("Error joining game:", error);
    }
  };

  const DisplayNewGamePopup = () => {
    return (
      <div>
        <BaseButton
          onClick={() => {
            Popup.deActivateBlackPopup();
            Popup.addDynamicLayer(<CreateCustomGame></CreateCustomGame>);
          }}
        >
          Create custom game
        </BaseButton>
        <BaseButton
          onClick={() => {
            /*Popup.activateBlackPopup(
              <DisplayJoinRankedGamePopup></DisplayJoinRankedGamePopup>
            );*/
            UIMessage.displayInfoMessageToStaticGameData(
              "Ranked Game",
              "Ranked games will be available soon. This will automatically transport you in a game with other players. Your ranking will be affected by the outcome of the game."
            );
          }}
        >
          Join ranked game
        </BaseButton>
      </div>
    );
  };

  const DisplayJoinRankedGamePopup = () => {
    return (
      <div>
        <BaseButton>Join ranked game with draft</BaseButton>
        <BaseButton
          onClick={() => {
            handleJoinGame();
            Popup.deActivateBlackPopup();
          }}
        >
          Join ranked game with no draft
        </BaseButton>
      </div>
    );
  };

  const handleJoinTutorial = async () => {
    console.debug("Joining Tutorial...");
    try {
      // Send a request to "/api/join-game"
      const response = await fetch("/api/join-tutorial", {
        method: "GET",
      });

      if (response.ok) {
        console.log("Waiting for server response...");
        // The request was successful
        const data = await response.json();
        //console.log("VWA 2");
        console.log("Joined tutorial successfully:", data);
        // Handle the response data as needed, such as updating the game list
        fetchGames();
      } else {
        // The request failed with an error
        console.error("Failed to join tutorial:", response.statusText);
      }
    } catch (error) {
      console.error("Error joining tutorial:", error);
    }
  };

  useEffect(() => {
    // Fetch the list of games from the server and update the state

    fetchGames();
  }, []); // Run this effect only once when the component mounts

  const navigationData = {
    buttonsData: [
      {
        text: "Back",
        callback: () => {
          Popup.goBack();
        },
      },
    ],
  };
  return (
    <GameGlobalLayout showTitle={false} backgroundType="uniform">
      <div className="text-box-center">
        <div style={{}}>
          {true && (
            <div style={{ fontSize: "1.5em" }}>
              {" "}
              Custom Games
              <LineSpace></LineSpace>
            </div>
          )}

          <div>
            {/* Button to create a new game */}

            <div
              style={
                {
                  /*display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",*/
                }
              }
            >
              <span style={{ fontSize: "1.5em" }}>Id : </span>
              <BaseInput
                value={searchId}
                onChange={(e) => {
                  const input = e.target.value;
                  setSearchId(input);
                }}
                width={"4em"}
              ></BaseInput>
              <span> </span>
              <BaseButton
                onClick={() => {
                  setRefreshCounter(refreshCounter + 1);
                }}
              >
                <Logo logoName="refresh icon"></Logo>
              </BaseButton>
            </div>

            <LineSpace></LineSpace>
            {false && (
              <div>
                <button
                  onClick={handleJoinTutorial}
                  style={{ marginRight: "2em" }}
                >
                  Join Tutorial
                </button>
                <button onClick={handleJoinGame}>Join Game</button>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "0.5em",
              justifyContent: "center",
            }}
          >
            {/* Render the list of games */}
            {games
              .filter((game) => {
                if (searchId === "") {
                  return true;
                } else {
                  return game.gameId.toString().includes(searchId);
                }
              })
              .reverse()
              .map((game) => {
                return (
                  <div key={game.gameId}>
                    <BaseButton>
                      <div>
                        {" "}
                        <GameListItem
                          game={game}
                          setRouteMainHandler={setRouteMainHandler}
                        >
                          {" "}
                        </GameListItem>
                      </div>{" "}
                    </BaseButton>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <MenuNavigationView navigationData={navigationData}></MenuNavigationView>
    </GameGlobalLayout>
  );
};
