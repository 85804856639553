import React from "react";
import Popup from "../../Data/Other/Popup";
import RulePage from "../RulePage";
import Color from "../../Common/Config/Colors";

let getPageFunction = null;

export const setPageResolver = (resolver) => {
  getPageFunction = resolver;
};

const RuleLink = ({ name, children }) => {
  if (!getPageFunction) {
    throw new Error(
      "Page resolver is not set. Make sure to call setPageResolver."
    );
  }

  const page = getPageFunction(name);

  if (!page) {
    throw new Error(`Page not found: ${name}`);
  }

  return (
    <span
      style={{ color: Color.COLOR_CYAN }}
      onClick={() => {
        Popup.addDynamicLayer(
          <RulePage data={getPageFunction(name)}></RulePage>
        );
      }}
    >
      {" "}
      {children}
    </span>
  );
};

export default RuleLink;
