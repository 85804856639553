const Popup = require("../../Other/Popup.js");
const Phase = require("../Game/Phase.js");
const Request = require("../../../Common/Requests/Request.js");

class ActionCommonData {
  static prepare(playerData, phase, step, data, goToActionGLobalView = false) {
    playerData.phase = phase;
    playerData.step = step;
    playerData.actionData = data;
    if (goToActionGLobalView) Popup.addLayer({ name: "ActionGlobalView" });
  }

  static cleanActionData(playerData) {
    playerData.phase = Phase.PHASE_SELECT_ACTION;
    playerData.step = Phase.STEP_SELECT_ACTION;
    playerData.actionData = null;
  }

  static getData(playerData) {
    return playerData.actionData;
  }

  static async resolveClient(playerData, updateData = null, phase, step) {
    if (phase && step) {
      playerData.phase = phase;
      playerData.step = step;
    }

    if (updateData) {
      await Request.updateGameState(updateData);
    } else {
      const resolveData = {
        actionData: playerData.actionData,
      };
      await Request.updateGameState(resolveData);
    }

    return;
  }

  static resolveServerIni(playerData, resolveData) {
    playerData.actionData = resolveData.actionData;
  }
}

module.exports = ActionCommonData;
