import { useEffect } from "react";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import Fleet from "../../Data/GameData/MapData/Fleet";
import Planet from "../../Data/GameData/MapData/Planet";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import FleetLineView from "../Object/FleetLineView";
import PlanetView from "../Object/PlanetView";
import SpaceObjectImageView from "../Object/SpaceObjectImageView";
import UnitSelectionView from "../Units/UnitSelectionView";
import BaseButton from "../Utils/BaseButton";
import LogBook from "../../Data/GameData/Connection/LogBook";
import Color from "../../Common/Config/Colors";
import TechTypeView from "../Technology/TechTypeView";
import Logo from "../Utils/Logo";
import TechTree from "../../Data/GameData/Technology/TechTree";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject";
import MinorFaction from "../../Data/GameData/MinorFaction/MinorFaction";
import Item from "../../Data/GameData/Transactions/Item";
import ItemDetailedView from "../Transaction/ItemDetailedView";
import ItemView from "../Transaction/ItemView";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";

const {
  default: SelectionListView,
} = require("../Utils/Layout/SelectionListView");
const System = require("../../Data/GameData/MapData/System");

class InteractionFunctions {
  static layoutMapData = {};

  // OBSOLETE
  static selectPlanetsInSystem = ({
    //amountPlanets,
    selectedPlanets,
    system,
    callback,
    addPlanet = (item) => {
      selectedPlanets.push(item);
    },
    removePlanet = (item) => {
      selectedPlanets.splice(selectedPlanets.indexOf(item), 1);
    },
  }) => {
    const planets = System.getPlanets(system);

    const displayPlanet = (item) => {
      return <PlanetView planet={item}></PlanetView>;
    };

    const navigationData = {
      //taskBarData: { text: "test" },

      buttonsData: [
        {
          text: "Ok",
          callback: () => {
            Popup.goBack();
            callback(selectedPlanets);
          },
        },
      ],
    };

    const displayComponent = () => {
      return (
        <GameGlobalLayout title={null} backgroundType="shadow">
          <div
            style={{
              height: "100%",
            }}
          >
            <SelectionListView
              items={planets}
              displayItem={displayPlanet}
              selectedItems={selectedPlanets}
              selectItem={addPlanet}
              unSelectItem={removePlanet}
            ></SelectionListView>
          </div>
          <NavigationView
            navigationData={navigationData}
            screenData={{ system: system }}
          ></NavigationView>
        </GameGlobalLayout>
      );
    };

    Popup.activateBlackPopup({ displayComponent: displayComponent });
  };

  static selectPlanetsOnMap = (
    amountMin,
    amountMax,
    callback,
    differentPlanets = true
  ) => {
    const selectedPlanets = [];

    const generateLogBook = () => {
      const logBook = LogBook.createLogBook();
      const planetNames = selectedPlanets.map((planet) => planet.name);
      LogBook.generateAddMessage(
        logBook,
        "Selected planets : " + planetNames.join(", ")
      );
      return logBook;
    };

    const navigationData = {
      taskBarData: {
        generateLogBook: generateLogBook,
      },
      buttonsData: [
        {
          text: "Cancel",
          callback: () => {
            Popup.resetMapNavigationData();
            Popup.unStackToMap();
          },
        },
        {
          text: "Confirm",
          callback: () => {
            if (selectedPlanets.length < amountMin) {
              UIMessage.displayInfoMessage(
                "Not enough planets selected",
                "You need to select at least " + amountMin + " planets."
              );
              return;
            } else {
              const planetNames = selectedPlanets.map((planet) => planet.name);
              UIMessage.displayConfirmMessage(
                "Confirm selection",
                "Do you confirm selecting " + planetNames.join(", ") + " ?",
                () => {
                  Popup.resetMapNavigationData();
                  Popup.unStackToMap();
                  callback(selectedPlanets);
                }
              );
            }
          },
        },
      ],
    };

    const addPlanet = (planet) => {
      if (selectedPlanets.length >= amountMax) {
        UIMessage.displayInfoMessage(
          "Not more than " + amountMax + " planets.",
          "You can't select more planets than " + amountMax + " planets."
        );
        return;
      }

      //If different planets
      if (differentPlanets) {
        if (selectedPlanets.includes(planet)) {
          UIMessage.displayInfoMessage(
            "Planet already selected",
            "You already selected this planet."
          );
          return;
        }
      }
      selectedPlanets.push(planet);
      navigationData.taskBarData.generateLogBook = generateLogBook;
    };
    const removePlanet = (planet) => {
      selectedPlanets.splice(selectedPlanets.indexOf(planet), 1);
      navigationData.taskBarData.generateLogBook = generateLogBook;
    };

    Popup.setMapNavigationData(navigationData);
    Popup.setMapClickCallback((planet) => {
      if (planet.isPlanet) {
        addPlanet(planet);
      }
      /*this.selectPlanetsInSystem({
          //amountPlanets: amount,
          selectedPlanets: selectedPlanets,
          system: system,
          callback: () => {},
          //navigationData: navigationData,
          addPlanet: addPlanet,
          removePlanet: removePlanet,
        });*/
    }, "planet");

    Popup.unStackToMap();
  };

  static selectUnitsInSystem = ({
    selectedUnits,
    system,
    callback,
    onUpdate = () => {},
    filter = null,
    addUnit = (item) => {
      selectedUnits.push(item);
    },
    removeUnit = (item) => {
      selectedUnits.splice(selectedUnits.indexOf(item), 1);
    },
  }) => {
    const playerData = StaticGameData.getPlayerData();

    const planets = System.getPlanets(system);

    const displayPlanet = (item) => {
      return <PlanetView planet={item}></PlanetView>;
    };

    const spaceFleet = System.getFleet(system, playerData.faction.name);
    const spaceUnits = Fleet.getUnits(spaceFleet).filter((unit) => {
      if (filter) {
        if (filter.classesIncluded) {
          return filter.classesIncluded.includes(unit.class);
        }
        return true;
      }
      return true;
    });

    const DisplayContentComponent = () => {
      return (
        <div style={{ textAlign: "center" }}>
          <div>Space Area :</div>
          <UnitSelectionView
            units={spaceUnits}
            selectedUnits={selectedUnits}
            faction={playerData.faction.name}
            onUpdate={onUpdate}
          ></UnitSelectionView>
          {planets.map((planet, index) => {
            const planetFleet = Planet.getFleetOrCreate(
              playerData,
              planet,
              playerData.faction.name
            );
            let planetUnits = Fleet.getUnits(planetFleet).filter((unit) => {
              if (filter) {
                if (filter.classesIncluded) {
                  return filter.classesIncluded.includes(unit.class);
                }
                return true;
              }
              return true;
            });
            return (
              <div key={index} style={{ margin: "0.5em" }}>
                <div>{planet.name}</div>
                <div>
                  <SpaceObjectImageView
                    showFleet={false}
                    spaceObject={planet}
                    maxHeight={"3em"}
                  ></SpaceObjectImageView>{" "}
                </div>
                <div>
                  <UnitSelectionView
                    units={planetUnits}
                    selectedUnits={selectedUnits}
                    faction={playerData.faction.name}
                    onUpdate={onUpdate}
                  ></UnitSelectionView>
                </div>
              </div>
            );
          })}
        </div>
      );
    };

    const displayComponent = () => {
      return (
        <div>
          {" "}
          <div
            style={{
              height: "100%",
            }}
          >
            <DisplayContentComponent></DisplayContentComponent>
          </div>
          <div>
            <BaseButton
              onClick={() => {
                Popup.deActivateBlackPopup(displayComponent());
                callback(selectedUnits);
              }}
            >
              Ok
            </BaseButton>
          </div>
        </div>
      );
    };

    Popup.activateBlackPopup(displayComponent());
  };

  static selectUnitsOnMap = (
    callback,
    text = null,
    filter = { fromOnlyOneSystem: false, classesIncluded: null }
  ) => {
    const selectedUnits = [];
    const playerData = StaticGameData.getPlayerData();

    const displayTaskBarContent = () => {
      const fleet = Fleet.createFleet(playerData, playerData.faction.name);
      Fleet.addUnits(fleet, selectedUnits);
      return (
        <span>
          {text && <span>{text} </span>}
          <span>Currently selected : </span>
          <FleetLineView fleet={fleet} size="1.5em"></FleetLineView>
        </span>
      );
    };

    const navigationData = {
      taskBarData: {
        content: displayTaskBarContent(),
      },
      buttonsData: [
        {
          text: "Cancel",
          callback: () => {
            Popup.resetMapNavigationData();
            Popup.unStackToMap();
          },
        },
      ],
    };
    if (!filter.fromOnlyOneSystem) {
      navigationData.buttonsData.push({
        text: "Confirm",
        callback: () => {
          callback(selectedUnits);
        },
      });
    }

    const onUpdate = () => {
      navigationData.taskBarData.content = displayTaskBarContent();
    };

    const onValidateInOneSystem = () => {
      if (filter.fromOnlyOneSystem && selectedUnits.length > 0) {
        callback(selectedUnits);
      }
    };

    /*useEffect(() => {
      console.log("selectedUnits : ", selectedUnits);
    }, [selectedUnits.length]);*/

    /*const addUnit = (unit) => {
      selectedUnits.push(unit);
      navigationData.taskBarData.content = displayTaskBarContent();
    };
    const removeUnit = (unit) => {
      selectedUnits.splice(selectedUnits.indexOf(unit), 1);
      navigationData.taskBarData.content = displayTaskBarContent();
    };*/

    Popup.setMapNavigationData(navigationData);
    Popup.setMapClickCallback((system) => {
      this.selectUnitsInSystem({
        //amountPlanets: amount,
        selectedUnits: selectedUnits,
        system: system,
        onUpdate: onUpdate,
        callback: onValidateInOneSystem,
        filter: filter,
        //navigationData: navigationData,
        //addUnit: addUnit,
        //removeUnit: removeUnit,
      });
    });

    Popup.unStackToMap();
  };

  static selectASystem = (
    callback,
    text = null,
    component = null,
    cancelCallback = null,
    askConfirm = true
  ) => {
    let selectedSystem = null;
    const playerData = StaticGameData.getPlayerData();

    const displayTaskBarContent = () => {
      return (
        <span>
          {text && <span>{text} </span>}
          {component && <span>{component}</span>}
          System selected :{selectedSystem ? selectedSystem.name : "?"}
        </span>
      );
    };

    const navigationData = {
      taskBarData: {
        content: displayTaskBarContent(),
      },
      buttonsData: [
        {
          text: "Cancel",
          callback: () => {
            Popup.resetMapNavigationData();
            Popup.unStackToMap();

            if (cancelCallback) cancelCallback();
          },
        },
      ],
    };

    const onUpdate = () => {
      navigationData.taskBarData.content = displayTaskBarContent();
    };

    Popup.setMapNavigationData(navigationData);
    Popup.setMapClickCallback((system) => {
      selectedSystem = system;
      onUpdate();

      if (askConfirm) {
        UIMessage.displayConfirmMessage(
          selectedSystem.name + " selected",
          "Do you confirm selecting " + selectedSystem.name + " ?",
          () => {
            callback(selectedSystem);
          }
        );
      } else {
        callback(selectedSystem);
      }
    });

    Popup.unStackToMap();
  };

  static selectAPlanet = (
    callback,
    text = "Select a planet.",
    component = null,
    cancelCallback,
    askForConfirmation = true
  ) => {
    let selectedSystem = null;
    let selectedPlanet = null;
    const playerData = StaticGameData.getPlayerData();

    const displayTaskBarContent = () => {
      return (
        <span>
          {text && <span>{text} </span>}
          {component && <span>{component}</span>}
          Planet selected :{selectedPlanet ? selectedPlanet.name : "?"}
        </span>
      );
    };

    const navigationData = {
      taskBarData: {
        content: displayTaskBarContent(),
      },
      buttonsData: [
        {
          text: "Cancel",
          callback: () => {
            if (cancelCallback) {
              cancelCallback();
            }
            Popup.resetMapNavigationData();
            Popup.unStackToMap();
          },
        },
      ],
    };

    Popup.setMapNavigationData(navigationData);
    Popup.setMapClickCallback((planet) => {
      if (planet.isPlanet) {
        if (askForConfirmation) {
          UIMessage.displayConfirmMessage(
            planet.name + " selected",
            "Do you confirm selecting " + planet.name + " ?",
            () => {
              Popup.resetMapNavigationData();
              callback(planet);
            }
          );
        } else {
          Popup.resetMapNavigationData();
          callback(planet);
        }
      }
    }, "planet");

    Popup.unStackToMap();
  };

  static selectAPlanetInASystem = (system, callback) => {
    const planets = System.getPlanets(system);

    Popup.activateBlackPopup(
      <div>
        {planets.map((planet, index) => {
          return (
            <div key={index}>
              <BaseButton
                onClick={() => {
                  Popup.deActivateBlackPopup();
                  callback(planet);
                }}
              >
                <SpaceObjectImageView
                  spaceObject={planet}
                  showAsLogo={true}
                  maxHeight={"8em"}
                ></SpaceObjectImageView>
                {planet.name}
              </BaseButton>
            </div>
          );
        })}
      </div>
    );
  };

  static selectAUnit = (callback, text = null, component = null) => {
    const selectedUnits = [];
    const playerData = StaticGameData.getPlayerData();

    const displayTaskBarContent = () => {
      return (
        <span>
          {text && <span>{text} </span>}
          <span>Select a unit </span>
        </span>
      );
    };

    const navigationData = {
      taskBarData: {
        content: displayTaskBarContent(),
      },
      buttonsData: [
        {
          text: "Cancel",
          callback: () => {
            Popup.resetMapNavigationData();
            Popup.unStackToMap();
          },
        },
      ],
    };

    const onUpdate = () => {
      navigationData.taskBarData.content = displayTaskBarContent();
      if (selectedUnits.length === 1) {
        const unit = selectedUnits[0];
        selectedUnits.splice(0, selectedUnits.length);
        UIMessage.displayConfirmMessage(
          unit.type + " selected",
          "Do you confirm selecting " + unit.type + " ?",
          () => {
            Popup.deActivateBlackPopup();
            callback(unit);
          }
        );
      }
    };

    /*const onValidateInOneSystem = () => {
      if (filter.fromOnlyOneSystem && selectedUnits.length > 0) {
        callback(selectedUnits);
      }
    };*/

    Popup.setMapNavigationData(navigationData);
    Popup.setMapClickCallback((system) => {
      this.selectUnitsInSystem({
        //amountPlanets: amount,
        selectedUnits: selectedUnits,
        system: system,
        onUpdate: onUpdate,
        callback: () => {},
        filter: {},
        //navigationData: navigationData,
        //addUnit: addUnit,
        //removeUnit: removeUnit,
      });
    });

    Popup.unStackToMap();
  };

  static doNothing = (callback) => {
    const navigationData = {
      buttonsData: [
        {
          text: "back",
          callback: () => {
            callback();
            Popup.resetMapNavigationData();
            Popup.touch();
          },
        },
      ],
    };

    Popup.setMapNavigationData(navigationData);
    Popup.touch();
  };

  static chooseATech = (callback, onlyOwned = false, onlyNotOwned = false) => {
    const playerData = StaticGameData.getPlayerData();
    const techTree = playerData.techTree;
    let techList = TechTree.getAllTechs(techTree);
    let selectedTechs = [];
    if (onlyOwned) {
      techList = techList.filter((tech) => tech.owned);
    }
    if (onlyNotOwned) {
      techList = techList.filter((tech) => !tech.owned);
    }

    const displayTech = (tech) => {
      const textColor = Color.getColorFromTechColor(tech.color);
      if (onlyOwned && !tech.owned) {
        return null;
      }

      if (onlyNotOwned && tech.owned) {
        return null;
      }

      return (
        <div>
          <BaseButton
            type={tech.owned ? "greyOut" : "white"}
            onClick={() => {
              Popup.addLayer({
                name: "TechView",
                tech: tech,
              });
            }}
          >
            <div style={{ textAlign: "left" }}>
              <div
                style={{
                  //color: textColor,
                  display: "grid",
                  gridTemplateColumns: "auto auto auto ",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <TechTypeView color={tech.color}></TechTypeView>

                <span style={{ marginRight: "2em" }}> {tech.name}</span>
                <span style={{}}>
                  {tech.requirements.map((req, index) => {
                    return (
                      <TechTypeView key={index} color={req}></TechTypeView>
                    );
                  })}
                </span>
              </div>
            </div>
          </BaseButton>
        </div>
      );
    };

    const callbackConfirm = (tech) => {
      UIMessage.displayConfirmMessage(
        "Choose " + tech.name + " ?",
        "Do you confirm choosing " + tech.name + " ?",
        () => {
          Popup.goBack();
          callback(tech);
        }
      );
    };
    const navigationData = {
      buttonsData: [
        {
          text: "Back",
          callback: () => {
            Popup.goBack();
          },
        },
      ],
    };

    const DisplayTechSelection = (techList) => {
      return (
        <GameGlobalLayout
          title={null}
          showTitle={false}
          backgroundType="shadow"
        >
          <div
            style={{
              height: "100%",
            }}
          >
            <SelectionListView
              items={techList}
              displayItem={displayTech}
              selectedItems={selectedTechs}
              selectItem={callbackConfirm}
              unSelectItem={() => {}}
            ></SelectionListView>
          </div>
          <NavigationView navigationData={navigationData}></NavigationView>
        </GameGlobalLayout>
      );
    };
    Popup.addDynamicLayer(DisplayTechSelection(techList));
  };

  static selectAExiledFaction = (callback, text, cancelCallback) => {
    const navigationData = {
      taskBarData: {
        text: text ? text : "Select an exiled faction",
      },
      buttonsData: [
        {
          text: "Cancel",
          callback: () => {
            Popup.resetMapNavigationData();
            Popup.unStackToMap();

            if (cancelCallback) cancelCallback();
          },
        },
      ],
    };

    Popup.setMapNavigationData(navigationData);
    Popup.setMapClickCallback((object) => {
      if (
        object &&
        object.type === SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION
      ) {
        UIMessage.displayConfirmMessage(
          "Choose " + SecondaryObject.getMinorFaction(object).name + " ?",
          "Do you confirm choosing " +
            SecondaryObject.getMinorFaction(object).name +
            " ?",
          () => {
            Popup.resetMapNavigationData();
            Popup.unStackToMap();
            callback(object);
          }
        );
      }
    }, "exiled");

    Popup.unStackToMap();
  };

  static chooseAItem = (callback, artifactOnly = false, relicOnly = false) => {
    const playerData = StaticGameData.getPlayerData();
    const allItems = playerData.items;
    let items = [];
    if (artifactOnly) {
      items = Item.getArtifactsFromItems(allItems);
    } else if (relicOnly) {
      items = Item.getRelicsFromItems(allItems);
    } else {
      items = allItems;
    }
    let selectedItems = [];

    const displayItem = (item) => {
      return (
        <div>
          <BaseButton
            type={Item.isItemExhausted(item) ? "navigation" : "white"}
            onClick={() => {
              Popup.addDynamicLayer(
                <ItemDetailedView
                  playerId={PlayerData.getPlayerId(playerData)}
                  itemId={item.id}
                ></ItemDetailedView>
              );
            }}
          >
            <ItemView item={item} exhaustColorChange={false}></ItemView>
          </BaseButton>
        </div>
      );
    };

    const callbackConfirm = (item) => {
      UIMessage.displayConfirmMessage(
        "Choose " + item.name + " ?",
        "Do you confirm choosing " + item.name + " ?",
        () => {
          Popup.goBack();
          callback(item);
        }
      );
    };
    const navigationData = {
      buttonsData: [
        {
          text: "Back",
          callback: () => {
            Popup.goBack();
          },
        },
      ],
    };

    const DisplayItemSelection = (items) => {
      return (
        <GameGlobalLayout
          title={null}
          showTitle={false}
          backgroundType="shadow"
        >
          <div
            style={{
              height: "100%",
            }}
          >
            <SelectionListView
              items={items}
              displayItem={displayItem}
              selectedItems={selectedItems}
              selectItem={callbackConfirm}
              unSelectItem={() => {}}
            ></SelectionListView>
          </div>
          <NavigationView navigationData={navigationData}></NavigationView>
        </GameGlobalLayout>
      );
    };

    Popup.addDynamicLayer(DisplayItemSelection(items));
  };

  static selectAExiledFaction = (callback, text, cancelCallback) => {
    const navigationData = {
      taskBarData: {
        text: text ? text : "Select an exiled faction",
      },
      buttonsData: [
        {
          text: "Cancel",
          callback: () => {
            Popup.resetMapNavigationData();
            Popup.unStackToMap();

            if (cancelCallback) cancelCallback();
          },
        },
      ],
    };

    Popup.setMapNavigationData(navigationData);
    Popup.setMapClickCallback((object) => {
      if (
        object &&
        object.type === SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION
      ) {
        UIMessage.displayConfirmMessage(
          "Choose " + SecondaryObject.getMinorFaction(object).name + " ?",
          "Do you confirm choosing " +
            SecondaryObject.getMinorFaction(object).name +
            " ?",
          () => {
            Popup.resetMapNavigationData();
            Popup.unStackToMap();
            callback(object);
          }
        );
      }
    }, "exiled");

    Popup.unStackToMap();
  };

  static selectASpaceObjectOrSystem = (
    callback,
    text = null,
    component = null,
    cancelCallback = null,
    askConfirm = true,
    typeOnly = "all"
  ) => {
    let selectedObject = null;
    const playerData = StaticGameData.getPlayerData();

    const displayTaskBarContent = () => {
      return (
        <span>
          {text && <span>{text} </span>}
          {component && <span>{component}</span>}
        </span>
      );
    };

    const navigationData = {
      taskBarData: {
        content: displayTaskBarContent(),
      },
      buttonsData: [
        {
          text: "Cancel",
          callback: () => {
            Popup.resetMapNavigationData();
            Popup.unStackToMap();

            if (cancelCallback) cancelCallback();
          },
        },
      ],
    };

    const onUpdate = () => {
      navigationData.taskBarData.content = displayTaskBarContent();
    };

    Popup.setMapNavigationData(navigationData);
    Popup.setMapClickCallback((object) => {
      selectedObject = object;
      onUpdate();

      if (!object.isPlanet && typeOnly === "planet") {
        return;
      }
      if (!object.isSystem && typeOnly === "system") {
        return;
      }
      if (
        !object.type &&
        object.type === SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION &&
        typeOnly === "exiled"
      ) {
        return;
      }

      if (askConfirm) {
        UIMessage.displayConfirmMessage(
          object.name + " selected",
          "Do you confirm selecting " + object.name + " ?",
          () => {
            Popup.unStackToMap();
            callback(object);
          }
        );
      } else {
        Popup.unStackToMap();
        callback(object);
      }
    }, "all");
  };
}

export default InteractionFunctions;
