import React, { useState, useEffect, useRef, Children } from "react";
import "../../CSS/Menu.css";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import Request from "../../Common/Requests/Request";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Color from "../../Common/Config/Colors";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";

import ChatRoom from "../../Data/ChatData/ChatRoom.js";
import FactionLogo from "../Utils/FactionLogo";
import ChatFactionLogo from "./ChatFactionLogo";
import ChatStaticData from "../../Data/ChatData/ChatStaticData";
import ChatData from "../../Data/ChatData/ChatData";
import useChatData from "./UseChatData";
import BaseButton from "../Utils/BaseButton";
import useScrollPosition from "../Utils/Hooks/UseScrollPosition";
import ChatRoomInputView from "./ChatRoomInputView";
import ImagesUrl from "../../Data/Other/ImagesUrl.js";
import BodyView from "../Faction/BodyView.jsx";
import GameUpdate from "../../Data/GameData/Game/GameUpdate.js";
import ChatItemView from "./ChatItemView.jsx";
import ChatItem from "../../Data/ChatData/ChatItem.js";

const ChatRequest = require("../../Data/ChatData/ChatRequest");

const IMAGE_PLANET_TEST = "/Images/Map/Objects/planet_test.png";

const ChatRoomView = ({ room, setActiveRoom, chatData, chatItems }) => {
  const playerData = StaticGameData.getPlayerData();
  //console.log("debug playerData : ", playerData);
  const playerInGameId = playerData.playerInGameId;
  const popup = StaticGameData.popup;
  console.log("ChatRoomView RENDER");
  let roomData = room;

  let isLoadingRoom = false;

  const [filterOnOwnTag, setFilterOnOwnTag] = useState(false);

  // const [chatData, setChatData] = useState(ChatStaticData.chatData);

  //Scroll at end
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const roomChatItems = chatItems[roomData.id];

  //Update roomred if needed
  const gameUpdate = playerData.gameUpdate;
  if (!GameUpdate.isRoomRed(gameUpdate, roomData.id)) {
    GameUpdate.markRoomAsRed(gameUpdate, roomData.id);
    Request.callPostAPI(
      {
        gameUpdateChanges: { roomIdToRead: roomData.id },
        gameId: StaticGameData.gameState.gameId,
      },
      "gameState-update-light",
      () => {},
      false
    );
  }

  /*const handleFetchChatData = async (chatDataP) => {
    const lastItemIdInDB = ChatData.getRoomFromId(
      chatDataP,
      roomData.id
    ).lastItemId;
    const lastItemIdLoaded = ChatStaticData.roomLastLoadedItemId[roomData.id];
    //console.log("debug fetch data");
    setChatData(chatDataP);
    if (lastItemIdInDB > lastItemIdLoaded) {
      await ChatStaticData.loadMostRecentChatItems(roomData.id);
      setChatItems(ChatStaticData.getChatItems(roomData.id));
      ChatStaticData.roomLastLoadedItemId[roomData.id] = lastItemIdInDB;
    }
  };
  const { loading } = useChatData(handleFetchChatData); // Pass the callback to the custom hook*/

  //Scroll at the end
  const scrollContainerRef = useRef(null);
  let isuserLookingHistory = false;
  const scrollPosition = useScrollPosition(
    scrollContainerRef,
    null,
    true,
    true,
    null,
    Popup.getGlobalStateKey("chat_scroll_room" + room.id)
  );

  //let previousScrollPosition = 0;

  /* console.log("scrollContainerRef RERENDER");
  console.log(
    "scrollContainerRef scrollContainerRef",
    scrollContainerRef.current
  );*/

  /*useEffect(() => {
    if (scrollContainerRef.current) {
      console.log(
        "scrollContainerRef.current useEffect : ",
        scrollContainerRef.current
      );
      console.log(
        "scrollContainerRef.current chatContainerRef useEffect : ",
        chatContainerRef.current
      );
    }
  }, [scrollContainerRef]);*/

  //let updateChatItemsState = null;

  //Ref to keep focus on input field
  //const inputRef = useRef(null);

  //const [roomData, setRoomData] = useState(room);

  const styles = {
    chatContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      borderRadius: "5px",
      overflow: "hidden",
      color: "white",
      //backgroundColor: Color.COLOR_GREY_2,
      backgroundImage: `url(${ImagesUrl.URL_MAP_BACKGROUND})`,
    },
    messagesView: {
      display: "flex",
      padding: "10px",

      //backgroundColor: "purple",
      display: "flex", // Add display: flex to make align-items work
      flexDirection: "column", // Ensure messages stack vertically
      //alignItems: "flex-end", // Align items at the bottom
      //justifyContent: "flex-end", // Align items to the bottom right
    },
    message: {
      padding: "5px 10px",
      margin: "5px 0",
      borderRadius: "5px",
      borderWidth: "2px",
      borderStyle: "solid",
      //backgroundColor: Color.COLOR_GREY_5,
      backgroundColor: "black",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
      textAlign: "left",
      color: "white",
      width: "fit-content",
    },
    messageInput: {
      display: "flex",
      padding: "10px",
      //backgroundColor: Color.COLOR_GREY_3,
      maxHeight: "3em",
    },
    input: {
      flex: 1,
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      marginRight: "10px",
      backgroundColor: "black",
      color: "white",
    },
    button: {
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      backgroundColor: "black",
      color: "white",
      //color: "#fff",
      cursor: "pointer",
    },
    buttonHover: {
      backgroundColor: Color.COLOR_GREY_5,
    },
  };

  /*useEffect(() => {
    console.log("popup.shouldFocusInput useEffect : ", popup.shouldFocusInput);
    if (popup.shouldFocusInput && inputRef.current) {
      inputRef.current.focus(); // Focus on the input field
      delete popup.shouldFocusInput; // Reset the flag after focusing
    }
  }, []); // Run this effect when shouldFocusInput changes
*/
  let currentRoom = roomData;
  let currentLastItemId = currentRoom.lastItemId;

  const handleChatDataFetch = (chatData) => {
    const nextRoom = ChatData.getRoomFromId(chatData, currentRoom.id);
    const nextLastItemId = nextRoom.lastItemId;
    if (nextLastItemId > currentLastItemId) {
      currentRoom = nextRoom;
      currentLastItemId = nextLastItemId;
    }
  };

  //const { loading } = useChatData(handleChatDataFetch); // Pass the callback to the custom hook

  const DisplayChatItemsView = ({ roomChatItems, filterOnOwnTag }) => {
    console.log(
      "DisplayChatItemsView RENDER : ",
      roomChatItems[roomChatItems.length - 1]
    );
    /*updateChatItemsState = (chatItemsP) => {
        console.log("updateChatItemsState chatItemsP : ", chatItemsP);
        setChatItems(chatItemsP);
      };*/

    //when chat items are updating, scroll to the end if it should
    if (scrollContainerRef.current) {
      if (
        scrollContainerRef.current.scrollTop ===
        scrollContainerRef.current.scrollHeight -
          scrollContainerRef.current.clientHeight
      ) {
        isuserLookingHistory = false;
      } else {
        isuserLookingHistory = true;
      }
      console.log("Scroll is looking history : " + isuserLookingHistory);
    }

    useEffect(() => {
      if (!isuserLookingHistory && scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop =
          scrollContainerRef.current.scrollHeight;
      }
    }, [roomChatItems]);

    return (
      <div style={styles.messagesView}>
        {roomChatItems.map((chatItem, index) => {
          const playerColor = Color.getColorFromPlayerInGameId(
            playerData,
            chatItem.playerInGameId
          );

          let display = true;
          if (filterOnOwnTag && playerData.faction) {
            const chatItemTags =
              ChatItem.getTagIncludingSenderFaction(chatItem);
            if (
              !chatItemTags.some(
                (tag) => tag === ":" + playerData.faction.name + ":"
              )
            ) {
              display = false;
              console.log("debug chatItem : ", chatItem);
            }
          }

          return (
            <div
              key={index}
              /*style={{
                ...styles.message,
                borderColor: Color.BASE_BUTTON_BORDER,
                borderRadius: "15px",
              }}*/
            >
              {display && <ChatItemView chatItem={chatItem}></ChatItemView>}
            </div>
          );
        })}
      </div>
    );
  };

  const DisplayNavigationBar = () => {
    const DisplayRoomTitle = () => {
      if (roomData.id === "common") {
        return <span> Common </span>;
      } else {
        return (
          <span style={{ fontSize: "1.5em" }}>
            <ChatFactionLogo
              playerInGameId={roomData.playerInGameId}
            ></ChatFactionLogo>{" "}
          </span>
        );
      }
    };
    return (
      <div>
        {false && <DisplayRoomTitle></DisplayRoomTitle>}
        <BaseButton
          onClick={() => {
            Popup.unStackToMap();
          }}
        >
          Map
        </BaseButton>

        {false && (
          <BaseButton
            onClick={() => {
              setActiveRoom(null);
            }}
          >
            Rooms
          </BaseButton>
        )}
      </div>
    );
  };

  let colorRoom = null;
  if (roomData.playerInGameId) {
    const roomPlayerData = PlayerData.getPlayerDataFromInGameId(
      playerData,
      roomData.playerInGameId
    );
    if (roomPlayerData.faction) {
      colorRoom = roomPlayerData.faction.dedicatedColor;
    }
  }

  return (
    <div style={styles.chatContainer}>
      <div
        ref={scrollContainerRef}
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          overflowY: "auto",
          //overflowY: "hidden",
          //backgroundColor: "yellow",
          flexDirection: "column",
          zIndex: 999,
          //justifyContent: "flex-end",
          //alignItems: "flex-end",
          position: "relative",
        }}
      >
        {false && (
          <div
            style={{
              position: "absolute",
              zIndex: 2000,
              left: "50%",
              top: "20px",
              transform: "translate(-50%, 0)",
              border: "2px solid white",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            {roomData.id === "common" ? (
              <span>Council Meeting Room</span>
            ) : (
              <span>
                {" "}
                <ChatFactionLogo
                  playerInGameId={roomData.playerInGameId}
                  showFactionName={false}
                ></ChatFactionLogo>{" "}
                Meeting Room
              </span>
            )}
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            flexGrow: 1,
          }}
        >
          <DisplayChatItemsView
            roomChatItems={roomChatItems}
            filterOnOwnTag={filterOnOwnTag}
          ></DisplayChatItemsView>{" "}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div style={{ position: "relative" }}>
        {colorRoom && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: "50%",
              transform: "translate(-50%, -100%)",
            }}
          >
            <BodyView color={colorRoom}></BodyView>
          </div>
        )}
        <ChatRoomInputView
          room={room}
          styles={styles}
          scrollContainerRef={scrollContainerRef}
          filterOnOwnTag={filterOnOwnTag}
          setFilterOnOwnTag={setFilterOnOwnTag}
          scrollToBottom={scrollToBottom}
        ></ChatRoomInputView>
      </div>

      <DisplayNavigationBar></DisplayNavigationBar>
    </div>
  );
};

/*<GameGlobalLayout style={{ height: "100%" }} backgroundType="shadow">
      <div
        style={{
          height: "100%",
          overflow: "hidden",
          //backgroundColor: Color.COLOR_MANAGE_VIEW_BACKGROUND,
        }}
      >
        <DisplayChatView></DisplayChatView>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>*/

export default ChatRoomView;
