const CostResource = require("../Resource/CostResource");

class Unit {
  static createUnit(type) {
    return { type: type, mass: 1, range: 1 };
  }

  static unitToText(units) {
    const unitCounts = {};

    // Count the occurrences of each unit abbreviation
    units.forEach((unit) => {
      if (unitCounts[unit.abbreviation]) {
        unitCounts[unit.abbreviation]++;
      } else {
        unitCounts[unit.abbreviation] = 1;
      }
    });

    // Generate the text representation
    const text = Object.entries(unitCounts)
      .map(
        ([abbreviation, count]) =>
          `${count} ${abbreviation}${count > 1 ? "s" : ""}`
      )
      .join(", ");

    return text;
  }

  /*static deployUnit(unit, tile) {
    tile.unitsDeployed.splice(tile.unitsDeployed.indexOf(unit), 1);
    if ((unit.classe = "ship")) {
      tile.unitsInSpace.push(unit);
    } else {
      tile.unitsOnPlanet.push(unit);
    }
  }*/
}

module.exports = Unit;
