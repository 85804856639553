import React, { useState } from "react";
import StaticGameData from "../Data/GameData/StaticGameData.js";
import BaseButton from "../GameView/Utils/BaseButton.jsx";
import GameGlobalLayout from "../GameView/GameGlobalLayout.jsx";
import NavigationView from "../GameView/NavigationView.jsx";
import Logo from "../GameView/Utils/Logo.jsx";
import LinkExt from "./LinkExt.jsx";

const Popup = require("../Data/Other/Popup");
const HelpData = require("./HelpData.js");
const Rules = require("../Data/GameData/Game/Rules.js");
const Phase = require("../Data/GameData/Game/Phase.js");

const Linkc = ({ linkc, text = null }) => {
  console.log("Linkc linkc: ", linkc);
  const title = text ? text : getContent(linkc).title;
  return (
    <BaseButton
      onClick={() => {
        Popup.addLayer({ name: "HelpPopup", helpReference: linkc });
      }}
    >
      {title}
    </BaseButton>
  );
};

const getContent = (helpReference) => {
  const helpData = {
    helpMainScreen: {
      title: "Main Help Screen",
      content: (
        <div>
          This is the main help screen. Here you can find help on the different
          aspects of the game.
          <Linkc linkc="actionGlobal"></Linkc>
        </div>
      ),
      references: ["helpMainScreen"],
    },
    tutorialHelpView: {
      title: "Tutorial Help View",
      content: (
        <div>
          <div>
            {" "}
            At any moment in the game, you can consult the documentation of the
            game by clicking on the button "Menu" at the bottom of the screen,
            then on "Info". This will display the documentation of the game.
            <hr></hr>
            Click here to show a presentation of the game :{" "}
            <Linkc linkc="presentation"></Linkc>
          </div>
          <hr></hr>
          <div>
            {(() => {
              const playerData = StaticGameData.getPlayerData();
              const phase = playerData.phase;
              const step = playerData.step;
              return (
                <div>
                  <div>
                    You are now doing a {step} action, part of the activation
                    phase.
                  </div>
                  <div>
                    {(() => {
                      switch (step) {
                        case Phase.STEP_ACTIVATION_ACTIVATED:
                          return <Linkc linkc="actionGlobal" />;
                        case Phase.STEP_ACTIVATION_MOVE:
                          return <Linkc linkc="moveStep" />;
                        case Phase.STEP_ACTIVATION_LIFT:
                          return <Linkc linkc="liftStep" />;
                        case Phase.STEP_ACTIVATION_DROP:
                          return <Linkc linkc="dropStep" />;
                        case Phase.STEP_ACTIVATION_INVASTION:
                          return <Linkc linkc="invastionStep" />;
                        case Phase.STEP_ACTIVATION_PRODUCTION:
                          return <Linkc linkc="productionStep" />;
                        case Phase.STEP_ACTIVATION_STRUCTURE:
                          return <Linkc linkc="buildStructureStep" />;
                        case Phase.STEP_ACTIVATION_BUILD:
                          return <Linkc linkc="buildUnitsStep" />;
                        case Phase.STEP_START_OF_GAME_PICK_FACTION:
                          return <Linkc linkc="pickFactionStep" />;
                        case Phase.STEP_START_OF_GAME_COLONIZE:
                          return <Linkc linkc="colonizeStep" />;
                        case Phase.STEP_SELECT_ACTION:
                          return <Linkc linkc="selectActionStep" />;
                        default:
                          return null;
                      }
                    })()}
                  </div>
                </div>
              );
            })()}
          </div>
        </div>
      ),
    },
    presentation: {
      title: "Presentation",
      content: (
        <div>
          <div>
            <Linkc linkc="index"></Linkc>
          </div>
          <div>
            Master of the warp is a game of space diplomacy and conquest. It is
            a deep strategic and tactical game. The mechanic is made to be
            simple to understand, but the gameplay possibilities are almost
            endless.
          </div>
          <div>
            The players play their turns simultaneously. There are not too many
            turns, between 7 for short games to 14 for long games. A short game
            will be finished in one week if the turn time is set to one day, or
            70 minutes if set to 10 minutes.
            <Linkc linkc="turnMecanic"></Linkc>.<hr></hr>
          </div>
          <div>
            Each player controls a different faction. The factions are highly
            asymmetrical and make a unique experience by themselves.
            <Linkc linkc="factions"></Linkc>.<hr></hr>
          </div>
          <div>
            Each game, the technological tree and the galaxy map are different,
            procedurally generated. This makes a unique meta game impossible :
            players have to assess what is the best faction to pick and what is
            the best strategy for each game.
          </div>
          <div>
            During the game, players will have to respond to random events,
            which will require them to update their plans accordingly.
          </div>
          <div>
            The game features unique actions : these are actions which players
            are collecting through the game and can activate when they want. The
            unique actions have different effects modifying the mechanic of the
            game. Players may not see the unique actions owned by other players,
            which can create some big surprises !
          </div>
          <div>
            The game ends when a player reaches a certain amount of victory
            points. To gain VP, players have to accomplish public or secret
            objectives. The set of objectives is different each game.
            <hr></hr>
          </div>
          <div>
            During his turns, a player will mostly execute actions.
            <Linkc linkc="actionGlobal"></Linkc>
          </div>
        </div>
      ),
    },
    actionGlobal: {
      title: "List of Actions",
      content: (
        <div>
          <div>
            Here are the different action a player can do during his turn :
            <ul>
              <Linkc linkc="moveAction"></Linkc>
            </ul>
            <ul>
              <Linkc linkc="liftAction"></Linkc>
            </ul>{" "}
            <ul>
              <Linkc linkc="dropAction"></Linkc>
            </ul>
            <ul>
              <Linkc linkc="invasionAction"></Linkc>
            </ul>
            <ul>
              <Linkc linkc="productionAction"></Linkc>
            </ul>
            <ul>
              <Linkc linkc="buildStructureAction"></Linkc>
            </ul>
            <ul>
              <Linkc linkc="buildUnitsAction"></Linkc>
            </ul>
            <ul>
              <Linkc linkc="transferAction"></Linkc>
            </ul>
          </div>
        </div>
      ),
    },
    turnMecanic: {
      title: "Turn Mecanic",
      content: (
        <div>
          <div>
            All players are taking their turn simultaneously. After all players
            have taken their turn, or when the time limit for the round is
            reached in case of a timer active, the game resolves combat and
            administration tasks.
          </div>
          <hr></hr>
          <div>One such iteration is called a round. slow game.</div> <hr></hr>
          <div>
            During their turn, players are executing a set of actions. They
            decide the order and the type of these actions.
            <Linkc linkc="actionGlobal"></Linkc>
          </div>{" "}
        </div>
      ),
    },
    moveAction: {
      title: "Move Action",
      content: (
        <div>
          <div>
            There is always a target system for a move action. It is surrounded
            by blank dotted line on the map. The move action consist of moving
            units to the target system.
          </div>
          <div>
            After the move action, the system will be locked. This will prevent
            units from moving 2 times during the same round.
            <Linkc linkc="systemLocked"></Linkc>
          </div>
          <div>
            When you select a move action, the game lead you to the map. You can
            then select system to move units from.
          </div>{" "}
          <div>
            In the navigation bar, you can decide to confirm or cancel the move
            action.
          </div>{" "}
          <div>
            All units you move during a move action will go to the target system
            of this move action.
          </div>
          <div>
            Cost : Move action has a cost.
            <ul>1 energy as a base cost for each move action</ul>
            <ul>1 energy for each planet you move at least one unit from.</ul>
          </div>
        </div>
      ),
    },
    invasionAction: {
      title: "Invasion Action",
      content: (
        <div>
          <div>An invasion action does not do something immediately.</div>
          <div>
            It records an order for your units to invade a planet you don't
            control yet. This order will be executed by your units after the
            space combat in the planet system, and only if you control the space
            area of this system.
          </div>
          <div>
            When the order is executed, all of your ground forces will invade
            the planet and start ground combat if enemy ground forces are
            present.
          </div>
          <div>
            If you win the ground combat, you take control of the planet and all
            its structures.
          </div>{" "}
          <div>
            You can only invade one planet of the same system per round. As a
            consequence, the game will let you select only one planet to invade
            in each system.
          </div>
          <hr></hr>
          <div>
            If there are planets you don't control in the active system, you can
            choose one planet to invade (only one). If you win the $space combat
            and take control of the space area of the system, all of your ground
            forces in space will be dropped to the planet you choose to invade.
            After that, a ground combat may occur.
          </div>
        </div>
      ),
    },
    liftAction: {
      title: "Lift Action",
      content: (
        <div>
          <div>
            A lift action consists of lifting units from a planet you control to
            its system space area.
          </div>
          <div>Lift action has a cost : 1 energy</div>
        </div>
      ),
    },
    dropAction: {
      title: "Drop Action",
      content: (
        <div>
          <div>
            A drop action consists of dropping units from a system space area
            onto a planet you control.
          </div>
        </div>
      ),
    },
    productionAction: {
      title: "Production Action",
      content: (
        <div>
          <div>
            The production action on a planet consists of producing resources
            and population.
          </div>
          <div>
            To produce minerals, you have to assign population to mining
            minerals. Each population assigned will produce a certain amount of
            mineral, depending on the planet.
          </div>{" "}
          <div>
            To produce science, you have to assign population to researching
            science. Each population assigned will produce a certain amount of
            science, depending on the planet.
          </div>{" "}
          <div>
            To produce energy, you have to assign population to producing
            energy. Each population assigned will produce a certain amount of
            energy, depending on the planet.
          </div>{" "}
          <div>
            Population is produced automatically. The amount of population
            produced is a % of the population already on the planet. The %
            depends on the planet.
          </div>{" "}
          <div>
            Unassigned population : you can keep unassigned population. They
            will not produce this round, but you will be able to spend them as a
            cost, for example for building structures. Assigned population
            cannot be spent as a cost.
          </div>{" "}
          <div>
            You can execute only one production action per round and per planet.
          </div>{" "}
        </div>
      ),
    },
    buildStructureAction: {
      title: "Build Structures Action",
      content: (
        <div>
          <div>
            The build structures action lets you produce structures on the
            planet. *see System locked
          </div>
          <div>
            After the build structure action , the system will be locked. This
            will prevent units from moving 2 times during the same round.
            <Linkc linkc="systemLocked"></Linkc>
          </div>{" "}
          <div>
            You can execute only one build structures action per round and per
            planet.
          </div>{" "}
          <div></div> <div></div>
        </div>
      ),
    },
    buildUnitsAction: {
      title: "Build Units Action",
      content: (
        <div>
          <div>
            The build units action lets you build units using structures which
            are able to build units.
          </div>
          <div>
            Some structures can build units. The list of units a structure can
            build is different per structure. Such structures also have a
            construction value, which is the maximum number of units it can
            build during one build action.
          </div>{" "}
          <div>
            After the build units action, the system will be locked. This means
            that units will not be able to move the same round they were built..
            <Linkc linkc="systemLocked"></Linkc>
          </div>{" "}
          <div>
            You can execute only one build units action per round and per
            planet.
          </div>
        </div>
      ),
    },
    transferAction: {
      title: "Transfer Action",
      content: (
        <div>
          <div>
            The transfer action lets you transfer population and minerals to the
            selected planet from another planet.
          </div>
          <div>
            The cost of the transfer action is 1 energy per set of 3 items
            transferred, rounded up. An item in this case is a population or a
            mineral.
          </div>{" "}
          <div>
            You may not transfer between 2 planets if one of the space areas of
            these 2 planets is controlled by another faction.
          </div>{" "}
        </div>
      ),
    },
    pickFactionStep: {
      title: "Pick Faction Step",
      content: (
        <div>
          <div>
            During the pick faction action, you can choose your faction. Click
            on your home system surrounded by golden square to pick your
            faction.
          </div>
          <hr></hr>
          <div>
            Each faction have different abilities, strengths and weaknesses.
            Choose wisely.
          </div>
          <hr></hr>
        </div>
      ),
    },
    colonizeAction: {
      title: "Colonize Action",
      content: (
        <div>
          <div>
            The colonize action is executed only at the beginning of the game.
            During colonize step, you can freely colonize planets in the galaxy.
          </div>
          <hr></hr>
          <div>
            To colonize a planet, click on the system of the planet, then on the
            planet name and finaly on the button "Colonize" at the bottom of the
            screen.
          </div>
          <hr></hr>
          <div>
            The amount of planet you can colonize is limited by the amount of
            colonist you have. Generally, a faction has 2 colonist. There are
            exeptions.
          </div>
          <hr></hr>
          <div>
            The only condition to colonize a planet is that the planet is not at
            a distance more than 2 from your home system.
          </div>
          <hr></hr>
          <div>
            When you colonize a planet, a free population will be placed on it.
          </div>
        </div>
      ),
    },
    selectActionStep: {
      title: "Select Action Step",
      content: (
        <div>
          <div>
            During the select action phase, you have to decide what to do.
            Mainly, this is the activation of a system.
          </div>
          <hr></hr>
          <div>
            You can select a system and click on the "Activate" button to
            activate the system and start a activation phase.
          </div>
          <hr></hr>
        </div>
      ),
    },
    resource: {
      title: "Resources",
      content: (
        <div>
          <div>
            Here are the main resources of the game :
            <ul>
              <Logo logoName="population"></Logo>{" "}
              <Linkc linkc="population"></Linkc>
            </ul>
            <ul>
              <Logo logoName="mineral"></Logo> <Linkc linkc="mineral"></Linkc>
            </ul>
            <ul>
              <Logo logoName="science"></Logo> <Linkc linkc="science"></Linkc>
            </ul>
            <ul>
              <Logo logoName="energy"></Logo> <Linkc linkc="energy"></Linkc>
            </ul>
            <ul>
              <Logo logoName="credit"></Logo> <Linkc linkc="credit"></Linkc>
            </ul>
            <ul>
              <Logo logoName="influence"></Logo>{" "}
              <Linkc linkc="influence"></Linkc>
            </ul>
            <ul>
              <Logo logoName="cargo"></Logo> <Linkc linkc="fleetLimit"></Linkc>
            </ul>
          </div>
        </div>
      ),
    },
    fleetLimit: {
      title: "Fleet Limit",
      content: (
        <div>
          <div>
            Fleet limit <Logo logoName="cargo"></Logo> is one of the{" "}
            <Linkc linkc="resource"></Linkc> of the game.
          </div>
          <hr></hr>
          <div>
            Your fleet limit is the maximum amount of mass you can have in a
            system. Your non figther ships has a mass value. If the mass of your
            fleet exceed your fleet limit, you will have to scrap ships until it
            is not the case anymore.
          </div>
          <div>Most ships have a mass of 1.</div>
        </div>
      ),
    },
    mineral: {
      title: "Mineral",
      content: (
        <div>
          <div>
            Mineral <Logo logoName="mineral"></Logo> is one of the{" "}
            <Linkc linkc="resource"></Linkc> of the game.
          </div>
          <hr></hr>
          <div>
            Mineral is mainly used to build structures and units. It is produced
            by population mining on planets.
          </div>
          <hr></hr>
          <div>
            Mineral is stored on planet and may be $transfered from planet to
            planet.
          </div>
        </div>
      ),
    },
    population: {
      title: "Population",
      content: (
        <div>
          <div>
            Population <Logo logoName="population"></Logo> is one of the{" "}
            <Linkc linkc="resource"></Linkc> of the game.
          </div>
          <hr></hr>
          <div>
            Population grow naturaly, at a different % rate on each planet.
          </div>
          <hr></hr>
          <div>
            Population live on planets may be $transfered from planet to planet.
          </div>
        </div>
      ),
    },
    energy: {
      title: "Energy",
      content: (
        <div>
          <div>
            Energy <Logo logoName="energy"></Logo> is one of the{" "}
            <Linkc linkc="resource"></Linkc> of the game.
          </div>
          <hr></hr>
          <div>
            Energy is used to execute actions, like moving ships, lifting ground
            forces,...
          </div>
          <hr></hr>
          <div>
            Supply bases are consuming energy at the end of the round. You can
            see how much will be consumed by the (-x) next to your energy
            indicator at the top of the screen. Pay attention that if you have a
            negative energy, you will not be able to execute some actions which
            cost energy. This is not a good position to be in. Stealing
            generators to other player may be a good idea.
          </div>

          <hr></hr>
          <div>Energy is mainly produced by generators on planets.</div>
          <hr></hr>
          <div>
            At the end of each round, each faction receives a bonus of energy.
            So even if you don't have any generator, you will receive some
            energy. For most of the faction, the amount of energy received is{" "}
            {Rules.ENERGY_PRODUCTION}.
          </div>
        </div>
      ),
    },
    influence: {
      title: "Influence",
      content: (
        <div>
          <div>
            Influence <Logo logoName="influence"></Logo> is one of the{" "}
            <Linkc linkc="resource"></Linkc> of the game.
          </div>
          <hr></hr>
          <div>
            Influence is not yet implemented. It will be used to purchase some
            extra strategic and tactical abilities on each round.
          </div>
        </div>
      ),
    },
    credit: {
      title: "Credit",
      content: (
        <div>
          <div>
            Credit <Logo logoName="credit"></Logo> is one of the{" "}
            <Linkc linkc="resource"></Linkc> of the game.
          </div>
          <hr></hr>
          <div>
            Influence is not yet implemented. It will be used to buy mineral,
            energy, science and influence. Player will be able to trade credits.
          </div>
        </div>
      ),
    },
    science: {
      title: "Science",
      content: (
        <div>
          <div>
            Science <Logo logoName="science"></Logo> is one of the{" "}
            <Linkc linkc="resource"></Linkc> of the game.
          </div>
          <hr></hr>
          <div>
            Science is not yet implemented. It will be used to buy technologies,
            but there are no technologies in the game yet.
          </div>
        </div>
      ),
    },
    factions: {
      title: "Factions",
      content: (
        <div>
          <div>
            The game will features many factions having each their own strenghts
            and weaknesses.
          </div>
          <div>
            Factions are not yet implemented. All factions are the same for now.
          </div>
        </div>
      ),
    },
    index: {
      title: "Help Index",
      content: (
        <div>
          {" "}
          <ul>
            {" "}
            <Linkc linkc="resource"></Linkc>
          </ul>
          <ul>
            {" "}
            <Linkc linkc="actionGlobal"></Linkc>
          </ul>{" "}
          <ul>
            {" "}
            <Linkc linkc="turnMecanic"></Linkc>
          </ul>
        </div>
      ),
    },
    systemLocked: {
      title: "System Locked",
      content: (
        <div>
          A locked system appears more dark than a non locked system. Units
          cannot move out of a locked system.
        </div>
      ),
    },
  };

  return helpData[helpReference];
};

const HelpPopup = ({ helpReference }) => {
  const handleBackPopup = () => {
    Popup.goBack();
  };

  const helpData = getContent(helpReference);
  console.log("HelpData data: ", helpData);

  const navigationData = {
    buttonsData: [
      {
        text: "Map",
        callback: () => {
          Popup.unStackToMap();
        },
      },
      {
        text: "Back",
        callback: () => {
          Popup.goBack();
        },
      },
    ],
  };

  if (helpData === null) {
    return null;
  }

  const DisplayTexts = () => {
    return helpData.texts.map((textItem, index) => (
      <div key={index}>
        {textItem}
        <br />
      </div>
    ));
  };

  const DisplayReferences = () => {
    return helpData.references.map((referenceItem, index) => (
      <span key={index}>
        <BaseButton
          onClick={() => {
            Popup.addLayer({ name: "HelpPopup", helpReference: referenceItem });
          }}
        >
          {HelpData.data[referenceItem].title}
        </BaseButton>
      </span>
    ));
  };

  return (
    <div className="div-fill">
      <GameGlobalLayout title={helpData.title}>
        <div className="text-box">{helpData.content}</div>
        <NavigationView navigationData={navigationData}></NavigationView>
      </GameGlobalLayout>
    </div>
  );

  /*return (
    <div className="div-fill">
      <GameGlobalLayout title={helpData.title}>
        <div className="text-box">
          <DisplayTexts></DisplayTexts>
          <DisplayReferences></DisplayReferences>
        </div>
        <NavigationView navigationData={navigationData}></NavigationView>
      </GameGlobalLayout>
    </div>
  );*/
};

export default HelpPopup;
