import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import BaseButton from "../GameView/Utils/BaseButton";
import ImagesUrl from "../Data/Other/ImagesUrl";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import Color from "../Common/Config/Colors";
import StaticGameData from "../Data/GameData/StaticGameData";
import WelcomeScreen from "../Main/WelcomeScreen";
import BaseImage from "../GameView/Utils/BaseImage";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import BaseInput from "../GameView/Utils/BaseInput";
import Request from "../Common/Requests/Request";

const Popup = require("../Data/Other/Popup");

export const ResetPasswordFromMail = ({}) => {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [token, setToken] = useState("");
  const [siteRoot, setSiteRoot] = useState("");

  useEffect(() => {
    // Parse the URL to extract the token
    const urlPath = window.location.pathname; // Example: "/reset-password/<token>"

    const parts = urlPath.split("/"); // Split the path into parts
    if (parts.length === 3) {
      const extractedToken = parts[2]; // The token is at index 2
      setToken(extractedToken); // Set the token in state
      console.log("Extracted Token:", extractedToken);
    }

    // Extract the root of the site
    const siteRoot = `${window.location.protocol}//${window.location.host}`; // Example: "http://localhost:3000"
    setSiteRoot(siteRoot); // Set the site root in state if needed
  }, []); // Run this only once when the component mounts

  const handleResetPassword = async () => {
    if (!password || !passwordConfirmation) {
      UIMessage.displayInfoMessageToStaticGameData(
        null,
        "Please enter your password and password confirmation."
      );
      return;
    }

    if (password !== passwordConfirmation) {
      UIMessage.displayInfoMessageToStaticGameData(
        null,
        "Passwords do not match."
      );
      return;
    }

    Request.callPostAPI(
      { token: token, password: password },
      "reset-password-from-mail",
      (result) => {}
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div>Password : </div>
      <LineSpace></LineSpace>
      <div>
        <BaseInput
          type="password"
          id="password"
          value={password}
          onChange={(e) => {
            const input = e.target.value;
            setPassword(input);
          }}
        ></BaseInput>
      </div>
      <LineSpace></LineSpace>
      <div>Password confirmation : </div>
      <LineSpace></LineSpace>
      <div>
        <BaseInput
          type="password"
          id="passwordConfirmation"
          value={passwordConfirmation}
          onChange={(e) => {
            const input = e.target.value;
            setPasswordConfirmation(input);
          }}
        ></BaseInput>
      </div>
      <LineSpace></LineSpace>
      <div>
        <BaseButton onClick={handleResetPassword}>Ok</BaseButton>
      </div>
      <LineSpace></LineSpace>
      <div>
        <BaseButton
          onClick={() => {
            console.log("Redirecting to:", siteRoot);
            window.location.href = siteRoot;
          }}
        >
          Go to Portal Empires
        </BaseButton>
      </div>
    </div>
  );
};

export default ResetPasswordFromMail;
