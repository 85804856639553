import React, { useState } from "react";

import UIMessage from "../../Data/GameData/Connection/UIMessage";
import BaseButton from "../Utils/BaseButton";
import GameState from "../../Data/GameData/Game/GameState";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";
import LogBookView from "../Utils/LogMessage/LogBookView";
import Rules from "../../Data/GameData/Game/Rules";
import Phase from "../../Data/GameData/Game/Phase";
import ActionSelectionView from "../ManageSystem/ActionView/ActionSelectionPopup";
import DetailViewWhenNoFactionPicked from "./DetailViewWhenNoFactionPicked";
import DraftFactionView from "../Action/StartOfGame/DraftFactionView";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const Popup = require("../../Data/Other/Popup");
const Color = require("../../Common/Config/Colors");

const MapInteractionPopup = ({ playerData, targetItem }) => {
  const isItemSystem = targetItem.objects && !targetItem.isFactionDraftSystem;
  const isItemPlanet = targetItem.isPlanet;
  const isItemDraftSystem = targetItem.isFactionDraftSystem;

  let backgroundColor;
  backgroundColor = "rgb(0, 0, 0,1)";
  backgroundColor = "rgba(0, 0, 0, 0.7)";

  const style = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 2000,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  };

  const handleMouseDownOnBackground = (e) => {
    //e.preventDefault();
    //e.stopPropagation();
  };

  const handleMouseUpOnBackground = (e) => {
    //e.preventDefault();
    //e.stopPropagation();

    StaticGameData.popup.mapClicked = null;
    Popup.deleteAttribute("mapClicked");
  };

  const Content = () => {
    const phase = playerData.phase;
    const step = playerData.step;

    return (
      <div>
        {phase == Phase.PHASE_START_OF_GAME &&
          step === Phase.STEP_START_OF_GAME_PICK_FACTION && (
            <DraftFactionView draftSystem={targetItem}></DraftFactionView>
          )}
        {phase === Phase.PHASE_SELECT_ACTION && (
          <ActionSelectionView
            playerData={playerData}
            targetItem={targetItem}
          ></ActionSelectionView>
        )}
      </div>
    );
  };

  return (
    <div
      style={style}
      onMouseDown={handleMouseDownOnBackground}
      onMouseUp={handleMouseUpOnBackground}
      className="div-fill"
    >
      <div>
        <div
          style={{
            backgroundColor: backgroundColor,
            height: "auto",
            border: "4px solid white",
            borderRadius: "10px",
            //padding: "10px",
            //padding: "10px",
            //maxHeight: "100%",
            //maxWidth: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
          className="div-ver-center"
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onMouseUp={(e) => {
            e.stopPropagation();
          }}
        >
          <Content></Content>
        </div>
      </div>
    </div>
  );
};

export default MapInteractionPopup;
