import React, { useState } from "react";
import BaseButton from "../GameView/Utils/BaseButton";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import Popup from "../Data/Other/Popup";
import GameGlobalLayout from "../GameView/GameGlobalLayout";
import MenuNavigationView from "../MainMenu/MenuNavigationView";
import "../CSS/Menu.css";
import BaseInput from "../GameView/Utils/BaseInput";
import StaticGameData from "../Data/GameData/StaticGameData";
import Game from "../Data/GameData/Game/Game";
import Request from "../Common/Requests/Request";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import Time from "../Data/GameData/Time/Time";

const DisplaySelectAmountPlayers = ({ setAmountPlayers }) => {
  return (
    <div>
      {[2, 3, 4, 5, 6].map((num) => (
        <BaseButton
          key={num}
          onClick={() => {
            setAmountPlayers(num);
            Popup.deActivateBlackPopup();
          }}
        >
          {num}
        </BaseButton>
      ))}
    </div>
  );
};

const DisplayGameParameters = ({
  draft,
  setDraft,
  passwordGame,
  setPasswordGame,
  amountPlayers,
  setAmountPlayers,
  playerNameHidden,
  setPlayerNameHidden,
  vpLimit,
  setVpLimit,
  timeType,
  setTimeType,
  roundTime,
  setRoundTime,
  roundFixedTime,
  setRoundFixedTime,
  roundDuration,
  setRoundDuration,
}) => {
  const containerParamStyle = {
    border: "1px solid white",
    borderRadius: "5px",
    padding: "10px",
  };
  return (
    <div>
      <div style={containerParamStyle}>
        <span>
          Draft :{" "}
          <BaseButton
            onClick={() => {
              /*UIMessage.displayInfoMessageToStaticGameData(
                "Draft",
                "For the moment, only games with drafts are available. This feature will allow to start a game with a random faction."
              );*/
              setDraft(!draft);
            }}
          >
            {draft ? "Yes" : "No"}
          </BaseButton>
        </span>
        {draft ? (
          <div>
            Players will draft their faction, their map position and the hero in
            their home system.
          </div>
        ) : (
          <div>
            {" "}
            Players will be placed randomly on the map and will be assigned a
            random faction.
          </div>
        )}
      </div>
      <LineSpace />
      <div style={containerParamStyle}>
        <span>
          Password :{" "}
          <BaseInput
            value={passwordGame}
            onChange={(e) => setPasswordGame(e.target.value)}
          />
        </span>
        {passwordGame === "" && (
          <div>Games without password will be joinable by anyone</div>
        )}
      </div>

      <LineSpace />
      <div style={containerParamStyle}>
        <span>
          Amount players :{" "}
          <BaseButton
            onClick={() =>
              Popup.activateBlackPopup(
                <DisplaySelectAmountPlayers
                  setAmountPlayers={setAmountPlayers}
                />
              )
            }
          >
            {amountPlayers}
          </BaseButton>
        </span>
      </div>
      <LineSpace></LineSpace>
      <div style={containerParamStyle}>
        Player names hidden :{" "}
        <BaseButton
          onClick={() => {
            setPlayerNameHidden(!playerNameHidden);
          }}
        >
          {playerNameHidden ? "Yes" : "No"}
        </BaseButton>
        <div>
          {playerNameHidden
            ? "Players will not know who are controlling the other factions."
            : "Players will know the name of the players controlling the other factions."}
        </div>
      </div>
      <LineSpace></LineSpace>
      {false && (
        <div style={containerParamStyle}>
          Win condition :
          <BaseButton
            onClick={() => {
              Popup.activateBlackPopup(
                <DisplayVPLimitConfig
                  vpLimit={vpLimit}
                  setVpLimit={setVpLimit}
                />
              );
            }}
          >
            {vpLimit}
          </BaseButton>{" "}
          victory points.
        </div>
      )}
      <div style={containerParamStyle}>
        Rounds limit :
        <BaseButton
          onClick={() => {
            Popup.activateBlackPopup(
              <DisplayRoundDurationConfig
                roundDuration={roundDuration}
                setRoundDuration={setRoundDuration}
              />
            );
          }}
        >
          {roundDuration}
        </BaseButton>{" "}
        rounds.
        <div>The game will be finished after {roundDuration} rounds.</div>
      </div>
      <LineSpace></LineSpace>
      <div style={containerParamStyle}>
        {" "}
        <DisplayTimeOptions
          timeType={timeType}
          setTimeType={setTimeType}
          roundTime={roundTime}
          setRoundTime={setRoundTime}
        ></DisplayTimeOptions>
      </div>
      <LineSpace></LineSpace>
      <BaseButton
        onClick={() => {
          createGame(
            draft,
            passwordGame,
            amountPlayers,
            playerNameHidden,
            vpLimit,
            timeType,
            roundTime,
            roundFixedTime,
            roundDuration
          );
        }}
      >
        Create Game
      </BaseButton>
    </div>
  );
};

const DisplayTimeOptions = ({
  timeType,
  setTimeType,
  roundTime,
  setRoundTime,
}) => {
  const TimeMode = ({ timeType, setTimeType }) => {
    return (
      <div>
        <div>
          <BaseButton
            onClick={() => {
              setTimeType(Game.TIME_TYPE_FREE);
              Popup.deActivateBlackPopup();
            }}
          >
            Free
          </BaseButton>
          <BaseButton
            onClick={() => {
              UIMessage.displayInfoMessageToStaticGameData(
                "Round Duration",
                "This feature is not available yet. It will allow you to set a duration for each round in minutes or days. The next round will be automatically triggered at the end of the duration."
              );
            }}
          >
            Round Duration
          </BaseButton>
          <BaseButton
            onClick={() => {
              UIMessage.displayInfoMessageToStaticGameData(
                "Round Duration",
                "This feature is not available yet. It will allow you to set a fixed time on which the next round will be triggered. For example, if you set 8:00 PM, the next round will be triggered at 8:00 PM."
              );
            }}
          >
            Fixed Time
          </BaseButton>
        </div>
      </div>
    );
  };
  const SelectDuration = ({ setDuration }) => {
    return (
      <div>
        <div>Minutes</div>
        <div>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_5_MIN);
              Popup.deActivateBlackPopup();
            }}
          >
            5
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_7_MIN);
              Popup.deActivateBlackPopup();
            }}
          >
            7
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_10_MIN);
              Popup.deActivateBlackPopup();
            }}
          >
            10
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_15_MIN);
              Popup.deActivateBlackPopup();
            }}
          >
            15
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_20_MIN);
              Popup.deActivateBlackPopup();
            }}
          >
            20
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_30_MIN);
              Popup.deActivateBlackPopup();
            }}
          >
            30
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_45_MIN);
              Popup.deActivateBlackPopup();
            }}
          >
            45
          </BaseButton>
        </div>
        <hr></hr>
        <div>Hours</div>
        <div>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_1_HOUR);
              Popup.deActivateBlackPopup();
            }}
          >
            1
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_2_HOUR);
              Popup.deActivateBlackPopup();
            }}
          >
            2
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_3_HOUR);
              Popup.deActivateBlackPopup();
            }}
          >
            3
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_4_HOUR);
              Popup.deActivateBlackPopup();
            }}
          >
            4
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_6_HOUR);
              Popup.deActivateBlackPopup();
            }}
          >
            6
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_8_HOUR);
              Popup.deActivateBlackPopup();
            }}
          >
            8
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_12_HOUR);
              Popup.deActivateBlackPopup();
            }}
          >
            12
          </BaseButton>
        </div>
        <hr></hr>
        <div>Days</div>
        <div>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_1_DAY);
              Popup.deActivateBlackPopup();
            }}
          >
            1
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_2_DAY);
              Popup.deActivateBlackPopup();
            }}
          >
            2
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_3_DAY);
              Popup.deActivateBlackPopup();
            }}
          >
            3
          </BaseButton>
          <BaseButton
            onClick={() => {
              setDuration(Time.TIME_7_DAY);
              Popup.deActivateBlackPopup();
            }}
          >
            7
          </BaseButton>
        </div>
      </div>
    );
  };

  let mode = "Free";
  let modeDescription =
    "Players will have unlimited time to play their turn. The next round will be triggered when all players have ended their turn.";
  if (timeType === Game.TIME_TYPE_ROUND_DURATION) {
  } else if (timeType === Game.TIME_TYPE_ROUND_FIXED_TIME) {
  }

  return (
    <div>
      <div>
        Time mode :
        <BaseButton
          onClick={() => {
            Popup.activateBlackPopup(
              <TimeMode
                timeType={timeType}
                setTimeType={setTimeType}
              ></TimeMode>
            );
          }}
        >
          {mode}
        </BaseButton>{" "}
      </div>
      <div>{modeDescription}</div>
      {timeType === Game.TIME_TYPE_FREE && (
        <div>
          <LineSpace></LineSpace>
          <div>
            <span>Preferable round duration : </span>
            <BaseButton
              onClick={() => {
                Popup.activateBlackPopup(
                  <SelectDuration setDuration={setRoundTime}></SelectDuration>
                );
              }}
            >
              {Time.getDisplayTimeInString(roundTime)}
            </BaseButton>
          </div>
        </div>
      )}
    </div>
  );
};

const DisplayVPLimitConfig = ({ vpLimit, setVpLimit }) => {
  return (
    <div>
      {[80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200].map(
        (num) => (
          <BaseButton
            key={num}
            onClick={() => {
              setVpLimit(num);
              Popup.deActivateBlackPopup();
            }}
          >
            {num}
          </BaseButton>
        )
      )}
    </div>
  );
};

const DisplayRoundDurationConfig = ({ roundDuration, setRoundDuration }) => {
  return (
    <div>
      {[4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((num) => (
        <BaseButton
          key={num}
          onClick={() => {
            setRoundDuration(num);
            Popup.deActivateBlackPopup();
          }}
        >
          {num}
        </BaseButton>
      ))}
    </div>
  );
};

const createGame = (
  draft,
  passwordGame,
  amountPlayers,
  playerNameHidden,
  vpLimit,
  timeType,
  roundTime,
  roundFixedTime,
  roundDuration
) => {
  //Log the parameters

  const game = Game.createGame(
    null,
    amountPlayers,
    "Custom",
    draft,
    passwordGame,
    playerNameHidden,
    false,
    vpLimit,
    null,
    timeType,
    roundTime,
    roundFixedTime,
    roundDuration
  );
  console.log("DEBUG game : ", game);

  Request.callPostAPI({ game: game }, "create-custom-game", () => {
    Popup.goBack();
  });
};

export const CreateCustomGame = () => {
  const [draft, setDraft] = useState(true);
  const [passwordGame, setPasswordGame] = useState("");
  const [amountPlayers, setAmountPlayers] = useState(6);
  const [playerNameHidden, setPlayerNameHidden] = useState(false);
  const [vpLimit, setVpLimit] = useState(100);
  const [roundDuration, setRoundDuration] = useState(8);
  const [timeType, setTimeType] = useState(Game.TIME_TYPE_FREE);
  const [roundTime, setRoundTime] = useState(Time.TIME_1_DAY);
  const [roundFixedTime, setRoundFixedTime] = useState(null);

  const navigationData = {
    buttonsData: [
      {
        text: "Cancel",
        callback: () => Popup.goBack(),
      },
    ],
    taskBarData: { text: "Connected as : " + StaticGameData.username },
  };

  return (
    <GameGlobalLayout showTitle={false} backgroundType="uniform">
      <div
        className="text-box-center"
        style={
          {
            //height: "100%", --> Cause issue with scrolling
            //Border inside red with box shadow
          }
        }
      >
        <div className="div-fill">
          <DisplayGameParameters
            draft={draft}
            setDraft={setDraft}
            passwordGame={passwordGame}
            setPasswordGame={setPasswordGame}
            amountPlayers={amountPlayers}
            setAmountPlayers={setAmountPlayers}
            playerNameHidden={playerNameHidden}
            setPlayerNameHidden={setPlayerNameHidden}
            vpLimit={vpLimit}
            setVpLimit={setVpLimit}
            timeType={timeType}
            setTimeType={setTimeType}
            roundTime={roundTime}
            setRoundTime={setRoundTime}
            roundFixedTime={roundFixedTime}
            setRoundFixedTime={setRoundFixedTime}
            roundDuration={roundDuration}
            setRoundDuration={setRoundDuration}
          />
        </div>
      </div>
      <MenuNavigationView navigationData={navigationData} />
    </GameGlobalLayout>
  );
};
