import React, { useState, useEffect, useMemo } from "react";
import "../../CSS/Menu.css";

import IconGridView from "./IconGridView.jsx";
import IconView from "../Icon/IconView.jsx";
import SpaceObjectImageView from "../Object/SpaceObjectImageView.jsx";
import GameState from "../../Data/GameData/Game/GameState.js";
import Logo from "../Utils/Logo.jsx";
import FactionLogo from "../Utils/FactionLogo.jsx";
import FleetsSummaryView from "../ManageSystem/FleetsSummaryView.jsx";
import UnitImageView from "../Object/UnitImageView.jsx";
import UnitsStackView from "../Units/UnitsStackView.jsx";
import ImagesUrl from "../../Data/Other/ImagesUrl.js";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject.js";
import SecondaryObjectView from "./SecondaryObjectView.jsx";
import CuriosityView from "./CuriosityView.jsx";
import DraftData from "../../Data/GameData/Faction/DraftData.js";
import BaseButton from "../Utils/BaseButton.jsx";
import MinorFactionObjectView from "../Object/MinorFactionObjectView.jsx";
import HeroNames from "../../Data/GameData/MinorFaction/HeroNames.js";
import GameGlobalLayout from "../GameGlobalLayout.jsx";
import NavigationView from "../NavigationView.jsx";
import FactionAbilityDetailedView from "../Faction/FactionAbilityDetailedView.jsx";
import FactionPickSheetView from "../Faction/FactionPickSheetView.jsx";
import HeroInfoView from "../Faction/HeroInfoView.jsx";
import BodyView from "../Faction/BodyView.jsx";
import Map from "../../Data/GameData/MapData/Map.js";
import UIMessage from "../../Data/GameData/Connection/UIMessage.js";
import DraftActionData from "../../Data/GameData/ActionData/DraftActionData.js";
import BaseImage from "../Utils/BaseImage.jsx";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Popup = require("../../Data/Other/Popup.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const ActivationData = require("../../Data/GameData/Activation/ActivationData.js");
const Phase = require("../../Data/GameData/Game/Phase.js");
const EndRoundReviewCA = require("../../ClientAction/EndRoundReviewCA.js");
const Unit = require("../../Data/GameData/MapData/Unit.js");

const DisplayPlayers = ({ draftData, playerData }) => {
  const players = DraftData.getPlayers(draftData);
  //console.log("draftData ", draftData);
  const activePlayer = DraftData.getActivePlayer(draftData);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {players.map((player, index) => {
        const otherPlayerData = PlayerData.getPlayerDataFromInGameId(
          playerData,
          player.playerInGameId
        );
        const hero = DraftData.getHeroFromPlayerInGameId(
          draftData,
          player.playerInGameId
        );

        const mapPosition = DraftData.getPositionFromPlayerInGameId(
          draftData,
          player.playerInGameId
        );

        return (
          <div
            key={index}
            style={{
              border:
                activePlayer.playerInGameId === otherPlayerData.playerInGameId
                  ? "4px solid " + Color.BASE_BUTTON_BORDER
                  : "1px solid white",
              borderRadius: "5px",
              padding: "10px",
              margin: "5px",
            }}
          >
            <div>
              {playerData.playerInGameId === otherPlayerData.playerInGameId && (
                <span style={{ color: "gold" }}>You : </span>
              )}
              {otherPlayerData.username
                ? otherPlayerData.username
                : "Player " + otherPlayerData.playerInGameId}
            </div>
            <div>
              Faction :{" "}
              {otherPlayerData.faction ? (
                <span>
                  <FactionLogo
                    factionName={otherPlayerData.faction.name}
                  ></FactionLogo>{" "}
                  {otherPlayerData.faction.name}
                </span>
              ) : (
                "?"
              )}{" "}
            </div>
            <div>
              Hero :{" "}
              {hero ? <span style={{ color: "gold" }}>{hero}</span> : "?"}{" "}
            </div>
            <div style={{ color: mapPosition ? "gold" : "white" }}>
              Position :{" "}
              {mapPosition ? (
                <span>
                  {
                    Map.getSystemFromCoords(
                      playerData.map,
                      mapPosition.mapPosition.x,
                      mapPosition.mapPosition.y
                    ).sliceName
                  }
                </span>
              ) : (
                "?"
              )}{" "}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const DisplayHeroes = ({ draftData, playerData }) => {
  const heroList = DraftData.getHeroList(draftData);

  //return;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      {heroList.map((hero, index) => {
        const imageStyle = {
          width: "100px",
          height: "auto",
          display: "block",
        };
        const isHeroPicked = DraftData.isHeroPicked(draftData, hero.hero.name);
        const playerFromHero = DraftData.getPlayerFromPickedHero(
          draftData,
          hero.hero.name
        );

        const isPlayerOwnerOfHero = playerFromHero
          ? playerData.playerInGameId === playerFromHero.playerInGameId
          : false;
        const buttonType = isPlayerOwnerOfHero
          ? "gold"
          : isHeroPicked
          ? "greyOut"
          : "white";

        return (
          <div style={{ margin: "5px" }} key={index}>
            <div
            /*style={{
                backgroundColor: Color.COLOR_LIGHT_SHADOW,
                //borderRadius: size / 8,
                //width: size + "px",
                //backgroundColor: "red",
                //padding: size / 8 + "px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "5px",
                overflow: "hidden",
                borderColor: "rgb(54,80,134)",
              }}*/
            >
              <BaseButton
                type={buttonType}
                onClick={() => {
                  Popup.addDynamicLayer(
                    <HeroInfoView hero={hero.hero}></HeroInfoView>
                  );
                }}
              >
                <div className="div-ver-center"></div>
                <div style={{ position: "relative" }}>
                  <BaseImage
                    style={imageStyle}
                    src={HeroNames.getHeroImageFileName(hero.hero.name)}
                    alt={"LOGO_" + hero.hero.name}
                  />{" "}
                  {isHeroPicked && (
                    <div
                      style={{
                        ...imageStyle,
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                    >
                      <BaseImage
                        style={imageStyle}
                        src={ImagesUrl.URL_RED_CROSS_ICON}
                        alt={"red_corss"}
                      />
                    </div>
                  )}
                </div>

                <div>{hero.hero.populationCost}</div>
              </BaseButton>
            </div>
            {isHeroPicked ? (
              <BaseButton
                type={buttonType}
                onClick={() => {
                  UIMessage.displayInfoMessage(
                    "Hero Picked",
                    "This hero is already picked."
                  );
                }}
              >
                <div>{hero.hero.name}</div>
                <div style={{ color: isPlayerOwnerOfHero ? "gold" : "red" }}>
                  {PlayerData.getPlayerDisplayName(
                    playerData,
                    DraftData.getPlayerFromPickedHero(draftData, hero.hero.name)
                      .playerInGameId
                  )}
                </div>
              </BaseButton>
            ) : (
              <BaseButton
                onClick={() => {
                  UIMessage.displayConfirmMessage(
                    "Are you sure ?",
                    "Are you sure you want to pick this hero ?",
                    () => {
                      Popup.unStackToMap();
                      DraftActionData.resolvePickHeroClient(hero.hero);
                    }
                  );
                }}
              >
                <div>{hero.hero.name}</div>
                <div>Pick</div>
              </BaseButton>
            )}
          </div>
        );
      })}
    </div>
  );
};

const DisplayFactions = ({ draftData, playerData }) => {
  const factionList = DraftData.getFactionList(draftData);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      {factionList.map((factionDraftData, index) => {
        const faction = factionDraftData.faction;

        const isFactionPicked = DraftData.isFactionPicked(
          draftData,
          faction.name
        );
        const playerFromFaction = DraftData.getPlayerFromPickedFaction(
          draftData,
          faction.name
        );

        const isPlayerOwnerOfFaction = playerFromFaction
          ? playerData.playerInGameId === playerFromFaction.playerInGameId
          : false;
        const buttonType = isPlayerOwnerOfFaction
          ? "gold"
          : isFactionPicked
          ? "greyOut"
          : "white";

        const imageStyle = {
          width: "100px",
          height: "auto",
          display: "block",
        };

        return (
          <div style={{ margin: "5px" }} key={index}>
            <div>
              {" "}
              <BaseButton
                type={buttonType}
                onClick={() => {
                  Popup.addDynamicLayer(
                    <FactionPickSheetView
                      factionName={faction.name}
                    ></FactionPickSheetView>
                  );
                }}
              >
                <div style={{ position: "relative" }}>
                  <BodyView color={faction.dedicatedColor} size="150px">
                    {" "}
                  </BodyView>
                  {isFactionPicked && (
                    <div
                      style={{
                        ...imageStyle,
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <BaseImage
                        style={imageStyle}
                        src={ImagesUrl.URL_RED_CROSS_ICON}
                        alt={"red_corss"}
                      />
                    </div>
                  )}
                </div>
              </BaseButton>
            </div>

            {isFactionPicked ? (
              <BaseButton
                type={buttonType}
                onClick={() => {
                  UIMessage.displayInfoMessage(
                    "Faction Picked",
                    "This faction is already picked."
                  );
                }}
              >
                <div>{faction.name}</div>
                <div
                  style={{
                    color: isPlayerOwnerOfFaction
                      ? "gold"
                      : isFactionPicked
                      ? "red"
                      : "white",
                  }}
                >
                  {PlayerData.getPlayerDisplayName(
                    playerData,
                    DraftData.getPlayerFromPickedFaction(
                      draftData,
                      faction.name
                    ).playerInGameId
                  )}
                </div>
              </BaseButton>
            ) : (
              <BaseButton
                onClick={() => {
                  UIMessage.displayConfirmMessage(
                    "Are you sure ?",
                    "Are you sure you want to pick this faction ?",
                    () => {
                      Popup.unStackToMap();
                      DraftActionData.resolvePickFactionClient(faction.name);
                    }
                  );
                }}
              >
                <div>
                  <FactionLogo factionName={faction.name}></FactionLogo>{" "}
                  {faction.name}
                </div>
                <div>Pick</div>
              </BaseButton>
            )}
          </div>
        );
      })}
    </div>
  );
};

const DraftView = ({ draftData, navigation = false }) => {
  const playerData = StaticGameData.getPlayerData();
  //return;

  if (navigation === true) {
    const navigationData = {
      buttonsData: [
        {
          text: "Back",
          callback: () => Popup.goBack(),
        },
      ],
    };

    return (
      <GameGlobalLayout showTitle={false} backgroundType="uniform">
        <div className="text-box-center">
          <DisplayPlayers
            draftData={draftData}
            playerData={playerData}
          ></DisplayPlayers>
          <hr></hr>
          <DisplayHeroes
            draftData={draftData}
            playerData={playerData}
          ></DisplayHeroes>
          <hr></hr>
          <DisplayFactions
            draftData={draftData}
            playerData={playerData}
          ></DisplayFactions>
        </div>
        <NavigationView navigationData={navigationData} />
      </GameGlobalLayout>
    );
  }

  return (
    <div>
      <DisplayPlayers
        draftData={draftData}
        playerData={playerData}
      ></DisplayPlayers>
      <hr></hr>
      <DisplayHeroes
        draftData={draftData}
        playerData={playerData}
      ></DisplayHeroes>
      <hr></hr>
      <DisplayFactions
        draftData={draftData}
        playerData={playerData}
      ></DisplayFactions>
    </div>
  );
};

/*<div
            className="div-fill"
            style={{ position: "relative", height: "20%" }}
          >
            <ObjectText></ObjectText>
          </div>*/

export default DraftView;
