import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";

import IconGridView from "./IconGridView.jsx";
import IconView from "../Icon/IconView.jsx";
import SpaceObjectImageView from "../Object/SpaceObjectImageView.jsx";
import GameState from "../../Data/GameData/Game/GameState.js";
import Logo from "../Utils/Logo.jsx";
import FactionLogo from "../Utils/FactionLogo.jsx";
import FleetsSummaryView from "../ManageSystem/FleetsSummaryView.jsx";
import UnitImageView from "../Object/UnitImageView.jsx";
import UnitsStackView from "../Units/UnitsStackView.jsx";
import BaseButton from "../Utils/BaseButton.jsx";
import ModifyFleetLimitAction from "../../Data/GameData/ActionData/ModifyFleetLimitAction.js";
import TechButtonView from "../Technology/TechButtonView.jsx";
import InteractionFunctions from "../UIInteraction/InteractionFunctions.jsx";
import InventoryView from "../Transaction/InventoryView.jsx";
import AllAbilitiesView from "../Ability/AllAbilitiesView.jsx";
import Objectives from "../../Data/GameData/Objectifs/Objectives.js";
import PublicObjectiveView from "../Scoring/PublicObjectiveView.jsx";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Popup = require("../../Data/Other/Popup.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const ActivationData = require("../../Data/GameData/Activation/ActivationData.js");
const Phase = require("../../Data/GameData/Game/Phase.js");
const EndRoundReviewCA = require("../../ClientAction/EndRoundReviewCA.js");
const Unit = require("../../Data/GameData/MapData/Unit.js");

const IMAGE_PLANET_TEST = "/Images/Map/Objects/planet_test.png";
const IMAGE_SHIP_URL = "/Images/Map/Icon/icon_ship.png";
const IMAGE_GROUND_FORCE_URL = "/Images/Map/Icon/icon_ground_force.png";

const ICON_BACKGROUND_OPACITY = 0.75;
const ICON_FONT_SIZE_PC = 100;

const GRID_WIDTH = 5;
const GRID_HEIGHT = 5;

const ObjectiveMapView = ({}) => {
  const playerData = StaticGameData.getPlayerData();

  const publicObjectives = Objectives.getPublicObjectives(playerData);

  //Otherwise return normal system display
  return (
    <div>
      <div>
        {" "}
        <div style={{ color: "gold", fontSize: "1.2em" }}>Objectives</div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          {publicObjectives.map((objectiveName, index) => (
            <div key={index}>
              <PublicObjectiveView
                objectiveName={objectiveName}
              ></PublicObjectiveView>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

/*<div
            className="div-fill"
            style={{ position: "relative", height: "20%" }}
          >
            <ObjectText></ObjectText>
          </div>*/

export default ObjectiveMapView;
