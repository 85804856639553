class CustomChars {
  static numberToLetter(number) {
    // Ensure the input is a positive integer between 1 and 26
    if (Number.isInteger(number) && number > 0 && number <= 26) {
      // Convert the number to a letter using ASCII code
      return String.fromCharCode(64 + number);
    } else {
      return "Invalid input. Please enter a number between 1 and 26.";
    }
  }

  static replaceFirstLetterByLowerCase(string) {
    // Ensure the input is a string
    if (typeof string === "string") {
      // Replace the first letter of the string with its lowercase equivalent
      return string.replace(string[0], string[0].toLowerCase());
    } else {
      return "Invalid input. Please enter a string.";
    }
  }
}

module.exports = CustomChars;
