const Unit = require("./Unit.js");
const Rules = require("../Game/Rules.js");
const System = require("./System.js");
const Fleet = require("./Fleet.js");
const Planet = require("./Planet.js");
const SecondaryObject = require("./SecondaryObject.js");
const TechTree = require("../Technology/TechTree.js");
const TechList = require("../Technology/TechList.js");
const Curiosity = require("./Curiosity.js");
const FactionAbility = require("../Faction/FactionAbility.js");
const Item = require("../Transactions/Item.js");
const Law = require("../Law/Law.js");

class Map {
  static getSystemFromCoords(map, x, y) {
    /*console.log("Map getSystemFromCoords x: ", x);
    console.log("Map getSystemFromCoords y: ", y);
    console.log("Map getSystemFromCoords map: ", map);*/

    const mapSizeY = map.systems.length;
    y = ((((y - 1) % mapSizeY) + mapSizeY) % mapSizeY) + 1;
    const test = -1 % 3;
    if (x < 1) {
      return null;
    }
    if (x > map.systems[0].length) {
      return null;
    }

    return map.systems[y - 1][x - 1];
  }

  static getSizeX(map) {
    return map.systems[0].length;
  }

  static getSizeY(map) {
    return map.systems.length;
  }

  static getSystemList(map) {
    const systemList = [];
    for (let y = 0; y < map.systems.length; y++) {
      for (let x = 0; x < map.systems[0].length; x++) {
        systemList.push(map.systems[y][x]);
      }
    }
    return systemList;
  }

  static getPlanetList(map) {
    const planetList = [];
    const systemList = this.getSystemList(map);
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];
      const planets = System.getPlanets(system);
      for (let j = 0; j < planets.length; j++) {
        planetList.push(planets[j]);
      }
    }
    return planetList;
  }

  static getSystemFromCoordsObject(map, systemCoords) {
    return this.getSystemFromCoords(map, systemCoords.x, systemCoords.y);
  }

  static replaceSystemFromCoords(map, x, y, system) {
    System.setCoords(system, x, y);
    map.systems[y - 1][x - 1] = system;
  }

  //Distances

  static getDistanceBetweenSystems(
    playerData,
    originSystem,
    targetSystem,
    map,
    modeShipMove = false,
    context = {}
  ) {
    const recordedDistances = [];
    const recordDistance = (systemName, distance) => {
      for (const recordedDistance of recordedDistances) {
        if (recordedDistance.systemName === systemName) {
          if (recordedDistance.distance > distance) {
            recordedDistance.distance = distance;
          }
          return;
        }
      }
      recordedDistances.push({ systemName, distance });
    };

    const getbestDistanceFromSystemName = (systemName) => {
      for (const recordedDistance of recordedDistances) {
        if (recordedDistance.systemName === systemName) {
          return recordedDistance.distance;
        }
      }
      return Infinity;
    };

    // Helper function to perform the recursive search
    const searchPaths = (
      currentSystem,
      currentDistance,
      visitedSystems,
      contextP = {
        gravitySails: false,
        comingFromNebulae: false,
        propulsionAidUsed: false,
      }
    ) => {
      // If we reach the target system, return the distance
      if (currentSystem.name === targetSystem.name) {
        return currentDistance;
      }

      if (currentSystem.name === "Liftoff") {
        console.log("DEBUG: ");
      }
      //Apply propulsion aid logic
      if (currentSystem.name !== originSystem.name) {
        if (
          Item.playerHasExhaustedItemNotProduceBySelfFaction(
            playerData,
            Item.NAME_PROPULSION_AID
          ) &&
          modeShipMove
        ) {
          if (!contextP.propulsionAidUsed) {
            const item = Item.getItemFromName(
              playerData,
              Item.NAME_PROPULSION_AID
            );

            if (item.data.faction !== playerData.faction.name) {
              if (
                System.isThereUnitsOfFaction(currentSystem, item.data.faction)
              ) {
                currentDistance = currentDistance - 1;
                contextP.propulsionAidUsed = true;
              }
            }
          }
        }
      }

      // If the current distance is not shorter than the recorded best, stop processing
      const bestDistance = getbestDistanceFromSystemName(currentSystem.name);
      if (currentDistance >= bestDistance) {
        // If the current distance is not shorter than the recorded best, stop processing
        return Infinity;
      }

      // Update recorded distance as this is the new shortest path to this system
      recordDistance(currentSystem.name, currentDistance);

      // Add the current system to the visited set
      visitedSystems.add(currentSystem.name);

      // Get all adjacent systems
      const adjacentSystems = Map.getAdjacentSystems(
        playerData,
        currentSystem,
        map,
        modeShipMove
      );

      // Variable to keep track of the shortest distance found
      let shortestDistance = Infinity;

      //Nebulae effect PAY attention the effect when leaving nebulae is treated din SystemAction can unit move
      if (contextP.comingFromNebulae) {
        if (
          !FactionAbility.hasFactionAbility(
            playerData,
            FactionAbility.VAHL_AR_SKYBORNE_GRAVITY_SAILS
          )
        ) {
          if (modeShipMove === true) {
            return Infinity;
          }
        }
      }

      for (const adjacentSystem of adjacentSystems) {
        //Copy context
        const context = { ...contextP };

        // Skip if the system has already been visited
        if (visitedSystems.has(adjacentSystem.name)) continue;

        let incrementDistance = 1;

        // Apply gravity sails logic
        if (
          FactionAbility.hasFactionAbility(
            playerData,
            FactionAbility.VAHL_AR_SKYBORNE_GRAVITY_SAILS
          ) &&
          !context.gravitySails &&
          currentSystem.backgroundObject ===
            Curiosity.object.asteroidField.name &&
          modeShipMove
        ) {
          incrementDistance = 0;
          context.gravitySails = true;
        }

        //Ships leaving nebulae have 1 move PAY attention the effect when leaving nebulae is treate din SystemAction can unit move
        if (
          currentSystem.backgroundObject === Curiosity.object.nebula.name &&
          currentSystem.name !== originSystem.name
        ) {
          context.comingFromNebulae = true;
        }

        // Recur for the adjacent system, updating the distance and visited systems
        const distance = searchPaths(
          adjacentSystem,
          currentDistance + incrementDistance,
          new Set(visitedSystems), // Pass a copy of the visited systems
          context
        );
        if (currentSystem.name === "Zircon") {
          console.log("DEBUG: ");
        }
        // Update the shortest distance if a shorter one is found
        if (distance < shortestDistance) {
          shortestDistance = distance;
        }
      }

      // Return the shortest distance found (or Infinity if no valid path)
      return shortestDistance;
    };

    // Start the recursive search
    const initialVisitedSystems = new Set();
    const result = searchPaths(originSystem, 0, initialVisitedSystems);

    // If no valid path is found, return -1
    return result === Infinity ? -1 : result;
  }

  static getMoveDistanceBetweenSystems(
    playerData,
    originSystem,
    targetSystem,
    map,
    modeShipMove = false,
    context = {}
  ) {
    return this.getDistanceBetweenSystems(
      playerData,
      originSystem,
      targetSystem,
      map,
      modeShipMove,
      { ...context }
    );
  }

  static areFactionsNeighbor(playerData, factionName1, factionName2, map) {
    const systems1 = Map.getSystemsWhereFactionHasSomething(map, factionName1);
    const systems2 = Map.getSystemsWhereFactionHasSomething(map, factionName2);

    for (let i = 0; i < systems1.length; i++) {
      for (let j = 0; j < systems2.length; j++) {
        if (
          Map.getDistanceBetweenSystems(
            playerData,
            systems1[i],
            systems2[j],
            map
          ) === 1 ||
          systems1[i].name === systems2[j].name
        ) {
          return true;
        }
      }
    }
    return false;
  }

  static getSystemsFromDistanceFromSystem(playerData, system, distance, map) {
    const systems = [];
    const systemList = Map.getSystemList(map);
    for (let i = 0; i < systemList.length; i++) {
      if (
        Map.getDistanceBetweenSystems(
          playerData,
          system,
          systemList[i],
          map
        ) === distance
      ) {
        systems.push(systemList[i]);
      }
    }
    return systems;
  }

  static getHSColonizatorOfPlanet(playerData, planet, map) {
    const hsPositions = map.playerStartingPositions;
    for (let i = 0; i < hsPositions.length; i++) {
      const HS = Map.getSystemFromCoords(
        map,
        hsPositions[i].x,
        hsPositions[i].y
      );
      if (Map.isPlanetColonizable(playerData, HS, planet)) {
        return HS;
      }
    }
    return null;
  }

  static getHomeSystemList(map) {
    const homeSystemList = [];
    const systemList = Map.getSystemList(map);
    for (let i = 0; i < systemList.length; i++) {
      if (systemList[i].isHomeSystem) {
        homeSystemList.push(systemList[i]);
      }
    }
    return homeSystemList;
  }

  static getColonizablePlanetsFromSystem(playerData, HS, map) {
    const planets = Map.getPlanetList(map);
    const colonizablePlanets = [];
    for (let i = 0; i < planets.length; i++) {
      if (Map.isPlanetColonizable(playerData, HS, planets[i])) {
        colonizablePlanets.push(planets[i]);
      }
    }
    return colonizablePlanets;
  }

  static isPlanetColonizable(playerData, homeSystem, planet) {
    try {
      this.CanObjectBeColonized(planet, homeSystem, playerData);
    } catch (e) {
      return false;
    }
    return true;
  }

  //Colinize
  static CanObjectBeColonized(object, homeSystem, playerData) {
    /*console.log("Map ObjectCanBeColonized object: ", object);
    console.log("Map ObjectCanBeColonized playerData: ", playerData);*/
    const map = playerData.map;
    if (!object.isPlanet) {
      throw new Error("Object is not a planet");
    }

    if (object.faction) {
      throw new Error("This planet is already colonized.");
    }

    const targetSystem = Map.getSystemFromSpaceObject(object, playerData.map);
    if (targetSystem === null) {
      throw new Error("Target system not found");
    }

    const isSystemInSlice = Map.isSystemInSlice(map, homeSystem, targetSystem);
    if (!isSystemInSlice) {
      throw new Error(
        "This planet is not in your starting slice. You can only colonize planets that are in your starting slice."
      );
    }

    return true;
  }

  static isSystemInSlice(map, HS, system) {
    const HsCoord = System.getCoords(HS);
    const systemCoord = System.getCoords(system);

    //Return true if on same column and connected via the map cycle.
    /*if (!map.reverseConnections) {
      if (
        HsCoord.y === Map.getSizeY(map) - 1 &&
        systemCoord.y === 1 &&
        systemCoord.x === HsCoord.x
      ) {
        return true;
      }
      if (
        HsCoord.y === Map.getSizeY(map) &&
        systemCoord.y === 2 &&
        systemCoord.x === HsCoord.x
      ) {
        return true;
      }
    } else {
      if (
        HsCoord.y === Map.getSizeY(map) - 1 &&
        systemCoord.y === 1 &&
        systemCoord.x === Map.getSizeX(map) + 1 - HsCoord.x
      ) {
        return true;
      }
      if (
        HsCoord.y === Map.getSizeY(map) &&
        systemCoord.y === 2 &&
        systemCoord.x === Map.getSizeX(map) + 1 - HsCoord.x
      ) {
        return true;
      }
    }*/

    //Otherwise, just calculate the distance
    const distanceNoCycle =
      Math.abs(HsCoord.x - systemCoord.x) + Math.abs(HsCoord.y - systemCoord.y);

    let startingXReverseConnections = HsCoord.x;
    if (map.reverseConnections) {
      startingXReverseConnections = Map.getSizeX(map) + 1 - HsCoord.x;
    }
    let cycleConnectionDistance =
      Math.abs(startingXReverseConnections - systemCoord.x) +
      Map.getSizeY(map) -
      Math.abs(HsCoord.y - systemCoord.y);

    const distance = Math.min(cycleConnectionDistance, distanceNoCycle);

    if (distance < 2) {
      return true;
    } else if (distance > 2) {
      return false;
    } else if (distance === 2) {
      //Detection of the equidistant tile in the slice with cycle
      if (HsCoord.y > Map.getSizeY(map) - 2 && systemCoord.y < 3) {
        if (startingXReverseConnections === system.x) {
          return true;
        }
      }

      //Detection of the equidistant tile not in the slice with cycle
      if (systemCoord.y > Map.getSizeY(map) - 2 && HsCoord.y < 3) {
        if (startingXReverseConnections === system.x) {
          return false;
        }
      }

      //Detection of the equidistant slice not in the slice
      if (HS.x === system.x && HS.y > system.y) {
        return false;
      }

      //Otherwise it is in the slice
      return true;
    }
    return false;
  }

  static getStartingSliceSystems(map, HS) {
    const systemList = Map.getSystemList(map);
    const systemsInStartingSlice = [];
    for (let i = 0; i < systemList.length; i++) {
      if (Map.isSystemInSlice(map, HS, systemList[i])) {
        systemsInStartingSlice.push(systemList[i]);
      }
    }
    return systemsInStartingSlice;
  }

  static getSpaceObjectFromName(map, name) {
    const systemList = Map.getSystemList(map);
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];
      const spaceObject = System.getSpaceObjectFromName(system, name);
      if (spaceObject) {
        return spaceObject;
      }
    }
    throw new Error("SpaceObject not found");
    //return null;
  }

  static getSpaceObjectsFromType(map, type) {
    const systemList = Map.getSystemList(map);
    let spaceObjects = [];
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];
      const spaceObjectReturnArray = System.getSpaceObjectsFromType(
        system,
        type
      );
      if (spaceObjectReturnArray) {
        spaceObjects = spaceObjects.concat(spaceObjectReturnArray);
      }
    }
    return spaceObjects;
  }

  static getSystemFromSystem(map, system) {
    const systemList = this.getSystemList(map);
    for (let i = 0; i < systemList.length; i++) {
      if (System.areSystemsTheSame(system, systemList[i])) {
        return systemList[i];
      }
    }

    return null;
  }

  static getSystemsFromWormhole(map, wormhole) {
    const systems = [];
    const systemList = this.getSystemList(map);
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];
      for (let j = 0; j < system.objects.length; j++) {
        const object = system.objects[j];
        if (
          object.type === SecondaryObject.SECONDARY_OBJECT_WORMHOLE &&
          object.wormhole === wormhole.wormhole
        ) {
          systems.push(system);
        }
      }
    }
    return systems;
  }

  static getSystemFromName(map, systemName) {
    const systemList = this.getSystemList(map);
    for (let i = 0; i < systemList.length; i++) {
      if (systemList[i].name === systemName) {
        return systemList[i];
      }
    }

    return null;
  }

  static getSystemsFromFaction(map, factionName) {
    const systemList = this.getSystemList(map);
    const systems = [];
    for (let i = 0; i < systemList.length; i++) {
      if (systemList[i].faction === factionName) {
        systems.push(systemList[i]);
      }
    }
    return systems;
  }

  static getSystemFromSpaceObjectName(map, spaceObjectName) {
    const systemList = this.getSystemList(map);
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];
      const spaceObject = System.getSpaceObjectFromName(
        system,
        spaceObjectName
      );
      if (spaceObject) {
        return system;
      }
    }

    return null;
  }

  static getSystemFromSpaceObject(spaceObject, map) {
    //console.log("Map getSystemFromSpaceObject spaceObject: ", spaceObject);
    const systemList = Map.getSystemList(map);
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];
      //console.log("Map getSystemFromSpaceObject system: ", system);
      if (system.objects) {
        for (let j = 0; j < system.objects.length; j++) {
          const object = system.objects[j];
          //console.log("Map getSystemFromSpaceObject object: ", object);

          if (object.name === spaceObject.name) {
            return system;
          }
        }
      }
    }
    return null;
  }

  static getFleetFromUnitId(map, unitId) {
    const systems = Map.getSystemList(map);
    for (let i = 0; i < systems.length; i++) {
      const fleet = System.getFleetFromUnitId(systems[i], unitId);
      if (fleet) {
        return fleet;
      }
    }
    return null;
  }

  static removeUnit(playerData, unit) {
    const unitId = unit.id;
    const system = Map.getSystemFromUnitId(playerData.map, unitId);
    System.removeUnit(playerData, system, unit);
  }

  static getAllUnitsFromFaction(playerData, map, factionName) {
    const systemList = Map.getSystemList(map);
    const units = [];
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];
      const systemUnits = System.getAllUnitsFromFactionIncludingPlanets(
        playerData,
        system,
        factionName
      );
      if (systemUnits.length > 0) {
        units.push(...systemUnits);
      }
    }
    return units;
  }

  static getSystemFromUnitId(map, unitId) {
    const systemList = Map.getSystemList(map);
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];
      if (System.getUnitFromId(system, unitId)) {
        return system;
      }
    }
    return null;
  }

  static getUnitFromUnitId(map, unitId) {
    const systemList = Map.getSystemList(map);
    for (let i = 0; i < systemList.length; i++) {
      const unit = System.getUnitFromId(systemList[i], unitId);
      if (unit) {
        return unit;
      }
    }
    return null;
  }

  static getUnitsFromTypeFromFaction(playerData, factionName, type) {
    const map = playerData.map;
    const systemList = Map.getSystemList(map);
    const units = [];
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];
      const systemUnits = System.getUnitsFromTypeFromFaction(
        playerData,
        system,
        factionName,
        type
      );
      if (systemUnits.length > 0) {
        units.push(...systemUnits);
      }
    }
    return units;
  }

  static getPlanetsFromFaction(map, factionName) {
    const planetList = Map.getPlanetList(map);
    const factionPlanets = [];
    for (let i = 0; i < planetList.length; i++) {
      if (planetList[i].faction === factionName) {
        factionPlanets.push(planetList[i]);
      }
    }
    return factionPlanets;
  }

  static getSystemWhereAtLeastOneUnitOfTypeFromFaction(
    playerData,
    factionName,
    type
  ) {
    const map = playerData.map;
    const systemList = Map.getSystemList(map);
    const resultSystems = [];
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];
      const units = System.getUnitsFromTypeFromFaction(
        playerData,
        system,
        factionName,
        type
      );
      if (units.length > 0) {
        resultSystems.push(system);
      }
    }

    return resultSystems;
  }

  static getPlanetsWhereFactionHasUnit(playerData, factionName) {
    const map = playerData.map;
    const planetList = Map.getPlanetList(map);
    const resultPlanets = [];
    for (let i = 0; i < planetList.length; i++) {
      const planet = planetList[i];
      const fleet = Planet.getFleet(planet, factionName);
      if (fleet && !Fleet.isEmpty(fleet)) {
        resultPlanets.push(planet);
      }
    }

    return resultPlanets;
  }

  static getPlanetsWhereAtLeastOneUnitOfTypeFromFaction(
    playerData,
    factionName,
    type
  ) {
    const map = playerData.map;
    const planetList = Map.getPlanetsFromFaction(map, factionName);
    const resultPlanets = [];
    for (let i = 0; i < planetList.length; i++) {
      const planet = planetList[i];
      const units = Planet.getUnitsFromTypeFromFaction(
        planet,
        factionName,
        type
      );
      if (units.length > 0) {
        resultPlanets.push(planet);
      }
    }

    return resultPlanets;
  }

  static getSystemsPlanetsFromFaction(map, factionName) {
    const systemList = Map.getSystemList(map);
    const systemPlanets = [];
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];
      //console.log("Map getSystemsPlanetsFromFaction system: ", system);
      const planetsTemp = System.getPlanetsFromFaction(system, factionName);
      if (planetsTemp.length > 0) {
        systemPlanets.push({ system: system, planets: planetsTemp });
      }
    }
    return systemPlanets;
  }

  static getSystemsControlledByFaction(map, factionName) {
    const systemList = Map.getSystemList(map);
    const systems = [];
    for (let i = 0; i < systemList.length; i++) {
      if (systemList[i].faction === factionName) {
        systems.push(systemList[i]);
      }
    }
    return systems;
  }

  static getSystemsWhereFactionHasShips(map, factionName) {
    const systemList = Map.getSystemList(map);
    const systems = [];
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];
      if (!Fleet.isEmpty(System.getFleet(system, factionName))) {
        systems.push(system);
      }
    }
    return systems;
  }

  static getSystemsWhereFactionHasSomething(map, factionName) {
    const systemList = Map.getSystemList(map);
    const systems = [];

    for (let i = 0; i < systemList.length; i++) {
      let unitPresence = false;

      const system = systemList[i];
      if (
        system.faction === factionName ||
        !Fleet.isEmpty(System.getFleet(system, factionName))
      ) {
        unitPresence = true;
      }
      if (!unitPresence) {
        const planets = System.getPlanets(system);
        for (let j = 0; j < planets.length; j++) {
          const planet = planets[j];
          if (
            planet.faction === factionName ||
            !Fleet.isEmpty(Planet.getFleet(planet, factionName))
          ) {
            unitPresence = true;
          }
        }
      }
      if (unitPresence) {
        systems.push(system);
      }
    }

    return systems;
  }

  static getSystemsWhereFactionHasPlanet(map, factionName) {
    const systemList = Map.getSystemList(map);
    const systems = [];
    for (let i = 0; i < systemList.length; i++) {
      for (let j = 0; j < systemList[i].objects.length; j++) {
        const object = systemList[i].objects[j];
        if (object.isPlanet && object.faction === factionName) {
          systems.push(systemList[i]);
          break;
        }
      }
    }
    return systems;
  }

  static getSpaceObjectsFromFaction = (map, factionName) => {
    const systemList = Map.getSystemList(map);
    const spaceObjects = [];
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];
      for (let j = 0; j < system.objects.length; j++) {
        const object = system.objects[j];
        if (object.faction === factionName) {
          spaceObjects.push(object);
        }
      }
    }
    return spaceObjects;
  };

  static getAdjacentSystems(playerData, system, map, modeShipMove = false) {
    let adjacentSystems = [];
    const x = system.x;
    const y = system.y;

    const mapSizeX = Map.getSizeX(map);
    const mapSizeY = Map.getSizeY(map);

    //Modulo are done in SystemFromCoords
    adjacentSystems.push(Map.getSystemFromCoords(map, x - 1, y));
    adjacentSystems.push(Map.getSystemFromCoords(map, x + 1, y));
    //Manage reverse connection
    if (y === 1 && map.reverseConnections) {
      adjacentSystems.push(
        Map.getSystemFromCoords(map, mapSizeX + 1 - x, mapSizeY)
      );
    } else {
      adjacentSystems.push(Map.getSystemFromCoords(map, x, y - 1));
    }
    if (y === mapSizeY && map.reverseConnections) {
      adjacentSystems.push(Map.getSystemFromCoords(map, mapSizeX + 1 - x, 1));
    } else {
      adjacentSystems.push(Map.getSystemFromCoords(map, x, y + 1));
    }

    //Wormholes
    if (modeShipMove === true) {
      let riftProtectionLaw = Law.getActiveLaw(
        playerData,
        Law.NAME_RIFT_PROTECTION_ACT
      );
      if (!riftProtectionLaw) {
        const spaceObjects = System.getSpaceObjects(system);
        for (let i = 0; i < spaceObjects.length; i++) {
          if (
            spaceObjects[i].type === SecondaryObject.SECONDARY_OBJECT_WORMHOLE
          ) {
            const adjacentSystemsByWormhole = Map.getSystemsFromWormhole(
              map,
              spaceObjects[i]
            ).filter((systemI) => systemI.name !== system.name);
            adjacentSystems.push(...adjacentSystemsByWormhole);
          }
        }
      }

      //if a nebulae in system, remove all adjacent systems
      /*if (
        system.backgroundObject === Curiosity.object.nebula.name &&
        !FactionAbility.hasFactionAbility(
          playerData,
          FactionAbility.VAHL_AR_SKYBORNE_GRAVITY_SAILS
        )
      ) {
        return [];
      }*/
    }

    //Remove if player can't move there
    if (modeShipMove === true) {
      if (
        !TechTree.hasPlayerTech(playerData, TechList.TECH.massDeflector.name)
      ) {
        adjacentSystems = adjacentSystems.filter((system) => {
          if (system) {
            const backgroundObject = system.backgroundObject;
            return backgroundObject !== Curiosity.object.asteroidField.name;
          }
          return false;
        });
      }
    }

    //Filter out when null in adjacentSystems
    return adjacentSystems.filter((system) => system !== null);
  }
}

module.exports = Map;
