import React, { useState, useRef, useEffect } from "react";
import "../../CSS/Font.css";
import "../../CSS/Menu.css";
import LogBookView from "../Utils/LogMessage/LogBookView";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";

const Popup = require("../../Data/Other/Popup");
const StaticGameData = require("../../Data/GameData/StaticGameData");
const ActivationData = require("../../Data/GameData/Activation/ActivationData");

const FullScreenLogView = ({
  logBook,
  scrollAtEnd,
  rememberScrollKey = null,
}) => {
  console.log("FullScreenLogView", logBook);

  const navigationData = {
    buttonsData: [
      { text: "Map", callback: () => Popup.unStackToMap() },
      { text: "Back", callback: () => Popup.goBack() },
    ],
  };

  return (
    <GameGlobalLayout
      scrollAtEnd={scrollAtEnd}
      backgroundType={"uniform"}
      rememberScrollKey={rememberScrollKey}
    >
      <div className="text-box" style={{ fontSize: "1em" }}>
        <LogBookView logBook={logBook}></LogBookView>
      </div>

      <NavigationView
        screenData={{ logBook: logBook }}
        navigationData={navigationData}
        //navigationData={navigationData}
      ></NavigationView>
    </GameGlobalLayout>
  );
};

export default FullScreenLogView;
