const Color = require("../../../Common/Config/Colors");
const StaticGameData = require("../StaticGameData");
const Popup = require("../../Other/Popup");

class LogMessage {
  static TYPE_ERROR = "error";
  static TYPE_INFO = "info";
  static TYPE_CONFIRM = "confirm";
  static TYPE_TITLE_SECTION = "title section";
  static TYPE_LINE = "line";

  static createMessage(itemList, type = this.TYPE_INFO) {
    //console.log("LogMessage createMessage", type);
    //Check if type is valid
    if (
      type !== this.TYPE_ERROR &&
      type !== this.TYPE_INFO &&
      type !== this.TYPE_CONFIRM &&
      type !== this.TYPE_TITLE_SECTION &&
      type !== this.TYPE_LINE
    ) {
      throw new Error("Invalid message type");
    }

    const logMessage = {
      type: type,
      items: [],
    };

    for (let i = 0; i < itemList.length; i++) {
      const item = itemList[i];

      if (item.type) {
        LogMessage.addItem(logMessage, item.content, item.type);
      } else {
        LogMessage.addItem(logMessage, item.content);
      }
    }

    return logMessage;
  }

  static ITEM_TYPE_UNIT = "unit";
  static ITEM_TYPE_SYSTEM = "system";
  static ITEM_TYPE_FACTION_LOGO = "faction logo";
  static ITEM_TYPE_TEXT = "text";
  static ITEM_TYPE_RESOURCE_LOGO = "resource logo";
  static ITEM_TYPE_FLEET = "fleet";
  static ITEM_TYPE_SPACE_OBJECT = "space object";
  static ITEM_TYPE_LOGO = "logo";
  static ITEM_TYPE_ITEM = "item";

  /*static addItem(logMessage, itemType, content) {
    if (itemType === this.ITEM_TYPE_UNIT) {
      this.addUnit(logMessage, content);
    }
    if (itemType === this.ITEM_TYPE_SYSTEM) {
      this.addSystem(logMessage, content);
    }
    if (itemType === this.ITEM_TYPE_FACTION_LOGO) {
      this.addFactionLogo(logMessage, content);
    }
    if (itemType === this.ITEM_TYPE_TEXT) {
      this.addText(logMessage, content);
    }
    if (itemType === this.ITEM_TYPE_RESOURCE_LOGO) {
      this.addResourceLogo(logMessage, content);
    }
    if (itemType === this.ITEM_TYPE_FLEET) {
      this.addFleet(logMessage, content);
    }
  }*/

  /*static addUnit(logMessage, unit) {
    this._addLogItem(
      logMessage,
      this.ITEM_TYPE_UNIT,
      Unit.getPrintingName(unit)
    );
  }*/

  static addText(logMessage, text) {
    this._addLogItem(logMessage, this.ITEM_TYPE_TEXT, text);
  }

  static addSystem(logMessage, system) {
    this._addLogItem(logMessage, this.ITEM_TYPE_SYSTEM, system.name);
  }

  static addFactionLogo(logMessage, factionName) {
    this._addLogItem(logMessage, this.ITEM_TYPE_FACTION_LOGO, factionName);
  }

  static addResourceLogo(logMessage, resourceType) {
    this._addLogItem(logMessage, this.ITEM_TYPE_RESOURCE_LOGO, resourceType);
  }

  static addFleet(logMessage, fleet) {
    //register deep json copy of fleet
    this._addLogItem(
      logMessage,
      this.ITEM_TYPE_FLEET,
      JSON.parse(JSON.stringify(fleet))
    );
  }

  static addItem(logMessage, content, itemType = LogMessage.ITEM_TYPE_TEXT) {
    if (
      itemType === LogMessage.ITEM_TYPE_SYSTEM ||
      itemType === LogMessage.ITEM_TYPE_SPACE_OBJECT
    ) {
      logMessage.items.push({
        type: itemType,
        content: content.name,
      });
      return;
    }

    if (itemType === LogMessage.ITEM_TYPE_FLEET) {
      logMessage.items.push({
        type: itemType,
        content: JSON.parse(JSON.stringify(content)),
      });
      return;
    }

    if (itemType === LogMessage.ITEM_TYPE_UNIT) {
      logMessage.items.push({
        type: itemType,
        content: JSON.parse(JSON.stringify(content)),
      });
      return;
    }

    logMessage.items.push({
      type: itemType,
      content: content,
    });
  }
}

module.exports = LogMessage;
