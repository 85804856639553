const Popup = require("../../Other/Popup");
const StaticGameData = require("../StaticGameData");
const Map = require("../MapData/Map");
const Rules = require("../Game/Rules");
const PlayerData = require("../PlayerData/PlayerData");
const UIMessage = require("../Connection/UIMessage");
const SpaceObject = require("../MapData/SpaceObject");
const Request = require("../../../Common/Requests/Request.js");
const SystemAction = require("../ActionCommon/SystemAction");
const ActivationData = require("../Activation/ActivationData");

class OngoingActionActivation {
  static OngoingActionActivation(ongoingActionData) {
    if (!ongoingActionData) {
      throw new Error("OngoingActionActivation : no ongoingActionData");
    }
    if (!ongoingActionData.activationStep) {
      throw new Error("OngoingActionActivation : no activationStep");
    }
    const playerData = StaticGameData.getPlayerData();
    const system = ActivationData.getActiveSystem(playerData);

    if (ongoingActionData.activationStep === "activated") {
      this.activated(ongoingActionData, system);
    }
    if (ongoingActionData.activationStep === Rules.STEP_ACTIVATION_MOVE) {
      this.stepMove(ongoingActionData, system);
    }
  }

  static activated(ongoingActionData, system) {}
  static stepMove(ongoingActionData, system) {}
}

module.exports = OngoingActionActivation;
