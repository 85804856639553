//DEPRECIATED

class MapPosition {
  constructor(
    q,
    r,
    playerInfoTile1_Q,
    playerInfoTile1_R,
    playerInfoTile2_Q,
    playerInfoTile2_R,
    playerInfoTile3_Q,
    playerInfoTile3_R
  ) {
    this.q = q;
    this.r = r;
    this.playerInfoTile1_Q = playerInfoTile1_Q;
    this.playerInfoTile1_R = playerInfoTile1_R;
    this.playerInfoTile2_Q = playerInfoTile2_Q;
    this.playerInfoTile2_R = playerInfoTile2_R;
    this.playerInfoTile3_Q = playerInfoTile3_Q;
    this.playerInfoTile3_R = playerInfoTile3_R;
  }
}

module.exports = MapPosition;
