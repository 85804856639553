import React from "react";
import RuleImage from "../Utils/RuleImage";
import RuleP from "../Utils/RuleP";
import RuleUl from "../Utils/RuleUl";
import RuleLi from "../Utils/RuleLi";
import RuleLink from "../Utils/RuleLink";
import SpaceObject from "../../Data/GameData/MapData/SpaceObject";
import PlanetBonus from "../../Data/GameData/Bonus/PlanetBonus";
import PlanetView from "../../GameView/Object/PlanetView";
import SpaceObjectImageView from "../../GameView/Object/SpaceObjectImageView";
import BaseButton from "../../GameView/Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import ManagePlanetView from "../../GameView/ManageSystem/ManagePlanetView";
import Logo from "../../GameView/Utils/Logo";
import Planet from "../../Data/GameData/MapData/Planet";
import LineSpace from "../../GameView/Utils/Layout/LineSpace";
import TechTypeView from "../../GameView/Technology/TechTypeView";
import Color from "../../Common/Config/Colors";

const NonReplacableText = ({ text }) => {
  return <span>{text}</span>;
};

export default NonReplacableText;
