class ReplayAction {
  static NAME_MOVE = "move";

  /**
   * Create a new ReplayAction object
   * @param {string} actionName - The name of the main action
   * @param {object} data - Additional data related to the action
   * @returns {object} A new ReplayAction object
   */
  static create(actionName, data) {
    return {
      actionName,
      subActions: [],
      data,
    };
  }

  /**
   * Add a sub-action to a ReplayAction object
   * @param {object} replayAction - The ReplayAction object
   * @param {string} subActionName - The name of the sub-action
   * @param {object} subActionData - Additional data for the sub-action
   */
  static addSubAction(replayAction, subActionName, subActionData = {}) {
    if (!replayAction.subActions) {
      replayAction.subActions = [];
    }
    replayAction.subActions.push({
      name: subActionName,
      data: subActionData,
    });
  }

  /**
   * Get all sub-actions from a ReplayAction object
   * @param {object} replayAction - The ReplayAction object
   * @returns {Array} List of sub-actions
   */
  static getSubActions(replayAction) {
    return replayAction.subActions || [];
  }

  //Replay action playing is the action that should be played
  static getReplayData(playerData) {
    if (!playerData.replayData) {
      playerData.replayData = {};
    }

    return playerData.replayData;
  }

  static getReplayActionsPlaying(playerData) {
    const replayData = this.getReplayData(playerData);
    if (!replayData.replayActionsPlaying) {
      replayData.replayActionsPlaying = [];
    }

    return replayData.replayActionsPlaying;
  }

  static setReplayActionsPlaying(playerData, replayActionsPlaying) {
    const replayData = this.getReplayData(playerData);
    replayData.replayActionsPlaying = replayActionsPlaying;
  }

  static addReplayActionPlaying(playerData, replayAction) {
    const replayActionsPlaying = this.getReplayActionsPlaying(playerData);
    replayActionsPlaying.push(replayAction);
  }

  static getNextReplayActionPlaying(playerData) {
    const replayActionsPlaying = this.getReplayActionsPlaying(playerData);
    return replayActionsPlaying.shift();
  }

  static isAnimationPlaying(playerData) {
    const replayActionsPlaying = this.getReplayActionsPlaying(playerData);
    return replayActionsPlaying.length > 0;
  }
}

module.exports = ReplayAction;
