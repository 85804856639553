const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Cost = require("../Utils/Cost.js");
const SpaceObject = require("../MapData/SpaceObject.js");
const LogBook = require("../Connection/LogBook.js");
const Phase = require("../Game/Phase.js");
const ActionCommonData = require("./ActionCommonData.js");

class TransferActionData {
  //Transfer Functions

  static isElligible(playerData, planet) {
    if (planet.faction === playerData.faction.name) {
      return true;
    } else {
      return false;
    }
  }

  static VIEW_GLOBAL_VIEW = "GlobalView";
  static VIEW_TRANSFER = "Transfer";

  static switchToView(playerData, view) {
    ActionCommonData.getData(playerData).view = view;

    if (view === this.VIEW_TRANSFER) {
      const planet = this.getSelectedPlanet(playerData);
      ActionCommonData.getData(playerData).amountMineral = 0;
      ActionCommonData.getData(playerData).amountPopulation = 0;
      ActionCommonData.getData(playerData).availablePopulation =
        planet.unassignedPop;
      ActionCommonData.getData(playerData).availableMineral = planet.mineral;
    }
    Popup.touch();
  }

  static getView(playerData) {
    return ActionCommonData.getData(playerData).view;
  }

  static prepare(playerData, planet) {
    const data = {
      receivingPlanet: planet,
      selectedPlanet: null,
      view: this.VIEW_GLOBAL_VIEW,
      amountMineral: 0,
      amountPopulation: 0,
      availablePopulation: 0,
      availableMineral: 0,
      directTransfer: false,
    };

    ActionCommonData.prepare(
      playerData,
      Phase.PHASE_ACTION,
      Phase.STEP_ACTION_TRANSFER,
      data
    );
  }

  static prepareDirectTransfer(playerData, receivingPlanet, sendingPlanet) {
    this.prepare(playerData, receivingPlanet);
    const data = ActionCommonData.getData(playerData);
    this.selectPlanet(playerData, sendingPlanet);
    data.view = this.VIEW_TRANSFER;
    data.directTransfer = true;
  }

  static setAmountMineral(playerData, amount) {
    ActionCommonData.getData(playerData).amountMineral = amount;
  }

  static setAmountPopulation(playerData, amount) {
    ActionCommonData.getData(playerData).amountPopulation = amount;
  }

  static getReceivingPlanet(playerData) {
    return Map.getSpaceObjectFromName(
      playerData.map,
      ActionCommonData.getData(playerData).receivingPlanet.name
    );
  }

  static selectPlanet(playerData, planet) {
    ActionCommonData.getData(playerData).selectedPlanet = planet;
  }

  static unSelectPlanet(playerData) {
    ActionCommonData.getData(playerData).selectedPlanet = null;
  }

  static getSelectedPlanet(playerData) {
    return Map.getSpaceObjectFromName(
      playerData.map,
      ActionCommonData.getData(playerData).selectedPlanet.name
    );
  }

  static getSelectedPlanetData(playerData) {
    const data = {
      amountMineral: ActionCommonData.getData(playerData).amountMineral,
      amountPopulation: ActionCommonData.getData(playerData).amountPopulation,
      availableMineral: ActionCommonData.getData(playerData).availableMineral,
      availablePopulation:
        ActionCommonData.getData(playerData).availablePopulation,
    };
    return data;
  }

  static addMineral(playerData, amount) {
    const data = ActionCommonData.getData(playerData);
    if (
      amount < 0 &&
      ActionCommonData.getData(playerData).amountMineral + amount < 0
    ) {
      return;
    }
    if (
      amount > 0 &&
      ActionCommonData.getData(playerData).amountMineral + amount >
        data.availableMineral
    ) {
      return;
    }
    ActionCommonData.getData(playerData).amountMineral = CustomMath.roundDec(
      ActionCommonData.getData(playerData).amountMineral + amount
    );
    Popup.touch();
  }

  static addPopulation(playerData, amount) {
    const planet = this.getSelectedPlanet(playerData);
    const receivingPlanet = this.getReceivingPlanet(playerData);
    if (
      amount < 0 &&
      ActionCommonData.getData(playerData).amountPopulation + amount < 0
    ) {
      return;
    }

    if (
      amount > 0 &&
      ActionCommonData.getData(playerData).amountPopulation + amount >
        planet.unassignedPop
    ) {
      return;
    }

    if (
      amount > 0 &&
      ActionCommonData.getData(playerData).amountPopulation +
        amount +
        receivingPlanet.population >
        Planet.getMaxAmountPopulation(playerData, receivingPlanet)
    ) {
      UIMessage.displayInfoMessage(
        "Planet too small",
        "You can't transfer more population than the planet can hold. " +
          receivingPlanet.name +
          " has a size of " +
          receivingPlanet.size +
          ", and it can hold a maximum of " +
          Planet.getMaxAmountPopulation(playerData, receivingPlanet) +
          " population."
      );
      return;
    }

    //Planet.assignPopulationOnTransfer(planet, amount);
    ActionCommonData.getData(playerData).amountPopulation = CustomMath.roundDec(
      ActionCommonData.getData(playerData).amountPopulation + amount
    );

    Popup.touch();
  }

  static getCostTransfer(playerData) {
    const cost = Cost.createCost({});

    const transferData = ActionCommonData.getData(playerData);

    const costTransfer = PlayerData.getCostTransfer(
      playerData,
      transferData.amountPopulation,
      transferData.amountMineral
    );

    return costTransfer;
  }

  static resolveServer(playerData, resolveData) {
    ActionCommonData.resolveServerIni(playerData, resolveData);
    console.log("resolveTransfer", ActionCommonData.getData(playerData));
    const receivingPlanet = Map.getSpaceObjectFromName(
      playerData.map,
      ActionCommonData.getData(playerData).receivingPlanet.name
    );

    const sendingPlanet = Map.getSpaceObjectFromName(
      playerData.map,
      ActionCommonData.getData(playerData).selectedPlanet.name
    );

    System.canTransferFromToPlanet(
      playerData,
      Map.getSystemFromSpaceObject(sendingPlanet, playerData.map),
      sendingPlanet
    );
    System.canTransferFromToPlanet(
      playerData,
      Map.getSystemFromSpaceObject(receivingPlanet, playerData.map),
      receivingPlanet
    );

    const amountMineral = ActionCommonData.getData(playerData).amountMineral;
    const amountPopulation =
      ActionCommonData.getData(playerData).amountPopulation;

    //Chekc that mineral and population are intergers with modulo
    CustomMath.checkInteger(amountMineral);
    CustomMath.checkInteger(amountPopulation);

    if (sendingPlanet.faction !== playerData.faction.name) {
      throw new Error("You can't transfer from a planet that is not yours");
    }
    if (amountMineral > sendingPlanet.mineral) {
      throw new Error("You can't transfer more mineral than the planet has");
    }
    if (amountPopulation > sendingPlanet.population) {
      throw new Error("You can't transfer more population than the planet has");
    }
    receivingPlanet.mineral = CustomMath.roundDec(
      receivingPlanet.mineral + amountMineral
    );
    Planet.addPopulation(receivingPlanet, amountPopulation);

    sendingPlanet.mineral = CustomMath.roundDec(
      sendingPlanet.mineral - amountMineral
    );
    Planet.removePopulation(sendingPlanet, amountPopulation);

    if (amountPopulation > 0) {
      Planet.checkAfterPlacingPop(playerData, receivingPlanet);
    }

    const cost = this.getCostTransfer(playerData);
    PlayerData.spendCost(playerData, cost);

    ActionCommonData.cleanActionData(playerData);
  }
}

module.exports = TransferActionData;
