const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const SpaceObject = require("../MapData/SpaceObject.js");
const LogBook = require("../Connection/LogBook.js");
const Phase = require("../Game/Phase.js");
const ActionCommonData = require("./ActionCommonData.js");
const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction.js");

class DropActionData {
  //Lift Functions

  static isElligible(playerData, planet) {
    if (planet.faction === playerData.faction.name) {
      return true;
    } else {
      return false;
    }
  }

  static prepare(playerData, planet) {
    const data = {
      planetName: planet.name,
      fleetDropped: Fleet.createFleet(playerData, playerData.faction.name),
    };

    ActionCommonData.prepare(
      playerData,
      Phase.PHASE_ACTION,
      Phase.STEP_ACTION_DROP,
      data
    );
  }

  static dropUnit(playerData, planet, unit) {
    const data = ActionCommonData.getData(playerData);
    if (unit === null) {
      throw new Error("Unit not found");
    }
    Fleet.addUnit(data.fleetDropped, unit);
  }

  static removeDropUnit(playerData, planet, unit) {
    const data = ActionCommonData.getData(playerData);
    if (unit === null) {
      throw new Error("Unit is null");
    }
    Fleet.removeUnit(data.fleetDropped, unit);
  }

  static resolveServer(playerData, resolveData) {
    ActionCommonData.resolveServerIni(playerData, resolveData);

    const data = ActionCommonData.getData(playerData);
    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);

    const system = Map.getSystemFromSpaceObject(planet, playerData.map);
    const spaceFleet = System.getFleetOrCreate(
      playerData,
      system,
      playerData.faction.name
    );

    if (!planet) {
      throw new Error("Planet not found in active system");
    }

    const planetFleet = Planet.getFleetOrCreate(
      playerData,
      planet,
      playerData.faction.name
    );

    const unitsToDrop = Fleet.getUnits(data.fleetDropped);
    for (let j = 0; j < unitsToDrop.length; j++) {
      const unit = unitsToDrop[j];
      if (unit.class !== Fleet.UNIT_CLASS_GROUND_FORCE) {
        throw new Error("Only ground forces can be dropped");
      }

      Fleet.removeUnit(spaceFleet, unit);
      MapUnitaryAction.placeUnitsOnPlanet(playerData, planet, [unit]);
    }

    if (!Fleet.isEmpty(data.fleetDropped)) {
      /*System.logActivityToSpaceObject(system, planet, [
        {
          type: LogMessage.ITEM_TYPE_FACTION_LOGO,
          content: playerData.faction.name,
        },
        { content: " Dropped ground forces on " },
        {
          type: LogMessage.ITEM_TYPE_SPACE_OBJECT,
          content: planet,
        },
        {
          content: " ",
        },
        {
          type: LogMessage.ITEM_TYPE_FLEET,
          content: Fleet.getLogItem(data.fleetDropped),
        },
      ]);*/
    }

    Fleet.checkValidity(playerData, spaceFleet);
    ActionCommonData.cleanActionData(playerData);
  }

  static collapseAllBut(playerData, planetName) {
    const planetsData = this.getPlanetsData(playerData);
    for (let i = 0; i < planetsData.length; i++) {
      if (planetsData[i].name !== planetName) {
        planetsData[i].collapsed = true;
      }
    }
  }
}

module.exports = DropActionData;
