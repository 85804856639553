import React, { useState, useEffect } from "react";
import ActivationViewLayout from "./ActivationViewLayout";
import ActivationNavigationView from "./ActivationNavigationView";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import PlanetViewActivation from "./Objects/PlanetViewActivation";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");

const ActivationAssignementView = ({ system }) => {
  const playerData = StaticGameData.getPlayerData();

  //console.log("ActivationMoveView RENDER : ", playerData.activationData);

  const navigationData = {
    buttonsData: [
      {
        text: "Build",
        callback: null,
      },
      { text: "Go to end activation step", callback: null },
    ],
  };

  const DisplayPlanets = () => {
    return (
      //Map on system.spaceObjects where spaceObject.isPlanet
      <div>
        {system.objects.map((spaceObject, index) => {
          if (spaceObject.isPlanet) {
            return (
              <div key={index}>
                <div className="div-hor-center">
                  <div style={{ maxHeight: "4em", margin: "1em" }}>
                    <PlanetViewActivation spaceObject={spaceObject}>
                      {" "}
                      <BaseButton>Transfer</BaseButton>
                      <BaseButton>Reset Build</BaseButton>
                      <BaseButton>Build</BaseButton>
                    </PlanetViewActivation>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const DisplayAvailablePopulation = () => {
    return (
      <div>
        <div>Available Population : {playerData.population}</div>
      </div>
    );
  };

  return (
    <ActivationViewLayout style={{ height: "100%" }}>
      <div className="text-box" style={{ height: "100%" }}>
        <DisplayPlanets></DisplayPlanets>
      </div>
      <ActivationNavigationView
        navigationData={navigationData}
      ></ActivationNavigationView>
    </ActivationViewLayout>
  );
};

export default ActivationAssignementView;

//
