const ReplayAction = require("../ReplayAction");

class ReplayActionMove {
  static NAME_MOVE_OUT = "MoveOutOfSystem";
  static NAME_MOVE_IN = "MoveIntoSystem";

  static create = (data) => {
    return {
      name: ReplayAction.NAME_MOVE,
      subActions: [],
      data: {
        systemOut: data.systemOut,
        systemIn: data.systemIn,
        units: data.units,
        cost: data.cost,
      },
    };
  };
}

module.exports = ReplayActionMove;
