import React, { useState, useEffect } from "react";

import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import SelectionListView from "../../Utils/Layout/SelectionListView";
import MoveActionData from "../../../Data/GameData/ActionData/MoveActionData";
import FleetLineView from "../../Object/FleetLineView";
import UnitinFLeetSelectionView from "../../Units/UnitinFLeetSelectionView";
import SpaceObjectImageView from "../../Object/SpaceObjectImageView";
import Planet from "../../../Data/GameData/MapData/Planet";
import ModifyFleetLimitAction from "../../../Data/GameData/ActionData/ModifyFleetLimitAction";
import Logo from "../../Utils/Logo";
import CostView from "../../Utils/CostView";
import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const ActivationCA = require("../../../ClientAction/ActivationCA");

const ActionModifyFleetLimitView = () => {
  const playerData = StaticGameData.getPlayerData();

  const modifier = ModifyFleetLimitAction.getModifier(playerData);
  const cost = ModifyFleetLimitAction.getCost(playerData);
  const targetFleetLimit = playerData.cargo + modifier;

  const DisplayContent = () => {
    return (
      <div>
        <div style={{ marginBottom: "1em" }}>
          Choose how you want to modify your fleet limit.
        </div>
        <div style={{ marginBottom: "1em" }}>
          Current fleet limit : {playerData.cargo}{" "}
          <Logo logoName="cargo"></Logo>
        </div>
        <div style={{ marginBottom: "1em" }}>
          Target fleet limit : {targetFleetLimit} <Logo logoName="cargo"></Logo>
        </div>

        <div style={{ textAlign: "center", marginBottom: "1em" }}>
          <BaseButton
            width="30%"
            onClick={() => {
              ModifyFleetLimitAction.modifyModifier(playerData, -1);
              Popup.touch();
            }}
          >
            -
          </BaseButton>
          <BaseButton
            width="30%"
            onClick={() => {
              ModifyFleetLimitAction.modifyModifier(playerData, +1);
              Popup.touch();
            }}
          >
            +
          </BaseButton>
        </div>
        <div style={{ marginBottom: "1em" }}>
          Cost : <CostView cost={cost}></CostView>
        </div>
      </div>
    );
  };

  return (
    <div
      className="div-fill"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div>
        {" "}
        <DisplayContent></DisplayContent>
      </div>

      <div>
        <BaseButton
          onClick={() => {
            ActionCommonData.cleanActionData(playerData);
            Popup.deActivateBlackPopup();
          }}
        >
          Cancel
        </BaseButton>
        <BaseButton
          onClick={() => {
            ActionCommonData.resolveClient(playerData);
            Popup.deActivateBlackPopup();
          }}
        >
          Confirm
        </BaseButton>
      </div>
    </div>
  );
};

export default ActionModifyFleetLimitView;

//
