const PlayerData = require("../GameData/PlayerData/PlayerData");
const Rules = require("../GameData/Game/Rules.js");
const System = require("../GameData/MapData/System.js");
const Unit = require("../GameData/MapData/Unit.js");
const Map = require("../GameData/MapData/Map.js");
const Fleet = require("../GameData/MapData/Fleet.js");
const LogBook = require("../GameData/Connection/LogBook.js");
const LogMessage = require("../GameData/Connection/LogMessage.js");
const LogAttachment = require("../GameData/Connection/LogAttachment.js");
const ChatMessageList = require("./ChatRoom.js");
const ChatItem = require("./ChatItem.js");
const StaticGameData = require("../GameData/StaticGameData");
const ChatData = require("./ChatData.js");
const ChatRoom = require("./ChatRoom.js");

class ChatRequest {
  static ACTION_ADD_CHAT_ITEM = "addChatItem";

  /*static route(chatData, action, updateData) {
    switch (action) {
      case ChatRequest.ACTION_ADD_CHAT_ITEM:
        ChatRequest.addChatItem(chatData, updateData);
        break;
      default:
        console.error("ChatRequest: Unknown action", updateData.action);
    }
  }

  static addChatItem = (chatData, updateData) => {
    const { playerInGameId, text } = updateData;
    const room = ChatData.getRoomFromPlayerInGameId(chatData, playerInGameId);
    ChatRoom.addChatItemText(room, text);
  };*/
}

module.exports = ChatRequest;
