import React, { useState, useEffect } from "react";
import "../../../CSS/Menu.css";
import GameGlobalLayout from "../../GameGlobalLayout";
import NavigationView from "../../NavigationView";

const StaticGameData = require("../../../Data/GameData/StaticGameData");

const DraftFactionView = ({ draftSystem, navigationData = {} }) => {
  const playerData = StaticGameData.getPlayerData();

  const factionsToDraft = draftSystem.factionsToPickFrom;

  let isPlayerHomeSystem = false;
  {
    if (
      playerData.homeSystemCoords.x === draftSystem.x &&
      playerData.homeSystemCoords.y === draftSystem.y
    ) {
      isPlayerHomeSystem = true;
    }
  }

  const ContentPickFaction = () => {};

  const ContentConsultFaction = () => {};

  const Content = () => {
    return (
      <div>
        {isPlayerHomeSystem && <ContentPickFaction></ContentPickFaction>}
        {!isPlayerHomeSystem && <ContentConsultFaction></ContentConsultFaction>}
        <div>
          {isPlayerHomeSystem && (
            <span>You can choose one of these factions : </span>
          )}
          {!isPlayerHomeSystem && (
            <span>
              The player at this position can choose one of these factions :{" "}
            </span>
          )}
        </div>
        <div>
          {factionsToDraft.map((faction, index) => {
            return <div key={index}>{faction.name}</div>;
          })}
        </div>
      </div>
    );
  };

  return <Content></Content>;
};

export default DraftFactionView;
