const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const PlanetData = require("../MapData/Planet.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const UnitCatalog = require("../Utils/UnitCatalog.js");
const SpaceObject = require("../MapData/SpaceObject.js");
const WarningAction = require("../ActionCommon/WarningAction.js");
const Phase = require("../Game/Phase.js");
const ActionCommonData = require("./ActionCommonData.js");
const BuildUnitActionData = require("./BuildUnitActionData.js");
const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction.js");

class BuildStructureActionData {
  //Transfer Functions

  static isElligible(playerData, planet) {
    if (planet.faction === playerData.faction.name) {
      return true;
    } else {
      return false;
    }
  }

  static prepare(playerData, planet) {
    const data = {
      planetName: planet.name,
      fleetProducing: Fleet.createFleet(playerData, playerData.faction.name),
      unitCatalog: UnitCatalog.create(playerData),
    };

    ActionCommonData.prepare(
      playerData,
      Phase.PHASE_ACTION,
      Phase.STEP_ACTION_BUILD_STRUCTURE,
      data
    );
  }

  static updateFleetProducing(playerData) {
    const data = ActionCommonData.getData(playerData);
    const unitCatalog = data.unitCatalog;
    data.fleetProducing = UnitCatalog.generateGhostFleet(
      playerData,
      unitCatalog
    );
  }

  static addOrderUnit(playerData, unit, amount) {
    const data = ActionCommonData.getData(playerData);
    const unitCatalog = data.unitCatalog;
    UnitCatalog.addOrderUnit(unitCatalog, unit, amount);
    this.updateFleetProducing(playerData);
  }

  static resolveClient(playerData) {
    const system = Map.getSystemFromSpaceObject(
      Map.getSpaceObjectFromName(
        playerData.map,
        playerData.actionData.planetName
      ),
      playerData.map
    );
    const confirmSystemLock = (playerData) => {
      if (!system.hasBeenActivated) {
        UIMessage.displayConfirmMessage(
          "Lock System",
          "Building structure will have the effect of locking the system. This means that no units will be able to move out of the system until next round. Do you want to continue ?",
          () => {
            ActionCommonData.resolveClient(playerData);
          }
        );
      } else {
        ActionCommonData.resolveClient(playerData);
      }
    };

    confirmSystemLock(playerData);
  }

  static getFullCatalog(playerData, planet) {
    const data = ActionCommonData.getData(playerData);
    return data.unitCatalog;
  }

  static getClassCatalog(playerData, planet, unitClass) {
    const data = ActionCommonData.getData(playerData);
    return UnitCatalog.getClassCatalog(data.unitCatalog, unitClass);
  }

  static resolveServer(playerData, resolveData) {
    ActionCommonData.resolveServerIni(playerData, resolveData);

    const data = ActionCommonData.getData(playerData);

    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);
    const system = Map.getSystemFromSpaceObject(planet, playerData.map);

    const constructingSystem = Map.getSystemFromSpaceObject(
      planet,
      playerData.map
    );

    const fleetProducing = UnitCatalog.generateFleet(
      playerData,
      data.unitCatalog
    );
    const cost = Fleet.getCost(playerData, fleetProducing);
    PlayerData.spendCost(playerData, cost, planet);

    const constructingStructures = Fleet.getUnits(
      fleetProducing,
      Fleet.UNIT_CLASS_STRUCTURE
    );

    //Check units requirements
    BuildUnitActionData.canPlanetConstruct(playerData, planet, fleetProducing);

    //place units on planet
    MapUnitaryAction.placeUnitsOnPlanet(
      playerData,
      planet,
      constructingStructures
    );

    /*System.logActivityToSpaceObject(constructingSystem, planet, [
      { type: LogMessage.ITEM_TYPE_SPACE_OBJECT, content: planet },
      { content: " built " },
      { type: LogMessage.ITEM_TYPE_FLEET, content: fleetProducing },
    ]);*/

    Planet.checkSizeUsed(playerData, planet);

    //Logging
    if (!Fleet.isEmpty(fleetProducing)) {
      System.generateLogActivity(
        system,
        "$space_object$ built structures : $fleet$.",
        [planet, fleetProducing]
      );
    }

    planet.hasBuiltStructure = true;
    system.hasBeenActivated = true;
    ActionCommonData.cleanActionData(playerData);
  }
}

module.exports = BuildStructureActionData;
