import React, { useState, useEffect } from "react";

import BaseButton from "../Utils/BaseButton";
import FactionLogo from "../Utils/FactionLogo";
import CostView from "../Utils/CostView";
import UnitImageView from "../Object/UnitImageView";
import Style from "../../Common/Config/Style";
import LifeBarView from "./LifeBarView";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const Popup = require("../../Data/Other/Popup");
const Unit = require("../../Data/GameData/MapData/Unit");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");
const Color = require("../../Common/Config/Colors");
const Cost = require("../../Data/GameData/Utils/Cost");

const UnitsStackView = ({
  slot,
  sizeEm = 1,
  scale = null,
  mapZoom = null,
  floatingAnimation = false,
}) => {
  const playerData = StaticGameData.getPlayerData();

  if (slot.length === 0) return null;

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {slot.units.map((units, index) => {
        //Sum of hp and hpMax of units
        const hp = units.reduce(
          (acc, unit) => acc + Unit.getHP(playerData, unit),
          0
        );
        const hpMax = units.reduce(
          (acc, unit) => acc + Unit.getMaxHP(playerData, unit),
          0
        );
        /*console.log("units : ", units);
        console.log("hp : ", units);
        console.log("hpMax : ", hpMax);*/

        const left = -index * 15 + "px";
        const top = -index * 15 + "px";
        const adaptedScale =
          units[0].class === Unit.CLASS_STRUCTURE ? scale * 0.75 : scale * 1;
        //const left = 0;
        //const top = 0;
        return (
          <div
            key={index}
            style={{
              zIndex: 2000,
              position: "absolute",
              left: left,
              top: top,

              //transform: "translate(-50%, -50%)",
              //backgroundColor: "RGBA(255,255,0,0.5)",
              //border: "2px solid purple",
              //width: "auto" /* Automatically adjusts width based on content */,
              //height: "auto",
              //transform: `translate(-50%, -50%)`,
              transform: `translate(-50%, -50%)`, // Add floatOffset here
            }}
          >
            <div className="div-fill" style={{}}>
              <UnitImageView
                unit={units[0]}
                sizeEm={sizeEm}
                scale={adaptedScale}
                showLifeBar={true}
                unitAmount={units.length}
                unitAmountTopPercent={90}
                //lifeBarTopPercent={20}
                lifeBarCompletion={(hp * 100) / hpMax}
                lifeBarTopPercent={50}
                //labelBelow={units[0].type}
                mapZoom={mapZoom}
                floatingAnimation={floatingAnimation}
              ></UnitImageView>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UnitsStackView;
