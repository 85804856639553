const Fleet = require("../MapData/Fleet");
const Planet = require("../MapData/Planet");
const TechList = require("../Technology/TechList");
const TechTree = require("../Technology/TechTree");
const CustomMath = require("../../../Common/Math/CustomMath");
const System = require("../MapData/System");
const SolveCapacityData = require("../MandatoryAction/SolveCapacityData");
const SolveFleetLimitData = require("../MandatoryAction/SolveFleetLimitData");
const Unit = require("../MapData/Unit");

class UnitUnitaryAction {
  //Check legality
  static doesUnitsContainShip(units) {
    for (let i = 0; i < units.length; i++) {
      if (units[i].class === Unit.CLASS_SHIP) {
        return true;
      }
    }
    return false;
  }

  static doesUnitsContainGroundForce(units) {
    for (let i = 0; i < units.length; i++) {
      if (units[i].class === Unit.CLASS_GROUND_FORCE) {
        return true;
      }
    }
    return false;
  }

  static doesUnitsContainStructure(units) {
    for (let i = 0; i < units.length; i++) {
      if (units[i].class === Unit.CLASS_STRUCTURE) {
        return true;
      }
    }
    return false;
  }

  static repairUnit(playerData, unit) {
    const hp = Unit.getHP(playerData, unit);
    const totalHP = Unit.getMaxHP(playerData, unit);
    Unit.setHP(playerData, unit, totalHP);
  }
}

module.exports = UnitUnitaryAction;
