const Amount = require("../Amount");

class Resource {
  static TYPE_RESOURCE() {
    return [
      { type: "production", abbreviation: "prod", color: "Gold", symbol: "P" },
      { type: "influence", abbreviation: "inf", color: "Cyan", symbol: "I" },
      {
        type: "support",
        abbreviation: "sup",
        color: "SandyBrown",
        symbol: "S",
      },
      {
        type: "expert",
        abbreviation: "exp",
        color: "LightGreen",
        symbol: "E",
      },
      { type: "tech", abbreviation: "tech", color: "HotPink", symbol: "T" },
      {
        type: "order",
        abbreviation: "ord",
        color: "LightBlue",
        symbol: "O",
      },
      { type: "credit", abbreviation: "cre", color: "yellow", symbol: "C" },
    ];
  }

  constructor(type, amount) {
    this.type = type;
    this.amount = new Amount(amount);
  }

  static getTotalValue(resource) {
    return Amount.getTotalValue(resource.amount);
  }

  static getBaseValue(resource) {
    return Amount.getBaseValue(resource.amount);
  }

  static getTotalModifiers(resource) {
    return Amount.getTotalModifiers(resource.amount);
  }

  static getAbbreviation(type) {
    const resourceType = this.TYPE_RESOURCE().find(
      (resource) => resource.type === type
    );
    return resourceType ? resourceType.abbreviation : null;
  }

  static getText(type) {
    return type;
  }

  static getColor(type) {
    const resourceType = this.TYPE_RESOURCE().find(
      (resource) => resource.type === type
    );
    return resourceType ? resourceType.color : null;
  }

  static getSymbol(type) {
    const resourceType = this.TYPE_RESOURCE().find(
      (resource) => resource.type === type
    );
    return resourceType ? resourceType.symbol : null;
  }

  static addToResource(resource1, resource2) {
    if (resource1.type !== resource2.type) {
      throw new Error("Resource types do not match");
    }
    const totalToAdd = Amount.getTotalValue(resource2.amount);
    resource1.amount.amount = resource1.amount.amount + totalToAdd;
  }

  static subtractToResource(resource1, resource2) {
    if (resource1.type !== resource2.type) {
      throw new Error("Resource types do not match");
    }
    const totalAmountResource2 = Amount.getTotalValue(resource2.amount);
    const resourceModifiers1 = Amount.getTotalModifiers(resource1);
    const reste = totalAmountResource2 - resourceModifiers1;
    resource1.Amount.amount = resource1.Amount.amount - reste;
  }
}

module.exports = Resource;
