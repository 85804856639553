import React, { useState, useEffect, useMemo } from "react";
import useViewportSize from "./CustomHook/UseViewportSize";
import "../CSS/Menu.css";
import "../CSS/MapGrid.css";
import ArrowButton from "./Utils/ArrowButton";
import SystemView from "./Map/SystemView";
import System from "../Data/GameData/MapData/System";
import StaticGameData from "../Data/GameData/StaticGameData";
import TaskBarView from "./TaskBarView";
import NavigationView from "./NavigationView";
import GameGlobalLayout from "./GameGlobalLayout";
import FactionLogo from "./Utils/FactionLogo";
import Logo from "./Utils/Logo";
import ResourceBarView from "./Utils/ResourceBarView";
import BaseButton from "./Utils/BaseButton";
import PlanetViewActivation from "./ManageSystem/ActivationView/Objects/PlanetViewActivation";
import PlayerData from "../Data/GameData/PlayerData/PlayerData";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import WarningAction from "../Data/GameData/ActionCommon/WarningAction";
import ActionAdminView from "./ManageSystem/AdminViews/ActionAdminView.jsx";
import AdminActionData from "../Data/GameData/ActionAdminData/AdminActionData.js";
import AdminCreateUnitData from "../Data/GameData/ActionAdminData/AdminCreateUnitData.js";
import AdminCreateUnitView from "./ManageSystem/AdminViews/AdminCreateUnitView.jsx";
import { MainMenu } from "../MainMenu/MainMenu.jsx";
import GameInfoView from "./Game/GameInfoView.jsx";
import LineSpace from "./Utils/Layout/LineSpace.jsx";
import ScoreData from "../Data/GameData/Objectifs/ScoreData.js";
import Law from "../Data/GameData/Law/Law.js";

const CreateMap = require("../Data/GameData/MapData/CreateMap.js");
const Request = require("../Common/Requests/Request.js");
const Map = require("../Data/GameData/MapData/Map.js");
const Popup = require("../Data/Other/Popup.js");

const GameMenuView = ({ setRouteMainHandler }) => {
  // Using the custom hook to manage viewport size
  const viewportSize = useViewportSize();
  //console.log("Viewport size: ", viewportSize);
  //console.log("MapView playerData: ", playerData);

  const playerData = StaticGameData.getPlayerData();

  const navigationData = {
    buttonsData: [
      {
        text: "Map",
        callback: () => {
          Popup.unStackToMap();
        },
      },
    ],
  };

  const nextRound = () => {
    const checkHasVotes = (callback) => {
      const playerVote = Law.getPlayerVote(playerData);
      const votingLaws = Law.getVotingLaws(playerData);
      if (!playerVote && votingLaws.length > 0) {
        UIMessage.displayConfirmMessage(
          "No vote",
          "You have not voted for a law. Are you sure you want to go to the next round ? You are missing an opportunity to vote for a law.",
          () => {
            callback();
          }
        );
      } else {
        callback();
      }
    };

    UIMessage.displayConfirmMessage(
      "Ready for round " + (playerData.roundNumber + 1) + " ?",
      "We are round " +
        playerData.roundNumber +
        ". Are you ready to go to round " +
        (playerData.roundNumber + 1) +
        " ?",
      () => {
        if (
          !ScoreData.getSelectedObjective(playerData) &&
          !ScoreData.hasScoredObjectiveThisRound(playerData)
        ) {
          UIMessage.displayConfirmMessage(
            "No objectives selected or scored !",
            "You did not select any objectives or score any objectives this round. You are missing one opportunity to score victory point, as you can only score one objective per round.",
            () => {
              checkHasVotes(() => {
                Request.nextRound();
              });
            }
          );
        } else {
          checkHasVotes(() => {
            Request.nextRound();
          });
        }
      }
    );
  };

  const info = () => {
    Popup.addLayer({
      name: "HelpPopup",
      helpReference: "helpMainScreen",
    });
  };

  const load = () => {
    UIMessage.displayConfirmMessage(
      "Load Game",
      "Are you sure you want to load the game?",
      () => {
        Request.updateAdmin("LoadTestState");
      }
    );
  };

  const save = () => {
    UIMessage.displayConfirmMessage(
      "Save Game",
      "Are you sure you want to save the game?",
      () => {
        Request.updateAdmin("SaveTestState");
      }
    );
  };

  const refresh = () => {
    Request.getGameState();
  };

  const quitGame = () => {
    //setRouteMainHandler({ activeScreen: "MainMenu" });

    Popup.quitGame();
  };

  const admin = () => {
    AdminActionData.prepare(playerData);
    Popup.addDynamicLayer(
      <ActionAdminView playerData={playerData}></ActionAdminView>
    );
  };

  const DisplayMenuButtons = () => {
    return (
      <div className="div-ver-fill" style={{ fontSize: "2em" }}>
        <div>
          {" "}
          <BaseButton
            onClick={() => {
              Popup.activateBlackPopup(<GameInfoView></GameInfoView>);
            }}
          >
            Game Info
          </BaseButton>
        </div>
        {!playerData.isWaitingEndOfRound && (
          <div>
            {" "}
            <BaseButton onClick={nextRound}>Next Round</BaseButton>
          </div>
        )}

        <div>
          {" "}
          <BaseButton onClick={save}>Save State</BaseButton>
        </div>
        <div>
          {" "}
          <BaseButton onClick={load}>Load State</BaseButton>
        </div>
        <div>
          {" "}
          <BaseButton onClick={refresh}>Refresh Game</BaseButton>
        </div>
        <div>
          {" "}
          <BaseButton onClick={quitGame}>Quit Game</BaseButton>
        </div>
        <div>
          {" "}
          <BaseButton onClick={admin}>Admin</BaseButton>
        </div>
        <div>
          {" "}
          <BaseButton onClick={info}>Info</BaseButton>
        </div>
      </div>
    );
  };

  const DisplayMenu = () => {
    return (
      <div style={{}}>
        <DisplayMenuButtons></DisplayMenuButtons>
      </div>
    );
  };

  return (
    <div className="div-fill" style={{}}>
      <GameGlobalLayout centerOnScreen={true}>
        <div
          style={
            {
              /*display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            outline: "2px solid green",
            outlineOffset: "-4px",
            height: "100%",*/
              //outline: "2px solid green",
              //outlineOffset: "-4px",
              //height: "100%",
            }
          }
        >
          <LineSpace></LineSpace>
          <DisplayMenu></DisplayMenu>
        </div>
        <NavigationView navigationData={navigationData}></NavigationView>
      </GameGlobalLayout>
    </div>
  );
};

export default GameMenuView;
