import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import MainComponent from "./Main/Main.jsx";
const StaticGameData = require("./Data/GameData/StaticGameData");

const App = () => {
  const [msg, setMsg] = useState("");

  useEffect(() => {
    document.body.style.backgroundColor = "black"; // Replace `yourColorHere` with your desired color
    document.body.style.color = "white";
  }, []);

  //const [activeComponent, setActiveComponent] = useState(<LoginScreen />);
  //console.log("Starting App.js");
  //setActiveComponent(<LoginScreen />);

  return (
    <div className="App">
      <MainComponent></MainComponent>
    </div>
  );
};

/*<img src={logo} className="App-logo" alt="logo" />
<p>{msg}</p>
<button onClick={handleClick}> Dis bonjour </button>*/

export default App;
