const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const SystemAction = require("../ActionCommon/SystemAction.js");
const ValidAction = require("../ActionCommon/WarningAction.js");
const WarningAction = require("../ActionCommon/WarningAction.js");
const Cost = require("../Utils/Cost.js");
const LogBook = require("../Connection/LogBook.js");
const LogAttachment = require("../Connection/LogAttachment.js");
const CheckAction = require("../ActionCommon/CheckAction.js");
const Phase = require("../Game/Phase.js");
const StaticGameData = require("../StaticGameData.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Request = require("../../../Common/Requests/Request.js");
const ActionCommonData = require("./ActionCommonData.js");

class ProductionActionData {
  static prepareStep(playerData) {
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    ).filter((planet) => !planet.hasProduced);

    const data = {
      planets: planets,
      resourcePerPlanet: {},
      exhaustedPlanets: [],
      gain: {
        mineral: 0,
        science: 0,
        energy: 0,
        population: 0,
      },
    };

    ActionCommonData.prepare(
      playerData,
      Phase.PHASE_ACTION,
      Phase.STEP_ACTION_PRODUCE,
      data
    );
  }

  static exhaustPlanetSwitch = (planet) => {
    const data = ActionCommonData.getData(StaticGameData.getPlayerData());
    if (!data.exhaustedPlanets.includes(planet)) {
      data.exhaustedPlanets.push(planet);
    } else {
      data.exhaustedPlanets = data.exhaustedPlanets.filter(
        (item) => item !== planet
      );
    }
  };

  static isPlanetExhausted = (planet) => {
    const data = ActionCommonData.getData(StaticGameData.getPlayerData());
    return data.exhaustedPlanets.includes(planet);
  };

  static modifyResourceGain = (planet, resourceNameP) => {
    const data = ActionCommonData.getData(StaticGameData.getPlayerData());
    let resourceName = resourceNameP;
    let signInverter = 1;

    if (this.isPlanetExhausted(planet)) {
      resourceName = data.resourcePerPlanet[planet.name];
      delete data.resourcePerPlanet[planet.name];
      signInverter = -1;
    } else {
      data.resourcePerPlanet[planet.name] = resourceName;
    }

    let modifier = 0;
    switch (resourceName) {
      case "mineral":
        modifier =
          Planet.getMineralProduction(StaticGameData.getPlayerData(), planet) *
          signInverter;
        data.gain.mineral += modifier;
        break;
      case "science":
        modifier =
          Planet.getScienceProduction(StaticGameData.getPlayerData(), planet) *
          signInverter;
        data.gain.science += modifier;
        break;
      case "energy":
        modifier =
          Planet.getEnergyProduction(StaticGameData.getPlayerData(), planet) *
          signInverter;
        data.gain.energy += modifier;
        break;
    }

    const populationModifier =
      signInverter *
      Planet.getPopulationProduction(StaticGameData.getPlayerData(), planet);
    data.gain.population += populationModifier;

    this.exhaustPlanetSwitch(planet);

    Popup.touch();
  };

  static getGain = () => {
    const data = ActionCommonData.getData(StaticGameData.getPlayerData());
    return data.gain;
  };

  static resolveClient = () => {
    const data = ActionCommonData.getData(StaticGameData.getPlayerData());
    const planetNames = data.exhaustedPlanets.map((planet) => planet.name);

    const updateData = {
      planetNames: planetNames,
      resourcePerplanet: data.resourcePerPlanet,
    };

    ActionCommonData.resolveClient(StaticGameData.getPlayerData(), updateData);
  };

  static resolveServer(playerData, updateData) {
    for (let i = 0; i < updateData.planetNames.length; i++) {
      const planet = Map.getSpaceObjectFromName(
        playerData.map,
        updateData.planetNames[i]
      );

      if (Planet.isExhausted(planet)) {
        throw new Error("Planet has already produced");
      }

      if (updateData.resourcePerplanet[planet.name] === "mineral") {
        PlayerData.gainMineral(
          playerData,
          Planet.getMineralProduction(playerData, planet)
        );
      }

      if (updateData.resourcePerplanet[planet.name] === "science") {
        PlayerData.gainScience(
          playerData,
          Planet.getScienceProduction(playerData, planet)
        );
      }

      if (updateData.resourcePerplanet[planet.name] === "energy") {
        PlayerData.gainEnergy(
          playerData,
          Planet.getEnergyProduction(playerData, planet)
        );
      }
      PlayerData.gainPopulation(
        playerData,
        Planet.getPopulationProduction(playerData, planet)
      );
      planet.hasProduced = true;
    }
  }
}

module.exports = ProductionActionData;
