import React, { useState, useEffect } from "react";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import Color from "../../Common/Config/Colors";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import AbilityCard from "../../Data/GameData/Ability/AbilityCard";
import AbilityCardDescView from "../Ability/AbilityCardDescView";
import ResolveFactionAbility from "../../Data/GameData/Ability/ResolveFactionAbility";
import FactionAbilityInput from "../Ability/FactionAbilityInput";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");

const FactionAbilityDetailedView = ({ playerId, abilityCardDesc }) => {
  const activePlayerData = PlayerData.getPlayerDataFromInGameId(
    StaticGameData.getPlayerData(),
    playerId
  );

  const DisplayUseAbility = () => {
    const isActiveAbility = AbilityCard.isActiveAbility(abilityCardDesc);

    if (!isActiveAbility) {
      return;
    }
    return (
      <div style={{ marginTop: "0.5em" }}>
        {AbilityCard.isExhausted(abilityCardDesc) ? (
          <BaseButton
            type="greyOut"
            onClick={() => {
              UIMessage.displayInfoMessage(
                "Exhausted",
                "This ability is exhausted, you cannot use it for now. It will be readied at the beginning of the next round."
              );
            }}
          >
            Exhausted
          </BaseButton>
        ) : (
          <BaseButton
            onClick={() => {
              FactionAbilityInput.routeFactionAbilityInput(
                abilityCardDesc.name
              );
            }}
          >
            Use
          </BaseButton>
        )}
      </div>
    );
  };

  const DisplayGeneral = () => {
    return (
      <span>
        <div
          style={{
            backgroundColor: Color.COLOR_GREY_3,
            padding: "10px",
            borderRadius: "5px",
            marginTop: "1em",
            marginBottom: "1em",
          }}
        >
          <AbilityCardDescView
            abilityCardDesc={abilityCardDesc}
          ></AbilityCardDescView>
        </div>
        <div>
          State :{" "}
          {AbilityCard.isExhausted(abilityCardDesc) ? "exhausted" : "ready"}
        </div>
        {abilityCardDesc.type === AbilityCard.TYPE_EFFECT && (
          <div>
            This is an effect : effects expire at the start of the next round.
          </div>
        )}

        <div>
          <DisplayUseAbility></DisplayUseAbility>
        </div>
      </span>
    );
  };

  const navigationData = {
    buttonsData: [{ text: "Back", callback: () => Popup.goBack() }],
  };

  return (
    <GameGlobalLayout backgroundType="uniform">
      <div
        className="text-box"
        style={{ textAlign: "center", lineHeight: "2em" }}
      >
        <DisplayGeneral></DisplayGeneral>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default FactionAbilityDetailedView;
