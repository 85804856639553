import React, { useState } from "react";
import StaticGameData from "../../../Data/GameData/StaticGameData.js";
import InteractionFunctions from "../../UIInteraction/InteractionFunctions.jsx";
import AdminActionData from "../../../Data/GameData/ActionAdminData/AdminActionData.js";
import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData.js";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData.js";
import BaseButton from "../../Utils/BaseButton.jsx";
import FactionLogo from "../../Utils/FactionLogo.jsx";

const Popup = require("../../../Data/Other/Popup.js");

const ModifyResources = () => {
  const prepare = (playerData) => {
    AdminActionData.prepare(playerData);

    Popup.activateBlackPopup(
      <DisplayModifyResourcesView playerData={playerData} />
    );
  };

  const DisplayModifyResourcesView = ({ playerData }) => {
    const [mineral, setMineral] = useState(0);
    const [energy, setEnergy] = useState(0);
    const [science, setScience] = useState(0);
    const [vp, setVp] = useState(0);
    const [population, setPopulation] = useState(0);
    const [credit, setCredit] = useState(0);

    return (
      <div>
        <div>
          <span>mineral : {mineral}</span>
          <BaseButton onClick={() => setMineral(mineral - 0.1)}>-</BaseButton>
          <BaseButton onClick={() => setMineral(mineral + 0.1)}>+</BaseButton>
          <BaseButton onClick={() => setMineral(mineral - 1)}>--</BaseButton>
          <BaseButton onClick={() => setMineral(mineral + 1)}>++</BaseButton>
        </div>
        <div>
          <span>energy : {energy}</span>
          <BaseButton onClick={() => setEnergy(energy - 0.1)}>-</BaseButton>
          <BaseButton onClick={() => setEnergy(energy + 0.1)}>+</BaseButton>
          <BaseButton onClick={() => setEnergy(energy - 1)}>--</BaseButton>
          <BaseButton onClick={() => setEnergy(energy + 1)}>++</BaseButton>
        </div>
        <div>
          <span>science : {science}</span>
          <BaseButton onClick={() => setScience(science - 0.1)}>-</BaseButton>
          <BaseButton onClick={() => setScience(science + 0.1)}>+</BaseButton>
          <BaseButton onClick={() => setScience(science - 1)}>--</BaseButton>
          <BaseButton onClick={() => setScience(science + 1)}>++</BaseButton>
        </div>
        <div>
          <span>population : {population}</span>
          <BaseButton onClick={() => setPopulation(population - 0.1)}>
            -
          </BaseButton>
          <BaseButton onClick={() => setPopulation(population + 0.1)}>
            +
          </BaseButton>
          <BaseButton onClick={() => setPopulation(population - 1)}>
            --
          </BaseButton>
          <BaseButton onClick={() => setPopulation(population + 1)}>
            ++
          </BaseButton>
        </div>
        <div>
          <span>credit : {credit}</span>
          <BaseButton onClick={() => setCredit(credit - 0.1)}>-</BaseButton>
          <BaseButton onClick={() => setCredit(credit + 0.1)}>+</BaseButton>
          <BaseButton onClick={() => setCredit(credit - 1)}>--</BaseButton>
          <BaseButton onClick={() => setCredit(credit + 1)}>++</BaseButton>
        </div>
        <div>
          <span>vp : {vp}</span>
          <BaseButton onClick={() => setVp(vp - 0.1)}>-</BaseButton>
          <BaseButton onClick={() => setVp(vp + 0.1)}>+</BaseButton>
          <BaseButton onClick={() => setVp(vp - 1)}>--</BaseButton>
          <BaseButton onClick={() => setVp(vp + 1)}>++</BaseButton>
        </div>

        <div>
          <BaseButton
            onClick={() => {
              Popup.resetMapNavigationData();
              Popup.unStackToMap();
              AdminActionData.prepare(playerData);
              const data = ActionCommonData.getData(playerData);
              data.modifyResources = {
                mineral: mineral,
                energy: energy,
                science: science,
                vp: vp,
                population: population,
                credit: credit,
              };
              AdminActionData.resolveClient(
                playerData,
                AdminActionData.ACTION_MODIFY_RESOURCES
              );
            }}
          >
            Confirm
          </BaseButton>
          <BaseButton onClick={() => Popup.deActivateBlackPopup()}>
            Back
          </BaseButton>
        </div>
      </div>
    );
  };

  return { prepare }; // Export both functions
};

export default ModifyResources;
