import React from "react";
import ResourceView from "./ResourceView.jsx";

const Resource = require("../../Data/GameData/Resource/ResourceInstance/Resource.js");

const CostResourceView = ({
  costResource,
  verticalMode = false,
  abbreviationMode = false,
  symbolMode = false,
  numberMode = false,
}) => {
  if (costResource == null) {
    throw new Error("CostResourceView: costResource is null");
  }
  if (costResource.resources == null) {
    throw new Error("CostResourceView: costResource.resources is null");
  }

  const HorizontalModeView = ({ costResource }) => {
    return (
      <div className="div-fill">
        {costResource.resources.map((resource, index) => (
          <span key={index}>
            {index !== 0 && <span>, </span>}
            <ResourceView
              resource={resource}
              abbreviationMode={abbreviationMode}
              symbolMode={symbolMode}
              numberMode={numberMode}
            />
          </span>
        ))}
      </div>
    );
  };

  const VerticalModeView = ({ costResource }) => {
    return (
      <div className="div-ver-center">
        {costResource.resources.map((resource, index) => (
          <ResourceView
            key={index}
            resource={resource}
            abbreviationMode={abbreviationMode}
            symbolMode={symbolMode}
            numberMode={numberMode}
          />
        ))}
      </div>
    );
  };

  const StandardModeView = ({ costResource }) => {
    const resourceViews = [];

    for (const resource in costResource.resources) {
      resourceViews.push(
        <ResourceView
          resource={resource}
          abbreviationMode={abbreviationMode}
          symbolMode={symbolMode}
        ></ResourceView>
      );
    }
    return <span>{resourceViews.join(", ")}</span>;
  };

  if (verticalMode) {
    return (
      <VerticalModeView
        costResource={costResource}
        abbreviationMode={abbreviationMode}
        symbolMode={symbolMode}
        numberMode={numberMode}
      ></VerticalModeView>
    );
  }
  return (
    <HorizontalModeView
      costResource={costResource}
      abbreviationMode={abbreviationMode}
      symbolMode={symbolMode}
      numberMode={numberMode}
    ></HorizontalModeView>
  );
};

export default CostResourceView;
