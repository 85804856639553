class Color {
  static COLOR_MINERAL = "Aqua";
  static COLOR_FOOD = "Coral";
  static COLOR_SCIENCE = "Fuchsia";

  //Green Theme
  static COLOR_GREEN_THEME_SHADOW = "RGB(20,80,0)";
  static COLOR_GREEN_THEME_MEDIUM = "RGB(50,200,0)";
  static COLOR_GREEN_THEME_LIGHT = "RGB(65,255,0)";
  //static COLOR_GREEN_THEME_2 = "RGB(20,80,0)";
  //static COLOR_GREEN_THEME_2 = "RGB(20,80,0)";

  static COLOR_CYAN = "Cyan";

  //The following is Obsoletes
  static COLOR_COMMAND = "LightBlue";
  static COLOR_TECH = "HotPink";
  static COLOR_INFRA = "SandyBrown";
  static COLOR_CREDIT = "Gold";
  static COLOR_EXPERT = "LightGreen";
  static COLOR_PRODUCTION = "Gold";
  static COLOR_INFLUENCE = "Cyan";

  static COLOR_VALIDATION = "LightGreen";
  static COLOR_NON_VALIDATION = "LightPink";

  //Highligt
  static COLOR_HIGHLIGHT = "rgba(255, 255, 255, 0.3)";
  static COLOR_LOWLIGHT = "rgba(0, 0, 0, 0.8)";
  static COLOR_LIGHT_SHADOW = "rgba(0, 0, 0, 0.3)";
  static COLOR_MEDIUM_SHADOW = "rgba(0, 0, 0, 0.5)";
  static COLOR_HEAVY_SHADOW = "rgba(0, 0, 0, 0.85)";
  static COLOR_MANAGE_VIEW_BACKGROUND = this.COLOR_MEDIUM_SHADOW;

  // Player Colors
  // Player Colors
  static COLOR_PLAYER_RED = "Red";
  //light blue
  //static COLOR_LIGHTBLUE = "LightBlue";

  static COLOR_PLAYER_BLUE = "Blue";
  static COLOR_PLAYER_GREEN = "Green";
  static COLOR_PLAYER_RED = "Red";
  static COLOR_PLAYER_YELLOW = "Yellow";
  static COLOR_PLAYER_ORANGE = "Orange";
  static COLOR_PLAYER_PURPLE = "Purple";
  static COLOR_PLAYER_BROWN = "Brown";
  static COLOR_PLAYER_PINK = "Pink";
  static COLOR_PLAYER_TEAL = "Teal";
  static COLOR_PLAYER_LIME = "Lime";
  static COLOR_PLAYER_GREY = "Grey";
  static COLOR_NEUTRAL_FACTION = "Black";
  //static COLOR_PLAYER_ETHERAL = "etheral";

  static COLOR_PLAYER_ROCKS = "rocks";

  static COLOR_CONCORDANT_ORDER = "concordant_order";
  static COLOR_VAL_AR_SKYBORNE = "vahl_ar_skyborne";
  static COLOR_LITHORIAN_COLLECTIVE = "lithorian_collective";
  static COLOR_ASHEN_COHORT = "ashen_cohort";
  static COLOR_DOMINUS_LEGION = "dominus_legion";
  static COLOR_ETHERAL_SPREAD = "etheral_spread";
  static COLOR_ASTHARAN_TRADE_GUILD = "astharan_trade_guild";

  static possiblePlayerColors = [
    Color.COLOR_PLAYER_RED,
    Color.COLOR_PLAYER_BLUE,
    Color.COLOR_PLAYER_GREEN,
    Color.COLOR_PLAYER_YELLOW,
    Color.COLOR_PLAYER_ORANGE,
    Color.COLOR_PLAYER_PURPLE,
    //Color.COLOR_PLAYER_BROWN,
    Color.COLOR_PLAYER_PINK,
    //Color.COLOR_PLAYER_TEAL,
    //Color.COLOR_PLAYER_LIME,
    //Color.COLOR_PLAYER_Grey,
  ];

  //Technololy
  static COLOR_TECH_BLUE = "RGB(7,171,255)";
  static COLOR_TECH_RED = "RGB(238,105,119)";
  static COLOR_TECH_GREEN = "RGB(60,230,42)";
  static COLOR_TECH_YELLOW = "RGB(220,220,0)";
  static COLOR_TECH_WHITE = "white";
  static COLOR_NAME_TECH_GREEN = "green";
  static COLOR_NAME_TECH_BLUE = "blue";
  static COLOR_NAME_TECH_RED = "red";
  static COLOR_NAME_TECH_YELLOW = "yellow";
  static COLOR_NAME_TECH_WHITE = "white";

  static getColorFromTechColor = (techColor) => {
    if (techColor === this.COLOR_NAME_TECH_BLUE) return this.COLOR_TECH_BLUE;
    if (techColor === this.COLOR_NAME_TECH_RED) return this.COLOR_TECH_RED;
    if (techColor === this.COLOR_NAME_TECH_GREEN) return this.COLOR_TECH_GREEN;
    if (techColor === this.COLOR_NAME_TECH_YELLOW)
      return this.COLOR_TECH_YELLOW;
    if (techColor === this.COLOR_NAME_TECH_WHITE) return this.COLOR_TECH_WHITE;
    return "white";
  };

  static getColorFromPlayerColor(colorP) {
    if (colorP === this.COLOR_PLAYER_RED) return "RGB(255,50,50)";
    if (colorP === this.COLOR_PLAYER_BLUE) return "#7CB9E8";
    if (colorP === this.COLOR_PLAYER_GREEN) return "RGB(50,255,50)";
    if (colorP === this.COLOR_PLAYER_YELLOW) return "Yellow";
    if (colorP === this.COLOR_PLAYER_ORANGE) return "Orange";
    if (colorP === this.COLOR_PLAYER_PURPLE) return "RGB(224,99,224)";
    if (colorP === this.COLOR_PLAYER_BROWN) return "Brown";
    if (colorP === this.COLOR_PLAYER_PINK) return "RGB(255,114,139)";
    if (colorP === this.COLOR_PLAYER_TEAL) return "Teal";
    if (colorP === this.COLOR_PLAYER_LIME) return "Lime";
    if (colorP === this.COLOR_PLAYER_GREY) return "Grey";
    if (colorP === this.COLOR_NEUTRAL_FACTION) return "Black";
    if (colorP === this.COLOR_PLAYER_ETHERAL) return "RGB(209,94,209)";
    if (colorP === this.COLOR_PLAYER_ROCKS) return "RGB(112,69,48)";
    if (colorP === this.COLOR_CONCORDANT_ORDER) return "RGB(24, 233, 20)";
    if (colorP === this.COLOR_VAL_AR_SKYBORNE) return "RGB(0, 143, 224)";
    if (colorP === this.COLOR_LITHORIAN_COLLECTIVE) return "RGB(112,69,48)";
    if (colorP === this.COLOR_ASHEN_COHORT) return "RGB(238, 36, 49)";
    if (colorP === this.COLOR_DOMINUS_LEGION) return "RGB(201, 30, 30)";
    if (colorP === this.COLOR_ETHERAL_SPREAD) return "RGB(254, 133, 161)";
    if (colorP === this.COLOR_ASTHARAN_TRADE_GUILD) return "RGB(234, 212, 0)";

    throw new Error("Invalid Player color : " + colorP);
  }

  static getColorFromFaction(playerData, factionName, playerColor = false) {
    // Check if player's faction matches the specified faction name
    if (playerData.faction) {
      if (playerData.faction.name === factionName) {
        if (playerColor) return playerData.color;
        return Color.getColorFromPlayerColor(playerData.color);
      }
    }

    // Check other players for a match
    if (playerData.otherPlayers) {
      const otherPlayer = playerData.otherPlayers.find(
        (p) => p.faction && p.faction.name === factionName
      );
      if (otherPlayer) {
        if (playerColor) return otherPlayer.color;
        return Color.getColorFromPlayerColor(otherPlayer.color); // Return color if a match is found
      }
    }

    if (factionName.startsWith("(m)") || factionName.startsWith("Exiled")) {
      return this.COLOR_NEUTRAL_FACTION;
    }

    // Return null if no match is found
    return null;
  }

  static getColorFromPlayerInGameId(playerData, playerInGameId) {
    // Check if player's faction matches the specified faction name
    if (playerData.playerInGameId === playerInGameId) {
      return playerData.color;
    }

    // Check other players for a match
    const otherPlayer = playerData.otherPlayers.find(
      (p) => p.playerInGameId === playerInGameId
    );
    if (otherPlayer) {
      return otherPlayer.color; // Return color if a match is found
    }

    // Return null if no match is found
    return "white";
  }

  //Menu
  static COLOR_MENU_GREY_BACKGROUND = "rgb(50,50,50)";

  //RESOURCES
  static COLOR_MINERAL = "#00adff";
  //static COLOR_MINERAL = "Cyan";
  static COLOR_POP = "#00ff46";
  static COLOR_INFLUENCE = "#ff0000";
  static COLOR_SCIENCE = "#fb00ff";
  static COLOR_ENERGY = "#ffab00";
  //Light brown
  static COLOR_SIZE = "white";

  //grey palette
  static COLOR_GREY_1 = "#1e2124";
  static COLOR_GREY_2 = "#282b30";
  static COLOR_GREY_3 = "#36393e";
  static COLOR_GREY_4 = "#424549";
  static COLOR_GREY_5 = "RGB(150,150,150)";
  static COLOR_GREY_BLUE = "#7289da";

  //Map
  static COLOR_SYSTEM_BORDER = "RGB(69,111,184)";

  //Action Buttons
  static COLOR_BUTTON_ACTION_1 = "RGB(40, 63, 104)";
  static COLOR_BUTTON_ACTION_2 = this.COLOR_SYSTEM_BORDER;

  //BaseButton
  static BASE_BUTTON_BACK = "rgb(0,0,0,0.7)";
  static BASE_BUTTON_BACK_HOVER = "rgb(54,80,134)";
  static BASE_BUTTON_BORDER = "rgb(54,80,134)";
  static BASE_BUTTON_EXHAUSTED = "rgb(27,40,67)";

  //Chat neutral player color
  static CHAT_NEUTRAL_PLAYER_COLOR = "rgb(102,153,255)";

  /*static getColorFromColor = (color) => {
    if (color === this.COLOR_PLAYER_RED) return "RGB(100,20,20";
    if (color === this.COLOR_PLAYER_BLUE) return "#7CB9E8";
    if (color === this.COLOR_PLAYER_GREEN) return "Green";
    if (color === this.COLOR_PLAYER_YELLOW) return "Yellow";
    if (color === this.COLOR_PLAYER_ORANGE) return "Orange";
    if (color === this.COLOR_PLAYER_PURPLE) return "Purple";
    if (color === this.COLOR_PLAYER_BROWN) return "Brown";
    if (color === this.COLOR_PLAYER_PINK) return "Pink";
    if (color === this.COLOR_PLAYER_TEAL) return "Teal";
    if (color === this.COLOR_PLAYER_LIME) return "Lime";
    if (color === this.COLOR_PLAYER_GREY) return "Grey";

    throw new Error("Invalid Player color : " + color);
  };*/
}

module.exports = Color;
