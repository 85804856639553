import React, { useState } from "react";
import "../../CSS/Font.css";
import ResourceView from "../../GameView/Label/ResourceView";
import CostResourceView from "../../GameView/Label/CostResourceView";
const Map = require("../../Data/GameData/Map/Map.js");
const UnitIndex = require("../../Data/GameData/Units/UnitIndex.js");
const CostResource = require("../../Data/GameData/Resource/CostResource.js");

const Popup = require("../../Data/Other/Popup.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");

const BuildUnitsWFView = ({ gameState }) => {
  const data = StaticGameData.popup.data;
  const callback = StaticGameData.popup.callback;

  const q = data.q;
  const r = data.r;
  const tile = Map.getTileAtCoordinates(q, r, gameState);

  const taskName = "Build Units";
  const playerState = gameState.playerStates[gameState.mapPosition - 1];
  const [productionExhausted, setProductionExhausted] = useState(
    data.generatedProduction
  );

  const [unitsToBuild, setUnitsToBuild] = useState(data.unitsToBuild);

  const [costToPay, setCostToPay] = useState(
    UnitIndex.getUnitsToBuilCost(data.unitsToBuild)
  ); // {resource: 0, infrastructure: 0}
  let errorMessage = null;

  const missingProduction = () => {
    let missingProduction = costToPay.productionResource - productionExhausted;
    if (missingProduction < 0) {
      missingProduction = 0;
    }
    return missingProduction;
  };

  const handleBuildUnit = (unitId) => {
    // Handle unit build logic here
    //setParentPopup(null);
    console.log(`Building unit with id: ${unitId}`);
  };

  const handleUnitCountChange = (type, subType, amount) => {
    if (!unitsToBuild[type]) {
      unitsToBuild[type] = {};
    }

    if (!unitsToBuild[type][subType]) {
      unitsToBuild[type][subType] = 0;
    }

    if (unitsToBuild[type][subType] < -amount) {
      amount = unitsToBuild[type][subType];
    }
    const newUnitsToBuild = { ...unitsToBuild };
    newUnitsToBuild[type][subType] += amount;
    //console.log("data.unitsToBuild", data.unitsToBuild);
    //console.log("result.unitsToBuild", result.unitsToBuild);

    const newCost = UnitIndex.getUnitsToBuilCost(newUnitsToBuild);
    setCostToPay(newCost);
    setUnitsToBuild(newUnitsToBuild);
  };

  //SubComponents
  const UnitListView = () => {
    const cellType = { paddingRight: "10px" };
    const unitPlans =
      gameState.playerStates[gameState.mapPosition - 1].unitPlans;
    return (
      <div style={{ fontSize: "16px", width: "100%", height: "100%" }}>
        <table style={{ width: "100%", tableLayout: "fixed" }}>
          <tbody>
            <tr>
              <td style={{ width: "25%", borderBottom: "4px solid white" }}>
                Type
              </td>
              <td style={{ width: "25%", borderBottom: "4px solid white" }}>
                Sub-Type
              </td>
              <td style={{ width: "20%", borderBottom: "4px solid white" }}>
                Cost
              </td>
              <td style={{ width: "10%", borderBottom: "4px solid white" }}>
                Nbr
              </td>
              <td
                style={{ width: "10%", borderBottom: "4px solid white" }}
              ></td>
            </tr>
            {unitPlans.map((unitPlan, index) => {
              const unitData = UnitIndex.getUnit(
                unitPlan.type,
                unitPlan.subType
              );

              return (
                <tr key={index}>
                  <td style={cellType}>{unitPlan.type}</td>
                  <td style={cellType}>{unitPlan.subType}</td>
                  <td style={cellType}>
                    <CostResourceView
                      costResource={unitData.cost}
                      symbolMode={true}
                    ></CostResourceView>
                  </td>
                  <td style={cellType}>
                    {unitsToBuild[unitPlan.type] &&
                    unitsToBuild[unitPlan.type][unitPlan.subType] !== undefined
                      ? unitsToBuild[unitPlan.type][unitPlan.subType]
                      : 0}
                  </td>
                  <td style={cellType}>
                    <div>
                      <button
                        onClick={() =>
                          handleUnitCountChange(
                            unitPlan.type,
                            unitPlan.subType,
                            1
                          )
                        }
                        style={{ width: "100%" }}
                      >
                        &#8593;
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() =>
                          handleUnitCountChange(
                            unitPlan.type,
                            unitPlan.subType,
                            -1
                          )
                        }
                        style={{ width: "100%" }}
                      >
                        &#8595;
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan="5">TTTTTTTTTTTTTTTTEEEEEEEEEEESSSSSSSSSSSTTTT</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const CostView = () => {
    return <div></div>;
  };

  const ResourceSpendingView = () => {
    return (
      <CostResourceView
        costResource={costToPay}
        verticalMode={true}
      ></CostResourceView>
    );
  };

  const ButtonBack = () => {
    return (
      <button
        className="button-menu"
        onClick={() => {
          callback({ next: "Back", unitsToBuild: unitsToBuild });
        }}
      >
        Back
      </button>
    );
  };

  const ButtonMap = () => {
    return (
      <button
        className="button-menu"
        onClick={() => {
          callback({ next: "Map", unitsToBuild: unitsToBuild });
        }}
      >
        Map
      </button>
    );
  };

  const ButtonBuild = () => {
    return (
      <button
        className="button-menu"
        style={{ fontSize: "120%" }}
        onClick={() => {
          callback({ next: "Build", unitsToBuild: unitsToBuild });
        }}
      >
        <span>Build</span>
      </button>
    );
  };

  const ButtonExhaust = () => {
    return (
      <button
        className="button-menu"
        style={{ fontSize: "120%" }}
        onClick={() => {
          callback({ next: "ExhaustOnMap", unitsToBuild: unitsToBuild });
        }}
      >
        Production : {productionExhausted}
      </button>
    );
  };

  const navigateButtonFontSize = "2.5vh";

  return (
    <div className="container-menu">
      <div className="div-ver-center">
        <div
          className="div-hor-center"
          style={{ height: "70%", width: "100%" }}
        >
          <UnitListView></UnitListView>
        </div>
        <div className="div-hor-center" style={{ height: "30%" }}>
          <div className="div-ver-center" style={{ width: "50%" }}>
            <div
              className="div-fill"
              style={{
                height: "66%",
                fontSize: "150%",
              }}
            >
              <ResourceSpendingView></ResourceSpendingView>
            </div>
            <div className="div-fill" style={{ height: "33%" }}>
              <ButtonBack></ButtonBack>
            </div>
          </div>
          <div className="div-ver-center" style={{ width: "50%" }}>
            <div className="div-fill" style={{ height: "33%" }}>
              <ButtonExhaust></ButtonExhaust>
            </div>
            <div className="div-fill" style={{ height: "33%" }}>
              <ButtonBuild></ButtonBuild>
            </div>
            <div className="div-fill" style={{ height: "33%" }}>
              <ButtonMap></ButtonMap>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
/*return (
    <div className="build-units-container">
      <div>
        <div
          style={{
            position: "relative",
            top: 0,
            left: 0,
            width: "100vw",
            height: "77.5vh",
          }}
        >
          <UnitListView></UnitListView>
        </div>
        <div
          style={{
            position: "relative",
            bottom: 0,
            left: 0,
            width: "100vw",
            height: "22.5vh",
          }}
        >
          <CostView></CostView>
        </div>
        <div
          style={{
            position: "relative",
            bottom: 0,
            left: 0,
            width: "100vw",
            height: "7.5vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
              fontSize: navigateButtonFontSize,
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "50%",
                height: "100%",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "50%",
                height: "100%",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );*/

export default BuildUnitsWFView;

/*return (
    <div className="build-units-container">
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <h2>{taskName}</h2>
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100vw",
          }}
        >
          <CostView></CostView>
        </div>

        <UnitListView></UnitListView>

        <SpendCreditView></SpendCreditView>
        <div>
          <button onClick={() => handleBuildUnit(43)}>Build</button>
        </div>
        <div>
          <button
            onClick={() => {
              setParentPopup(null);
            }}
          >
            Back
          </button>
          <button
            onClick={() => {
              setParentPopup(null);
              setMainPopup({ name: "MapView" });
            }}
          >
            Map
          </button>
        </div>
      </div>
    </div>
  );*/
