import React from "react";
const Resource = require("../../Data/GameData/Resource/ResourceInstance/Resource");
const Amount = require("../../Data/GameData/Resource/Amount");

const ResourceView = ({
  resource,
  abbreviationMode = false,
  symbolMode = false,
  numberMode = false,
  isExhausted = false,
}) => {
  const amount = resource.amount.amount;
  const modifierTotal = Amount.getTotalModifiers(resource.amount);
  let modifierTotalText = "";
  if (modifierTotal != 0) {
    modifierTotalText = `+(${modifierTotal})`;
  }

  let text = " " + resource.type;
  if (abbreviationMode) {
    text = " " + Resource.getAbbreviation(resource.type);
  }
  if (symbolMode) {
    text = " " + Resource.getSymbol(resource.type);
  }
  if (numberMode) {
    text = "";
  }
  text = `${amount}${modifierTotalText}${text}`;
  const color = isExhausted ? "lightGrey" : Resource.getColor(resource.type);

  return <span style={{ color: color }}>{text}</span>;
};

export default ResourceView;
