import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo";
import ScoreData from "../../Data/GameData/Objectifs/ScoreData";
import FactionSpan from "../Utils/FactionSpan";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import TransactionView from "./TransactionView";
import ProposeTransactionView from "./ProposeTransactionView";
import ActionPropose from "../ManageSystem/ActionView/ActionPropose";
import Item from "../../Data/GameData/Transactions/Item";
import ItemView from "./ItemView";
import ItemDetailedView from "./ItemDetailedView";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");

const InventoryView = ({ playerId }) => {
  console.log("RENDER InventoryView playerId: ", playerId);

  const playerDataToDisplay = PlayerData.getPlayerDataFromInGameId(
    StaticGameData.getPlayerData(),
    playerId
  );
  console.log(
    "RENDER InventoryView playerDataToDisplay: ",
    playerDataToDisplay
  );
  const items = playerDataToDisplay.items;
  const artifacts = Item.getArtifactsFromItems(items);
  const relics = Item.getRelicsFromItems(items);

  const DisplayArtifacts = ({ artifacts }) => {
    if (artifacts.length === 0) {
      return;
    }
    return (
      <div>
        <div style={{ textDecoration: "underline" }}>Artifacts : </div>
        {artifacts.map((artifact, key) => (
          <div key={key}>
            <BaseButton
              type={Item.isItemExhausted(artifact) ? "navigation" : "white"}
              onClick={() => {
                Popup.addDynamicLayer(
                  <ItemDetailedView
                    playerId={PlayerData.getPlayerId(playerDataToDisplay)}
                    itemId={artifact.id}
                  ></ItemDetailedView>
                );
              }}
            >
              <ItemView item={artifact} exhaustColorChange={false}></ItemView>
            </BaseButton>
          </div>
        ))}
      </div>
    );
  };

  const DisplayRelics = ({ relics }) => {
    if (relics.length === 0) {
      return;
    }

    return (
      <div>
        <div style={{ textDecoration: "underline" }}>Relics : </div>
        {relics.map((relic, key) => (
          <div key={key}>
            <BaseButton
              onClick={() => {
                Popup.addDynamicLayer(
                  <ItemDetailedView
                    playerId={PlayerData.getPlayerId(playerDataToDisplay)}
                    itemId={relic.id}
                  ></ItemDetailedView>
                );
              }}
            >
              <ItemView item={relic}></ItemView>
            </BaseButton>
          </div>
        ))}
      </div>
    );
  };

  const navigationData = {
    buttonsData: [
      {
        text: "Back",
        callback: () => {
          Popup.deActivateBlackPopup();
        },
      },
    ],
    isMapStandardNavigationData: false,
  };

  return (
    <GameGlobalLayout
      showResourceBarInTitle={false}
      titleComponent={
        <span>
          Inventory of :{" "}
          <FactionLogo
            factionName={playerDataToDisplay.faction.name}
          ></FactionLogo>
        </span>
      }
    >
      <div className="text-box" style={{ textAlign: "center" }}>
        {" "}
        <DisplayArtifacts artifacts={artifacts}></DisplayArtifacts>
        <DisplayRelics relics={relics}></DisplayRelics>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default InventoryView;
