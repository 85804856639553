import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import Popup from "../../Data/Other/Popup.js";
import NavigationView from "../NavigationView.jsx";
import GameGlobalLayout from "../GameGlobalLayout.jsx";
import StaticGameData from "../../Data/GameData/StaticGameData.js";
import LogBookView from "../Utils/LogMessage/LogBookView.jsx";
import BaseButton from "../Utils/BaseButton.jsx";
import FullScreenLogView from "./FullScreenLogView.jsx";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Request = require("../../Common/Requests/Request.js");
const Map = require("../../Data/GameData/MapData/Map.js");
const LogData = require("../../Data/GameData/Activation/LogData.js");
const LogBook = require("../../Data/GameData/Connection/LogBook.js");

const SystemLogActivityView = ({
  system,
  backCallback = () => Popup.deActivateBlackPopup(),
}) => {
  const playerData = StaticGameData.getPlayerData();

  /*console.log("combatLog", combatLog);e
  console.log("activityLog", activityLog);*/

  const navigationData = {
    buttonsData: [{ text: "Back", callback: backCallback }],
  };

  /*if (combatLog) {
    navigationData.buttonsData.push({
      text: "Combat Log",
      callback: () => {
        LogData.setActiveLog(playerData, LogData.LOG_COMBAT);
        Popup.touch();
      },
    });
  }*/

  const DisplayLog = ({ logBook }) => {
    return (
      <div className="text-box">
        <LogBookView logBook={logBook} scrollAtEnd={true}></LogBookView>
      </div>
    );
  };

  const DisplayActivity = () => {
    const activityLog = System.getActivityLog(system);
    console.log("activityLog", activityLog);
    return (
      <div className="text-box">
        <div>fafeaf</div> <div>fafeaf</div> <div>fafeaf</div> <div>fafeaf</div>{" "}
        <div>fafeaf</div> <div>fafeaf</div> <div>fafeaf</div> <div>fafeaf</div>{" "}
        <div>fafeaf</div> <div>fafeaf</div> <div>fafeaf</div> <div>fafeaf</div>{" "}
        <div>fafeaf</div> <div>fafeaf</div> <div>fafeaf</div> <div>fafeaf</div>{" "}
        <div>fafeaf</div> <div>fafeaf</div> <div>fafeaf</div> <div>fafeaf</div>{" "}
        <div>fafeaf</div> <div>fafeaf</div> <div>fafeaf</div> <div>fafeaf</div>{" "}
        <div>fafeaf</div> <div>fafeaf</div> <div>fafeaf</div> <div>fafeaf</div>{" "}
        <div>fafeaf</div> <div>fafeaf</div>
        <LogBookView logBook={activityLog}></LogBookView>
      </div>
    );
  };

  return (
    <GameGlobalLayout
      title={null}
      backgroundType="shadow"
      scrollAtEnd={true}
      rememberScroll={true}
      rememberScrollKey={"activity_log_" + system.name}
    >
      <DisplayActivity></DisplayActivity>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default SystemLogActivityView;
