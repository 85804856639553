import React, { useState } from "react";
import "../CSS/Font.css";
import "../CSS/Menu.css";

const StaticGameData = require("../Data/GameData/StaticGameData");

const ErrorView = ({}) => {
  const callback = StaticGameData.popup.callback;
  const handleOkClick = () => {
    console.log("lalalal");
    StaticGameData.setPopup({ ...StaticGameData.popup, errorMessage: null });

    callback();
  };

  console.log("RENDER ERROR VIEW");

  return (
    <div
      className="div-fill"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "fixed",
        width: "100vw",
        height: "100vh",
      }}
      onClick={() => handleOkClick()}
    >
      <div className="div-hor-center">
        <div className="errorPopup">
          {StaticGameData.popup.errorMessage && (
            <div>
              <span>{StaticGameData.popup.errorMessage}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorView;
