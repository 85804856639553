const CustomMath = require("../../../Common/Math/CustomMath");

class Time {
  static create({
    seconds = 0,
    minutes = 0,
    hours = 0,
    days = 0,
    weeks = 0,
    months = 0,
    years = 0,
  }) {
    return {
      minutes:
        seconds / 60 +
        minutes +
        hours * 60 +
        days * 24 * 60 +
        weeks * 7 * 24 * 60 +
        months * 30 * 24 * 60 +
        years * 365 * 24 * 60,
    };
  }

  static getDisplayTime(time) {
    const minutes = Math.floor(time.minutes);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(weeks / 4);
    const years = Math.floor(months / 12);

    return {
      years,
      months: months % 12,
      weeks: weeks % 4,
      days: days % 7,
      hours: hours % 24,
      minutes: minutes % 60,
    };
  }

  static getDisplayTimeInString(time) {
    const displayTime = Time.getDisplayTime(time);

    let displayString = "";
    if (displayTime.years > 0) {
      displayString += displayTime.years + " years ";
    }
    if (displayTime.months > 0) {
      displayString += displayTime.months + " months ";
    }
    if (displayTime.weeks > 0) {
      displayString += displayTime.weeks + " weeks ";
    }
    if (displayTime.days > 0) {
      displayString += displayTime.days + " days ";
    }
    if (displayTime.hours > 0) {
      displayString += displayTime.hours + " hours ";
    }
    if (displayTime.minutes > 0) {
      displayString += displayTime.minutes + " minutes";
    }

    return displayString;
  }

  static TIME_5_MIN = Time.create({ minutes: 5 });
  static TIME_7_MIN = Time.create({ minutes: 10 });
  static TIME_10_MIN = Time.create({ minutes: 10 });
  static TIME_15_MIN = Time.create({ minutes: 15 });
  static TIME_20_MIN = Time.create({ minutes: 20 });
  static TIME_30_MIN = Time.create({ minutes: 30 });
  static TIME_45_MIN = Time.create({ minutes: 45 });
  static TIME_1_HOUR = Time.create({ hours: 1 });
  static TIME_2_HOUR = Time.create({ hours: 2 });
  static TIME_3_HOUR = Time.create({ hours: 3 });
  static TIME_4_HOUR = Time.create({ hours: 4 });
  static TIME_6_HOUR = Time.create({ hours: 6 });
  static TIME_8_HOUR = Time.create({ hours: 8 });
  static TIME_12_HOUR = Time.create({ hours: 12 });
  static TIME_1_DAY = Time.create({ days: 1 });
  static TIME_2_DAY = Time.create({ days: 2 });
  static TIME_3_DAY = Time.create({ days: 3 });
  static TIME_7_DAY = Time.create({ days: 7 });
}

module.exports = Time;
