import React, { useState } from "react";
import { GameListView } from "../GameList/GameList";
import TestSimpleComponent from "../Test/TestSimpleComponent";
import StaticGameData from "../Data/GameData/StaticGameData";
import GameGlobalLayout from "../GameView/GameGlobalLayout";
import NavigationView from "../GameView/NavigationView";
import MenuNavigationView from "./MenuNavigationView";
import BaseButton from "../GameView/Utils/BaseButton";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import Popup from "../Data/Other/Popup";
import ProfileView from "./ProfileView";
import OptionView from "./OptionView";

export const MainMenu = ({ setRouteMainHandler }) => {
  const globalStateActiveTab = Popup.getGlobalState("MainMenuTab");
  const [activeTab, setActiveTab] = useState(
    globalStateActiveTab || "listOfGames"
  );

  console.log("MainMenu RENDER");

  const handleTabChange = (tabName) => {
    Popup.addGlobalState("MainMenuTab", tabName);
    setActiveTab(tabName);
    //setRouteMain({ activeScreen: "MainMenu" });
  };

  const navigationData = {
    buttonsData: [
      {
        text: "Games",
        callback: () => {
          handleTabChange("listOfGames");
        },
      },
      {
        text: "Profile",
        callback: () => {
          handleTabChange("userProfile");
        },
      },
      {
        text: "Options",
        callback: () => {
          handleTabChange("options");
        },
      },
    ],
    taskBarData: { text: "Connected as : " + StaticGameData.username },
  };

  return (
    <GameGlobalLayout showTitle={false} backgroundType="uniform">
      <div className="text-box-center">
        <div>
          {false && (
            <div>
              {" "}
              <button onClick={() => handleTabChange("listOfGames")}>
                List of Games
              </button>
              <button>User Profile Config</button>
              <button>Options</button>
              <button onClick={() => handleTabChange("chat")}>Chat</button>
              <button onClick={() => handleTabChange("friends")}>
                Friends
              </button>
            </div>
          )}
        </div>
        <LineSpace></LineSpace>
        <div>
          {/* Tab content */}
          {activeTab === "listOfGames" && (
            <GameListView
              setRouteMainHandler={setRouteMainHandler}
              popup={StaticGameData.popup}
            ></GameListView>
          )}
          {activeTab === "userProfile" && <ProfileView></ProfileView>}
          {activeTab === "options" && <OptionView></OptionView>}
          {activeTab === "chat" && <div>Chat Content</div>}
          {activeTab === "friends" && (
            <div>
              <TestSimpleComponent></TestSimpleComponent>
            </div>
          )}
        </div>
      </div>
      <MenuNavigationView navigationData={navigationData}></MenuNavigationView>
    </GameGlobalLayout>
  );
};
