import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import FactionLogo from "../Utils/FactionLogo";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import CostView from "../Utils/CostView";
import Logo from "../Utils/Logo";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import ResearchActionData from "../../Data/GameData/ActionData/ResearchActionData";
import FleetLineView from "../Object/FleetLineView";
import LogBookView from "../Utils/LogMessage/LogBookView";
import AbilityInput from "./AbilityInput";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const SpaceObject = require("../../Data/GameData/MapData/SpaceObject.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const TechTree = require("../../Data/GameData/Technology/TechTree.js");
const Tech = require("../../Data/GameData/Technology/Tech.js");
const LogBook = require("../../Data/GameData/Connection/LogBook");
const Request = require("../../Common/Requests/Request.js");
const CombatRecording = require("../../Data/GameData/Combat/CombatRecording.js");
const Planet = require("../../Data/GameData/MapData/Planet.js");

const AbilityList = ({ playerData }) => {
  const techAbilityList = TechTree.getTechAbilitiesOwned(playerData.techTree);

  const DisplayTechAbility = ({ tech }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const buttonType =
      Tech.isExhausted(tech) === true ? "greyOut" : "navigation";

    return (
      <div key={tech.name} style={{ margin: "0.5em" }}>
        <BaseButton
          type={buttonType}
          onClick={() => {
            if (isExpanded === false) {
              setIsExpanded(true);
              return;
            }

            Popup.deActivateBlackPopup();
            if (Tech.isExhausted(tech)) {
              UIMessage.displayInfoMessage(
                "Ability is exhausted",
                "This ability is exhausted. This means that you cannot use it until next round."
              );
            } else {
              AbilityInput.getInputFromTech(tech)();
            }
          }}
        >
          <div style={{ textDecoration: "underline" }}> {tech.name}</div>
          {isExpanded === true && <div> {tech.effect}</div>}
        </BaseButton>
      </div>
    );
  };

  const DisplayListAbility = () => {
    return (
      <div style={{ margin: "0.5em" }}>
        {techAbilityList.map((tech, index) => (
          <DisplayTechAbility key={index} tech={tech} />
        ))}
        {techAbilityList.length === 0 && (
          <div style={{ margin: "0.5em" }}>No ability available</div>
        )}
      </div>
    );
  };
  return (
    <div>
      <div>
        <DisplayListAbility></DisplayListAbility>
      </div>

      <div>
        <BaseButton
          onClick={() => {
            Popup.deActivateBlackPopup();
          }}
        >
          Back
        </BaseButton>
      </div>
    </div>
  );
};

export default AbilityList;
