const LogBook = require("../Connection/LogBook");
const LogMessage = require("../Connection/LogMessage");
const Map = require("../MapData/Map");
const TechTree = require("../Technology/TechTree");
const CustomMath = require("../../../Common/Math/CustomMath");
const ScoreData = require("./ScoreData");
const UIMessage = require("../Connection/UIMessage");
const Phase = require("../Game/Phase");
const PlayerData = require("../PlayerData/PlayerData");
const HiddenScore = require("./HiddenScore");

class EndGameRanking {
  static saveRankingToGame(game) {}

  static getPositionString(rank) {
    if (rank === 1) {
      return "1st";
    }
    if (rank === 2) {
      return "2nd";
    }
    if (rank === 3) {
      return "3rd";
    }
    return rank + "th";
  }

  static getIsGameFinished(playerData) {
    return playerData.gameStatus.isGameFinished;
  }

  static addHiddenVP(playerDataList) {
    for (let i = 0; i < playerDataList.length; i++) {
      const playerData = playerDataList[i];
      const hiddenVP = HiddenScore.getHiddenScore(playerData);
      ScoreData.addScore(playerData.scoreData, hiddenVP, "Adding hidden VP");
      HiddenScore.setHiddenScore(playerData, 0);
    }
  }

  static adminStartOfRound(resolutionData) {
    const gameStateList = resolutionData.gameStateList;
    const playerDataList = gameStateList.map(
      (gameState) => gameState.playerData
    );

    if (EndGameRanking.isGameFinished(playerDataList)) {
      EndGameRanking.setGameFinished(playerDataList);

      //Adding hiddenVP
      EndGameRanking.addHiddenVP(playerDataList);

      const rankedList = EndGameRanking.create(playerDataList);

      const LB = LogBook.createLogBook();
      LogBook.generateAddMessage(LB, "Game is finished ! ", []);
      LogBook.generateAddMessage(LB, "End of game ranking : ", []);

      for (let i = 0; i < rankedList.length; i++) {
        LogBook.generateAddMessage(
          LB,
          "$faction$ " +
            rankedList[i].faction +
            " : " +
            this.getPositionString(rankedList[i].rank) +
            " (" +
            rankedList[i].score +
            " VP)",
          [rankedList[i].faction]
        );
      }

      //update player data
      for (let i = 0; i < playerDataList.length; i++) {
        UIMessage.sendInfoLogBookToClient(
          playerDataList[i],
          "End of Game Ranking",
          LB
        );
        playerDataList[i].phase = Phase.PHASE_END_OF_GAME;
        playerDataList[i].step = Phase.STEP_END_OF_GAME;
      }

      return rankedList;
    } else {
      EndGameRanking.setGameNotFinished(playerDataList);
      return null;
    }
  }

  static isGameFinished(playerDataList) {
    const vpLimit = playerDataList[0].gameParam.vpLimit;
    const roundAmount = PlayerData.getRoundAmount(playerDataList[0]);

    // Check if any player's score meets or exceeds the vpLimit
    /*return playerDataList.some(
      (player) => ScoreData.getScore(player.scoreData) >= vpLimit
    );*/
    return playerDataList[0].roundNumber > roundAmount;
  }

  static setGameFinished(playerDataList) {
    playerDataList.forEach((player) => {
      player.gameStatus = { isGameFinished: true };
    });
  }

  static setGameNotFinished(playerDataList) {
    playerDataList.forEach((player) => {
      player.gameStatus = { isGameFinished: false };
    });
  }

  static create(playerDataList) {
    const sortedList = [...playerDataList].sort(
      (a, b) =>
        ScoreData.getScore(b.scoreData) - ScoreData.getScore(a.scoreData)
    );

    // Initialize ranking list with rank and ex aequo handling
    const rankedList = [];
    let currentRank = 1;

    sortedList.forEach((player, index) => {
      if (
        index > 0 &&
        ScoreData.getScore(player.scoreData) ===
          ScoreData.getScore(sortedList[index - 1].scoreData)
      ) {
        // If the score is the same as the previous player, assign the same rank
        rankedList.push({
          faction: player.faction.name,
          rank: currentRank,
          score: ScoreData.getScore(player.scoreData),
        });
      } else {
        // Otherwise, assign the new rank and update currentRank
        currentRank = index + 1;
        rankedList.push({
          faction: player.faction.name,
          rank: currentRank,
          score: ScoreData.getScore(player.scoreData),
        });
      }
    });

    return rankedList; // Return the new list with faction and rank only
  }

  static getRankFromFaction(rankedList, factionName) {
    return rankedList.find((rank) => rank.faction === factionName).rank;
  }
}

module.exports = EndGameRanking;
