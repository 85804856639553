const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const ActivationData = require("../Activation/ActivationData.js");
const System = require("../MapData/System.js");
const Unit = require("../MapData/Unit.js");
const Map = require("../MapData/Map.js");
const Fleet = require("../MapData/Fleet.js");
const Faction = require("../PlayerData/Faction.js");
const UIMessage = require("../Connection/UIMessage.js");
const Game = require("../Game/Game.js");
const Color = require("../../../Common/Config/Colors.js");

class StartOfGameAction {
  static async pickFaction({ gameState, factionName, game }) {
    const faction = Faction.createFaction(factionName);

    const message = UIMessage.createMessage(
      "Faction chosen",
      UIMessage.TYPE_INFO
    );

    const playerData = gameState.playerData;
    PlayerData.pickFaction(playerData, factionName);
    UIMessage.addLine(
      message,
      "You have chosen the faction " + factionName + "."
    );
    UIMessage.addLine(
      message,
      "The home system of your faction has been placed."
    );

    //Select color for player
    {
      const faction = Faction.createFaction(factionName);
      if (faction.dedicatedColor) {
        playerData.color = faction.dedicatedColor;
      } else {
        const usedColors = Game.getUsedColors(game);

        const color1 = faction.color1;
        const color2 = faction.color2;
        //check if color1 and color2 are used
        let color;
        if (!usedColors.includes(color1)) {
          color = color1;
        } else if (!usedColors.includes(color2)) {
          color = color2;
        } else {
          const availableColors = Color.possiblePlayerColors.filter(
            (color) => !usedColors.includes(color)
          );
          if (availableColors.length === 0) {
            throw new Error("No available colors");
          }
          //Pick a random color in availableColors
          color =
            availableColors[Math.floor(Math.random() * availableColors.length)];
        }
        playerData.color = color;
        //console.log("Player color:", color);
      }
    }

    const homeSystem = Map.getSystemFromCoords(
      playerData.map,
      playerData.homeSystemCoords.x,
      playerData.homeSystemCoords.y
    );

    Faction.fillEmptyHSWithPlayerFaction(playerData, faction, homeSystem);

    /*Map.replaceSystemFromCoords(
      playerData.map,
      playerData.homeSystemCoords.x,
      playerData.homeSystemCoords.y,
      Faction.createHomeSystem(
        gameState.playerData,
        faction,
        playerData.homeSystemCoords.x,
        playerData.homeSystemCoords.y,
        factionName
      )
    );*/

    UIMessage.addLine(
      message,
      'The color "' + playerData.color + '" has been assigned to you.'
    );
    //End of function
    //gameState.playerData. = message;
  }
}

module.exports = StartOfGameAction;
