import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import BaseButton from "../GameView/Utils/BaseButton";
import ImagesUrl from "../Data/Other/ImagesUrl";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import Color from "../Common/Config/Colors";
import StaticGameData from "../Data/GameData/StaticGameData";
import WelcomeScreen from "../Main/WelcomeScreen";
import BaseImage from "../GameView/Utils/BaseImage";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import BaseInput from "../GameView/Utils/BaseInput";
import Request from "../Common/Requests/Request";

const Popup = require("../Data/Other/Popup");

export const ResetPasswordFromGame = ({}) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [token, setToken] = useState("");

  const handleResetPassword = async () => {
    if (!password || !passwordConfirmation) {
      UIMessage.displayInfoMessageToStaticGameData(
        null,
        "Please enter your password and password confirmation."
      );
      return;
    }

    if (password !== passwordConfirmation) {
      UIMessage.displayInfoMessageToStaticGameData(
        null,
        "Passwords do not match."
      );
      return;
    }

    Request.callPostAPI(
      { token: token, password: password, currentPassword: currentPassword },
      "reset-password-from-game",
      (result) => {}
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div>Current Password : </div>
      <LineSpace></LineSpace>
      <div>
        <BaseInput
          type="password"
          id="currentPassword"
          value={currentPassword}
          onChange={(e) => {
            const input = e.target.value;
            setCurrentPassword(input);
          }}
        ></BaseInput>{" "}
      </div>
      <div>Password : </div>
      <LineSpace></LineSpace>
      <div>
        <BaseInput
          type="password"
          id="password"
          value={password}
          onChange={(e) => {
            const input = e.target.value;
            setPassword(input);
          }}
        ></BaseInput>
      </div>
      <LineSpace></LineSpace>
      <div>Password confirmation : </div>
      <LineSpace></LineSpace>
      <div>
        <BaseInput
          type="password"
          id="passwordConfirmation"
          value={passwordConfirmation}
          onChange={(e) => {
            const input = e.target.value;
            setPasswordConfirmation(input);
          }}
        ></BaseInput>
      </div>
      <LineSpace></LineSpace>
      <div>
        <BaseButton onClick={handleResetPassword}>Apply Change</BaseButton>
      </div>
      <LineSpace></LineSpace>
      <div>
        <BaseButton
          onClick={() => {
            Popup.goBack();
          }}
        >
          Back
        </BaseButton>
      </div>
      <LineSpace></LineSpace>
    </div>
  );
};

export default ResetPasswordFromGame;
