import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import CostView from "../Utils/CostView";
import Cost from "../../Data/GameData/Utils/Cost";
import ActivePassiveAbility from "../../Data/GameData/Ability/ActivePassiveAbility";
import Item from "../../Data/GameData/Transactions/Item";
import Faction from "../../Data/GameData/PlayerData/Faction";
import NavigationView from "../NavigationView";
import Unit from "../../Data/GameData/MapData/Unit";
import UnitImageView from "../Object/UnitImageView";
import StaticGameData from "../../Data/GameData/StaticGameData";
import ImagesUrl from "../../Data/Other/ImagesUrl";
import GameGlobalLayout from "../GameGlobalLayout";
import Popup from "../../Data/Other/Popup";
import HeroNames from "../../Data/GameData/MinorFaction/HeroNames";
import Color from "../../Common/Config/Colors";
import MinorFactionAbility from "../../Data/GameData/MinorFaction/MinorFactionAbility";
import MinorFaction from "../../Data/GameData/MinorFaction/MinorFaction";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import BaseButton from "../Utils/BaseButton";
import Logo from "../Utils/Logo";
import AbilityView from "../Ability/AbilityView";
import BaseImage from "../Utils/BaseImage";

const HeroInfoView = ({ hero }) => {
  const playerData = StaticGameData.getPlayerData();

  const navigationData = {
    buttonsData: [{ text: "Back", callback: () => Popup.goBack() }],
  };

  const imageStyle = {
    width: "256px",
    height: "auto",
    display: "block",
    borderWidth: "4px",
    borderStyle: "solid",
    borderColor: Color.BASE_BUTTON_BORDER,
    borderRadius: "5px",
    overflow: "hidden",
  };

  const DisplayAbility = () => {
    const heroDesc = MinorFactionAbility.getAbilities(hero);
    const abilityDescData = MinorFaction.getFactionDescDataFromName(hero.name);
    const abilityName = abilityDescData.ability.ability.name;
    const abilityDescription = abilityDescData.ability.ability.text;

    const logoName = "action";

    return (
      <div style={{ marginTop: "1em" }}>
        <BaseButton
          type={"white"}
          onClick={() => {
            UIMessage.displayInfoMessage(
              "Not now",
              "Hero's abilities are to use in game, after drafting, if you control the system they are in."
            );
          }}
        >
          <div style={{ textDecoration: "underline" }}>{abilityName}</div>
          <div>
            Cost : {hero.populationCost} <Logo logoName="population"></Logo>
          </div>

          <AbilityView
            abilityDesc={abilityDescData.ability.ability}
          ></AbilityView>
        </BaseButton>
        <div style={{ marginBottom: "1em" }}>
          Each round, the <Logo logoName="population"></Logo> cost for resolving
          this action decrease by 2 (min {hero.populationCostMin}, max{" "}
          {hero.populationCostMax}).
        </div>
      </div>
    );
  };

  return (
    <GameGlobalLayout title={null} backgroundType="shadow">
      <div className="text-box-center">
        {" "}
        <div className="div-fill">
          <BaseImage
            style={imageStyle}
            src={HeroNames.getHeroImageFileName(hero.name)}
            alt={"LOGO_" + hero.name}
          />
        </div>
        <DisplayAbility></DisplayAbility>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default HeroInfoView;
