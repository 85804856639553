import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";

import IconGridView from "./IconGridView.jsx";
import IconView from "../Icon/IconView.jsx";
import SpaceObjectImageView from "../Object/SpaceObjectImageView.jsx";
import GameState from "../../Data/GameData/Game/GameState.js";
import Logo from "../Utils/Logo.jsx";
import FactionLogo from "../Utils/FactionLogo.jsx";
import FleetsSummaryView from "../ManageSystem/FleetsSummaryView.jsx";
import UnitImageView from "../Object/UnitImageView.jsx";
import UnitsStackView from "../Units/UnitsStackView.jsx";
import ImagesUrl from "../../Data/Other/ImagesUrl.js";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject.js";
import SecondaryObjectView from "./SecondaryObjectView.jsx";
import Curiosity from "../../Data/GameData/MapData/Curiosity.js";
import BaseImage from "../Utils/BaseImage.jsx";
import BaseImageMapZoom from "../Utils/BaseImageMapZoom.jsx";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Popup = require("../../Data/Other/Popup.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const ActivationData = require("../../Data/GameData/Activation/ActivationData.js");
const Phase = require("../../Data/GameData/Game/Phase.js");
const EndRoundReviewCA = require("../../ClientAction/EndRoundReviewCA.js");
const Unit = require("../../Data/GameData/MapData/Unit.js");

const CuriosityView = ({ curiosity, sizeX, sizeY, mapView = false }) => {
  const playerData = StaticGameData.getPlayerData();

  if (curiosity === System.BACK_NONE) return;
  let imageUrl = "";
  if (curiosity === Curiosity.object.mineralField.name) {
    imageUrl = ImagesUrl.BACK_MINERAL_FIELD;
  }
  if (curiosity === Curiosity.object.asteroidField.name) {
    imageUrl = ImagesUrl.BACK_ASTEROID_FIELD;
  }
  if (curiosity === Curiosity.object.uteriumDust.name) {
    imageUrl = ImagesUrl.BACK_UTORIUM_DUST;
  }
  if (curiosity === Curiosity.object.storm.name) {
    imageUrl = ImagesUrl.BACK_STORM;
  }
  if (curiosity === Curiosity.object.crystalField.name) {
    imageUrl = ImagesUrl.BACK_CRYSTAL_FIELD;
  }
  if (curiosity === Curiosity.object.fluxAnomaly.name) {
    imageUrl = ImagesUrl.BACK_FLUX_ANOMALY;
  }
  if (curiosity === Curiosity.object.nebula.name) {
    imageUrl = ImagesUrl.BACK_NEBULAE;
  }

  const imageStyle = {
    //If maxWidth defined, use it here, otherwise use 100%
    maxWidth: sizeX ? sizeX : "100%",
    maxHeight: sizeY ? sizeY : "100%",
    height: "auto",
    opacity: mapView ? 0.5 : 1,
    //display: "block",
  };

  return (
    <div>
      <BaseImage
        style={imageStyle}
        src={imageUrl}
        alt={curiosity}
        zoomTreshold={6}
        onlyOneRes={true}
      ></BaseImage>
    </div>
  );
};

/*<div
            className="div-fill"
            style={{ position: "relative", height: "20%" }}
          >
            <ObjectText></ObjectText>
          </div>*/

export default CuriosityView;
