const Phase = require("../Game/Phase.js");
const ActionCommonData = require("../ActionData/ActionCommonData.js");
const AdminCreateUnitData = require("./AdminCreateUnitData.js");
const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction.js");
const Map = require("../MapData/Map.js");
const ScoreData = require("../Objectifs/ScoreData.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");

class AdminActionData {
  static SCREEN_GLOBAL = "SCREEN_GLOBAL";
  static SCREEN_CREATE_UNIT = "SCREEN_CREATE_UNIT";
  static SCREEN_REMOVE_UNIT = "SCREEN_REMOVE_UNIT";

  static ACTION_CREATE_UNIT = "ACTION_CREATE_UNIT";
  static ACTION_REMOVE_UNIT = "ACTION_REMOVE_UNIT";
  static ACTION_CHANGE_OWNER_OBJECT = "ACTION_CHANGE_OWNER_OBJECT";
  static ACTION_MODIFY_RESOURCES = "ACTION_MODIFY_RESOURCES";

  static prepare(playerData) {
    const data = {
      activeScreen: this.SCREEN_GLOBAL,
      actionType: null,
      createUnitData: AdminCreateUnitData.create(playerData),
      removeUnitData: null,
      changeOwnerObject: {
        objectName: null,
        isPlanet: false,
        newOwnerFaction: null,
      },
      modifyResources: {
        mineral: 0,
        energy: 0,
        science: 0,
        vp: 0,
        population: 0,
        credit: 0,
      },
    };

    ActionCommonData.prepare(
      playerData,
      Phase.PHASE_ACTION,
      Phase.STEP_ADMIN,
      data
    );
  }

  static getCreateUnitData = (playerData) => {
    return ActionCommonData.getData(playerData).createUnitData;
  };

  static getActiveScreen = (playerData) => {
    return ActionCommonData.getData(playerData).activeScreen;
  };

  static setActiveScreen = (playerData, activeScreen) => {
    ActionCommonData.getData(playerData).activeScreen = activeScreen;
  };

  static resolveClient(playerData, actionType) {
    const data = ActionCommonData.getData(playerData);
    data.actionType = actionType;
    ActionCommonData.resolveClient(playerData);
  }

  static resolveServer(playerData, resolveData) {
    ActionCommonData.resolveServerIni(playerData, resolveData);

    const data = ActionCommonData.getData(playerData);
    if (data.actionType === this.ACTION_CREATE_UNIT) {
      AdminCreateUnitData.resolveCreateUnits(playerData);
    }

    if (data.actionType === this.ACTION_REMOVE_UNIT) {
      this.resolveRemoveUnit(playerData, data.removeUnitData.units);
    }

    if (data.actionType === this.ACTION_CHANGE_OWNER_OBJECT) {
      this.changeOwnerOfObject(playerData);
    }

    if (data.actionType === this.ACTION_MODIFY_RESOURCES) {
      this.modifyResources(playerData);
    }
  }

  static resolveRemoveUnit(playerData, units) {
    units.forEach((unit) => {
      Map.removeUnit(playerData, unit);
    });
  }

  static changeOwnerOfObject(playerData) {
    const data = ActionCommonData.getData(playerData).changeOwnerObject;
    if (data.isPlanet) {
      const planet = Map.getSpaceObjectFromName(
        playerData.map,
        data.objectName
      );
      planet.faction = data.newOwnerFaction ? data.newOwnerFaction : null;
    } else {
      const system = Map.getSystemFromName(playerData.map, data.objectName);
      system.faction = data.newOwnerFaction ? data.newOwnerFaction : null;
    }
  }

  static modifyResources(playerData) {
    const data = ActionCommonData.getData(playerData).modifyResources;

    playerData.mineral = CustomMath.roundDec(playerData.mineral + data.mineral);
    playerData.energy = CustomMath.roundDec(playerData.energy + data.energy);
    playerData.science = CustomMath.roundDec(playerData.science + data.science);
    playerData.population = CustomMath.roundDec(
      playerData.population + data.population
    );
    playerData.credit = CustomMath.roundDec(playerData.credit + data.credit);
    const scoreData = playerData.scoreData;

    ScoreData.addScore(scoreData, data.vp, "Admin modification of vp");
  }
}

module.exports = AdminActionData;
