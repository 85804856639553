import React, { useState } from "react";
import "../CSS/Font.css";
import "../CSS/Menu.css";
import BaseButton from "./Utils/BaseButton";
import LogBookView from "./Utils/LogMessage/LogBookView";
import Color from "../Common/Config/Colors";
import DraftData from "../Data/GameData/Faction/DraftData";
import PlayerData from "../Data/GameData/PlayerData/PlayerData";

const Popup = require("../Data/Other/Popup");
const StaticGameData = require("../Data/GameData/StaticGameData");

const TaskBarView = ({ taskBarData, elementStyle }) => {
  //console.log("TaskBarView setInGame : ", StaticGameData.setInGame);

  //Rewrite content according to Drafting
  if (Popup.game) {
    const playerData = StaticGameData.getPlayerData();

    //console.log("TaskBarView playerData : ", playerData);
    if (
      playerData.gameParam.draft &&
      !DraftData.isDraftFinished(playerData.draftData)
    ) {
      taskBarData = {};
      const draftData = playerData.draftData;
      taskBarData.displayText = null;
      taskBarData.generateLogBook = null;
      taskBarData.content = null;
      const activePlayer = DraftData.getActivePlayer(draftData);
      const playerDisplayName = PlayerData.getPlayerDisplayName(
        playerData,
        activePlayer.playerInGameId
      );

      if (
        playerData.playerInGameId ===
        DraftData.getActivePlayer(draftData).playerInGameId
      ) {
        taskBarData.text =
          "Up to you to draft something (faction, hero or position), as " +
          PlayerData.getPlayerDisplayName(
            playerData,
            playerData.playerInGameId
          ) +
          ".";
      } else {
        taskBarData.text =
          "You are " +
          PlayerData.getPlayerDisplayName(
            playerData,
            playerData.playerInGameId
          ) +
          ". Up to the player " +
          playerDisplayName +
          " to draft something (faction, hero or position)";
      }
    }
  }

  //normal behaviour

  if (!taskBarData) {
    return;
  }

  if (
    !taskBarData.text &&
    !taskBarData.displayText &&
    !taskBarData.generateLogBook &&
    !taskBarData.content
  ) {
    return null;
  }

  const taskBarText = taskBarData.text;
  const okCallback = taskBarData.okCallback;
  const cancelCallback = taskBarData.cancelCallback;
  const helpCallback = taskBarData.helpCallback;
  const doneCallback = taskBarData.doneCallback;
  const customButton = taskBarData.customButton;
  //const { text, okCallback, cancelCallback } = taskBarData;

  //console.log("TaskBarView taskBarData : ", taskBarData);
  //console.log("TaskBarView okCallback : ", okCallback);

  return (
    <div
      className="div-fill"
      style={{ width: "99vw" }}

      /*style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        backgroundColor: "rgb(54, 54, 54)",
        zIndex: 1000,
        width: "100vw",
        height: "auto",
        paddingTop: paddingTopBottom,
        paddingBottom: paddingTopBottom,
        fontSize: "2vh",
      }}*/
    >
      <div
        style={{
          margin: "5px",
          padding: "5px",
          backgroundColor: "RGB(255,255,255,0.85)",
          color: "black",
          //border: "1px solid black",
          borderRadius: "10px",
        }}
      >
        {/* Your task content here */}
        {taskBarText && (
          <div style={elementStyle}>
            <span>{taskBarText}</span>
          </div>
        )}
        {taskBarData.displayText && (
          <div style={elementStyle}>
            <span>{taskBarData.displayText()}</span>
          </div>
        )}
        {taskBarData.content && (
          <div style={elementStyle}>{taskBarData.content}</div>
        )}
        {taskBarData.generateLogBook && (
          <div style={elementStyle}>
            <LogBookView
              logBook={taskBarData.generateLogBook()}
              textAlign="center"
            ></LogBookView>
          </div>
        )}
        <div className="div-fill">
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {cancelCallback && (
              <div style={elementStyle}>
                <BaseButton onClick={cancelCallback}> Cancel </BaseButton>
              </div>
            )}
            {helpCallback && (
              <div style={elementStyle}>
                <BaseButton onClick={helpCallback}> Cancel </BaseButton>
              </div>
            )}
            {okCallback && (
              <div style={elementStyle}>
                <BaseButton onClick={okCallback}> Ok </BaseButton>
              </div>
            )}
            {doneCallback && (
              <div style={elementStyle}>
                <BaseButton onClick={doneCallback}> Done </BaseButton>
              </div>
            )}
            {customButton && (
              <div style={elementStyle}>
                <BaseButton onClick={customButton.callback}>
                  {" "}
                  {customButton.text}{" "}
                </BaseButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskBarView;
