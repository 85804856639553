const StaticGameData = require("../../Data/GameData/StaticGameData");
const Popup = require("../../Data/Other/Popup");
const UIMessage = require("../../Data/GameData/Connection/UIMessage");
const DraftData = require("../../Data/GameData/Faction/DraftData");
const GameUpdate = require("../../Data/GameData/Game/GameUpdate");
const GameGlobalFunctions = require("../../Data/Other/GameGlobalFunctions");
const ChatStaticData = require("../../Data/ChatData/ChatStaticData");

class Request {
  static afterGameStateChanged() {}

  static async forceEndOfRound() {
    await this.nextRound(true);
  }

  static async updateGameState(updateData, updateTransactions = false) {
    console.log("updateGameState", updateData);
    Popup.setClientWaitingServer(true);

    try {
      const reqData = {
        gameId: StaticGameData.gameState.gameId,
        updateData: updateData,
        clientPhase: StaticGameData.getPlayerData().phase,
        clientStep: StaticGameData.getPlayerData().step,
      };
      const response = await fetch("/api/update-game-state", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reqData: reqData,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.errorMessage) {
          console.log("Error message : ", result.errorMessage);
          UIMessage.setMessageToDisplay(
            StaticGameData.getPlayerData(),
            result.errorMessage
          );
        } else {
          if (updateTransactions) {
            await StaticGameData.updateTransactions();
          }
          StaticGameData.setGameState(result);
          StaticGameData.updateTransactionsFunction(result.transactionData);
        }

        Popup.touch();
      } else {
        return response.json();
      }
      Popup.setClientWaitingServer(false);
    } catch (error) {
      Popup.setClientWaitingServer(false);

      console.error("Error :", error);
    }
  }

  static async callPostAPI(
    bodyData,
    API_URL,
    callback = () => {},
    printLoading = true,
    displayMessages = true
  ) {
    if (printLoading) {
      Popup.setClientWaitingServer(true);
    }

    try {
      const reqData = bodyData;

      const response = await fetch("/api/" + API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reqData: reqData,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        //console.log("result", result);
        if (result.errorMessage && displayMessages) {
          console.log("Error message : ", result.errorMessage);
          UIMessage.setMessageToDisplay(
            StaticGameData.getPlayerData(),
            result.errorMessage
          );
          if (printLoading) {
            Popup.setClientWaitingServer(false);
          }
        } else {
          if (result.messageToStatic && displayMessages) {
            UIMessage.displayInfoMessageToStaticGameData(
              result.messageToStatic.title,
              result.messageToStatic.message
            );
          }

          if (printLoading) {
            Popup.setClientWaitingServer(false);
          }
        }

        if (result.message && displayMessages) {
          if (Popup.game) {
            UIMessage.displayInfoMessage(null, result.messageToStatic.message);
          } else {
            UIMessage.displayInfoMessageToStaticGameData(null, result.message);
          }
        }

        callback(result);

        return result;
      } else {
        const result = await response.json();

        if (result.message && displayMessages) {
          if (Popup.game) {
            UIMessage.displayInfoMessage(null, result.messageToStatic.message);
          } else {
            UIMessage.displayInfoMessageToStaticGameData(null, result.message);
          }
        }
        if (printLoading) {
          Popup.setClientWaitingServer(false);
        }
        return result;
      }
    } catch (error) {
      //throw error;

      if (printLoading) {
        Popup.setClientWaitingServer(false);
      }

      console.error("Error :", error);
      //throw error;
    }
  }

  /*static async getPlayerData() {
    const gameState = await this.getGameState();
    return gameState.playerData;
  }*/

  static async nextRound(force = false) {
    const reqData = {
      gameId: StaticGameData.gameState.gameId,
      force: force,
    };
    Popup.setClientWaitingServer(true);
    try {
      const response = await fetch("/api/next-round", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reqData: reqData,
        }),
      });

      if (response.ok) {
        const gameStateData = await response.json();
        StaticGameData.setGameState(gameStateData);
        StaticGameData.updateTransactionsFunction(
          gameStateData.transactionData
        );

        Popup.touch();
      } else {
        return response.json();
      }
      Popup.setClientWaitingServer(false);
    } catch (error) {
      Popup.setClientWaitingServer(false);

      console.error("Error :", error);
    }
  }

  static async lightUpdate() {
    try {
      const response = await fetch("/api/get-game-state", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: StaticGameData.gameState.gameId,
          lightUpdate: true,
        }),
      });

      const lightGameState = await response.json();

      //Check if round did not change, otherwise full update
      if (
        StaticGameData.gameState.playerData.roundNumber !==
        lightGameState.playerData.roundNumber
      ) {
        console.log(
          "GameUpdate : ",
          " Realised inside light update that need to full update the game"
        );
        this.getGameState();
        return;
      }

      this.partialGameUpdate(
        StaticGameData.gameState.playerData,
        lightGameState.playerData
      );
      GameUpdate.setGameIncrement(
        StaticGameData.gameState.playerData.gameUpdate,
        lightGameState.playerData.gameUpdate
      );

      StaticGameData.updateTransactionsFunction(lightGameState.transactionData);
      //ChatStaticData.appendNewChatItems(lightGameState.chatItemsReturn);

      Popup.touch();
    } catch (error) {
      console.error("Error fetching gameState light:", error);
    }
  }

  static routeLightHeavyUpdate(roundNumber) {
    const playerData = StaticGameData.gameState.playerData;
    console.log("GameUpdate : ", "playerData : ", playerData);
    console.log("GameUpdate : ", "need update, checking which type of update");
    if (
      // If draft is finished
      !playerData.gameParam.draft ||
      DraftData.isDraftFinished(playerData.draftData)
    ) {
      //If round number is the same, only update playerwaiting for next round
      if (StaticGameData.gameState.playerData.roundNumber === roundNumber) {
        console.log("GameUpdate : ", " Need to light update the game");
        this.lightUpdate();
        return;
      }
    }
    //If full update, unstack to map. This will happens only when start new round for now
    console.log("GameUpdate : ", " Need to full update the game");
    this.getGameState();
  }

  static async getGameState(
    printLoading = true,
    eraseMessages = false,
    automaticUpdate = false
  ) {
    try {
      // Fetch the gameState from the Node.js server
      // Game state is the combination of player data and hidden data
      // Hidden data is always null in the client
      if (printLoading) {
        Popup.setClientWaitingServer(true);
      }

      const response = await fetch("/api/get-game-state", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: StaticGameData.gameState.gameId,
        }),
      });

      const gameState = await response.json();

      //Erase messages if not needed to print messages again.
      /*if (
        eraseMessages &&
        gameState.playerData.roundNumber ===
          StaticGameData.gameState.playerData.roundNumber &&
        !(
          StaticGameData.gameState.playerData.gameParam.draft &&
          !DraftData.isDraftFinished(
            StaticGameData.gameState.playerData.draftData
          )
        )
      ) {
        UIMessage.resetMessageToDisplay(gameState.playerData);
      }*/

      //Don't update all gameState if needed
      if (false && automaticUpdate) {
        //if not in draft
        if (
          !StaticGameData.gameState.playerData.gameParam.draft ||
          DraftData.isDraftFinished(
            StaticGameData.gameState.playerData.draftData
          )
        ) {
          //If round number is the same, only update playerwaiting for next round
          if (
            StaticGameData.gameState.playerData.roundNumber ===
            gameState.playerData.roundNumber
          ) {
            console.log("Only updating partially gamestate");
            this.partialGameUpdate(
              StaticGameData.gameState.playerData,
              gameState.playerData
            );
            GameUpdate.setGameIncrement(
              StaticGameData.gameState.playerData.gameUpdate,
              gameState.playerData.gameUpdate
            );
            Popup.touch();
            return;
          } else {
            //If full update, unstack to map. This will happens only when start new round for now
            Popup.unStackToMap();
          }
        }
      }

      // Update the component state with the fetched gameState
      //console.log("gameState : ", gameState);
      StaticGameData.setGameState(gameState);

      StaticGameData.updateTransactionsFunction(gameState.transactionData);

      //ChatStaticData.appendNewChatItems(gameState.chatItemsReturn);

      /*console.log(
        "Faction draft system",
        Map.getSystemFromCoords(gameState.playerData.map, 1, 1)
      );*/
      if (printLoading) {
        Popup.setClientWaitingServer(false);
      }

      console.log("*******************Popup.touch()");
      return gameState;
    } catch (error) {
      if (printLoading) {
        Popup.setClientWaitingServer(false);
      }

      console.error("Error fetching gameState:", error);
    }
  }

  static async triggerNextRound() {
    const reqData = {
      gameId: StaticGameData.gameState.gameId,
    };
    Popup.setClientWaitingServer(true);

    try {
      const response = await fetch("/api/next-round", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reqData: reqData,
        }),
      });
      const result = await response.json();
      StaticGameData.setGameState(result);
      StaticGameData.updateTransactionsFunction(result.transactionData);
      Popup.touch();
      // Update the component state with the fetched gameState
      //setGameState(gameStateData);
      Popup.setClientWaitingServer(false);
    } catch (error) {
      Popup.setClientWaitingServer(false);

      console.error("Error triggering next round:", error);
    }
  }

  static async updateAdmin(reqSubType) {
    console.log("VWA DEBUG ********** updateAdmin");
    Popup.setClientWaitingServer(true);

    try {
      const reqData = {
        gameId: StaticGameData.gameState.gameId,
        reqSubType: reqSubType,
      };
      const response = await fetch("/api/update-admin-state", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reqData: reqData,
        }),
      });

      if (response.ok) {
        const gameStateData = await response.json();
        StaticGameData.setGameState(gameStateData);
        StaticGameData.updateTransactionsFunction(
          gameStateData.transactionData
        );
        Popup.touch();
      } else {
        return response.json();
      }
      Popup.setClientWaitingServer(false);
    } catch (error) {
      Popup.setClientWaitingServer(false);

      console.error("Error :", error);
    }
  }

  static async getChatData() {
    console.log("getChatData");
    try {
      const response = await fetch("/api/get-chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: StaticGameData.gameState.gameId,
        }),
      });

      const chatData = await response.json();
      console.log("debug request chatData : ", chatData);
      return chatData;
    } catch (error) {
      console.error("Error fetching chat data:", error);
    }
  }

  static async updateChatData() {}

  static async getChatRoomData(playerInGameId) {
    try {
      const response = await fetch("/api/get-chat-room", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            gameId: StaticGameData.gameState.gameId,
            playerInGameId: playerInGameId,
          },
        }),
      });

      const room = await response.json();
      return room;
    } catch (error) {
      console.error("Error fetching room data:", error);
    }
  }

  static async updateChatData(
    updateData,
    action,
    returnChatData = true,
    loading = true
  ) {
    console.log("updateGameState", updateData);
    if (loading) Popup.setClientWaitingServer(true);

    try {
      const reqData = {
        gameId: StaticGameData.gameState.gameId,
        updateData: updateData,
        returnChatData: returnChatData,
        action: action,
      };
      const response = await fetch("/api/update-chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reqData: reqData,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.errorMessage) {
          console.log("Error message : ", result.errorMessage);
          UIMessage.setMessageToDisplay(
            StaticGameData.getPlayerData(),
            result.errorMessage
          );
        }

        //Popup.touch();
      } else {
        return response.json();
      }
      if (loading) Popup.setClientWaitingServer(false);
    } catch (error) {
      if (loading) Popup.setClientWaitingServer(false);

      console.error("Error :", error);
    }
  }

  static async getChatItems(roomId, startIndex, endIndex = null) {
    console.log("getChatItems", roomId, startIndex, endIndex);
    try {
      const response = await fetch("/api/get-chat-items", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: StaticGameData.gameState.gameId,
          roomId: roomId,
          startIndex: startIndex,
          endIndex: endIndex,
        }),
      });

      const chatItems = await response.json();

      ChatStaticData.concatChatItems(roomId, chatItems);
      ChatStaticData.updateChatItems(ChatStaticData.roomChatItems);
      Popup.touch();

      //update local game Update. The server has already updated the server gameUpdate with this post request
      if (chatItems.length > 0) {
        const lastChatItemId = chatItems[chatItems.length - 1].itemId;
        GameUpdate.setRoomLastItemLoaded(
          StaticGameData.gameState.playerData.gameUpdate,
          roomId,
          lastChatItemId
        );
      }

      return chatItems;
    } catch (error) {
      console.error("Error fetching chat items:", error);
    }
  }

  static async insertChatItem(roomId, chatItem) {
    try {
      const response = await fetch("/api/insert-chat-item", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: StaticGameData.gameState.gameId,
          roomId: roomId,
          chatItem: chatItem,
        }),
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error inserting chat item:", error);
    }
  }

  static async getCombat(combatId) {
    try {
      const response = await fetch("/api/get-combat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: StaticGameData.gameState.gameId,
          combatId: combatId,
        }),
      });

      const combatData = await response.json();
      return combatData;
    } catch (error) {
      console.error("Error fetching combat data:", error);
    }
  }

  static async getUpdateRequiredInfo() {
    try {
      const response = await fetch("/api/get-updateRequiredInfo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: StaticGameData.gameState.gameId,
        }),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching update required info data:", error);
    }
  }

  static partialGameUpdate(playerData, playerDataSource) {
    //Waiting end of round update
    this.updateOtherPlayersWaitingForNewRound(playerData, playerDataSource);
    const playerDataSourceList =
      GameGlobalFunctions.getAllPlayersData(playerDataSource);
    for (let i = 0; i < playerDataSourceList.length; i++) {
      const playerSource = playerDataSourceList[i];
      const playerDataTarget = GameGlobalFunctions.getPlayerDataFromInGameId(
        playerData,
        playerSource.playerInGameId
      );

      //Update credits and items
      playerDataTarget.credit = playerSource.credit;
      playerDataTarget.items = playerSource.items;

      //replacee messages from server
      playerDataTarget.messageToDisplay = playerSource.messageToDisplay || [];
    }

    //Update messages
  }

  static updateOtherPlayersWaitingForNewRound(playerData, playerDataSource) {
    const allPlayerDataList = GameGlobalFunctions.getAllPlayersData(playerData);
    const allPlayerDataSourceList =
      GameGlobalFunctions.getAllPlayersData(playerDataSource);

    for (let i = 0; i < allPlayerDataList.length; i++) {
      const player = allPlayerDataList[i];
      //find in allPlayerDataSourceList the player matching id
      const playerSource = allPlayerDataSourceList.find(
        (playerSource) => playerSource.playerInGameId === player.playerInGameId
      );
      if (playerSource) {
        player.isWaitingEndOfRound = playerSource.isWaitingEndOfRound;
      }
    }
  }

  static getListGamesFromPlayer = async (gameListType, setGameList) => {
    console.log("Fetching games...");

    try {
      Popup.setClientWaitingServer(true);

      const response = await fetch("/api/list-games", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameListType: gameListType,
        }),
      });

      Popup.setClientWaitingServer(false);
      if (response.ok) {
        const result = await response.json();
        setGameList(result.listOfGames); // Update the state with the received game data
      } else {
        console.error("Failed to fetch games:", response.statusText);
      }
    } catch (error) {
      Popup.setClientWaitingServer(false);
      console.error("Error fetching games:", error);
    }
  };
}

module.exports = Request;
