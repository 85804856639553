import React, { useState, useEffect } from "react";
import ActivationViewLayout from "./ActivationViewLayout";
import ActivationNavigationView from "./ActivationNavigationView";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import PlanetViewActivation from "./Objects/PlanetViewActivation";
import Logo from "../../Utils/Logo";
import ActivationTransferInView from "./ActivationTransferInView";
import ActivationTransferView from "./ActivationTransferView";
import ActivationCA from "../../../ClientAction/ActivationCA";
import TransferData from "../../../Data/GameData/Activation/TransferData";
import ExpandableView from "../../Utils/Layout/ExpandableView";
import SelectionListView from "../../Utils/Layout/SelectionListView";
import InvasionData from "../../../Data/GameData/Activation/InvasionData";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const ProductionData = require("../../../Data/GameData/Activation/ProductionData.js");
const Planet = require("../../../Data/GameData/MapData/Planet.js");
const DropData = require("../../../Data/GameData/Activation/DropData.js");

const ActivationInvasionView = ({ system, navigationData }) => {
  console.log("RENDER ActivationInvasionView ");
  const playerData = StaticGameData.getPlayerData();

  const planetsInActiveSystem = System.getPlanetsNotFromFaction(
    system,
    playerData.faction.name
  );

  navigationData.buttonsData.push(
    {
      text: "Help",
      callback: () => {
        Popup.addLayer({ name: "HelpPopup", helpReference: "invasionStep" });
      },
    },
    {
      text: "Cancel",
      callback: () => {
        ActivationCA.initialize_STEP_ACTIVATION_ACTIVATED();
      },
      type: "action",
    },
    {
      text: "Confirm",
      callback: () => {
        const invade = () => {
          ActivationCA.resolve_STEP_ACTIVATION_INVASION();
        };

        const invadedPlanetName = InvasionData.getInvadedPlanetName(playerData);

        if (invadedPlanetName) {
          UIMessage.displayConfirmMessage(
            "Invade ?",
            "Are you sure you want to invade : " +
              InvasionData.getInvadedPlanetName(playerData) +
              " ?",
            invade
          );
        } else {
          UIMessage.displayConfirmMessage(
            "No Invasion ?",
            "Are you sure you want to invade no planets ?",
            invade
          );
        }
      },
      type: "action",
    }
  );

  const DisplaySpaceArea = ({ system }) => {
    const systemFleet = System.getFleet(system, playerData.faction.name);

    return (
      <div className="text-box">
        <div>Fleet in space : </div>
        <FleetSummaryView fleet={systemFleet}></FleetSummaryView>

        <hr></hr>
      </div>
    );
  };

  const DisplayPlanets = () => {
    return (
      <div>
        {planetsInActiveSystem.map((planet, index) => {
          const planetData = InvasionData.getPlanetData(playerData, planet);

          return (
            <div key={index}>
              <PlanetViewActivation spaceObject={planet} showStock={true}>
                {planetData.invade && (
                  <BaseButton
                    onClick={() => {
                      InvasionData.removeInvadePlanet(playerData, planet);
                    }}
                  >
                    * Will be invaded *
                  </BaseButton>
                )}
                {!planetData.invade && (
                  <BaseButton
                    onClick={() => {
                      InvasionData.invadePlanet(playerData, planet);
                    }}
                  >
                    Click to invade
                  </BaseButton>
                )}
              </PlanetViewActivation>

              <hr></hr>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div style={{}}>
      <div className="text-box">
        You can select one planet to invade. If you win the space combat and
        take control of the space area, all of your ground forces will be
        dropped to the planet you choose to invade.
      </div>
      <DisplaySpaceArea system={system}></DisplaySpaceArea>
      <DisplayPlanets></DisplayPlanets>
    </div>
  );
};

export default ActivationInvasionView;
