import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import Popup from "../../Data/Other/Popup.js";
import NavigationView from "../NavigationView.jsx";
import GameGlobalLayout from "../GameGlobalLayout.jsx";
import StaticGameData from "../../Data/GameData/StaticGameData.js";
import LogBookView from "../Utils/LogMessage/LogBookView.jsx";
import BaseButton from "../Utils/BaseButton.jsx";
import FullScreenLogView from "./FullScreenLogView.jsx";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Request = require("../../Common/Requests/Request.js");
const Map = require("../../Data/GameData/MapData/Map.js");
const LogData = require("../../Data/GameData/Activation/LogData.js");
const LogBook = require("../../Data/GameData/Connection/LogBook.js");

const SystemLogCombatView = ({
  system,
  backCallback = () => Popup.deActivateBlackPopup(),
}) => {
  const playerData = StaticGameData.getPlayerData();

  const navigationData = {
    buttonsData: [{ text: "Back", callback: backCallback }],
  };

  const DisplayCombat = () => {
    const combatList = System.getAllCombatKeys(system);
    if (combatList.length === 0) {
      return (
        <div className="text-box" style={{ textAlign: "center" }}>
          No combat happened in this system.
        </div>
      );
    }

    return (
      <div>
        <div className="text-box" style={{ textAlign: "center" }}>
          {combatList.map((data, index) => {
            return (
              <div key={index}>
                <BaseButton
                  onClick={() => {
                    Popup.addLayer({
                      name: "CombatView",
                      combatKey: data.key,
                    });
                  }}
                >
                  {data.text}
                </BaseButton>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="div-ver-center">
      <DisplayCombat></DisplayCombat>
      <NavigationView navigationData={navigationData}></NavigationView>
    </div>
  );
};

export default SystemLogCombatView;
