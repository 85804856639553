import React, { useState, useEffect } from "react";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import SpaceObjectImageView from "../../Object/SpaceObjectImageView";
import Logo from "../../Utils/Logo";
import ActivationCA from "../../../ClientAction/ActivationCA";
import TransferData from "../../../Data/GameData/Activation/TransferData";
import ProductionActionData from "../../../Data/GameData/ActionData/ProductionActionData";
import PlanetView from "../../Object/PlanetView";
import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData";
import CostView from "../../Utils/CostView.jsx";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const Planet = require("../../../Data/GameData/MapData/Planet.js");

const ActionProductionView = ({}) => {
  console.log("RENDER ActivationProductionView ");
  const playerData = StaticGameData.getPlayerData();
  const productionData = ActionCommonData.getData(playerData);
  const planets = Map.getPlanetsFromFaction(
    playerData.map,
    playerData.faction.name
  ).filter((planet) => !planet.hasProduced);

  const DisplayPlanet = ({ planet }) => {
    const populationEfficiency = Planet.getPopulationEfficiency(
      playerData,
      planet
    );
    const mineralEfficiency = Planet.getMineralEfficiency(playerData, planet);
    const scienceEfficiency = Planet.getScienceEfficiency(playerData, planet);
    const energyEfficiency = Planet.getEnergyEfficiency(playerData, planet);
    const productionMineral = Planet.getMineralProduction(playerData, planet);
    const productionScience = Planet.getScienceProduction(playerData, planet);
    const productionEnergy = Planet.getEnergyProduction(playerData, planet);

    const productionPopulation = Planet.getPopulationProduction(
      playerData,
      planet
    );

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr ",
          //display: "flex",
          //flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "600px",
          margin: "0.5em",
          //flexWrap: "wrap",
        }}
      >
        <div>
          <div>
            <SpaceObjectImageView
              spaceObject={planet}
              maxHeight={"4em"}
              showAsLogo={true}
            ></SpaceObjectImageView>
          </div>
          <div>{planet.name}</div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>
            <BaseButton
              type={
                ProductionActionData.isPlanetExhausted(planet)
                  ? "greyOut"
                  : "white"
              }
              onClick={() => {
                ProductionActionData.modifyResourceGain(planet, "mineral");
              }}
            >
              {productionMineral} <Logo logoName="mineral"></Logo>
            </BaseButton>
          </span>
          <span>
            <BaseButton
              type={
                ProductionActionData.isPlanetExhausted(planet)
                  ? "greyOut"
                  : "white"
              }
              onClick={() => {
                ProductionActionData.modifyResourceGain(planet, "science");
              }}
            >
              {productionScience} <Logo logoName="science"></Logo>
            </BaseButton>
          </span>
          <span>
            <BaseButton
              type={
                ProductionActionData.isPlanetExhausted(planet)
                  ? "greyOut"
                  : "white"
              }
              onClick={() => {
                ProductionActionData.modifyResourceGain(planet, "energy");
              }}
            >
              {productionEnergy} <Logo logoName="energy"></Logo>
            </BaseButton>
          </span>
          <span>
            <span>
              {productionPopulation} <Logo logoName="population"></Logo>
            </span>
          </span>{" "}
        </div>
      </div>
    );
  };

  const DisplayPlanets = ({}) => {
    return (
      <div className="div-fill">
        <div
          //className="div-ver-center"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr ",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "600px",
          }}
        >
          {planets.map((planet, index) => {
            return (
              <div key={index}>
                <DisplayPlanet planet={planet}></DisplayPlanet>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const DisplayGain = ({}) => {
    console.log("RENDER DisplayGain : ", ProductionActionData.getGain());
    return (
      <div style={{ fontSize: "1.5em", margin: "0.5em", minHeight: "2em" }}>
        <CostView
          cost={ProductionActionData.getGain()}
          textIfEmpty="Click to produce"
          compactView={true}
          //showEvenIfZero={true}
        ></CostView>
      </div>
    );
  };

  const DisplayNavigation = ({}) => {
    return (
      <div>
        <BaseButton
          onClick={() => {
            Popup.deActivateBlackPopup();
          }}
        >
          Cancel
        </BaseButton>
        <BaseButton
          onClick={() => {
            Popup.deActivateBlackPopup();
            ProductionActionData.resolveClient();
          }}
        >
          Confirm
        </BaseButton>
      </div>
    );
  };

  return (
    <div>
      {planets.length === 0 && (
        <div style={{ margin: "0.5em" }}>No planet to produce</div>
      )}
      {planets.length > 0 && (
        <div>
          <DisplayPlanets></DisplayPlanets>
          <DisplayGain></DisplayGain>
        </div>
      )}

      <DisplayNavigation></DisplayNavigation>
    </div>
  );
};

export default ActionProductionView;
