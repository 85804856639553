const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Cost = require("../Utils/Cost.js");
const Phase = require("../Game/Phase.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const ActionCommonData = require("./ActionCommonData.js");

class ModifyFleetLimitAction {
  static prepare(playerData) {
    const data = {
      fleetLimitModifier: 0,
    };
    ActionCommonData.prepare(
      playerData,
      Phase.PHASE_ACTION,
      Phase.STEP_MODIFY_FLEET_LIMIT,
      data
    );
  }

  static getCost(playerData) {
    const amount = playerData.actionData.fleetLimitModifier;
    const cost = this.getCostFromAmount(playerData, amount);
    return cost;
  }

  static getCostFromAmount(playerData, amount) {
    //const costEnergy = CustomMath.roundDec(Rules.FLEET_LIMIT_COST * amount);
    const currentFleetLimit = playerData.cargo;
    let costEnergy = 0;
    let cursorFleetLimit = currentFleetLimit;
    for (let i = 0; i < amount; i++) {
      cursorFleetLimit++;
      costEnergy = costEnergy + Rules.FLEET_LIMIT_COST * (cursorFleetLimit - 3);
    }
    costEnergy = CustomMath.roundDec(costEnergy);
    return Cost.createCost({ energy: costEnergy });
  }

  static getModifier(playerData) {
    return playerData.actionData.fleetLimitModifier;
  }

  static modifyModifier(playerData, amount) {
    playerData.actionData.fleetLimitModifier += amount;
    if (playerData.actionData.fleetLimitModifier < 0) {
      playerData.actionData.fleetLimitModifier = 0;
    }
  }

  static setModifier(playerData, amount) {
    playerData.actionData.fleetLimitModifier = amount;
  }

  static resolveServer(playerData, resolveData) {
    ActionCommonData.resolveServerIni(playerData, resolveData);

    const cost = this.getCost(playerData);

    PlayerData.spendCost(playerData, cost);

    playerData.cargo = CustomMath.roundDec(
      playerData.cargo + playerData.actionData.fleetLimitModifier
    );

    ActionCommonData.cleanActionData(playerData);
  }
}

module.exports = ModifyFleetLimitAction;
