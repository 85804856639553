class Rules {
  //Beginning of game
  static COLONIZE_DISTANCE = 2;

  //Resources Types
  static RESOURCE_TYPE_CREDIT = "credit";
  static RESOURCE_TYPE_POPULATION = "population";
  static RESOURCE_TYPE_ENERGY = "energy";
  static RESOURCE_TYPE_MINERAL = "mineral";
  static RESOURCE_TYPE_INFLUENCE = "influence";
  static RESOURCE_TYPE_SIZE = "size";
  static RESOURCE_TYPE_CARGO = "cargo";
  static RESOURCE_TYPE_CREDIT = "credit";
  static RESOURCE_TYPE_SCIENCE = "science";
  static getResourceName = (resourceType) => {
    if (resourceType === Rules.RESOURCE_TYPE_MINERAL) {
      return "Mineral";
    } else if (resourceType === Rules.RESOURCE_TYPE_ENERGY) {
      return "Energy";
    } else if (resourceType === Rules.RESOURCE_TYPE_CREDIT) {
      return "Credit";
    } else if (resourceType === Rules.RESOURCE_TYPE_INFLUENCE) {
      return "Influence";
    } else if (resourceType === Rules.RESOURCE_TYPE_POPULATION) {
      return "Population";
    } else if (resourceType === Rules.RESOURCE_TYPE_SCIENCE) {
      return "Science";
    } else if (resourceType === Rules.RESOURCE_TYPE_SIZE) {
      return "Size";
    } else if (resourceType === Rules.RESOURCE_TYPE_CARGO) {
      return "Cargo";
    }
    throw new Error("Resource type not found to identify resource name");
  };

  //Resoures
  static SUPPLY_BASE_ENERGY_CONSUMPTION = 1;
  static SUPPLY_BASE_FLEET_LIMIT_GAIN = 3;
  static ENERGY_PRODUCTION = 4;
  static FLEET_LIMIT_COST = 1;

  //Combat
  static COMBAT_ROUND_AMOUNT = 3;

  //Costs
  static COST_LIFT = 0;
  static COST_MOVE = 2;
  static COST_ATTACK_SPACE_WHEN_PEACE = 5;
  static COST_ATTACK_GROUND_WHEN_PEACE = 5;

  //Buy
  static BUY_ENERGY_COST = 1;
  static BUY_MINERAL_COST = 1;
  static BUY_SCIENCE_COST = 1;
  static BUY_INFLUENCE_COST = 1;

  //Map
  static AMOUNT_STRUCTURE_PER_PLANET_MAX = 4;

  //Scoring
  static VP_VICTORY = 150;
  static VP_PER_PLANET = 3;
  static VP_PER_TECH = 1;
  static MAXIMUM_VP_MINOR_FACTION = 4;
  static PENALITY_FOR_HS_CONTROL = 2;
  static PENALITY_FOR_HS_PLANET_CONTROL = 3;

  //Hidden VP
  static VP_HIDDEN_PER_PLANET = 1;
  static VP_HIDDEN_PLANET_PROBA = 0.03;
  static VP_HIDDEN_PER_SYSTEM = 1;
  static VP_HIDDEN_SYSTEM_PROBA = 0.02;
  static VP_HIDDEN_PER_REASEARCH = 1;
  static VP_HIDDEN_RESEARCH_PROBA = 0.02;
}

module.exports = Rules;
