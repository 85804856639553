const PlayerData = require("../Data/GameData/PlayerData/PlayerData.js");
const Map = require("../Data/GameData/MapData/Map.js");
const System = require("../Data/GameData/MapData/System.js");
const Faction = require("../Data/GameData/PlayerData/Faction.js");
const Game = require("../Data/GameData/Game/Game.js");
const Color = require("../Common/Config/Colors.js");
const UIMessage = require("../Data/GameData/Connection/UIMessage.js");
const Request = require("../Common/Requests/Request.js");
const Phase = require("../Data/GameData/Game/Phase.js");
const StartOfGameCA = require("./StartOfGameCA.js");
const ActivationCA = require("./ActivationCA.js");
const SelectActionCA = require("./SelectActionCA.js");
const CombatCA = require("./CombatCA.js");
const EndOfRoundReviewCA = require("./EndRoundReviewCA.js");
const MandatoryActionCA = require("./MandatoryActionCA.js");
const Popup = require("../Data/Other/Popup.js");

class CARouter {
  static routeCA(gameState) {
    const phase = gameState.playerData.phase;
    const step = gameState.playerData.step;

    console.log("CARouter.routeCA: phase=" + phase + ", step=" + step);

    //ini interface parametrization in function of the step
    //Popup.resetMapNavigationData();

    if (PlayerData.isThereMandatoryAction(gameState.playerData)) {
      return MandatoryActionCA.initialize(gameState);
    }

    if (phase === Phase.PHASE_START_OF_GAME) {
      if (step === Phase.STEP_START_OF_GAME_PICK_FACTION) {
        return StartOfGameCA.initialize_STEP_START_OF_GAME_PICK_FACTION(
          gameState
        );
      }
      if (step === Phase.STEP_START_OF_GAME_COLONIZE) {
        return StartOfGameCA.initialize_STEP_START_OF_GAME_COLONIZE(gameState);
      }
    }

    if (phase === Phase.PHASE_SELECT_ACTION) {
      if (step === Phase.STEP_SELECT_ACTION) {
        return SelectActionCA.initialize_STEP_SELECT_ACTION(gameState);
      }
    }
    if (phase === Phase.PHASE_ACTIVATION) {
      return ActivationCA.initialize_PHASE_ACTIVATION(gameState);
    }
    if (phase === Phase.PHASE_MANDATORY_ACTION) {
      MandatoryActionCA.initialize(gameState);
    }
    if (phase === Phase.PHASE_EORR) {
      if (step === Phase.STEP_EORR) {
        EndOfRoundReviewCA.initialize_STEP_EORR(gameState);
      }
    }
  }
}

module.exports = CARouter;
