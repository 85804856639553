import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import CostView from "../Utils/CostView";
import Cost from "../../Data/GameData/Utils/Cost";
import ActivePassiveAbility from "../../Data/GameData/Ability/ActivePassiveAbility";
import Item from "../../Data/GameData/Transactions/Item";
import Faction from "../../Data/GameData/PlayerData/Faction";
import NavigationView from "../NavigationView";
import Unit from "../../Data/GameData/MapData/Unit";
import BodyView from "./BodyView";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import LineSpace from "../Utils/Layout/LineSpace";
import Fleet from "../../Data/GameData/MapData/Fleet";
import FleetLineView from "../Object/FleetLineView";
import PlanetView from "../Object/PlanetView";
import FactionAbility from "../../Data/GameData/Faction/FactionAbility";
import AbilityCardDescView from "../Ability/AbilityCardDescView";
import FactionLogo from "../Utils/FactionLogo";
import Color from "../../Common/Config/Colors";
import TechTypeView from "../Technology/TechTypeView";
import BaseButton from "../Utils/BaseButton";
import TechTree from "../../Data/GameData/Technology/TechTree";
import Logo from "../Utils/Logo";
import ItemView from "../Transaction/ItemView";
import ItemDetailedView from "../Transaction/ItemDetailedView";

const FactionPickSheetView = ({ factionName }) => {
  const faction = Faction.createFaction(factionName);
  const playerData = StaticGameData.getPlayerData();

  const navigationData = {
    buttonsData: [{ text: "Back", callback: () => Popup.goBack() }],
  };

  const DisplayTitle = () => {
    return (
      <div style={{ fontSize: "1.5em" }}>
        <span>
          <FactionLogo factionName={faction.name}></FactionLogo> {faction.name}
        </span>
      </div>
    );
  };

  const DisplayFactionBodyImage = () => {
    return <BodyView color={faction.dedicatedColor} size="256px"></BodyView>;
  };

  const DisplayData = () => {
    return (
      <div>
        <div style={{ textDecoration: "underline" }}>Data</div>
        <div>Starting Colonists : {faction.startingColonists}</div>
        <LineSpace></LineSpace>
        <div style={{ textDecoration: "underline" }}>Home Planet</div>
        <div>
          <PlanetView
            planet={Faction.createHomePlanet(faction)}
            //showButtonPlanetName={false}
          ></PlanetView>
        </div>{" "}
        <LineSpace></LineSpace>
      </div>
    );
  };

  const DisplayStartingResources = () => {
    const startingResources = faction.startingResources;

    /* mineral: mineral,
    energy: energy,
    science: science,
    population: population,
    credit: credit,
    colonists: colonists,*/

    return (
      <div>
        <LineSpace></LineSpace>
        <div style={{ textDecoration: "underline" }}>Starting Resources</div>
        <div>
          <Logo logoName="mineral"></Logo> : {startingResources.mineral}
        </div>
        <div>
          <Logo logoName="energy"></Logo> : {startingResources.energy}
        </div>
        <div>
          <Logo logoName="science"></Logo> : {startingResources.science}
        </div>
        <div>
          <Logo logoName="population"></Logo> : {startingResources.population}
        </div>
        <div>
          <Logo logoName="credit"></Logo> : {startingResources.credit}
        </div>
        <div>Colonists : {startingResources.colonists}</div>
      </div>
    );
  };

  const DisplayItems = () => {
    const startingItems = faction.startingItems;
    return (
      <div>
        <LineSpace></LineSpace>
        <div style={{ textDecoration: "underline" }}>Starting Items</div>
        {startingItems.map((item, index) => {
          return (
            <div key={index}>
              <LineSpace></LineSpace>
              <BaseButton
                onClick={() => {
                  Popup.addDynamicLayer(
                    <ItemDetailedView itemForced={item}></ItemDetailedView>
                  );
                }}
              >
                {" "}
                <ItemView item={item}></ItemView>
              </BaseButton>
            </div>
          );
        })}
      </div>
    );
  };

  const DisplayStartingTechnologies = () => {
    const startingTechnologies = faction.startingTechnologies;

    const DisplayTech = ({ techName }) => {
      const tech = TechTree.getTechFromName(playerData.techTree, techName);
      return (
        <div style={{}}>
          <BaseButton
            type={tech.active && !tech.isExhausted ? "white" : "navigation"}
            onClick={() => {
              Popup.addLayer({
                name: "TechView",
                tech: tech,
              });
            }}
          >
            <div style={{ textAlign: "left" }}>
              <div
                style={{
                  //color: textColor,
                  display: "grid",
                  gridTemplateColumns: "auto auto auto auto",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <TechTypeView color={tech.color}></TechTypeView>
                <span style={{ marginRight: "2em" }}> {tech.name}</span>
                <span style={{ color: "white" }}>
                  {tech.requirements.map((req, index) => {
                    return (
                      <TechTypeView key={index} color={req}></TechTypeView>
                    );
                  })}
                </span>
              </div>

              {false && (
                <div style={{ textAlign: "center" }}>
                  {tech.shortDescription}
                </div>
              )}
            </div>
          </BaseButton>
        </div>
      );
    };
    if (!startingTechnologies) return;

    return (
      <div>
        <LineSpace></LineSpace>
        <div style={{ textDecoration: "underline" }}>Starting Technologies</div>
        {startingTechnologies.map((technology, index) => {
          return (
            <div key={index}>
              <LineSpace></LineSpace>
              <DisplayTech techName={technology}></DisplayTech>
            </div>
          );
        })}
      </div>
    );
  };

  const DisplayStartingFleet = () => {
    const fakePlayerData = Unit.createFakePlayerData(faction);
    const startingUnits = faction.startingUnits;

    const startingFleet = Fleet.createFleet(fakePlayerData, factionName);
    for (let i = 0; i < startingUnits.length; i++) {
      const unit = Unit.createUnit(
        fakePlayerData,
        factionName,
        startingUnits[i]
      );
      Fleet.addUnit(startingFleet, unit);
    }

    return (
      <div>
        <div style={{ textDecoration: "underline" }}>Starting Fleet</div>
        <FleetLineView fleet={startingFleet}></FleetLineView>
      </div>
    );
  };

  const DisplayFactionAbilities = () => {
    const abilities = faction.abilities;
    const abilityCardDescs = abilities.map((abilityCard) =>
      FactionAbility.getAbilityCardDesc(abilityCard)
    );

    return (
      <div>
        <div style={{ textDecoration: "underline" }}>Faction Abilities</div>
        {abilityCardDescs.map((abilityCardDesc, index) => (
          <div key={index}>
            <LineSpace></LineSpace>
            <AbilityCardDescView
              abilityCardDesc={abilityCardDesc}
            ></AbilityCardDescView>{" "}
          </div>
        ))}
      </div>
    );
  };

  return (
    <GameGlobalLayout title={null} backgroundType="uniform">
      <div className="text-box" style={{ textAlign: "center" }}>
        <DisplayTitle></DisplayTitle>
        <LineSpace></LineSpace>

        <DisplayData></DisplayData>
        <LineSpace></LineSpace>
        <DisplayStartingFleet></DisplayStartingFleet>
        <LineSpace></LineSpace>
        <DisplayFactionAbilities></DisplayFactionAbilities>
        <DisplayStartingTechnologies></DisplayStartingTechnologies>
        <DisplayStartingResources></DisplayStartingResources>
        <DisplayItems></DisplayItems>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default FactionPickSheetView;
