const StaticGameData = require("../GameData/StaticGameData");
const Request = require("../../Common/Requests/Request");
const Popup = require("../Other/Popup");

class Logic {
  constructor(name, data, gameState) {
    this.userInputs = [];
    this.name = name;
    this.data = data;
    this.commit = false;
    this.gameState = gameState;
  }

  async execute() {}

  async getUserInput(data, newPopup) {
    if (StaticGameData.serverMode) {
      const userInput = this.userInputs[0];
      this.userInputs.shift();

      return userInput;
    } else {
      return new Promise((resolve) => {
        const callback = async (userInputs) => {
          this.userInputs.push(userInputs);
          resolve(userInputs);
        };
        newPopup = {
          ...newPopup,
          data: data,
          callback: callback,
        };
        Popup.overWrite(newPopup);
        //StaticGameData.setPopup(StaticGameData.popup);
      });
    }
  }

  async displayError(errorMessage) {
    if (StaticGameData.serverMode === true) return;
    return new Promise((resolve) => {
      const callback = async () => {
        resolve();
      };
      Popup.overWrite({
        ...StaticGameData.popup,
        errorMessage: errorMessage,
        callback: callback,
      });
    });
  }

  async applyLogic(popup) {
    if (!StaticGameData.serverMode) {
      this.commit = true;
      await Request.applyLogic(this);
      Popup.overWrite(popup);
    } else {
      this.commit = true;
    }
  }

  async commitChanges(logicInstance, userInputs) {
    if (!StaticGameData.serverMode) {
      await Request.applyLogic(logicInstance, userInputs);
    }
  }

  async rollback(popup) {
    if (!StaticGameData.serverMode) {
      this.rollback = true;

      await Request.getGameState();

      Popup.overWrite(popup);
    } else {
      this.rollback = true;
    }
  }
}

module.exports = Logic;
