const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../../GameData/Connection/LogMessage.js");
const SystemAction = require("../ActionCommon/SystemAction.js");
const ValidAction = require("../ActionCommon/WarningAction.js");
const WarningAction = require("../ActionCommon/WarningAction.js");
const Cost = require("../Utils/Cost.js");
const LogBook = require("../Connection/LogBook.js");
const LogAttachment = require("../Connection/LogAttachment.js");
const CheckAction = require("../ActionCommon/CheckAction.js");
const Phase = require("../Game/Phase.js");
const StaticGameData = require("../StaticGameData.js");
const ActionCommonData = require("./ActionCommonData.js");

const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction.js");
const UnitUnitaryAction = require("../UnitaryAction/UnitUnitaryAction.js");
const { check } = require("../MandatoryAction/SolveCapacityData.js");
const PlayerRememberAction = require("../PlayerData/PlayerRememberAction.js");
const Faction = require("../PlayerData/Faction.js");
const Item = require("../Transactions/Item.js");

const InvasionActionData = require("./InvasionActionData.js");
const TechTree = require("../Technology/TechTree.js");
const TechList = require("../Technology/TechList.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const ReplayAction = require("../Replay/ReplayAction.js");
const ReplayActionMove = require("../Replay/Actions/ReplayActionMove.js");

class MoveActionData {
  static resolveClient(playerData, data) {
    const units = data.unitsToMove;
    if (units.length === 0) {
      UIMessage.displayInfoMessage("Move Action", "No units selected.");
      return;
    }

    const targetSystemName = data.systemName;
    const targetPlanetName = data.planetName;
    const fleet = Fleet.createFleet(playerData, playerData.faction.name);
    Fleet.addUnits(fleet, units);
    const logBookConfirm = LogBook.createLogBook();
    if (!targetPlanetName) {
      LogBook.generateAddMessage(
        logBookConfirm,
        "Are you sure to move these units to " +
          targetSystemName +
          "'s space area ?",
        []
      );
    } else {
      LogBook.generateAddMessage(
        logBookConfirm,
        "Are you sure to move these units on " +
          targetPlanetName +
          "'s ground ?",
        []
      );
    }

    const systemSource = this.identifySourceSystem(playerData, units);

    const targetSystem = Map.getSystemFromName(
      playerData.map,
      targetSystemName
    );

    let targetPlanet = null;
    if (targetPlanetName) {
      targetPlanet = Map.getSpaceObjectFromName(
        playerData.map,
        targetPlanetName
      );
    }
    /*if (targetPlanet && targetPlanet.faction !== playerData.faction.name) {
      UIMessage.displayInfoMessage(
        "Not Allowed",
        "You can't move units on a planet you don't control. You have to invade the planet first."
      );
      return;
    }*/

    /*if (targetPlanetName) {
      const targetPlanet = Map.getSpaceObjectFromName(
        playerData.map,
        targetPlanetName
      );
      if (targetPlanet && targetPlanet.faction !== playerData.faction.name) {
        UIMessage.displayInfoMessage(
          "Not Allowed",
          "You can't move units on a planet you don't control. You have to invade the planet first."
        );
        return;
      }
    }*/

    if (!targetSystem.hasBeenActivated && systemSource !== targetSystem) {
      LogBook.generateAddMessage(
        logBookConfirm,
        "A portal will be created in the target system " +
          targetSystemName +
          ". No units will be able to move out of this system until next round.",
        []
      );
      LogBook.generateAddMessage(
        logBookConfirm,
        "The cost to create the portal is " + Rules.COST_MOVE + " $logo$",
        ["energy"]
      );
    }

    LogBook.generateAddMessage(logBookConfirm, "$fleet$", [fleet]);

    const warningForCapacityAndFleet = () => {
      const systemSourceCheck = JSON.parse(JSON.stringify(systemSource));
      const targetSystemCheck = JSON.parse(JSON.stringify(targetSystem));
      let targetPlanetCheck = null;
      if (targetPlanetName) {
        targetPlanetCheck = System.getPlanetFromName(
          targetSystemCheck,
          targetPlanetName
        );
      }
      try {
        this.executeMove(
          playerData,
          units,
          systemSourceCheck,
          targetSystemCheck,
          targetPlanetCheck
        );
        PlayerData.removeAllMandatoryAction(playerData);
      } catch (e) {
        //throw e;
        PlayerData.removeAllMandatoryAction(playerData);
        UIMessage.displayErrorMessage("Not Allowed", e, playerData);
        return;
      }

      WarningAction.warningFleetIsValid(systemSourceCheck, playerData, () => {
        WarningAction.warningFleetIsValid(targetSystemCheck, playerData, () => {
          ActionCommonData.resolveClient(
            playerData,
            data,
            Phase.PHASE_ACTION,
            Phase.STEP_ACTION_MOVE
          );
        });
      });
    };

    //Test if should propose to invade planet
    const warningInvade = () => {
      if (targetPlanet) {
        if (targetPlanet.faction !== playerData.faction.name) {
          UIMessage.displayConfirmMessage(
            "Invade planet " + targetPlanet.name + " ?",
            "You do not control this planet. Do you want to give the order to invade this planet ? This means that at the end of the combats, if you control the space area in this system, all your ground forces will be dropped onto the planet and try to take control of it. This will be considered as an attack against the owner of the planet.",
            () => {
              data.invade = true;
              warningForAttackOnGround();
            }
          );
          return;
        }
      }
      warningForAttackOnGround();
    };

    //Warning for attacks
    const warningForAttackInSpace = () => {
      const attackedFaction = this.getAttackedFactionInSpace(
        playerData.faction.name,
        targetSystem
      );
      if (attackedFaction) {
        const logBookAttack = LogBook.createLogBook();
        LogBook.generateAddMessage(
          logBookAttack,
          "The space area of " +
            targetSystem.name +
            " is controlled by $faction$. This will be considered as an attack in space to $faction$.",
          [targetSystem.faction, targetSystem.faction]
        );

        const attackedFactionPlayerData = PlayerData.getPlayerDataFromFaction(
          playerData,
          attackedFaction
        );
        if (
          Item.includeRelicFromNameFromFaction(
            Item.NAME_PEACE_SPACE,
            playerData.faction.name,
            attackedFactionPlayerData.items
          )
        ) {
          LogBook.generateAddMessage(
            logBookAttack,
            "$faction$ has a " +
              Item.NAME_PEACE_SPACE +
              " relic from your faction. You will loose " +
              Rules.COST_ATTACK_SPACE_WHEN_PEACE +
              " $logo$ during the round resolution phase because of this attack. The relic will then be sent back to you at the start of the next round.",
            [targetSystem.faction, "population"]
          );
          LogBook.generateAddMessage(
            logBookAttack,
            "For each negative $logo$ : you loose 1 victory point at the start of round.",
            ["population"]
          );
        }

        UIMessage.displayConfirmLogBook(logBookAttack, () => {
          warningInvade();
        });
      } else {
        warningInvade();
      }
    };

    const warningForAttackOnGround = () => {
      if (targetPlanet && targetPlanet.faction !== playerData.faction.name) {
        InvasionActionData.checkAttackOnGround(
          playerData,
          targetPlanet,
          warningForCapacityAndFleet
        );
        return;
      }

      warningForCapacityAndFleet();
    };

    UIMessage.displayConfirmLogBook(logBookConfirm, () => {
      warningForAttackInSpace();
    });
  }

  static executeMove(
    playerData,
    units,
    systemSource,
    targetSystem,
    targetPlanet
  ) {
    const checkCapacity = () => {
      //Check capacity
      const fleet = Fleet.createFleet(playerData, playerData.faction.name);
      Fleet.addUnits(fleet, units);

      //identify drop, lift, move
      if (systemSource.name === targetSystem.name) {
        if (!targetPlanet) {
          //Lift
          const spaceFleet = System.getFleetOrCreate(
            playerData,
            targetSystem,
            playerData.faction.name
          );
          Fleet.addFleetToFleet(spaceFleet, fleet);
          try {
            Fleet.checkCapacity(playerData, fleet);
          } catch (e) {
            throw new Error(
              "Issue with capacity of the fleet : " +
                e.message +
                " If you move units from planets to the space area, keep in mind that you need capacity on ships to transport them."
            );
          }
        } else {
          //Drop and eventually lift
          const unitsToLift = [];
          for (let i = 0; i < units.length; i++) {
            const unitPlanet = System.getPlanetFromUnit(targetSystem, units[i]);
            if (unitPlanet && unitPlanet.name !== targetPlanet.name) {
              unitsToLift.push(units[i]);
            }
          }
          const fleetToCheck = Fleet.createFleet(
            playerData,
            playerData.faction.name
          );
          Fleet.addUnits(fleetToCheck, unitsToLift);
          const spaceFleet = System.getFleetOrCreate(
            playerData,
            targetSystem,
            playerData.faction.name
          );
          Fleet.addFleetToFleet(spaceFleet, fleetToCheck);
          try {
            Fleet.checkCapacity(playerData, fleetToCheck);
          } catch (e) {
            throw new Error(
              "Issue with capacity of the fleet: " +
                e.message +
                " If you move units from one planet to another, keep in mind that you need capacity on ships to transport them."
            );
          }
        }
      } else {
        const fleetMoving = Fleet.createFleet(
          playerData,
          playerData.faction.name
        );
        Fleet.addUnits(fleetMoving, units);
        try {
          Fleet.checkCapacity(playerData, fleetMoving);
        } catch (e) {
          throw new Error(
            "Issue with capacity of the moving fleet: " + e.message
          );
        }
      }
    };
    checkCapacity();

    //Check if units can move according to range
    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      SystemAction.canUnitMove(systemSource, targetSystem, unit, playerData);
    }

    MapUnitaryAction.removeUnitsFromSystemSAOrGround(
      playerData,
      systemSource,
      units
    );
    if (UnitUnitaryAction.doesUnitsContainStructure(units)) {
      throw new Error("You can't move structures.");
    }
    if (targetPlanet) {
      const groundForces = units.filter((unit) => {
        return unit.class === Unit.CLASS_GROUND_FORCE;
      });
      const ships = units.filter((unit) => {
        return unit.class === Unit.CLASS_SHIP;
      });
      /*if (UnitUnitaryAction.doesUnitsContainShip(units)) {
        throw new Error("You can't land ships on a planet.");
      }*/
      //Detect invasion
      if (targetPlanet.faction === playerData.faction.name) {
        MapUnitaryAction.placeUnitsOnPlanet(
          playerData,
          targetPlanet,
          groundForces
        );
        MapUnitaryAction.placeUnitsInSystemSA(playerData, targetSystem, ships);
      } else {
        //Invasion order will be triggered at the end of the move resolve
        MapUnitaryAction.placeUnitsInSystemSA(playerData, targetSystem, units);
      }
    } else {
      MapUnitaryAction.placeUnitsInSystemSA(playerData, targetSystem, units);
    }
  }

  static identifySourceSystem(playerData, units) {
    let systemSource = null;

    for (let i = 0; i < units.length; i++) {
      const unitSystem = Map.getSystemFromUnitId(playerData.map, units[i].id);
      if (systemSource === null) {
        systemSource = unitSystem;
      } else {
        if (systemSource !== unitSystem) {
          throw new Error("You can't move units from different systems.");
        }
      }
    }
    return systemSource;
  }

  static resolveServer(playerData, resolveData) {
    const data = resolveData;
    const units = data.unitsToMove;
    if (units.length === 0) {
      throw new Error("No units selected.");
    }

    const targetSystemName = data.systemName;
    const targetPlanetName = data.planetName;
    //console.log("DEBUG targetSystemName : " + targetSystemName);
    //console.log("DEBUG targetPlanetName : " + targetPlanetName);

    const systemSource = this.identifySourceSystem(playerData, units);
    const targetSystem = Map.getSystemFromName(
      playerData.map,
      targetSystemName
    );
    let planet = null;
    if (targetPlanetName) {
      planet = System.getPlanetFromName(targetSystem, targetPlanetName);
    }

    //console.log("DEBUG systemSource.name : " + systemSource.name);
    //console.log("DEBUG targetSystem.name : " + targetSystem.name);
    //If System Source is locked
    if (
      systemSource.hasBeenActivated &&
      systemSource.name !== targetSystem.name
    ) {
      throw new Error(
        "A portal is present in the source system " +
          systemSource.name +
          ". No units can move out of a system with a portal."
      );
    }

    //Cost
    let costMove = Cost.createCost({ energy: Rules.COST_MOVE });
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.portalRetention.name)
    ) {
      costMove.energy = Math.max(0, CustomMath.roundDec(costMove.energy - 0.5));
    }
    if (systemSource !== targetSystem && !targetSystem.hasBeenActivated) {
      PlayerData.spendCost(playerData, costMove);
    }

    //Remember Attacks
    const attackedFactionInSpace = this.getAttackedFactionInSpace(
      playerData.faction.name,
      targetSystem
    );
    let attackedFactionOnGround = null;
    if (planet) {
      attackedFactionOnGround = this.getAttackedFactionOnGround(
        playerData.faction.name,
        planet
      );
    }
    if (attackedFactionInSpace) {
      PlayerRememberAction.addAttackedFactionInSpace(
        playerData.rememberAction,
        attackedFactionInSpace,
        targetSystem
      );
    }
    if (attackedFactionOnGround) {
      PlayerRememberAction.addAttackedFactionOnGround(
        playerData.rememberAction,
        attackedFactionOnGround,
        planet
      );
    }

    this.executeMove(playerData, units, systemSource, targetSystem, planet);

    //Lock System
    if (systemSource !== targetSystem) {
      targetSystem.hasBeenActivated = true;
    }

    //Invasion order
    if (data.invade && planet) {
      InvasionActionData.prepare(playerData, planet);
      const resolveData = { actionData: playerData.actionData };
      InvasionActionData.resolveServer(playerData, resolveData);
    }

    //Record replay action
    const replayAction = ReplayActionMove.create({
      systemOut: systemSource,
      systemIn: targetSystem,
      units: units,
      cost: costMove,
    });
    ReplayAction.addReplayActionPlaying(playerData, replayAction);
  }

  static getAttackedFactionInSpace(movingFaction, targetSystem) {
    if (
      targetSystem.faction &&
      !Faction.isMinorFaction(targetSystem.faction) &&
      targetSystem.faction !== movingFaction
    ) {
      return targetSystem.faction;
    }
    return null;
  }

  static getAttackedFactionOnGround(movingFaction, targetPlanet) {
    if (
      targetPlanet.faction &&
      !Faction.isMinorFaction(targetPlanet.faction) &&
      targetPlanet.faction !== movingFaction
    ) {
      return targetPlanet.faction;
    }
    return null;
  }
}

module.exports = MoveActionData;
