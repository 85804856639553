const CostResource = require("../../Resource/CostResource");
const Unit = require("../Unit");
const Resource = require("../../Resource/ResourceInstance/Resource");

class Dreadnought extends Unit {
  constructor(id, controllingPlayerPos, subType) {
    if (subType === "Agile") {
      super(
        id,
        "ship",
        "Dreadnought",
        subType,
        new CostResource([
          new Resource("production", 4),
          new Resource("support", 1),
        ]),
        "D",
        controllingPlayerPos,
        ["Advanced Warp", "Canon", "Canon", "Canon", "Canon"]
      );
      return;
    }

    throw new Error("No Dreadnought found of that type.");
  }
}

module.exports = Dreadnought;
