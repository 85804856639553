import React from "react";
import UnitLineView from "./UnitLineView";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import SelectionListView from "../Utils/Layout/SelectionListView";
import UnitImageView from "../Object/UnitImageView";

const Fleet = require("../../Data/GameData/MapData/Fleet");

const UnitSelectionView = ({
  units,
  selectedUnits = [],
  faction,
  onUpdate = () => {},
}) => {
  return (
    <div style={{}}>
      <SelectionListView
        items={units}
        lineLayout={true}
        displayItem={(item) => {
          return (
            <div style={{ fontSize: "1em" }}>
              {" "}
              <UnitLineView unit={item} factionName={faction}></UnitLineView>
            </div>
          );
        }}
        selectedItems={selectedUnits}
        selectItem={(item) => {
          selectedUnits.push(item);
          onUpdate();
          Popup.touch();
        }}
        unSelectItem={(item) => {
          selectedUnits.splice(selectedUnits.indexOf(item), 1);
          onUpdate();
          Popup.touch();
        }}
        textIfEmpty="No units"
      ></SelectionListView>
    </div>
  );
};

export default UnitSelectionView;
