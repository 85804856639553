const PlayerData = require("../Data/GameData/PlayerData/PlayerData.js");
const Map = require("../Data/GameData/MapData/Map.js");
const System = require("../Data/GameData/MapData/System.js");
const Faction = require("../Data/GameData/PlayerData/Faction.js");
const Game = require("../Data/GameData/Game/Game.js");
const Color = require("../Common/Config/Colors.js");
const UIMessage = require("../Data/GameData/Connection/UIMessage.js");
const Request = require("../Common/Requests/Request.js");
const Phase = require("../Data/GameData/Game/Phase.js");
const Popup = require("../Data/Other/Popup.js");
const ActivationData = require("../Data/GameData/Activation/ActivationData.js");
const StaticGameData = require("../Data/GameData/StaticGameData.js");
const InvasionData = require("../Data/GameData/Activation/InvasionData.js");
const ProductionData = require("../Data/GameData/Activation/ProductionData.js");
const TransferData = require("../Data/GameData/Activation/TransferData.js");
const ConstructionData = require("../Data/GameData/Activation/ConstructionData.js");
const StructureData = require("../Data/GameData/Activation/StructureData.js");
const LiftData = require("../Data/GameData/Activation/LiftData.js");
const DropData = require("../Data/GameData/Activation/DropData.js");
const MoveActionData = require("../Data/GameData/ActionData/MoveActionData.js");
const LogAttachment = require("../Data/GameData/Connection/LogAttachment.js");
const LogBook = require("../Data/GameData/Connection/LogBook.js");
const LogMessage = require("../Data/GameData/Connection/LogMessage.js");
const RulesText = require("../Data/GameData/Game/RulesText.js");

class ActivationCA {
  static initialize_PHASE_ACTIVATION(gameState) {
    Popup.resetMode();

    Popup.addLayer({ name: "ManageActivationView" });

    if (gameState.playerData.step === Phase.STEP_ACTIVATION_ACTIVATED) {
      this.initialize_STEP_ACTIVATION_ACTIVATED(gameState);
    }
    if (gameState.playerData.step === Phase.STEP_ACTIVATION_MOVE) {
      this.initialize_STEP_ACTIVATION_MOVE(gameState);
    }
    /*if (gameState.playerData.step === Phase.STEP_ACTIVATION_ASSIGNEMENT) {
      this.initialize_STEP_ACTIVATION_ASSIGNEMENT(gameState);
    }*/
    /*if (gameState.playerData.step === Phase.STEP_ACTIVATION_TRANSFER) {
      this.initialize_STEP_ACTIVATION_TRANSFER(gameState);
    }*/
    /*if (gameState.playerData.step === Phase.STEP_ACTIVATION_PLANET_MANAGEMENT) {
      this.initialize_STEP_ACTIVATION_PLANET_MANAGEMENT(gameState);
    }*/
    if (gameState.playerData.step === Phase.STEP_ACTIVATION_LIFT) {
      this.initialize_STEP_ACTIVATION_LIFT();
    }
    if (gameState.playerData.step === Phase.STEP_ACTIVATION_DROP) {
      this.initialize_STEP_ACTIVATION_DROP();
    }
    if (gameState.playerData.step === Phase.STEP_ACTIVATION_INVASION) {
      this.initialize_STEP_ACTIVATION_INVASION();
    }
    if (gameState.playerData.step === Phase.STEP_ACTIVATION_PRODUCTION) {
      this.initialize_STEP_ACTIVATION_PRODUCTION();
    }
    if (gameState.playerData.step === Phase.STEP_ACTIVATION_BUILD) {
      this.initialize_STEP_ACTIVATION_BUILD();
    }
    if (gameState.playerData.step === Phase.STEP_ACTIVATION_STRUCTURE) {
      this.initialize_STEP_ACTIVATION_STRUCTURE();
    }
    if (gameState.playerData.step === Phase.STEP_ACTIVATION_END_ACTIVATION) {
      this.initialize_STEP_ACTIVATION_END_ACTIVATION();
    }
  }

  static initialize_STEP_ACTIVATION_ACTIVATED() {
    const playerData = StaticGameData.getPlayerData();
    playerData.phase = Phase.PHASE_ACTIVATION;
    playerData.step = Phase.STEP_ACTIVATION_ACTIVATED;
    Popup.touch();
  }

  static async resolve_STEP_ACTIVATION_ACTIVATED() {}

  static initialize_STEP_ACTIVATION_MOVE(system) {
    const playerData = StaticGameData.getPlayerData();

    if (PlayerData.isThereMandatoryAction(playerData)) {
      Popup.addLayer({ name: "MandatoryActionGlobalView" });
      return;
    }

    MoveActionData.prepare(playerData, system);
    playerData.phase = Phase.PHASE_ACTION;
    playerData.step = Phase.STEP_ACTION_MOVE;

    const moveData = PlayerData.getActionData(playerData);

    const confirmMove = () => {
      const confirmLogBook = LogBook.generateLogBook(
        "Are you sure you want to move these units to " +
          moveData.targetSystemName +
          " ? The cost of this move action is : " +
          moveData.moveCost.energy +
          " $resource$",
        ["energy"]
      );
      LogBook.generateAddMessage(
        confirmLogBook,
        RulesText.WARNING_SYSTEM_LOCKED(system)
      );
      UIMessage.displayConfirmLogBook(
        confirmLogBook,
        ActivationCA.resolve_STEP_ACTIVATION_MOVE
      );
    };
    const displayText = () => {
      return (
        "Choose units you want to move to " +
        moveData.targetSystemName +
        ". Current Move cost : " +
        moveData.moveCost.energy +
        " energy."
      );
    };

    const generateLogBook = () => {
      const logBook = LogBook.generateLogBook(
        "Choose units you want to move to " +
          moveData.targetSystemName +
          ". Current Move cost : " +
          moveData.moveCost.energy +
          " $resource$.",
        ["energy"]
      );
      return logBook;
    };

    Popup.setMapNavigationData({
      buttonsData: [
        {
          text: "?",
          callback: () => {
            Popup.addLayer({
              name: "HelpPopup",
              helpReference: "moveAction",
            });
          },
        },
        {
          text: "Cancel Move",
          callback: () => {
            console.log("Cancel Move");
            playerData.phase = Phase.PHASE_SELECT_ACTION;
            playerData.step = Phase.STEP_SELECT_ACTION;
            MoveActionData.cancelMoveAction(playerData);
            Popup.resetMapNavigationData();
            Popup.touch();
          },
        },
        {
          text: "Confirm Move",
          callback: confirmMove,
        },
      ],
      taskBarData: {
        //displayText: displayText,
        generateLogBook: generateLogBook,
      },
    });
    Popup.mapClickCallback = MoveActionData.handleMapClick;
    Popup.unStackToMap();
  }

  static async resolve_STEP_ACTIVATION_MOVE() {
    const playerData = StaticGameData.getPlayerData();
    const resolveData = {
      actionData: playerData.actionData,
    };

    Request.updateGameState(resolveData);
  }

  static initialize_STEP_ACTIVATION_LIFT(planet) {
    const playerData = StaticGameData.getPlayerData();
    LiftData.prepareStep(playerData, planet);
    playerData.phase = Phase.PHASE_ACTIVATION;
    playerData.step = Phase.STEP_ACTIVATION_LIFT;
    Popup.touch();
  }

  static resolve_STEP_ACTIVATION_LIFT(action) {
    const playerData = StaticGameData.getPlayerData();
    const resolveData = {
      action: action,
      liftStep: playerData.activationData.liftStep,
    };
    Request.updateGameState(resolveData);
  }

  static initialize_STEP_ACTIVATION_DROP(planet) {
    const playerData = StaticGameData.getPlayerData();
    DropData.prepareStep(playerData, planet);
    playerData.phase = Phase.PHASE_ACTIVATION;
    playerData.step = Phase.STEP_ACTIVATION_DROP;
    Popup.touch();
  }

  static resolve_STEP_ACTIVATION_DROP(action) {
    const playerData = StaticGameData.getPlayerData();
    const resolveData = {
      action: action,
      dropStep: playerData.activationData.dropStep,
    };
    Request.updateGameState(resolveData);
  }

  static initialize_STEP_ACTIVATION_INVASION() {
    const playerData = StaticGameData.getPlayerData();
    InvasionData.prepareStep(playerData);
    playerData.phase = Phase.PHASE_ACTIVATION;
    playerData.step = Phase.STEP_ACTIVATION_INVASION;
    Popup.touch();
  }

  static resolve_STEP_ACTIVATION_INVASION() {
    const playerData = StaticGameData.getPlayerData();
    const resolveData = {
      invasionStep: playerData.activationData.invasionStep,
    };
    Request.updateGameState(resolveData);
  }

  static initialize_STEP_ACTIVATION_BUILD(planet) {
    const playerData = StaticGameData.getPlayerData();
    ConstructionData.prepareStep(playerData, planet);
    playerData.phase = Phase.PHASE_ACTIVATION;
    playerData.step = Phase.STEP_ACTIVATION_BUILD;
    Popup.touch();
  }

  static async resolve_STEP_ACTIVATION_BUILD(action = "build") {
    if (action === "transfer") {
      Request.updateGameState({
        action: "transfer",
        transferData:
          StaticGameData.getPlayerData().activationData.transferData,
      });
    } else {
      Popup.reset();

      Request.updateGameState(
        StaticGameData.getPlayerData().activationData.constructionStep
      );
    }
  }

  static initialize_STEP_ACTIVATION_TRANSFER(planet) {
    const playerData = StaticGameData.getPlayerData();
    TransferData.prepareTransferData(playerData, planet);
    playerData.phase = Phase.PHASE_ACTIVATION;
    playerData.step = Phase.STEP_ACTIVATION_TRANSFER;
    Popup.touch();
  }

  static async resolve_STEP_ACTIVATION_TRANSFER() {
    const playerData = StaticGameData.getPlayerData();
    const resolveData = {
      transferData: playerData.activationData.transferData,
    };
    Request.updateGameState(resolveData);
  }

  static initialize_STEP_ACTIVATION_PRODUCTION(planet) {
    const playerData = StaticGameData.getPlayerData();
    ProductionData.prepareStep(playerData, planet);
    playerData.phase = Phase.PHASE_ACTIVATION;
    playerData.step = Phase.STEP_ACTIVATION_PRODUCTION;
    Popup.touch();
  }

  static async resolve_STEP_ACTIVATION_PRODUCTION(action) {
    if (action === "transfer") {
      Request.updateGameState({
        action: "transfer",
        transferData:
          StaticGameData.getPlayerData().activationData.transferData,
      });
    } else if (action === "production") {
      Request.updateGameState({
        action: action,
        productionStep:
          StaticGameData.getPlayerData().activationData.productionStep,
      });
    } else if (action === "no production") {
      Request.updateGameState({
        action: action,
      });
    }
  }

  static initialize_STEP_ACTIVATION_STRUCTURE(planet) {
    const playerData = StaticGameData.getPlayerData();
    StructureData.prepareStep(playerData, planet);
    playerData.phase = Phase.PHASE_ACTIVATION;
    playerData.step = Phase.STEP_ACTIVATION_STRUCTURE;
    Popup.touch();
  }

  static async resolve_STEP_ACTIVATION_STRUCTURE(action = "build") {
    console.log("resolve_STEP_ACTIVATION_STRUCTURE action : ", action);
    if (action === "transfer") {
      console.log("resolve_STEP_ACTIVATION_STRUCTURE transfer");
      Request.updateGameState({
        action: "transfer",
        transferData:
          StaticGameData.getPlayerData().activationData.transferData,
      });
    } else {
      Request.updateGameState(
        StaticGameData.getPlayerData().activationData.structureStep
      );
    }
  }

  static initialize_STEP_ACTIVATION_END_ACTIVATION() {}

  static async resolve_STEP_ACTIVATION_END_ACTIVATION() {
    const playerData = StaticGameData.getPlayerData();
    playerData.phase = Phase.PHASE_ACTIVATION;
    playerData.step = Phase.STEP_ACTIVATION_END_ACTIVATION;
    Request.updateGameState({});
  }
}

module.exports = ActivationCA;
