import React, { useState } from "react";
import FactionLogo from "../Utils/FactionLogo.jsx";

const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const ImagesUrl = require("../../Data/Other/ImagesUrl.js");

const IMAGE_FACTION_LOGO_BASE = "/Images/Faction/";

const FactionView = ({ faction }) => {
  return (
    <div>
      <div style={{ fontSize: "1.5em" }}>
        {" "}
        <FactionLogo factionName={faction.name}></FactionLogo>{" "}
        <span> {faction.name}</span>
      </div>
      <div>Starting colonists : {faction.startingColonists}</div>
      <div>
        Energy bonus each round : {Faction.getEnergyProduction(faction)}
      </div>
      <div>Starting fleet limit : {faction.startingCargo}</div>
    </div>
  );
};

export default FactionView;
