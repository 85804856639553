const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const PlanetData = require("../MapData/Planet.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Phase = require("../Game/Phase.js");

class SolveCapacityData {
  static check(playerData, system) {
    /*PlayerData.removeMandatoryActionByName(
      playerData,
      Phase.STEP_MANDATORY_FLEET_CAPACITY
    );*/
    try {
      System.checkCapacity(playerData, system);
    } catch (e) {
      SolveCapacityData.prepareStep(playerData, system);
    }
  }

  static prepareStep(playerData, system) {
    const data = {
      systemName: system.name,
      fleetToRemove: Fleet.createFleet(playerData, playerData.faction.name),
    };
    PlayerData.addMandatoryAction(
      playerData,
      Phase.STEP_MANDATORY_FLEET_CAPACITY,
      data
    );
  }

  static checkIfStillActive(playerData) {
    const data = PlayerData.getFirstMandatoryAction(playerData).data;
    const system = Map.getSystemFromName(playerData.map, data.systemName);
    try {
      System.checkCapacity(playerData, system);
    } catch (error) {
      return true;
    }
    return false;
  }

  static resolve(playerData) {
    const data = PlayerData.getFirstMandatoryAction(playerData).data;

    const system = Map.getSystemFromName(playerData.map, data.systemName);

    const fleet = System.getFleet(system, playerData.faction.name);

    const units = Fleet.getUnits(data.fleetToRemove, Fleet.UNIT_CLASS_ALL);
    if (units.filter((unit) => !unit.transportRequired).length > 0) {
      throw new Error("You can't remove units that don't require transport");
    }

    Fleet.removeFleetFromFleet(data.fleetToRemove, fleet);

    try {
      System.checkCapacity(playerData, system);
    } catch (error) {
      this.prepareStep(playerData, system);
    }
  }
}

module.exports = SolveCapacityData;
