const Faction = require("../GameData/PlayerData/Faction");

class ChatTag {
  static tagMappings = {
    ...Object.fromEntries(
      Faction.factions.map((faction) => [
        `:${faction.name}:`, // Use faction's tag as the key
        `<img 
            src="${Faction.getLogoImageFromFaction(faction.name)}" 
            alt="${faction.name}" 
            style="
              width: 1em; 
              height: 1em; 
              object-fit: cover; 
              display: inline-block; 
              vertical-align: middle;"
          />`, // Generate HTML dynamically with inline styles
      ])
    ),
  };

  static getAllTagsFromString(text) {
    console.log("debug text in:", text);

    // Dynamically build a regex pattern from tagMappings keys
    const validTags = Object.keys(this.tagMappings)
      .map((key) => key.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")) // Escape special regex characters
      .join("|"); // Join keys with `|` to create an OR pattern

    const regex = new RegExp(`(${validTags})`, "g"); // Create the regex for exact matches
    console.log("debug regex:", regex);

    // Match all valid tags in the string
    const matches = text.match(regex) || [];

    console.log("debug tags found:", matches);
    return matches;
  }
}

module.exports = ChatTag;
