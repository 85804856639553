import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo";
import ScoreData from "../../Data/GameData/Objectifs/ScoreData";
import FactionSpan from "../Utils/FactionSpan";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import TransactionView from "./TransactionView";
import ProposeTransactionView from "./ProposeTransactionView";
import ActionPropose from "../ManageSystem/ActionView/ActionPropose";
import Item from "../../Data/GameData/Transactions/Item";
import ItemView from "./ItemView";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");

const ItemListView = ({ itemList }) => {
  const DisplayItem = ({ item }) => {
    const itemDesc = Item.getDescription(item);

    return (
      <span>
        <ItemView item={item}></ItemView>
      </span>
    );
  };

  return (
    <span>
      <span>
        {itemList.map((item, key) => (
          <span key={key}>
            <DisplayItem item={item}></DisplayItem>
          </span>
        ))}
      </span>
    </span>
  );
};

export default ItemListView;
