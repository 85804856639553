import { useState, useEffect } from "react";

const useViewportSize = () => {
  const [viewportSize, setViewportSize] = useState({
    width: document.documentElement.clientWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setViewportSize({
      width: document.documentElement.clientWidth,
      height: window.innerHeight,
    });
    const width = document.documentElement.clientWidth;
    const height = window.innerHeight;
    console.log("Viewport ratio: ", width / height);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs only once, like componentDidMount

  return viewportSize;
};

export default useViewportSize;
