import ResourceView from "./Label/ResourceView";

import React, { useState } from "react";
import "../CSS/Font.css";
import "../CSS/Menu.css";

const StaticGameData = require("../Data/GameData/StaticGameData");
const Popup = require("../Data/Other/Popup");
const PickFactionLogic = require("../Data/GameLogic/Other/PickFactionLogic");

//OBSOLETE
const DraftView = ({ gameState, fetchGameState }) => {
  // Your statistics content and styling here

  return;

  const { mapPosition } = StaticGameData.popup;
  console.log("mapPosition");
  console.log(mapPosition);
  const playerState = gameState.playerStates[mapPosition - 1];
  const gameId = gameState.gameId;
  let isLoggedPlayerState = false;
  if (gameState.mapPosition === mapPosition) isLoggedPlayerState = true;

  const [selectedFactionIndex, setSelectedFactionIndex] = useState(0);
  const factionDraft = playerState.factionDraft;
  const handleLeftArrowClick = () => {
    setSelectedFactionIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : factionDraft.length - 1
    );
  };

  const handleRightArrowClick = () => {
    setSelectedFactionIndex((prevIndex) =>
      prevIndex < factionDraft.length - 1 ? prevIndex + 1 : 0
    );
  };

  const containerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw", // 100% of viewport width
    height: "100vh", // 100% of viewport height
    backgroundColor: "Red",
  };

  const handleBackButtonClick = () => {
    Popup.goBack();
  };

  const renderDraftedFaction = () => {
    const handlePickFaction = async () => {
      const pickedFaction = factionDraft[selectedFactionIndex].factionName;

      const logicData = {
        playerPos: gameState.mapPosition,
        factionPicked: pickedFaction,
      };
      const pickFactionLogic = new PickFactionLogic(logicData, gameState);
      pickFactionLogic.execute();

      Popup.goBack();
      fetchGameState();
    };

    return (
      <div>
        <div className="container-text">
          <h1>{factionDraft[selectedFactionIndex].factionName}</h1>
          <div>
            <p>
              Home system planet name :{" "}
              {factionDraft[selectedFactionIndex].homeSystemTile.planetName}
            </p>
            <p>
              <ResourceView
                resource={
                  factionDraft[selectedFactionIndex].homeSystemTile.production
                }
              ></ResourceView>
            </p>
            <p>
              <ResourceView
                resource={
                  factionDraft[selectedFactionIndex].homeSystemTile.influence
                }
              ></ResourceView>
            </p>
          </div>
        </div>
        {isLoggedPlayerState && (
          <button onClick={handlePickFaction}>Pick Faction</button>
        )}
      </div>
    );
  };

  return (
    <div className="container-menu">
      {/* Back Button */}
      <button onClick={handleBackButtonClick}>Back</button>

      {/* Left Arrow */}
      <button onClick={handleLeftArrowClick}>&larr;</button>
      {/* Right Arrow */}
      <button onClick={handleRightArrowClick}>&rarr;</button>

      {/* Display the selected faction */}
      {renderDraftedFaction()}
    </div>
  );
};

export default DraftView;
