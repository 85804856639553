import React, { useState, useEffect } from "react";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import SelectionListView from "../../Utils/Layout/SelectionListView";
import CostView from "../../Utils/CostView";
import PlanetView from "../../Object/PlanetView";
import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData";
import FleetLineView from "../../Object/FleetLineView";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const ProductionData = require("../../../Data/GameData/Activation/ProductionData.js");
const Planet = require("../../../Data/GameData/MapData/Planet.js");
const LiftActionData = require("../../../Data/GameData/ActionData/LiftActionData.js");

const ActionLiftView = ({ navigationData }) => {
  console.log("RENDER ActionLiftView ");
  const playerData = StaticGameData.getPlayerData();

  const data = ActionCommonData.getData(playerData);

  const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);
  const system = Map.getSystemFromSpaceObject(planet, playerData.map);

  navigationData.buttonsData.push(
    {
      text: "Cancel Lift",
      callback: () => {
        ActionCommonData.cleanActionData(playerData);
        Popup.goBack();
      },
    },
    {
      text: "Lift",
      callback: () => {
        ActionCommonData.resolveClient(playerData);
        Popup.goBack();
      },
    }
  );

  const DisplaySpaceArea = ({ system }) => {
    const spaceAreaFleet = Fleet.createFleet(
      playerData,
      playerData.faction.name
    );
    const systemFleet = System.getFleet(system, playerData.faction.name);
    Fleet.addFleetToFleet(systemFleet, spaceAreaFleet);

    Fleet.addFleetToFleet(data.fleetLifted, spaceAreaFleet);

    const capacity = Fleet.getTotalCapacity(playerData, spaceAreaFleet);
    const requiredCapacity = Fleet.getTotalRequiredCapacity(
      playerData,
      spaceAreaFleet
    );

    //Display cost
    const cost = LiftActionData.getCostLift(playerData);
    navigationData.child = (
      <div>
        <span>Lift cost : </span>
        <CostView cost={cost}></CostView>
      </div>
    );

    return (
      <div className="text-box" style={{ textAlign: "center" }}>
        <div>Fleet in space : </div>
        <div style={{}}>
          {" "}
          <FleetLineView fleet={spaceAreaFleet}></FleetLineView>
        </div>

        <div>
          Capacity : {requiredCapacity} / {capacity}
        </div>

        <hr></hr>
      </div>
    );
  };

  const DisplayPlanets = () => {
    const planetFleet = Planet.getFleet(planet, playerData.faction.name);

    const groundForces = Fleet.getUnits(
      planetFleet,
      Fleet.UNIT_CLASS_GROUND_FORCE
    );
    const liftedGroundForces = Fleet.getUnits(
      data.fleetLifted,
      Fleet.UNIT_CLASS_GROUND_FORCE
    );
    const displayItem = (item) => {
      return (
        <UnitLineView
          unit={item}
          factionName={playerData.faction.name}
        ></UnitLineView>
      );
    };
    const selectedItems = liftedGroundForces;
    const selectItem = (item) => {
      Fleet.addUnit(data.fleetLifted, item);

      Popup.touch();
    };

    const unSelectItem = (item) => {
      Fleet.removeUnit(data.fleetLifted, item);
      Popup.touch();
    };

    return (
      <div>
        <PlanetView
          planet={planet}
          showStock={true}
          showFleet={false}
        ></PlanetView>

        {!Fleet.isEmpty(planetFleet) ? (
          <SelectionListView
            items={groundForces}
            displayItem={displayItem}
            selectedItems={selectedItems}
            selectItem={selectItem}
            unSelectItem={unSelectItem}
            textSelected="Stay"
            textUnselected="Lift"
          ></SelectionListView>
        ) : (
          <span>You have no units on this planet</span>
        )}
        <hr></hr>
      </div>
    );
  };

  return (
    <div style={{}}>
      <DisplaySpaceArea system={system}></DisplaySpaceArea>
      <DisplayPlanets></DisplayPlanets>
    </div>
  );
};

export default ActionLiftView;
