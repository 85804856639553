const Fleet = require("../MapData/Fleet");
const CustomMath = require("../../../Common/Math/CustomMath");
const ResolveData = require("../EndOfRound/ResolveData");
const GameGlobalFunctions = require("../../Other/GameGlobalFunctions");

class CombatFactionData {
  //Not done yet
  static createList(map, system, gameStateList, planet = null) {
    const factionList = gameStateList.map(
      (gameState) => gameState.playerData.faction.name
    );

    const combatFactionDataList = [];
    for (let i = 0; i < factionList.length; i++) {
      const faction = factionList[i];
      combatFactionDataList.push({
        faction: faction,
        hitToAssign: 0,
        hits: 0,
      });
    }
  }

  static executeOnSpaceCombatParticipatingUnits(system, faction, func) {
    const units = CombatFactionData.getSpaceCombatParticipatingUnits(
      system,
      faction
    );
    for (let i = 0; i < units.length; i++) {
      func(units[i]);
    }
  }

  static getSpaceCombatParticipatingUnits(system, faction) {
    const fleets = system.fleets;
    const unitsParticipating = [];
    for (let i = 0; i < fleets.length; i++) {
      if (fleets[i].faction === faction) {
        unitsParticipating.push(
          ...Fleet.getUnits(fleets[i], Fleet.UNIT_CLASS_SHIP)
        );
      }
    }
    return unitsParticipating;
  }

  static getGroundCombatParticipatingUnits(system, planet, faction) {
    const fleets = planet.fleets;
    const unitsParticipating = [];
    for (let i = 0; i < fleets.length; i++) {
      if (fleets[i].faction === faction) {
        unitsParticipating.push(
          ...Fleet.getUnits(fleets[i], Fleet.UNIT_CLASS_GROUND_FORCE)
        );
        unitsParticipating.push(
          ...Fleet.getUnits(fleets[i], Fleet.UNIT_CLASS_STRUCTURE)
        );
      }
    }
    return unitsParticipating;
  }
}

module.exports = CombatFactionData;
