//const SpaceObject = require("../MapData/SpaceObject");
//const System = require("../MapData/System");
//const Color = require("../../../Common/Config/Colors");
const StaticGameData = require("../StaticGameData");
//const PlayerData = require("../PlayerData/PlayerData");
const Popup = require("../../Other/Popup");

class UIMessage {
  static TYPE_ERROR = "error";
  static TYPE_INFO = "info";
  static TYPE_CONFIRM = "confirm";
  static TYPE_TRANSACTION = "transaction";
  static TYPE_TUTORIAL = "tutorial";

  static MESSAGE_FORMAT_TEXT = "text";
  static MESSAGE_FORMAT_LOGBOOK = "logbook";
  static MESSAGE_FORMAT_COMPONENT = "component";

  static initializePlayerData(playerData) {
    playerData.currentMessageProduced = 0;
    playerData.currentMessageRed = 0;
  }

  static getIncrementMessageProduced(playerData) {
    return playerData.currentMessageProduced++;
  }

  static getCurrentMessageRed(playerData) {
    return playerData.currentMessageRed;
  }

  static setCurrentMessageRed(playerData, value) {
    playerData.currentMessageRed = value;
  }

  static markMessageAsRead(playerData, message) {
    if (message.id) {
      this.setCurrentMessageRed(playerData, message.id);
      //Request
    }
  }

  static createMessage(name, type = this.TYPE_INFO) {
    //Check if type is valid
    if (
      type !== this.TYPE_ERROR &&
      type !== this.TYPE_INFO &&
      type !== this.TYPE_CONFIRM &&
      type !== this.TYPE_TRANSACTION
    ) {
      throw new Error("Invalid message type");
    }

    return {
      name: name,
      type: type,
      format: this.MESSAGE_FORMAT_TEXT,
      confirmCallback: null,
      lines: [],
    };
  }

  static addLine(message, line) {
    message.lines.push(line);
  }
  static getLines(message) {
    return message.lines;
  }
  static displayErrorMessage(
    title,
    e,
    playerData = StaticGameData.getPlayerData()
  ) {
    const message = UIMessage.createMessage(title, UIMessage.TYPE_ERROR);
    this.addLine(message, e.message);
    UIMessage.addMessageToDisplay(playerData, message);

    Popup.touch();
  }

  static displayInfoMessage(
    title,
    messageP,
    playerData = StaticGameData.getPlayerData()
  ) {
    const message = UIMessage.createMessage(title, UIMessage.TYPE_INFO);
    this.addLine(message, messageP);
    UIMessage.addMessageToDisplay(playerData, message);

    Popup.touch();
  }

  static displayInfoMessageToStaticGameData(title, messageP) {
    const message = UIMessage.createMessage(title, UIMessage.TYPE_INFO);
    this.addLine(message, messageP);
    UIMessage.addMessageToDisplayToStaticGameData(message);
    Popup.touch();
  }

  static displayTutorialMessage(logBookP, playerData) {
    const message = {
      logBook: logBookP,
      type: this.TYPE_TUTORIAL,
    };
    UIMessage.addMessageToDisplay(playerData, message);
  }

  static sendInfoMessageToClient(playerData, title, messageP) {
    const message = UIMessage.createMessage(title, UIMessage.TYPE_INFO);
    this.addLine(message, messageP);
    UIMessage.addMessageToDisplay(playerData, message);
  }

  static sendInfoLogBookToClient(playerData, title, logBook) {
    const message = {
      title: title,
      format: this.MESSAGE_FORMAT_LOGBOOK,
      logBook: logBook,
      type: this.TYPE_INFO,
    };
    UIMessage.addMessageToDisplay(playerData, message);
  }

  static sendTransactionMessageToClient(
    playerData,
    transactionId,
    action,
    context = {}
  ) {
    const message = UIMessage.createMessage(
      "Transaction " + action,
      UIMessage.TYPE_TRANSACTION
    );
    message.transactionId = transactionId;
    message.action = action;
    message.rejectingFaction = context.rejectingFaction;
    UIMessage.addMessageToDisplay(playerData, message);
  }

  static displayConfirmMessage(
    title,
    messageP,
    confirmCallback,
    noPlayerData = false,
    context
  ) {
    const message = UIMessage.createMessage(title, UIMessage.TYPE_CONFIRM);
    message.confirmCallback = confirmCallback;
    this.addLine(message, messageP);
    const playerData = StaticGameData.getPlayerData();
    if (noPlayerData) {
      UIMessage.addMessageToDisplayToStaticGameData(message);
    } else {
      UIMessage.addMessageToDisplay(playerData, message, context);
    }

    Popup.touch();
  }

  static displayConfirmLogBook(logBookP, confirmCallback) {
    const message = {
      format: this.MESSAGE_FORMAT_LOGBOOK,
      logBook: logBookP,
      confirmCallback: confirmCallback,
      type: this.TYPE_CONFIRM,
    };

    console.log("UIMessage message", message);
    const playerData = StaticGameData.getPlayerData();
    UIMessage.addMessageToDisplay(playerData, message);
    Popup.touch();
  }

  static displayInfoLogBook(logBookP, context) {
    const message = {
      format: this.MESSAGE_FORMAT_LOGBOOK,
      logBook: logBookP,
      type: this.TYPE_INFO,
    };
    const playerData = StaticGameData.getPlayerData();
    UIMessage.addMessageToDisplay(playerData, message, context);
    Popup.touch();
  }

  static sendErrorMessageToClient(gameState, title, e) {
    const message = UIMessage.createMessage(title, UIMessage.TYPE_ERROR);
    this.addLine(message, e.message);
    UIMessage.addMessageToDisplay(gameState.playerData, message);
  }

  static sendErrorLogBookToClient(playerData, title, logBook) {
    const message = {
      title: title,
      format: this.MESSAGE_FORMAT_LOGBOOK,
      logBook: logBook,
      type: this.TYPE_INFO,
    };
    UIMessage.addMessageToDisplay(playerData, message);
  }

  //Managing messages in player Data
  static setMessageToDisplay(playerData, message) {
    playerData.messageToDisplay = [];
    playerData.messageToDisplay.push(message);
  }

  static resetMessageToDisplay(playerData) {
    playerData.messageToDisplay = [];
  }

  static setMessageToDisplayArray(playerData, messageArray) {
    playerData.messageToDisplay = messageArray;
  }

  static isThereAMessageToDisplay(playerData) {
    if (
      playerData.messageToDisplay === null ||
      playerData.messageToDisplay.length === 0
    ) {
      return false;
    }
    return true;
  }

  static isThereMessageInStaticGameData() {
    if (
      StaticGameData.messageToDisplay === null ||
      StaticGameData.messageToDisplay.length === 0
    ) {
      return false;
    }
    return true;
  }

  static getFirstMessageToDisplay(playerData) {
    return playerData.messageToDisplay[0];
  }

  static getFirstMessageToDisplayinStaticGameData() {
    return StaticGameData.messageToDisplay[0];
  }

  static addMessageToDisplay(playerData, message, context = {}) {
    const displayOnTop = context.displayOnTop;
    const createId = StaticGameData.serverMode;

    if (!playerData.messageToDisplay) {
      playerData.messageToDisplay = [];
    }
    if (displayOnTop) {
      playerData.messageToDisplay.unshift(message);
    } else {
      playerData.messageToDisplay.push(message);
    }

    if (createId) {
      message.id = this.getIncrementMessageProduced(playerData);
    }
  }

  static addMessageToDisplayToStaticGameData(message) {
    if (!StaticGameData.messageToDisplay) {
      StaticGameData.messageToDisplay = [];
    }
    StaticGameData.messageToDisplay.push(message);
  }

  static setSingleMessageToDisplay(playerData, message) {
    this.resetMessageToDisplay(playerData);
    this.addMessageToDisplay(playerData, message);
  }

  static removeMessageToDisplay(playerData) {
    //Remove first message of the array
    playerData.messageToDisplay.shift();
  }

  static removeMessageToDisplayFromStaticGameData() {
    //Remove first message of the array
    StaticGameData.messageToDisplay.shift();
  }

  static removeMessageToDisplayFromId(playerData, id) {
    const index = playerData.messageToDisplay.findIndex(
      (m) => m.id && m.id === id
    );
    if (index !== -1) {
      playerData.messageToDisplay.splice(index, 1);
    }
  }
}

module.exports = UIMessage;
