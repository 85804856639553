import React, { useState, useEffect } from "react";

const Color = require("../../Common/Config/Colors.js");

export function getObjectSummaryJSXArray(spaceObject) {
  const jsxArray = [];
  //console.log("spaceObject", spaceObject);
  if (spaceObject.isPlanet) {
    jsxArray.push(
      <span style={{ color: Color.COLOR_MINERAL }}>
        {spaceObject.mineralEfficiency}
      </span>
    );
    jsxArray.push(
      <span style={{ color: Color.COLOR_SCIENCE }}>
        {spaceObject.scienceEfficiency}
      </span>
    );
    jsxArray.push(
      <span style={{ color: Color.COLOR_INFLUENCE }}>
        {spaceObject.influence}
      </span>
    );
    jsxArray.push(
      <span style={{ color: Color.COLOR_POP_GROW }}>
        {spaceObject.populationGrow}%
      </span>
    );
    jsxArray.push(
      <span style={{ color: Color.COLOR_SIZE }}>{spaceObject.size}</span>
    );
  }
  return jsxArray;
}
