const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const ActivationData = require("../Activation/ActivationData.js");
const PlanetData = require("../MapData/Planet.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Cost = require("./Cost.js");

class UnitShopList {
  static create(unitList) {
    const shopList = [];

    for (let i = 0; i < unitList.length; i++) {
      const unit = unitList[i];
      shopList.push({
        unit: unit,
        orderAmount: 0,
      });
    }

    return shopList;
  }

  static getCost(playerData, shopList) {
    const cost = Cost.createCost({});

    for (let i = 0; i < shopList.length; i++) {
      const unit = shopList[i].unit;
      const orderAmount = shopList[i].orderAmount;

      if (unit.costMineral) {
        cost.mineral += unit.costMineral * orderAmount;
      }
      if (unit.costPopulation) {
        cost.population += unit.costPopulation * orderAmount;
      }
      if (unit.costEnergy) {
        cost.energy += unit.costEnergy * orderAmount;
      }
      if (unit.costInfluence) {
        cost.influence += unit.costInfluence * orderAmount;
      }
    }

    return cost;
  }

  static addOrderUnit(shopList, unit, amount) {
    CustomMath.checkInteger(amount);
    for (let i = 0; i < shopList.length; i++) {
      if (Unit.areUnitsTheSame(shopList[i].unit, unit)) {
        if (shopList[i].orderAmount + amount < 0) {
          return;
        }
        shopList[i].orderAmount += amount;
        return;
      }
    }
  }

  static getAmountUnitsShopped(shopList) {
    let amount = 0;
    for (let i = 0; i < shopList.length; i++) {
      amount += shopList[i].orderAmount;
    }
    return amount;
  }

  static generateGhostFleet(playerData, shopList) {
    const ghostFleet = Fleet.createFleet(playerData, playerData.faction.name);
    for (let i = 0; i < shopList.length; i++) {
      const unit = shopList[i].unit;
      const amount = shopList[i].orderAmount;
      for (let j = 0; j < amount; j++) {
        const newUnit = Unit.createUnit(
          playerData,
          playerData.faction.name,
          unit.type,
          unit.subType,
          false
        );
        Fleet.addUnit(ghostFleet, newUnit);
      }
    }

    return ghostFleet;
  }

  static generateFleet(playerData, shopList) {
    const fleet = Fleet.createFleet(playerData, playerData.faction.name);
    for (let i = 0; i < shopList.length; i++) {
      const unit = shopList[i].unit;
      const amount = shopList[i].orderAmount;
      for (let j = 0; j < amount; j++) {
        const newUnit = Unit.createUnit(
          playerData,
          playerData.faction.name,
          unit.type,
          unit.subType,
          true
        );
        Fleet.addUnit(fleet, newUnit);
      }
    }

    return fleet;
  }
}

module.exports = UnitShopList;
