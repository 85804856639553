const Unit = require("../MapData/Unit");

const TechTree = require("../Technology/TechTree");
const TechList = require("../Technology/TechList");
const Map = require("../MapData/Map");
const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction");
const Tech = require("../Technology/Tech");
const Fleet = require("../MapData/Fleet");
const System = require("../MapData/System");
const ResolveData = require("../EndOfRound/ResolveData");
const CombatData = require("../Combat/CombatData");
const LogBook = require("../Connection/LogBook");
const SolveFleetLimitData = require("../MandatoryAction/SolveFleetLimitData");
const SolveCapacityData = require("../MandatoryAction/SolveCapacityData");
const CombatRecording = require("../Combat/CombatRecording");
const PlayerData = require("../PlayerData/PlayerData");
const Cost = require("../Utils/Cost");
const SecondaryObject = require("../MapData/SecondaryObject");
const ScoreData = require("../Objectifs/ScoreData");
const MinorFaction = require("../MinorFaction/MinorFaction");
const Request = require("../../../Common/Requests/Request");
const AbilityCommon = require("./AbilityCommon");
const Phase = require("../Game/Phase");
const StaticGameData = require("../StaticGameData");
const Faction = require("../PlayerData/Faction");
const ResearchActionData = require("../ActionData/ResearchActionData");
const UnitUnitaryAction = require("../UnitaryAction/UnitUnitaryAction");
const ServiceUnitaryAction = require("../UnitaryAction/ServiceUnitaryAction");
const Planet = require("../MapData/Planet");
const UIMessage = require("../Connection/UIMessage");
const Item = require("../Transactions/Item");
const PlanetBonus = require("../Bonus/PlanetBonus");
const HeroNames = require("../MinorFaction/HeroNames");
const FactionAbility = require("../Faction/FactionAbility");
const CustomMath = require("../../../Common/Math/CustomMath");

class ResolveMinorFactionAbility {
  //static PHASE_TECH_ABILITY = "resolveTechAbility";

  static sendToServerCommon(spaceObjectName, actionData) {
    const playerData = StaticGameData.getPlayerData();
    const data = {
      spaceObjectName: spaceObjectName,
      data: actionData,
    };

    playerData.phase = AbilityCommon.PHASE_RESOLVE_ABILITY;
    playerData.step = Phase.STEP_MINOR_FACTION_ABILITY;

    Request.updateGameState(data);
  }

  static resolveCommon = (playerData, updateData) => {
    const spaceObjectName = updateData.spaceObjectName;
    const data = updateData.data;

    if (playerData.hasResolvedMinorFactionAbility === true) {
      throw new Error(
        "You have already resolved a hero ability this round. You can resolve only one hero ability per round."
      );
    }

    playerData.hasResolvedMinorFactionAbility = true;

    const system = Map.getSystemFromSpaceObjectName(
      playerData.map,
      spaceObjectName
    );
    const spaceObject = System.getSpaceObjectFromName(system, spaceObjectName);
    const minorFaction = SecondaryObject.getMinorFaction(spaceObject);

    if (system.faction !== playerData.faction.name) {
      throw new Error(
        "You do not have control of the space area of the system " +
          system.name +
          ". To use the ability of a hero, you must have control of the space area of the system it is in."
      );
    }

    //this.gainVP(playerData, system, minorFaction);
    //Pay the cost in population
    const cost = Cost.createCost({
      population: minorFaction.populationCost,
    });

    //CONCORDANT_ORDER_RESPECTED
    if (
      FactionAbility.hasFactionAbility(
        playerData,
        FactionAbility.CONCORDANT_ORDER_RESPECTED
      )
    ) {
      cost.population = Math.max(2, CustomMath.roundDec(cost.population - 2));
    }

    PlayerData.spendCost(playerData, cost);

    this.routeAbility(playerData, system, spaceObjectName, minorFaction, data);

    MinorFaction.resetPopulationCost(minorFaction);
  };

  static gainVP = (playerData, system, minorFaction) => {
    const scoreData = PlayerData.getScoreData(playerData);
    ScoreData.addScore(
      scoreData,
      minorFaction.vp,
      "Action of " + minorFaction.name + " in the system " + system.name + "."
    );
    MinorFaction.resetVP(minorFaction);
  };

  static routeAbility = (
    playerData,
    system,
    spaceObjectName,
    minorFaction,
    data
  ) => {
    /*this.erinys(playerData, system, minorFaction, data);
    return;*/

    switch (minorFaction.name) {
      case HeroNames.HERO_AETHERION:
        this.aetherion(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_ANDAR:
        this.andar(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_BOREALIS:
        this.borealis(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_CALVARIA:
        this.calvaria(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_DELPHAS:
        this.delphas(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_DRAKOR:
        this.drakor(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_ERINYS:
        this.erinys(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_EROSIUM:
        this.erosium(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_FELTOR:
        this.feltor(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_GHELOR:
        this.ghelor(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_HELKARAN:
        this.helkaran(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_ICANTA:
        this.icanta(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_JELORIA:
        this.jeloria(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_KORATH:
        this.korath(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_KOBAMDA:
        this.kobamda(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_LARAN:
        this.laran(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_MALTRION:
        this.maltrion(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_MINARI:
        this.minari(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_OPHARIAN:
        this.opharian(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_PROTHIAN:
        this.prothian(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_QUORIDIOM:
        this.quoridiom(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_RYNZORATH:
        this.rynzorath(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_SELTAAR:
        this.seltaar(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_TERRAN:
        this.terran(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_TAARKIR:
        this.taarkir(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_UTARION:
        this.utarion(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_VOIDBORN:
        this.voidborn(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_WOLTHAAR:
        this.wolthaar(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_XELPHAR:
        this.xelphar(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_XENOS:
        this.xenos(playerData, system, minorFaction, data);
        break;
      case HeroNames.HERO_ZYLORIAN:
        this.zylorian(playerData, system, minorFaction, data);
        break;

      default:
        throw new Error("Unknown heron ability");
    }
  };

  static hasResolvedMinorFactionAbility = (playerData) => {
    return playerData.hasResolvedMinorFactionAbility;
  };

  static kohor = (playerData, system, minorFaction, data) => {
    PlayerData.gainCredit(playerData, 6);
  };

  static aetherion = (playerData, system, minorFaction, data) => {
    const techName = data.techName;
    const tech = TechTree.getTechFromName(playerData.techTree, techName);

    ResearchActionData.researchTech(playerData, tech, 1);

    this.logAndMessage(
      playerData,
      minorFaction,
      "Researched " + tech.name + " ignoring 1 prerequisite."
    );
  };

  static andar = (playerData, system, minorFaction, data) => {
    const planetName = data.planetName;
    const planet = Map.getSpaceObjectFromName(playerData.map, planetName);

    ServiceUnitaryAction.createUnitOnPlanetControlled(
      playerData,
      planet,
      Unit.UNIT_TYPE_FACTORY
    );

    this.logAndMessage(
      playerData,
      minorFaction,
      "Added a factory on the planet " + planet.name + "."
    );
  };

  static borealis = (playerData, system, minorFaction, data) => {
    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);
    if (!Planet.didBuildUnits(planet)) {
      throw new Error(
        "Planet " + planet.name + " did not build any units this round."
      );
    }

    Planet.setHasBuiltUnits(planet, false);
    UIMessage.sendInfoMessageToClient(
      playerData,
      minorFaction.name,
      "The planet " + planet.name + " can build units again this round."
    );

    this.logAndMessage(
      playerData,
      minorFaction,
      "The planet " + planet.name + " can build units again this round."
    );
  };

  static calvaria = (playerData, system, minorFaction, data) => {
    const items = playerData.items;
    const scroll = Item.create(playerData, Item.NAME_CALVARIA_SCROLL);
    //Item.exhaustItem(scroll);
    items.push(scroll);

    this.logAndMessage(playerData, minorFaction, "Gained a Calvaria scroll.");
  };

  static delphas = (playerData, system, minorFaction, data) => {
    const items = playerData.items;
    const scroll = Item.create(playerData, Item.NAME_DELPHAS_SCROLL);
    items.push(scroll);

    this.logAndMessage(playerData, minorFaction, "Gained a Delphas scroll.");
  };

  static drakor = (playerData, system, minorFaction, data) => {
    const systemName = data.systemName;
    const targetSystem = Map.getSystemFromName(playerData.map, systemName);

    const distance = Map.getDistanceBetweenSystems(
      playerData,
      system,
      targetSystem,
      playerData.map
    );
    if (distance === 0 || distance > 1) {
      throw new Error(
        "The system " +
          targetSystem.name +
          " is not adjacent to the system " +
          system.name +
          "."
      );
    }

    const fleet = System.getFleetOrCreate(
      playerData,
      targetSystem,
      HeroNames.HERO_DRAKOR
    );

    Fleet.addUnit(
      fleet,
      Unit.createUnit(
        playerData,
        HeroNames.HERO_DRAKOR,
        Unit.UNIT_TYPE_DESTROYER
      )
    );
    Fleet.addUnit(
      fleet,
      Unit.createUnit(
        playerData,
        HeroNames.HERO_DRAKOR,
        Unit.UNIT_TYPE_DESTROYER
      )
    );
    Fleet.addUnit(
      fleet,
      Unit.createUnit(
        playerData,
        HeroNames.HERO_DRAKOR,
        Unit.UNIT_TYPE_DESTROYER
      )
    );
    Fleet.addUnit(
      fleet,
      Unit.createUnit(
        playerData,
        HeroNames.HERO_DRAKOR,
        Unit.UNIT_TYPE_DESTROYER
      )
    );

    System.addFleetToSystem(targetSystem, fleet);

    this.logAndMessage(
      playerData,
      minorFaction,
      "Added 4 destroyers in the system " + targetSystem.name + "."
    );
  };

  static erinys = (playerData, system, minorFaction, data) => {
    const planetNames = data.planetNames;

    //Check planetNames differents
    const uniquePlanetNames = [...new Set(planetNames)];
    if (uniquePlanetNames.length !== planetNames.length) {
      throw new Error("You cannot select the same planet multiple times.");
    }

    const planets = planetNames.map((name) =>
      Map.getSpaceObjectFromName(playerData.map, name)
    );

    for (const planet of planets) {
      if (planet.faction !== playerData.faction.name) {
        throw new Error("You do not control the planet " + planet.name + ".");
      }

      if (!planet.hasProduced) {
        throw new Error(
          "The planet " +
            planet.name +
            " did not produce any resources this round. You have to select planets which have already produced resources this round."
        );
      }

      planet.hasProduced = false;
    }

    this.logAndMessage(
      playerData,
      minorFaction,
      "The planets " +
        planetNames.join(", ") +
        " can produce resources again this round."
    );
  };

  static erosium = (playerData, system, minorFaction, data) => {};

  static feltor = (playerData, system, minorFaction, data) => {
    const targetSystem = Map.getSystemFromName(playerData.map, data.systemName);

    if (!targetSystem.hasBeenActivated) {
      throw new Error(
        "There is no portal to remove in the system " + targetSystem.name + "."
      );
    }

    targetSystem.hasBeenActivated = false;

    this.logAndMessage(
      playerData,
      minorFaction,
      "Removed the portal in the system " + targetSystem.name + "."
    );
  };

  static ghelor = (playerData, system, minorFaction, data) => {
    const planetName = data.planetName;
    const planet = Map.getSpaceObjectFromName(playerData.map, planetName);

    ServiceUnitaryAction.createUnitOnPlanetControlled(
      playerData,
      planet,
      Unit.UNIT_TYPE_ASSEMBLY_ORBITAL
    );

    this.logAndMessage(
      playerData,
      minorFaction,
      "Added an assembly orbital on the planet " + planet.name + "."
    );
  };

  static helkaran = (playerData, system, minorFaction, data) => {
    PlayerData.gainEnergy(playerData, 6);
    this.logAndMessage(playerData, minorFaction, "Gained 6 energy.");
  };

  static icanta = (playerData, system, minorFaction, data) => {
    const items = playerData.items;
    const scroll = Item.create(playerData, Item.NAME_ICANTA_SCROLL);
    items.push(scroll);

    this.logAndMessage(playerData, minorFaction, "Gained an Icanta scroll.");
  };

  static jeloria = (playerData, system, minorFaction, data) => {
    const items = playerData.items;
    const scroll = Item.create(playerData, Item.NAME_JELORIA_SCROLL);
    items.push(scroll);

    this.logAndMessage(playerData, minorFaction, "Gained a Jeloria scroll.");
  };

  static korath = (playerData, system, minorFaction, data) => {
    const planetNames = data.planetNames;
    const planets = planetNames.map((name) =>
      Map.getSpaceObjectFromName(playerData.map, name)
    );

    for (const planet of planets) {
      ServiceUnitaryAction.createUnitOnPlanetControlled(
        playerData,
        planet,
        Unit.UNIT_TYPE_MECH
      );
    }

    this.logAndMessage(
      playerData,
      minorFaction,
      "Added a mech to the planets " + planetNames.join(", ") + "."
    );
  };

  static kobamda = (playerData, system, minorFaction, data) => {
    const items = playerData.items;
    const scroll = Item.create(playerData, Item.NAME_KOBAMDA_SCROLL);
    items.push(scroll);

    this.logAndMessage(playerData, minorFaction, "Gained a Kobamda scroll.");
  };

  static laran = (playerData, system, minorFaction, data) => {
    const systemName = data.systemName;
    const targetSystem = Map.getSystemFromName(playerData.map, systemName);

    const units = System.getAllUnitsFromFactionIncludingPlanets(
      playerData,
      targetSystem,
      playerData.faction.name
    );

    for (const unit of units) {
      UnitUnitaryAction.repairUnit(playerData, unit);
    }

    this.logAndMessage(
      playerData,
      minorFaction,
      "All your units in the system " +
        targetSystem.name +
        " are fully repaired."
    );
  };

  static maltrion = (playerData, system, minorFaction, data) => {
    const items = playerData.items;
    const scroll = Item.create(playerData, Item.NAME_MALTRION_SCROLL);
    //Item.exhaustItem(scroll);
    items.push(scroll);

    this.logAndMessage(playerData, minorFaction, "Gained a Maltrion scroll.");
  };

  static minari = (playerData, system, minorFaction, data) => {
    PlayerData.gainMineral(playerData, 6);
    this.logAndMessage(playerData, minorFaction, "Gained 6 minerals.");
  };

  static opharian = (playerData, system, minorFaction, data) => {
    PlayerData.gainScience(playerData, 8);
    this.logAndMessage(playerData, minorFaction, "Gained 8 sciences.");
  };

  static prothian = (playerData, system, minorFaction, data) => {
    const systemName = data.systemName;
    const targetSystem = Map.getSystemFromName(playerData.map, systemName);

    const fleet = System.getFleetOrCreate(
      playerData,
      targetSystem,
      playerData.faction.name
    );

    const units = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_SHIP);
    if (units.length === 0) {
      throw new Error(
        "You don't have any ships in the system " + targetSystem.name + "."
      );
    }
    const unit = Unit.createUnit(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_DREADNOUGH
    );
    MapUnitaryAction.placeUnitsInSystemSA(playerData, targetSystem, [unit]);

    this.logAndMessage(
      playerData,
      minorFaction,
      "Placed a dreadnought in the system " + targetSystem.name + "."
    );
  };

  static quoridiom = (playerData, system, minorFaction, data) => {
    const items = playerData.items;
    const scroll = Item.create(playerData, Item.NAME_QUORIDIOM_SCROLL);
    //Item.exhaustItem(scroll);
    items.push(scroll);

    this.logAndMessage(playerData, minorFaction, "Gained a Quoridiom scroll.");
  };

  static rynzorath = (playerData, system, minorFaction, data) => {
    const items = playerData.items;
    const scroll = Item.create(playerData, Item.NAME_RYNZORATH_SCROLL);
    //Item.exhaustItem(scroll);
    items.push(scroll);

    this.logAndMessage(playerData, minorFaction, "Gained a Rynzorath scroll.");
  };

  static seltaar = (playerData, system, minorFaction, data) => {
    const items = playerData.items;
    const scroll = Item.create(playerData, Item.NAME_SELTAAR_SCROLL);
    items.push(scroll);

    this.logAndMessage(playerData, minorFaction, "Gained a Seltaar scroll.");
  };

  static terran = (playerData, system, minorFaction, data) => {
    const targetSpaceObjectName = data.targetSpaceObjectName;
    const targetSpaceObject = Map.getSpaceObjectFromName(
      playerData.map,
      targetSpaceObjectName
    );

    if (!targetSpaceObject.type) {
      throw new Error("You have to select an Exiled Faction.");
    }

    if (
      targetSpaceObject.type &&
      targetSpaceObject.type !== SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION
    ) {
      throw new Error("You have to select an Exiled Faction.");
    }

    const targetSystem = Map.getSystemFromSpaceObjectName(
      playerData.map,
      targetSpaceObjectName
    );
    if (
      !targetSystem.faction ||
      targetSystem.faction !== playerData.faction.name
    ) {
      throw new Error("You have to select a system you control.");
    }

    //Resolving targetExiledFaction ability
    const targetMinorFaction =
      SecondaryObject.getMinorFaction(targetSpaceObject);
    const minorFactionDesc = MinorFaction.getFactionDescDataFromName(
      targetMinorFaction.name
    );
    targetMinorFaction.populationCost = minorFactionDesc.populationCostMin;

    playerData.hasResolvedMinorFactionAbility = false;

    this.logAndMessage(
      playerData,
      minorFaction,
      "Cost of " +
        targetMinorFaction.name +
        " ability has been set to its minimum."
    );
  };

  static taarkir = (playerData, system, minorFaction, data) => {
    const items = playerData.items;
    const scroll = Item.create(playerData, Item.NAME_TAARKIR_SCROLL);
    //Item.exhaustItem(scroll);
    items.push(scroll);

    this.logAndMessage(playerData, minorFaction, "Gained a Taarkir scroll.");
  };

  static utarion = (playerData, system, minorFaction, data) => {
    const unitId = data.unitId;
    const targetSystem = Map.getSystemFromUnitId(playerData.map, unitId);
    const fleet = System.getFleetFromUnitId(targetSystem, unitId);

    if (fleet.faction !== playerData.faction.name) {
      throw new Error("You do not control the unit " + unit.type + ".");
    }

    const unit = Fleet.getUnitFromId(fleet, unitId);
    if (unit.type !== Unit.UNIT_TYPE_CRUSER) {
      throw new Error("The unit must be a cruiser to be transformed.");
    }

    MapUnitaryAction.removeUnitsFromSystemSA(playerData, targetSystem, [unit]);
    MapUnitaryAction.placeUnitsInSystemSA(playerData, targetSystem, [
      Unit.createUnit(
        playerData,
        playerData.faction.name,
        Unit.UNIT_TYPE_DREADNOUGH
      ),
      Unit.createUnit(
        playerData,
        playerData.faction.name,
        Unit.UNIT_TYPE_FIGHTER
      ),
      Unit.createUnit(
        playerData,
        playerData.faction.name,
        Unit.UNIT_TYPE_FIGHTER
      ),
    ]);

    this.logAndMessage(
      playerData,
      minorFaction,
      "Transformed a cruser into a dreadnought and 2 fighters in system " +
        targetSystem.name +
        "."
    );
  };

  static voidborn = (playerData, system, minorFaction, data) => {
    const items = playerData.items;
    const scroll = Item.create(playerData, Item.NAME_VOIDBORN_SCROLL);
    items.push(scroll);

    this.logAndMessage(playerData, minorFaction, "Gained a Voidborn scroll.");
  };

  static wolthaar = (playerData, system, minorFaction, data) => {
    PlayerData.gainCredit(playerData, 5);
    this.logAndMessage(playerData, minorFaction, "Gained 5 credits.");
  };

  static xelphar = (playerData, system, minorFaction, data) => {
    PlayerData.gainPopulation(playerData, 8);
    this.logAndMessage(playerData, minorFaction, "Gained 8 populations.");
  };

  static xenos = (playerData, system, minorFaction, data) => {
    const planetNames = data.planetNames;
    const planets = planetNames.map((name) =>
      Map.getSpaceObjectFromName(playerData.map, name)
    );

    if (planets.length > 3) {
      throw new Error("You cannot select more than 3 planets.");
    }

    for (const planet of planets) {
      if (planet.faction !== playerData.faction.name) {
        throw new Error("You do not control the planet " + planet.name + ".");
      }

      MapUnitaryAction.placeUnitsOnPlanet(playerData, planet, [
        Unit.createUnit(
          playerData,
          playerData.faction.name,
          Unit.UNIT_TYPE_PLANETARY_CANON
        ),
      ]);
    }

    this.logAndMessage(
      playerData,
      minorFaction,
      "Added a planetary canon to the planets " + planetNames.join(", ") + "."
    );
  };

  static zylorian = (playerData, system, minorFaction, data) => {
    const planetNames = data.planetNames;
    const planets = planetNames.map((name) =>
      Map.getSpaceObjectFromName(playerData.map, name)
    );

    if (planets.length > 3) {
      throw new Error("You cannot select more than 3 planets.");
    }

    for (const planet of planets) {
      if (planet.faction !== playerData.faction.name) {
        throw new Error("You do not control the planet " + planet.name + ".");
      }
      const bonus = PlanetBonus.createRandomBonusName();
      Planet.addBonusFromName(planet, bonus);
    }

    this.logAndMessage(
      playerData,
      minorFaction,
      "Added a random bonus to the planets " + planetNames.join(", ") + "."
    );
  };

  static logAndMessage = (playerData, minorFaction, message) => {
    UIMessage.sendInfoMessageToClient(playerData, minorFaction.name, message);
    PlayerData.generateLogActivity(
      playerData,
      minorFaction.name + " : " + message
    );
  };
}

module.exports = ResolveMinorFactionAbility;
