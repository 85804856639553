const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const SystemAction = require("../ActionCommon/SystemAction.js");
const ValidAction = require("../ActionCommon/WarningAction.js");
const WarningAction = require("../ActionCommon/WarningAction.js");
const Cost = require("../Utils/Cost.js");
const LogBook = require("../Connection/LogBook.js");
const LogAttachment = require("../Connection/LogAttachment.js");
const CheckAction = require("../ActionCommon/CheckAction.js");
const Phase = require("../Game/Phase.js");
const StaticGameData = require("../StaticGameData.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Request = require("../../../Common/Requests/Request.js");
const ActionCommonData = require("./ActionCommonData.js");
const TechTree = require("../Technology/TechTree.js");
const TechList = require("../Technology/TechList.js");

class MinorFactionActionData {
  static prepare(playerData, minorFaction) {
    const data = {
      minorFactionId: minorFaction.id,
    };

    ActionCommonData.prepare(
      playerData,
      Phase.PHASE_ACTION,
      Phase.STEP_ACTION_BUY_RESOURCES,
      data
    );
  }

  static useMinorFactionAbility(playerData, minorFaction) {}

  //Set
  static updateMineralToBuy(playerData, amount) {
    const data = ActionCommonData.getData(playerData);
    data.mineralToBuy += amount;
    data.mineralToBuy = CustomMath.roundDec(data.mineralToBuy);
    if (data.mineralToBuy < 0) data.mineralToBuy = 0;
    Popup.touch();
  }

  static updateScienceToBuy(playerData, amount) {
    const data = ActionCommonData.getData(playerData);
    data.scienceToBuy += amount;
    data.scienceToBuy = CustomMath.roundDec(data.scienceToBuy);
    if (data.scienceToBuy < 0) data.scienceToBuy = 0;
    Popup.touch();
  }

  static updateEnergyToBuy(playerData, amount) {
    const data = ActionCommonData.getData(playerData);
    data.energyToBuy += amount;
    data.energyToBuy = CustomMath.roundDec(data.energyToBuy);
    if (data.energyToBuy < 0) data.energyToBuy = 0;
    Popup.touch();
  }

  static updateInfluenceToBuy(playerData, amount) {
    const data = ActionCommonData.getData(playerData);
    data.influenceToBuy += amount;
    if (data.influenceToBuy < 0) data.influenceToBuy = 0;
    Popup.touch();
  }

  //Get

  static getCost(playerData) {
    const data = ActionCommonData.getData(playerData);

    let globalModifier = 0;
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.businessAcademy.name)
    ) {
      globalModifier = 0.2;
    }

    const cost = Cost.createCost({
      credit:
        data.mineralToBuy * Rules.BUY_MINERAL_COST * (1 - globalModifier) +
        data.scienceToBuy * Rules.BUY_SCIENCE_COST * (1 - globalModifier) +
        data.energyToBuy * Rules.BUY_ENERGY_COST * (1 - globalModifier),
    });
    return cost;
  }

  //Resolve
  static clientResolve(playerData) {
    const sendToServer = () => {
      ActionCommonData.resolveClient(playerData);
    };

    sendToServer();
  }

  static serverResolve(playerData, resolveData) {
    ActionCommonData.resolveServerIni(playerData, resolveData);

    const data = ActionCommonData.getData(playerData);

    PlayerData.spendCost(playerData, this.getCost(playerData));

    PlayerData.gainEnergy(playerData, data.energyToBuy);
    PlayerData.gainScience(playerData, data.scienceToBuy);
    PlayerData.gainMineral(playerData, data.mineralToBuy);
  }
}

module.exports = MinorFactionActionData;
