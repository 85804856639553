import React, { useState } from "react";

import BaseButton from "../BaseButton";

const Popup = require("../../../Data/Other/Popup");

const SelectionListView = ({
  items,
  displayItem,
  selectedItems,
  selectItem,
  unSelectItem,
  textSelected,
  textUnselected,
  textIfEmpty = "No items to display",
  lineLayout = false,
}) => {
  if (!items) return null;
  if (!selectItem)
    throw new Error("SelectionListView : selectItem is not defined");
  if (!unSelectItem)
    throw new Error("SelectionListView : unSelectItem is not defined");
  if (!displayItem)
    throw new Error("SelectionListView : displayItem is not defined");

  const DisplayItemsInLine = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap", // Allows items to wrap onto multiple lines
        }}
      >
        {items.length === 0 && <span>{textIfEmpty}</span>}

        {items.length > 0 &&
          items.map((item, index) => {
            const isSelected = selectedItems.includes(item);
            const backgroundColor = isSelected ? "gold" : "none";
            const border = isSelected ? "2px solid gold" : "none";

            return (
              <span
                key={index}
                style={{ marginRight: "0.5em", display: "inline-block" }}
              >
                <div
                  className="div-ver-center"
                  style={{
                    //backgroundColor: backgroundColor,
                    border: border,
                  }}
                >
                  <span>{displayItem(item)}</span>
                  <span>
                    <BaseButton
                      width="100%"
                      onClick={() => {
                        if (isSelected) {
                          unSelectItem(item);
                          Popup.touch();
                        } else {
                          selectItem(item);
                          Popup.touch();
                        }
                      }}
                      style={
                        {
                          //backgroundColor: backgroundColor,
                        }
                      }
                    >
                      {/*isSelected ? textSelected : textUnselected*/}
                      {isSelected ? <span>-</span> : <span>+</span>}
                    </BaseButton>
                  </span>
                </div>
              </span>
            );
          })}
      </div>
    );
  };

  const DisplayItems = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <table
          style={{
            textAlign: "center",
            padding: "5px",
          }}
        >
          <tbody>
            {items.length === 0 && (
              <tr>
                <td>{textIfEmpty}</td>
              </tr>
            )}
            {items.length > 0 &&
              items.map((item, index) => {
                const isSelected = selectedItems.includes(item);
                const backgroundColor = isSelected ? "gold" : "none";
                const border = isSelected ? "2px solid gold" : "none";
                return (
                  <tr
                    key={index}
                    style={
                      {
                        //backgroundColor: backgroundColor,
                        //border: border,
                        //textAlign: "center",
                      }
                    }
                  >
                    <td
                      style={{
                        //backgroundColor: backgroundColor,
                        border: border,

                        //textAlign: "center",
                      }}
                    >
                      {/* First Column: Display Item */}
                      <div>
                        <span>{displayItem(item)}</span>
                      </div>

                      {/* Second Column: Button to select */}
                    </td>
                    <td
                      style={{
                        width: "3em",
                      }}
                    >
                      <div>
                        <BaseButton
                          onClick={() => {
                            console.log("click isSelected: ", isSelected);
                            console.log(
                              "isSelected selectedItems: ",
                              selectedItems
                            );
                            if (isSelected) {
                              unSelectItem(item);
                              Popup.touch();
                            } else {
                              selectItem(item);
                              Popup.touch();
                            }
                          }}
                          style={
                            {
                              // backgroundColor: backgroundColor
                            }
                          }
                        >
                          {/*isSelected ? textSelected : textUnselected*/}
                          {isSelected ? <span>-</span> : <span>+</span>}
                        </BaseButton>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gap: "2px",
        }}
      >
        {items.length === 0 && <span>{textIfEmpty}</span>}
        {items.map((item, index) => {
          const isSelected = selectedItems.includes(item);
          const backgroundColor = isSelected ? "grey" : "none";

          return (
            <div
              key={index}
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                gap: "2px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* First Column: Display Item */}
              <div
                style={{
                  //backgroundColor: backgroundColor,
                  padding: "px",
                  textAlign: "center",
                }}
              >
                <span>{displayItem(item)}</span>
              </div>

              {/* Second Column: Button to select */}
              <span style={{ textAlign: "left" }}>
                {" "}
                <BaseButton
                  onClick={() => {
                    if (isSelected) {
                      unSelectItem(item);
                      Popup.touch();
                    } else {
                      selectItem(item);
                      Popup.touch();
                    }
                  }}
                  style={
                    {
                      //backgroundColor: backgroundColor
                    }
                  }
                >
                  {/*isSelected ? textSelected : textUnselected*/}
                  {isSelected ? <span>-</span> : <span>+</span>}
                </BaseButton>
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {lineLayout && <DisplayItemsInLine></DisplayItemsInLine>}
      {!lineLayout && <DisplayItems></DisplayItems>}
    </div>
  );
};

export default SelectionListView;
