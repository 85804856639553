import React, { useState } from "react";
import StaticGameData from "../Data/GameData/StaticGameData.js";
import BaseButton from "../GameView/Utils/BaseButton.jsx";
import GameGlobalLayout from "../GameView/GameGlobalLayout.jsx";
import NavigationView from "../GameView/NavigationView.jsx";
import Logo from "../GameView/Utils/Logo.jsx";

const Popup = require("../Data/Other/Popup.js");
const HelpData = require("./HelpData.js");
const Rules = require("../Data/GameData/Game/Rules.js");

const LinkExt = ({ linkc, text }) => {
  const title = text;
  return (
    <BaseButton
      onClick={() => {
        Popup.addLayer({ name: "HelpPopup", helpReference: linkc });
      }}
    >
      {title}
    </BaseButton>
  );
};

export default LinkExt;
