const CustomMath = require("../../../Common/Math/CustomMath.js");
const Fleet = require("./Fleet.js");
const Unit = require("./Unit.js");
const System = require("./System.js");
const Map = require("./Map.js");
const SecondaryObject = require("./SecondaryObject.js");
const MinorFaction = require("../MinorFaction/MinorFaction.js");
const PlayerData = require("../PlayerData/PlayerData.js");
const Faction = require("../PlayerData/Faction.js");
const Planet = require("./Planet.js");
const Objectives = require("../Objectifs/Objectives.js");
const Curiosity = require("./Curiosity.js");
const SpaceObject = require("./SpaceObject.js");

class CreateMap {
  static createMap(amountPlayers) {
    const playerStartingPositions = [];
    const mapSizeX = 4;
    const amountLineByPlayer = 2;
    for (let i = 0; i < amountPlayers; i++) {
      if (i % amountLineByPlayer === 0) {
        playerStartingPositions.push({ x: 1, y: i * amountLineByPlayer + 1 });
      } else {
        playerStartingPositions.push({
          x: mapSizeX,
          y: i * amountLineByPlayer + 1,
        });
      }
    }

    const map = {
      systems: this.createSystemArray(
        mapSizeX,
        amountPlayers * amountLineByPlayer,
        playerStartingPositions
      ),
      playerStartingPositions: playerStartingPositions,
    };

    //If odd amount of player, map conenctions are reversed
    map.reverseConnections = false;
    if (amountPlayers % 2 === 1) {
      map.reverseConnections = true;
    }

    this.balanceStartingTiles(map, playerStartingPositions);

    //Specify wormholes letters
    const systems = Map.getSystemList(map);
    SecondaryObject.specifyWormholesLetter(systems, amountPlayers);

    //FillConnections in systems
    for (let x = 0; x < mapSizeX; x++) {
      map.systems[0][x].mapConnection = { number: x + 1, direction: "up" };
      if (map.reverseConnections) {
        map.systems[map.systems.length - 1][x].mapConnection = {
          number: mapSizeX - x,
          direction: "down",
        };
      } else {
        map.systems[map.systems.length - 1][x].mapConnection = {
          number: x + 1,
          direction: "down",
        };
      }
    }

    //Return
    return map;
  }

  static startingTiles = [];

  static balanceStartingTiles(map, playerStartingPositions) {
    const amountOfSwitches = 10;

    let totalValue = 0;
    const calculateValues = () => {
      totalValue = 0;
      this.startingTiles = [];
      return playerStartingPositions.map((playerStartingPosition) => {
        const valueTemp = this.evaluatePlayerStartingPosition(
          map,
          playerStartingPosition
        );
        totalValue += valueTemp;
        return {
          x: playerStartingPosition.x,
          y: playerStartingPosition.y,
          value: valueTemp,
        };
      });
    };

    for (let i = 0; i < amountOfSwitches; i++) {
      //Calculate individual value for each tile
      const data = calculateValues();
      //averageValue = CustomMath.roundDec(averageValue);
      const averageValuePerSystem =
        totalValue /
        (Map.getSizeX(map) * Map.getSizeY(map) -
          playerStartingPositions.length);
      const averageValuePerTile = totalValue / playerStartingPositions.length;

      //Sort data per value
      data.sort((a, b) => {
        return a.value - b.value;
      });

      //exchange tiles
      for (let i = 0; i < Math.floor(data.length / 2); i++) {
        const dataToBuff = data[i];
        const dataToNerf = data[data.length - 1 - i];
        this.switchTiles(
          map,
          { x: dataToBuff.x, y: dataToBuff.y },
          { x: dataToNerf.x, y: dataToNerf.y },
          averageValuePerSystem,
          dataToNerf.value - dataToBuff.value
        );
      }
    }

    for (let i = 0; i < playerStartingPositions.length; i++) {
      const playerStartingPosition = playerStartingPositions[i];
      const positionValue = this.evaluatePlayerStartingPosition(
        map,
        playerStartingPosition
      );
      console.log(
        "Position " +
          playerStartingPosition.x +
          "-" +
          playerStartingPosition.y +
          " value : ",
        positionValue
      );
    }
  }

  static switchTiles(
    map,
    positionToBuff,
    positionToNerf,
    averageValuePerSystem,
    currentGap
  ) {
    //prepare random array of tiles
    const tileToBuff = Map.getStartingSliceSystems(
      map,
      Map.getSystemFromCoords(map, positionToBuff.x, positionToBuff.y)
    ).filter((system) => {
      const coords = System.getCoords(system);
      return coords.x !== positionToBuff.x || coords.y !== positionToBuff.y;
    });
    const tileToNerf = Map.getStartingSliceSystems(
      map,
      Map.getSystemFromCoords(map, positionToNerf.x, positionToNerf.y)
    ).filter((system) => {
      const coords = System.getCoords(system);
      return coords.x !== positionToNerf.x || coords.y !== positionToNerf.y;
    });
    CustomMath.shuffleArray(tileToBuff);
    CustomMath.shuffleArray(tileToNerf);

    //pick a bad system in position to buff
    let systemToBuff = null;
    let systemToBuffValue = 0;
    for (let i = 0; i < tileToBuff.length; i++) {
      const system = tileToBuff[i];
      systemToBuffValue = this.evaluateSystem(system);
      if (systemToBuffValue < averageValuePerSystem) {
        systemToBuff = system;
        break;
      }
    }

    //pick a good system in position to nerf
    let systemToNerf = null;
    let systemToNerfValue = 0;
    for (let i = 0; i < tileToNerf.length; i++) {
      const system = tileToNerf[i];
      systemToNerfValue = this.evaluateSystem(system);

      if (systemToNerfValue > averageValuePerSystem) {
        systemToNerf = system;
        break;
      }
    }

    //If no system found, don't switch
    if (systemToBuff === null || systemToNerf === null) {
      return false;
    }

    //If it increases the gap, don't switch
    const gapCorrection = (systemToNerfValue - systemToBuffValue) * 2;
    if (gapCorrection > currentGap * 2) {
      return;
    }

    const toBuffCoords = System.getCoords(systemToBuff);
    const toNerfCoords = System.getCoords(systemToNerf);
    Map.replaceSystemFromCoords(
      map,
      toNerfCoords.x,
      toNerfCoords.y,
      systemToBuff
    );
    Map.replaceSystemFromCoords(
      map,
      toBuffCoords.x,
      toBuffCoords.y,
      systemToNerf
    );
  }

  static nerfBuffTile(map, x, y, currentValue, planetTypeDeck, nerf = true) {
    const HS = Map.getSystemFromCoords(map, x, y);

    const replaceRandomSystem = (HS, map, nerf) => {
      const tileSystems = Map.getStartingSliceSystems(map, HS);

      //Remove HS from list
      const index = tileSystems.indexOf(HS);
      if (index > -1) {
        tileSystems.splice(index, 1);
      }

      //Pick random coords not HS
      CustomMath.shuffleArray(tileSystems);
      const system = tileSystems[0];
    };
  }

  static createMinorFactions(playerData, map) {
    //Creation of minor faction deck
    const minorFactionSlots = Map.getSpaceObjectsFromType(
      map,
      SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION
    );
    const minorFactionDeck = MinorFaction.generateRandomMinorFactionArray(
      minorFactionSlots.length
    );

    //Create of objective deck
    const objectiveDeck = Objectives.generateRandomObjectivesArray(
      minorFactionSlots.length
    );

    //Fill with minor faction objects including objective when type of secondary is minor faction
    const systemList = Map.getSystemList(map);
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];
      const objectList = System.getSpaceObjects(system);
      for (let j = 0; j < objectList.length; j++) {
        const object = objectList[j];
        if (object.type === SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION) {
          this.createMinorFactionObject(
            playerData,
            map,
            system,
            j,
            minorFactionDeck,
            objectiveDeck
          );
        }
      }
    }
  }

  static createMinorFactionObject(
    playerData,
    map,
    system,
    objectIndex,
    minorFactionDeck,
    objectiveDeck
  ) {
    let potentialMinorFactionIndex = 0;
    let valid = false;
    while (!valid) {
      const minorFaction = minorFactionDeck[potentialMinorFactionIndex];
      const minorFactionDesc = MinorFaction.getFactionDescDataFromName(
        minorFaction.name
      );
      //valid = this.minorFactionIsValid(minorFactionDesc, system);
      valid = true;

      if (valid) {
        const objects = System.getSpaceObjects(system);
        objects[objectIndex].minorFactionData =
          minorFactionDeck[potentialMinorFactionIndex];

        minorFactionDeck.splice(potentialMinorFactionIndex, 1);

        //Adding objective
        const objectiveName = objectiveDeck.pop();
        SecondaryObject.setObjectiveData(
          objects[objectIndex],
          Objectives.create(objectiveName)
        );

        this.createMinorFactionUnits(
          playerData,
          system,
          objectIndex,
          minorFactionDesc
        );
      }
      potentialMinorFactionIndex++;
    }
  }

  static minorFactionIsValid(minorFactionDesc, system) {
    //Units in space area
    if (minorFactionDesc.areUnitsInSystem) {
      if (this.isThereMinorFactionUnitInFleets(System.getFleets(system))) {
        return false;
      }
    }

    //Units on planet
    if (minorFactionDesc.areUnitsOnPlanet) {
      const planets = System.getPlanets(system);
      if (planets.length === 0) {
        return false;
      }
      const planet = planets[0];
      if (this.isThereMinorFactionUnitInFleets(Planet.getFleets(planet))) {
        return false;
      }
    }

    //Does need a planet
    if (minorFactionDesc.doesNeedAPlanet) {
      const planets = System.getPlanets(system);
      if (planets.length === 0) {
        return false;
      }
    }
    //Does need no planet
    if (minorFactionDesc.doesNeedNoPlanet) {
      const planets = System.getPlanets(system);
      if (planets.length > 0) {
        return false;
      }
    }

    return true;
  }

  static createMinorFactionUnits(
    playerData,
    system,
    objectIndex,
    minorFactionDesc
  ) {
    //Creation of units in space area
    if (minorFactionDesc.areUnitsInSystem) {
      const fleet = Fleet.createFleet(playerData, minorFactionDesc.name);
      const untisTypeToCreate = minorFactionDesc.unitsInSystemList;
      for (let i = 0; i < untisTypeToCreate.length; i++) {
        Fleet.createUnit(playerData, fleet, untisTypeToCreate[i]);
      }
      System.addFleetToSystem(system, fleet);
    }

    //Creation of units on planet
    if (minorFactionDesc.areUnitsOnPlanet) {
      const fleet = Fleet.createFleet(playerData, minorFactionDesc.name);
      const untisTypeToCreate = minorFactionDesc.unitsOnPlanetList;
      for (let i = 0; i < untisTypeToCreate.length; i++) {
        Fleet.createUnit(playerData, fleet, untisTypeToCreate[i]);
      }
      const planets = System.getPlanets(system);
      if (planets.length === 0) {
        throw new Error("No planet in system to add hero units");
      } else {
        const planet = planets[0];
        Planet.addFleetToPlanet(planet, fleet);
      }
    }
  }

  static isThereMinorFactionUnitInFleets(fleets) {
    for (let i = 0; i < fleets.length; i++) {
      const fleet = fleets[i];
      if (Faction.isMinorFaction(fleet.faction)) {
        const units = Fleet.getUnits(fleet);
        if (units.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  static evaluateSystem(system) {
    const getPointsForPlanetInSlice = (planet) => {
      if (planet.type === "Terran") {
        return 3;
      }
      if (planet.type === "Desert") {
        return 3;
      }
      if (planet.type === "Oceanic") {
        return 2;
      }
      if (planet.type === "Ice") {
        return 2;
      }
      if (planet.type === "Gaz") {
        return 3;
      }
      if (planet.type === "Volcanic") {
        return 2;
      }
      return 0;
    };

    const getPointsForCuriosity = (system) => {
      const curiosity = system.backgroundObject;
      if (curiosity === Curiosity.object.asteroidField.name) {
        return 0;
      }
      if (curiosity === Curiosity.object.crystalField.name) {
        return 2;
      }
      if (curiosity === Curiosity.object.uteriumDust.name) {
        return 1;
      }
      if (curiosity === Curiosity.object.fluxAnomaly.name) {
        return 1;
      }
      if (curiosity === Curiosity.object.mineralField.name) {
        return 1;
      }
      if (curiosity === Curiosity.object.nebula.name) {
        return 0;
      }
      if (curiosity === Curiosity.object.storm.name) {
        return 0;
      }
      return 0;
    };

    const getPointsForMinorFaction = (system) => {
      const minorFactionList = System.getMinorFactionObjects(system);
      let points = 0;
      for (let i = 0; i < minorFactionList.length; i++) {
        const minorFaction = minorFactionList[i];
      }
      return points;
    };

    const planets = System.getPlanets(system);
    let points = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      points += getPointsForPlanetInSlice(planet);
    }
    points += getPointsForCuriosity(system);
    points += getPointsForMinorFaction(system);
    return points;
  }

  static evaluatePlayerStartingPosition(map, playerStartingPosition) {
    const hs = Map.getSystemFromCoords(
      map,
      playerStartingPosition.x,
      playerStartingPosition.y
    );

    const systemsInSlice = Map.getStartingSliceSystems(map, hs);

    this.startingTiles.push(systemsInSlice);

    let slicePoints = 0;
    for (let i = 0; i < systemsInSlice.length; i++) {
      slicePoints += this.evaluateSystem(systemsInSlice[i]);
    }

    return slicePoints;
  }

  static createObject() {
    const randomizeObjectType = CustomMath.generateRandomNumber(1, 3);
  }

  static createSystemArray(sizeX, sizeY, playerStartingPositions) {
    //console.log("Create Map : Creating system array");

    //Prepare deck of planet types
    const amountSystems = sizeX * sizeY;
    const planetTypeDeck = [];
    while (planetTypeDeck.length < amountSystems) {
      planetTypeDeck.push(...SpaceObject.getPlanetTypeDeck());
    }
    CustomMath.shuffleArray(planetTypeDeck);

    //Get systems names

    const systemNames = this.getSystemNames();

    //Creating array of systems
    const systems = [];
    for (let y = 1; y < sizeY + 1; y++) {
      const line = [];
      for (let x = 1; x < sizeX + 1; x++) {
        //check if player starting position array
        let isPlayerStartingPosition = false;
        for (let i = 0; i < playerStartingPositions.length; i++) {
          if (
            playerStartingPositions[i].x === x &&
            playerStartingPositions[i].y === y
          ) {
            isPlayerStartingPosition = true;
          }
        }
        if (isPlayerStartingPosition) {
          // Pick a random system name

          const system = System.createFactionDraftSystem(x, y);
          console.log("Create Map : system : ", system);
          line.push(system);
        } else {
          const randomIndex = Math.floor(Math.random() * systemNames.length);
          const randomSystemName = systemNames.splice(randomIndex, 1)[0];

          const system = System.createSystem(
            x,
            y,
            randomSystemName,
            planetTypeDeck
          );
          line.push(system);
        }
      }
      systems.push(line);
    }
    //console.log("Create Map : systems : ", systems);
    return systems;
  }

  static prepareMapForGameState(map) {
    // Hide objects that are not discovered at start
    for (const system of map.systems) {
      System.hideNotDiscoveredAtStart(system);
    }
  }

  static sitPlayerOnMap(map, factionsToPickFrom, homeSystemPosition) {
    map.systems[homeSystemPosition.y - 1][
      homeSystemPosition.x - 1
    ].factionsToPickFrom = factionsToPickFrom;
  }

  static getSystemNames() {
    const starSystemNames = [
      "Celestia",
      "Nova",
      "Solara",
      "Zephyr",
      "Orion",
      "Aether",
      "Lyra",
      "Nebula",
      "Helios",
      "Sirius",
      "Centauri",
      "Phoenix",
      "Draco",
      "Seraph",
      "Vega",
      "Argo",
      "Cassio",
      "Pegasus",
      "Zenith",
      "Epsilon",
      "Horizon",
      "Apollo",
      "Quasar",
      "Ursa",
      "Andromeda",
      "Altair",
      "Polaris",
      "Aquila",
      "Caelum",
      "Sagitta",
      "Capella",
      "Corona",
      "Hydra",
      "Lyric",
      "Taurus",
      "Astra",
      "Serene",
      "Capricorn",
      "Proxima",
      "Electra",
      "Antares",
      "Solaris",
      "Terra",
      "Cygnus",
      "Aries",
      "Scorpio",
      "Aegis",
      "Atria",
      "Horus",
      "Cetus",
      "Regulus",
      "Cassiopeia",
      "Aldebaran",
      "Aquarius",
      "Gemini",
      "Pulsar",
      "Libra",
      "Pyxis",
      "Eridanus",
      "Antlia",
      "Pavo",
      "Leo", // Additional names
      "Luna",
      "Aurora",
      "Nyx",
      "Cassini",
      "Astro",
      "Astral",
      "Umbra",
      "Helix",
      "Stella",
      "Cosmo",
      "Crux",
      "Lynx",
      "Solstice",
      "Galaxy",
      "Spectrum",
      "Eclipse",
      "Selenia",
      "Crescent",
      "Zenon",
      "Expanse",
      "Auriga",
      "Serenity",
      "Nebulae",
      "Orbita",
      "Aetheria",
      "Nimbus",
      "Vortex",
      "Cosmic",
      "Pluto",
      "Stellar",
      "Elysium",
      "Equinox",
      "Supernova",
      "Stardust",
      "Solitude",
      "Aurelia",
      "Galactic",
      "Asteria",
      "Lunar",
      "Ethereal",
      "Zodiac",
      "Lumina",
      "Astria",
      "Ecliptic",
      "Triton",
      "Neptune",
      "Astrum",
      "Venus",
      "Aurelian",
      "Quintessence",
      "Luminary",
      "Advent",
      "Allegro",
      "Amber",
      "Apex",
      "Apogee",
      "Artemis",
      "Athena",
      "Avalon",
      "Avenger",
      "Axiom",
      "Bellerophon",
      "Beyond",
      "Bifrost",
      "Bifurcate",
      "Binary",
      "Biosphere",
      "Blackbird",
      "Blast",
      "Blink",
      "Blitz",
      "Blossom",
      "Blue",
      "Bolt",
      "Borealis",
      "Boreas",
      "Bravo",
      "Breeze",
      "Buckle",
      "Burning",
      "Burst",
      "Cascade",
      "Catalyst",
      "Ceres",
      "Challenger",
      "Chronos",
      "Citadel",
      "Columbia",
      "Comet",
      "Constellation",
      "Corvus",
      "Cosmos",
      "Covenant",
      "Crimson",
      "Cygnet",
      "Darkside",
      "Darkstar",
      "Dart",
      "Dawn",
      "Dawnbreaker",
      "Dawnstar",
      "Daybreak",
      "Delta",
      "Destiny",
      "Discovery",
      "Dragonfly",
      "Dreamscape",
      "Drifter",
      "Driftwood",
      "Dusk",
      "Duskblade",
      "Duskrunner",
      "Duststorm",
      "Dynamo",
      "Eagle",
      "Echo",
      "Eden",
      "Elysia",
      "Elysian",
      "Empyrean",
      "Enclave",
      "Endeavour",
      "Enigma",
      "Eon",
      "Etherea",
      "Everest",
      "Evolution",
      "Explorer",
      "Fahrenheit",
      "Falcon",
      "Farsight",
      "Fathom",
      "Fervent",
      "Fervor",
      "Firefly",
      "Fission",
      "Flare",
      "Fleet",
      "Fortuna",
      "Fractal",
      "Frontier",
      "Frost",
      "Fury",
      "Fusion",
      "Futura",
      "Gala",
      "Gale",
      "Gazelle",
      "Genesis",
      "Glacier",
      "Gladiator",
      "Glide",
      "Glimmer",
      "Glisten",
      "Globe",
      "Glow",
      "Goliath",
      "Gossamer",
      "Graviton",
      "Gravity",
      "Griffin",
      "Guardian",
      "Gyro",
      "Halcyon",
      "Halo",
      "Harbinger",
      "Harmony",
      "Haven",
      "Havoc",
      "Hawk",
      "Hera",
      "Hermes",
      "Hope",
      "Hubble",
      "Hyperion",
      "Hyperspace",
      "Icarus",
      "Iceberg",
      "Icewind",
      "Ignition",
      "Illuminate",
      "Illumination",
      "Illusion",
      "Imperial",
      "Impulse",
      "Inception",
      "Indigo",
      "Inferno",
      "Infinity",
      "Innovation",
      "Insight",
      "Inspire",
      "Interstellar",
      "Intrepid",
      "Intrigue",
      "Ironclad",
      "Jackal",
      "Jade",
      "Jadeite",
      "Jamboree",
      "Jasmine",
      "Javelin",
      "Jazz",
      "Jester",
      "Jetstream",
      "Jigsaw",
      "Jinx",
      "Jolt",
      "Journey",
      "Joy",
      "Jubilee",
      "Juggernaut",
      "Jungle",
      "Juniper",
      "Jupiter",
      "Kaiju",
      "Kairos",
      "Kaiser",
      "Kaleid",
      "Karma",
      "Kazoo",
      "Kestrel",
      "Keystone",
      "Kindred",
      "Kinetic",
      "Kinetica",
      "Kingfisher",
      "Kismet",
      "Kite",
      "Klondike",
      "Knighthawk",
      "Kodiak",
      "Krypton",
      "Labyrinth",
      "Lagoon",
      "Lancer",
      "Latitude",
      "Legacy",
      "Leviathan",
      "Liberator",
      "Liberty",
      "Lifeline",
      "Liftoff",
      "Lighthouse",
      "Lorelei",
      "Luminous",
      "Luster",
      "Magellan",
      "Majestic",
      "Majesty",
      "Mariner",
      "Mars",
      "Maverick",
      "Mercury",
      "Meteor",
      "Miraclon",
      "Mirage",
      "Momentum",
      "Monarch",
      "Monolith",
      "Moonlight",
      "Mosaic",
      "Mysterial",
      "Mystic",
      "Mystique",
      "Nautica",
      "Navigator",
      "Nebulon",
      "Nebulous",
      "Nemesis",
      "Nestor",
      "Nexus",
      "Nightfall",
      "Nightingale",
      "Nirvana",
      "Noble",
      "Nomadic",
      "Northstar",
      "Nucleus",
      "Oasis",
      "Octane",
      "Odyssey",
      "Olympus",
      "Omega",
      "Omniverse",
      "Onyx",
      "Opal",
      "Optima",
      "Oracle",
      "Orbit",
      "Osiris",
      "Outburst",
      "Outlander",
      "Outlook",
      "Outpost",
      "Outshine",
      "Overdrive",
      "Overlord",
      "Paladin",
      "Panorama",
      "Pantheon",
      "Panthera",
      "Paragon",
      "Pathfinder",
      "Perseus",
      "Pioneer",
      "Principle",
      "Prism",
      "Prodigy",
      "Prometheus",
      "Pulse",
      "Quadric",
      "Quantum",
      "Quark",
      "Quarry",
      "Quartz",
      "Quest",
      "Questor",
      "Quicken",
      "Quicksand",
      "Quickstep",
      "Quintain",
      "Quintal",
      "Quintet",
      "Quintuple",
      "Quiver",
      "Radiance",
      "Radiant",
      "Ranger",
      "Rapier",
      "Raptor",
      "Reactor",
      "Redshift",
      "Regal",
      "Reliant",
      "Renaissance",
      "Renegade",
      "Requiem",
      "Resolute",
      "Revelation",
      "Revenant",
      "Revolution",
      "Rift",
      "Rogue",
      "Rover",
      "Sagittarius",
      "Sapphire",
      "Saturn",
      "Sentinel",
      "Skyward",
      "Solace",
      "SpaceX",
      "Spectra",
      "Starborn",
      "Stargazer",
      "Starlight",
      "Starstruck",
      "Stratos",
      "Stratosphere",
      "Synchrony",
      "Talon",
      "Tempest",
      "Terraform",
      "Tesseract",
      "Threshold",
      "Thunderbird",
      "Titan",
      "Torch",
      "Tranquility",
      "Traverse",
      "Trinity",
      "Tritan",
      "Tritium",
      "Tundra",
      "Turbulence",
      "Twilight",
      "Typhoon",
      "Ultima",
      "Ultra",
      "Umbilicus",
      "Umbrella",
      "Umbriel",
      "Undine",
      "Unison",
      "Unity",
      "Universe",
      "Unveil",
      "Updraft",
      "Upturn",
      "Uranus",
      "Urchin",
      "Utopia",
      "Valence",
      "Vanguard",
      "Verdant",
      "Vermilion",
      "Vertex",
      "Verve",
      "Vesper",
      "Vigil",
      "Vigilance",
      "Vigilant",
      "Vigorous",
      "Virtue",
      "Virtuoso",
      "Vista",
      "Vitalize",
      "Vivid",
      "Voltaic",
      "Voyageur",
      "Wanderer",
      "Wanderlust",
      "Warden",
      "Warp",
      "Warrior",
      "Watchman",
      "Wavelength",
      "Wayfarer",
      "Wayfinder",
      "Wellspring",
      "Whirlpool",
      "Whirlwind",
      "Whisper",
      "Wildfire",
      "Wraith",
      "Yacht",
      "Yale",
      "Yarrow",
      "Yggdrasil",
      "Yin",
      "Yonder",
      "Yowl",
      "Ypsilon",
      "Yucca",
      "Yukon",
      "Zen",
      "Zenithal",
      "Zephyranthes",
      "Zephyrus",
      "Zeppelin",
      "Zero",
      "Zest",
      "Zestful",
      "Zeta",
      "Zeus",
      "Ziggurat",
      "Zing",
      "Zircon",
      "Zoo",
    ];
    return starSystemNames;
  }
}

module.exports = CreateMap;
