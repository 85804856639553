import React, { useState } from "react";

import BaseButton from "../Utils/BaseButton";
import FactionLogo from "../Utils/FactionLogo";
import CostView from "../Utils/CostView";
import UnitImageView from "../Object/UnitImageView";
import LifeBarView from "./LifeBarView";
import Fleet from "../../Data/GameData/MapData/Fleet";
import FleetLineView from "../Object/FleetLineView";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const Popup = require("../../Data/Other/Popup");
const Unit = require("../../Data/GameData/MapData/Unit");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");
const Color = require("../../Common/Config/Colors");
const Cost = require("../../Data/GameData/Utils/Cost");

const UnitsLineView = ({
  units,
  factionName,
  size = "1em",
  onClick = null,
}) => {
  const playerData = StaticGameData.getPlayerData();
  const fleet = Fleet.createFleet(playerData, factionName);
  Fleet.addUnits(fleet, units);

  return (
    <FleetLineView onClick={onClick} size={size} fleet={fleet}></FleetLineView>
  );
};

export default UnitsLineView;
