import React, { useState } from "react";
import Logo from "./Logo";
import LogoPopAssigned from "./LogoPopAssigned";

const CustomMath = require("../../Common/Math/CustomMath.js");

const IMAGE_LOGO_BASE = "/Images/";

const ResourceBarView = ({
  resourceType,
  amount,
  factionName = null,
  fontSize = "1em",
}) => {
  if (!resourceType) {
    throw new Logo("ResourceBarView : No resourceType to display");
  }

  if (amount === 0) {
    return;
  }

  const DisplayItem = () => {
    if (resourceType === "mineral") {
      return <Logo logoName="mineral"></Logo>;
    }

    if (resourceType === "energy") {
      return <Logo logoName="energy"></Logo>;
    }

    if (resourceType === "science") {
      return <Logo logoName="science"></Logo>;
    }

    if (resourceType === "influence") {
      return <Logo logoName="influence"></Logo>;
    }

    if (resourceType === "cargo") {
      return <Logo logoName="cargo"></Logo>;
    }

    if (resourceType === "credit") {
      return <Logo logoName="credit"></Logo>;
    }

    if (resourceType === "population") {
      return <Logo logoName="population"></Logo>;
    }

    if (resourceType === "popOnMineral") {
      return <LogoPopAssigned assignType="popOnMineral"></LogoPopAssigned>;
    }

    if (resourceType === "popOnEnergy") {
      return <LogoPopAssigned assignType="popOnEnergy"></LogoPopAssigned>;
    }

    if (resourceType === "popOnScience") {
      return <LogoPopAssigned assignType="popOnScience"></LogoPopAssigned>;
    }
  };

  const DisplayAmount = ({ amount }) => {
    let amoutRounded = Math.floor(amount);
    const rest = CustomMath.roundDec(amount - amoutRounded);

    const items = [];
    /*console.log("DisplayAmount: ");
    console.log("amount: ", amount);
    console.log("rest: ", rest);
    console.log("resourceType: ", resourceType);*/
    for (let i = 0; i < amoutRounded; i++) {
      /*if (i !== 0 && i % 3 === 0) {
        items.push(
          <span key={i}>
            <span style={{ marginLeft: "1em" }}></span>
            <DisplayItem></DisplayItem>
          </span>
        );
      } else {*/
      items.push(
        <span key={i}>
          <DisplayItem></DisplayItem>
        </span>
      );
      //}
    }

    if (rest > 0) {
      items.push(
        <span key="rest" style={{ opacity: 0.4 }}>
          {amount >= 1 && <span></span>}
          <DisplayItem></DisplayItem>
        </span>
      );
    }

    //if (resourceType !== "popOnMineral" && resourceType !== "popOnScience") {
    items.push(<span key="amountText"> : {amount}</span>);
    //}

    return (
      <span>
        {false && <Logo logoName="mineral" heightRem="1em"></Logo>}
        <span
          style={
            {
              //position: "relative", top: "0.25em"
            }
          }
        >
          {" "}
          {items}
        </span>
      </span>
    );
  };

  return (
    <span
      style={{
        //border: "1px solid white",
        //borderRadius: "10px",
        //backgroundColor: "grey",
        //padding: "2px",
        fontSize: fontSize,
      }}
    >
      <DisplayAmount amount={amount}></DisplayAmount>
    </span>
  );
};

export default ResourceBarView;
