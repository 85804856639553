const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const ActivationData = require("./ActivationData.js");
const PlanetData = require("../MapData/Planet.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Cost = require("../Utils/Cost.js");
const SpaceObject = require("../MapData/SpaceObject.js");
const LogBook = require("../Connection/LogBook.js");

class LiftData {
  //Lift Functions

  static prepareStep(playerData, planet) {
    const activeSystem = ActivationData.getActiveSystem(playerData);
    const data = {
      spaceFleet: Fleet.createFleet(playerData, playerData.faction.name),
      planetName: planet.name,
      fleetLifted: Fleet.createFleet(playerData, playerData.faction.name),
    };

    playerData.activationData.liftStep = data;
  }

  static getData(playerData) {
    return playerData.activationData.liftStep;
  }

  static getCostLift(playerData) {
    const cost = Cost.createCost({});
    const costLift = PlayerData.getCostLift(playerData);
    const data = this.getData(playerData);
    if (!Fleet.isEmpty(data.fleetLifted)) {
      Cost.addCostToCost(costLift, cost);
    }

    return cost;
  }

  static liftUnit(playerData, planet, unit) {
    const data = this.getData(playerData);
    const spaceFleet = playerData.activationData.liftStep.spaceFleet;

    if (unit === null) {
      throw new Error("Unit not found");
    }

    Fleet.addUnit(data.fleetLifted, unit);

    /*const liftedFleet = planetData.fleetLifted;
    const planetFleet = Planet.getFleet(planet, playerData.faction.name);
    Fleet.addUnit(liftedFleet, unit);
    Fleet.removeUnit(planetFleet, unit, true);
    Fleet.addUnit(spaceFleet, unit);*/
  }

  static removeLiftUnit(playerData, planet, unit) {
    const data = this.getData(playerData);
    if (unit === null) {
      throw new Error("Unit is null");
    }
    Fleet.removeUnit(data.fleetLifted, unit);

    /*const spaceFleet = playerData.activationData.liftStep.spaceFleet;

    if (unit === null) {
      throw new Error("Unit is null");
    }

    const liftedFleet = planetData.fleetLifted;
    const planetFleet = Planet.getFleet(planet, playerData.faction.name);
    Fleet.addUnit(planetFleet, unit);
    Fleet.removeUnit(liftedFleet, unit, true);
    Fleet.removeUnit(spaceFleet, unit, true);*/
  }

  static getSpaceAreaFleet(playerData) {
    return playerData.activationData.liftStep.spaceFleet;
  }

  static resolveLift(playerData) {
    const activeSystem = ActivationData.getActiveSystem(playerData);
    const spaceFleet = System.getFleetOrCreate(
      activeSystem,
      playerData.faction.name,
      playerData.color
    );
    const data = this.getData(playerData);

    const planet = System.getSpaceObjectFromName(activeSystem, data.planetName);

    if (!planet) {
      throw new Error("Planet not found in active system");
    }

    const planetFleet = Planet.getFleetOrCreate(
      planet,
      playerData.faction.name,
      playerData.color
    );

    const unitsToLift = Fleet.getUnits(data.fleetLifted);
    for (let j = 0; j < unitsToLift.length; j++) {
      const unit = unitsToLift[j];
      if (unit.class !== Fleet.UNIT_CLASS_GROUND_FORCE) {
        throw new Error("Only ground forces can be lifted");
      }

      Fleet.removeUnit(planetFleet, unit);
      Fleet.addUnit(spaceFleet, unit);
    }

    if (!Fleet.isEmpty(data.fleetLifted)) {
      const cost = this.getCostLift(playerData);
      PlayerData.spendCost(playerData, cost);

      System.logActivityToSpaceObject(activeSystem, planet, [
        {
          type: LogMessage.ITEM_TYPE_FACTION_LOGO,
          content: playerData.faction.name,
        },
        { content: " Lifted ground forces from " },
        {
          type: LogMessage.ITEM_TYPE_SPACE_OBJECT,
          content: planet,
        },
        {
          content: " ",
        },
        {
          type: LogMessage.ITEM_TYPE_FLEET,
          content: Fleet.getLogItem(data.fleetLifted),
        },
      ]);
    }

    Fleet.checkValidity(playerData, spaceFleet);
  }
}

module.exports = LiftData;
