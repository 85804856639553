const SpaceObject = require("../MapData/SpaceObject");
const System = require("../MapData/System");
const Color = require("../../../Common/Config/Colors");
const Fleet = require("../MapData/Fleet");
const Unit = require("../MapData/Unit");
const Planet = require("../MapData/Planet");
const ImagesUrl = require("../../Other/ImagesUrl");
const Rules = require("../Game/Rules");
const Item = require("../Transactions/Item");

class PlayerRememberAction {
  static create() {
    const data = {
      attackedFactionsInSpace: [],
      attackedFactionsOnGround: [],
    };

    return data;
  }

  static getAttackedFactionsInSpace(playerRememberAction) {
    return playerRememberAction.attackedFactionsInSpace
      .map((attackedFaction) => {
        return attackedFaction.faction;
      })
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
  }

  static getAttackedFactionsOnGround(playerRememberAction) {
    return playerRememberAction.attackedFactionsOnGround
      .map((attackedFaction) => {
        return attackedFaction.faction;
      })
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
  }

  static addAttackedFactionInSpace(playerRememberAction, faction, system) {
    //Avoid creatinf duplicate on same system
    if (
      playerRememberAction.attackedFactionsInSpace.some((attackedFaction) => {
        return (
          attackedFaction.faction === faction &&
          attackedFaction.systemName === system.name
        );
      })
    ) {
      return;
    }

    playerRememberAction.attackedFactionsInSpace.push({
      faction: faction,
      systemName: system.name,
    });
  }

  static addAttackedFactionOnGround(playerRememberAction, faction, planet) {
    if (
      playerRememberAction.attackedFactionsOnGround.some((attackedFaction) => {
        return (
          attackedFaction.faction === faction &&
          attackedFaction.planetName === planet.name
        );
      })
    ) {
      return;
    }

    playerRememberAction.attackedFactionsOnGround.push({
      faction: faction,
      planetName: planet.name,
    });
  }

  static clearAll(playerRememberAction) {
    playerRememberAction.attackedFactionsInSpace = [];
    playerRememberAction.attackedFactionsOnGround = [];
  }

  static hasAttackedFactionInSpace(playerRememberAction, faction) {
    return playerRememberAction.attackedFactionsInSpace.some(
      (attackedFaction) => {
        return attackedFaction.faction === faction;
      }
    );
  }

  static hasAttackedFactionInSpaceInSystem(
    playerRememberAction,
    faction,
    system
  ) {
    const systemAttackedList = this.getSystemsAttackedFactionsInSpace(
      playerRememberAction,
      faction
    );
    return systemAttackedList.some((systemName) => {
      return systemName === system.name;
    });
  }

  static hasAttackedFactionOnGroundOnPlanet(
    playerRememberAction,
    faction,
    planet
  ) {
    const planetAttackedList = this.getPlanetsAttackedFactionsOnGround(
      playerRememberAction,
      faction
    );
    return planetAttackedList.some((planetName) => {
      return planetName === planet.name;
    });
  }

  static hasAttackedFactionOnGround(playerRememberAction, faction) {
    return playerRememberAction.attackedFactionsOnGround.some(
      (attackedFaction) => {
        return attackedFaction.faction === faction;
      }
    );
  }

  static getSystemsAttackedFactionsInSpace(
    playerRememberAction,
    attackedFaction
  ) {
    return playerRememberAction.attackedFactionsInSpace
      .filter((data) => {
        return data.faction === attackedFaction;
      })
      .map((data) => {
        return data.systemName;
      });
  }

  static getPlanetsAttackedFactionsOnGround(
    playerRememberAction,
    attackedFaction
  ) {
    return playerRememberAction.attackedFactionsOnGround
      .filter((data) => {
        return data.faction === attackedFaction;
      })
      .map((data) => {
        return data.planetName;
      });
  }

  static getTextSystemsAttackedFactionsInSpace(
    playerRememberAction,
    attackedFaction
  ) {
    const systemNames = this.getSystemsAttackedFactionsInSpace(
      playerRememberAction,
      attackedFaction
    );
    return systemNames.join(", ");
  }

  static getTextPlanetsAttackedFactionsOnGround(
    playerRememberAction,
    attackedFaction
  ) {
    const planetNames = this.getPlanetsAttackedFactionsOnGround(
      playerRememberAction,
      attackedFaction
    );
    return planetNames.join(", ");
  }
}

module.exports = PlayerRememberAction;
