import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import FactionLogo from "../Utils/FactionLogo";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import CostView from "../Utils/CostView";
import Logo from "../Utils/Logo";
import TechTypeView from "./TechTypeView";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import LogBook from "../../Data/GameData/Connection/LogBook";
import PrerequisitesView from "./PrerequisitesView";
import LineSpace from "../Utils/Layout/LineSpace.jsx";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const SpaceObject = require("../../Data/GameData/MapData/SpaceObject.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const TechTree = require("../../Data/GameData/Technology/TechTree.js");
const Tech = require("../../Data/GameData/Technology/Tech.js");

const TechTreeView = ({ playerInGameId }) => {
  const playerData = StaticGameData.getPlayerData();
  const anyPlayerData = PlayerData.getPlayerDataFromInGameId(
    playerData,
    playerInGameId
  );
  const isSelfPlayer = playerData.playerInGameId === playerInGameId;

  let showShortDescriptionGS = Popup.getGlobalState(
    "TechTreeView_showShortDescription"
  );

  const [showShortDescription, setShowShortDescription] = useState(
    showShortDescriptionGS !== null ? showShortDescriptionGS : true
  );

  //console.log("TechTreeView ownedReq", ownedReq);

  let showOwnedTechOnlyGS = Popup.getGlobalState(
    "TechTreeView_showOwnedTechOnly"
  );
  let showOwnedTechOnly = showOwnedTechOnlyGS ? showOwnedTechOnlyGS : false;

  let techTree = anyPlayerData.techTree;
  const techList = TechTree.getAllTechs(techTree);

  const ownedReq = TechTree.getOwnedRequisites(techTree);

  const navigationData = {
    buttonsData: [],
  };

  let previousTech = null;

  navigationData.buttonsData.push({
    text: showShortDescription ? "-" : "+",
    callback: () => {
      Popup.addGlobalState(
        "TechTreeView_showShortDescription",
        !showShortDescription
      );

      setShowShortDescription(!showShortDescription);
    },
  });
  if (ownedReq.length > 0 && isSelfPlayer) {
    navigationData.buttonsData.push({
      content: (
        <span>
          {ownedReq.map((req, index) => {
            return (
              <span key={index}>
                <TechTypeView color={req}></TechTypeView>
              </span>
            );
          })}
        </span>
      ),

      callback: () => {
        const messageBook = LogBook.createLogBook();
        LogBook.generateAddMessage(
          messageBook,
          "This button shows you the prerequisites you own."
        );
        if (ownedReq.length > 0) {
          let messageText = "You owned the following prerequisites : ";
          let messageItems = [];
          for (let i = 0; i < ownedReq.length; i++) {
            messageText = messageText + "$logo$ ";
            messageItems.push(Tech.getLogoName(ownedReq[i]));
          }
          LogBook.generateAddMessage(messageBook, messageText, messageItems);
        } else {
          LogBook.generateAddMessage(
            messageBook,
            "You don't owned any tech prerequisites for now."
          );
        }

        LogBook.generateAddMessage(
          messageBook,
          "Each time you research a tech of a specific color, you gain one prerequisites of that color. Prerequisites are required to research most of the technologies."
        );
        LogBook.generateAddMessage(
          messageBook,
          "The prerequisites to research a technology is displayed on the right of the technology name."
        );
        LogBook.generateAddMessage(
          messageBook,
          "The prerequisites you gain when researching a technology is displayed on the left of the technology name."
        );
        LogBook.generateAddMessage(
          messageBook,
          "The technology you own are displayed with a golden border."
        );

        UIMessage.displayInfoLogBook(messageBook);
      },
    });
  }

  if (!isSelfPlayer) {
    navigationData.taskBarData = {
      displayText: () => {
        return (
          <div>
            <div>
              {" "}
              These are the techs owned by{" "}
              <FactionLogo
                factionName={anyPlayerData.faction.name}
              ></FactionLogo>
            </div>
            <div>
              <FactionLogo
                factionName={anyPlayerData.faction.name}
              ></FactionLogo>
              <span>
                {" "}
                owns the following prerequisites :{" "}
                <PrerequisitesView prerequisites={ownedReq}></PrerequisitesView>
              </span>
            </div>
          </div>
        );
      },
    };
  }

  if (showOwnedTechOnly) {
    navigationData.buttonsData.push({
      text: "All",
      callback: () => {
        showOwnedTechOnly = false;
        Popup.addGlobalState("TechTreeView_showOwnedTechOnly", false);
        Popup.touch();
      },
    });
  } else {
    navigationData.buttonsData.push({
      text: "Own",
      callback: () => {
        showOwnedTechOnly = true;
        Popup.addGlobalState("TechTreeView_showOwnedTechOnly", true);
        Popup.touch();
      },
    });
  }

  navigationData.buttonsData.push({
    text: "Back",
    callback: () => {
      Popup.goBack();
    },
  });

  const DisplayTechTree = () => {
    const DisplayTech = ({ tech }) => {
      const textColor = Color.getColorFromTechColor(tech.color);
      if (showOwnedTechOnly && !tech.owned) {
        return null;
      }

      return (
        <span
          style={
            {
              //border: "1px solid white",
              //borderRadius: "5px",
              //margin: "1em",
              //backgroundColor: "blue",
              //maxWidth: "80%",
            }
          }
        >
          <BaseButton
            type={tech.owned ? "gold" : "white"}
            onClick={() => {
              Popup.addLayer({
                name: "TechView",
                tech: tech,
              });
            }}
            //borderStyle={tech.owned ? "3px solid gold" : null}
          >
            <div style={{ textAlign: "left" }}>
              <div
                style={{
                  //color: textColor,
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <span style={{ marginRight: "1em" }}>
                  {" "}
                  <TechTypeView color={tech.color}></TechTypeView>
                  {tech.name}
                </span>

                <span>
                  <span style={{}}>
                    {tech.requirements.map((req, index) => {
                      return (
                        <TechTypeView key={index} color={req}></TechTypeView>
                      );
                    })}
                  </span>
                  <span style={{ whiteSpace: "nowrap" }}>
                    <Logo logoName="science" />
                    {tech.cost.science}
                  </span>
                </span>
              </div>

              {showShortDescription && (
                <div style={{ textAlign: "center" }}>
                  <hr></hr>
                  <div>{tech.shortDescription} </div>
                </div>
              )}
            </div>
          </BaseButton>
        </span>
      );
    };

    const blueTechs = TechTree.getAllTechFromColor(
      techTree,
      Color.COLOR_NAME_TECH_BLUE
    );
    const redTechs = TechTree.getAllTechFromColor(
      techTree,
      Color.COLOR_NAME_TECH_RED
    );
    const greenTechs = TechTree.getAllTechFromColor(
      techTree,
      Color.COLOR_NAME_TECH_GREEN
    );
    const yellowTechs = TechTree.getAllTechFromColor(
      techTree,
      Color.COLOR_NAME_TECH_YELLOW
    );
    const whiteTechs = TechTree.getAllTechFromColor(
      techTree,
      Color.COLOR_NAME_TECH_WHITE
    );

    const DisplayTechsOfColor = ({ techList }) => {
      if (techList.length === 0) {
        return null;
      }
      return (
        <div
          style={
            {
              /* borderWidth: "4 px",
            borderStyle: "solid",
            borderColor: Color.getColorFromTechColor(techList[0].color),
            padding: "1em",
            borderRadius: "10px",
            backgroundColor: Color.getColorFromTechColor(techList[0].color),*/
            }
          }
        >
          <div style={{ fontSize: "2em" }}>
            {" "}
            <TechTypeView color={techList[0].color}></TechTypeView>
          </div>
          <LineSpace></LineSpace>
          {techList.map((tech, index) => {
            return (
              <span key={index} style={{ marginBottom: "0.4em" }}>
                <DisplayTech tech={tech}></DisplayTech>
              </span>
            );
          })}
        </div>
      );
    };

    return (
      <div>
        <div
          style={
            {
              //display: "flex",
              //flexWrap: "wrap",
              //justifyContent: "center",
              //alignItems: "center",
              //gap between rows
              //rowGap: "0em",
            }
          }
        >
          <DisplayTechsOfColor techList={blueTechs}></DisplayTechsOfColor>
          <div style={{ margin: "2em" }}></div>
          <LineSpace></LineSpace> <LineSpace></LineSpace>
          <DisplayTechsOfColor techList={redTechs}></DisplayTechsOfColor>
          <div style={{ margin: "2em" }}></div>
          <DisplayTechsOfColor techList={greenTechs}></DisplayTechsOfColor>
          <div style={{ margin: "2em" }}></div>
          <DisplayTechsOfColor techList={yellowTechs}></DisplayTechsOfColor>
          <div style={{ marginTop: "2em" }}>Uncolored</div>
          <DisplayTechsOfColor techList={whiteTechs}></DisplayTechsOfColor>
        </div>
      </div>
    );
  };

  return (
    <GameGlobalLayout
      title={null}
      backgroundType="shadow"
      rememberScroll={true}
      rememberScrollKey={"TechTreeView"}
    >
      <div
        className="text-box-center"
        style={{
          height: "100%",
          maxWidth: "100%",

          //boxShadow: "inset 0 0 0 4px pink", // Simulate an inner border
        }}
      >
        <DisplayTechTree></DisplayTechTree>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default TechTreeView;
