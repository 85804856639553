const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const ActivationData = require("./ActivationData.js");
const PlanetData = require("../MapData/Planet.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const SpaceObject = require("../MapData/SpaceObject.js");
const LogBook = require("../Connection/LogBook.js");

class InvasionData {
  //Lift Functions

  static prepareStep(playerData) {
    const activeSystem = ActivationData.getActiveSystem(playerData);
    const data = {
      planets: [],
    };
    const ASPlanets = System.getPlanets(activeSystem);
    for (let i = 0; i < ASPlanets.length; i++) {
      let invade = false;
      if (Planet.isFactionInvading(ASPlanets[i], playerData.faction.name)) {
        invade = true;
      }
      data.planets.push({
        name: ASPlanets[i].name,
        invade: invade,
      });
    }
    playerData.activationData.invasionStep = data;
  }

  static getPlanetsData(playerData) {
    return playerData.activationData.invasionStep.planets;
  }

  static getPlanetData(playerData, planet) {
    const planetsData = playerData.activationData.invasionStep.planets;
    for (let i = 0; i < planetsData.length; i++) {
      if (planetsData[i].name === planet.name) {
        return planetsData[i];
      }
    }
    return null;
  }

  static invadePlanet = (playerData, planet) => {
    const planetsData = this.getPlanetsData(playerData);
    for (let i = 0; i < planetsData.length; i++) {
      const planetData = planetsData[i];
      if (planetData.name === planet.name) {
        planetsData[i].invade = true;
      } else {
        planetsData[i].invade = false;
      }
    }
    Popup.touch();
  };

  static removeInvadePlanet = (playerData, planet) => {
    const planetData = this.getPlanetData(playerData, planet);
    planetData.invade = false;
    Popup.touch();
  };

  static resolveInvasion(playerData) {
    const activeSystem = ActivationData.getActiveSystem(playerData);

    let planetToInvade = null;
    let invasionCount = 0;

    const planetsData = this.getPlanetsData(playerData);
    for (let i = 0; i < planetsData.length; i++) {
      if (planetsData[i].invade) {
        invasionCount++;
        planetToInvade = System.getSpaceObjectFromName(
          activeSystem,
          planetsData[i].name
        );

        if (System.doesIncludeObject(activeSystem, planetToInvade) === false) {
          throw new Error("Planet not found in system");
        }

        Planet.addInvade(planetToInvade, playerData.faction.name);
      } else {
        const planetNotToInvade = System.getSpaceObjectFromName(
          activeSystem,
          planetsData[i].name
        );
        Planet.removeInvade(planetNotToInvade, playerData.faction.name);
      }
    }

    if (invasionCount > 1) {
      throw new Error("You can only invade one planet at a time");
    }
    if (invasionCount === 0) {
      return;
    }
  }

  static getInvadedPlanetName(playerData) {
    const planetsData = this.getPlanetsData(playerData);
    for (let i = 0; i < planetsData.length; i++) {
      if (planetsData[i].invade) {
        return planetsData[i].name;
      }
    }

    return null;
  }
}

module.exports = InvasionData;
