import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import SpaceObjectImageView from "../Object/SpaceObjectImageView";
import { getObjectSummaryJSXArray } from "./SpaceObjectInfoArray"; // Adjust the path accordingly
import FleetSummaryView from "./FleetSummaryView";
import FactionLogo from "../Utils/FactionLogo";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const SpaceObject = require("../../Data/GameData/MapData/SpaceObject.js");

const FleetsSummaryView = ({
  fleets,
  unitClass = "all",
  onClick = null,
  superShort = false,
}) => {
  if (!fleets) {
    return null;
  }

  /*if (Fleet.isEmpty(fleets)) {
    return null;
  }*/

  if (superShort) {
    return (
      <div>
        <BaseButton type="transparent" noPadding={true}>
          {fleets.map((fleet, index) => (
            <span key={index}>
              <FleetSummaryView
                fleet={fleet}
                unitClass={unitClass}
                onClick={onClick}
                superShort={superShort}
              ></FleetSummaryView>
            </span>
          ))}
        </BaseButton>
      </div>
    );
  }

  return (
    <div>
      {fleets.map((fleet, index) => (
        <div key={index}>
          <FleetSummaryView
            fleet={fleet}
            unitClass={unitClass}
            onClick={onClick}
            superShort={superShort}
          ></FleetSummaryView>
        </div>
      ))}
    </div>
  );
};

export default FleetsSummaryView;
