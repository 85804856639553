const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const UnitCatalog = require("../Utils/UnitCatalog.js");
const SpaceObject = require("../MapData/SpaceObject.js");
const WarningAction = require("../ActionCommon/WarningAction.js");
const SolveFleetLimitData = require("../MandatoryAction/SolveFleetLimitData.js");
const SolveCapacityData = require("../MandatoryAction/SolveCapacityData.js");
const UnitShopList = require("../Utils/UnitShopList.js");
const Cost = require("../Utils/Cost.js");
const Phase = require("../Game/Phase.js");
const ActionCommonData = require("./ActionCommonData.js");
const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction.js");
const TechTree = require("../Technology/TechTree.js");
const TechList = require("../Technology/TechList.js");
const Law = require("../Law/Law.js");

class BuildUnitActionData {
  static unitsShop = (playerData, planet) => {
    let catalogList = [];
    catalogList = catalogList.concat(
      Unit.getUnitShipsCatalog(playerData, playerData.faction.name)
    );
    catalogList = catalogList.concat(
      Unit.getUnitGroundForcesCatalog(playerData, playerData.faction.name)
    );
    catalogList = catalogList.concat(
      Unit.getUnitStructuresCatalog(playerData, playerData.faction.name)
    );

    //Filter unit not constructable on planet
    if (planet) {
      for (let i = 0; i < catalogList.length; i++) {
        try {
          this.canPlanetConstructUnitType(playerData, planet, catalogList[i]);
        } catch (e) {
          catalogList.splice(i, 1);
          i--;
        }
      }
    }

    const unitShop = { list: [] };
    for (let i = 0; i < catalogList.length; i++) {
      const unit = catalogList[i];
      unitShop.list.push({ unit: unit, amount: 0 });
    }

    return unitShop;
  };

  static modifyOrderAmount(playerData, unitShop, unit, amountP, planet) {
    let amount = amountP;
    const amountUnitsConstructed = this.getOrderUnitAmount(unitShop);
    if (amount > 0) {
      if (
        amountUnitsConstructed + amount >
        Planet.getConstructionValue(playerData, planet)
      ) {
        amount =
          Planet.getConstructionValue(playerData, planet) -
          amountUnitsConstructed;
      }
    }
    for (let i = 0; i < unitShop.list.length; i++) {
      if (unitShop.list[i].unit === unit) {
        unitShop.list[i].amount += amount;
        if (unitShop.list[i].amount < 0) {
          unitShop.list[i].amount = 0;
        }
        return;
      }
    }
  }

  static getOrderCost(playerData, unitShop) {
    const cost = Cost.createCost({});
    for (let i = 0; i < unitShop.list.length; i++) {
      const unit = unitShop.list[i].unit;
      const amount = unitShop.list[i].amount;
      const unitCost = Unit.getCost(playerData, unit);
      Cost.multiplyCost(unitCost, amount);
      Cost.addCostToCost(unitCost, cost);
    }
    return cost;
  }

  static getOrderUnitAmount(unitShop) {
    let amount = 0;
    for (let i = 0; i < unitShop.list.length; i++) {
      amount += unitShop.list[i].amount;
    }
    return amount;
  }

  static prepare(playerData, planet) {
    const data = {
      planetName: planet.name,
      unitShop: this.unitsShop(playerData, planet),
    };

    ActionCommonData.prepare(
      playerData,
      Phase.PHASE_ACTION,
      Phase.STEP_ACTION_BUILD_UNIT,
      data
    );
  }

  static getUnitsProduced(playerData, unitShop) {
    const unitsProduced = [];
    for (let i = 0; i < unitShop.list.length; i++) {
      const unit = unitShop.list[i].unit;
      const amount = unitShop.list[i].amount;
      for (let j = 0; j < amount; j++) {
        unitsProduced.push(
          Unit.createUnit(playerData, playerData.faction.name, unit.type)
        );
      }
    }
    return unitsProduced;
  }

  static warningConstruction(playerData, callback) {
    const data = ActionCommonData.getData(playerData);

    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);

    const constructingSystem = JSON.parse(
      JSON.stringify(Map.getSystemFromSpaceObject(planet, playerData.map))
    );

    const spaceAreaFleet = System.getFleetOrCreate(
      playerData,
      constructingSystem,
      playerData.faction.name
    );

    const unitsProduced = this.getUnitsProduced(playerData, data.unitShop);
    const shipsProduced = unitsProduced.filter(
      (unit) => unit.class === Fleet.UNIT_CLASS_SHIP
    );
    Fleet.addUnits(spaceAreaFleet, shipsProduced);

    WarningAction.warningFleetIsValid(constructingSystem, playerData, callback);

    //planet.hasBuiltUnits = true;
  }

  static resolveClient(playerData) {
    const system = Map.getSystemFromSpaceObject(
      Map.getSpaceObjectFromName(
        playerData.map,
        playerData.actionData.planetName
      ),
      playerData.map
    );

    this.warningConstruction(playerData, () => {
      ActionCommonData.resolveClient(playerData);
    });
    /*const confirmSystemLock = (playerData) => {
      if (!system.hasBeenActivated) {
        UIMessage.displayConfirmMessage(
          "Lock System",
          "Building structure will have the effect of locking the system. This means that no units will be able to move out of the system until next round. Do you want to continue ?",
          () => {
            ActionCommonData.resolveClient(playerData);
          }
        );
      } else {
        ActionCommonData.resolveClient(playerData);
      }

      this.warningFleetIsValid(playerData);
    };*/

    /*confirmSystemLock(playerData, () => {
      ActionCommonData.resolveClient(playerData);
    });*/
  }

  static resolveServer(playerData, resolveData) {
    ActionCommonData.resolveServerIni(playerData, resolveData);

    const data = ActionCommonData.getData(playerData);
    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);

    //Check if planet is producing units
    const unitsProduced = this.getUnitsProduced(playerData, data.unitShop);
    if (unitsProduced.length === 0) {
      return;
    }

    if (planet.faction !== playerData.faction.name) {
      throw new Error(
        "You can't build units on a planet that is not controlled by your faction."
      );
    }

    if (planet.hasBuiltUnits) {
      throw new Error(
        "You can't build units on a planet that has already build units this round."
      );
    }

    //Check Law planetary or orbital demilitarization
    if (Law.getActiveLaw(playerData, Law.NAME_PLANETARY_DEMILITARIZATION_ACT)) {
      //Check if untis contains ground forces
      if (
        unitsProduced.some(
          (unit) => unit.class === Fleet.UNIT_CLASS_GROUND_FORCE
        )
      ) {
        throw new Error(
          "Planetary Demilitarization Act law : you can't build ground forces."
        );
      }
    }
    if (Law.getActiveLaw(playerData, Law.NAME_ORBITAL_DEMILITARIZATION_LAW)) {
      //Check if untis contains ships
      if (unitsProduced.some((unit) => unit.class === Fleet.UNIT_CLASS_SHIP)) {
        throw new Error(
          "Orbital Demilitarization Law : you can't build ships."
        );
      }
    }

    const system = Map.getSystemFromSpaceObject(planet, playerData.map);

    const spaceAreaFleet = System.getFleetOrCreate(
      playerData,
      system,
      playerData.faction.name
    );

    //Spend cost
    const cost = this.getOrderCost(playerData, data.unitShop);
    PlayerData.spendCost(playerData, cost);

    for (let i = 0; i < unitsProduced.length; i++) {
      const unitProduced = unitsProduced[i];
      this.canPlanetConstructUnitType(playerData, planet, unitProduced);
    }

    //Check if enough construction value
    const amountUnitsConstructed = this.getOrderUnitAmount(data.unitShop);
    if (
      amountUnitsConstructed > Planet.getConstructionValue(playerData, planet)
    ) {
      throw new Error(
        "This planet can only construct " +
          planet.constructionValue +
          " units. You are trying to build " +
          amountUnitsConstructed +
          " units."
      );
    }

    const shipsProduced = unitsProduced.filter(
      (unit) => unit.class === Fleet.UNIT_CLASS_SHIP
    );
    const groundForcesProduced = unitsProduced.filter(
      (unit) => unit.class === Fleet.UNIT_CLASS_GROUND_FORCE
    );
    const structuresProduced = unitsProduced.filter(
      (unit) => unit.class === Fleet.UNIT_CLASS_STRUCTURE
    );

    //Check if space area is not controlled by another faction
    if (
      shipsProduced.length > 0 &&
      system.faction &&
      system.faction !== playerData.faction.name
    ) {
      throw new Error(
        "The space area of this system is controlled by : " +
          system.faction +
          ". You can't construct ships in this space area : your planets are blockaded."
      );
    }

    if (shipsProduced.length > 0) {
      MapUnitaryAction.placeUnitsInSystemSA(playerData, system, shipsProduced);
    }

    MapUnitaryAction.placeUnitsOnPlanet(
      playerData,
      planet,
      groundForcesProduced
    );

    MapUnitaryAction.placeUnitsOnPlanet(playerData, planet, structuresProduced);

    planet.hasBuiltUnits = true;
    //system.hasBeenActivated = true;

    const fleetProduced = Fleet.createFleet(
      playerData,
      playerData.faction.name
    );
    Fleet.addUnits(fleetProduced, unitsProduced);

    //Logging
    if (!Fleet.isEmpty(fleetProduced)) {
      if (!Fleet.isEmpty(fleetProduced)) {
        System.generateLogActivity(
          system,
          "$space_object$ built units : $fleet$.",
          [planet, fleetProduced]
        );
      }
      /*System.logActivityToSpaceObject(system, planet, [
          { type: LogMessage.ITEM_TYPE_SPACE_OBJECT, content: planet },
          { content: " built " },
          {
            type: LogMessage.ITEM_TYPE_FLEET,
            content: Fleet.getLogItem(fleetProducing),
          },
        ]);*/
    }

    /*try {
      System.checkLogisticMass(playerData, system);
    } catch (e) {
      SolveFleetLimitData.prepareStep(playerData, system);
    }

    try {
      System.checkCapacity(playerData, system);
    } catch (e) {
      SolveCapacityData.prepareStep(playerData, system);
    }

    ActionCommonData.cleanActionData(playerData);*/
  }

  static canPlanetConstructUnitType(playerData, planet, unit) {
    //Checking that unit requirement are met on planet
    //const structure, the structure used as requirement to build other units
    //How to check if a variable is defined ?

    if (unit.requiredTechnologies) {
      for (let i = 0; i < unit.requiredTechnologies.length; i++) {
        if (!TechTree.hasPlayerTech(playerData, unit.requiredTechnologies[i])) {
          throw new Error(
            "The technology " +
              unit.requiredTechnologies[i] +
              " is required to build a " +
              unit.type +
              "."
          );
        }
      }
    }

    const structuresOnPlanetsFleet = Planet.getFleet(
      planet,
      playerData.faction.name
    );
    const structuresPresentOnPlanet = Fleet.getUnits(
      structuresOnPlanetsFleet,
      Fleet.UNIT_CLASS_STRUCTURE
    );

    //Constructing units
    if (unit.requiredStructures) {
      for (let j = 0; j < unit.requiredStructures.length; j++) {
        const requiredStructure = unit.requiredStructures[j];

        if (
          !structuresPresentOnPlanet.some(
            (structure) => structure.type === requiredStructure
          )
        ) {
          throw new Error(
            "A " +
              requiredStructure +
              " is required on the planet " +
              planet.name +
              " to build a " +
              unit.type +
              "."
          );
        }
      }
    }
  }
}

module.exports = BuildUnitActionData;
