const Color = require("../../../Common/Config/Colors");
const Request = require("../../../Common/Requests/Request");
const StaticGameData = require("../StaticGameData");

class TransactionData {
  static STATE_PROPOSED = "Proposed";
  static STATE_ACCEPTED = "Accepted";

  static create() {
    return {
      transactionCurrentId: 1,
      proposedTransactions: [],
      acceptedTransactions: [],
      rejectedTransactions: [],
    };
  }

  static proposeTransaction(transactionData, transaction) {
    let id = transactionData.transactionCurrentId++;
    transactionData.proposedTransactions.push({
      id: id,
      transaction: transaction,
      acceptedInRound: null,
    });

    return id;
  }

  static acceptTransaction(transactionData, transactionId, round) {
    const transaction = transactionData.proposedTransactions.find(
      (t) => t.id === transactionId
    );
    transaction.acceptedInRound = round;
    transactionData.acceptedTransactions.push(transaction);

    const index = transactionData.proposedTransactions.indexOf(transaction);
    transactionData.proposedTransactions.splice(index, 1);
  }
  static rejectTransaction(transactionData, transactionId, round) {
    // Find the index of the transaction by ID

    const index = transactionData.proposedTransactions.findIndex(
      (t) => t.id === transactionId
    );

    // Proceed only if the transaction is found
    if (index !== -1) {
      const transaction = transactionData.proposedTransactions[index];
      transaction.acceptedInRound = round;
      transactionData.rejectedTransactions.push(transaction);

      // Remove the transaction from proposedTransactions by its index
      transactionData.proposedTransactions.splice(index, 1);
    } else {
      console.warn(
        `Transaction with ID ${transactionId} not found in proposedTransactions.`
      );
    }
  }

  static getProposedTransactions(transactionData) {
    return transactionData.proposedTransactions;
  }

  static getAcceptedTransactions(transactionData) {
    return transactionData.acceptedTransactions;
  }

  static getTransactionFromId(transactionData, transactionId) {
    const fullArray = [
      ...transactionData.proposedTransactions,
      ...transactionData.acceptedTransactions,
      ...transactionData.rejectedTransactions,
    ];
    const transaction = fullArray.find((t) => t.id === transactionId);
    return transaction;
  }

  static getMostRecetTransactionId(transactionData) {
    return transactionData.transactionCurrentId - 1;
  }

  static isTransactionAccepted(transactionData, transactionId) {
    return transactionData.acceptedTransactions.some(
      (t) => t.id === transactionId
    );
  }

  static isTransactionRejected(transactionData, transactionId) {
    return transactionData.rejectedTransactions.some(
      (t) => t.id === transactionId
    );
  }

  static isTransactionProposed(transactionData, transactionId) {
    return transactionData.proposedTransactions.some(
      (t) => t.id === transactionId
    );
  }
}

module.exports = TransactionData;
