const PlayerData = require("../Data/GameData/PlayerData/PlayerData.js");
const Map = require("../Data/GameData/MapData/Map.js");
const System = require("../Data/GameData/MapData/System.js");
const Faction = require("../Data/GameData/PlayerData/Faction.js");
const Game = require("../Data/GameData/Game/Game.js");
const Color = require("../Common/Config/Colors.js");
const UIMessage = require("../Data/GameData/Connection/UIMessage.js");
const Request = require("../Common/Requests/Request.js");
const Phase = require("../Data/GameData/Game/Phase.js");
const StaticGameData = require("../Data/GameData/StaticGameData.js");
const Popup = require("../Data/Other/Popup.js");
const Rules = require("../Data/GameData/Game/Rules.js");
const SystemAction = require("../Data/GameData/ActionCommon/SystemAction.js");
const SelectActionCA = require("./SelectActionCA.js");

class CombatCA {}

module.exports = CombatCA;
