class Cost {
  static createCost({
    mineral = 0,
    science = 0,
    energy = 0,
    influence = 0,
    cargo = 0,
    credit = 0,
    population = 0,
  }) {
    return { mineral, science, energy, influence, cargo, credit, population };
  }
  static multiplyCost(cost, multiplier) {
    cost.mineral *= multiplier;
    cost.science *= multiplier;
    cost.energy *= multiplier;
    cost.influence *= multiplier;
    cost.cargo *= multiplier;
    cost.credit *= multiplier;
    cost.population *= multiplier;
  }

  static addCostToCost(costToAdd, cost) {
    cost.mineral = cost.mineral + costToAdd.mineral;
    cost.science = cost.science + costToAdd.science;
    cost.energy = cost.energy + costToAdd.energy;
    cost.influence = cost.influence + costToAdd.influence;
    cost.cargo = cost.cargo + costToAdd.cargo;
    cost.credit = cost.credit + costToAdd.credit;
    cost.population = cost.population + costToAdd.population;
  }

  //Costs
  static getCargoCost(amount) {
    return this.createCost({ energy: 1 * amount });
  }
  static isEmpty(cost) {
    return (
      cost.mineral === 0 &&
      cost.science === 0 &&
      cost.energy === 0 &&
      cost.influence === 0 &&
      cost.cargo === 0 &&
      cost.credit === 0 &&
      cost.population === 0
    );
  }
}

module.exports = Cost;
