import React, { useState, useEffect } from "react";

import StaticGameData from "../../Data/GameData/StaticGameData";

import PlanetView from "../Object/PlanetView";
import SpaceObjectImageView from "../Object/SpaceObjectImageView";
import BaseButton from "../Utils/BaseButton";
import Logo from "../Utils/Logo";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import ActivationCA from "../../ClientAction/ActivationCA";
import ProductionActionData from "../../Data/GameData/ActionData/ProductionActionData";
import TransferActionData from "../../Data/GameData/ActionData/TransferActionData";
import ResourceBarView from "../Utils/ResourceBarView";
import LogoPopAssigned from "../Utils/LogoPopAssigned";

const SystemAction = require("../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../Common/Requests/Request.js");
const Popup = require("../../Data/Other/Popup");
const System = require("../../Data/GameData/MapData/System.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Rules = require("../../Data/GameData/Game/Rules");
const Cost = require("../../Data/GameData/Utils/Cost");
const Unit = require("../../Data/GameData/MapData/Unit.js");
const UnitCatalog = require("../../Data/GameData/Utils/UnitCatalog.js");
const Map = require("../../Data/GameData/MapData/Map.js");

const ManageProductionView = ({}) => {
  const playerData = StaticGameData.getPlayerData();

  const [transferOn, setTransferOn] = useState(false);
  const [transferInPlanet, setTransferInPlanet] = useState(null);

  const planets = Map.getPlanetsFromFaction(
    playerData.map,
    playerData.faction.name
  );

  //Navigation data
  const navigationData = { buttonsData: [], taskBarData: null };
  navigationData.buttonsData.push({
    text: "Back",
    callback: () => {
      Popup.goBack();
    },
  });
  if (transferOn) {
    navigationData.buttonsData.push({
      text: "Cancel Transfer",
      callback: () => {
        setTransferOn(false);
      },
    });
  }

  const DisplayPlanets = () => {
    return (
      <div>
        {planets.map((planet, index) => {
          const productionButtonStyle = planet.hasProduced
            ? "greyOut"
            : "navigation";

          return (
            <div
              key={index}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                alignItems: "center",
                justifyItems: "center",
                borderBottom: "1px solid white",
                padding: "0.25em",
              }}
            >
              {" "}
              <div>
                <div>
                  <SpaceObjectImageView
                    spaceObject={planet}
                    maxWidth="3em"
                    showFleet={false}
                    showFactionLogo={false}
                  ></SpaceObjectImageView>{" "}
                </div>{" "}
                <div style={{ fontSize: "0.75em" }}>
                  <BaseButton
                    onClick={() => {
                      Popup.addLayer({
                        name: "ManageObjectView",
                        spaceObject: planet,
                      });
                    }}
                  >
                    {planet.name}
                  </BaseButton>{" "}
                </div>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "0.25em",
                }}
              >
                {" "}
                <div style={{ fontSize: "1em", textAlign: "left" }}>
                  <Logo logoName="population"></Logo> {planet.unassignedPop}{" "}
                </div>
                <div>
                  <LogoPopAssigned assignType="popOnMineral"></LogoPopAssigned>{" "}
                  : {planet.popOnMineral}
                </div>{" "}
                <div>
                  {" "}
                  <LogoPopAssigned assignType="popOnScience"></LogoPopAssigned>{" "}
                  : {planet.popOnScience}{" "}
                </div>{" "}
                <div>
                  {" "}
                  <LogoPopAssigned assignType="popOnEnergy"></LogoPopAssigned> :{" "}
                  {planet.popOnEnergy}
                </div>
                <div>
                  {" "}
                  <Logo logoName="mineral"></Logo> : {planet.mineral}
                </div>
              </div>
              <div>
                {" "}
                {!transferOn && (
                  <div>
                    <div style={{ fontSize: "1em" }}>
                      {" "}
                      <BaseButton
                        key="Produce Resources"
                        onClick={() => {
                          if (planet.hasProduced) {
                            Popup.removeActionSelectionAttributes();
                            UIMessage.displayInfoMessage(
                              "Already produced",
                              "This planet already produced during this round. It will be able to produce again next round."
                            );
                          } else {
                            ProductionActionData.prepareStep(
                              playerData,
                              planet
                            );
                          }
                        }}
                        type={productionButtonStyle}
                      >
                        Produce
                      </BaseButton>
                      ,
                    </div>
                    <div style={{ fontSize: "1em" }}>
                      {" "}
                      <BaseButton
                        key="Transfer"
                        onClick={() => {
                          setTransferInPlanet(planet);
                          setTransferOn(true);
                        }}
                      >
                        Transfer
                      </BaseButton>
                    </div>
                  </div>
                )}
                {transferOn && (
                  <BaseButton
                    key="Transfer"
                    onClick={() => {
                      TransferActionData.prepareDirectTransfer(
                        playerData,
                        transferInPlanet,
                        planet
                      );
                    }}
                  >
                    Transfer From
                  </BaseButton>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <GameGlobalLayout>
      <div>
        <DisplayPlanets></DisplayPlanets>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default ManageProductionView;

//
