import React, { useState, useEffect } from "react";
import { GameListView } from "../GameList/GameList";
import TestSimpleComponent from "../Test/TestSimpleComponent";
import StaticGameData from "../Data/GameData/StaticGameData";
import GameGlobalLayout from "../GameView/GameGlobalLayout";
import NavigationView from "../GameView/NavigationView";
import MenuNavigationView from "./MenuNavigationView";
import BaseButton from "../GameView/Utils/BaseButton";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import Popup from "../Data/Other/Popup";
import ResetPasswordFromGame from "../login/ResetPasswordFromGame";
import { callPostAPI } from "../Common/Requests/Request";
import EloRanking from "../Data/GameData/Objectifs/EloRanking";
import User from "../Data/UserData/User";
import StatisticView from "./StatisticView";
import Color from "../Common/Config/Colors";

export const ProfileView = () => {
  const [user, setUser] = useState(null);

  // Function to fetch user data
  const fetchUser = async () => {
    try {
      const userData = await callPostAPI({}, "get-self-user");
      setUser(userData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Use effect to call fetchUser when the component mounts
  useEffect(() => {
    fetchUser();
  }, []);

  // Render user data or a loading message

  const customGameEloRating = user ? EloRanking.getCustomGameElo(user) : "...";
  const rankedGameEloRating = user ? EloRanking.getRankedGameElo(user) : "...";
  const ongoingGameList = user ? User.getOngoingGames(user) : "...";
  const finishedGameList = user ? User.getFinishedGames(user) : "...";

  return (
    <div>
      <h1>Profile</h1>
      <div>Username : {StaticGameData.username}</div>
      <LineSpace></LineSpace>
      <div>Games ongoing : {ongoingGameList.length}</div>
      <div>Games finished : {finishedGameList.length}</div>
      <LineSpace></LineSpace>
      <div style={{ color: "gold" }}>
        Elo rating ranked game : {rankedGameEloRating}
      </div>{" "}
      <div style={{ color: Color.COLOR_CYAN }}>
        Elo rating custom game : {customGameEloRating}
      </div>{" "}
      <LineSpace></LineSpace>
      <BaseButton
        onClick={() => {
          Popup.addDynamicLayer(<StatisticView user={user}></StatisticView>);
        }}
      >
        Statistics
      </BaseButton>{" "}
      <LineSpace></LineSpace>
      <BaseButton
        onClick={() => {
          Popup.addDynamicLayer(
            <ResetPasswordFromGame></ResetPasswordFromGame>
          );
        }}
      >
        Change Password
      </BaseButton>
    </div>
  );
};

export default ProfileView;
