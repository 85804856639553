import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import FactionLogo from "../Utils/FactionLogo";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import CostView from "../Utils/CostView";
import Logo from "../Utils/Logo";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const SpaceObject = require("../../Data/GameData/MapData/SpaceObject.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const TechTree = require("../../Data/GameData/Technology/TechTree.js");

const Tech = require("../../Data/GameData/Technology/Tech.js");

const TechTypeView = ({ color }) => {
  let logoName = null;

  if (color === Color.COLOR_NAME_TECH_BLUE) {
    logoName = "tech blue";
  }
  if (color === Color.COLOR_NAME_TECH_RED) {
    logoName = "tech red";
  }
  if (color === Color.COLOR_NAME_TECH_YELLOW) {
    logoName = "tech yellow";
  }
  if (color === Color.COLOR_NAME_TECH_GREEN) {
    logoName = "tech green";
  }

  if (!logoName) {
    return;
  }

  return <Logo logoName={logoName}></Logo>;
};

export default TechTypeView;
