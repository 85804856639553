import React, { useState, useEffect } from "react";
import ActivationViewLayout from "./ActivationViewLayout";
import ActivationNavigationView from "./ActivationNavigationView";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import PlanetViewActivation from "./Objects/PlanetViewActivation";
import Logo from "../../Utils/Logo";
import ActivationTransferInView from "./ActivationTransferInView";
import ActivationTransferView from "./ActivationTransferView";
import ActivationCA from "../../../ClientAction/ActivationCA";
import TransferData from "../../../Data/GameData/Activation/TransferData";
import ExpandableView from "../../Utils/Layout/ExpandableView";
import SelectionListView from "../../Utils/Layout/SelectionListView";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Color = require("../../../Common/Config/Colors.js");
const Map = require("../../../Data/GameData/MapData/Map.js");
const ProductionData = require("../../../Data/GameData/Activation/ProductionData.js");
const Planet = require("../../../Data/GameData/MapData/Planet.js");
const DropData = require("../../../Data/GameData/Activation/DropData.js");

const ActivationDropView = ({ system, navigationData }) => {
  console.log("RENDER ActivationLiftView ");
  const playerData = StaticGameData.getPlayerData();

  const data = DropData.getData(playerData);

  const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);

  const spaceAreaFleet = Fleet.createFleet(playerData, playerData.faction.name);
  const systemFleet = System.getFleet(system, playerData.faction.name);
  Fleet.addFleetToFleet(systemFleet, spaceAreaFleet);
  Fleet.removeFleetFromFleet(data.fleetDropped, spaceAreaFleet);

  navigationData.buttonsData.push(
    {
      text: "Help",
      callback: () => {
        Popup.addLayer({ name: "HelpPopup", helpReference: "dropStep" });
      },
    },
    {
      text: "No Drop",
      callback: () => {
        ActivationCA.initialize_STEP_ACTIVATION_ACTIVATED();
      },
      type: "action",
    },
    {
      text: "Confirm",
      callback: () => {
        const lift = () => {
          ActivationCA.resolve_STEP_ACTIVATION_DROP("drop");
        };

        UIMessage.displayConfirmMessage(
          "Drop ?",
          "Are you sure you want to drop these units ?",
          lift
        );
      },
      type: "action",
    }
  );

  const DisplaySpaceArea = ({ system }) => {
    return (
      <div className="text-box">
        <div>Fleet in space : </div>
        <FleetSummaryView fleet={spaceAreaFleet}></FleetSummaryView>

        <hr></hr>
      </div>
    );
  };

  const DisplayPlanets = () => {
    const planetFleet = Fleet.createFleet(playerData, playerData.faction.name);
    Fleet.addFleetToFleet(data.fleetDropped, planetFleet);

    const systemFleet = System.getFleet(system, playerData.faction.name);
    const remainingToPickFleet = Fleet.createFleet(
      playerData,
      playerData.faction.name
    );
    Fleet.addFleetToFleet(systemFleet, remainingToPickFleet);

    //const collapsedChildren = () => {};

    const groundForces = Fleet.getUnits(
      remainingToPickFleet,
      Fleet.UNIT_CLASS_GROUND_FORCE
    );
    const droppedGroundForces = Fleet.getUnits(
      data.fleetDropped,
      Fleet.UNIT_CLASS_GROUND_FORCE
    );
    const displayItem = (item) => {
      return (
        <UnitLineView
          unit={item}
          factionName={playerData.faction.name}
        ></UnitLineView>
      );
    };
    const selectedItems = droppedGroundForces;
    const selectItem = (item) => {
      Fleet.addUnit(data.fleetDropped, item);
      Popup.touch();
    };

    const unSelectItem = (item) => {
      Fleet.removeUnit(data.fleetDropped, item);
      Popup.touch();
    };

    return (
      <div>
        <PlanetViewActivation
          spaceObject={planet}
          showStock={true}
        ></PlanetViewActivation>
        <div>
          Dropping : <FleetSummaryView fleet={planetFleet}></FleetSummaryView>
        </div>
        <SelectionListView
          items={groundForces}
          displayItem={displayItem}
          selectedItems={selectedItems}
          selectItem={selectItem}
          unSelectItem={unSelectItem}
          textSelected="Stay"
          textUnselected="Drop"
        ></SelectionListView>
        <hr></hr>
      </div>
    );
  };

  return (
    <div style={{}}>
      <DisplaySpaceArea system={system}></DisplaySpaceArea>
      <DisplayPlanets></DisplayPlanets>
    </div>
  );
};

export default ActivationDropView;
