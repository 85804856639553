import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo";

import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import TechTree from "../../Data/GameData/Technology/TechTree";
import TechButtonView from "../Technology/TechButtonView";
import Color from "../../Common/Config/Colors";
import TechTypeView from "../Technology/TechTypeView";
import Logo from "../Utils/Logo";
import Item from "../../Data/GameData/Transactions/Item";
import ItemView from "../Transaction/ItemView";
import ItemDetailedView from "../Transaction/ItemDetailedView";
import FactionAbility from "../../Data/GameData/Faction/FactionAbility";
import Faction from "../../Data/GameData/PlayerData/Faction";
import AbilityCard from "../../Data/GameData/Ability/AbilityCard";
import FactionAbilityDetailedView from "../Faction/FactionAbilityDetailedView";
import PlayerEffect from "../../Data/GameData/Faction/PlayerEffect";
import LineSpace from "../Utils/Layout/LineSpace";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");

const AllAbilitiesView = ({ playerDataToDisplay }) => {
  const navigationData = {
    buttonsData: [
      {
        text: "Back",
        callback: () => {
          Popup.goBack();
        },
      },
    ],
    isMapStandardNavigationData: false,
  };

  const techTree = playerDataToDisplay.techTree;
  const techs = TechTree.getAllTechs(techTree).filter((tech) => {
    return tech.owned;
  });

  const factionAbilityCards = Faction.getAbilityCards(
    playerDataToDisplay.faction
  );
  const abilityDescList = factionAbilityCards.map((abilityCard) => {
    return FactionAbility.getAbilityCardDesc(abilityCard);
  });

  const effectList = PlayerEffect.getEffectList(playerDataToDisplay);
  const effectDescList = effectList.map((effect) => {
    return PlayerEffect.getAbilityCardDesc(effect);
  });

  const relics = Item.getRelicsFromItems(playerDataToDisplay.items).filter(
    (relic) => {
      return relic.data.faction !== playerDataToDisplay.faction.name;
    }
  );
  const artifacts = Item.getArtifactsFromItems(playerDataToDisplay.items);

  const DisplayItemsAbilities = ({ items }) => {
    const DisplayItem = ({ item }) => {
      const itemDesc = Item.getDescription(item);
      return (
        <div>
          <BaseButton
            type={
              !item.isExhausted && Item.hasAnActiveAbility(itemDesc)
                ? "white"
                : "navigation"
            }
            onClick={() => {
              Popup.addDynamicLayer(
                <ItemDetailedView
                  playerId={PlayerData.getPlayerId(playerDataToDisplay)}
                  itemId={item.id}
                ></ItemDetailedView>
              );
            }}
          >
            <ItemView item={item} exhaustColorChange={false}></ItemView>
          </BaseButton>
        </div>
      );
    };

    return (
      <div>
        {items.map((item, key) => (
          <div key={key}>
            <DisplayItem item={item}></DisplayItem>
          </div>
        ))}
      </div>
    );
  };

  const DisplayRelics = () => {
    if (relics.length === 0) {
      return;
    }

    return (
      <div>
        <div style={{ textDecoration: "underline" }}>Relics : </div>
        <DisplayItemsAbilities items={relics}></DisplayItemsAbilities>
      </div>
    );
  };

  const DisplayArtifacts = () => {
    if (artifacts.length === 0) {
      return;
    }

    return (
      <div>
        <div style={{ textDecoration: "underline" }}>Artifacts : </div>
        <DisplayItemsAbilities items={artifacts}></DisplayItemsAbilities>
      </div>
    );
  };

  const DisplayTechsAbilities = () => {
    if (techs.length === 0) {
      return;
    }

    const DisplayTech = ({ tech }) => {
      const textColor = Color.getColorFromTechColor(tech.color);

      return (
        <div style={{}}>
          <BaseButton
            type={tech.active && !tech.isExhausted ? "white" : "navigation"}
            onClick={() => {
              Popup.addLayer({
                name: "TechView",
                tech: tech,
              });
            }}
          >
            <div style={{ textAlign: "left" }}>
              <div
                style={{
                  //color: textColor,
                  display: "grid",
                  gridTemplateColumns: "auto auto auto auto",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <TechTypeView color={tech.color}></TechTypeView>
                <span style={{ marginRight: "2em" }}> {tech.name}</span>
                <span style={{ color: "white" }}>
                  {tech.requirements.map((req, index) => {
                    return (
                      <TechTypeView key={index} color={req}></TechTypeView>
                    );
                  })}
                </span>
              </div>

              {false && (
                <div style={{ textAlign: "center" }}>
                  {tech.shortDescription}
                </div>
              )}
            </div>
          </BaseButton>
        </div>
      );
    };

    return (
      <div>
        <div style={{ textDecoration: "underline" }}>Techs : </div>
        {techs.map((tech, key) => (
          <div key={key}>
            <DisplayTech tech={tech}></DisplayTech>
          </div>
        ))}
      </div>
    );
  };

  const DisplayFactionAbilities = () => {
    if (abilityDescList.length === 0) {
      return;
    }

    const DisplayAbilityDesc = ({ abilityDesc }) => {
      const isActive = AbilityCard.isActiveAbility(abilityDesc);

      return (
        <div style={{}}>
          <BaseButton
            type={
              isActive && !AbilityCard.isExhausted(abilityDesc)
                ? "white"
                : "navigation"
            }
            onClick={() => {
              Popup.addDynamicLayer(
                <FactionAbilityDetailedView
                  playerId={PlayerData.getPlayerId(playerDataToDisplay)}
                  abilityCardDesc={abilityDesc}
                ></FactionAbilityDetailedView>
              );
            }}
          >
            <span>{abilityDesc.name}</span>
          </BaseButton>
        </div>
      );
    };

    return (
      <div>
        <div style={{ textDecoration: "underline" }}>Faction Abilities : </div>
        {abilityDescList.map((abilityDesc, key) => (
          <div key={key}>
            <DisplayAbilityDesc abilityDesc={abilityDesc}></DisplayAbilityDesc>
          </div>
        ))}
      </div>
    );
  };

  const DisplayEffects = () => {
    //console.log("DEBUG effectDescList : ", effectDescList);
    if (effectDescList.length === 0) {
      return;
    }

    const DisplayEffectDesc = ({ effectDesc }) => {
      const isActive = AbilityCard.isActiveAbility(effectDesc);

      return (
        <div style={{}}>
          <BaseButton
            type={
              isActive && !AbilityCard.isExhausted(effectDesc)
                ? "white"
                : "navigation"
            }
            onClick={() => {
              Popup.addDynamicLayer(
                <FactionAbilityDetailedView
                  playerId={PlayerData.getPlayerId(playerDataToDisplay)}
                  abilityCardDesc={effectDesc}
                ></FactionAbilityDetailedView>
              );
            }}
          >
            <span>{effectDesc.name}</span>
          </BaseButton>
        </div>
      );
    };

    return (
      <div>
        <div style={{ textDecoration: "underline" }}>Active Effects : </div>
        {effectDescList.map((effectDesc, key) => (
          <div key={key}>
            <DisplayEffectDesc effectDesc={effectDesc}></DisplayEffectDesc>
          </div>
        ))}
      </div>
    );
  };

  return (
    <GameGlobalLayout
      showResourceBarInTitle={false}
      titleComponent={
        <span>
          Abilities of :{" "}
          <FactionLogo
            factionName={playerDataToDisplay.faction.name}
          ></FactionLogo>
        </span>
      }
    >
      <div className="text-box" style={{ textAlign: "center" }}>
        {" "}
        <DisplayEffects></DisplayEffects>
        <LineSpace></LineSpace>
        <DisplayTechsAbilities></DisplayTechsAbilities>
        <LineSpace></LineSpace>
        <DisplayRelics></DisplayRelics>
        <LineSpace></LineSpace>
        <DisplayArtifacts></DisplayArtifacts>
        <LineSpace></LineSpace>
        <DisplayFactionAbilities></DisplayFactionAbilities>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default AllAbilitiesView;
