const Fleet = require("../MapData/Fleet");
const CustomMath = require("../../../Common/Math/CustomMath");
const ResolveData = require("../EndOfRound/ResolveData");
const GameGlobalFunctions = require("../../Other/GameGlobalFunctions");
const CombatFactionData = require("./CombatFactionData");

class CombatPack {
  static create(
    combatFunctions,
    combatData,
    recData,
    resolveData,
    system,
    planet = null,
    map
  ) {
    const data = {
      combatFunctions: combatFunctions,
      combatData: combatData,
      recData: recData,
      resolveData: resolveData,
      system: system,
      planet: planet,
      map: map,
      DLog: null,
      Hlog: null,
    };

    return data;
  }

  static getCombatFunctions(combatPack) {
    return combatPack.combatFunctions;
  }

  static getCombatData(combatPack) {
    return combatPack.combatData;
  }

  static getRecData(combatPack) {
    return combatPack.recData;
  }

  static getResolveData(combatPack) {
    return combatPack.resolveData;
  }

  static getSystem(combatPack) {
    return combatPack.system;
  }

  static getPlanet(combatPack) {
    return combatPack.planet;
  }

  static getMap(combatPack) {
    return combatPack.map;
  }

  static getDLog(combatPack) {
    return combatPack.DLog;
  }

  static setDLog(combatPack, DLog) {
    combatPack.DLog = DLog;
  }

  static getHLog(combatPack) {
    return combatPack.HLog;
  }

  static setHLog(combatPack, HLog) {
    combatPack.HLog = HLog;
  }
}

module.exports = CombatPack;
