const { desktopNames } = require("browserslist");
const CustomMath = require("../../../Common/Math/CustomMath");
const LogBook = require("../Connection/LogBook");

class Law {
  /*
    Obsolescence Mandate
    Stellar Nexus Act
    Health Reform
    Rift Protection Act
    Antiquity Preservation

    Unified Research Accord
    Mineral Allocation Directive
    Energy Allocation Directive

    Mining Regulation
    Research Regulation
    Energy Regulation
    Population Regulation

    Mineral Conservation Act
    Science Conservation Act
    Energy Conservation Act
    Population Conservation Act
    
    Wealth Equalization
    Demographic Synchronization

    Research Taxation

    Subspace Storm Initiative

    Space Resource Amplification

    Void Disturbance Act (random ships are damaged)

    Maintenance Cycle Mandate (strucutres are deactivated)

    Free Trade Accord

    Galactic Culling Protocol

    Hell Moon Doctrine

    Planetary Demilitarization Act

    Orbital Demilitarization Law

    Fleet Decentralization Policy

    */

  static NAME_OBSEOLESCENCE_MANDATE = "Obsolescence Mandate";
  static NAME_EXPERIMENTAL_UPGRADE_PROGRAM = "Experimental Upgrade Program";

  static NAME_STELLAR_NEXUS_ACT = "Stellar Nexus Act";
  static NAME_HEALTH_REFORM = "Health Reform";
  static NAME_RIFT_PROTECTION_ACT = "Rift Protection Act";
  static NAME_ANTIQUITY_PRESERVATION = "Antiquity Preservation";

  static NAME_UNIFIED_RESEARCH_ACCORD = "Unified Research Accord";
  static NAME_MINERAL_ALLOCATION_DIRECTIVE = "Mineral Allocation Directive";
  static NAME_ENERGY_ALLOCATION_DIRECTIVE = "Energy Allocation Directive";

  static NAME_MINING_REGULATION = "Mining Regulation";
  static NAME_RESEARCH_REGULATION = "Research Regulation";
  static NAME_ENERGY_REGULATION = "Energy Regulation";
  static NAME_POPULATION_REGULATION = "Population Regulation";

  static NAME_MINERAL_CONSERVATION_ACT = "Mineral Conservation Act";
  static NAME_SCIENCE_CONSERVATION_ACT = "Science Conservation Act";
  static NAME_ENERGY_CONSERVATION_ACT = "Energy Conservation Act";
  static NAME_POPULATION_CONSERVATION_ACT = "Population Conservation Act";

  static NAME_ISOLATIONISM_DIRECTIVE = "Isolationism Directive";

  static NAME_WEALTH_EQUALIZATION = "Wealth Equalization";
  static NAME_DEMOGRAPHIC_SYNCHRONIZATION = "Demographic Synchronization";

  static NAME_RESEARCH_TAXATION = "Research Taxation";

  static NAME_SUBSPACE_STORM_INITIATIVE = "Subspace Storm Initiative";

  static NAME_SPACE_RESOURCE_AMPLIFICATION = "Space Resource Amplification";

  static NAME_VOID_DISTURBANCE_ACT = "Void Disturbance Act";

  static NAME_MAINTENANCE_CYCLE_MANDATE = "Maintenance Cycle Mandate";

  static NAME_FREE_TRADE_ACCORD = "Free Trade Accord";

  static NAME_GALACTIC_CULLING_PROTOCOL = "Galactic Culling Protocol";

  static NAME_HELL_MOON_DOCTRINE = "Hell Moon Doctrine";

  static NAME_PLANETARY_DEMILITARIZATION_ACT = "Planetary Demilitarization Act";

  static NAME_ORBITAL_DEMILITARIZATION_LAW = "Orbital Demilitarization Law";

  static NAME_FLEET_DECENTRALIZATION_POLICY = "Fleet Decentralization Policy";

  static create = (name, values) => {
    return {
      name: name,
      values: values,
    };
  };

  static getLawNameDeck = () => {
    const nameDeck = [
      this.NAME_OBSEOLESCENCE_MANDATE,
      this.NAME_EXPERIMENTAL_UPGRADE_PROGRAM,
      this.NAME_STELLAR_NEXUS_ACT,
      this.NAME_HEALTH_REFORM,
      this.NAME_RIFT_PROTECTION_ACT,
      this.NAME_ANTIQUITY_PRESERVATION,
      this.NAME_UNIFIED_RESEARCH_ACCORD,
      this.NAME_MINERAL_ALLOCATION_DIRECTIVE,
      this.NAME_ENERGY_ALLOCATION_DIRECTIVE,
      this.NAME_POPULATION_CONSERVATION_ACT,
      this.NAME_MINING_REGULATION,
      this.NAME_RESEARCH_REGULATION,
      this.NAME_ENERGY_REGULATION,
      this.NAME_POPULATION_REGULATION,
      this.NAME_ISOLATIONISM_DIRECTIVE,
      this.NAME_WEALTH_EQUALIZATION,
      this.NAME_RESEARCH_TAXATION,
      this.NAME_SPACE_RESOURCE_AMPLIFICATION,
      //this.NAME_VOID_DISTURBANCE_ACT,
      this.NAME_MAINTENANCE_CYCLE_MANDATE,
      this.NAME_FREE_TRADE_ACCORD,
      this.NAME_GALACTIC_CULLING_PROTOCOL,
      this.NAME_HELL_MOON_DOCTRINE,
      this.NAME_PLANETARY_DEMILITARIZATION_ACT,
      this.NAME_ORBITAL_DEMILITARIZATION_LAW,
      this.NAME_FLEET_DECENTRALIZATION_POLICY,
    ];

    return nameDeck;
  };

  static createDescription(law) {
    switch (law.name) {
      case Law.NAME_OBSEOLESCENCE_MANDATE:
        return {
          name: Law.NAME_OBSEOLESCENCE_MANDATE,
          shortDescription: law.values.type + " -1 damages, -2 capacity",
          description:
            law.values.type +
            " ships deal -1 damages and have -2 capacity (minimum 0).",
        };
      case Law.NAME_EXPERIMENTAL_UPGRADE_PROGRAM:
        return {
          name: Law.NAME_EXPERIMENTAL_UPGRADE_PROGRAM,
          shortDescription: law.values.type + "+0.5 damages, +1 capacity",
          description:
            law.values.type + " ships deal +0.5 damages and have +1 capacity.",
        };
      case Law.NAME_STELLAR_NEXUS_ACT:
        return {
          name: Law.NAME_STELLAR_NEXUS_ACT,
          shortDescription: "Ships +1 range.",
          description: "All ships have +1 range.",
        };

      case Law.NAME_HEALTH_REFORM:
        return {
          name: Law.NAME_HEALTH_REFORM,
          shortDescription:
            "Infantry removed from " +
            law.values.type1 +
            " and " +
            law.values.type2 +
            " planets",
          description:
            "At the beginning of the round, all infantries are removed from " +
            law.values.type1 +
            " and " +
            law.values.type2 +
            " planets.",
        };
      case Law.NAME_RIFT_PROTECTION_ACT:
        return {
          name: Law.NAME_RIFT_PROTECTION_ACT,
          shortDescription: "Units dies in wormholes",
          description:
            "At the beginning of the round, all units in the space area of a system containing at least one wormhole are removed. Wormholes can't be used for movement.",
        };
      case Law.NAME_ANTIQUITY_PRESERVATION:
        return {
          name: Law.NAME_ANTIQUITY_PRESERVATION,
          shortDescription: "Can't research tech",
          description: "Players can't research technology.",
        };

      case Law.NAME_UNIFIED_RESEARCH_ACCORD:
        return {
          name: Law.NAME_UNIFIED_RESEARCH_ACCORD,
          shortDescription: "Each player has " + law.values.amount + " science",
          description:
            "At the beginning of the round, science of each player is set to " +
            law.values.amount +
            ". Planets do not produce science.",
        };
      case Law.NAME_MINERAL_ALLOCATION_DIRECTIVE:
        return {
          name: Law.NAME_MINERAL_ALLOCATION_DIRECTIVE,
          shortDescription: "Each player has " + law.values.amount + " mineral",
          description:
            "At the beginning of the round, mineral of each player is set to " +
            law.values.amount +
            ". Planets do not produce minerals.",
        };
      case Law.NAME_ENERGY_ALLOCATION_DIRECTIVE:
        return {
          name: Law.NAME_ENERGY_ALLOCATION_DIRECTIVE,
          shortDescription: "Each player has " + law.values.amount + " energy",
          description:
            "At the beginning of the round, energy of each player is set to " +
            law.values.amount +
            ". Planets do not produce energy.",
        };

      case Law.NAME_POPULATION_CONSERVATION_ACT:
        return {
          name: Law.NAME_POPULATION_CONSERVATION_ACT,
          shortDescription:
            "Each player has " + law.values.amount + " population",
          description:
            "At the beginning of the round, population of each player is set to " +
            law.values.amount +
            ". Planets do not produce population.",
        };

      case Law.NAME_MINING_REGULATION:
        return {
          name: Law.NAME_MINING_REGULATION,
          shortDescription:
            "Planets base mineral production max " + law.values.amount + "",
          description:
            "Existing planets, if they have a base mineral production of more than " +
            law.values.amount +
            ", have their base mineral production set to " +
            law.values.amount +
            " minerals, until the end of the game.",
        };

      case Law.NAME_RESEARCH_REGULATION:
        return {
          name: Law.NAME_RESEARCH_REGULATION,
          shortDescription:
            "Planets base research production max " + law.values.amount + "",
          description:
            "Existing planets, if they have a base science production of more than " +
            law.values.amount +
            ", have their base science production set to " +
            law.values.amount +
            " science, until the end of the game.",
        };

      case Law.NAME_ENERGY_REGULATION:
        return {
          name: Law.NAME_ENERGY_REGULATION,
          shortDescription:
            "Planets base energy production max " + law.values.amount + "",
          description:
            "Existing planets, if they have a base energy production of more than " +
            law.values.amount +
            ", have their base energy production set to " +
            law.values.amount +
            " energy, until the end of the game.",
        };

      case Law.NAME_POPULATION_REGULATION:
        return {
          name: Law.NAME_POPULATION_REGULATION,
          shortDescription:
            "Planets base population production max " + law.values.amount + "",
          description:
            "Existing planets, if they have a base population production of more than " +
            law.values.amount +
            ", have their base population production set to " +
            law.values.amount +
            " population, until the end of the game.",
        };

      case Law.NAME_ISOLATIONISM_DIRECTIVE:
        return {
          name: Law.NAME_ISOLATIONISM_DIRECTIVE,
          shortDescription: "Can't buy",
          description:
            "Players can't buy resources with credits. At the end of the round, players loses all their credits.",
        };

      /* case Law.NAME_MINERAL_CONSERVATION_ACT:
        return "Mineral Conservation Act";
      case Law.NAME_SCIENCE_CONSERVATION_ACT:
        return "Science Conservation Act";
      case Law.NAME_ENERGY_CONSERVATION_ACT:
        return "Energy Conservation Act";
      case Law.NAME_POPULATION_CONSERVATION_ACT:
        return "Population Conservation Act";*/

      case Law.NAME_WEALTH_EQUALIZATION:
        return {
          name: Law.NAME_WEALTH_EQUALIZATION,
          shortDescription:
            "Each players has " + law.values.amount + " credits",
          description:
            "At the beginning of the round, credit of each player is set to " +
            law.values.amount +
            ".",
        };

      /*case Law.NAME_DEMOGRAPHIC_SYNCHRONIZATION:
        return "Demographic Synchronization";*/

      case Law.NAME_RESEARCH_TAXATION:
        return {
          name: Law.NAME_RESEARCH_TAXATION,
          shortDescription: "Research cost increased by " + law.values.amount,
          description:
            "All research cost are increased by " +
            law.values.amount +
            " science, until the end of the game.",
        };

      /*case Law.NAME_SUBSPACE_STORM_INITIATIVE:
        return "Subspace Storm Initiative";*/

      case Law.NAME_SPACE_RESOURCE_AMPLIFICATION:
        return {
          name: Law.NAME_SPACE_RESOURCE_AMPLIFICATION,
          shortDescription: "Resources mined in space are doubled",
          description:
            "Resource gained from mineral field, uterium dust and flux anomaly are doubled.",
        };

      case Law.NAME_VOID_DISTURBANCE_ACT:
        return {
          name: Law.NAME_VOID_DISTURBANCE_ACT,
          shortDescription: "Ships takes damages",
          description:
            "At the beginning of the round, each ship's health is set to a random percentage of its remaining health, minimum 1.",
        };

      case Law.NAME_MAINTENANCE_CYCLE_MANDATE:
        return {
          name: Law.NAME_MAINTENANCE_CYCLE_MANDATE,
          shortDescription: "Structures deactivated",
          description:
            "Planetary canon do not use their PLANETARY CANON DEFENSE and their SPACE CANON DEFENSE abilities. Structures do not generate any morale.",
        };

      case Law.NAME_FREE_TRADE_ACCORD:
        return {
          name: Law.NAME_FREE_TRADE_ACCORD,
          shortDescription: "Factions receives trade 1",
          description:
            "At the beginning of the round, each player receives a trade 1 relic produced by their own faction.",
        };

      case Law.NAME_GALACTIC_CULLING_PROTOCOL:
        return {
          name: Law.NAME_GALACTIC_CULLING_PROTOCOL,
          shortDescription: "Infantries dies",
          description:
            "At the beginning of the round, all infantries in space are destroyed and all infantries on planets are destroyed, except for 2 per planet.",
        };

      case Law.NAME_HELL_MOON_DOCTRINE:
        return {
          name: Law.NAME_HELL_MOON_DOCTRINE,
          shortDescription: "Hell Moon Gift",
          description:
            "Each player receives an artifact which can create a Hell Moon in a system they control containing a planet they control.",
        };

      case Law.NAME_PLANETARY_DEMILITARIZATION_ACT:
        return {
          name: Law.NAME_PLANETARY_DEMILITARIZATION_ACT,
          shortDescription: "Ground forces cannot be built",
          description: "Players can't build ground forces.",
        };

      case Law.NAME_ORBITAL_DEMILITARIZATION_LAW:
        return {
          name: Law.NAME_ORBITAL_DEMILITARIZATION_LAW,
          shortDescription: "Ships cannot be built",
          description: "Players can't build ships.",
        };

      case Law.NAME_FLEET_DECENTRALIZATION_POLICY:
        return {
          name: Law.NAME_FLEET_DECENTRALIZATION_POLICY,
          shortDescription: "Fleet limit decreases by " + law.values.amount,
          description:
            "At the beginning of the round, the fleet limit of each player is reduced by " +
            law.values.amount +
            ", minimum 1.",
        };

      default:
        return "Unknown Law";
    }
  }

  static getLawData(playerData) {
    if (!playerData.lawData) {
      playerData.lawData = {};
    }
    return playerData.lawData;
  }

  static getActiveLaw(playerData, lawName) {
    const activeLaws = Law.getActiveLaws(playerData);

    return activeLaws.find((law) => law.name === lawName);
  }

  static getActiveLaws(playerData) {
    //testing
    /*return [
      {
        name: Law.NAME_ORBITAL_DEMILITARIZATION_LAW,
        values: { type: "Destroyer" },
      },
    ];*/

    const lawData = Law.getLawData(playerData);
    if (!lawData.laws) {
      lawData.laws = [];
    }
    return lawData.laws;
  }

  static addActiveLaw(playerData, law) {
    const activeLaws = Law.getActiveLaws(playerData);
    activeLaws.push(law);
  }

  static resetActiveLaws(playerData) {
    const lawData = Law.getLawData(playerData);
    lawData.laws = [];
  }

  static getVotingLaws(playerData) {
    const lawData = Law.getLawData(playerData);
    if (!lawData.votingLaws) {
      lawData.votingLaws = [];
    }

    return lawData.votingLaws;
  }

  static addVotingLaw(playerData, law) {
    const votingLaws = Law.getVotingLaws(playerData);

    votingLaws.push(law);
  }

  static getVotingLaw(playerData, lawName) {
    const votingLaws = Law.getVotingLaws(playerData);
    return votingLaws.find((law) => law.name === lawName);
  }

  static resetVotingLaws(playerData) {
    const lawData = Law.getLawData(playerData);

    lawData.votingLaws = [];
  }

  static resetPlayerVote(playerData) {
    const lawData = Law.getLawData(playerData);

    lawData.playerVote = null;
  }

  static getPlayerVote(playerData) {
    const lawData = Law.getLawData(playerData);
    if (!lawData.playerVote) {
      lawData.playerVote = null;
    }
    return lawData.playerVote;
  }

  static setPlayerVote(playerData, lawName) {
    const lawData = Law.getLawData(playerData);
    lawData.playerVote = lawName;
  }

  static getLawLog(playerData) {
    const lawData = Law.getLawData(playerData);
    if (!lawData.logBook) {
      lawData.logBook = LogBook.createLogBook();
    }
    return lawData.logBook;
  }

  static addLog(playerData, text, items = []) {
    const logBook = Law.getLawLog(playerData);
    LogBook.generateAddMessage(logBook, text, items);
  }

  static appendLog(playerData, logbookP) {
    const logBook = Law.getLawLog(playerData);
    LogBook.concat(logbookP, logBook);
  }

  static generateLog(playerData, text, items = []) {
    const logBook = Law.getLawLog(playerData);
    LogBook.generateAddMessage(logBook, text, items);
  }
}

module.exports = Law;
