const EloRank = require("elo-rank");
const User = require("../../UserData/User");
const Game = require("../Game/Game");
const EndGameRanking = require("./EndGameRanking");
const CustomMath = require("../../../Common/Math/CustomMath");

class EloRanking {
  static getEloRanking(user) {
    if (!user.eloRankingData) {
      user.eloRankingData = {
        eloCustomGame: 1200,
        eloRankedGame: 1200,
        gamesPlayed: 0,
      };
    }
    return user.eloRankingData; // Return the eloRankingData
  }

  static calculateNewElo(game, userList) {
    const ranking = game.ranking;
    if (!ranking) {
      throw new Error("No ranking in game");
    }
    const isRankedGame = Game.isRanked(game);

    //Initialize if no elo ranking
    const userWorkingList = [];
    for (let i = 0; i < userList.length; i++) {
      const user = userList[i];
      userWorkingList.push({ username: User.getUserName(user) });
      const elorRating = EloRanking.getEloRanking(user);
      if (isRankedGame) {
        userWorkingList[i].elo = elorRating.eloRankedGame;
      } else {
        userWorkingList[i].elo = elorRating.eloCustomGame;
      }
      const faction = Game.getPlayerFaction(game, User.getUserName(user));
      const gameRank = EndGameRanking.getRankFromFaction(ranking, faction);
      userWorkingList[i].rank = gameRank;
      userWorkingList[i].faction = faction;
    }

    //Initializing elo tool
    const elo = new EloRank(32); // K-factor is 32

    //Calculate change of elo of each players
    for (let i = 0; i < userWorkingList.length; i++) {
      const player = userWorkingList[i];
      const opponents = userWorkingList.filter((user) => user !== player);
      const change = EloRanking.calculateChangeEloForPlayer(
        player,
        opponents,
        elo
      );
      userWorkingList[i].eloChange = change;
    }

    //Update Ranking of game and user elo
    for (let i = 0; i < ranking.length; i++) {
      const rankRow = ranking[i];
      if (!rankRow.faction) {
        throw new Error(`Invalid rankRow: faction field is missing`);
      }
      const userWorking = userWorkingList.find(
        (user) => user.faction === rankRow.faction
      );
      if (!userWorking) {
        throw new Error(
          `Faction ${rankRow.faction} not found in userWorkingList`
        );
      }
      const eloChange = userWorking.eloChange;
      rankRow.eloChange = eloChange;
      rankRow.initialElo = userWorking.elo;

      //Update user elo
      const actualUser = userList.find(
        (userItem) => User.getUserName(userItem) === userWorking.username
      );
      if (!actualUser) {
        throw new Error(
          `User with username ${userWorking.username} not found in userList`
        );
      }
      const userElo = EloRanking.getEloRanking(actualUser);
      if (isRankedGame) {
        userElo.eloRankedGame += eloChange;
      } else {
        userElo.eloCustomGame += eloChange;
      }
      userElo.gamesPlayed += 1;
    }
  }

  static calculateChangeEloForPlayer(player, opponents, elo) {
    let totalChange = 0;

    // Loop through all opponents
    for (const opponent of opponents) {
      const expectedScore = elo.getExpected(player.elo, opponent.elo);

      // Determine actual score based on ranks
      let actualScore;
      if (player.rank < opponent.rank) {
        actualScore = 1; // Player outranks opponent
      } else if (player.rank > opponent.rank) {
        actualScore = 0; // Opponent outranks player
      } else {
        actualScore = 0.5; // Tied rank
      }

      // Calculate the Elo change for this opponent and add to totalChange
      totalChange +=
        elo.updateRating(expectedScore, actualScore, player.elo) - player.elo;
    }

    return CustomMath.roundDec(totalChange); // Return the total Elo change for the player
  }

  static getCustomGameElo(user) {
    return EloRanking.getEloRanking(user).eloCustomGame;
  }

  static getRankedGameElo(user) {
    return EloRanking.getEloRanking(user).eloRankedGame;
  }

  static getRankPerformanceScore(game, faction) {
    if (!game.ranking) {
      return null;
    }
    const ranking = game.ranking;
    const totalPlayers = ranking.length;

    if (totalPlayers === 0) {
      throw new Error("No players in ranking.");
    }
    if (totalPlayers === 1) {
      return null;
    }

    // Helper function to calculate the normalized rank
    const calculateNormalizedScore = (rank) =>
      ((totalPlayers - rank) / (totalPlayers - 1)) * 100;

    // Find the player's rank row
    const playerRankRow = ranking.find((rank) => rank.faction === faction);
    if (!playerRankRow) {
      throw new Error(`Faction ${faction} not found in ranking.`);
    }

    const playerRank = playerRankRow.rank;

    // Handle ties by calculating the average normalized score for the tied rank positions
    const tiedPlayers = ranking.filter((rank) => rank.rank === playerRank);

    // Calculate the average score for the tied ranks
    const minRank = playerRank;
    const maxRank = playerRank + tiedPlayers.length - 1;
    const rankPerformanceScore =
      (calculateNormalizedScore(minRank) + calculateNormalizedScore(maxRank)) /
      2;

    return rankPerformanceScore;
  }

  //Faction Stats
  static createArrayStatsPerFaction() {
    return {};
  }

  static getFactionStatFromArray(faction, array) {
    if (!array[faction]) {
      array[faction] = {
        amountGamesRanked: 0,
        performanceArrayRanked: [],
        amountGamesCustom: 0,
        performanceArrayCustom: [],
        faction: faction,
      };
    }
    return array[faction];
  }

  static addStatOfGameToArray(array, game, username) {
    if (!Game.isFinished(game)) {
      return;
    }
    console.log("array: ", array);
    const faction = Game.getPlayerFaction(game, username);
    if (!faction) throw new Error("No faction found for user : " + username);
    const factionStat = this.getFactionStatFromArray(faction, array);

    const performanceForGame = this.getRankPerformanceScore(game, faction);
    if (performanceForGame) {
      if (Game.isRanked(game)) {
        factionStat.performanceArrayRanked.push(performanceForGame);
        factionStat.amountGamesRanked++;
      } else {
        factionStat.performanceArrayCustom.push(performanceForGame);
        factionStat.amountGamesCustom++;
      }
    }
  }

  static finalizeStatsArray(array) {
    for (const faction in array) {
      const factionStat = array[faction];
      if (factionStat.amountGamesRanked > 0) {
        const sumRanked = factionStat.performanceArrayRanked.reduce(
          (a, b) => a + b,
          0
        );
        factionStat.averageRankedPerformance =
          sumRanked / factionStat.amountGamesRanked;
      }
      if (factionStat.amountGamesCustom > 0) {
        const sumCustom = factionStat.performanceArrayCustom.reduce(
          (a, b) => a + b,
          0
        );
        factionStat.averageCustomPerformance =
          sumCustom / factionStat.amountGamesCustom;
      }
    }
  }
}

module.exports = EloRanking;
