import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import CostView from "../Utils/CostView";
import Cost from "../../Data/GameData/Utils/Cost";
import ActivePassiveAbility from "../../Data/GameData/Ability/ActivePassiveAbility";
import Item from "../../Data/GameData/Transactions/Item";
import Faction from "../../Data/GameData/PlayerData/Faction";
import NavigationView from "../NavigationView";
import Unit from "../../Data/GameData/MapData/Unit";
import UnitImageView from "../Object/UnitImageView";
import StaticGameData from "../../Data/GameData/StaticGameData";
import ImagesUrl from "../../Data/Other/ImagesUrl";
import Color from "../../Common/Config/Colors";
import BaseImage from "../Utils/BaseImage";

const BodyView = ({ color, size }) => {
  const playerData = StaticGameData.getPlayerData();

  const imageURL = ImagesUrl.FACTION_PORTRAIT_BASE + color + ".png";

  return (
    <div>
      <BaseImage
        style={{
          border: "2px solid " + Color.BASE_BUTTON_BORDER,
          borderRadius: "5px",
        }}
        src={imageURL}
        alt={`${color} portrait`}
        width={size}
        height={size}
        onlyOneRes={false}
      ></BaseImage>
      {false && (
        <img
          style={{
            border: "2px solid " + Color.BASE_BUTTON_BORDER,
            borderRadius: "5px",
          }}
          src={imageURL}
          alt={`${color} portrait`}
          width={size}
          height={size}
        />
      )}
    </div>
  );
};

export default BodyView;
