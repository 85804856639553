import React, { useState } from "react";
import "../CSS/Font.css";
import "../CSS/Menu.css";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import BaseButton from "./Utils/BaseButton";
import GameState from "../Data/GameData/Game/GameState";
import PlayerData from "../Data/GameData/PlayerData/PlayerData";

const StaticGameData = require("../Data/GameData/StaticGameData");
const Popup = require("../Data/Other/Popup");

const LoadingView = () => {
  //console.log("MessageView: message", message);
  // Define background color based on message type
  let backgroundColor = "black";

  const style = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 2000,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  };

  return (
    <div style={style} className="div-fill">
      <div className="div-fill" style={{ width: "98%", height: "95%" }}>
        <div
          style={{
            backgroundColor: backgroundColor,
            height: "auto",
            border: "4px solid white",
            //padding: "10px",
            //padding: "10px",
            //maxHeight: "100%",
            //maxWidth: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="div-ver-center"
        >
          <div>Waiting for server...</div>
        </div>
      </div>
    </div>
  );
};

export default LoadingView;
