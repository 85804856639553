const GameGlobalFunctions = require("../../Other/GameGlobalFunctions");
const CustomMath = require("../../../Common/Math/CustomMath");
const Time = require("../Time/Time");

class Game {
  static TIME_TYPE_ROUND_DURATION = "roundDuration";
  static TIME_TYPE_ROUND_FIXED_TIME = "roundFixedTime";
  static TIME_TYPE_FREE = "free";

  static createGame(
    gameId,
    maxNumberPlayers = 2,
    gameListType,
    draft = false,
    passwordGame = "",
    playerNameHidden = true,
    ranked = true,
    vpLimit = 70,
    statusToDisplay = null,
    timeType = Game.TIME_TYPE_FREE,
    roundTime = null,
    roundFixedTime = null,
    roundAmount = 8
  ) {
    //crypt with bcrypt

    return {
      gameId: gameId,
      maxNumberPlayers: maxNumberPlayers,
      players: [], //players
      isTutorial: false,
      gameListType: gameListType,
      password: passwordGame,
      isPassword: passwordGame !== "",
      draft: draft,
      playerNameHidden: playerNameHidden,
      ranked: ranked,
      vpLimit: vpLimit,
      playerIdIndex: 1,
      statusToDisplay: statusToDisplay,
      status: "Not Started", //Started, Finished
      currentRound: 0,

      //Time
      timeType: timeType,
      roundTime: roundTime,
      roundFixedTime: roundFixedTime,
      roundAmount: roundAmount,
    };
  }

  static isStarted(game) {
    return game.status === "Started";
  }

  static isFinished(game) {
    return game.status === "Finished";
  }

  static isRanked(game) {
    return game.ranked;
  }

  static getPrintRoundStatus(game) {
    if (game.status === "Not Started") {
      return "Not Started";
    }
    if (game.status === "Finished") {
      return "Finished";
    }
    if (game.status === "Started") {
      if (!GameGlobalFunctions.exists(Game.getCurrentRound(game))) {
        return null;
      }
      return "Round " + Game.getCurrentRound(game);
    }
  }

  static getCurrentRound(game) {
    return game.currentRound;
  }

  static setCurrentRound(game, round) {
    game.currentRound = round;
  }
  static incrementCurrentRound(game) {
    if (game.currentRound) {
      game.currentRound++;
    }
  }

  //Players
  static addPlayer(game, username) {
    game.players.push({
      username: username,
      color: null,
      isWaitingForNewRound: false,
      faction: null,
      factionPickList: null,
      playerInGameId: game.playerIdIndex,
    });
    game.playerIdIndex++;
  }

  static getPlayerDisplayName(player) {
    return player
      ? player.username
        ? player.username
        : "Player " + player.playerInGameId
      : null;
  }

  static getRoundAmount(game) {
    return game.roundAmount ? game.roundAmount : 8;
  }

  static removePlayer(game, username) {
    const playerIndex = game.players.findIndex(
      (player) => player.username === username
    );
    if (playerIndex === -1) {
      throw new Error("Player not found");
    }
    game.players.splice(playerIndex, 1);
  }

  //Factions
  static setPlayerFactionPickList(game, username, factionPickList) {
    const playerIndex = game.players.findIndex(
      (player) => player.username === username
    );
    if (playerIndex === -1) {
      throw new Error("Player not found");
    }
    game.players[playerIndex].factionPickList = factionPickList;
  }

  static setPlayerFaction(game, factionName, username) {
    const playerIndex = game.players.findIndex(
      (player) => player.username === username
    );
    if (playerIndex === -1) {
      return null;
    }
    game.players[playerIndex].faction = factionName;
  }

  static getUsernameList(game) {
    return game.players.map((player) => player.username);
  }

  static getPlayerFaction(game, username) {
    const playerIndex = game.players.findIndex(
      (player) => player.username === username
    );
    if (playerIndex === -1) {
      return null;
    }

    return game.players[playerIndex].faction;
  }

  static getPlayerFactionFromPlayerId(game, playerInGameId) {
    const playerIndex = game.players.findIndex(
      (player) => player.playerInGameId === playerInGameId
    );
    if (playerIndex === -1) {
      return null;
    }
    return game.players[playerIndex].faction;
  }

  static getOtherPlayerFactions(game, username) {
    return game.players
      .filter((player) => player.username !== username)
      .map((player) => player.faction);
  }

  static getPlayerFromUsername(game, username) {
    return game.players.find((player) => player.username === username);
  }

  static getPlayerFromFaction(game, factionName) {
    return game.players.find((player) => player.faction === factionName);
  }

  //username
  static getPlayersUsernames(game) {
    return game.players.map((player) => player.username);
  }

  static getUsedColors(game) {
    return game.players.map((player) => player.color);
  }

  static setIsWaitingForNewRoundForPlayer(game, playerInGameId, isWaiting) {
    const playerIndex = game.players.findIndex(
      (player) => player.playerInGameId === playerInGameId
    );
    if (playerIndex === -1) {
      throw new Error("Player not found");
    }
    game.players[playerIndex].isWaitingForNewRound = isWaiting;
  }

  static getIsWaitingForNewRoundForPlayer(game, username) {
    const playerIndex = game.players.findIndex(
      (player) => player.username === username
    );
    if (playerIndex === -1) {
      throw new Error("Player not found");
    }
    return game.players[playerIndex].isWaitingForNewRound;
  }

  static areAllPlayersWaitingForNewRound(game) {
    let allPlayersWaiting = true;

    for (let i = 0; i < game.players.length; i++) {
      if (
        !game.players[i].isWaitingForNewRound &&
        game.players[i].username !== "tutorial"
      ) {
        allPlayersWaiting = false;
        break;
      }
    }
    return allPlayersWaiting;
  }

  static setAllPlayersWaitingForNewRound(game, isWaiting) {
    game.players.forEach((player) => (player.isWaitingForNewRound = isWaiting));
  }

  //Filter
  static filterGameHiddenData(game, username) {
    if (game.playerNameHidden && !Game.isFinished(game)) {
      game.players.forEach((player) => {
        if (player.username !== username) {
          delete player.username;
        }
      });
    }
  }

  static isGameFull(game) {
    return game.players.length === game.maxNumberPlayers;
  }

  static isPlayerInGameFromPlayerId(game, playerInGameId) {
    return game.players.some(
      (player) => player.playerInGameId === playerInGameId
    );
  }

  static isPlayerInGameFromUsername(game, username) {
    return game.players.some((player) => player.username === username);
  }

  static isGameCreatorFromUsername(game, username) {
    return (
      game.players[0] &&
      game.players[0].username &&
      game.players[0].username === username
    );
  }
}

module.exports = Game;
