import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import FactionLogo from "../Utils/FactionLogo";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import CostView from "../Utils/CostView";
import Logo from "../Utils/Logo";
import TechTypeView from "./TechTypeView";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import ResearchActionData from "../../Data/GameData/ActionData/ResearchActionData";
import AbilityInput from "../Ability/AbilityInput.jsx";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const SpaceObject = require("../../Data/GameData/MapData/SpaceObject.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const TechTree = require("../../Data/GameData/Technology/TechTree.js");
const Tech = require("../../Data/GameData/Technology/Tech.js");
const LogBook = require("../../Data/GameData/Connection/LogBook");

const TechView = ({ tech }) => {
  const playerData = StaticGameData.getPlayerData();
  const ownedReq = TechTree.getOwnedRequisites(playerData.techTree);

  console.log("tech", tech);
  const navigationData = {
    buttonsData: [],
  };

  //Prerequisites button
  if (ownedReq.length > 0) {
    navigationData.buttonsData.push({
      content: (
        <span>
          {ownedReq.map((req, index) => {
            return (
              <span key={index}>
                <TechTypeView color={req}></TechTypeView>
              </span>
            );
          })}
        </span>
      ),

      callback: () => {
        const messageBook = LogBook.createLogBook();
        LogBook.generateAddMessage(
          messageBook,
          "This button shows you the prerequisites you own."
        );
        if (ownedReq.length > 0) {
          let messageText = "You owned the following prerequisites : ";
          let messageItems = [];
          for (let i = 0; i < ownedReq.length; i++) {
            messageText = messageText + "$logo$ ";
            messageItems.push(Tech.getLogoName(ownedReq[i]));
          }
          LogBook.generateAddMessage(messageBook, messageText, messageItems);
        } else {
          LogBook.generateAddMessage(
            messageBook,
            "You don't owned any tech prerequisites for now."
          );
        }

        LogBook.generateAddMessage(
          messageBook,
          "Each time you research a tech of a specific color, you gain one prerequisites of that color. Prerequisites are required to research most of the technologies."
        );
        LogBook.generateAddMessage(
          messageBook,
          "The prerequisites to research a technology is displayed on the right of the technology name."
        );
        LogBook.generateAddMessage(
          messageBook,
          "The prerequisites you gain when researching a technology is displayed on the left of the technology name."
        );
        LogBook.generateAddMessage(
          messageBook,
          "The technology you own are displayed with a golden border."
        );

        UIMessage.displayInfoLogBook(messageBook);
      },
    });
  }

  navigationData.buttonsData.push({
    text: "Back",
    callback: () => {
      Popup.goBack();
    },
  });

  const DisplayTech = ({ tech }) => {
    const textColor = Color.getColorFromTechColor(tech.color);
    console.log("tech", tech);

    const DisplayResearchTech = ({ tech }) => {
      if (tech.owned || !playerData.faction) {
        return;
      }

      const handleClick = () => {
        UIMessage.displayConfirmMessage(
          "Reseach " + tech.name + " ?",
          "Are you sure you want to research " +
            tech.name +
            " for " +
            tech.cost.science +
            " sciences ?",
          () => {
            ResearchActionData.prepare(playerData, tech.name);
            console.log("StaticGameData.popup", StaticGameData.popup);
            Popup.goBack();
            Popup.touch();

            ResearchActionData.clientResolve(playerData);
          }
        );
      };

      return (
        <div style={{ marginTop: "0.5em" }}>
          <BaseButton onClick={handleClick}>Research {tech.name}</BaseButton>
        </div>
      );
    };

    const DisplayUseTech = () => {
      if (!tech.active || !tech.owned) {
        return;
      }

      return (
        <div style={{ marginTop: "0.5em" }}>
          {tech.isExhausted ? (
            <BaseButton
              type="greyOut"
              onClick={() => {
                UIMessage.displayInfoMessage(
                  "Exhausted",
                  "This technology is exhausted, you cannot use it for now. It will be readied at the beginning of the next round."
                );
              }}
            >
              Exhausted
            </BaseButton>
          ) : (
            <BaseButton
              onClick={() => {
                AbilityInput.getInputFromTech(tech)();
              }}
            >
              Use
            </BaseButton>
          )}
        </div>
      );
    };

    return (
      <div className="text-box" style={{ textAlign: "center" }}>
        <div style={{ fontSize: "1.5em" }}>
          <div style={{ marginTop: "0.5em" }}>
            <TechTypeView color={tech.color}></TechTypeView>{" "}
            <span style={{ color: textColor }}> {tech.name}</span>
          </div>
          <div style={{ marginTop: "0.5em" }}>
            Cost : {tech.cost.science} <Logo logoName="science"></Logo>
          </div>
          {tech.requirements.length > 0 && (
            <div style={{ marginTop: "0.5em" }}>
              Required prerequisites :
              {tech.requirements.map((req, index) => {
                return (
                  <span key={index}>
                    {" "}
                    <TechTypeView color={req}></TechTypeView>
                  </span>
                );
              })}
            </div>
          )}
        </div>
        <div
          style={{
            marginTop: "0.5em",
            backgroundColor: Color.COLOR_GREY_3,
            padding: "10px",
          }}
        >
          {tech.effect}
        </div>
        <div style={{ marginTop: "0.5em" }}>
          <i>{tech.description}</i>
        </div>
        <DisplayResearchTech tech={tech}></DisplayResearchTech>
        <DisplayUseTech></DisplayUseTech>
        {false && tech.owned && tech.active === true && (
          <div
            style={{
              marginTop: "1em",
              color: "white",
              backgroundColor: "red",
            }}
          >
            This tech makes you gain an ability. To use this tech, go to the map
            and then use the 'abilities' tool.
          </div>
        )}
      </div>
    );
  };

  return (
    <GameGlobalLayout title={null} backgroundType="shadow">
      <div
        style={{
          height: "100%",
        }}
      >
        <DisplayTech tech={tech}></DisplayTech>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default TechView;
