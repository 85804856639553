import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import CostView from "../Utils/CostView";
import Cost from "../../Data/GameData/Utils/Cost";
import ActivePassiveAbility from "../../Data/GameData/Ability/ActivePassiveAbility";
import Item from "../../Data/GameData/Transactions/Item";

const AbilityView = ({ abilityDesc }) => {
  const cost = ActivePassiveAbility.getCost(abilityDesc);
  const text = ActivePassiveAbility.getText(abilityDesc);
  const active = ActivePassiveAbility.isActive(abilityDesc);
  const itemsInfo = ActivePassiveAbility.getItemsInfo(abilityDesc);

  console.log("DEBUG itemsInfo ", itemsInfo);

  const DisplayActiveAbility = () => {
    return (
      <span>
        {cost && !Cost.isEmpty(cost) && (
          <div>
            Cost : <CostView cost={cost} compactView={true}></CostView>
          </div>
        )}
        <div>
          <span>Action : </span>

          <span>{text}</span>
        </div>
      </span>
    );
  };

  const DisplayItemsInfo = ({ itemsInfo }) => {
    if (!itemsInfo || itemsInfo.length === 0) return;

    return (
      <div>
        {itemsInfo.map((item, index) => {
          const itemDesc = Item.getDescription(item);
          const abilities = itemDesc.abilities;
          return (
            <div key={index}>
              <div style={{ marginTop: "1em" }}>{item.name} :</div>
              {abilities.map((ability, index2) => {
                return (
                  <div key={index2}>
                    <AbilityView abilityDesc={ability}></AbilityView>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const DisplayPassiveAbility = () => {
    return (
      <span>
        <span>Passive : </span>
        {text}
      </span>
    );
  };

  return (
    <span>
      {active ? (
        <DisplayActiveAbility></DisplayActiveAbility>
      ) : (
        <DisplayPassiveAbility></DisplayPassiveAbility>
      )}
      <DisplayItemsInfo itemsInfo={itemsInfo}></DisplayItemsInfo>
    </span>
  );
};

export default AbilityView;
