import React, { useState } from "react";

import BaseButton from "../../Utils/BaseButton";

const Popup = require("../../../Data/Other/Popup");
const StaticGameData = require("../../../Data/GameData/StaticGameData");
const PlayerData = require("../../../Data/GameData/PlayerData/PlayerData");
const Color = require("../../../Common/Config/Colors");

const ActivationNavigationView = ({
  screenData = null,
  navigationData = { buttonsData: [] },
  taskBarData = { text: null },
}) => {
  //Task bar text coming from MODE
  const playerData = StaticGameData.getPlayerData();

  const elementStyle = {
    //margin: "10px",
  };

  /*console.log(
    "ActivationNavigationView ActivationNavigationView : ",
    navigationData
  );*/

  return (
    <div
      style={{
        //position: "absolute",
        //bottom: 0,
        //left: 0,
        backgroundColor: Color.COLOR_GREY_3,
        zIndex: 100000,
        width: "100vw",
        fontSize: "1em",
        borderTop: "2px solid " + Color.COLOR_GREY_5,

        //transform: "translate(0, -100%)",
      }}
    >
      {taskBarData && taskBarData.text && (
        <div
          style={{
            color: "white",
            textAlign: "center",

            padding: "10px",
          }}
        >
          {taskBarData.text}
        </div>
      )}
      <div className="div-hor-wrap">
        {navigationData &&
          navigationData.buttonsData.map((buttonData, index) => {
            const { text, callback } = buttonData;
            return (
              <div key={index} style={elementStyle}>
                <BaseButton onClick={callback}>{text}</BaseButton>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ActivationNavigationView;
