import React, { useState, useEffect } from "react";

import StaticGameData from "../../../Data/GameData/StaticGameData.js";
import BaseButton from "../../Utils/BaseButton.jsx";
import UnitLineView from "../../Units/UnitLineView.jsx";
import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData.js";
import NavigationView from "../../NavigationView.jsx";
import GameGlobalLayout from "../../GameGlobalLayout.jsx";
import AdminActionData from "../../../Data/GameData/ActionAdminData/AdminActionData.js";

const Popup = require("../../../Data/Other/Popup.js");
const AdminCreateUnitData = require("../../../Data/GameData/ActionAdminData/AdminCreateUnitData.js");

const AdminCreateUnitView = ({ activePlayerData }) => {
  const playerData = StaticGameData.getPlayerData();
  const data = ActionCommonData.getData(playerData);
  const createUnitData = AdminCreateUnitData.getData(playerData);
  const unitShop = createUnitData.unitShop;

  const DisplayCatalogLine = ({ itemLine, unitShop }) => {
    const unit = itemLine.unit;
    const amount = itemLine.amount;

    return (
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ fontSize: "2em" }}>
            <UnitLineView
              unit={unit}
              factionName={playerData.faction.name}
              showHP={false}
              showRequirements={false}
              showCost={false}
              size="1.5em"
            ></UnitLineView>
          </span>
          <span>
            <span> {amount}</span>
          </span>
          <span>
            <div className="div-hor-center">
              <BaseButton
                width="3em"
                onClick={() => {
                  AdminCreateUnitData.modifyOrderAmount(
                    playerData,
                    unitShop,
                    unit,
                    +1
                  );

                  Popup.touch();
                }}
              >
                +
              </BaseButton>
              <BaseButton
                width="3em"
                onClick={() => {
                  AdminCreateUnitData.modifyOrderAmount(
                    playerData,
                    unitShop,
                    unit,
                    -1
                  );
                  Popup.touch();
                }}
              >
                -
              </BaseButton>
            </div>
          </span>
        </div>
      </div>
    );
  };

  const DisplayBuildView = () => {
    return (
      <div>
        <div>
          {unitShop.list.map((item, index) => {
            return (
              <DisplayCatalogLine
                key={index}
                itemLine={item}
                unitShop={unitShop}
              ></DisplayCatalogLine>
            );
          })}
        </div>
      </div>
    );
  };

  const navigationData = {
    buttonsData: [
      { text: "Back", callback: () => Popup.goBack() },
      {
        text: "Confirm",
        callback: () => {
          Popup.unStackToMap();
          Popup.resetMapNavigationData();
          AdminActionData.resolveClient(
            playerData,
            AdminActionData.ACTION_CREATE_UNIT
          );
        },
      },
    ],
  };

  return (
    <GameGlobalLayout title={null} backgroundType="shadow">
      <div
        className="text-box"
        style={{
          textAlign: "center",
          height: "100%",
        }}
      >
        <DisplayBuildView></DisplayBuildView>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default AdminCreateUnitView;
