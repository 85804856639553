import React, { useState, useEffect } from "react";
import ActivationViewLayout from "./ActivationViewLayout";
import ActivationNavigationView from "./ActivationNavigationView";
import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import PlanetViewActivation from "./Objects/PlanetViewActivation";
import ConstructionData from "../../../Data/GameData/Activation/ConstructionData";
import ResourceBarView from "../../Utils/ResourceBarView";
import CostView from "../../Utils/CostView";
import ActivationCA from "../../../ClientAction/ActivationCA";
import Logo from "../../Utils/Logo";
import Unit from "../../../Data/GameData/MapData/Unit";
import ActivationTransferView from "./ActivationTransferView";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const Rules = require("../../../Data/GameData/Game/Rules");
const Cost = require("../../../Data/GameData/Utils/Cost");
const UnitCatalog = require("../../../Data/GameData/Utils/UnitCatalog");
const UnitShopList = require("../../../Data/GameData/Utils/UnitShopList");
const Color = require("../../../Common/Config/Colors");
const TransferData = require("../../../Data/GameData/Activation/TransferData");
const Map = require("../../../Data/GameData/MapData/Map.js");

const ActivationBuildView = ({ system, navigationData }) => {
  const playerData = StaticGameData.getPlayerData();
  const constructionData = ConstructionData.getData(playerData);
  const view = constructionData.view;

  console.log("ActivationBuildView constructionData : ", constructionData);

  const activeSystem = ActivationData.getActiveSystem(playerData);
  const planet = Map.getSpaceObjectFromName(
    playerData.map,
    constructionData.planetName
  );

  const DisplayCatalogLine = ({
    itemLine,
    unitShopList,
    selectedConstData,
  }) => {
    const unit = itemLine.unit;
    const amount = itemLine.orderAmount;

    return (
      <div>
        <div className="div-hor-center">
          <span style={{ width: "60%" }}>
            <UnitLineView
              unit={unit}
              factionName={playerData.faction.name}
              showHP={false}
              showRequirements={true}
              showCost={true}
            ></UnitLineView>
          </span>
          <span style={{ width: "10%" }}>
            <span> {amount}</span>
          </span>
          <span style={{ width: "30%" }}>
            <BaseButton
              onClick={() => {
                ConstructionData.addOrder(playerData, selectedConstData, unit);

                Popup.touch();
              }}
            >
              +
            </BaseButton>
            <BaseButton
              onClick={() => {
                ConstructionData.removeOrder(
                  playerData,
                  selectedConstData,
                  unit
                );
                Popup.touch();
              }}
            >
              -
            </BaseButton>
          </span>
        </div>
      </div>
    );
  };

  const DisplayBuildView = () => {
    navigationData.buttonsData = [
      {
        text: "Ok",
        callback: () => {
          ConstructionData.switchToView(
            playerData,
            ConstructionData.VIEW_GLOBAL_VIEW
          );
        },
        type: "action",
      },
    ];

    //const planet = ConstructionData.getSelectedPlanet(playerData);
    //const planetData = ConstructionData.getPlanetData(playerData, planet);
    //const selectedClass = constructionData.unitClassSelected;
    //const fullCatalog = ConstructionData.getFullCatalog(playerData, planet);
    /*const classCatalog = ConstructionData.getClassCatalog(
      playerData,
      planet,
      selectedClass
    );*/
    //let fullCatalogCost = UnitCatalog.getCost(playerData, fullCatalog);

    const selectedConstData =
      ConstructionData.getSelectedUnitConstData(playerData);
    console.log("selectedConstData : ", selectedConstData);
    const constructingUnit = selectedConstData.unit;
    const unitShopList = selectedConstData.unitShopList;
    const cost = UnitShopList.getCost(playerData, unitShopList);

    //const fleetProducing = selectedConstData.ghostFleetConstructing;

    return (
      <div>
        <div>
          <span>Building unit : </span>
          <UnitLineView
            unit={constructingUnit}
            factionName={playerData.faction.name}
            showHP={false}
          ></UnitLineView>
        </div>
        <div>
          <DisplayAmountOfConstructedUnits
            unitConstData={selectedConstData}
          ></DisplayAmountOfConstructedUnits>
        </div>
        <div>
          Cost : <CostView cost={cost}></CostView>
        </div>

        <div>
          {unitShopList.map((item, index) => {
            return (
              <DisplayCatalogLine
                key={index}
                itemLine={item}
                unitShopList={unitShopList}
                selectedConstData={selectedConstData}
              ></DisplayCatalogLine>
            );
          })}
        </div>
      </div>
    );
  };

  const DisplayAmountOfConstructedUnits = ({ unitConstData }) => {
    const unit = unitConstData.unit;
    const amount = UnitShopList.getAmountUnitsShopped(
      unitConstData.unitShopList
    );
    console.log("unitConstData : ", unitConstData);

    return (
      <span>
        {Unit.getPrintingName(unit)} is constructing : {amount} /{" "}
        {Unit.getConstructionAmount(playerData, unit)} units
      </span>
    );
  };

  const DisplaySpaceArea = ({}) => {
    const fleet = System.getFleet(activeSystem, playerData.faction.name);
    return (
      <FleetSummaryView fleet={fleet} showCapacity={true}></FleetSummaryView>
    );
  };

  const DisplayGlobalView = () => {
    const confirm = () => {
      UIMessage.displayConfirmMessage(
        "Construct ?",
        "Are you sure you want to construct these units ?",
        () => {
          ConstructionData.warningConstruction(playerData, () => {
            ActivationCA.resolve_STEP_ACTIVATION_BUILD();
          });
        }
      );
    };

    navigationData.buttonsData.push(
      //{ text: "Don't Construct", callback: null },
      {
        text: "Help",
        callback: () => {
          Popup.addLayer({
            name: "HelpPopup",
            helpReference: "buildUnitsStep",
          });
        },
      },
      {
        text: "Don't Build",
        callback: () => {
          ActivationCA.initialize_STEP_ACTIVATION_ACTIVATED();
        },
        type: "action",
      },
      {
        text: "Confirm",
        callback: confirm,
        type: "action",
      }
    );

    return (
      <div>
        <div>
          Space area : <DisplaySpaceArea></DisplaySpaceArea>
        </div>
        <hr></hr>

        <PlanetViewActivation
          spaceObject={planet}
          showPopRepartition={true}
        ></PlanetViewActivation>
        <div>
          Construction total cost :{" "}
          <CostView cost={constructionData.cost}></CostView>
        </div>
        <div style={{ marginTop: "2em" }}></div>
        {constructionData.unitsConstData.length === 0 && (
          <div>This planet has no units which can contruct units.</div>
        )}
        {constructionData.unitsConstData.map((unitConstData, indexConst) => {
          const unit = unitConstData.unit;
          const unitShopList = unitConstData.unitShopList;
          const ghostFleetConstructing = unitConstData.ghostFleetConstructing;
          //console.log("unitConstData : ", unitConstData);
          return (
            <div
              key={indexConst}
              style={
                {
                  //backgroundColor: Color.COLOR_GREY_3,
                }
              }
            >
              <div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                  }}
                >
                  <span>
                    <UnitLineView
                      unit={unit}
                      factionName={playerData.faction.name}
                      showHP={false}
                    ></UnitLineView>
                  </span>
                  {Fleet.isEmpty(ghostFleetConstructing) && (
                    <span>
                      <BaseButton
                        onClick={() => {
                          ConstructionData.switchToBuildView(
                            playerData,
                            unitConstData,
                            planet
                          );
                        }}
                      >
                        Construct units
                      </BaseButton>
                    </span>
                  )}
                  {!Fleet.isEmpty(ghostFleetConstructing) && (
                    <FleetSummaryView
                      fleet={ghostFleetConstructing}
                      onClick={() => {
                        ConstructionData.switchToBuildView(
                          playerData,
                          unitConstData,
                          planet
                        );
                      }}
                    ></FleetSummaryView>
                  )}
                </div>
                <div>
                  <DisplayAmountOfConstructedUnits
                    unitConstData={unitConstData}
                  ></DisplayAmountOfConstructedUnits>
                </div>
                <div>
                  Cost : <CostView cost={unitConstData.cost}></CostView>
                </div>{" "}
              </div>
              <div style={{ marginTop: "2em" }}></div>
            </div>
          );
        })}
        <hr></hr>
      </div>
    );
  };

  if (ConstructionData.getView(playerData) === ConstructionData.VIEW_TRANSFER) {
    //Remove all items of navigation data. buttonData
    navigationData.buttonsData.splice(0, navigationData.buttonsData.length);
    navigationData.buttonsData.push({
      text: "Back",
      callback: () =>
        ConstructionData.switchToView(
          playerData,
          ConstructionData.VIEW_GLOBAL_VIEW
        ),
    });
  }

  return (
    <div>
      {view === ConstructionData.VIEW_GLOBAL_VIEW && (
        <DisplayGlobalView></DisplayGlobalView>
      )}
      {view === ConstructionData.VIEW_BUILD_VIEW && (
        <DisplayBuildView></DisplayBuildView>
      )}
      {ConstructionData.getView(playerData) ===
        ConstructionData.VIEW_TRANSFER && (
        <ActivationTransferView
          system={system}
          planet={ConstructionData.getTransferInPlanet(playerData)}
          navigationData={navigationData}
          resolve={ActivationCA.resolve_STEP_ACTIVATION_BUILD}
        ></ActivationTransferView>
      )}
    </div>
  );
};

export default ActivationBuildView;

//
