const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const SpaceObject = require("../MapData/SpaceObject.js");
const LogBook = require("../Connection/LogBook.js");
const Phase = require("../Game/Phase.js");
const ActionCommonData = require("./ActionCommonData.js");
const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction.js");
const Item = require("../Transactions/Item.js");
const Faction = require("../PlayerData/Faction.js");

class InvasionActionData {
  //Lift Functions

  static isElligible(playerData, planet) {
    if (planet.faction !== playerData.faction.name) {
      return true;
    } else {
      return false;
    }
  }

  static prepare(playerData, planet, stopInvasion = false) {
    playerData.phase = Phase.PHASE_ACTION;
    playerData.step = Phase.STEP_ACTION_INVASION;

    const data = {
      planetName: planet.name,
      stopInvasion: stopInvasion,
    };

    playerData.actionData = data;
    //Popup.addLayer({ name: "ActionGlobalView" });
  }

  static getAttackedFactionOnGround(invadingFaction, targetPlanet) {
    if (
      targetPlanet.faction &&
      !Faction.isMinorFaction(targetPlanet.faction) &&
      targetPlanet.faction !== invadingFaction
    ) {
      return targetPlanet.faction;
    }
    return null;
  }

  static resolveClient(playerData) {
    const data = ActionCommonData.getData(playerData);
    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);

    const attackedFaction = this.getAttackedFactionOnGround(
      playerData.faction.name,
      planet
    );
    if (attackedFaction) {
      const logBookAttack = LogBook.createLogBook();
      LogBook.generateAddMessage(
        logBookAttack,
        "The planet " +
          planet.name +
          " is controlled by $faction$. This will be considered as an attack on the ground to $faction$.",
        [planet.faction, planet.faction]
      );
      const attackedFactionPlayerData = PlayerData.getPlayerDataFromFaction(
        playerData,
        attackedFaction
      );
      if (
        Item.includeRelicFromNameFromFaction(
          Item.NAME_PEACE_GROUND,
          playerData.faction.name,
          attackedFactionPlayerData.items
        )
      ) {
        LogBook.generateAddMessage(
          logBookAttack,
          "$faction$ has a " +
            Item.NAME_PEACE_GROUND +
            " relic from your faction.  You will loose " +
            Rules.COST_ATTACK_GROUND_WHEN_PEACE +
            " $logo$ during the round resolution phase because of this attack. The relic will then be sent back to you at the start of the next round.",
          [planet.faction, "population"]
        );
        LogBook.generateAddMessage(
          logBookAttack,
          "ATTENTION : you will loose 1 VP per negative $logo$ at the beginning of the next round.",
          ["population"]
        );
      }
      UIMessage.displayConfirmLogBook(logBookAttack, () => {
        ActionCommonData.resolveClient(playerData);
      });
    } else {
      ActionCommonData.resolveClient(playerData);
    }
  }

  static checkAttackOnGround(playerData, planet, callback) {
    const attackedFaction = this.getAttackedFactionOnGround(
      playerData.faction.name,
      planet
    );
    if (attackedFaction) {
      const logBookAttack = LogBook.createLogBook();
      LogBook.generateAddMessage(
        logBookAttack,
        "The planet " +
          planet.name +
          " is controlled by $faction$. This will be considered as an attack on the ground to $faction$.",
        [planet.faction, planet.faction]
      );
      const attackedFactionPlayerData = PlayerData.getPlayerDataFromFaction(
        playerData,
        attackedFaction
      );
      if (
        Item.includeRelicFromNameFromFaction(
          Item.NAME_PEACE_GROUND,
          playerData.faction.name,
          attackedFactionPlayerData.items
        )
      ) {
        LogBook.generateAddMessage(
          logBookAttack,
          "$faction$ has a " +
            Item.NAME_PEACE_GROUND +
            " relic from your faction.  You will loose " +
            Rules.COST_ATTACK_GROUND_WHEN_PEACE +
            " $logo$ during the round resolution phase because of this attack. The relic will then be sent back to you at the start of the next round.",
          [planet.faction, "population"]
        );
        LogBook.generateAddMessage(
          logBookAttack,
          "ATTENTION : you will loose 1 VP per negative $logo$ at the beginning of the next round.",
          ["population"]
        );
      }
      UIMessage.displayConfirmLogBook(logBookAttack, callback);
    } else {
      callback();
    }
  }

  static getPlanetsData(playerData) {
    return ActionCommonData.getData(playerData).planets;
  }

  static getPlanetData(playerData, planet) {
    const planetsData = ActionCommonData.getData(playerData).planets;
    for (let i = 0; i < planetsData.length; i++) {
      if (planetsData[i].name === planet.name) {
        return planetsData[i];
      }
    }
    return null;
  }

  static invadePlanet = (playerData, planet) => {
    const planetsData = this.getPlanetsData(playerData);
    for (let i = 0; i < planetsData.length; i++) {
      const planetData = planetsData[i];
      if (planetData.name === planet.name) {
        planetsData[i].invade = true;
      } else {
        planetsData[i].invade = false;
      }
    }
    Popup.touch();
  };

  static removeInvadePlanet = (playerData, planet) => {
    const planetData = this.getPlanetData(playerData, planet);
    planetData.invade = false;
    Popup.touch();
  };

  static resolveServer(playerData, resolveData) {
    ActionCommonData.resolveServerIni(playerData, resolveData);

    const data = ActionCommonData.getData(playerData);
    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);
    const system = Map.getSystemFromSpaceObject(planet, playerData.map);
    const message = UIMessage.createMessage("Invasion");

    //Check if player is allowed to place units on the planet.
    try {
      MapUnitaryAction.placeUnitsOnPlanet(playerData, planet, []);
    } catch (e) {
      UIMessage.sendInfoMessageToClient(
        playerData,
        "Warning.",
        e.message +
          " Your units will not be able to land on the planet. Bombardment will still occur."
      );
    }

    if (data.stopInvasion) {
      UIMessage.addLine(message, planet.name + " will not be invaded.");
      Planet.removeInvade(planet, playerData.faction.name);
    } else {
      if (planet.faction === playerData.faction.name) {
        throw new Error("You can't invade a planet you already own.");
      }

      const planetsInSystem = System.getPlanets(system);
      let planetAlreadyInvadingName = null;
      for (let i = 0; i < planetsInSystem.length; i++) {
        const planetInSystem = planetsInSystem[i];
        if (planetInSystem.name !== planet.name) {
          if (
            Planet.isFactionInvading(planetInSystem, playerData.faction.name)
          ) {
            planetAlreadyInvadingName = planetInSystem.name;
          }
        }
      }

      UIMessage.addLine(
        message,
        planet.name +
          " will be invaded after you win the space combat. All your ground forces in this system will be dropped on the planet."
      );
      if (planetAlreadyInvadingName) {
        UIMessage.addLine(
          message,
          planetAlreadyInvadingName +
            " will not be invaded. You can only invade one planet per round and per system."
        );
      }

      for (let i = 0; i < planetsInSystem.length; i++) {
        const planetInSystem = planetsInSystem[i];
        if (planetInSystem.name === planet.name) {
          Planet.addInvade(planetInSystem, playerData.faction.name);
        } else {
          Planet.removeInvade(planetInSystem, playerData.faction.name);
        }
      }
    }
    UIMessage.addMessageToDisplay(playerData, message);
    ActionCommonData.cleanActionData(playerData);
  }

  static getInvadedPlanetName(playerData) {
    const planetsData = this.getPlanetsData(playerData);
    for (let i = 0; i < planetsData.length; i++) {
      if (planetsData[i].invade) {
        return planetsData[i].name;
      }
    }

    return null;
  }
}

module.exports = InvasionActionData;
