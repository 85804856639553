const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const System = require("../MapData/System.js");
const Unit = require("../MapData/Unit.js");
const Map = require("../MapData/Map.js");
const Fleet = require("../MapData/Fleet.js");
const LogBook = require("../Connection/LogBook.js");
const LogMessage = require("../Connection/LogMessage.js");
const UIMessage = require("../Connection/UIMessage.js");
const Planet = require("../MapData/Planet.js");

class WarningAction {
  static executeCallback(logBook, callback) {
    if (LogBook.isEmpty(logBook)) {
      callback();
    } else {
      UIMessage.displayConfirmLogBook(logBook, callback);
    }
  }

  static afterUnitPlaceInSystem(playerData, system) {
    const logBook = LogBook.createLogBook();

    const factionName = playerData.faction.name;

    const fleet = System.getFleet(system, factionName);

    const totalLogisticMass = Fleet.getLogisticMass(fleet);

    Fleet.checkValidity();
  }

  static fleetLimit(playerData, system) {
    const logBook = LogBook.createLogBook();

    const factionName = playerData.faction.name;
    const fleet = System.getFleet(system, factionName);
  }

  static warningFleetIsValid(system, playerData, callback) {
    const logBook = LogBook.createLogBook();

    const fleet = System.getFleet(system, playerData.faction.name);
    console.log("WarningAction warningFleetIsValid fleet ", fleet);
    if (!fleet) {
      this.executeCallback(logBook, callback);
      return;
    }

    //Check fleet limit
    const fleetLogisticMass = Fleet.getLogisticMass(fleet);
    const fleetLimit = playerData.cargo;
    if (fleetLogisticMass > fleetLimit) {
      LogBook.createMessage(
        logBook,
        [
          {
            content:
              "The number of non fighter ship in " +
              system.name +
              " will be higher than your fleet limit ( ",
          },
          { type: LogMessage.ITEM_TYPE_RESOURCE_LOGO, content: "cargo" },
          {
            content:
              "). You will have to scrap ships until it is not the case anymore.",
          },
        ],
        false,
        LogMessage.TYPE_WARNING
      );
      LogBook.createMessage(
        logBook,
        [
          { type: LogMessage.ITEM_TYPE_RESOURCE_LOGO, content: "cargo" },
          {
            content: fleetLogisticMass + " / " + fleetLimit,
          },
        ],
        false,
        LogMessage.TYPE_WARNING
      );
    }

    //Check fleet capacity
    const fleetCapacity = Fleet.getTotalCapacity(
      PlayerData.getPlayerDataFromFaction(playerData, fleet.faction),
      fleet
    );
    const fleetRequiredCapacity = Fleet.getTotalRequiredCapacity(
      PlayerData.getPlayerDataFromFaction(playerData, fleet.faction),
      fleet
    );
    if (fleetRequiredCapacity > fleetCapacity) {
      LogBook.createMessage(
        logBook,
        [
          {
            content:
              "The required capacity to carry your units in " +
              system.name +
              " will be higher than your fleet capacity",
          },
          {
            content:
              ". You will have to scrap units until it is not the case anymore.",
          },
        ],
        false,
        LogMessage.TYPE_WARNING
      );
    }
    this.executeCallback(logBook, callback);
  }

  static warningNextRound(playerData, callback) {
    let warningSystemNotActivated = false;
    const logBook = LogBook.createLogBook();
    let tempLogBook = LogBook.createLogBook();
    LogBook.createMessage(tempLogBook, [
      {
        content:
          "You did not activated the following systems. This means that the planets in these systems did not produce anything. Are you sure to end the round without producing in these systems ?",
      },
    ]);
    const systems = Map.getSystemsWhereFactionHasPlanet(
      playerData.map,
      playerData.faction.name
    );
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      if (!system.hasBeenActivated) {
        warningSystemNotActivated = true;
        LogBook.createMessage(tempLogBook, [
          { type: LogMessage.ITEM_TYPE_SYSTEM, content: system },
          {
            content: " has not been activated",
          },
        ]);
      }
    }
    if (warningSystemNotActivated) {
      LogBook.concat(tempLogBook, logBook);
    }

    tempLogBook = LogBook.createLogBook();

    this.executeCallback(logBook, callback);
  }

  static warningSpend(playerData, cost, planet = null) {
    PlayerData.spendCost(playerData, cost, planet);
  }
}

module.exports = WarningAction;
