class RulesText {
  //Activation - Locking system
  static WARNING_SYSTEM_LOCKED = (system) => {
    return (
      "The system will be locked after the move. This means that units will not be able to move out of " +
      system.name +
      " anymore during this round."
    );
  };
}

module.exports = RulesText;
