import React, { createContext, useContext, useState } from "react";

const RenderContextMapZoom = createContext();

export const RenderProviderMapZoom = ({ children }) => {
  const [forceRenderValue, setForceRenderValue] = useState(0.0);

  const updateRenderValue = (newValue) => {
    setForceRenderValue(newValue); // Update the float value
  };

  return (
    <RenderContextMapZoom.Provider
      value={{ forceRenderValue, updateRenderValue }}
    >
      {children}
    </RenderContextMapZoom.Provider>
  );
};

export const useRenderContextMapZoom = () => useContext(RenderContextMapZoom);
