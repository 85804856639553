import React from "react";
import RuleImage from "../Utils/RuleImage";
import RuleP from "../Utils/RuleP";
import RuleUl from "../Utils/RuleUl";
import RuleLi from "../Utils/RuleLi";
import RuleLink from "../Utils/RuleLink";
import SpaceObject from "../../Data/GameData/MapData/SpaceObject";
import PlanetBonus from "../../Data/GameData/Bonus/PlanetBonus";
import PlanetView from "../../GameView/Object/PlanetView";
import SpaceObjectImageView from "../../GameView/Object/SpaceObjectImageView";
import BaseButton from "../../GameView/Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import ManagePlanetView from "../../GameView/ManageSystem/ManagePlanetView";
import Logo from "../../GameView/Utils/Logo";
import Planet from "../../Data/GameData/MapData/Planet";
import LineSpace from "../../GameView/Utils/Layout/LineSpace";

const createPlanet = (type, bonuses) => {
  const planet = SpaceObject.createPlanet(type);
  planet.name = type;
  PlanetBonus.resetBonus(planet);
  if (bonuses) {
    for (let i = 0; i < bonuses.length; i++) {
      PlanetBonus.addSpecificBonus(planet, bonuses[i]);
    }
  }

  return planet;
};

const DisplayPlanetType = ({ type }) => {
  const planet = createPlanet(type);
  console.log("planet", planet);
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr",
        marginBottom: "1em",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span style={{}}>
        <div className="div-ver-fill">
          {" "}
          <SpaceObjectImageView
            spaceObject={planet}
            showFleet={false}
            showBonus={false}
            maxWidth="50px"
          />
          <div style={{}}> {type}</div>
        </div>
      </span>
      <span style={{}}>
        <Logo logoName="mineral"></Logo> {planet.mineralEfficiency}
      </span>
      <span style={{}}>
        <Logo logoName="science"></Logo> {planet.scienceEfficiency}
      </span>
      <span style={{}}>
        <Logo logoName="population"></Logo> {planet.populationEfficiency}
      </span>
      <span>
        <Logo logoName="energy"></Logo> {planet.energyEfficiency}
      </span>
    </div>
  );
};

const ObjectPagesData = {
  systemExplanation: {
    title: "System",
    aliases: ["system", "systems"],

    content: (
      <div>
        <RuleP>
          A system is a region of space that includes a space area and may also
          include:
        </RuleP>
        <RuleUl>
          <RuleLi>A planet.</RuleLi>
          <RuleLi>
            A <RuleLink name="wormholeExplanation">wormhole</RuleLink>.
          </RuleLi>
          <RuleLi>Heroes in the space area.</RuleLi>
          <RuleLi>
            A curiosity, represented by the background image of the system.
          </RuleLi>
        </RuleUl>
        <RuleImage name="object_spacearea"></RuleImage>
        <RuleImage name="object_system_planet"></RuleImage>
        <RuleImage name="object_system_wormhole"></RuleImage>
        <RuleImage name="object_system_hero"></RuleImage>
        <RuleImage name="object_curiosities"></RuleImage>
        <RuleP>
          Players can control the space area of a system. When they do, they
          control the system. Players can also control planets within systems,
          which is a separate type of control.
        </RuleP>
      </div>
    ),
    subsections: [
      {
        title: "System Control",
        aliases: ["control system"],

        content: (
          <div>
            <RuleP>
              A space area or a planet within a system can be controlled by a
              player or remain uncontrolled. Control is determined exclusively
              at the end of combat during the combat phase. The player with the
              highest morale at the end of combat gains control of the contested
              space area or planet.
            </RuleP>
          </div>
        ),
      },
      {
        title: "Special Features of Systems",
        content: (
          <div>
            <RuleP>
              Some systems contain special features, such as wormholes, which
              connect to other systems, or unique curiosities that impact
              gameplay. These features can significantly affect your strategy.
            </RuleP>
          </div>
        ),
      },
    ],
  },
  wormholeExplanation: {
    title: "Wormhole",
    aliases: ["wormhole", "wormholes"],
    content: (
      <div>
        <RuleP>
          Wormholes of the same color connect the{" "}
          <RuleLink name="systemExplanation">systems</RuleLink> they are in,
          making them adjacent.
        </RuleP>
        <RuleImage name="object_wormhole_adjacency"></RuleImage>
      </div>
    ),
  },
  planetExplanation: {
    title: "Planet",
    aliases: ["planet", "planets"],
    content: (
      <div>
        <RuleImage name="object_system_planet"></RuleImage>
        <RuleP>
          There are six types of planets, each with unique characteristics:
        </RuleP>
        <div style={{}}>
          <div>
            <DisplayPlanetType type="Terran"></DisplayPlanetType>
          </div>
          <div>
            <DisplayPlanetType type="Oceanic"></DisplayPlanetType>
          </div>
          <div>
            <DisplayPlanetType type="Desert"></DisplayPlanetType>
          </div>
          <div>
            <DisplayPlanetType type="Ice"></DisplayPlanetType>
          </div>
          <div>
            <DisplayPlanetType type="Gaz"></DisplayPlanetType>
          </div>
          <div>
            <DisplayPlanetType type="Volcanic"></DisplayPlanetType>
          </div>
        </div>

        <RuleP>
          On top of that, each planet can have some bonuses, modifying their
          behaviour which makes them more unique.
        </RuleP>
        <RuleP>Each planet can:</RuleP>
        <RuleUl>
          <RuleLi>Produce resources once per round.</RuleLi>
          <RuleLi>Build units once per round.</RuleLi>
        </RuleUl>
        <RuleP>
          A planet can either be controlled by a player or remain uncontrolled.
        </RuleP>

        <RuleP>
          A planet can have a maximum of 4 structures on it and an unlimited
          amount of ground forces.
        </RuleP>
        <RuleP>A planet can be invaded by players.</RuleP>
        <RuleP>
          A planet can be colonized during the first round of the game.
        </RuleP>
      </div>
    ),
  },
  planetBonusesExplanation: {
    title: "Planet Bonuses",
    aliases: ["bonus", "bonuses", "planet bonus"],
    content: (
      <div>
        <RuleP>
          A planet can have some bonuses attached to it. A bonus modifies its
          characteristics, enhancing or altering its behavior in specific ways.
          These bonuses play a critical role in shaping strategy and gameplay.
        </RuleP>
        <RuleP>Here are the different bonuses that a planet can have:</RuleP>
        {(() => {
          const DisplayPlanetBonus = ({ bonus, planetType }) => {
            const planet = createPlanet(planetType, [bonus]);
            const bonusObject = PlanetBonus.getBonus(bonus);
            return (
              <div style={{ textAlign: "center" }}>
                <div>
                  {" "}
                  <SpaceObjectImageView
                    spaceObject={planet}
                    showFleet={false}
                    showBonus={true}
                    maxWidth="100px"
                  />
                </div>
                <div> {bonusObject.effect} </div>
              </div>
            );
          };

          return (
            <div>
              <div>
                <DisplayPlanetBonus
                  bonus={PlanetBonus.NAME_MINERAL}
                  planetType="Gaz"
                />
              </div>
              <LineSpace></LineSpace>
              <div>
                <DisplayPlanetBonus
                  bonus={PlanetBonus.NAME_RESEARCH}
                  planetType="Gaz"
                />
              </div>
              <LineSpace></LineSpace>
              <div>
                <DisplayPlanetBonus
                  bonus={PlanetBonus.NAME_ENERGY}
                  planetType="Gaz"
                />
              </div>
              <LineSpace></LineSpace>
              <div>
                <DisplayPlanetBonus
                  bonus={PlanetBonus.NAME_POPULATION}
                  planetType="Gaz"
                />
              </div>
              <LineSpace></LineSpace>
              <div>
                <DisplayPlanetBonus
                  bonus={PlanetBonus.NAME_CREDIT}
                  planetType="Gaz"
                />
              </div>
              <LineSpace></LineSpace>
              <div>
                <DisplayPlanetBonus
                  bonus={PlanetBonus.NAME_BUILD}
                  planetType="Gaz"
                />
              </div>
              <LineSpace></LineSpace>
            </div>
          );
        })()}
      </div>
    ),
  },
  produceResourcesExplanation: {
    title: "Produce Resources",
    aliases: [
      "produce resources",
      "resource production",
      "producing resources",
    ],
    content: (
      <div>
        <RuleP>
          Each planet can produce resources once per round. Players must decide
          which resource the planet will produce during the round. The available
          options are:
        </RuleP>
        <RuleUl>
          <RuleLi>
            <Logo logoName="mineral"></Logo> Mineral
          </RuleLi>
          <RuleLi>
            <Logo logoName="science"></Logo> Science
          </RuleLi>
          <RuleLi>
            <Logo logoName="energy"></Logo> Energy
          </RuleLi>
        </RuleUl>
        <RuleP>
          In addition to producing one of the above resources, planets always
          produce population <Logo logoName="population"></Logo>.
        </RuleP>
      </div>
    ),
  },
  mineralExplanation: {
    title: "Mineral",
    aliases: ["mineral", "minerals"],
    content: (
      <div>
        <div>
          <Logo logoName="mineral" heightRem="3em"></Logo>
        </div>
        <RuleP>
          Mineral is one of the primary resources in the game and is mostly used
          to build units. It is essential for expanding your forces and
          maintaining control in the galaxy.
        </RuleP>
        <RuleP>
          Minerals are produced on planets that you control. Each planet can
          generate a certain amount of minerals per round, depending on its
          characteristics and bonuses.
        </RuleP>
      </div>
    ),
  },
  scienceExplanation: {
    title: "Science",
    aliases: ["science"],
    content: (
      <div>
        <div>
          <Logo logoName="science" heightRem="3em"></Logo>
        </div>
        <RuleP>
          Science is one of the primary resources in the game and is used to
          research technologies. It allows players to unlock new capabilities,
          improve their units, and gain strategic advantages.
        </RuleP>
      </div>
    ),
  },

  energyExplanation: {
    title: "Energy",
    aliases: ["energy", "energies"],
    content: (
      <div>
        <div>
          <Logo logoName="energy" heightRem="3em"></Logo>
        </div>
        <RuleP>
          Energy is one of the primary resources in the game and is mostly used
          to move units and increase the fleet limit. It is a critical resource
          for maintaining mobility and strategic positioning across the galaxy.
        </RuleP>
        <RuleP>
          Energy is reset to <strong>4</strong> for all players at the beginning
          of each round. This means that energy does not carry over from one
          round to the next, requiring players to carefully plan their movements
          and fleet management within the constraints of each round's energy
          supply.
        </RuleP>
      </div>
    ),
  },
  populationExplanation: {
    title: "Population",
    aliases: ["population", "populations"],
    content: (
      <div>
        <div>
          <Logo logoName="population" heightRem="3em"></Logo>
        </div>
        <RuleP>
          Population is a fundamental resource in the game, mostly used to build
          infantry and structures.
        </RuleP>
        <RuleP>
          Additionally, population is used to interact with heroes, enabling you
          to benefit from their powerful abilities.
        </RuleP>
        <RuleP>
          Population is produced automatically by planets each round, in
          addition to any other chosen resource like mineral, science, or
          energy.
        </RuleP>
      </div>
    ),
  },
  controlExplanation: {
    title: "Control",
    aliases: ["control", "system control", "planet control", "controlled"],
    content: (
      <div>
        <RuleP>
          Systems and planets can be controlled by factions. Control is only
          established during the combat phase, based on the outcome of battles.
        </RuleP>
        <RuleP>
          <strong>System Control:</strong> To take control of a system, a
          faction must have the highest morale at the end of the combat phase in
          that system. Morale represents the strength and determination of a
          faction's forces in space combat.
        </RuleP>
        <RuleP>
          <strong>Planet Control:</strong> Similarly, to take control of a
          planet, a faction must have the highest morale at the end of a ground
          combat phase on that planet. Ground forces and their effective
          deployment play a critical role in securing planetary control.
        </RuleP>
        <RuleP>
          Controlling systems and planets is essential for expanding territorial
          influence and gaining access to valuable resources. Carefully planning
          combat engagements and managing morale effectively are key to securing
          control.
        </RuleP>
        <RuleImage name="object_control_system"></RuleImage>
        <RuleImage name="object_control_planet"></RuleImage>
      </div>
    ),
  },
  itemExplanation: {
    title: "Items",
    aliases: ["item", "items", "relic", "relics", "artifact", "artifacts"],
    content: (
      <div>
        <RuleP>
          Items in <strong>Portal Empires</strong> enhance gameplay by providing
          powerful abilities and strategic options. Items are categorized into{" "}
          <strong>artifacts</strong> and <strong>relics</strong>, each with
          unique mechanics and purposes.
        </RuleP>
        <RuleP>
          <strong>Artifacts:</strong> These are special items that provide
          abilities and can be obtained primarily through interactions with
          heroes. Artifacts can have passive or active abilities.
        </RuleP>
        <RuleUl>
          <RuleLi>
            Artifacts with <strong>passive abilities</strong> are always active
            and provide their effects automatically.
          </RuleLi>
          <RuleLi>
            Artifacts with <strong>active abilities</strong> must be activated
            to use their effects. When activated, the artifact is{" "}
            <strong>exhausted</strong>.
          </RuleLi>
          <RuleLi>
            Artifacts that are exhausted are <strong>destroyed</strong> at the
            beginning of the next round, making them consumable and single-use.
          </RuleLi>
          <RuleLi>
            Artifacts can be exchanged between players through transactions,
            adding a layer of diplomacy and trade to the game.
          </RuleLi>
        </RuleUl>
        <RuleP>
          <strong>Relics:</strong> Relics are faction-specific items created by
          one faction and designed to be used by others. A relic always belongs
          to the faction that produces it, and only that faction can send it to
          another player.
        </RuleP>
        <RuleUl>
          <RuleLi>
            Relics can have <strong>passive</strong> or{" "}
            <strong>active abilities</strong>. Passive abilities provide their
            effects automatically, while active abilities must be activated and
            exhaust the relic.
          </RuleLi>
          <RuleLi>
            When a relic is exhausted, it is <strong>sent back</strong> to the
            producing faction at the beginning of the next round. This makes
            relics single-use for the receiving faction but reusable by others
            in future transactions.
          </RuleLi>
          <RuleLi>
            The producing faction cannot use its own relics, as their purpose is
            to benefit other factions and foster cooperation or alliances.
          </RuleLi>
        </RuleUl>
        <RuleImage name="item"></RuleImage>
      </div>
    ),
  },
  mapExplanation: {
    title: "Map",
    aliases: ["map", "galaxy map", "game map", "adjacent", "adjacency"],
    content: (
      <div>
        <RuleP>
          The galaxy in <strong>Portal Empires</strong> is composed of
          interconnected <strong>systems</strong>, forming the game’s map.{" "}
        </RuleP>
        <RuleP>
          The map is looping: the <strong>top edge</strong> connects to the{" "}
          <strong>bottom edge</strong>, creating a vertical loop. However, the{" "}
          <strong>left and right edges do not connect</strong>, maintaining a
          fixed horizontal boundary.
        </RuleP>
        <RuleP>
          Each faction starts at a <strong>symmetric position</strong> on the
          map, ensuring equal distances and positioning relative to other
          factions. This symmetry promotes fairness and balanced gameplay.
        </RuleP>
        <RuleP>
          <strong>Adjacency:</strong> Systems are considered adjacent if they
          share a border. Adjacency rules include:
        </RuleP>
        <RuleUl>
          <RuleLi>
            Systems sharing a direct border (not diagonal) are adjacent.
          </RuleLi>
          <RuleLi>
            Systems on the <strong>top and bottom edges</strong> of the map are
            adjacent if they share the same color of connection, preserving the
            looping nature of the galaxy.
          </RuleLi>
          <RuleLi>
            Systems containing the <strong>same color of wormhole</strong> are
            also considered adjacent, regardless of their location on the map.
          </RuleLi>
        </RuleUl>
        <RuleP>
          Understanding the map's layout, adjacency rules, and looping mechanics
          is critical for effective movement, control, and strategic planning.
          Leverage these mechanics to optimize fleet positioning and outmaneuver
          your opponents.
        </RuleP>
        <RuleImage name="map"></RuleImage>
        <RuleImage name="map_connection"></RuleImage>
      </div>
    ),
  },
  distanceExplanation: {
    title: "Distance",
    aliases: ["distance", "movement distance", "map distance"],
    content: (
      <div>
        <RuleP>
          <strong>Distance</strong> in <strong>Portal Empires</strong> is
          measured by the shortest sequence of adjacent systems from one system
          to another. Understanding distance is critical for planning movements,
          scoring objectives, and executing strategies effectively.
        </RuleP>
        <RuleP>There are two types of distance in the game:</RuleP>
        <RuleUl>
          <RuleLi>
            <strong>Movement Distance:</strong> This type of distance is used
            for fleet movement and takes into account:
            <RuleUl>
              <RuleLi>System adjacency based on borders.</RuleLi>
              <RuleLi>
                Adjacency provided by wormholes of the same color.
              </RuleLi>
              <RuleLi>
                Abilities or effects that reduce movement distance.
              </RuleLi>
            </RuleUl>
          </RuleLi>
          <RuleLi>
            <strong>Map Distance:</strong> This type of distance is used for
            scoring objectives and does not consider:
            <RuleUl>
              <RuleLi>Wormhole adjacency.</RuleLi>
              <RuleLi>
                Abilities or effects that modify movement distance.
              </RuleLi>
            </RuleUl>
            It is a purely geographical measurement based on direct system
            adjacency.
          </RuleLi>
        </RuleUl>
        <RuleP>
          Understanding the difference is essential for scoring appropriately,
          as objectives always reference <strong>map distance</strong>.
        </RuleP>
      </div>
    ),
  },
  factionsExplanation: {
    title: "Factions",
    aliases: ["faction", "factions"],
    content: (
      <div>
        <RuleP>
          The factions in <strong>Portal Empires</strong> are asymmetric, each
          with unique abilities and characteristics. These differences provide
          distinct strategic advantages, encouraging diverse playstyles and
          approaches to conquest, diplomacy, and combat.
        </RuleP>
      </div>
    ),
  },
  fleetLimitExplanation: {
    title: "Fleet Limit",
    aliases: ["fleet limit", "fleet size", "ship limit"],
    content: (
      <div>
        <Logo logoName="cargo" heightRem="3em">
          {" "}
        </Logo>
        <RuleP>
          Each player in <strong>Portal Empires</strong> has a fleet limit,
          which is the maximum number of non-fighter ships that can occupy a
          single system. This limit ensures balanced gameplay and requires
          careful planning for fleet composition and movement.
        </RuleP>
        <RuleP>
          If the number of non-fighter ships in a system exceeds the fleet
          limit, the palyer must <strong>scrap ships</strong> until the fleet
          size is within the allowed limit. This can result in strategic losses
          if fleet limits are not managed effectively.
        </RuleP>
        <RuleP>
          Commanders can increase their faction’s fleet limit by paying{" "}
          <strong>energy</strong>. However, the cost to increase the fleet limit
          rises with each additional level, making it progressively more
          expensive to expand your fleet capacity.
        </RuleP>
      </div>
    ),
  },
};

export default ObjectPagesData;
