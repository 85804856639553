const LogBook = require("../Connection/LogBook");
const LogMessage = require("../Connection/LogMessage");
const Map = require("../MapData/Map");
const TechTree = require("../Technology/TechTree");
const CustomMath = require("../../../Common/Math/CustomMath");
const Popup = require("../../Other/Popup");
const UIMessage = require("../Connection/UIMessage");
const Rules = require("../Game/Rules");

class HiddenScore {
  static getHiddenScore(playerData) {
    if (!playerData.hiddenScore) {
      playerData.hiddenScore = 0;
    }
    return playerData.hiddenScore;
  }

  static setHiddenScore(playerData, value) {
    playerData.hiddenScore = value;
  }

  static adminEndOfRound(playerData) {
    const planetList = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    const discoveredPlanets = [];

    for (const planet of planetList) {
      const proba = Rules.VP_HIDDEN_PLANET_PROBA;
      if (CustomMath.generateRandomReal(0, 1) < proba) {
        discoveredPlanets.push(planet.name); // Record the planet name.
      }
    }

    // Generate a single log entry if any discoveries were made.
    if (discoveredPlanets.length > 0) {
      const planetNames = discoveredPlanets.join(", ");
      const totalVp = discoveredPlanets.length * Rules.VP_HIDDEN_PER_PLANET;
      const logMessage =
        totalVp +
        " $logo$ : your explorers have discovered ancient sites on the following planets: " +
        planetNames +
        ". These remarkable discoveries grant you a total of " +
        totalVp +
        " hidden Victory Points due to the cultural fame they bring. (There is a " +
        (Rules.VP_HIDDEN_PLANET_PROBA * 100).toFixed(0) +
        "% chance to discover a ancient site per planet you control per round).";

      HiddenScore.addHiddenScore(
        playerData,
        totalVp,
        "Ancient sites discovered",
        logMessage,
        ["vp"]
      );
    }

    const discoveredSystems = [];

    const systemList = Map.getSystemsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    for (const system of systemList) {
      const proba = Rules.VP_HIDDEN_SYSTEM_PROBA;
      if (CustomMath.generateRandomReal(0, 1) < proba) {
        discoveredSystems.push(system.name); // Record the system name.
      }
    }

    // Generate a single log entry if any discoveries were made.
    if (discoveredSystems.length > 0) {
      const systemNames = discoveredSystems.join(", ");
      const totalVp = discoveredSystems.length * Rules.VP_HIDDEN_PER_SYSTEM;
      const logMessage =
        totalVp +
        " $logo$ : your explorers have discovered ancient ships in the following systems: " +
        systemNames +
        ". These remarkable finds grant you a total of " +
        totalVp +
        " hidden Victory Points due to the cultural fame they bring. (There is a " +
        (Rules.VP_HIDDEN_SYSTEM_PROBA * 100).toFixed(0) +
        "% chance of discovering an ancient ship per system you control per round).";

      HiddenScore.addHiddenScore(
        playerData,
        totalVp,
        "Ancient ships discovered",
        logMessage,
        ["vp"]
      );
    }
  }

  static addHiddenScore(
    playerData,
    amount,
    title,
    explanationText,
    explanationItems = []
  ) {
    if (!playerData.hiddenScore) {
      playerData.hiddenScore = 0;
    }
    playerData.hiddenScore = playerData.hiddenScore + amount;
    playerData.hiddenScore = CustomMath.roundDec(playerData.hiddenScore);

    const logBook = LogBook.createLogBook();
    LogBook.generateAddMessage(
      logBook,
      "Hidden VP : " + explanationText,
      explanationItems
    );
    UIMessage.sendInfoLogBookToClient(playerData, title, logBook);
  }

  static filterHiddenData(playerData) {
    playerData.hiddenScore = null;
  }
}

module.exports = HiddenScore;
