const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const SystemAction = require("../ActionCommon/SystemAction.js");
const ValidAction = require("../ActionCommon/WarningAction.js");
const WarningAction = require("../ActionCommon/WarningAction.js");
const Cost = require("../Utils/Cost.js");
const LogBook = require("../Connection/LogBook.js");
const LogAttachment = require("../Connection/LogAttachment.js");
const CheckAction = require("../ActionCommon/CheckAction.js");
const Phase = require("../Game/Phase.js");
const StaticGameData = require("../StaticGameData.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Request = require("../../../Common/Requests/Request.js");
const ActionCommonData = require("./ActionCommonData.js");

class LiftActionData {
  static isElligible(playerData, planet) {
    if (planet.faction === playerData.faction.name) {
      return true;
    } else {
      return false;
    }
  }

  static prepare(playerData, planet) {
    const data = {
      spaceFleet: Fleet.createFleet(playerData, playerData.faction.name),
      planetName: planet.name,
      fleetLifted: Fleet.createFleet(playerData, playerData.faction.name),
    };

    ActionCommonData.prepare(
      playerData,
      Phase.PHASE_ACTION,
      Phase.STEP_ACTION_LIFT,
      data
    );
  }

  static getCostLift(playerData) {
    const cost = Cost.createCost({});
    const costLift = PlayerData.getCostLift(playerData);
    const data = ActionCommonData.getData(playerData);
    if (!Fleet.isEmpty(data.fleetLifted)) {
      Cost.addCostToCost(costLift, cost);
    }

    return cost;
  }

  static liftUnit(playerData, planet, unit) {
    const data = ActionCommonData.getData(playerData);
    const spaceFleet = playerData.actionData.spaceFleet;

    if (unit === null) {
      throw new Error("Unit not found");
    }

    Fleet.addUnit(data.fleetLifted, unit);

    /*const liftedFleet = planetData.fleetLifted;
    const planetFleet = Planet.getFleet(planet, playerData.faction.name);
    Fleet.addUnit(liftedFleet, unit);
    Fleet.removeUnit(planetFleet, unit, true);
    Fleet.addUnit(spaceFleet, unit);*/
  }

  static removeLiftUnit(playerData, planet, unit) {
    const data = ActionCommonData.getData(playerData);
    if (unit === null) {
      throw new Error("Unit is null");
    }
    Fleet.removeUnit(data.fleetLifted, unit);

    /*const spaceFleet = playerData.activationData.liftStep.spaceFleet;

    if (unit === null) {
      throw new Error("Unit is null");
    }

    const liftedFleet = planetData.fleetLifted;
    const planetFleet = Planet.getFleet(planet, playerData.faction.name);
    Fleet.addUnit(planetFleet, unit);
    Fleet.removeUnit(liftedFleet, unit, true);
    Fleet.removeUnit(spaceFleet, unit, true);*/
  }

  static getSpaceAreaFleet(playerData) {
    return playerData.actionData.spaceFleet;
  }

  static resolveServer(playerData, resolveData) {
    ActionCommonData.resolveServerIni(playerData, resolveData);

    const data = ActionCommonData.getData(playerData);
    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);
    const system = Map.getSystemFromSpaceObject(planet, playerData.map);
    const spaceFleet = System.getFleetOrCreate(
      playerData,
      system,
      playerData.faction.name
    );

    if (!planet) {
      throw new Error("Planet not found to lift ground forces");
    }

    const planetFleet = Planet.getFleetOrCreate(
      playerData,
      planet,
      playerData.faction.name
    );

    const unitsToLift = Fleet.getUnits(data.fleetLifted);
    for (let j = 0; j < unitsToLift.length; j++) {
      const unit = unitsToLift[j];
      if (unit.class !== Fleet.UNIT_CLASS_GROUND_FORCE) {
        throw new Error("Only ground forces can be lifted");
      }

      Fleet.removeUnit(planetFleet, unit);
      Fleet.addUnit(spaceFleet, unit);
    }

    if (!Fleet.isEmpty(data.fleetLifted)) {
      const cost = this.getCostLift(playerData);
      PlayerData.spendCost(playerData, cost);

      /*System.logActivityToSpaceObject(system, planet, [
        {
          type: LogMessage.ITEM_TYPE_FACTION_LOGO,
          content: playerData.faction.name,
        },
        { content: " Lifted ground forces from " },
        {
          type: LogMessage.ITEM_TYPE_SPACE_OBJECT,
          content: planet,
        },
        {
          content: " ",
        },
        {
          type: LogMessage.ITEM_TYPE_FLEET,
          content: Fleet.getLogItem(data.fleetLifted),
        },
      ]);*/
    }

    Fleet.checkValidity(playerData, spaceFleet);
    ActionCommonData.cleanActionData(playerData);
  }
}

module.exports = LiftActionData;
