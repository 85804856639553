import React from "react";
import RuleImage from "../Utils/RuleImage";
import RuleP from "../Utils/RuleP";
import RuleUl from "../Utils/RuleUl";
import RuleLi from "../Utils/RuleLi";
import RuleLink from "../Utils/RuleLink";
import SpaceObject from "../../Data/GameData/MapData/SpaceObject";
import PlanetBonus from "../../Data/GameData/Bonus/PlanetBonus";
import PlanetView from "../../GameView/Object/PlanetView";
import SpaceObjectImageView from "../../GameView/Object/SpaceObjectImageView";
import BaseButton from "../../GameView/Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import ManagePlanetView from "../../GameView/ManageSystem/ManagePlanetView";
import Logo from "../../GameView/Utils/Logo";
import Planet from "../../Data/GameData/MapData/Planet";
import LineSpace from "../../GameView/Utils/Layout/LineSpace";
import TechTypeView from "../../GameView/Technology/TechTypeView";
import Color from "../../Common/Config/Colors";
import NonReplacableText from "./NonReplacableText";

const MechanicPagesData = {
  roundAndPhasesExplanation: {
    title: "Rounds and Phases",
    aliases: ["round", "phases", "game rounds", "game phases"],
    content: (
      <div>
        <RuleImage name="round_and_phases"></RuleImage>
        <RuleP>
          <strong>Portal Empires</strong> operates in simultaneous rounds, where
          all Commanders issue their orders, conduct trade, and perform
          transactions at the same time. Once orders are set, the game processes
          combat resolution, where battles and encounters are resolved
          automatically.
        </RuleP>
        <RuleP>
          Each game in <strong>Portal Empires</strong> lasts approximately 8
          rounds. The pace of each round varies:
        </RuleP>
        <RuleUl>
          <RuleLi>
            Some games resolve a round every 10 minutes, allowing for fast-paced
            gameplay.
          </RuleLi>
          <RuleLi>
            Others resolve one round per day, offering a more relaxed pace. For
            example, a game with 1-day rounds typically lasts about one week.
          </RuleLi>
        </RuleUl>
        <RuleP>Rounds are divided into four distinct phases:</RuleP>
        <RuleUl>
          <RuleLi>
            <strong>Beginning of Round Phase:</strong> Resource pools are reset,
            portals are removed, and other preparatory actions occur.
          </RuleLi>
          <RuleLi>
            <strong>Action Phase:</strong> Players take actions simultaneously
            without revealing them to others. This is when Commanders issue
            orders, conduct trade, and set their strategies for the round.
          </RuleLi>
          <RuleLi>
            <strong>Combat Phase:</strong> Battles and encounters are resolved.
            Control of systems and planets is determined based on combat
            outcomes.
          </RuleLi>
          <RuleLi>
            <strong>End of Round Phase:</strong> Post-combat events are
            processed, and the game transitions into the next round.
          </RuleLi>
        </RuleUl>
      </div>
    ),
  },
  beginningOfRoundExplanation: {
    title: "Beginning of Round",
    aliases: ["beginning of round", "beginning phase"],
    content: (
      <div>
        <RuleP>
          The <strong>Beginning of Round</strong> phase sets the stage for the
          upcoming round. Key game mechanics are reset, and players benefit from
          certain abilities and effects that trigger at this time.
        </RuleP>
        <RuleP>Here’s what happens during the Beginning of Round phase:</RuleP>
        <RuleUl>
          <RuleLi>
            <strong>Energy Reset:</strong> Each player's energy is reset to{" "}
            <strong>4</strong>, regardless of their energy usage in the previous
            round. This ensures that all players start the round with the same
            energy resources.
          </RuleLi>
          <RuleLi>
            <strong>Ability and Technology Effects:</strong> Players benefit
            from any effects or abilities that trigger at the start of the
          </RuleLi>
        </RuleUl>
      </div>
    ),
  },
  endOfRoundExplanation: {
    title: "End of Round Phase",
    aliases: ["end of round", "final phase", "round conclusion"],
    content: (
      <div>
        <RuleP>
          The <strong>End of Round</strong> phase marks the conclusion of a
          round in <strong>Portal Empires</strong>. The game checks if the
          maximum round limit (e.g., 8 rounds) has been reached. If the limit is
          reached, the game ends, and the final rankings are determined.
        </RuleP>
        <RuleP>
          If the round limit is not reached, the game proceeds to the next
          round. Otherwise, the game concludes, and the final rankings determine
          the overall winner.
        </RuleP>
      </div>
    ),
  },
  actionPhaseExplanation: {
    title: "Action Phase",
    aliases: ["action phase", "player actions", "round actions"],
    content: (
      <div>
        <RuleP>
          The <strong>Action Phase</strong> is where players execute their
          strategies and make critical decisions in{" "}
          <strong>Portal Empires</strong>. This phase is conducted
          simultaneously, with each player taking their actions privately,
          without visibility into what others are doing.
        </RuleP>
        <RuleP>During the Action Phase, players can:</RuleP>
        <RuleUl>
          <RuleLi>
            Issue orders to their units, such as moving ships, invading planets,
            or setting up defenses.
          </RuleLi>
          <RuleLi>
            Build units or structures on their controlled planets, provided they
            have the necessary resources and infrastructure.
          </RuleLi>
          <RuleLi>
            Manage resources, including producing resources on planets and
            utilizing abilities or technologies that grant resource bonuses.
          </RuleLi>
          <RuleLi>
            Trade and execute transactions with other players, such as
            exchanging resources or negotiating alliances.
          </RuleLi>
          <RuleLi>
            Chat with other players to strategize, negotiate, or form temporary
            alliances. Communication is an essential part of diplomacy and
            outmaneuvering opponents.
          </RuleLi>
        </RuleUl>
        <RuleP>
          The simultaneous nature of the Action Phase creates an atmosphere of
          secrecy and unpredictability, as players must anticipate their rivals'
          moves without knowing their exact plans. Strategic planning and
          resource management are crucial for making the most of this phase.
        </RuleP>
      </div>
    ),
  },
  combatPhaseExplanation: {
    title: "Combat Phase",
    aliases: ["combat phase", "battle resolution", "fighting phase"],
    content: (
      <div>
        <RuleP>
          The <strong>Combat Phase</strong> is where battles and contested
          actions are resolved in <strong>Portal Empires</strong>. This phase
          determines control of systems, planets, and the outcome of any
          conflicts between players.
        </RuleP>
        <RuleP>
          During the Combat Phase, the game processes all battles in the
          following sequence:
        </RuleP>
        <RuleUl>
          <RuleLi>
            <strong>Space Combat:</strong> Conflicts in contested systems are
            resolved first. Each faction's fleet engages in combat, and the
            faction with the highest remaining{" "}
            <RuleLink name="moraleExplanation">morale</RuleLink> at the end
            gains control of the system.
          </RuleLi>
          <RuleLi>
            <strong>Ground Combat:</strong> If a faction has issued an invasion
            order for a planet in a contested system and has ground forces
            present, ground combat is resolved. Control of the planet is
            determined by the faction with the highest morale after ground
            combat.
          </RuleLi>
        </RuleUl>
        <RuleP>
          Once all combats have been resolved, control of systems and planets is
          updated, and the game moves to the{" "}
          <RuleLink name="endOfRoundExplanation">End of Round Phase</RuleLink>.
        </RuleP>
        <RuleImage name="combat_example"></RuleImage>
      </div>
    ),
  },
  combatExplanation: {
    title: "Combat",
    aliases: ["combat", "battle", "fight mechanics"],
    content: (
      <div>
        <RuleP>
          Combat in <strong>Portal Empires</strong> is resolved over three
          combat <NonReplacableText text="rounds" />. Each combat{" "}
          <NonReplacableText text="round"></NonReplacableText> involves fleets
          generating hits, distributing damage, and determining which faction
          retains the most morale at the end of the engagement.
        </RuleP>
        <RuleP>
          Here's how a combat <NonReplacableText text="round" /> works:
        </RuleP>
        <RuleUl>
          <RuleLi>
            <strong>Unit Hit Generation:</strong> Each fleet produces hits based
            on the combat values of its units. Every unit has a combat pair of
            values (minimum and maximum), such as <strong>0.3</strong> and{" "}
            <strong>1.3</strong>. During each combat{" "}
            <NonReplacableText text="round" />, each unit generates a hit amount
            randomly between its minimum and maximum values. The total hits
            generated by all units in the fleet are summed up to determine the
            fleet's total hits for that <NonReplacableText text="round" />.
          </RuleLi>
          <RuleLi>
            <strong>Hit Distribution:</strong> After all fleets generate their
            hits, the hits are distributed among the fleets. If there are more
            than 2 fleets in the combat, the hits are distributed proportionally
            based on the remaining HP of each fleet. Fleets with more HP have a
            greater presence in the combat and thus receive a larger share of
            the hits.
          </RuleLi>
          <RuleLi>
            <strong>Unit Damage Assignment:</strong> Each fleet assigns the
            received hits to its units. Hits are always rounded down to an
            integer value, and each hit is assigned to a random HP point of a
            unit in the fleet.
          </RuleLi>
        </RuleUl>
        <RuleP>
          Once all three <NonReplacableText text="rounds" /> of combat are
          completed, the faction with the highest remaining morale gains control
          of the system or planet where the combat occurred.
        </RuleP>
        <RuleImage name="combat_produce_hits"></RuleImage>
        <RuleImage name="combat_assign_hits_1"></RuleImage>
        <RuleImage name="combat_assign_hits_2"></RuleImage>
      </div>
    ),
  },
  abilitiesExplanation: {
    title: "Abilities",
    aliases: ["abilities", "ability"],
    content: (
      <div>
        <RuleP>
          <strong>Abilities</strong> in <strong>Portal Empires</strong> enhance
          gameplay by providing unique effects and powers to factions,
          technologies, units, and items. Abilities can be classified as{" "}
          <strong>passive</strong> or <strong>active</strong>.
        </RuleP>
        <RuleP>
          <strong>Passive Abilities:</strong> These abilities are always in a
          ready state and provide their benefits automatically. They activate
          during specific steps of the game without requiring player input.
        </RuleP>
        <RuleP>
          <strong>Active Abilities:</strong> These abilities must be manually
          activated by the player to use their effects. Active abilities operate
          as follows:
        </RuleP>
        <RuleUl>
          <RuleLi>
            Active abilities start in the <strong>ready state</strong>, making
            them available for use.
          </RuleLi>
          <RuleLi>
            When a player activates an ability, it is <strong>exhausted</strong>{" "}
            and cannot be used again until it is readied.
          </RuleLi>
          <RuleLi>
            All exhausted active abilities are <strong>readied</strong> at the
            start of the{" "}
            <RuleLink name="beginningOfRoundExplanation">
              Beginning of Round Phase
            </RuleLink>
            , making them available for the next round.
          </RuleLi>
        </RuleUl>
        <RuleP>
          Players can view all their abilities, both passive and active, in the{" "}
          <strong>Abilities Screen</strong>. This screen provides an overview of
          abilities available for use and helps players plan their strategies
          effectively.
        </RuleP>
        <RuleP>Abilities can come from various sources, including:</RuleP>
        <RuleUl>
          <RuleLi>
            <strong>Faction Abilities:</strong> Unique abilities tied to the
            player’s faction, providing powerful advantages.
          </RuleLi>
          <RuleLi>
            <strong>Technology Abilities:</strong> Unlocked through research,
            these abilities enhance gameplay options and strategies.
          </RuleLi>
          <RuleLi>
            <strong>Unit Abilities:</strong> Specific units possess abilities
            that activate during certain steps of the game (for example during
            combat). These are typically passive abilities that provide benefits
            automatically.
          </RuleLi>
          <RuleLi>
            <strong>Item Abilities:</strong> Granted by artefact or relics,
            these abilities can offer unique tactical options.
          </RuleLi>
        </RuleUl>
        <RuleImage name="abilities_all"></RuleImage>
      </div>
    ),
  },
};

export default MechanicPagesData;
