import React, { useState, useEffect } from "react";

import UIMessage from "../../../Data/GameData/Connection/UIMessage";
import PlayerData from "../../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../../Data/GameData/StaticGameData";
import ActivationData from "../../../Data/GameData/Activation/ActivationData";
import BaseButton from "../../Utils/BaseButton";
import FleetSummaryView from "../FleetSummaryView";
import UnitLineView from "../../Units/UnitLineView";
import UnitSelectionView from "../../Units/UnitSelectionView";
import SelectionListView from "../../Utils/Layout/SelectionListView";
import MoveActionData from "../../../Data/GameData/ActionData/MoveActionData";
import FleetLineView from "../../Object/FleetLineView";
import ActionMoveView from "./ActionMoveView";
import GameGlobalLayout from "../../GameGlobalLayout";
import NavigationView from "../../NavigationView";
import ActionModifyFleetLimitView from "./ActionModifyFleetLimitView";
import Rules from "../../../Data/GameData/Game/Rules";
import Phase from "../../../Data/GameData/Game/Phase";
import ActionProductionView from "./ActionProductionView";
import Color from "../../../Common/Config/Colors";
import ActionLiftView from "./ActionLiftView";
import ActionDropView from "./ActionDropView";
import ActionBuildUnitView from "./ActionBuildUnitView";
import ActionBuildStructureView from "./ActionBuildStructureView";
import ActionTransferView from "./ActionTransferView";
import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData";
import MandatoryActionNotAllowedOtherAction from "../../MandatoryActionView.jsx/MandatoryActionNotAllowedOtherAction";
import ActionBuyView from "./ActionBuyView";

const SystemAction = require("../../../Data/GameData/ActionCommon/SystemAction.js");
const Request = require("../../../Common/Requests/Request.js");
const Popup = require("../../../Data/Other/Popup");
const System = require("../../../Data/GameData/MapData/System.js");
const Fleet = require("../../../Data/GameData/MapData/Fleet.js");
const ActivationCA = require("../../../ClientAction/ActivationCA");

const ActionGlobalView = () => {
  const playerData = StaticGameData.getPlayerData();
  const step = playerData.step;
  const activeView = StaticGameData.popup.actionView;

  if (PlayerData.isThereMandatoryAction(playerData)) {
    return (
      <MandatoryActionNotAllowedOtherAction></MandatoryActionNotAllowedOtherAction>
    );
  }

  const getHelpReference = () => {
    if (step === Phase.STEP_ACTION_MOVE) return "moveAction";
    if (step === Phase.STEP_ACTION_PRODUCE) return "productionAction";
    if (step === Phase.STEP_ACTION_LIFT) return "liftAction";
    if (step === Phase.STEP_ACTION_DROP) return "dropAction";
    if (step === Phase.STEP_ACTION_BUILD_UNIT) return "buildUnitAction";
    if (step === Phase.STEP_ACTION_BUILD_STRUCTURE)
      return "buildStructureAction";
    if (step === Phase.STEP_ACTION_TRANSFER) return "transferAction";
    return null;
  };

  const navigationData = { buttonsData: [], taskBarData: null };
  const helpReference = getHelpReference();
  if (helpReference) {
    console.log("ActionGlobalView helpReference: ", helpReference);
    navigationData.buttonsData.push({
      text: "?",
      callback: () => {
        Popup.addLayer({
          name: "HelpPopup",
          helpReference: helpReference,
        });
      },
    });
  }

  //console.log("ActionGlobalView step: ", step);

  return (
    <GameGlobalLayout style={{ height: "100%" }} backgroundType="shadow">
      <div
        className="text-box"
        style={{
          height: "100%",
          //backgroundColor: Color.COLOR_MANAGE_VIEW_BACKGROUND,
        }}
      >
        {step === Phase.STEP_ACTION_MOVE && (
          <ActionMoveView navigationData={navigationData}></ActionMoveView>
        )}
        {step === Phase.STEP_MODIFY_FLEET_LIMIT && (
          <ActionModifyFleetLimitView
            navigationData={navigationData}
          ></ActionModifyFleetLimitView>
        )}
        {step === Phase.STEP_ACTION_PRODUCE && (
          <ActionProductionView
            navigationData={navigationData}
          ></ActionProductionView>
        )}
        {step === Phase.STEP_ACTION_LIFT && (
          <ActionLiftView navigationData={navigationData}></ActionLiftView>
        )}
        {step === Phase.STEP_ACTION_DROP && (
          <ActionDropView navigationData={navigationData}></ActionDropView>
        )}
        {step === Phase.STEP_ACTION_BUILD_UNIT && (
          <ActionBuildUnitView
            navigationData={navigationData}
          ></ActionBuildUnitView>
        )}
        {step === Phase.STEP_ACTION_BUILD_STRUCTURE && (
          <ActionBuildStructureView
            navigationData={navigationData}
          ></ActionBuildStructureView>
        )}
        {step === Phase.STEP_ACTION_TRANSFER && (
          <ActionTransferView
            navigationData={navigationData}
          ></ActionTransferView>
        )}
        {step === Phase.STEP_ACTION_BUY_RESOURCES && (
          <ActionBuyView navigationData={navigationData}></ActionBuyView>
        )}
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default ActionGlobalView;

//
