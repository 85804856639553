class Style {
  static STYLE_MAP_TEXT_BOX = {
    backgroundColor: "RGBA(0,0,0,0.8)",
    color: "white",
    paddingLeft: "0.2em",
    paddingRight: "0.2em",
    borderRadius: "20%", // Set borderRadius to 50% for a circular shape
    fontSize: "12px",
    whiteSpace: "nowrap",
  };
}

module.exports = Style;
