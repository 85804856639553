import React, { useState } from "react";
import "../CSS/Font.css";
import "../CSS/Menu.css";
import ResourceView from "./Label/ResourceView.jsx";

const Color = require("../Common/Config/Colors");

const StaticGameData = require("../Data/GameData/StaticGameData");

const Banner = ({ playerState }) => {
  /*const containerStyle = {
    top: "0",
    left: "0",
    height: "5vh",
    width: "100vw",
    backgroundColor : 
  };*/

  if (!playerState) {
    return;
  }
  if (!playerState.faction) {
    return;
  }

  const playerFaction = playerState.faction;
  const playerResources = [
    playerState.credit,
    playerState.tech,
    playerState.expert,
    playerState.support,
    playerState.order,
  ];

  return (
    <div className="banner">
      <div className="div-hor-center" style={{ fontSize: "150%" }}>
        <div className="div-fill" style={{ width: "30%" }}>
          <span>{playerFaction.factionName}</span>
        </div>
        {playerResources.map((resource, index) => (
          <div key={index} className="div-fill" style={{ width: "14%" }}>
            <span>
              <ResourceView
                key={index}
                resource={resource}
                abbreviationMode={false}
                symbolMode={true}
              ></ResourceView>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Banner;
