import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo";
import ScoreData from "../../Data/GameData/Objectifs/ScoreData";
import FactionSpan from "../Utils/FactionSpan";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import Transaction from "../../Data/GameData/Transactions/Transaction";
import Logo from "../Utils/Logo";
import ItemView from "./ItemView";
import ChatStringView from "../ChatView/ChatStringView";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");

const TransactionView = ({
  transaction,
  buttonType = "white",
  onClick = () => {},
  detailedView = false,
}) => {
  const proposingFaction = transaction.proposingFaction;
  const receivingFaction = transaction.receivingFaction;

  const creditBalance = Transaction.getCreditBalance(transaction);
  const proposingArray = [];
  const receivingArray = [];

  const commaComponent = <span>, </span>;

  const addCreditComponent = () => {
    if (creditBalance > 0) {
      proposingArray.push(
        <span>
          {" "}
          {creditBalance} <Logo logoName="credit"></Logo>
        </span>
      );
      proposingArray.push(commaComponent);
    }
    if (creditBalance < 0) {
      receivingArray.push(
        <span>
          {" "}
          {-creditBalance} <Logo logoName="credit"></Logo>
        </span>
      );
      receivingArray.push(commaComponent);
    }
  };

  const addItemsComponents = ({ items, array }) => {
    let key = 0;

    items.map((item) => {
      array.push(
        <span>
          <ItemView item={item}></ItemView>
        </span>
      );
      array.push(commaComponent);
    });
  };

  addCreditComponent();
  addItemsComponents({
    items: Transaction.getItemsOut(transaction),
    array: proposingArray,
  });

  addItemsComponents({
    items: Transaction.getItemsIn(transaction),
    array: receivingArray,
  });

  //Removing comma at the end
  proposingArray.pop();
  receivingArray.pop();

  return (
    <div>
      <BaseButton type={buttonType} onClick={onClick}>
        <div>
          <FactionLogo factionName={proposingFaction}></FactionLogo>{" "}
          {detailedView && <span>offers</span>} :
          {proposingArray.map((item, index) => {
            return <span key={index}> {item} </span>;
          })}
        </div>
        {Transaction.getTextOut(transaction) && (
          <div
            style={
              {
                /*textAlign: "center"*/
              }
            }
          >
            <ChatStringView
              string={Transaction.getTextOut(transaction)}
            ></ChatStringView>
          </div>
        )}
        <hr></hr>
        <div>
          <FactionLogo factionName={receivingFaction}></FactionLogo>{" "}
          {detailedView && <span>offers</span>} :
          {receivingArray.map((item, index) => {
            return <span key={index}> {item} </span>;
          })}
        </div>
        {Transaction.getTextIn(transaction) && (
          <div
            style={
              {
                /*textAlign: "center"*/
              }
            }
          >
            <ChatStringView
              string={Transaction.getTextIn(transaction)}
            ></ChatStringView>
          </div>
        )}
      </BaseButton>
    </div>
  );
};

export default TransactionView;
