import React, { useState, useEffect } from "react";
import Color from "../../Common/Config/Colors";
import Law from "../../Data/GameData/Law/Law";
import BaseButton from "../Utils/BaseButton";
import LineSpace from "../Utils/Layout/LineSpace";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import Popup from "../../Data/Other/Popup";
import ResolveLaw from "../../Data/GameData/Law/ResolveLaw";
import Phase from "../../Data/GameData/Game/Phase";
import ActionCommonData from "../../Data/GameData/ActionData/ActionCommonData";
import Logo from "../Utils/Logo";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");

const LawDetailedView = ({ law, voting = false, voted = false }) => {
  const playerData = StaticGameData.getPlayerData();

  const lawDesc = Law.createDescription(law);

  const playerVote = Law.getPlayerVote(playerData);

  const DisplayVote = () => {
    return (
      <div style={{ marginTop: "1em" }}>
        {playerVote !== law.name ? (
          <BaseButton
            onClick={() => {
              ActionCommonData.resolveClient(
                playerData,
                { lawName: law.name },
                Phase.PHASE_ACTION,
                Phase.STEP_VOTE_ACTION
              );
            }}
          >
            Vote for this law
          </BaseButton>
        ) : (
          <div>
            <div>
              <div style={{ color: "cyan" }}>
                You are voting for this law :{" "}
                {ResolveLaw.getVoteAmount(playerData.map, playerData)} votes{" "}
                <Logo logoName="vote"></Logo>
              </div>
              <BaseButton
                onClick={() => {
                  ActionCommonData.resolveClient(
                    playerData,
                    { lawName: null },
                    Phase.PHASE_ACTION,
                    Phase.STEP_VOTE_ACTION
                  );
                }}
              >
                Cancel voting for this law
              </BaseButton>{" "}
            </div>{" "}
          </div>
        )}
      </div>
    );
  };

  const DisplayLaw = ({ lawDesc }) => {
    return (
      <div className="text-box" style={{ textAlign: "center" }}>
        <div style={{ fontSize: "1.5em" }}>
          <div style={{ marginTop: "0.5em" }}>
            <span style={{ color: "cyan" }}> {lawDesc.name}</span>
          </div>
        </div>
        <LineSpace></LineSpace>
        <div
          style={{
            marginTop: "0.5em",
            backgroundColor: Color.COLOR_GREY_3,
            padding: "10px",
          }}
        >
          {lawDesc.description}
        </div>
        <LineSpace></LineSpace>
        {voting && <DisplayVote></DisplayVote>}
        <LineSpace></LineSpace>
        {voting && (
          <div>
            This law, if it is voted, will be active during the next round.
            <LineSpace></LineSpace>
          </div>
        )}
        <div>
          For now, you have a total of{" "}
          {ResolveLaw.getVoteAmount(playerData.map, playerData)} votes{" "}
          <Logo logoName="vote"></Logo>.
        </div>
        <div>
          {ResolveLaw.getVoteFromItems(playerData)}{" "}
          <Logo logoName="vote"></Logo> from items.
        </div>
        <div>
          {ResolveLaw.getVoteFromPlanets(playerData.map, playerData)}{" "}
          <Logo logoName="vote"></Logo> from planets.
        </div>
        <LineSpace></LineSpace>
        <div>Political relics give you vote equal to their value.</div>
        <div>Planets with vote bonus gives additional votes.</div>
      </div>
    );
  };

  const navigationData = {
    buttonsData: [],
  };
  navigationData.buttonsData.push({
    text: "Back",
    callback: () => {
      Popup.goBack();
    },
  });

  return (
    <GameGlobalLayout title={null} backgroundType="shadow">
      <div
        style={{
          height: "100%",
        }}
      >
        <DisplayLaw lawDesc={lawDesc}></DisplayLaw>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default LawDetailedView;
