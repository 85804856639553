import React from "react";
import RuleImage from "../Utils/RuleImage";
import RuleP from "../Utils/RuleP";
import RuleUl from "../Utils/RuleUl";
import RuleLi from "../Utils/RuleLi";
import RuleLink from "../Utils/RuleLink";
import NonReplacableText from "./NonReplacableText";

const UnitPagesData = {
  range: {
    title: "Range",
    content: (
      <div>
        <RuleP>
          Each ship in the game has a defined range, representing the maximum
          distance it can move from the system it currently occupies. Movement
          is restricted to adjacent squares that share a direct border with the
          current location—diagonal movement is not allowed.
        </RuleP>
        <RuleImage name="movement_adjacency"></RuleImage>
      </div>
    ),
  },
  moraleExplanation: {
    title: "Morale",
    aliases: ["morale", "unit morale", "combat morale"],
    content: (
      <div>
        <RuleP>
          Morale is a measure of a unit's strength and determination in combat.
          It plays a critical role in determining control during the combat
          phase.
        </RuleP>
        <RuleP>
          Each unit has a base morale value, which represents its potential
          contribution to the faction's overall morale in combat. However, the
          effective morale contributed by a unit at the end of combat is
          adjusted based on the unit's remaining health.
        </RuleP>
        <RuleP>
          The morale of a unit at the end of combat is prorated by the
          percentage of its remaining HP. For example, a unit at 50% health will
          contribute only 50% of its base morale to the faction's total morale
          for that combat.
        </RuleP>
        <RuleP>
          Managing unit health and morale effectively is critical for achieving
          victory in combat and securing control of systems and planets.
        </RuleP>
        <div>
          <RuleImage name="unit_morale"></RuleImage>
          <RuleImage name="unit_morale_combat"></RuleImage>
        </div>
      </div>
    ),
  },
  rangeExplanation: {
    title: "Range",
    aliases: ["range", "movement range", "ship range"],
    content: (
      <div>
        <RuleP>
          Range determines the number of adjacent systems a unit can traverse in
          a single movement. Each unit has a specific range value that indicates
          how far it can travel during a single round.
        </RuleP>
        <RuleP>
          To move to a system, the destination must be within the unit's range
          and connected by valid system routes. A portal must also be created in
          the destination system to facilitate movement. Units cannot exceed
          their maximum range, even with a portal.
        </RuleP>
        <RuleP>
          Certain technologies and bonuses can increase a unit's range, allowing
          them to traverse more systems and gain greater strategic mobility.
          Some units naturally have a longer range, making them valuable for
          striking distant targets or supporting remote systems.
        </RuleP>
        <RuleP>
          Planning movements based on unit range is essential for efficient
          fleet deployment. Position your fleets strategically to maintain
          control, reinforce allies, or prepare for offensive maneuvers.
        </RuleP>
        <RuleImage name="unit_range"></RuleImage>
      </div>
    ),
  },
  capacityExplanation: {
    title: "Capacity",
    aliases: [
      "capacity",
      "transport capacity",
      "fleet capacity",
      "required capacity",
    ],
    content: (
      <div>
        <RuleP>
          Capacity represents the ability of certain units to transport other
          units. It ensures that fleets can operate efficiently by balancing the
          units requiring transport with the transport capability of the fleet.
        </RuleP>
        <RuleP>
          Some units, such as ground forces and fighter ships, cannot move on
          their own and must be transported. These units have a{" "}
          <strong>required capacity</strong> value, which represents the space
          needed to transport them.
        </RuleP>
        <RuleP>
          Other units, such as carriers, dreadnoughts, and hellmoons, have a{" "}
          <strong>capacity</strong> value, which allows them to transport other
          units. A fleet is valid for movement if the total required capacity of
          all units needing transport is less than or equal to the total
          available capacity of the transporting units.
        </RuleP>
        <RuleP>
          If, at any time, the total required capacity of units in a system
          exceeds the available capacity, the Commander must scrap units until
          the required capacity is equal to or less than the available capacity.
          This ensures fleets remain operational and within their transport
          limits.
        </RuleP>

        <RuleImage name="unit_capacity"></RuleImage>
        <RuleImage name="unit_required_capacity"></RuleImage>
      </div>
    ),
  },
  unitExplanation: {
    title: "Unit",
    aliases: ["unit", "units", "game units"],
    content: (
      <div>
        <RuleP>
          Units are the backbone of your forces in{" "}
          <strong>Portal Empires</strong>. There are 3 classes of units : ships,
          ground forces, and structures.
        </RuleP>
        <RuleP>Units can have the following characteristics:</RuleP>
        <RuleUl>
          <RuleLi>
            <strong>HP</strong>
          </RuleLi>
          <RuleLi>
            <RuleLink name="unitCombatValueExplanation">
              <NonReplacableText text="Combat value"></NonReplacableText>
            </RuleLink>
          </RuleLi>
          <RuleLi>
            <strong>Capacity</strong>
          </RuleLi>
          <RuleLi>
            <strong>Required Capacity</strong>
          </RuleLi>
          <RuleLi>
            <strong>Range</strong>
          </RuleLi>
          <RuleLi>
            <strong>Base Morale</strong>
          </RuleLi>
        </RuleUl>
        <RuleP>
          In addition to these basic characteristics, units may also have{" "}
          <strong>unit abilities</strong> that provide additional advantages or
          unique effects during the game.
        </RuleP>
        <RuleImage name="unit"></RuleImage>
      </div>
    ),
  },
  unitCombatValueExplanation: {
    title: "Unit Combat Value",
    aliases: [],
    content: (
      <div>
        <RuleP>
          In <strong>Portal Empires</strong>, each unit has a combat value that
          determines its effectiveness during battles. This combat value is
          represented as a pair of numbers: a <strong>minimum</strong> value and
          a <strong>maximum</strong> value. For example, a unit may have a
          combat value of <strong>0.3</strong> to <strong>1.3</strong>.
        </RuleP>
        <RuleP>
          During a combat <NonReplacableText text="round"></NonReplacableText>,
          each unit generates a random hit amount within its combat range. This
          simulates variability in performance, making each battle unique and
          dynamic.
        </RuleP>

        <RuleImage name="unit_combat"></RuleImage>
      </div>
    ),
  },
  hitPointsExplanation: {
    title: "Hit Points (HP)",
    aliases: ["hp", "hit points", "unit health"],
    content: (
      <div>
        <RuleP>
          <strong>Hit Points (HP)</strong> represent the health of a unit in{" "}
          <strong>Portal Empires</strong>. A unit's HP determines how much
          damage it can withstand during combat before being destroyed.
        </RuleP>
        <RuleP>
          During combat, units take damage when their fleet is assigned hits.
          Each hit reduces the HP of a random unit in the fleet.
        </RuleP>
        <RuleP>
          If a unit's HP reaches <strong>0</strong>, the unit is destroyed and
          removed from the fleet.
        </RuleP>
        <RuleImage name="unit_hp"></RuleImage>
      </div>
    ),
  },
};

export default UnitPagesData;
