import React, { useState, useEffect } from "react";

import StaticGameData from "../../../Data/GameData/StaticGameData.js";
import InteractionFunctions from "../../UIInteraction/InteractionFunctions.jsx";
import AdminActionData from "../../../Data/GameData/ActionAdminData/AdminActionData.js";
import ActionCommonData from "../../../Data/GameData/ActionData/ActionCommonData.js";

const Popup = require("../../../Data/Other/Popup.js");

class AdminRemoveUnitInput {
  static prepare = (playerData) => {
    AdminActionData.prepare(playerData);

    const selectUnitsInSystem = (system) => {
      console.log("selectUnitsInSystem", system.name);
      InteractionFunctions.selectUnitsInSystem({
        system: system,
        selectedUnits: [],
        callback: (units) => {
          const data = ActionCommonData.getData(playerData);
          data.removeUnitData = { units: units };
          Popup.unStackToMap();
          Popup.resetMapNavigationData();
          AdminActionData.resolveClient(
            playerData,
            AdminActionData.ACTION_REMOVE_UNIT
          );
        },
        text: "Select units to remove",
      });
    };

    InteractionFunctions.selectASystem((system) => {
      selectUnitsInSystem(system);
    }, "Select system to remove units from");
  };
}

export default AdminRemoveUnitInput;
