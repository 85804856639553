const CustomMath = require("../../../Common/Math/CustomMath");
const GameGlobalFunctions = require("../../Other/GameGlobalFunctions");
const UIMessage = require("../Connection/UIMessage");

class DraftData {
  static create(factionList, heroList, mapPositions, playerInGameIdList) {
    const data = {
      draftFinished: false,
      factions: factionList.map((faction) => {
        return {
          faction: faction,
          factionPicked: false,
        };
      }),
      heros: heroList.map((hero) => {
        return {
          hero: hero,
          heroPicked: false,
        };
      }),
      mapPositions: mapPositions.map((mapPosition, index) => {
        const name = String.fromCharCode(65 + index); // 65 is the ASCII code for 'A'
        return {
          mapPosition: mapPosition,
          mapPositionPicked: false,
          name: name, // Adding the name (A, B, C, etc.)
        };
      }),
      players: playerInGameIdList.map((playerInGameId) => {
        return {
          playerInGameId: playerInGameId,
          faction: null,
          hero: null,
          mapPosition: null,
        };
      }),
    };

    CustomMath.shuffleArray(data.players);

    data.activePlayerIdTurnList = data.players
      .map((player) => player)
      .concat(data.players.map((player) => player).reverse()) // Reverse the second series
      .concat(data.players.map((player) => player)); // Third series as normal

    return data;
  }

  static getActivePlayer(draftData) {
    return draftData.activePlayerIdTurnList[0];
  }

  static nextTurn(draftData) {
    draftData.activePlayerIdTurnList.shift();

    if (this.hasAllPlayersPicked(draftData)) {
      this.finishDraft(draftData);
    }
  }

  static hasAllPlayersPicked(draftData) {
    return draftData.players.every((player) => {
      return player.faction && player.heroName && player.mapPosition;
    });
  }

  static isFactionPicked(draftData, factionName) {
    return draftData.factions.find(
      (faction) => faction.faction.name === factionName
    ).factionPicked;
  }

  static isHeroPicked(draftData, heroName) {
    return draftData.heros.find((hero) => hero.hero.name === heroName)
      .heroPicked;
  }

  static isMapPositionPicked(draftData, mapPositionP) {
    return draftData.mapPositions.find(
      (mapPosition) =>
        mapPosition.mapPosition.x === mapPositionP.x &&
        mapPosition.mapPosition.y === mapPositionP.y
    ).mapPositionPicked;
  }

  static pickFaction(draftData, factionName, playerInGameId) {
    draftData.factions.find(
      (faction) => faction.faction.name === factionName
    ).factionPicked = true;
    draftData.players.find(
      (player) => player.playerInGameId === playerInGameId
    ).faction = factionName;
  }

  static pickHero(draftData, heroName, playerInGameId) {
    draftData.heros.find(
      (hero) => hero.hero.name === heroName
    ).heroPicked = true;
    draftData.players.find(
      (player) => player.playerInGameId === playerInGameId
    ).heroName = heroName;
  }

  static pickMapPosition(draftData, mapPositionP, playerInGameId) {
    const pickedMapPosition = draftData.mapPositions.find(
      (mapPosition) =>
        mapPosition.mapPosition.x === mapPositionP.x &&
        mapPosition.mapPosition.y === mapPositionP.y
    );
    pickedMapPosition.mapPositionPicked = true;
    draftData.players.find(
      (player) => player.playerInGameId === playerInGameId
    ).mapPosition = JSON.parse(JSON.stringify(pickedMapPosition));
  }

  static getHeroFromName(draftData, heroName) {
    return draftData.heros.find((hero) => hero.hero.name === heroName).hero;
  }

  static getFactionFromName(draftData, factionName) {
    return draftData.factions.find(
      (faction) => faction.faction.name === factionName
    ).faction;
  }

  static finishDraft(draftData) {
    draftData.draftFinished = true;
  }

  static isDraftFinished(draftData) {
    if (!draftData) {
      return true;
    }
    return draftData.draftFinished;
  }

  static getPlayers(draftData) {
    return draftData.players;
  }

  static getHeroFromPlayerInGameId(draftData, playerInGameId) {
    return draftData.players.find(
      (player) => player.playerInGameId === playerInGameId
    ).heroName;
  }

  static getFactionFromPlayerInGameId(draftData, playerInGameId) {
    return draftData.players.find(
      (player) => player.playerInGameId === playerInGameId
    ).faction;
  }

  static hasPlayerPickedPosition(draftData, playerInGameId) {
    return (
      draftData.players.find(
        (player) => player.playerInGameId === playerInGameId
      ).mapPosition !== null
    );
  }

  static hasPlayerPickedHero(draftData, playerInGameId) {
    const heroName = draftData.players.find(
      (player) => player.playerInGameId === playerInGameId
    ).heroName;
    if (heroName) {
      return true;
    }
    return false;
    /*return (
      draftData.players.find(
        (player) => player.playerInGameId === playerInGameId
      ).heroName !== null
    );*/
  }

  static hasPlayerPickedFaction(draftData, playerInGameId) {
    return (
      draftData.players.find(
        (player) => player.playerInGameId === playerInGameId
      ).faction !== null
    );
  }

  static getPositionFromPlayerInGameId(draftData, playerInGameId) {
    return draftData.players.find(
      (player) => player.playerInGameId === playerInGameId
    ).mapPosition;
  }

  static getHeroList(draftData) {
    return draftData.heros;
  }

  static getFactionList(draftData) {
    return draftData.factions;
  }

  static getPlayerFromPickedPosition(draftData, mapPosition) {
    return draftData.players.find((player) => {
      if (player.mapPosition) {
        return (
          player.mapPosition.mapPosition.x === mapPosition.x &&
          player.mapPosition.mapPosition.y === mapPosition.y
        );
      } else {
        return false;
      }
    });
  }

  static getPlayerFromPickedHero(draftData, heroName) {
    return draftData.players.find((player) => player.heroName === heroName);
  }

  static getPlayerFromPickedFaction(draftData, factionName) {
    return draftData.players.find((player) => player.faction === factionName);
  }

  static getAvailableFactions(draftData) {
    return draftData.factions.filter((faction) => !faction.factionPicked);
  }

  static getAvailableHeros(draftData) {
    return draftData.heros.filter((hero) => !hero.heroPicked);
  }

  static getAvailableMapPositions(draftData) {
    return draftData.mapPositions.filter(
      (mapPosition) => !mapPosition.mapPositionPicked
    );
  }

  static sendMessageStatusToClient(playerData) {
    const draftData = playerData.draftData;
    if (DraftData.isDraftFinished(draftData)) {
      UIMessage.sendInfoMessageToClient(
        playerData,
        "Draft finished",
        "Draft is finished"
      );
      return;
    }
    const activePlayerId = DraftData.getActivePlayer(draftData).playerInGameId;
    const activePlayerName = GameGlobalFunctions.getPlayerDisplayName(
      playerData,
      activePlayerId
    );
    if (activePlayerId !== playerData.playerInGameId) {
      UIMessage.sendInfoMessageToClient(
        playerData,
        activePlayerName + " to draft",
        "Up to " + activePlayerName + " to draft something"
      );
    } else {
      UIMessage.sendInfoMessageToClient(
        playerData,
        "Your turn to draft",
        activePlayerName + ", it is your turn to draft something"
      );
    }
  }

  static sendColonizeMessageToClient(playerData) {
    UIMessage.sendInfoMessageToClient(
      playerData,
      "Time for colonization",
      "Time for colonization ! You have " +
        playerData.colonists +
        " colonists. Each colonist can colonize a planet near your home system."
    );
  }
}

module.exports = DraftData;
